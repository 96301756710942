import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountService } from '../SERVICES/index';
import { environment } from 'src/environments/environment';
import { EnvService } from '../SERVICES/GENERAL/env.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private accountService: AccountService, private env : EnvService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.accountService.userValue;
        const isLoggedIn = user && user.data.token;
        const isApiUrl = request.url.startsWith(this.env.apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + user.data.token) });
        }

        return next.handle(request);
    }
}