<div class="container-fluid" *ngIf="!creazione && !creaPiano">
    <div class="content">
        <div class="row">
            <div class="col-md-12 ">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            <button *ngIf="viewListaPiani || isNavigazione" (click)="onComeBack()" id="back-button"
                                type="button" class="btn btn-round  btn-just-icon"
                                style="margin-top: 3px; color: rgb(54, 54, 54); box-shadow:none!important">
                                <i class="material-icons notranslate">arrow_back</i>
                            </button>
                            <h4 style="display: inline;">{{titleList}}</h4>
                            <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '6px'}">
                            </p-progressBar>
                        </div>
                        <hr>
                        <div *ngIf="!viewListaPiani && !isNavigazione" class="tab-content">
                            <div class="tab-pane active" id="pill1">
                                <div class="material-datatables">
                                    <div class="scrollable">
                                        <div class="table-responsive">
                                            <p *ngIf="listaStrutture === undefined || listaStrutture.length < 1">
                                                Nessun edificio disponibile
                                            </p>
                                            <table id="datatables"
                                                class="table table-striped table-no-bordered table-hover table-responsive"
                                                cellspacing="0" width="100%" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th (click)="sortTableEdifici('nome')" style="cursor: pointer;">
                                                            Struttura
                                                            <i class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i>
                                                        </th>
                                                        <th (click)="sortTableEdifici('city')" style="cursor: pointer;">
                                                            Città <i class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i></th>
                                                        <th (click)="sortTableEdifici('cliente')"
                                                            style="cursor: pointer;">
                                                            Cliente <i class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i></th>
                                                        <th (click)="sortTableEdifici('date')" style="cursor: pointer;">
                                                            Ultimo
                                                            Sopralluogo <i class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i></th>
                                                        <th (click)="sortTableEdifici('stato')"
                                                            style="cursor: pointer;">Stato
                                                            <i class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i>
                                                        </th>
                                                        <th class="text-right">
                                                            <a *ngIf="planimetrieService.isAdmin()" rel="tooltip"
                                                                title="Crea Edificio" (click)="onAdd()"
                                                                class="btn btn-simple btn-primary btn-icon"
                                                                style="padding: 2px 2px 2px 2px;"><i
                                                                    class="material-icons pointer"
                                                                    style="font-size: 20px;">add</i></a>
                                                            <a rel="tooltip" title="Aggiorna Lista"
                                                                (click)="onRefresh()"
                                                                class="btn btn-simple btn-primary btn-icon"
                                                                style="padding: 2px 2px 2px 2px;"><i
                                                                    class="material-icons pointer"
                                                                    style="font-size: 20px;">refresh</i></a>
                                                            <a *ngIf="planimetrieService.isAdmin()" rel="tooltip"
                                                                title="Elimina Edifici" (click)="onDeleteAll()"
                                                                class="btn btn-simple btn-danger btn-icon"
                                                                style="padding: 2px 2px 2px 2px;"><i
                                                                    class="material-icons pointer"
                                                                    style="font-size: 20px;">delete</i></a>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th style="width: 20px;">#</th>
                                                        <th>Struttura</th>
                                                        <th>Città</th>
                                                        <th>Cliente</th>
                                                        <th>Sopralluogo</th>
                                                        <th>Stato</th>
                                                        <th class="text-right">Azioni</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>

                                                    <tr *ngFor="let ed of listaStrutture; let i = index;">
                                                        <ng-container>
                                                            <td style="font-size: xx-small;"></td>
                                                            <td style="font-size: medium;">{{ed.nome}}</td>
                                                            <td style="font-size: medium;">
                                                                {{ed.indirizzo}}</td>
                                                            <td style="font-size: medium;">
                                                                {{ed.cliente}}</td>
                                                            <td style="font-size: medium;">
                                                                {{ed.dataSopralluogo | date:
                                                                'dd/MM/yyyy'}}</td>
                                                            <td style="font-size: medium;">
                                                                {{ed.stato | edificioStato}}</td>
                                                            <td class="text-right">
                                                                <a *ngIf="planimetrieService.isAdmin()"
                                                                    (click)="goToCreazioneEdificio(ed)" rel="tooltip"
                                                                    title="Modifica Struttura"
                                                                    class="btn btn-success btn-simple"
                                                                    style="padding: 2px 2px 2px 2px;"><i
                                                                        class="material-icons pointer"
                                                                        style="font-size: 20px;">edit</i></a>
                                                                <a (click)="goToListaPiani(ed)" rel="tooltip"
                                                                    title="Info Struttura"
                                                                    style="padding: 2px 2px 2px 2px;"
                                                                    class="btn btn-warning btn-simple"><i
                                                                        class="material-icons pointer"
                                                                        style="font-size: 20px;">view_list</i></a>
                                                                <a *ngIf="planimetrieService.isAdmin()" rel="tooltip"
                                                                    title="Elimina Struttura"
                                                                    (click)="deleteEdificio(ed)"
                                                                    class="btn btn-simple btn-danger btn-icon remove"
                                                                    style="padding: 2px 2px 2px 2px;"><i
                                                                        class="material-icons pointer"
                                                                        style="font-size: 20px;">close</i></a>

                                                            </td>
                                                        </ng-container>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div *ngIf="viewListaPiani && !isNavigazione" class="tab-content">
                            <div class="tab-pane active" id="pill1">
                                <div class="material-datatables">
                                    <div class="scrollable">
                                        <div class="table-responsive">
                                            <p *ngIf="listaPiani === undefined || listaPiani.length < 1">
                                                Nessun piano inserito
                                            </p>
                                            <table id="datatables"
                                                class="table table-striped table-no-bordered table-hover table-responsive"
                                                cellspacing="0" width="100%" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th (click)="sortTablePiani('nome')" style="cursor: pointer;">
                                                            Nome Piano
                                                            <i class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i>
                                                        </th>
                                                        <th class="text-right">
                                                            <a *ngIf="planimetrieService.isAdmin()" rel="tooltip"
                                                                title="Crea Piano" (click)="onAdd()"
                                                                class="btn btn-simple btn-primary btn-icon"
                                                                style="padding: 2px 2px 2px 2px;"><i
                                                                    class="material-icons pointer"
                                                                    style="font-size: 20px;">add</i></a>
                                                            <a rel="tooltip" title="Aggiorna Lista"
                                                                (click)="onRefresh()"
                                                                class="btn btn-simple btn-primary btn-icon"
                                                                style="padding: 2px 2px 2px 2px;"><i
                                                                    class="material-icons pointer"
                                                                    style="font-size: 20px;">refresh</i></a>
                                                            <a *ngIf="planimetrieService.isAdmin()" rel="tooltip"
                                                                title="Elimina Piani" (click)="onDeleteAll()"
                                                                class="btn btn-simple btn-danger btn-icon"
                                                                style="padding: 2px 2px 2px 2px;"><i
                                                                    class="material-icons pointer"
                                                                    style="font-size: 20px;">delete</i></a>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th style="width: 20px;">#</th>
                                                        <th>Nome Piano</th>
                                                        <th class="text-right">Azioni</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <ng-container *ngFor="let piano of listaPiani; let i = index;">
                                                        <tr>
                                                            <td>
                                                                <button type="button" pButton pRipple
                                                                    (click)="onExpandedRow(i, piano)"
                                                                    class="p-button-text p-button-rounded p-button-plain"
                                                                    [icon]="i == expandedRowIndex ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                                            </td>
                                                            <td style="font-size: medium;">{{piano.nomePiano}}</td>
                                                            <td class="text-right">
                                                                <a (click)="goToNavigazione(piano, null)" rel="tooltip"
                                                                    title="Navigazione Piano"
                                                                    style="padding: 2px 2px 2px 2px;"
                                                                    class="btn btn-warning btn-simple"><i
                                                                        class="material-icons pointer"
                                                                        style="font-size: 20px;">navigation</i></a>
                                                                <a (click)="downloadPlanimetria(piano)" rel="tooltip"
                                                                    title="Download" class="btn btn-primary btn-simple"
                                                                    style="padding: 2px 2px 2px 2px;"><i
                                                                        class="material-icons pointer"
                                                                        style="font-size: 20px;">get_app</i></a>
                                                                <a *ngIf="planimetrieService.isAdmin()"
                                                                    (click)="editPiano(piano)" rel="tooltip"
                                                                    title="Modifica Piano"
                                                                    class="btn btn-success btn-simple"
                                                                    style="padding: 2px 2px 2px 2px;"><i
                                                                        class="material-icons pointer"
                                                                        style="font-size: 20px;">edit</i></a>
                                                                <a *ngIf="planimetrieService.isAdmin()" rel="tooltip"
                                                                    title="Elimina Piano" (click)="deletePiano(piano)"
                                                                    class="btn btn-simple btn-danger btn-icon remove"
                                                                    style="padding: 2px 2px 2px 2px;"><i
                                                                        class="material-icons pointer"
                                                                        style="font-size: 20px;">close</i></a>

                                                            </td>
                                                        </tr>
                                                        <ng-container *ngIf="i != -1 && i == expandedRowIndex">
                                                            <tr
                                                                *ngFor="let stanza of expandedRowStanzePiano; let j = index;">
                                                                <td></td>
                                                                <td style="font-size: medium;">{{stanza.nomeStanza}}
                                                                </td>
                                                                <td class="text-right">
                                                                    <a (click)="goToNavigazione(piano, stanza.stanzaId)"
                                                                        rel="tooltip" title="Navigazione Piano"
                                                                        style="padding: 2px 2px 2px 2px;"
                                                                        class="btn btn-warning btn-simple"><i
                                                                            class="material-icons pointer"
                                                                            style="font-size: 20px;">navigation</i></a>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isNavigazione" class="row" style="height: 900px;">
                            <div class="row" #viewer id="viewer"></div>

                            <div>
                                <div class="col-xs-6" aria-label="Indietro" aria-pressed="true">
                                    <button type="button" (click)="onComeBack()" class="btn btn-secondary"
                                        style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Indietro</button>
                                </div>

                                <div *ngIf="planimetrieService.isAdmin() || planimetrieService.isOperatore()"
                                    class="col-xs-6 text-right" aria-label="Salva" aria-pressed="true">
                                    <button [disabled]="loading" type="button" (click)="onSaveMarker()"
                                        class="btn btn-primary">Salva
                                        <span *ngIf="loading">
                                            <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                                                styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <p-slideMenu #menu [model]="items" [popup]="true" [backLabel]="'Indietro'"
                                [viewportHeight]="150" (onHide)="onHideMenu()"></p-slideMenu>
                            <p-dialog [draggable]="false" [resizable]="false" #noteDialog header="Nota marker" [baseZIndex]="99999" [(visible)]="displayDialog">
                                <h5 class="nota-marker-label">Titolo</h5>
                                <input style="width: 100%; font-size: medium;" type="text" pInputText
                                    [(ngModel)]="markerNoteTitle" maxlength="20">
                                <h5 class="nota-marker-label">Nota</h5>
                                <textarea rows="5" cols="30" [(ngModel)]="markerNoteBody"
                                    style="resize: none; width: 100%; font-size: medium;" pInputTextarea
                                    maxlength="400"></textarea>

                                <div style="width: 100%; text-align: center; margin-top: 10%;">
                                    <p-button icon="pi pi-check" (click)="addNote()" label="Salva"
                                        styleClass="p-button-text"></p-button>
                                    <p-button icon="pi pi-times" (click)="onCloseDialog()" label="Chiudi"
                                        styleClass="p-button-danger p-button-text"></p-button>
                                </div>
                            </p-dialog>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>
</div>

<div *ngIf="creazione">
    <app-crea-edificio [edificio]="edificio" (comeBack)="comeBack($event)"></app-crea-edificio>
</div>

<div *ngIf="creaPiano">
    <app-crea-piano [edificio]="edificio" [ePiano]="ePiano" (comeBack)="comeBack($event)"></app-crea-piano>
</div>
