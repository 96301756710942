
import { Component, OnInit } from '@angular/core';
import { Utente } from '../../../MODELS/USER/utente';
import { UsersService } from '../../../SERVICES/index';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AccountService, AlertService } from '../../../SERVICES';
import { FileService } from '../../../SERVICES/FILE/file.service';
import { Sessione } from '../../../MODELS/ASSISTENZA/sessione';
import { Posizione } from '../../../MODELS/ASSISTENZA/posizione';
import { Router } from '@angular/router';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  badge = false;
  iot = false;
  cr = false;
  classroom = false;
  geo = false;
  ruolo;
  alfaCallCenter;
  clienti;

  constructor(private envService : EnvService, private accountService : AccountService){
    this.badge = this.envService.BADGE
    this.iot = this.envService.IOT
    this.cr = this.envService.CR
    this.classroom = this.envService.CLASSROOM
    this.geo = this.envService.GEO
    this.ruolo = this.accountService.ruolo
    this.alfaCallCenter=this.envService.ALFACALLCENTER
    this.clienti=this.envService.CLIENTI
  }

  ngOnInit(): void {
   
  }


     }
    
  

