


            <div class="container">
                <div class="row">
                    <div *ngIf="login" class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="card card-login ">
                                <div class="card-header text-center">
                                    <h4 class="card-title">{{env.nomeLogin}}</h4>
                                    <h4 class="card-title">Login</h4>
                                </div>
                                <div class="card-content">


                                    <div class="input-group">
                                        <span class="input-group-addon">
                                            <i class="material-icons notranslate">face</i>
                                        </span>
                                        <div class="form-group label-floating">
                                            <label class="control-label">Email</label>
                                            <input type="text" formControlName="email" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" maxlength="100">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email obbligatoria</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group">
                                        <span class="input-group-addon">
                                            <i class="material-icons notranslate">lock_outline</i>
                                        </span>
                                        <div class="form-group label-floating">
                                            <label class="control-label">Password</label>
                                            
                                            <input [type]="password" id="pw" class="form-control" formControlName="password"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" maxlength="100">
                                                <i *ngIf="!showPw" class="material-icons notranslate iconaPw" (click)="showPassword(true)">visibility</i>
                                                <i *ngIf="showPw"class="material-icons notranslate iconaPw" (click)="showPassword(false)">visibility_off</i>

                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password obbligatoria</div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button [disabled]="loading" class="btn btn-primary">
                                        Login
                                        <span *ngIf="loading">
                                            <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                                                styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                                        </span>
                                    </button>
                                    <p style="margin-top: 3%;margin-bottom: 3%;">
                                        <a routerLink="../assistenza">
                                            Assistenza
                                        </a>
                                    </p>

                                </div>
                            </div>
                        </form>

                    </div>
                    <!-- <app-recupera-password></app-recupera-password> -->
                </div>
            </div>
       