import { Component, DoCheck, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { AccountService, EnvService } from 'src/app/SERVICES/index';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-aula-virtuale-config',
  templateUrl: './aula-virtuale-config.component.html',
  styleUrls: ['./aula-virtuale-config.component.scss'],
})
export class AulaVirtualeConfigComponent implements OnInit, DoCheck {
  goToMode:boolean;
  listaaule = [];
  aula;
  aulaId
  cancellaAula = false;
  loading = false
  caricato = false;
  aule = true;
  crea = false;
  info = false;
  edit = false;
  ambiente;
  // PAGINAZIONE LISTA
  selectedValue
  isSelectingValue = false;
  isSearchingOn = false;
  isClickable = true;
  searchform: FormGroup;
  pagina;
  maxItems
  items
  numeroMaxPagine
  paginator = true;
  subscriptionSearch: Subscription
  refreshView = true;
  // PAGINAZIONE LISTA FINE
  constructor(
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private alertService: AlertService,
    private classService: ClassroomService,
    private routing: Router,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private envService:EnvService
  ) {
    this.goToMode = envService.goTo;
    this.ambiente = environment;
    this.pagina = 0;
    this.selectedValue = 10;
    this.chiamataPaginata(this.pagina, this.selectedValue)
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required]
    })
  }

  ngDoCheck(): void {
    if (this.accountService.listaEnv && (this.accountService.ruolo !== 1)) {
      this.routing.navigate(['./'])
    }


  }
  ngOnInit(): void {
    // if (this.accountService.ruolo !== 1) {
    //   this.routing.navigate([''])
    // }
    this.primengConfig.ripple = true;
  }

  chiamataPaginata(pagina, quantità, ricerca?, campo?, cardinalita?) {
    this.subscriptionSearch = this.classService.lista_classroom_leggera(pagina, quantità, ricerca, campo, cardinalita).subscribe((res) => {
      if (res.data.content.length > 0) {
        this.listaaule = res.data.content;
      } else {
        this.listaaule = []
      }
      this.numeroMaxPagine = Number(res.data.totalPages);
      this.maxItems = res.data.totalElements;
      this.items = res.data.numberOfElements

      this.isClickable = true;
      this.caricato = true;
      this.paginator = true;
      this.isSearchingOn = false;
      this.isSelectingValue = false;
      this.refreshView = true;
    })
  }

  cambiaPagina(event) {
    this.isClickable = false;
    this.pagina = event;
    if(this.valoreRicerca && this.valoreRicerca.length >0){
      this.chiamataPaginata(this.pagina, this.selectedValue, this.valoreRicerca)

    }else{
      this.chiamataPaginata(this.pagina, this.selectedValue, null)
    }  }

  cambiaValue() {
    this.isClickable = false;
    this.paginator = false;
    this.pagina = 0;
    this.isSelectingValue = true;
    this.isSearchingOn = true;
    this.refreshView = false;
    if(this.valoreRicerca && this.valoreRicerca.length >0){
      this.chiamataPaginata(this.pagina, this.selectedValue, this.valoreRicerca)

    }else{
      this.chiamataPaginata(this.pagina, this.selectedValue)
    }  }
  valoreRicerca
  search() {
    this.refreshView = false;
    this.valoreRicerca;
    this.pagina = 0;
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe()
    }
    this.isSearchingOn = true;
    this.paginator = false
    this.valoreRicerca = this.searchform.value.search
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue,  this.valoreRicerca)
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue)
    }
  }

  elimina(aulaId) {
    this.aulaId = aulaId
    this.cancellaAula = true;
    this.confirmationService.confirm({
      key: 'cancella',
      message: 'Sicuro di voler continuare? '+ '<br>' +' I dati verranno persi!',

      icon: 'pi pi-exclamation-triangle',

      reject: () => {
        this.cancellaAula = false;
        this.loading = false;
        this.confirmationService.close();
      }
    });
  }

  accettaCancella() {
    this.loading = true;
    this.refreshView = false;
    this.classService.deleteAula(this.aulaId).subscribe((res) => {
      this.alertService.success(res.status.description)
      if (this.listaaule.length == 1) {
        if (this.pagina > 0) {
          this.pagina = this.pagina - 1
          this.chiamataPaginata(this.pagina, this.selectedValue)
        } else {
          this.chiamataPaginata(this.pagina, this.selectedValue)
        }
      }else{
        this.chiamataPaginata(this.pagina, this.selectedValue)
      }
      this.confirmationService.close();
      this.cancellaAula = false;
      this.loading = false;
      this.refreshView=true;
    })
  }

  vaiCreazione() {
    this.crea = true;
    this.aule = false;
  }


  vediLista(vai) {
    this.chiamataPaginata(this.pagina, this.selectedValue)
    this.info = false;
    this.edit = false;
    this.crea = false;
    this.aule = true;
  }

  vaiModifica(id) {
    this.aula = this.listaaule.find(x => x.id === id)
    this.aule = false;
    this.edit = true;
  }

  vaiInfo(id) {
    this.aula = this.listaaule.find(x => x.id === id)
    this.aule = false;
    this.info = true;
  }

  ordinaEtichetta = 0
  ordinaArray(lable) {
    if (this.ordinaEtichetta == 0 || this.ordinaEtichetta == 2) {
      this.ordinaEtichetta = 1
    } else {
      this.ordinaEtichetta = 2
    }
    if(this.valoreRicerca.length >0){
      this.chiamataPaginata(this.pagina, this.selectedValue, this.valoreRicerca, lable, this.ordinaEtichetta,)

    }else{
      this.chiamataPaginata(this.pagina, this.selectedValue, null, lable, this.ordinaEtichetta)
    }
  }
  responsiveWidth() {    }

}
