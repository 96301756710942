import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { SessioneBadge } from 'src/app/MODELS/BADGE/sessione-badge';
import { Utente } from 'src/app/MODELS/USER/utente';
import { UsersService, AccountService, AlertService} from 'src/app/SERVICES';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { ExportExcelService } from 'src/app/SERVICES/FILE/excel.service';
import { DatePipe } from '@angular/common';
//import { EnvService } from 'src/app/SERVICES';

@Component({
  selector: 'app-report-badge',
  templateUrl: './report-badge.component.html',
  styleUrls: ['./report-badge.component.scss']
})
export class ReportBadgeComponent implements OnInit {

  searchform: FormGroup;
  users: Utente[]
  allUser: Utente[]
  selectedUsers3: Utente[] = [];
  utentiSelezionati: Utente[];
  idUtentiSelezionati: String[];
  report: boolean = false;
  reportDisponibili: boolean = false;
  selezionaUtente: boolean = true;

  calendar: FormGroup;
  ita;
  rangeDates: Date[];
  fromDay;
  fromMonth;
  fromYear;
  toDay;
  toMonth;
  toYear;
  today = new Date();
  dd = String(this.today.getDate()).padStart(2, '0');
  mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
  yyyy = this.today.getFullYear();
  todayDay=this.dd;
  todayMonth=this.mm;
  todayYear=this.yyyy;

  loading = false;
  loading2 = false;

  usersVideo;
  dataForExcel = [];
  fotoInizio;
  fotoFine;
  notaInizio;
  notaFine;
  //baseUrl = this.env.apiUrl;

  constructor(
    private userService: UsersService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private fileService: FileService,
    private excelService: ExportExcelService,
    private datePipe: DatePipe,
    //private env: EnvService
    ) {

    this.searchform = this.formBuilder.group({
      search: ['', Validators.required]
    })

    this.buildForm();
    this.userService.getAllReport().subscribe((res) => {
      this.users = res.data.listaUtentiAttivi;
      res.data.listaUtentiCancellati.forEach(element => {
        this.users.push(element)
      });
      this.allUser = this.users;
      this.report = true;

    }, (error) => {

    });


  }


  buildForm(): void {
    this.calendar = this.formBuilder.group({

     calendario: new FormControl('', Validators.required),

    });}



  ngOnInit(): void {
    this.ita = {
      firstDayOfWeek: 1,
      dayNames: ["Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
      dayNamesMin: ["Do","Lu","Ma","Me","Gi","Ve","Sa"],
      monthNames: [ "Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre" ],
      monthNamesShort: [ "Gen", "Feb", "Mar", "Apr", "Mag", "Giu","Lug", "Ago", "Set", "Ott", "Nov", "Dic" ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
  };

    this.buildForm();
    this.userService.getAllReport().subscribe((res) => {
      this.users = res.data.listaUtentiAttivi;
      res.data.listaUtentiCancellati.forEach(element => {
        this.users.push(element)
      });
      this.allUser = this.users;
      this.report = true;

    }, (error) => {

    });

  }

  searchInUser() {//metti search in user in gestione utenti
    let valore: string = this.searchform.value.search;
    this.users = this.allUser;
    if (valore) {
      let users = []
      this.users.forEach(user => {
        user.gruppos.forEach(gruppo => {
          if (gruppo.nomeGruppo.includes(valore) || gruppo.nomeGruppo.toLowerCase().includes(valore) || gruppo.nomeGruppo.toUpperCase().includes(valore)) {
            if (!(users.find(x => x === user))) {
            users.push(user);
          }
          }
        })

        if (user.nome.includes(valore) || user.nome.toLowerCase().includes(valore.toLowerCase()) || user.cognome.includes(valore) || user.cognome.toLowerCase().includes(valore.toLowerCase())
          || user.nome.toUpperCase().includes(valore) || user.cognome.toUpperCase().includes(valore) ||
          user.email.includes(valore) || user.email.toLowerCase().includes(valore.toLowerCase()) || user.email.toUpperCase().includes(valore) ||
          (user.nome + ' ' + user.cognome).toLocaleLowerCase().includes(valore.toLowerCase()) || (user.cognome + ' ' + user.nome).toLocaleLowerCase().includes(valore.toLowerCase())
        ) {
          if (!(users.find(x => x === user))) {
          users.push(user);
          }
        }
      });
      this.users = users;
    } else if (this.users !== this.allUser) {
      this.users = this.allUser;
    }
  }

  avanti() {
    this.alertService.clear();
    if (this.selectedUsers3.length > 0) {
      this.utentiSelezionati = this.selectedUsers3;
      this.selezionaUtente = false;
    }
    else {
      this.alertService.error("Seleziona uno o più utenti");
    }
  }

  indietro(){
    this.selezionaUtente = true;
    this.selectedUsers3 =[];
  }

  selezionaData(){


    if(this.rangeDates!==undefined && this.rangeDates[0]!==null)
    {
    var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
    var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
    var yyyy = this.rangeDates[0].getFullYear();
    this.fromDay=dd;
    this.fromMonth=mm;
    this.fromYear=yyyy;
    }
    if(this.rangeDates!==undefined && this.rangeDates[1]!==null)
    {
    var dd = String(this.rangeDates[1].getDate()).padStart(2, '0');
    var mm = String(this.rangeDates[1].getMonth() + 1).padStart(2, '0');
    var yyyy = this.rangeDates[1].getFullYear();
    this.toDay=dd;
    this.toMonth=mm;
    this.toYear=yyyy;
    }
    if(this.rangeDates!==undefined && this.rangeDates[1]===null)
    {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.toDay=dd;
      this.toMonth=mm;
      this.toYear=yyyy;
    }
    if(this.rangeDates===undefined)
    {
      this.fromDay="01";
      this.fromMonth="01";
      this.fromYear="1990";

      this.toDay=this.todayDay;
      this.toMonth=this.todayMonth;
      this.toYear=this.todayYear;
    }

  }
  isFirstTime = true;
  exportToExcel(usersVid, operatore) {
    this.dataForExcel = [];
   usersVid.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })

    let reportData = {
      title: 'Report Controllo Accessi - ' + operatore,
      data: this.dataForExcel,
      headers: Object.keys(usersVid[0]).slice(0,9) //esclude il campo booleano per l'out of range
    }
    this.excelService.ExportExcelBadge(reportData, this.rangeDates);
  }


  exportExcelVideo() {

    let usersVideo;
    usersVideo = this.usersVideo.data;
    let usersVid : SessioneBadge[];

    usersVideo.forEach(user => {
      usersVid = new Array;
      let operatore = user.user.nome + ' ' + user.user.cognome;
      let sessione : SessioneBadge = new SessioneBadge({operatore:operatore});
      user.sessions.forEach(session =>
        {
              sessione.data = this.datePipe.transform(session.startedAt, 'dd/MM/yy HH:mm:ss');
              sessione.operazione = "Entrata";
              usersVid.push(sessione);
              sessione = new SessioneBadge({operatore:operatore});
              session.reportTasks.forEach(task =>
                {
                  let dataInizio = this.datePipe.transform(task.startedAt, 'dd/MM/yy HH:mm:ss');
                  if(task.startPhoto)
                  {
                    //this.fileService.getReportImage(task.startPhoto).subscribe((res) =>{  this.fotoInizio = res.data.image;});
                    this.fotoInizio = window.origin + "/#/badge/report/image/" + task.startPhoto;
                  }
                  else
                  {
                    this.fotoInizio = "N/A";
                  }
                  if(task.startNote)
                  {
                    this.notaInizio = task.startNote;
                  }
                  let dataFine = this.datePipe.transform(task.endedAt, 'dd/MM/yy HH:mm:ss');
                  if(task.endPhoto)
                  {
                    //this.fileService.getReportImage(task.endPhoto).subscribe((res) =>{  this.fotoFine = res.data.image;});
                    this.fotoFine = window.origin + "/#/badge/report/image/" + task.endPhoto;
                  }
                  else
                  {
                    this.fotoFine = "N/A";
                  }
                  if(task.startNote)
                  {
                    this.notaFine = task.endNote;
                  }
                  task.locations.forEach(location =>
                    {
                      if(location.locationType == "TASK_START_LOCATION")
                      {
                        sessione.operazione = "Foto inizio lavori";
                        sessione.data = dataInizio;
                        sessione.foto = this.fotoInizio;
                        sessione.nota = this.notaInizio;
                      }
                      else if(location.locationType == "TASK_END_LOCATION")
                      {
                        sessione.operazione = "Foto fine lavori";
                        sessione.data = dataFine;
                        sessione.foto = this.fotoFine;
                        sessione.nota = this.notaFine;
                      }
                      else
                      {
                        sessione.operazione = "Geolocalizzazione";
                        sessione.data = "--";
                        sessione.foto = "N/A";
                      }

                      sessione.latitudine = location.latitudine;
                      sessione.longitudine = location.longitudine;
                      sessione.comune = location.capComune;
                      sessione.indirizzo = location.indirizzo;
                      sessione.fuoriRange = location.outOfRange;
                      usersVid.push(sessione);
                      sessione = new SessioneBadge({operatore:operatore});
                    })
                })
              sessione.data = this.datePipe.transform(session.endedAt, 'dd/MM/yy HH:mm:ss');
              sessione.operazione = "Uscita";
              usersVid.push(sessione);
              sessione = new SessioneBadge({operatore:operatore})
        })
        if(usersVid.length >= 1){
          //this.loading = false;
          this.reportDisponibili = true;
          this.exportToExcel(usersVid, operatore);
        }
        });


   
    if(!this.reportDisponibili) {
      //this.loading = false;
      this.alertService.clear;
      this.alertService.info('Nessun report presente per gli utenti e/o le date selezionate');
    }
    else
    {
      this.reportDisponibili = false;
    }
    this.loading = false;

}

  inviaDati(){
    //this.isFirstTime = false;
    this.loading=true;
    this.selezionaData();

    this.idUtentiSelezionati = new Array;
    this.utentiSelezionati.forEach(user => {
      let id: string;
      id = user.id;
      this.idUtentiSelezionati.push(id);
    });

    this.fileService.inviaDatiReportBadge(this.idUtentiSelezionati, this.fromDay, this.fromMonth, this.fromYear, this.toDay, this.toMonth, this.toYear ).subscribe((res)=>{

      this.usersVideo = res;
      setTimeout(() => {
        this.exportExcelVideo();
      }, 1000);
  },
    error => this.loading=false);

  }

}
