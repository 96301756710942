<div *ngIf="utenteId && loadingModifica" class="text-center" style="position: absolute;right: 0px;">
    <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="5"
      styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
</div>

<div class="content" *ngIf="!(utenteId && loadingModifica)">
    <div class="container-fluid">
        <!-- <p-messages [value]="msgs" ></p-messages> -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <form [formGroup]="createForm" (ngSubmit)="onSubmit()" class="form-horizontal">
                        
                        <div class="card-header card-header-text" >
                            <h4 *ngIf="!utenteId" class="card-title text-center" >Crea un nuovo tecnico</h4>
                            <h4 *ngIf="utenteId" class="card-title text-center" >Modifica tecnico</h4>
                        </div>
                        
                        <div class="card-content">
                            <div class="row">
                                <label class="col-sm-3 label-on-left" >Nome<span class="colorRed"> *</span></label>
                                <div class="col-sm-9">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" placeholder="Nome" formControlName="nome"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" maxlength="30" />
                                        <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                                            <div *ngIf="f.nome.errors.required">Campo nome obbligatorio</div>
                                            <div *ngIf="f.nome.errors.pattern">Campo nome invalido</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-3 label-on-left">Cognome<span class="colorRed"> *</span></label>
                                <div class="col-sm-9">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" placeholder="Cognome"
                                            formControlName="cognome" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.cognome.errors }"
                                            maxlength="30" />
                                        <div *ngIf="submitted && f.cognome.errors" class="invalid-feedback">
                                            <div *ngIf="f.cognome.errors.required">Campo cognome obbligatorio</div>
                                            <div *ngIf="f.cognome.errors.pattern">Campo cognome invalido</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-3 label-on-left" >Email<span class="colorRed"> *</span></label>
                                <div class="col-sm-9">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="email" placeholder="Email" formControlName="email"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" maxlength="50" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.pattern">Formato email non corretto</div>
                                            <div *ngIf="f.email.errors.required">Campo email obbligatorio</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-3 label-on-left">Fototessera</label>
                                <div class="col-sm-9">
                                    <div class="form-group label-floating is-empty">
                                        <input style="display: none" type="file" (input)="fototesseraCaricata($event)" #fileInputFototessera>
                                        <button type="button" class="btn btn-primary" style="margin-top: -3px;" (click)="fileInputFototessera.click()">Carica</button>
                                        <button *ngIf="(linkFototessera || fileFototessera) && !rimuoviFoto" type="button" class="btn btn-primary" style="margin-top: -3px;" (click)="rimuoviImmagine()">Rimuovi</button>
                                        <img *ngIf="utenteId && linkFototessera && !rimuoviFoto" src="{{linkFototessera}}" class="fototessera">
                                        <p *ngIf="fileFototessera">{{fileFototessera.name}}</p>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="text-right" style="margin-right: 5%;">
                                <hr>
                                <div class="btn-group" role="group" aria-label="Basic example">

                                    <button type="button" (click)="indietroDaCreazione()" class="btn btn-secondary"
                                        aria-pressed="true" [disabled]="loading">Annulla</button>
                                </div>
                                <div class="btn-group" role="group" aria-label="Basic example" aria-pressed="true">
                                    <button [disabled]="loading" type="sumbit" class="btn btn-primary">Salva
                                        <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
                                    </button>
                                </div>
                            </div>
                            <br>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

