import { DatePipe } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Edificio } from 'src/app/MODELS/PLANIMETRIE/edificio';
import { AlertService } from 'src/app/SERVICES';
import { PlanimetrieService } from 'src/app/SERVICES/PLANIMETRIE/planimetrie.service';

@Component({
  selector: 'app-crea-edificio',
  templateUrl: './crea-edificio.component.html',
  styleUrls: ['./crea-edificio.component.scss']
})
export class CreaEdificioComponent implements OnInit {

  @Input() edificio: Edificio;
  @Output() comeBack = new EventEmitter<string>();

  title: string;
  createForm: FormGroup;
  loading: boolean = false;
  creazione: boolean;
  submitted: boolean = false;
  stati: any[];
  validatorRuolo: boolean = false;
  isEdit: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private planimetrieService: PlanimetrieService,
    private alertService: AlertService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.isEdit = this.edificio !== null;

    this.title = 'Creazione Edificio';
    if (this.isEdit) {
      this.title = 'Modifica Edificio';
    }

    this.stati = [
      { label: "In corso", value: "incorso" },
      { label: "Nessun Sopralluogo", value: "nessunsopralluogo" },
      { label: "Completato", value: "completato" }
    ];

    this.createForm = this.formBuilder.group({
      nome: [this.isEdit ? this.edificio.nome : '', [Validators.required, this.noWhiteSpaceValidator]],
      indirizzo: [this.isEdit ? this.edificio.indirizzo : '', [Validators.required, this.noWhiteSpaceValidator]],
      cliente: [this.isEdit ? this.edificio.cliente : '', [Validators.required, this.noWhiteSpaceValidator]],
      stato: [this.isEdit ? this.edificio.stato : 'incorso', [Validators.required, Validators.min(1)]],
      dataSopralluogo: [this.isEdit ? this.datePipe.transform(this.edificio.dataSopralluogo, 'dd/MM/yyyy') : '']
    });
  }

  noWhiteSpaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'noWhiteSpaceValidator': true };
  }

  get f() { return this.createForm.controls; }

  onSubmit() {
    this.loading = true;
    this.submitted = true;

    if (this.createForm.invalid) {
      this.loading = false;
      return;
    }

    if (this.createForm.value.stato != 'completato') {
      this.createForm.value.dataSopralluogo = null;
    }

    let edificio: Edificio = {
      nome: this.createForm.value.nome,
      indirizzo: this.createForm.value.indirizzo,
      cliente: this.createForm.value.cliente,
      stato: this.createForm.value.stato,
      dataSopralluogo: this.createForm.value.dataSopralluogo
    }

    if (this.isEdit) {
      // Update
      edificio.edificioId = this.edificio.edificioId;
      this.planimetrieService.updateEdificio(edificio).subscribe((res) => {
        this.loading = false;
        this.alertService.success(`Edificio ${res.data.nome} aggiornato con successo!`);
        this.submitted = false;
        this.comeBack.emit('fromcrea');
      }, (error) => {
        this.loading = false;
        this.alertService.error('Si è verificato un problema');
      });
    } else {
      this.planimetrieService.insertEdificio(edificio).subscribe((res) => {
        this.loading = false;
        this.alertService.success('Successo', `Edificio ${res.data.nome} creato!`);
        this.submitted = false;
        this.comeBack.emit('fromcrea');
      }, (error) => {
        this.loading = false;
        this.alertService.error('Si è verificato un problema');
      });
    }
  }

  clear() {
    this.createForm.reset();
    this.f.stato.patchValue('incorso');
  }

  goBack() {
    this.comeBack.emit('fromcrea');
  }
}
