import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { LayoutComponent } from './layout.component';
import { LoginComponent } from './login.component';
import { AssistenzaComponent } from './assistenza.component';
import { RecuperaPasswordComponent } from './recupera-password/recupera-password.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { DeviceViewComponent } from './device/device-view/device-view.component';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AccountRoutingModule,
        ProgressSpinnerModule
    ],
    declarations: [
        LayoutComponent,
        LoginComponent,
        AssistenzaComponent,
        RecuperaPasswordComponent,
        DeviceViewComponent,
    ]
})
export class AccountModule { }