<!-- <div *ngIf="listaDitipi">


    <h5 class="card-title">Crea una nuova Configurazione</h5>
    <p-table [value]="listaTipi" [responsive]=true [scrollable]="true" scrollHeight="40rem" scrollWidth="100rem">

        <ng-template pTemplate="header">
            <tr>

                <th width="45%">Nome Tipo</th>
                <th width="45%">Descrizione</th>
                <th width="10%" style="text-align: right;">Azioni</th>

            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-tipo>
            <tr>

                <td width="45%">{{tipo.tipoDevice}}</td>
                <td width="45%">{{tipo.descrizione}}</td>
                <td style="text-align: right;" width="10%">
                    <a (click)="vaiCrea(tipo.tipoDevice)" rel="tooltip" title="Crea Configurazione"
                        class="btn btn-success btn-simple" style="padding: 2px 2px 2px 2px;">
                        <i class="material-icons notranslate pointer" style="font-size: 20px;">edit</i></a>
                </td>


            </tr>

        </ng-template>

    </p-table>
    <div class="row" style="margin-top: 2%">

    </div>

</div> -->
<div *ngIf="!listaDitipi">
    <h5 class="card-title">Crea una nuova Configurazione</h5>
  
        <!----------------------------------Intervallo-->
        <!-- <div *ngIf="tipoDevice==='H002'||tipoDevice==='H003'"> -->

            <div  class="row">
                <label class="col-sm-2 label-on-left" style="text-align: end;
                        margin-top: 20px;">Intervallo di misurazione battito cardiaco e pressione</label>
                <div class="col-sm-10">

                    <div class="form-group label-floating is-empty">
                        <label class="control-label"></label>
                      
                             
                        <mat-slider  thumbLabel [displayWith]="formatLabel" tickInterval="auto" step="1" min="1"
                            max="5" [(ngModel)]="valoreBpm" aria-label="units"></mat-slider>
                     
                        <small class="text-muted">Determina un intervallo di tempo tra una misurazione ed
                            un'altra</small>
                  
                    </div>
                </div>
            </div>

            <div  class="row">
                <label class="col-sm-2 label-on-left"  style="text-align: end;
                margin-top: 26px;">Intervallo di misurazione Temperatura</label>
                <div class="col-sm-10">
                    <div class="form-group label-floating is-empty">
                        <label class="control-label"></label>
                        <mat-slider  thumbLabel [displayWith]="formatLabel" tickInterval="auto" step="1" min="1"
                            max="5"  [(ngModel)]="valoreTemp" aria-label="units"></mat-slider>
                        <small class="text-muted">Determina un intervallo di tempo tra una misurazione ed un'altra
                        </small>
                    
                    </div>
                </div>
            </div>

            <!----------------------------------Volume-->

            <div class="row">
                <label class="col-sm-2 label-on-left"  style="text-align: end;
                margin-top: 32px;">Volume
                </label>
                <div class="col-sm-10">
                    <div class="form-group label-floating is-empty">
                        <div class="range">
                
                            <mat-slider  thumbLabel [displayWith]="formatLabelVolume" tickInterval="auto" step="1"
                                min="0" max="11"  [(ngModel)]="valoreVolume" aria-label="units">
                            </mat-slider>
                            
                        </div>
                    
                    </div>
                </div>
            </div>
        <form [formGroup]="configurazioneform">
        <!----------------------------------red Number-->
        <div *ngIf="tipoDevice==='H002'||tipoDevice==='H003'">
            <div class="row">
                <div class="col-sm-2 form-group label-floating is-empty"><label class="label-on-left">Numero Rosso<span class="colorRed"> *</span> </label></div>
                <div class="col-sm-10">
                    <div class="form-group label-floating is-empty">
                        <label class="control-label"></label>
                        <input type="text" maxlength="30" placeholder="Numero" formControlName="redNumber" class="form-control ng-untouched ng-pristine ng-invalid"
                             />
                        <small class="text-muted"></small>

                                <div *ngIf="submitted && h.redNumber.errors" class="invalid-feedback">
                                    <div *ngIf="h.redNumber.errors.required">Campo tipo obbligatorio</div>
                                    <div *ngIf="h.redNumber.errors.pattern">Inserisci un numero di telefono corretto</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <!----------------------------------Yellow Number-->
        <div *ngIf="tipoDevice==='H002'||tipoDevice==='H003'">
            <div class="row">
                <div class="col-sm-2 form-group label-floating is-empty"><label class="label-on-left">Numero Giallo<span class="colorRed"> *</span> </label></div>
                <div class="col-sm-10">
                    <div class="form-group label-floating is-empty">
                        <label class="control-label"></label>
                        <input type="text" maxlength="30" placeholder="Numero" formControlName="yellowNumber" class="form-control ng-untouched ng-pristine ng-invalid"
                             />
                        <small class="text-muted"></small>

                            <div *ngIf="submitted && h.yellowNumber.errors" class="invalid-feedback">
                                    <div *ngIf="h.yellowNumber.errors.required">Campo tipo obbligatorio</div>
                                    <div *ngIf="h.yellowNumber.errors.pattern">Inserisci un numero di telefono corretto</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <!----------------------------------green Number-->      
        <div *ngIf="tipoDevice==='H002'||tipoDevice==='H003'">
            <div class="row">
                <div class="col-sm-2 form-group label-floating is-empty"><label class="label-on-left">Numero Verde<span class="colorRed"> *</span> </label></div>
                <div class="col-sm-10">
                    <div class="form-group label-floating is-empty">
                        <label class="control-label"></label>
                        <input type="text" maxlength="30" placeholder="Numero" formControlName="greenNumber" class="form-control ng-untouched ng-pristine ng-invalid"
                             />
                        <small class="text-muted"></small>

                                <div *ngIf="submitted && h.greenNumber.errors" class="invalid-feedback">
                                    <div *ngIf="h.greenNumber.errors.required">Campo tipo obbligatorio</div>
                                    <div *ngIf="h.greenNumber.errors.pattern">Inserisci un numero di telefono corretto</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <!----------------------------------White List-->
        <div *ngIf="tipoDevice==='H002'||tipoDevice==='H003'">
            <div class="row">
                <div class="col-sm-2 form-group label-floating is-empty"><label class="label-on-left">White List<span class="colorRed"> *</span> </label></div>
                <div class="col-sm-10">
                    <div class="form-group label-floating is-empty">
                        <label class="control-label"></label>
                        <input type="text" maxlength="255" placeholder="Numero" formControlName="whiteList" class="form-control" />
                        <small class="text-muted">inserisci numeri separati da virgola</small>

                                <div *ngIf="submitted && h.whiteList.errors" class="invalid-feedback">
                                    <div *ngIf="h.whiteList.errors.required">Campo tipo obbligatorio</div>
                                    <div *ngIf="h.whiteList.errors.pattern">Inserisci numeri di telefono corretti</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-sm-2 label-on-left">Sleep Time </label>
                <div class="col-sm-10">
                          <div class="form-group label-floating is-empty">
                        <input  style="margin-top: 11px;"  type="checkbox" placeholder="SleepTime" formControlName="sleepTime"
                        class="form-check-input"  />       
                        <small style="display: flex;"class="text-muted">inserisce un range di tempo dove il device smette di comunicare</small>
                        </div>
                </div>
            </div>
       

            <div class="row" style="margin-top: 2%">
                <div class="col-xs-6">
    <button (click)="goBack()" class="btn btn-secondary"
        style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Indietro
        </button>
                </div>
        <div class="col-xs-6 text-right">
            <button  [disabled]="loading"(click)="onSubmit()" class="btn btn-primary">Crea<span *ngIf="loading">
            <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
        </span></button>
        </div>
    </div>
    </form> 
</div>

