import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { LoginComponent } from './login.component';
import { AssistenzaComponent } from './assistenza.component';
import { RecuperaPasswordComponent } from './recupera-password/recupera-password.component';
import { DeviceViewComponent } from './device/device-view/device-view.component';

const routes: Routes = [
    {
        path: '', component: LayoutComponent,
        children: [
            { path: 'login', component: LoginComponent, },
            { path: 'assistenza', component: AssistenzaComponent,},
            { path: 'recuperapassword/:token', component: RecuperaPasswordComponent},
            { path: 'deviceView', component: DeviceViewComponent},
            { path: '**', redirectTo:'login'}
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountRoutingModule { }
