<div class="container-fluid" *ngIf="gestioneFile">
  <div *ngIf="gestione" class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-content">
            <h4 class="card-title">Documentazione</h4>
            <ul
              *ngIf="!fromPrenotaTecnico && !fromCR"
              class="nav nav-pills nav-pills-primary"
              [ngClass]="{ opacity0: refreshView }"
            >
              <li class="active">
                <a
                  href="#pill1"
                  data-toggle="tab"
                  [ngClass]="{ active: activeTab === '#pill1' }"
                  (click)="activeTab = '#pill1'"
                  >File</a
                >
              </li>
              <li>
                <a
                  *ngIf="envScorm && (userRole === 1 || userRole === 4)"
                  id="corsiClick"
                  href="#pill3"
                  [ngClass]="{ active: activeTab === '#pill3' }"
                  data-toggle="tab"
                  >Corsi</a
                >
              </li>
              <li>
                <a
                  href="#pill2"
                  data-toggle="tab"
                  [ngClass]="{ active: activeTab === '#pill2' }"
                  >Cestino</a
                >
              </li>
              <li>
                <a
                  *ngIf="envScorm && (userRole === 1 || userRole === 4)"
                  href="#pill4"
                  [ngClass]="{ active: activeTab === '#pill4' }"
                  data-toggle="tab"
                  >Corsi Cestino</a
                >
              </li>
              <div
                class="pull-right"
                *ngIf="
                  !isRoot &&
                  cartellaCorrente.id &&
                  (this.isPersonal ||
                    this.userRole === 1 ||
                    this.cartellaCorrente.canWrite)
                "
              >
                <div
                  class="btn btn-primary btn-round"
                  style="padding: 8px 8px 8px 8px; margin-left: 35px"
                  (click)="addFile()"
                >
                  <i class="material-icons notranslate">add</i>
                </div>
              </div>
            </ul>

            <hr />
            <div class="tab-content" *ngIf="!loading">
              <div
                class="tab-pane active"
                id="pill1"
                [ngClass]="{ active: !activeTab || activeTab === '#pill1' }"
              >
                <div class="card-title" class="flex">
                  <button
                    *ngIf="!isRoot"
                    (click)="
                      backButtonLoading = true;
                      apriCartella(cartellaCorrente.parentId, true)
                    "
                    [disabled]="backButtonLoading"
                    class="btn btn-round btn-just-icon back-button"
                    style="
                      margin-top: 5px;
                      color: rgb(54, 54, 54);
                      box-shadow: none !important;
                    "
                  >
                    <i class="material-icons notranslate">arrow_back</i>
                  </button>
                  <h3 style="display: inline">
                    {{ rememberPath }}{{ cartellaCorrente.nome }}
                  </h3>
                </div>
                <div>
                  <div
                    *ngIf="
                      cartellaCorrente && cartellaCorrente.cartelle.length > 0
                    "
                    [ngClass]="fromCR ? '' : 'flex'"
                  >
                    <h5 *ngIf="!fromCR">Cartelle</h5>
                    <div class="flex" [ngClass]="{'ml-auto': !fromCR }">
                      <label class="form-group" [ngClass]="{'ml-auto': !fromCR }" style="margin-top: 10px"
                        >Ricerca tra le cartelle:
                      </label>
                      <input
                        class="form-control input-sm"
                        maxlength="30"
                        [(ngModel)]="searchWordCartelle"
                        style="width: 40vw"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="grid-container">
                    <span
                      *ngIf="
                        cartellaCorrente &&
                        !cartellaCorrente.id &&
                        !isRoot &&
                        !cartellaCorrente.cartelle &&
                        listaFile.length < 1
                      "
                    >
                      La cartella è vuota.
                    </span>
                    <ng-container *ngIf="showCartelleUser">
                      <div
                        class="grid-item pt-20"
                        (click)="apriAreaPersonale()"
                        *ngIf="isRoot"
                      >
                        <i
                          _ngcontent-wjn-c285=""
                          class="material-icons pointer"
                          style="font-size: 20px; vertical-align: -5px"
                          >folder</i
                        >Area Personale
                      </div>
                      <ng-container
                        *ngFor="let cartella of cartellaCorrente.cartelle"
                      >
                        <div
                          class="grid-item"
                          [ngClass]="{
                            'pt-20':
                              (isRoot && userRole !== 1) || fromPrenotaTecnico || cartella.persistent_folder
                          }"
                          *ngIf="
                            cartella.nome
                              .toLowerCase()
                              .includes(searchWordCartelle.toLowerCase())
                          "
                          (click)="apriCartella(cartella.id)"
                        >
                          <div
                            class="flex"
                            *ngIf="
                              (userRole === 1 || isPersonal) &&
                              !fromPrenotaTecnico &&
                              !cartella.persistent_folder
                            "
                          >
                            <i
                              class="material-icons notranslate flex v-center fs-12"
                              *ngIf="!isAreaPersonale"
                              (click)="editFolder(cartella)"
                              >edit</i
                            >
                            <span
                              class="ml-auto"
                              (click)="deleteFolder(cartella.id)"
                              >x</span
                            >
                          </div>
                          <div
                            *ngIf="!(userRole === 1 || isPersonal)"
                            class="pt-20"
                          ></div>
                          <i
                            _ngcontent-wjn-c285=""
                            class="material-icons pointer"
                            style="font-size: 20px; vertical-align: -5px"
                            >folder</i
                          >{{ cartella.nome }}
                        </div>
                      </ng-container>
                    </ng-container>
                    <div
                      (click)="getCartellaOrfani()"
                      class="grid-item pt-20"
                      *ngIf="userRole === 1 && isRoot"
                    >
                      <i
                        _ngcontent-wjn-c285=""
                        class="material-icons pointer"
                        style="font-size: 20px; vertical-align: -5px"
                        >folder</i
                      >Ripristinati
                    </div>
                    <div
                      (click)="editFolder({ nome: '' })"
                      class="grid-item new-folder-button pt-20"
                      *ngIf="
                        (userRole === 1 ||
                          isPersonal ||
                          (cartellaCorrente &&
                            !cartellaCorrente.id &&
                            isRoot &&
                            userRole === 1)) &&
                        !isAreaPersonale &&
                        !fromPrenotaTecnico
                      "
                    >
                      <i
                        _ngcontent-wjn-c285=""
                        class="material-icons pointer"
                        style="font-size: 20px; vertical-align: -5px"
                        >add</i
                      >Nuova cartella
                    </div>
                  </div>
                </div>
                <h5 *ngIf="!isRoot && !isAreaPersonale">File</h5>
                <div
                  *ngIf="!isRoot && !isAreaPersonale"
                  class="material-datatables"
                >
                  <div class="row">
                    <div class="col-sm-3 col-xs-3">
                      <div class="dataTables_length" id="datatables_length">
                        <label class="form-group"
                          >Limita Risultati
                          <select
                            [(ngModel)]="selectedValue"
                            (ngModelChange)="cambiaValue()"
                            aria-controls="datatables"
                            class="form-control input-sm"
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-9 col-xs-9">
                      <div class="pull-right">
                        <div id="datatables_filter" class="dataTables_filter">
                          <form [formGroup]="searchform">
                            <label class="form-group"
                              >Esegui una ricerca
                              <div class="row">
                                <div class="col-sm-10">
                                  <input
                                    type="text"
                                    (keydown)="onKeyDownEvent($event)"
                                    (input)="searchInFile()"
                                    formControlName="search"
                                    class="form-control"
                                    placeholder="Cerca..."
                                    maxlength="30"
                                    style="width: 40vw"
                                  />
                                </div>
                              </div>
                            </label>
                          </form>
                          <!-- <a *ngIf="!listaAvanzata" (click)="vaiRicerca()" class="pull-right"
                            style="margin-top: -10px;">Ricerca Avanzata</a>
                          <a *ngIf="listaAvanzata" (click)="visualizzaTuttiFile()" class="pull-right"
                            style="margin-top: -10px;">Visualizza tutti i
                            file</a> -->

                          <!-- <div *ngIf="searchInFile()"></div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="scrollable">
                    <p-confirmDialog
                      #cd
                      icon="pi pi-exclamation-triangle"
                      [style]="{ width: responsiveWidth() }"
                      [acceptLabel]="'Si'"
                      [baseZIndex]="5"
                    >
                      <p-footer>
                        <button
                          [disabled]="loading"
                          *ngIf="cancellaFile"
                          type="button"
                          pButton
                          icon="pi pi-check"
                          label="Sì"
                          (click)="accettaCestina()"
                        >
                          <span *ngIf="loading">
                            <p-progressSpinner
                              [style]="{ width: '10px', height: '10px' }"
                              strokeWidth="8"
                              styleClass="custom-spinner"
                              animationDuration=".5s"
                            ></p-progressSpinner>
                          </span>
                        </button>
                        <button
                          *ngIf="!cancellaFile"
                          type="button"
                          pButton
                          icon="pi pi-check"
                          label="Sì"
                          (click)="cd.accept()"
                        ></button>
                        <button
                          [disabled]="loading && cancellaFile"
                          type="button"
                          pButton
                          icon="pi pi-times"
                          label="No"
                          (click)="cd.reject()"
                        ></button>
                      </p-footer>
                    </p-confirmDialog>

                    <div class="table-responsive">
                      <p *ngIf="!listaFile || listaFile.length < 1">
                        Nessun file disponibile
                      </p>
                      <table
                        *ngIf="listaFile && listaFile.length > 0"
                        id="datatables"
                        class="table table-striped table-no-bordered table-hover table-responsive"
                        cellspacing="0"
                        width="100%"
                        style="width: 100%"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th
                              (click)="ordinaArray('nome')"
                              style="cursor: pointer"
                            >
                              Nome File
                              <i
                                class="material-icons notranslate"
                                style="font-size: 15px"
                                >unfold_more</i
                              >
                            </th>
                            <!-- <th (click)="ordinaArray('categoria')" style="cursor: pointer;">
                                                            Categoria <i class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i></th> -->
                            <!-- <th (click)="ordinaArray('gruppo')" style="cursor: pointer;">
                                                            Gruppo <i class="material-icons notranslate"
                                                                style="font-size: 15px;">unfold_more</i></th> -->
                            <th
                              (click)="ordinaArray('data')"
                              style="cursor: pointer"
                            >
                              Data Upload
                              <i
                                class="material-icons notranslate"
                                style="font-size: 15px"
                                >unfold_more</i
                              >
                            </th>
                            <th
                              (click)="ordinaArray('estensione')"
                              style="cursor: pointer"
                            >
                              Estensione
                              <i
                                class="material-icons notranslate"
                                style="font-size: 15px"
                                >unfold_more</i
                              >
                            </th>

                            <th class="text-right">Azioni</th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th style="width: 20px">#</th>
                            <th>Nome File</th>
                            <th>Data Upload</th>
                            <th>Estensione</th>

                            <th class="text-right">Azioni</th>
                          </tr>
                        </tfoot>
                        <tbody>
                          <tr *ngFor="let file of listaFile; let i = index">
                            <ng-container
                              *ngIf="
                                i >= selectedValue * (pagina - 1) &&
                                i < selectedValue * pagina
                              "
                            >
                              <td style="font-size: xx-small">
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'docx' ||
                                    file.extension === 'txt' ||
                                    file.extension === 'doc' ||
                                    file.extension === 'pdf' ||
                                    file.extension === 'odt' ||
                                    file.extension === 'ods' ||
                                    file.extension === 'odp' ||
                                    file.extension === 'odb' ||
                                    file.extension === 'pdf' ||
                                    file.extension === 'xls' ||
                                    file.extension === 'xlsx'
                                  "
                                  >library_books</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'mp3' ||
                                    file.extension === 'ogg' ||
                                    file.extension === 'wav'
                                  "
                                  >music_video</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'png' ||
                                    file.extension === 'jpg' ||
                                    file.extension === 'bmp' ||
                                    file.extension === 'gif' ||
                                    file.extension === 'psd'
                                  "
                                  >insert_photo</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'avi' ||
                                    file.extension === 'mpeg' ||
                                    file.extension === 'wmv' ||
                                    file.extension === 'mp4' ||
                                    file.extension === '3gp' ||
                                    file.extension === 'flv' ||
                                    file.extension === 'mkv'
                                  "
                                  >videocam</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'exe' ||
                                    file.extension === 'com' ||
                                    file.extension === 'bat' ||
                                    file.extension === 'cmd'
                                  "
                                  >computer</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'htm' ||
                                    file.extension === 'html' ||
                                    file.extension === 'shtml' ||
                                    file.extension === 'shtm' ||
                                    file.extension === 'stm' ||
                                    file.extension === 'asp' ||
                                    file.extension === 'aspx' ||
                                    file.extension === 'php' ||
                                    file.extension === 'dtw' ||
                                    file.extension === 'xml' ||
                                    file.extension === 'xps'
                                  "
                                  >code</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'zip' ||
                                    file.extension === 'rar'
                                  "
                                  >folder</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension !== 'zip' &&
                                    file.extension !== 'rar' &&
                                    file.extension !== 'exe' &&
                                    file.extension !== 'com' &&
                                    file.extension !== 'bat' &&
                                    file.extension !== 'cmd' &&
                                    file.extension !== 'png' &&
                                    file.extension !== 'jpg' &&
                                    file.extension !== 'bmp' &&
                                    file.extension !== 'gif' &&
                                    file.extension !== 'psd' &&
                                    file.extension !== 'mp3' &&
                                    file.extension !== 'ogg' &&
                                    file.extension !== 'wav' &&
                                    file.extension !== 'htm' &&
                                    file.extension !== 'html' &&
                                    file.extension !== 'shtml' &&
                                    file.extension !== 'shtm' &&
                                    file.extension !== 'stm' &&
                                    file.extension !== 'asp' &&
                                    file.extension !== 'aspx' &&
                                    file.extension !== 'php' &&
                                    file.extension !== 'dtw' &&
                                    file.extension !== 'xml' &&
                                    file.extension !== 'xps' &&
                                    file.extension !== 'pdf' &&
                                    file.extension !== 'txt' &&
                                    file.extension !== 'doc' &&
                                    file.extension !== 'docx' &&
                                    file.extension !== 'odt' &&
                                    file.extension !== 'ods' &&
                                    file.extension !== 'odp' &&
                                    file.extension !== 'odb' &&
                                    file.extension !== 'pdf' &&
                                    file.extension !== 'xls' &&
                                    file.extension !== 'xlsx' &&
                                    file.extension !== 'avi' &&
                                    file.extension !== 'wmv' &&
                                    file.extension !== 'mpeg' &&
                                    file.extension !== 'mp4' &&
                                    file.extension !== '3gp' &&
                                    file.extension !== 'flv' &&
                                    file.extension !== 'mkv'
                                  "
                                  >insert_drive_file</i
                                >
                              </td>
                              <td style="font-size: medium">
                                {{ file.title }}
                              </td>
                              <!-- <td style="font-size: medium;">{{file.category}}</td>
                                                            <td style="font-size: medium;">{{file.group}}</td> -->
                              <td style="font-size: medium">
                                {{
                                  file.uploadDate | date : "dd/MM/yyyy HH:mm"
                                }}
                              </td>
                              <td style="font-size: medium">
                                {{ file.extension }}
                              </td>

                              <td
                                class="text-right"
                                *ngIf="!fromPrenotaTecnico && !fromCR"
                              >
                                <a
                                  [href]="link + file.id"
                                  rel="tooltip"
                                  title="Download"
                                  class="btn btn-primary btn-simple"
                                  style="padding: 2px 2px 2px 2px"
                                  ><i
                                    class="material-icons pointer"
                                    style="font-size: 20px"
                                    >get_app</i
                                  ></a
                                >
                                <a
                                  *ngIf="
                                    userRole === 1 && !file.idEsercitazione
                                  "
                                  (click)="editFile(file)"
                                  rel="tooltip"
                                  title="Modifica File"
                                  class="btn btn-success btn-simple"
                                  style="padding: 2px 2px 2px 2px"
                                  ><i
                                    class="material-icons pointer"
                                    style="font-size: 20px"
                                    >edit</i
                                  ></a
                                >
                                <a
                                  (click)="visualizza(file)"
                                  rel="tooltip"
                                  title="Info File"
                                  style="padding: 2px 2px 2px 2px"
                                  class="btn btn-warning btn-simple"
                                  ><i
                                    class="material-icons pointer"
                                    style="font-size: 20px"
                                    >info</i
                                  ></a
                                >

                                <a
                                  *ngIf="isPersonal || userRole === 1"
                                  (click)="cestina(file.id)"
                                  rel="tooltip"
                                  title="Elimina File"
                                  class="btn btn-simple btn-danger btn-icon remove"
                                  style="padding: 2px 2px 2px 2px"
                                  ><i
                                    class="material-icons pointer"
                                    style="font-size: 20px"
                                    >close</i
                                  ></a
                                >
                              </td>
                              <td *ngIf="fromPrenotaTecnico" class="text-right">
                                <p-checkbox
                                  *ngIf="checkCheckbox(file) !== -1"
                                  [binary]="true"
                                  (onChange)="emitFileSelected(file)"
                                  [disabled]="disabled"
                                  [(ngModel)]="True"
                                >
                                </p-checkbox>
                                <p-checkbox
                                  *ngIf="checkCheckbox(file) === -1"
                                  [binary]="true"
                                  (onChange)="emitFileSelected(file)"
                                  [disabled]="disabled"
                                  [(ngModel)]="False"
                                >
                                </p-checkbox>
                              </td>
                              <td *ngIf="fromCR" class="text-right">
                                <button
                                  (click)="emitLink(file.id)"
                                  class="btn btn-primary"
                                >
                                  Seleziona
                                </button>
                              </td></ng-container
                            >
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!-- <div class="row">
                                        <div class="col-sm-5">
                                            <div class="dataTables_info" id="datatables_info" role="status"
                                                aria-live="polite" style="margin-top: 5%;">{{selectedValue*pagina < numeroFile?
                                                    selectedValue : numeroFile.toString()[numeroFile.toString().length - 1]}} di {{numeroFile}} file</div>
                                            </div>
                                            <div class="col-sm-7 text-right">
                                                <div class="dataTables_paginate paging_full_numbers"
                                                    id="datatables_paginate">
                                                    <ul class="pagination">
                                                        <li class="paginate_button pointer " id="datatables_first">
                                                            <a (click)="paginaUno()" aria-controls="datatables"
                                                                data-dt-idx="0" tabindex="0">Inizio</a>
                                                        </li>
                                                        <li class="paginate_button pointer " id="datatables_previous">
                                                            <a (click)="back()" aria-controls="datatables"
                                                                data-dt-idx="1" tabindex="0">Indietro</a>
                                                        </li>
                                                        <li *ngFor="let pagina of pagine; let i=index"
                                                            class="paginate_button pointer"
                                                            [class]="{active:pagina === appoggioclick}">
                                                            <a (click)="cambiaPagina(i)" aria-controls="datatables"
                                                                data-dt-idx="2" tabindex="0">{{pagina}}</a>
                                                        </li>
                                                        <li class="paginate_button pointer" id="datatables_next">
                                                            <a (click)="next()" aria-controls="datatables"
                                                                data-dt-idx="6" tabindex="0">Avanti</a>
                                                        </li>
                                                        <li class="paginate_button pointer" id="datatables_last">
                                                            <a (click)="fine()" aria-controls="datatables"
                                                                data-dt-idx="7" tabindex="0">Fine</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
---------------------------------------------------------- -->
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="col-sm-5">
                        <div
                          class="dataTables_info"
                          *ngIf="selectedValue < numeroFile"
                          id="datatables_info"
                          role="status"
                          aria-live="polite"
                          style="margin-top: 5%"
                        >
                          {{
                            selectedValue * pagina <= numeroFile
                              ? selectedValue
                              : numeroFile.toString()[
                                  numeroFile.toString().length - 1
                                ]
                          }}
                          di {{ numeroFile }} file
                        </div>
                        <div
                          class="dataTables_info"
                          *ngIf="selectedValue >= numeroFile"
                          id="datatables_info"
                          role="status"
                          aria-live="polite"
                          style="margin-top: 5%"
                        >
                          {{ numeroFile }} di {{ numeroFile }} file
                        </div>
                      </div>

                      <div class="col-sm-7 text-right giustificaDestra">
                        <app-paginator
                          *ngIf="paginator"
                          [pageSize]="appoggioPagina"
                          [(isClickable)]="isClickable"
                          (currentPageEmitter)="cambiaPagina($event)"
                          [startingPage]="pagina"
                        ></app-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--FILE CANCELLATI-->
              <div
                class="tab-pane"
                id="pill2"
                [ngClass]="{ active: activeTab === '#pill2' }"
              >
                <div class="card-title" class="flex">
                  <button
                    *ngIf="!isRootCestino"
                    (click)="indietroCartelleCestino()"
                    class="btn btn-round btn-just-icon back-button"
                    style="
                      margin-top: 5px;
                      color: rgb(54, 54, 54);
                      box-shadow: none !important;
                    "
                  >
                    <i class="material-icons notranslate">arrow_back</i>
                  </button>
                  <h3 style="display: inline">
                    {{ cartellaCorrenteCestino.nome }}
                  </h3>
                </div>
                <div *ngIf="cartellaCorrenteCestino.cartelle.length != 0">
                  <h5>Cartelle</h5>
                  <div class="grid-container">
                    <div
                      class="grid-item"
                      *ngFor="let cartella of cartellaCorrenteCestino.cartelle"
                    >
                      <div class="flex">
                        <i
                          class="material-icons notranslate flex v-center fs-12"
                          (click)="ripristinaCartella(cartella.id)"
                          >restore_from_trash</i
                        >
                        <span
                          class="ml-auto"
                          (click)="elimina(cartella.id, true)"
                          >x</span
                        >
                      </div>
                      <div *ngIf="!(userRole === 1)" class="pt-20"></div>
                      <i
                        _ngcontent-wjn-c285=""
                        class="material-icons pointer"
                        style="font-size: 20px; vertical-align: -5px"
                        >folder</i
                      >{{ cartella.nome }}
                    </div>
                  </div>
                </div>
                <h5>File</h5>
                <p-confirmDialog
                  key="ripristina"
                  #cdi
                  icon="pi pi-exclamation-triangle"
                  [style]="{ width: responsiveWidth() }"
                  [acceptLabel]="'Si'"
                  [baseZIndex]="5"
                >
                  <p-footer>
                    <button
                      [disabled]="loading"
                      type="button"
                      pButton
                      icon="pi pi-check"
                      label="Sì"
                      (click)="accettaRipristina()"
                    >
                      <span *ngIf="loading">
                        <p-progressSpinner
                          [style]="{ width: '10px', height: '10px' }"
                          strokeWidth="8"
                          styleClass="custom-spinner"
                          animationDuration=".5s"
                        ></p-progressSpinner>
                      </span>
                    </button>
                    <button
                      [disabled]="loading"
                      type="button"
                      pButton
                      icon="pi pi-times"
                      label="No"
                      (click)="cdi.reject()"
                    ></button>
                  </p-footer>
                </p-confirmDialog>
                <p-confirmDialog
                  key="ripristinaCartella"
                  #cdr
                  icon="pi pi-exclamation-triangle"
                  [style]="{ width: responsiveWidth() }"
                  [acceptLabel]="'Si'"
                  [baseZIndex]="5"
                >
                  <p-footer>
                    <button
                      [disabled]="loading"
                      type="button"
                      pButton
                      icon="pi pi-check"
                      label="Sì"
                      (click)="accettaRipristinoCartella()"
                    >
                      <span *ngIf="loading">
                        <p-progressSpinner
                          [style]="{ width: '10px', height: '10px' }"
                          strokeWidth="8"
                          styleClass="custom-spinner"
                          animationDuration=".5s"
                        ></p-progressSpinner>
                      </span>
                    </button>
                    <button
                      [disabled]="loading"
                      type="button"
                      pButton
                      icon="pi pi-times"
                      label="No"
                      (click)="cdr.reject()"
                    ></button>
                  </p-footer>
                </p-confirmDialog>
                <p-confirmDialog
                  key="cancellaFisica"
                  #cdii
                  icon="pi pi-exclamation-triangle"
                  [style]="{ width: responsiveWidth() }"
                  [acceptLabel]="'Si'"
                  [baseZIndex]="5"
                >
                  <p-footer>
                    <button
                      [disabled]="loading"
                      type="button"
                      pButton
                      icon="pi pi-check"
                      label="Sì"
                      (click)="accettaEliminaDefinitivamente()"
                    >
                      <span *ngIf="loading">
                        <p-progressSpinner
                          [style]="{ width: '10px', height: '10px' }"
                          strokeWidth="8"
                          styleClass="custom-spinner"
                          animationDuration=".5s"
                        ></p-progressSpinner>
                      </span>
                    </button>
                    <button
                      [disabled]="loading"
                      type="button"
                      pButton
                      icon="pi pi-times"
                      label="No"
                      (click)="cdii.reject()"
                    ></button>
                  </p-footer>
                </p-confirmDialog>

                <div class="material-datatables">
                  <div class="row">
                    <div class="col-sm-3 col-xs-3">
                      <div class="dataTables_length" id="datatables_length">
                        <label class="form-group"
                          >Limita Risultati
                          <select
                            [(ngModel)]="selectedValueCancellati"
                            (ngModelChange)="cambiaValueCancellati()"
                            aria-controls="datatables"
                            class="form-control input-sm"
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-9 col-xs-9">
                      <div id="datatables_filter" class="dataTables_filter">
                        <form [formGroup]="searchformCancellati">
                          <label class="form-group pull-right"
                            >Esegui una ricerca
                            <input
                              type="text"
                              formControlName="searchCancellati"
                              class="form-control"
                              placeholder="Cerca..."
                              maxlength="30"
                              style="width: 40vw"
                            />
                          </label>
                        </form>
                        <div *ngIf="searchInFileCancellati()"></div>
                      </div>
                    </div>
                  </div>
                  <div class="scrollable">
                    <p-confirmDialog
                      #cd
                      icon="pi pi-exclamation-triangle"
                      [style]="{ width: responsiveWidth() }"
                      [acceptLabel]="'Si'"
                      [baseZIndex]="5"
                    >
                      <p-footer>
                        <button
                          [disabled]="loading"
                          type="button"
                          pButton
                          icon="pi pi-check"
                          label="Sì"
                          (click)="accettaRipristina()"
                        >
                          <span *ngIf="loading">
                            <p-progressSpinner
                              [style]="{ width: '10px', height: '10px' }"
                              strokeWidth="8"
                              styleClass="custom-spinner"
                              animationDuration=".5s"
                            ></p-progressSpinner>
                          </span>
                        </button>
                        <button
                          [disabled]="loading"
                          type="button"
                          pButton
                          icon="pi pi-times"
                          label="No"
                          (click)="cd.reject()"
                        ></button>
                      </p-footer>
                    </p-confirmDialog>
                    <div class="table-responsive">
                      <p *ngIf="!fileCancellati || fileCancellati.length < 1">
                        Nessun file disponibile
                      </p>
                      <table
                        *ngIf="fileCancellati && fileCancellati.length > 0"
                        id="datatables"
                        class="table table-striped table-no-bordered table-hover"
                        cellspacing="0"
                        width="100%"
                        style="width: 100%"
                      >
                        <thead>
                          <tr>
                            <th style="width: 20px">#</th>
                            <th>Nome File</th>
                            <!-- <th>Categoria</th>
                                                            <th>Gruppo</th> -->
                            <th>Data Upload</th>
                            <th>Estensione</th>

                            <th class="text-right">Azioni</th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th style="width: 20px">#</th>
                            <th>Nome File</th>
                            <!-- <th>Categoria</th>
                                                            <th>Gruppo</th> -->
                            <th>Data Upload</th>
                            <th>Estensione</th>

                            <th class="text-right">Azioni</th>
                          </tr>
                        </tfoot>
                        <tbody>
                          <tr
                            *ngFor="let file of fileCancellati; let i = index"
                          >
                            <ng-container
                              *ngIf="
                                i >=
                                  selectedValueCancellati *
                                    (paginaCancellati - 1) &&
                                i < selectedValueCancellati * paginaCancellati
                              "
                            >
                              <td style="font-size: xx-small">
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'docx' ||
                                    file.extension === 'txt' ||
                                    file.extension === 'doc' ||
                                    file.extension === 'pdf' ||
                                    file.extension === 'odt' ||
                                    file.extension === 'ods' ||
                                    file.extension === 'odp' ||
                                    file.extension === 'odb' ||
                                    file.extension === 'pdf' ||
                                    file.extension === 'xls' ||
                                    file.extension === 'xlsx'
                                  "
                                  >library_books</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'mp3' ||
                                    file.extension === 'ogg' ||
                                    file.extension === 'wav'
                                  "
                                  >music_video</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'png' ||
                                    file.extension === 'jpg' ||
                                    file.extension === 'bmp' ||
                                    file.extension === 'gif' ||
                                    file.extension === 'psd'
                                  "
                                  >insert_photo</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'avi' ||
                                    file.extension === 'mpeg' ||
                                    file.extension === 'wmv' ||
                                    file.extension === 'mp4' ||
                                    file.extension === '3gp' ||
                                    file.extension === 'flv' ||
                                    file.extension === 'mkv'
                                  "
                                  >videocam</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'exe' ||
                                    file.extension === 'com' ||
                                    file.extension === 'bat' ||
                                    file.extension === 'cmd'
                                  "
                                  >computer</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'htm' ||
                                    file.extension === 'html' ||
                                    file.extension === 'shtml' ||
                                    file.extension === 'shtm' ||
                                    file.extension === 'stm' ||
                                    file.extension === 'asp' ||
                                    file.extension === 'aspx' ||
                                    file.extension === 'php' ||
                                    file.extension === 'dtw' ||
                                    file.extension === 'xml' ||
                                    file.extension === 'xps'
                                  "
                                  >code</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension === 'zip' ||
                                    file.extension === 'rar'
                                  "
                                  >folder</i
                                >
                                <i
                                  class="material-icons notranslate"
                                  style="font-size: 22px; color: #aaaaaa"
                                  *ngIf="
                                    file.extension !== 'zip' &&
                                    file.extension !== 'rar' &&
                                    file.extension !== 'exe' &&
                                    file.extension !== 'com' &&
                                    file.extension !== 'bat' &&
                                    file.extension !== 'cmd' &&
                                    file.extension !== 'png' &&
                                    file.extension !== 'jpg' &&
                                    file.extension !== 'bmp' &&
                                    file.extension !== 'gif' &&
                                    file.extension !== 'psd' &&
                                    file.extension !== 'mp3' &&
                                    file.extension !== 'ogg' &&
                                    file.extension !== 'wav' &&
                                    file.extension !== 'htm' &&
                                    file.extension !== 'html' &&
                                    file.extension !== 'shtml' &&
                                    file.extension !== 'shtm' &&
                                    file.extension !== 'stm' &&
                                    file.extension !== 'asp' &&
                                    file.extension !== 'aspx' &&
                                    file.extension !== 'php' &&
                                    file.extension !== 'dtw' &&
                                    file.extension !== 'xml' &&
                                    file.extension !== 'xps' &&
                                    file.extension !== 'pdf' &&
                                    file.extension !== 'txt' &&
                                    file.extension !== 'doc' &&
                                    file.extension !== 'docx' &&
                                    file.extension !== 'odt' &&
                                    file.extension !== 'ods' &&
                                    file.extension !== 'odp' &&
                                    file.extension !== 'odb' &&
                                    file.extension !== 'pdf' &&
                                    file.extension !== 'xls' &&
                                    file.extension !== 'xlsx' &&
                                    file.extension !== 'avi' &&
                                    file.extension !== 'mpeg' &&
                                    file.extension !== 'wmv' &&
                                    file.extension !== 'mp4' &&
                                    file.extension !== '3gp' &&
                                    file.extension !== 'flv' &&
                                    file.extension !== 'mkv'
                                  "
                                  >insert_drive_file</i
                                >
                              </td>
                              <td style="font-size: medium">
                                {{ file.title }}
                              </td>
                              <td style="font-size: medium">
                                {{ file.category }}
                              </td>
                              <td style="font-size: medium">
                                {{ file.group }}
                              </td>
                              <td style="font-size: medium">
                                {{
                                  file.uploadDate | date : "dd/MM/yyyy HH:mm"
                                }}
                              </td>
                              <td style="font-size: medium">
                                {{ file.extension }}
                              </td>

                              <td class="text-right">
                                <a
                                  (click)="elimina(file.id, false)"
                                  rel="tooltip"
                                  title="Elimina definitivamente"
                                  class="btn btn-simple btn-danger btn-icon remove"
                                  style="padding: 2px 2px 2px 2px"
                                  ><i
                                    class="material-icons pointer"
                                    style="font-size: 20px"
                                    >close</i
                                  ></a
                                >
                                <a
                                  (click)="ripristina(file.id)"
                                  rel="tooltip"
                                  title="Ripristina file"
                                  class="btn btn-simple btn-success btn-icon remove"
                                  style="padding: 2px 2px 2px 2px"
                                  ><i
                                    class="material-icons pointer"
                                    style="font-size: 20px"
                                    >restore_from_trash</i
                                  ></a
                                >
                              </td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- <div class="row">
                                            <div class="col-sm-5">
                                                <div class="dataTables_info" id="datatables_info" role="status"
                                                    aria-live="polite" style="margin-top: 5%;">{{selectedValueCancellati
                                                    < numeroFileCancellati? selectedValueCancellati:
                                                        numeroFileCancellati}} di {{numeroFileCancellati}} file</div>
                                                </div>
                                                <div class="col-sm-7 text-right">
                                                    <div class="dataTables_paginate paging_full_numbers"
                                                        id="datatables_paginate">
                                                        <ul class="pagination">
                                                            <li class="paginate_button pointer " id="datatables_first">
                                                                <a (click)="paginaUnoCancellati()"
                                                                    aria-controls="datatables" data-dt-idx="0"
                                                                    tabindex="0">Inizio</a>
                                                            </li>
                                                            <li class="paginate_button pointer "
                                                                id="datatables_previous">
                                                                <a (click)="backCancellati()" aria-controls="datatables"
                                                                    data-dt-idx="1" tabindex="0">Indietro</a>
                                                            </li>
                                                            <li *ngFor="let pagina of pagineCancellati; let i=index"
                                                                class="paginate_button pointer"
                                                                [class]="{active:pagina === appoggioclickCancellati}">
                                                                <a (click)="cambiaPaginaCancellati(i)"
                                                                    aria-controls="datatables" data-dt-idx="2"
                                                                    tabindex="0">{{pagina}}</a>
                                                            </li>
                                                            <li class="paginate_button pointer" id="datatables_next">
                                                                <a (click)="nextCancellati()" aria-controls="datatables"
                                                                    data-dt-idx="6" tabindex="0">Avanti</a>
                                                            </li>
                                                            <li class="paginate_button pointer" id="datatables_last">
                                                                <a (click)="fineCancellati()" aria-controls="datatables"
                                                                    data-dt-idx="7" tabindex="0">Fine</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div> -->
                  <!-- ---------------------------------------------------------- -->

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="col-sm-5">
                        <div
                          *ngIf="selectedValueCancellati < numeroFileCancellati"
                          class="dataTables_info"
                          id="datatables_info"
                          role="status"
                          aria-live="polite"
                          style="margin-top: 5%"
                        >
                          {{
                            selectedValueCancellati <= numeroFileCancellati
                              ? selectedValueCancellati
                              : numeroFileCancellati
                          }}
                          di {{ numeroFileCancellati }} file
                        </div>
                        <div
                          class="dataTables_info"
                          *ngIf="
                            selectedValueCancellati >= numeroFileCancellati
                          "
                          id="datatables_info"
                          role="status"
                          aria-live="polite"
                          style="margin-top: 5%"
                        >
                          {{ numeroFileCancellati }} di
                          {{ numeroFileCancellati }} file
                        </div>
                      </div>
                      <div class="col-sm-7 text-right giustificaDestra">
                        <app-paginator
                          *ngIf="paginatorCestino"
                          [pageSize]="appoggioPaginaCancellati"
                          [(isClickable)]="isClickableCestino"
                          (currentPageEmitter)="cambiaPaginaCancellati($event)"
                          [startingPage]="paginaCancellati"
                        >
                        </app-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane"
                *ngIf="userRole === 1 || userRole === 4"
                id="pill3"
                [ngClass]="{ active: activeTab === '#pill3' }"
              >
                <h5>File</h5>
                <p-confirmDialog
                  key="cancellaCorso"
                  #cd
                  icon="pi pi-exclamation-triangle"
                  [style]="{ width: responsiveWidth() }"
                  [acceptLabel]="'Si'"
                  [baseZIndex]="5"
                >
                  <p-footer>
                    <button
                      [disabled]="loading"
                      *ngIf="cancellaCorso"
                      type="button"
                      pButton
                      icon="pi pi-check"
                      label="Sì"
                      (click)="cancellaCorsoMethod()"
                    >
                      <span *ngIf="loading">
                        <p-progressSpinner
                          [style]="{ width: '10px', height: '10px' }"
                          strokeWidth="8"
                          styleClass="custom-spinner"
                          animationDuration=".5s"
                        ></p-progressSpinner>
                      </span>
                    </button>

                    <button
                      [disabled]="loading && cancellaFile"
                      type="button"
                      pButton
                      icon="pi pi-times"
                      label="No"
                      (click)="cd.reject()"
                    ></button>
                  </p-footer>
                </p-confirmDialog>
                <div class="material-datatables">
                  <div class="row">
                    <div class="col-sm-3 col-xs-3">
                      <div class="dataTables_length" id="datatables_length">
                        <div style="display: flex">
                          <label class="form-group"
                            >Limita Risultati
                            <select
                              [(ngModel)]="selectedValueCorsi"
                              (ngModelChange)="cambiaValueCorsi()"
                              aria-controls="datatables"
                              class="form-control input-sm"
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                            <div
                              *ngIf="isSelectingValueCorsi"
                              style="display: flex; align-items: end"
                            >
                              <div
                                class="text-center"
                                style="position: absolute; right: 0px"
                              >
                                <p-progressSpinner
                                  [style]="{ width: '20px', height: '20px' }"
                                  strokeWidth="5"
                                  styleClass="custom-spinner2"
                                  animationDuration=".5s"
                                >
                                </p-progressSpinner>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-9 col-xs-9">
                      <div id="datatables_filter" class="dataTables_filter">
                        <form [formGroup]="searchformCorso">
                          <label class="form-group pull-right"
                            >Esegui una ricerca
                            <div style="display: flex">
                              <input
                                type="text"
                                (input)="searchCorsi()"
                                formControlName="searchCorsi"
                                class="form-control"
                                placeholder="Cerca..."
                                maxlength="30"
                                style="width: 40vw"
                              />
                              <div
                                *ngIf="isSearchingOnCorsi"
                                style="display: flex; align-items: end"
                              >
                                <div
                                  class="text-center"
                                  style="position: absolute; right: 0px"
                                >
                                  <p-progressSpinner
                                    [style]="{ width: '20px', height: '20px' }"
                                    strokeWidth="5"
                                    styleClass="custom-spinner2"
                                    animationDuration=".5s"
                                  >
                                  </p-progressSpinner>
                                </div>
                              </div>
                            </div>
                          </label>
                        </form>
                        <!-- <div *ngIf="searchInCorsi()"></div> -->
                      </div>
                    </div>
                  </div>

                  <h5>File</h5>
                  <div class="scrollable">
                    <div class="table-responsive">
                      <p *ngIf="listaCorsi.length < 1">
                        Nessun corso disponibile
                      </p>
                      <table
                        *ngIf="listaCorsi.length > 0"
                        id="datatables"
                        class="table table-striped table-no-bordered table-hover table-responsive"
                        cellspacing="0"
                        width="100%"
                        style="width: 100%"
                      >
                        <thead
                          style="position: sticky; background-color: white"
                        >
                          <tr
                            style="
                              position: sticky;
                              z-index: 1;
                              background-color: white;
                            "
                          >
                            <th>#</th>
                            <th
                              (click)="ordinaArrayCorsi('nome')"
                              style="
                                cursor: pointer;
                                position: sticky;
                                z-index: 1;
                                background-color: white;
                              "
                            >
                              Nome Corso
                              <i
                                class="material-icons notranslate"
                                style="font-size: 15px"
                                >unfold_more</i
                              >
                            </th>
                            <th
                              (click)="ordinaArrayCorsi('categoria')"
                              style="
                                cursor: pointer;
                                position: sticky;
                                z-index: 1;
                                background-color: white;
                              "
                            >
                              Categoria
                              <i
                                class="material-icons notranslate"
                                style="font-size: 15px"
                                >unfold_more</i
                              >
                            </th>
                            <th
                              (click)="ordinaArrayCorsi('gruppo')"
                              style="
                                cursor: pointer;
                                position: sticky;
                                z-index: 1;
                                background-color: white;
                              "
                            >
                              Gruppo
                              <i
                                class="material-icons notranslate"
                                style="font-size: 15px"
                                >unfold_more</i
                              >
                            </th>
                            <th
                              (click)="ordinaArrayCorsi('data')"
                              style="
                                cursor: pointer;
                                position: sticky;
                                z-index: 1;
                                background-color: white;
                              "
                            >
                              Data Upload
                              <i
                                class="material-icons notranslate"
                                style="font-size: 15px"
                                >unfold_more</i
                              >
                            </th>

                            <th
                              class="text-right"
                              style="
                                position: sticky;
                                z-index: 1;
                                background-color: white;
                              "
                            >
                              Azioni
                            </th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th style="width: 20px">#</th>
                            <th>Nome Corso</th>
                            <th>Categoria</th>
                            <th>Gruppo</th>
                            <th>Data Upload</th>

                            <th class="text-right">Azioni</th>
                          </tr>
                        </tfoot>
                        <tbody>
                          <tr *ngFor="let corso of listaCorsi; let i = index">
                            <ng-container>
                              <td style="font-size: xx-small"></td>
                              <td style="font-size: medium">
                                {{ corso.nomeCorso }}
                              </td>
                              <td style="font-size: medium">
                                {{ corso.categoria }}
                              </td>
                              <td style="font-size: medium">
                                {{ corso.gruppo }}
                              </td>
                              <td style="font-size: medium">
                                {{
                                  corso.createdAt | date : "dd/MM/yyyy HH:mm"
                                }}
                              </td>
                              <td class="text-right">
                                <a
                                  (click)="editCorso(corso)"
                                  rel="tooltip"
                                  title="Modifica Corso"
                                  class="btn btn-success btn-simple"
                                  style="padding: 2px 2px 2px 2px"
                                  ><i
                                    class="material-icons pointer"
                                    style="font-size: 20px"
                                    >edit</i
                                  ></a
                                >
                                <a
                                  rel="tooltip"
                                  title="Info Corso"
                                  style="padding: 2px 2px 2px 2px"
                                  class="btn btn-warning btn-simple"
                                  (click)="infoCorso(corso)"
                                  ><i
                                    class="material-icons pointer"
                                    style="font-size: 20px"
                                    >info</i
                                  ></a
                                >
                                <a
                                  (click)="cancellaCorso(corso.id)"
                                  rel="tooltip"
                                  title="Elimina Corso"
                                  class="btn btn-simple btn-danger btn-icon remove"
                                  style="padding: 2px 2px 2px 2px"
                                  ><i
                                    class="material-icons pointer"
                                    style="font-size: 20px"
                                    >close</i
                                  ></a
                                >
                              </td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                      <div
                        class="dataTables_info"
                        id="datatables_info"
                        role="status"
                        aria-live="polite"
                        style="margin-top: 5%"
                      >
                        {{ itemsCorsi }} di
                        {{ maxItemsCorsi }}
                        Corsi
                      </div>
                    </div>
                    <div class="col-sm-7 text-right giustificaDestra">
                      <app-paginator
                        *ngIf="paginatorCorsi"
                        [pageSize]="numeroMaxPagineCorsi"
                        [(isClickable)]="isClickableCorsi"
                        (currentPageEmitter)="cambiaPaginaCorsi($event)"
                        [startingPage]="paginaCorsi + 1"
                      >
                      </app-paginator>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                *ngIf="userRole === 1 || userRole === 4"
                id="pill4"
                [ngClass]="{ active: activeTab === '#pill4' }"
              >
                <h5>File</h5>
                <p-confirmDialog
                  key="cancellaCorsoCestino"
                  #cd
                  icon="pi pi-exclamation-triangle"
                  [style]="{ width: responsiveWidth() }"
                  [acceptLabel]="'Si'"
                  [baseZIndex]="5"
                >
                  <p-footer>
                    <button
                      [disabled]="loading"
                      *ngIf="cancellaCorso"
                      type="button"
                      pButton
                      icon="pi pi-check"
                      label="Sì"
                      (click)="restoreCancellaCorsi()"
                    >
                      <span *ngIf="loading">
                        <p-progressSpinner
                          [style]="{ width: '10px', height: '10px' }"
                          strokeWidth="8"
                          styleClass="custom-spinner"
                          animationDuration=".5s"
                        ></p-progressSpinner>
                      </span>
                    </button>
                    <button
                      [disabled]="loading && cancellaFile"
                      type="button"
                      pButton
                      icon="pi pi-times"
                      label="No"
                      (click)="cd.reject()"
                    ></button>
                  </p-footer>
                </p-confirmDialog>
                <div class="material-datatables">
                  <div class="row">
                    <div class="col-sm-3 col-xs-3">
                      <div class="dataTables_length" id="datatables_length">
                        <label class="form-group"
                          >Limita Risultati
                          <select
                            [(ngModel)]="selectedValueCorsi"
                            (ngModelChange)="cambiaValueCestinoCorsi()"
                            aria-controls="datatables"
                            class="form-control input-sm"
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-9 col-xs-9">
                      <div id="datatables_filter" class="dataTables_filter">
                        <form [formGroup]="searchformCestinoCorsi">
                          <label class="form-group pull-right"
                            >Esegui una ricerca
                            <input
                              type="text"
                              (input)="searchCestinoCorsi()"
                              formControlName="searchCorsiCancellati"
                              class="form-control"
                              placeholder="Cerca..."
                              maxlength="30"
                              style="width: 40vw"
                            />
                          </label>
                        </form>
                        <!-- <div *ngIf="searchInCorsi()"></div> -->
                      </div>
                    </div>
                  </div>
                  <div class="scrollable">
                    <div class="table-responsive">
                      <p *ngIf="listaCorsiCestino.length < 1">
                        Nessun corso disponibile
                      </p>
                      <table
                        *ngIf="listaCorsiCestino.length > 0"
                        id="datatables"
                        class="table table-striped table-no-bordered table-hover table-responsive"
                        cellspacing="0"
                        width="100%"
                        style="width: 100%"
                      >
                        <!-- <thead style="position:sticky; z-index:1; background-color:white;">
                                                        <tr style="position:sticky; z-index:1; background-color:white;">
                                                            <th>#</th>
                                                            <th (click)="ordinaArrayCorsi('nome')"
                                                                style="cursor: pointer; position:sticky; z-index:1; background-color:white;">
                                                                Nome Corso <i class="material-icons notranslate"
                                                                    style="font-size: 15px;">unfold_more</i></th>
                                                            <th (click)="ordinaArrayCorsi('categoria')"
                                                                style="cursor: pointer; position:sticky; z-index:1; background-color:white;">
                                                                Categoria <i class="material-icons notranslate"
                                                                    style="font-size: 15px;">unfold_more</i></th>
                                                            <th (click)="ordinaArrayCorsi('gruppo')"
                                                                style="cursor: pointer; position:sticky; z-index:1; background-color:white;">
                                                                Gruppo <i class="material-icons notranslate"
                                                                    style="font-size: 15px;">unfold_more</i></th>
                                                            <th (click)="ordinaArrayCorsi('data')"
                                                                style="cursor: pointer; position:sticky; z-index:1; background-color:white;">
                                                                Data Upload <i class="material-icons notranslate"
                                                                    style="font-size: 15px;">unfold_more</i></th>
                                                            <th class="text-right"
                                                                style="position:sticky; z-index:1; background-color:white;">
                                                                Azioni</th>
                                                        </tr> -->
                        <thead
                          style="position: sticky; background-color: white"
                        >
                          <tr
                            style="
                              position: sticky;
                              z-index: 1;
                              background-color: white;
                            "
                          >
                            <th>#</th>
                            <th
                              style="
                                cursor: pointer;
                                position: sticky;
                                z-index: 1;
                                background-color: white;
                              "
                            >
                              Nome Corso
                            </th>
                            <th
                              style="
                                cursor: pointer;
                                position: sticky;
                                z-index: 1;
                                background-color: white;
                              "
                            >
                              Categoria
                            </th>
                            <th
                              style="
                                cursor: pointer;
                                position: sticky;
                                z-index: 1;
                                background-color: white;
                              "
                            >
                              Gruppo
                            </th>
                            <th
                              style="
                                cursor: pointer;
                                position: sticky;
                                z-index: 1;
                                background-color: white;
                              "
                            >
                              Data Upload
                            </th>

                            <th
                              class="text-right"
                              style="
                                position: sticky;
                                z-index: 1;
                                background-color: white;
                              "
                            >
                              Azioni
                            </th>
                          </tr>
                        </thead>

                        <tfoot>
                          <tr>
                            <th style="width: 20px">#</th>
                            <th>Nome Corso</th>
                            <th>Categoria</th>
                            <th>Gruppo</th>
                            <th>Data Upload</th>
                            <th class="text-right">Azioni</th>
                          </tr>
                        </tfoot>
                        <tbody>
                          <tr
                            *ngFor="
                              let corsoCancellati of listaCorsiCestino;
                              let i = index
                            "
                          >
                            <ng-container>
                              <td style="font-size: xx-small"></td>
                              <td style="font-size: medium">
                                {{ corsoCancellati.nomeCorso }}
                              </td>
                              <td style="font-size: medium">
                                {{ corsoCancellati.categoria }}
                              </td>
                              <td style="font-size: medium">
                                {{ corsoCancellati.gruppo }}
                              </td>
                              <td style="font-size: medium">
                                {{
                                  corsoCancellati.createdAt
                                    | date : "dd/MM/yyyy HH:mm"
                                }}
                              </td>
                              <td class="text-right">
                                <a
                                  (click)="
                                    cancellaCorsoCestino(
                                      corsoCancellati.id,
                                      true
                                    )
                                  "
                                  rel="tooltip"
                                  title="Elimina definitivamente"
                                  class="btn btn-simple btn-danger btn-icon remove"
                                  style="padding: 2px 2px 2px 2px"
                                  ><i
                                    class="material-icons pointer"
                                    style="font-size: 20px"
                                    >close</i
                                  ></a
                                >
                                <a
                                  (click)="
                                    cancellaCorsoCestino(
                                      corsoCancellati.id,
                                      false
                                    )
                                  "
                                  rel="tooltip"
                                  title="Ripristina file"
                                  class="btn btn-simple btn-success btn-icon remove"
                                  style="padding: 2px 2px 2px 2px"
                                  ><i
                                    class="material-icons pointer"
                                    style="font-size: 20px"
                                    >restore_from_trash</i
                                  ></a
                                >
                              </td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!-- -----------Painator Corsi Cestino---------- -->
                  <div class="row">
                    <div class="col-sm-5">
                      <div
                        class="dataTables_info"
                        id="datatables_info"
                        role="status"
                        aria-live="polite"
                        style="margin-top: 5%"
                      >
                        {{ itemsCestinoCorsi }} di
                        {{ maxItemsCestinoCorsi }} Corsi
                      </div>
                    </div>
                    <div class="col-sm-7 text-right giustificaDestra">
                      <app-paginator
                        *ngIf="paginatorCestinoCorsi"
                        [pageSize]="numeroMaxPagineCestinoCorsi"
                        [(isClickable)]="isClickableCestinoCorsi"
                        (currentPageEmitter)="cambiaPaginaCestinoCorsi($event)"
                        [startingPage]="paginaCestinoCorsi + 1"
                      >
                      </app-paginator>
                    </div>
                  </div>
                  <!-- ------------------------------- -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-content" *ngIf="loading">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="modifica">
    <app-add-edit-file
      [fileId]="idFile"
      [folderId]="cartellaCorrente.id"
      (tornaGestione)="indietro($event)"
    >
    </app-add-edit-file>
  </div>
  <div *ngIf="dettagli">
    <app-info-file
      [file]="fileSelezionato"
      (tornaGestione)="indietroDainfo($event)"
    ></app-info-file>
  </div>
  <div *ngIf="ricercaAvanzata">
    <app-ricerca-avanzata
      (tornaGestione)="indietroDaRicerca()"
      (listaFile)="listaRicercaAvanzata($event)"
    >
    </app-ricerca-avanzata>
  </div>

  <div *ngIf="infoCorsoB">
    <app-info-file
      [corso]="corso"
      (tornaGestione)="indietroDainfo($event)"
    ></app-info-file>
  </div>
  <div *ngIf="editCorsoB">
    <app-add-edit-file
      [ilCorso]="corso"
      (tornaGestione)="indietro($event)"
    ></app-add-edit-file>
  </div>
</div>

<p-confirmDialog
  key="creaCartella"
  #cdi
  icon="pi pi-exclamation-triangle"
  [style]="{ width: responsiveWidth() }"
  [acceptLabel]="'Si'"
  [baseZIndex]="5"
>
  <p-footer>
    <button
      [disabled]="loading"
      type="button"
      pButton
      icon="pi pi-check"
      label="Sì"
      (click)="accettaCreazione()"
    >
      <span *ngIf="loading">
        <p-progressSpinner
          [style]="{ width: '10px', height: '10px' }"
          strokeWidth="8"
          styleClass="custom-spinner"
          animationDuration=".5s"
        ></p-progressSpinner>
      </span>
    </button>
    <button
      [disabled]="loading"
      type="button"
      pButton
      icon="pi pi-times"
      label="No"
      (click)="cdi.reject()"
    ></button>
  </p-footer>
</p-confirmDialog>

<p-dialog
  id="modalChangeFolder"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="showAddModifyFolder"
  [closeOnEscape]="false"
  modal="true"
  [ngClass]="{ 'p-0': !alfaintes }"
>
  <ng-container *ngIf="isFinishedResizing">
    <h5 class="centered h-5">Cartella</h5>

    <div
      *ngIf="step1"
      [ngClass]="{ centered: !alfaintes }"
      class="labelChangeFolder h-5"
    >
      Nome cartella:
    </div>
    <input
      *ngIf="step1"
      type="text"
      class="form-control h-5"
      [(ngModel)]="nameFolder"
      placeholder="Inserisci Un Nome"
      maxlength="30"
      [ngClass]="{ 'width-40vw': alfaintes }"
    />

    <div *ngIf="isModifica && step1" [ngClass]="{ 'h-80': !alfaintes }">
      <div *ngIf="step1" class="h-100">
        <div class="labelChangeFolder p-top">Cambia Locazione Cartella:</div>
        <div class="p-top">{{ rememberPathFolders }}</div>
        <hr />
        <div class="scroll-auto-folder" [ngClass]="{ divFolders: !alfaintes }">
          <div *ngFor="let item of folders; let i = index">
            <div
              class="flex1200"
              *ngIf="i % 3 === 0 && item.folderName !== 'doNotShow'"
            >
              <div
                class="col-4 flex"
                [ngClass]="{ 'w-34': !alfaintes }"
                (click)="changeFolderModify(item)"
              >
                <i class="material-icons no-translate flex v-center bg-white"
                  >folder</i
                >
                <div class="one-line">
                  <span
                    class="flex v-center"
                    [ngClass]="{ 'c-red': idDaModificare == item.id }"
                    >{{ item.folderName }}</span
                  >
                </div>
              </div>
              <div
                class="col-4 flex"
                *ngIf="
                  folders[i + 1] && folders[i + 1].folderName !== 'doNotShow'
                "
                (click)="changeFolderModify(folders[i + 1])"
              >
                <i class="material-icons no-translate flex v-center bg-white"
                  >folder</i
                >
                <div class="one-line">
                  <span
                    class="flex v-center"
                    [ngClass]="{ 'c-red': idDaModificare == folders[i + 1].id }"
                    >{{ folders[i + 1].folderName }}</span
                  >
                </div>
              </div>
              <div
                class="col-4 flex"
                *ngIf="
                  folders[i + 2] && folders[i + 2].folderName !== 'doNotShow'
                "
                (click)="changeFolderModify(folders[i + 2])"
              >
                <i class="material-icons no-translate flex v-center bg-white"
                  >folder</i
                >
                <div class="one-line">
                  <span
                    class="flex v-center"
                    [ngClass]="{ 'c-red': idDaModificare == folders[i + 2].id }"
                    >{{ folders[i + 2].folderName }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex" *ngIf="userRole == 1">
          <button
            class="btn btn-secondary"
            (click)="showAddModifyFolder = false; allowClickOpenFolder = true"
          >
            Indietro
          </button>
          <button
            class="btn btn-primary ml-auto"
            (click)="step1 = false; checkIfGoToStep2()"
          >
            Avanti
          </button>
        </div>
      </div>
    </div>
    <div class="flex" *ngIf="!isModifica && step1 && userRole == 1">
      <button
        class="btn btn-secondary"
        (click)="showAddModifyFolder = false; allowClickOpenFolder = true"
      >
        Indietro
      </button>
      <button
        class="btn btn-primary ml-auto"
        (click)="step1 = false; checkIfGoToStep2()"
      >
        Avanti
      </button>
    </div>

    <div class="flex" *ngIf="userRole != 1">
      <button
        class="btn btn-secondary"
        (click)="showAddModifyFolder = false; allowClickOpenFolder = true"
      >
        Indietro
      </button>
      <button class="btn btn-primary ml-auto" (click)="createEditFolder()">
        Conferma
      </button>
    </div>

    <div *ngIf="!step1 && !loadingUser" [ngClass]="{ 'w-40vw': alfaintes }">
      <div class="grp" *ngIf="gruppi.length > 0">Seleziona gruppi:</div>
      <mat-chip-list [selectable]="true" [multiple]="true" class="example-chip">
        <mat-chip
          (click)="
            gruppo.selected = !gruppo.selected;
            pushCommand('GROUP', gruppo.id, true, gruppo.selected)
          "
          [selectable]="true"
          [selected]="gruppo.selected"
          class="example-box"
          *ngFor="let gruppo of gruppi"
        >
          {{ gruppo.nomeGruppo }}
        </mat-chip>
      </mat-chip-list>
      <div class="users">Seleziona utenti:</div>
      <table
        mat-table
        *ngIf="showTable"
        [dataSource]="listUsers"
        [ngClass]="{ 'w-100': !alfaintes }"
      >
        <ng-container matColumnDef="nome">
          <th
            class="pd12 centerVertical centered w-30"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="flex fs10" [ngClass]="{ centered: alfaintes }">
              Nome
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pd12 w-30 cpointer centered"
          >
            {{ element.nome }}
          </td>
        </ng-container>
        <ng-container matColumnDef="cognome">
          <th
            class="pd12 centerVertical w-30 centered"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="flex fs10" [ngClass]="{ centered: alfaintes }">
              Cognome
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pd12 w-30 cpointer centered"
          >
            {{ element.cognome }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ruolo">
          <th
            class="pd12 centerVertical w-30 centered"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="flex fs10" [ngClass]="{ centered: alfaintes }">
              Ruolo
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pd12 w-30 cpointer centered"
          >
            {{ element.ruolo.nomeRuolo }}
          </td>
        </ng-container>
        <ng-container matColumnDef="visualizza">
          <th
            class="pd12 centerVertical w-30 centered"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="flex fs10 centered">Visualizza</div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pd12 w-30 cpointer centered"
            [ngClass]="{ centeredCheckbox: !alfaintes }"
          >
            <p-checkbox
              [binary]="true"
              [(ngModel)]="element.visualizza"
              (onChange)="
                element.scrittura = false;
                pushCommand('USER', element.id, false, element.visualizza)
              "
            >
            </p-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="scrittura">
          <th
            class="pd12 centerVertical w-30 centered"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="flex fs10 centered">Scrittura</div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pd12 w-30 cpointer centered"
            [ngClass]="{ centeredCheckbox: !alfaintes }"
          >
            <p-checkbox
              [binary]="true"
              [(ngModel)]="element.scrittura"
              (onChange)="
                element.visualizza = true;
                pushCommand('USER', element.id, true, element.scrittura)
              "
            >
            </p-checkbox>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsSmartwatch"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsSmartwatch"
        ></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[maxUsersPaginator]"
        aria-label="Select page of users"
      ></mat-paginator>
      <div *ngIf="listUsers.length === 0">
        Non è possibile impostare i ruoli
      </div>
      <div class="flex">
        <button class="btn btn-secondary" (click)="step1 = true">
          Indietro
        </button>
        <button class="btn btn-primary ml-auto" (click)="createEditFolder()">
          Conferma
        </button>
      </div>
    </div>
    <div *ngIf="loadingUser">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
</p-dialog>

<p-dialog
  [draggable]="false"
  [resizable]="false"
  [(visible)]="editFileModal"
  [closeOnEscape]="false"
  modal="true"
>
  <h5 class="centered">File</h5>
  <input
    *ngIf="step1"
    type="text"
    class="form-control"
    [(ngModel)]="nameFile"
    placeholder="Inserisci Un Nome"
    maxlength="30"
    style="width: 40vw"
  />
  <div>
    <div>
      <div class="labelChangeFolder">Cambia Locazione File:</div>
      <div>{{ rememberPathFolders }}</div>
      <hr />
      <div class="scroll-auto-folder">
        <div *ngFor="let item of folders; let i = index">
          <div
            class="flex1200"
            *ngIf="i % 3 === 0 && item.folderName !== 'doNotShow'"
          >
            <div class="col-4 flex" (click)="changeFolderModify(item)">
              <i class="material-icons no-translate flex v-center bg-white"
                >folder</i
              >
              <div class="one-line">
                <span
                  class="flex v-center"
                  [ngClass]="{ 'c-red': idDaModificare == item.id }"
                  >{{ item.folderName }}</span
                >
              </div>
            </div>
            <div
              class="col-4 flex"
              *ngIf="
                folders[i + 1] && folders[i + 1].folderName !== 'doNotShow'
              "
              (click)="changeFolderModify(folders[i + 1])"
            >
              <i class="material-icons no-translate flex v-center bg-white"
                >folder</i
              >
              <div class="one-line">
                <span
                  class="flex v-center"
                  [ngClass]="{ 'c-red': idDaModificare == folders[i + 1].id }"
                  >{{ folders[i + 1].folderName }}</span
                >
              </div>
            </div>
            <div
              class="col-4 flex"
              *ngIf="
                folders[i + 2] && folders[i + 2].folderName !== 'doNotShow'
              "
              (click)="changeFolderModify(folders[i + 2])"
            >
              <i class="material-icons no-translate flex v-center bg-white"
                >folder</i
              >
              <div class="one-line">
                <span
                  class="flex v-center"
                  [ngClass]="{ 'c-red': idDaModificare == folders[i + 2].id }"
                  >{{ folders[i + 2].folderName }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <button
          class="btn btn-secondary"
          (click)="editFileModal = false; allowClickOpenFolder = true"
        >
          Indietro
        </button>
        <button class="btn btn-primary ml-auto" (click)="editFileService()">
          Modifica
        </button>
      </div>
    </div>
  </div>
</p-dialog>

<p-confirmDialog
  key="cestinaCartella"
  #cdik
  icon="pi pi-exclamation-triangle"
  [style]="{ width: responsiveWidth() }"
  [acceptLabel]="'Si'"
  [baseZIndex]="5"
>
  <p-footer>
    <button
      [disabled]="loading"
      type="button"
      pButton
      icon="pi pi-check"
      label="Sì"
      (click)="accettaCestinaCartella()"
    >
      <span *ngIf="loading">
        <p-progressSpinner
          [style]="{ width: '10px', height: '10px' }"
          strokeWidth="8"
          styleClass="custom-spinner"
          animationDuration=".5s"
        ></p-progressSpinner>
      </span>
    </button>
    <button
      [disabled]="loading"
      type="button"
      pButton
      icon="pi pi-times"
      label="No"
      (click)="cdik.reject()"
    ></button>
  </p-footer>
</p-confirmDialog>
