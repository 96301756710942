import { Component, OnInit } from '@angular/core';
import { IOTService } from '../serviceIOT.service';

@Component({
  selector: 'app-iot-modular',
  templateUrl: './iot-modular.component.html',
  styleUrls: ['./iot-modular.component.scss']
})
export class IotModularComponent implements OnInit {

  constructor(public iotService: IOTService) { }

  ngOnInit(): void {
  }


}
