import { RouteConfigLoadEnd } from '@angular/router';
import { ruolo } from './ruolo';
import { Id_gruppo } from './id_gruppo';

export class Utente {
    id?: string;
    nome?: string;
    cognome?: string;
    email?: string;
    telefono?: string;
    attivo?: number;
    token?: string;
    ruolo?: ruolo;
    gruppos?: [Id_gruppo];
    password? : string;
    latitudine?: number;
    longitudine?: number;
    capcomune?: any;
    dettaglio?: [];
    presenza? : boolean;
    relatore? : boolean;
    userNonInCr?: boolean;
    tracker?: boolean;
    user?: any;
    counter?: any;
}
