import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { AlertService, UsersService } from 'src/app/SERVICES';
import { CampagneService } from 'src/app/SERVICES/FARMACIE/campagne';

@Component({
  selector: 'app-campagne-add',
  templateUrl: './campagne-add.component.html',
  styleUrls: ['./campagne-add.component.scss'],
})
export class CampagneAddComponent implements OnInit {
  @Output() backEmit = new EventEmitter<any>();
  @ViewChild('stepper') private myStepper: MatStepper;
  @Input() campagnaModel = {
    id: null,
    startDate: '',
    endDate: '',
    listaTarget: [],
    listaQuestionari: [],
  };
  @Input() isInfo = false;
  startDate;
  True = true;
  currentStep = 0;
  False = false;
  listaQuestionariSelected = [];
  refreshView = true;
  maxPagine;
  indexStepper = 0;
  listaQuestionario;
  isClickableQuestionari = true;
  endDate;
  domandeAssociate = '';
  isModifica = false;
  target = '';
  listaTarget;
  numeroMinimoChiamate = 0;
  constructor(
    private alertService: AlertService,
    private service: CampagneService,
    private userService: UsersService
  ) {
    this.userService.getTarget().subscribe((res) => {
      this.listaTarget = res.data;
    });
  }

  ngOnInit(): void {
    if(this.campagnaModel.id !== null) {
      this.isModifica = true;
    }
    this.listaQuestionariSelected = this.campagnaModel.listaQuestionari;
  }

  saveCampagna() {
    let obj: any = {};
    try {
      obj.data_Inizio = this.getDateToBEFormat(this.campagnaModel.startDate);
      obj.data_Fine = this.getDateToBEFormat(this.campagnaModel.endDate);
    } catch (error) {
      this.alertService.error('Formato date non valido');
      return;
    }
    let checkError = false;
    obj.listaQuestionari = this.listaQuestionariSelected;
    obj.listaTarget = this.campagnaModel.listaTarget;
    obj.listaTarget.forEach((element, index) => {
      if (
        element.chiamataTarget === null ||
        element.chiamataTarget === undefined
      )
        checkError = true;
      obj.listaTarget[index].idTarget = obj.listaTarget[index].id;
      // obj.listaTarget[index].id = undefined;
    });
    if (checkError) {
      this.alertService.error('inserisci almeno un limite chiamate per target');
      return;
    }
    // obj.listaQuestionari = this.campagnaModel.listaQuestionari;
    obj.listaTarget = this.campagnaModel.listaTarget;
    if (!this.isModifica)
    this.service.inserisciFarmacia(obj).subscribe((res) => {
      this.alertService.success('Campagna Inserita con successo');
      this.backEmit.emit();
    });
    if(this.isModifica){
      obj.id = this.campagnaModel.id;
      this.service.modificaFarmacia(obj).subscribe((res) => {
        this.alertService.success('Campagna Inserita con successo');
        this.backEmit.emit();
      });
    }
  }

  getDateToBEFormat(date) {
    if (date) {
      try {
        return (
          date.getFullYear() +
          '-' +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + date.getDate()).slice(-2)
        );
      } catch (error) {
        return date;
      }
    }
    return undefined;
  }
  goBack() {
    this.backEmit.emit();
  }
  canGoToStep2() {
    if (
      this.campagnaModel.startDate !== '' &&
      this.campagnaModel.endDate !== '' &&
      this.campagnaModel.listaTarget.length > 0
    ) {
      return true;
    }
    return false;
  }

  canGoToStep3() {
    if (this.listaQuestionariSelected.length > 0) return true;
    return false;
  }

  errorePage() {
    if (this.currentStep === 0)
      if (!this.canGoToStep2()) {
        this.alertService.error('Inserire tutti i dati');
        return false;
      }
    if (this.currentStep === 1)
      if (!this.canGoToStep3()) {
        this.alertService.error('Inserire almeno un questionario');
        return false;
      }
    return true;
  }
  showedError = false;
  showError(event) {
    if (!this.showedError) {
      if (this.errorePage()) {
        this.showedError = false;
        if (event.previousIndex === 0) {
          this.listaQuestionariSelected = [];
        }
        if (event.selectedIndex === 1) {
          let listaId = [];
          this.campagnaModel.listaTarget.forEach((element) => {
            listaId.push(element.id);
          });
          this.refreshView = false;
          this.userService
            .getQuestionariPerTarget(0, listaId)
            .subscribe((res) => {
              if(res.data.pageList.length===0) {
                this.alertService.error('Nessun questionario per questo target');
                this.myStepper.previous()
              }
              this.listaQuestionario = res.data.pageList;
              this.maxPagine = res.data.pageCount;
              this.refreshView = true;
            });
        }
      } else {
        setTimeout(()=>{this.showedError=true;this.myStepper.previous();},1)
      }
    } else {
      this.showedError = false;
    }
  }
  compareItems(i1, i2) {
    return i1 && i2 && i1.id === i2.id;
  }
  cambiaPaginaQuestionari(event) {
    this.isClickableQuestionari = false;
    let listaId = [];
    this.campagnaModel.listaTarget.forEach((element) => {
      listaId.push(element.id);
    });
    this.paginaCorrente = event;
    this.refreshView = false;
    this.userService
      .getQuestionariPerTarget(event, listaId, this.ricercaQuestionario)
      .subscribe((res) => {
        this.listaQuestionario = res.data.pageList;
        this.maxPagine = res.data.pageCount;
        this.refreshView = true;
        this.isClickableQuestionari = true;
      });
  }
  aggiungiQuestionario(motivo) {
    this.listaQuestionariSelected.push(motivo);
  }

  rimuoviQuestionario(motivo) {
    this.listaQuestionariSelected.splice(
      this.listaQuestionariSelected.indexOf(motivo),
      1
    );
  }
  checkBoxChecked(motivo) {
    let index = false;
    this.listaQuestionariSelected.forEach((element) => {
      if (element.id === motivo.id) index = true;
    });
    return index;
  }
  subscription
  paginaCorrente = 0;
  ricercaQuestionario = '';
  search() {
    let listaId = [];
    this.refreshView = false;
    this.campagnaModel.listaTarget.forEach((element) => {
      listaId.push(element.id);
    });
    if(this.subscription) this.subscription.unsubscribe();
    this.subscription = this.userService.getQuestionariPerTarget(this.paginaCorrente,listaId,this.ricercaQuestionario).subscribe((res) => {
      this.listaQuestionario = res.data.pageList;
      this.maxPagine = res.data.pageCount;
      this.refreshView = true;
      this.isClickableQuestionari = true;
    });
  }
}
