<div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <form
              [formGroup]="newForm"
              (ngSubmit)="onSubmit()"
              class="form-horizontal"
            >
              <div class="card-header card-header-text">
                <h4 class="card-title text-center">Crea un nuovo assitito</h4>
              </div>
  
              <div class="row">
                <label class="col-sm-3 label-on-left"
                  >Nome<span class="colorRed">*</span></label
                >
                <div class="col-sm-9">
                  <div class="form-group label-floating is-empty w-98">
                    <label class="control-label"></label>
                    <input
                      type="text"
                      formControlName="nome"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.nome.errors }"
                      maxlength="30"
                    />
                    <div
                      *ngIf="submitted && f.nome.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.nome.errors.required">
                        Campo nome obbligatorio
                      </div>
                      <div *ngIf="f.nome.errors.pattern">
                        Campo nome non valido
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-3 label-on-left"
                  >Cognome<span class="colorRed">*</span></label
                >
                <div class="col-sm-9">
                  <div class="form-group label-floating is-empty w-98">
                    <label class="control-label"></label>
                    <input
                      type="text"
                      formControlName="cognome"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && f.cognome.errors
                      }"
                      maxlength="30"
                    />
                    <div
                      *ngIf="submitted && f.cognome.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.cognome.errors.required">
                        Campo cognome obbligatorio
                      </div>
                      <div *ngIf="f.cognome.errors.pattern">
                        Campo cognome non valido
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              
              <div class="row">
                <label class="col-sm-3 label-on-left"
                  >Codice fiscale</label
                >
                <div class="col-sm-9">
                  <div class="form-group label-floating is-empty w-98">
                    <label class="control-label"></label>
                    <input
                      type="text"
                      formControlName="codiceFiscale"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.codiceFiscale.errors }"
                    />
                    <div
                      *ngIf="submitted && f.codiceFiscale.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.codiceFiscale.errors.pattern">
                        Campo codice fiscale non valido
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="row">
                <label class="col-sm-3 label-on-left">Età</label>
                <div class="col-sm-9">
                  <div class="form-group label-floating is-empty w-98">
                    <label class="control-label"></label>
                    <input
                      type="number"
                      formControlName="eta"
                      class="form-control"
                      min="0"
                    />
                  </div>
                </div>
              </div>
  
              <div class="row">
                <label class="col-sm-3 label-on-left">Sesso</label>
                <div class="col-sm-9">
                  <div class="form-group label-floating is-empty w-98">
                    <label class="control-label"></label>
                    <select formControlName="sesso" class="form-control">
                      <option value="maschio">Maschio</option>
                      <option value="femmina">Femmina</option>
                    </select>
                  </div>
                </div>
              </div>
  
              <div class="row">
                <label class="col-sm-3 label-on-left">Città</label>
                <div class="col-sm-9">
                  <div class="form-group label-floating is-empty w-98">
                    <label class="control-label"></label>
                    <select
                      formControlName="citta"
                      class="form-control"
                    >
                    <option *ngFor="let city of arrCitta" [value]="city">{{ city }}</option> 
                    </select>
                  </div>
                </div>
              </div>

              <br />

              <div class="text-right" style="margin-right: 1%">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    (click)="indietroDaCreazione()"
                    class="btn btn-secondary"
                    aria-pressed="true"
                    [disabled]="loading"
                  >
                    Annulla
                  </button>
                </div>
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                  aria-pressed="true"
                >
                  <button
                    [disabled]="loading"
                    type="submit"
                    class="btn btn-primary"
                  >
                    Salva assistito
                    <span *ngIf="loading"
                      ><p-progressSpinner
                        [style]="{ width: '10px', height: '10px' }"
                        strokeWidth="8"
                        styleClass="custom-spinner"
                        animationDuration=".5s"
                      ></p-progressSpinner
                    ></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  