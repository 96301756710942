import { Component, DoCheck, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AccountService, AlertService, ShareService, UsersService } from './SERVICES';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Utente } from './MODELS/USER/utente';
import { WebSocketService } from './SERVICES/GENERAL/socket-client.service';
import { Message } from 'ng-chat';
import { Notifica } from './MODELS/USER/notifica';
import { OperatorService } from './SERVICES/ASSISTENZA/operator.service';
import { EnvService } from './SERVICES/GENERAL/env.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IotService } from './SERVICES/IOT/iot.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LavagnaService } from './SERVICES/CLASSROOM/lavagna.service';
import { CallRateService } from './SERVICES/CLASSROOM/callRate.service';
import { Sondaggio } from './MODELS/CLASSROOM/sondaggio';
import { Location } from '@angular/common';
import { CallCenterService } from './SERVICES/FARMACIE/callCenter';
import { GoToClassroomService } from './SERVICES/CLASSROOM/go-to-classroom.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ConfirmationService]

})
export class AppComponent implements OnInit, DoCheck, AfterViewInit {

  @ViewChild('modale') modale: ElementRef<HTMLElement>;
  @ViewChild('closeModal') closeModal: ElementRef<HTMLElement>;
  title = 'GAP';
  deviceInfo = null;
  finishedCollapsing = false;
  showButton768 = true;
  isMobile = false;
  isTablet = false;
  isDesktopDevice = false;
  showMenu = false;
  isClickPannello = true;
  login;
  showNavbarMobile = true;
  msgs: Message[] = [];
  open = false;
  user?: Utente;
  chatbotOpen?: boolean;
  chatliveOpen?: boolean;
  email?: string;
  whiteList = [];
  stompClient;
  listaCanali = [];
  listaNotifiche: Notifica[] = []
  numeroNotifiche;
  nomeUtente;
  ruolo;
  acque;
  ifep;
  nabacom;
  ipem;
  MOBILEVIEW;
  cr;
  clienti;
  chatbot;
  geo;
  iot;
  viewer360;
  classroom;
  dms;
  viewer;
  newsletter;
  retrievedImage;
  base64Data: any;
  retrieveResonse: any;
  colore;
  coloreCaricato = false;
  applica = false;
  apriChatMsg;
  firstTime = true;
  classroomId;
  oldClassroomId;
  subscription;
  subscription2;
  alertControlRoom = false;
  idUtenteChiamante;
  idDispositivo;
  displayAlarm: boolean = false;
  listaAllarmi = [];
  paginaAllarmi = false;
  familyUtente = []
  famigliaCaricata = false;
  menuLat = false;
  varMenu = false;
  liveChat;
  audio: HTMLAudioElement = new Audio('./assets/sounds/iphone_7.mp3');
  //LAVAGNA
  subscriptionWhiteboard?: Subscription | null = null
  subscriptionidLavagna?: Subscription | null = null
  isSocketWhiteboardActive: boolean = false
  subScribeIdLavagnaActive: boolean = false
  /**
   * per il css
   */
  versione
  lavagna = false
  socketAttiva = false
  lastUrl = '';
  selectedFeedback = false;
  riconnessioneWs = false;
 // userSubject: BehaviorSubject<Utente> = new BehaviorSubject<Utente>() #OPV
  afi
  alfaCallCenter;
  ambiente
  isFirstTimeClosinSideMenu = true;
  cmsAfi
  spegniPerloginSilente = false;
  novamaint
  prenotazioneTecnico: boolean = false;
  constructor(
    private shareService: ShareService,
    private accountService: AccountService,
    private alertService: AlertService,
    private router: Router,
    private webSocketService: WebSocketService,
    private primengConfig: PrimeNGConfig,
    private userService: UsersService,
    private operatorService: OperatorService,
    private env: EnvService,
    private deviceService: DeviceDetectorService,
    private confirmationService: ConfirmationService,
    private iotService: IotService,
    private lavagnaService: LavagnaService,
    private crService: CallRateService,
    private _Activatedroute: ActivatedRoute,
    private loc: Location,
    private callCenterService: CallCenterService,
    private goToService:GoToClassroomService
  ) {
    /*
    this.goToService.log(window.location.host,"appcomponente-prima-startWith")
    if(window.location.href.startsWith(`http://${window.location.host}/access_token_go_to`)) {
     //2 Passo gotowebinar ci reindirizza a questa url in alto qui
     //con essa ci allega il code che lo salvo nel service 
     //visto che sono info utili e avvio il component per la chiamata al BE con i dati 
     //forniti da gotowebinar, utili per la creazione del token.
      const uri = location.href.split('?code=');
      this.goToService.log(uri.toString()+"||"+location.href,"appcomponent-dentro-startWith")
      this.router.navigate([`/access_token_go_to`,uri[1]]);
    }
    */
    if(localStorage.getItem('showNavbarMobileIot') === 'activated') {
      this.showNavbarMobile = false;
    }
    this.ambiente = environment;
    this.afi = this.env.alfaintes;
    let token = this._Activatedroute.snapshot.paramMap.get("token");
    if (this.afi) {
      this.cmsAfi = this.env.CMSAFI;
    }
    if (token) {
      const v = localStorage.getItem('user') || ""
      let userCheck = JSON.parse(v) 
      if (userCheck && userCheck.data.nome) {
        this.spegniPerloginSilente = true;
        localStorage.setItem('loginSilente', 'true')
        this.loc.replaceState('')
        location.reload();
      } else {
        this.spegniPerloginSilente = true;
        localStorage.removeItem('user')
        let user: { 'data': { 'token': String } } = { 'data': { 'token': token } };
        localStorage.setItem('user', JSON.stringify(user));
        this.accountService.controllo().subscribe((res) => {
          localStorage.removeItem('user')
          localStorage.setItem('user', JSON.stringify(res));
          localStorage.setItem('loginSilente', 'true')
          this.spegniPerloginSilente = false;
          this.loc.replaceState('')
          location.reload();
        }, (error) => {
          localStorage.removeItem('user')
          this.loc.replaceState('')
          this.spegniPerloginSilente = false;
          location.reload();
        })
      }
    }
    if (localStorage.getItem('loginSilente') == 'true') {
      setTimeout(() => {
        this.spegniPerloginSilente = true;
        localStorage.removeItem('loginSilente')
        this.router.navigate(['/education'])
        this.spegniPerloginSilente = false;
      }, 1000);
    }
    if (location.hash === '#/lavagna') {
      this.lavagna = true;
    }
    this.router.events.subscribe((res) => {
      if (res instanceof NavigationStart) {
        this.closeSidebar();
        this.menuLat = false;
      }
    })
    this.shareService.feedback$.subscribe(x => this.newRoute())
    this.accountService.user.subscribe(x => this.user = x);
    this.shareService.chatbotOpen$.subscribe((res) => this.chatbotOpen = res);
    this.shareService.chatliveOpen$.subscribe((res) => this.chatliveOpen = res);
    this.shareService.idDispositivo$.subscribe((res) => this.idDispositivo = res);
    this.shareService.closeLateralMenu$.subscribe((res) => {
      if (!this.menuLat && !this.isFirstTimeClosinSideMenu)
        this.tastoLeft()
      this.isFirstTimeClosinSideMenu = false;
    });
    this.shareService.paginaAllarmi$.subscribe((res) => {
      setTimeout(() => {
        this.paginaAllarmi = res;
      });
    })
    //CARICA COLORE NAVBAR SU INIZIALIZZAZIONE
    this.colore = localStorage.getItem('colore');
    this.userService.coloreScelto().subscribe((res) => {
      this.colore = res.data.valore;
      localStorage.setItem('colore', this.colore);
    })
    //APPLICA COLORE NUOVO DOPO LA SCELTA
    this.shareService.applicaColore$.subscribe((res) => {
      if (res) {
        this.userService.coloreScelto().subscribe((res) => {
          this.colore = res.data.valore;
          localStorage.setItem('colore', this.colore);
        })
      }
    })
    //MOSTRA PROVE DI COLORE
    this.shareService.provaColore$.subscribe((colore) => {
      if (colore) {
        this.colore = colore;
      }
    })
    //RECUPERA IMMAGINE
    this.userService.prendiImmagine().subscribe((res) => {
      if (res.data) {
        this.retrieveResonse = res.data;
        this.base64Data = this.retrieveResonse.picByte;
        this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
        this.shareService.changePassaImmagine(this.retrievedImage);
      }
      else {
        this.retrievedImage = null;
        this.shareService.changePassaImmagine(this.retrievedImage);
      }
    })
    //CAMBIA IMMAGINE
    this.shareService.cambiaImmagine$.subscribe((res) => {
      if (res) {
        this.userService.prendiImmagine().subscribe((res) => {
          if (res.data) {
            this.retrieveResonse = res.data;
            this.base64Data = this.retrieveResonse.picByte;
            this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
            this.shareService.changePassaImmagine(this.retrievedImage);
          }
          else {
            this.retrievedImage = null;
            this.shareService.changePassaImmagine(this.retrievedImage);
          }
        })
      }
    })
    //FINE PERSONALIZZAZIONE
    if (this.accountService.userValue) {
      this.accountService.controllo().subscribe((res) => {
      },
      (error)=>{
        localStorage.removeItem('user');
        this.router.navigate(['/account/login']);
      })
      this.accountService.getListaEnv().subscribe((res) => {
        this.nomeUtente = this.accountService.userValue.data.nome + ' ' + this.accountService.userValue.data.cognome;
        this.ruolo = this.accountService.ruolo;
        this.shareService.isLoggedIn$.subscribe((res) => this.login = res);
        this.acque = this.env.acque;
        this.ifep = this.env.ifep;
        this.nabacom = this.env.nabacom;
        this.ipem = this.env.ipem;
        this.MOBILEVIEW = this.env.MOBILEVIEW;
        this.classroom = this.env.CLASSROOM;
        this.dms = this.env.DMS;
        this.cr = this.env.CR;
        this.geo = this.env.GEO;
        this.iot = this.env.IOT;
        this.clienti = this.env.CLIENTI;
        this.prenotazioneTecnico = this.env.EVENTI;
        this.viewer360 = this.env.VIEWER360;
        this.chatbot = this.env.CHATBOT;
        this.newsletter = this.env.NEWSLETTER;
        this.liveChat = this.env.LIVECHAT;
        this.novamaint= this.env.novamaint;
        this.deviceFunction();
        this.alfaCallCenter = this.env.ALFACALLCENTER;
        if (this.accountService.userValue && this.alfaCallCenter) {
          this.callCenterService.getProssimaChiamata().subscribe((res)=>{
            if (res && res.data) {
              if (res.data.chiamataInizio)
                this.callCenterService.orarioProssimaChiamata = new Date(res.data.chiamataInizio);
              this.callCenterService.idProssimaChiamata = res.data.id;
              this.callCenterService.idFarmacia = res.data.farmacia.id;
              this.callCenterService.idCampagna = res.data.idCampagna;
            }
          })
        }
      })
      this.iotService.getFamily().subscribe((res) => {
        this.famigliaCaricata = true;
        if (res) {
          if (this.iot && this.ruolo != 1) {
            this.familyUtente = res.data;
          }
        }
      })
    }
  }

  ngAfterViewInit(): void {
    this.finishedCollapsing = true;
  }

  ngOnInit(): void {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          if (!!event.url && event.url === 'http://localhost:4200/access_token_test') {
            location.href='http://localhost:4200/#/access_token_test';
          }
        }
      });
    this.alertService.isComingFromLogin = false;
    document.title = this.ambiente.AMBIENTE
    let link: any = document.querySelector("link[rel~='icon']");
    if (this.afi) {
      link.href = "./assets/favicon/alfaintes.ico"
    }

    localStorage.setItem('apiUrl', this.env.apiUrl + '/');

    this.primengConfig.ripple = true;
    let this2 = this;
    document.addEventListener('click', function (event) {
      let ignoreClickOnMeElement = document.getElementsByClassName('fix768');
      let isClickInsideElement;
      let isClickInsideElement1;
      if (ignoreClickOnMeElement && ignoreClickOnMeElement[0])
        isClickInsideElement = ignoreClickOnMeElement[0].contains(event.target as Node);
      if (ignoreClickOnMeElement && ignoreClickOnMeElement[1])
        isClickInsideElement1 = ignoreClickOnMeElement[1].contains(event.target as Node);
      if (!isClickInsideElement && !isClickInsideElement1) {
        this2.showButton768 = true;
      }
    })
    this.versione = this.env.versione;
    console.log('Versione Fe:' + this.env.versione);
    this.primengConfig.setTranslation({
      accept: 'Accetta',
      dayNames: ["Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
      dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
      monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
      monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
      today: 'Oggi',
      clear: 'Cancella',
      // dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
      //translations
    });
    if(localStorage.getItem('path')==='/chatBot') {
      setTimeout(()=>{this.router.navigate([localStorage.getItem('path')])},1000)
    }
  }

  ngDoCheck(): void {
    this.numeroNotifiche = this.listaNotifiche.length;
    this.classroomId = localStorage.getItem('classroom');
    setTimeout(() => {
      if (!this.riconnessioneWs) {
        if (this.stompClient && !this.stompClient?.connected) {
          this.webSocketService.attivo = false;
        }
      }
    }, 20000);

    if (this.user && this.famigliaCaricata && !this.webSocketService.attivo) {
      this.riconnessioneWs = true;
      this.stompClient = this.webSocketService.connect();
      this.stompClient.connect({}, frame => {
        this.riconnessioneWs = false;
        this.operatorService.aperturaWebsocket().subscribe((res) => { })
        // Subscribe to notification topic
        if (this.accountService.userValue.data.id) {
          this.stompClient.subscribe(`/topic/operatore/${this.accountService.userValue.data.id}`, (res) => {
            this.email = res.body;
            if(this.email == undefined)return
            this.shareService.changeWhiteList(this.email)
            this.shareService.changeWhiteList(null)
          })
          this.stompClient.subscribe(`/topic/user/${this.accountService.userValue.data.id}`, (res) => {
            this.alertService.info(res.body);
            //L'OPERATORE HA CONSENTITO L'ACCESSO
            let flag = true;
            let notifica: Notifica = {};
            notifica.notifica = res.body;
            notifica.tipo = 'controlRoom';
            this.listaNotifiche.forEach(element => {
              if (element.notifica === notifica.notifica) {
                flag = false;
              }
            })
            if (flag) {
              this.listaNotifiche.unshift(notifica)
            }
          })
          this.stompClient.subscribe(`/topic/call/${this.accountService.userValue.data.id}`, (res) => {
            this.alertService.info(res.body)
            //LA CONTROL ROOM E' LIBERA
            let flag = true;
            let notifica: Notifica = {};
            notifica.notifica = res.body;
            notifica.tipo = 'controlRoom';
            this.listaNotifiche.forEach(element => {
              if (element.notifica === notifica.notifica) {
                flag = false;
              }
            })
            if (flag) {
              this.listaNotifiche.unshift(notifica)
            }
          })

          this.stompClient.subscribe(`/topic`, (res) => {
            try{
            const msg = res.body as string
            if(msg.includes("token")){
              if(this.accountService.ruolo==1){
                this.alertService.info(res.body)
                return
              }
            }
          }catch(e){
            
          }
            this.alertService.info(res.body)
            let flag = true;
            let notifica: Notifica = {};
            notifica.notifica = res.body;
            notifica.tipo = 'controlRoom';
            this.listaNotifiche.forEach(element => {
              if (element.notifica === notifica.notifica) {
                flag = false;
              }
            })
            if (flag) {
              this.listaNotifiche.unshift(notifica)
            }
          })
          if (this.accountService.ruolo === 2) {
            this.stompClient.subscribe(`/topic/noControlRoom`, (res) => {
              if (res.body) {
                let listaID = []
                let resp: any = {};
                let flag = true;
                resp = JSON.parse(res.body)
                resp.forEach(response => {
                  listaID.push(response.id)
                  if (!this.firstTime) {
                    this.alertService.success("L'utente " + response.nomeUtente + " necessita assistenza per " + response.motivoChiamata)
                    this.audio.loop = true;
                    this.audio.play();
                  }
                  let notifica: Notifica = {};
                  notifica.tipo = 'controlRoomAssistenza'
                  notifica.notifica = "L'utente " + response.nomeUtente + " necessita assistenza per " + response.motivoChiamata;
                  notifica.idChiamante = response.idChiamante;
                  this.listaNotifiche.forEach(element => {
                    if (element.notifica === notifica.notifica) {
                      flag = false;
                    }
                  })
                  if (flag) {
                    this.listaNotifiche.unshift(notifica)
                  }
                });
                if (listaID) {
                  setTimeout(() => {
                    this.firstTime = false;
                    this.operatorService.letturaNotifica(listaID).subscribe((res) => { })
                  }, 5000);
                }
              }
            })
          }

          //EMBEDDED
          if (this.iot) {
            this.stompClient.subscribe(`/topic/device`, (res) => {
              if (this.idDispositivo) {
                this.subscription2 = this.stompClient.subscribe(`/topic/device/${this.idDispositivo}`, (res) => {
                  this.shareService.changeEmbedded(JSON.parse(res.body))
                })
              }
              else {
                if (this.subscription2) {
                  this.subscription2.unsubscribe();
                  this.shareService.changeEmbedded(null);
                }
              }
            })

            if (this.ruolo == 1) {
              this.stompClient.subscribe(`/topic/device/allarmi`, (res) => {
                let messaggio = JSON.parse(res.body)
                if (this.paginaAllarmi) {
                  this.shareService.changeAllarme(messaggio)
                }
                if (!this.paginaAllarmi) {
                  if (messaggio.emergenza) {
                    this.listaAllarmi.push(messaggio.emergenza)
                  }
                  else {
                    this.listaAllarmi.push(messaggio.monitoring)
                  }
                  if (!this.displayAlarm) {
                    this.showAllarmi();
                  }
                }
              })
            }

            else {
              if (this.familyUtente) {
                this.familyUtente.forEach(dispositivo => {
                  this.stompClient.subscribe(`/topic/device/allarmi/${dispositivo}`, (res) => {
                    let messaggio = JSON.parse(res.body)
                    if (this.paginaAllarmi) {
                      this.shareService.changeAllarme(messaggio)
                    }
                    if (!this.paginaAllarmi) {
                      if (messaggio.emergenza) {
                        this.listaAllarmi.push(messaggio.emergenza)
                      }
                      else {
                        this.listaAllarmi.push(messaggio.monitoring)
                      }
                      if (!this.displayAlarm) {
                        this.showAllarmi();
                      }
                    }
                  })
                })
              }
            }
          }

          //CHAT
          this.stompClient.subscribe('/topic/chat', (res) => {
            let chat: any = {};
            chat = JSON.parse(res.body)
            if (!(this.listaCanali.includes(chat.canale))) {
              let idUtente = this.accountService.userValue.data.id;
              let index = 0;
              if (chat.id.indexOf(idUtente) === 0) {
                index = 1;
              }
              let array: number[] = [];
              let userId = this.accountService.userValue.data.id;
              chat.id.forEach(element => {
                array.push(element);
              })
              this.shareService.changeIdCanale(chat.canale);
              this.listaCanali[index] = chat.canale;
              if (chat.id.indexOf(idUtente) !== -1) {
                this.stompClient.subscribe('/topic/chat/' + chat.canale, res => {
                  chat = JSON.parse(res.body)

                  if (!this.chatliveOpen) {
                    let notifica: Notifica = {};
                    let flag = true;
                    notifica.notifica = 'Hai un nuovo messaggio da ' + chat.nomeUtente;
                    notifica.tipo = 'chat'
                    notifica.messaggio = chat.nomeUtente;
                    this.listaNotifiche.forEach(element => {
                      if (element.notifica === notifica.notifica) {
                        flag = false;
                      }
                    })
                    if (flag) {
                      if (chat.idUtente !== this.accountService.userValue.data.id) {
                        this.listaNotifiche.unshift(notifica)
                      }
                    }
                  }
                  let messaggio: Message = new Message();
                  messaggio.message = chat.messaggio;
                  messaggio.fromId = chat.idUtente;
                  messaggio.dateSent = chat.dataMessaggio;
                  messaggio.toId = chat.idDest;
                  this.shareService.changeMessage(messaggio);
                  this.shareService.changeMessaggio(messaggio);
                })
                this.listaCanali.push(chat.canale)
              }
            } else {
              chat.canale = null;
            }
          })
        }
        this.socketAttiva = true
      })
    }
    //Lavagna start
    //per l'errore che capita in classroom visto che la socket non ha il tempo di aprirsi
    if (this.stompClient === undefined) {
      return
    }
    if (this.socketAttiva == false) {
      return
    }
    //lavagna end
    //CLASSROOM
    if (this.classroomId) {
      if (this.classroomId !== this.oldClassroomId) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.oldClassroomId = this.classroomId;
        this.subscription = this.stompClient.subscribe(`/topic/aula/${JSON.parse(localStorage.getItem('classroom'))}`, (res) => {
          //body contiene l'oggetto
          //adesso arrivano 2 tipo di body
          //1 contiene la presenza, l'altro il sondaggio
          const body = JSON.parse(res.body)
          const isSondaggio = body.messaggio || null
          const isRegistrazione = body.registrazione || null
          //qui va mappato un nuovo body BE nel caso in cui la risposta sia di tipo registrazione
          if (isSondaggio == null && isRegistrazione == null) {
            this.shareService.changeSegnalaPresenza(JSON.parse(res.body));
          } else if (isSondaggio) {
            let sondaggio: Sondaggio = <Sondaggio>body
            this.shareService.changeSondaggio(sondaggio)
          }
          else if (isRegistrazione) {
            if (this.ruolo == 1 || this.ruolo == 4) {
              this.shareService.changeRegistrazione(body);
              //metti il controllo sul ruolo di modo che il messaggio arrivi solo ad admin e docente
              //usa lo shareService per passare la variabile al componente aula. Banalmente può essere anche un booleano con un messaggio preimpostato
            }
          }
        })
      }
    }
    //#region WHITEBOARD
    //La prima volta che entra dopo il login
    //idLavagna è null quindi non entra in idAula&&idAual!=-1
    //Qui si apre il canale inerente alla lavagna
    //un canale rimane in ascolto solo se esiste,
    //quindi il docente entra in aula, fa una REST al BE e poi cambia
    //idAula, in questo modo app.component può ascolatare il canale
    ///topic/lavagna/idAula
    //che si apre quando il docente entra in aula
    //Body canale: idEditor,imageString,updateBackgroundCanvas
    //Caso in cui si apre la seconda finestra, e gli utenti
    //salvano nella cache idAula (e imageString)
    let idAula = JSON.parse(localStorage.getItem("idAula")) as number || null
    //se è così idAula non è vuoto e cambio il valore
    //tramite il servizio
    if (idAula && idAula != -1) {
      this.lavagnaService.changeidAula(idAula)
    }
    //quando un utente fa la log-in rimane in ascolto di idAula
    //valore ottenuto tramite la cache LocalStorage o entrata in aula
    if (!this.subScribeIdLavagnaActive) {
      this.subscriptionidLavagna = this.lavagnaService.idAula$.subscribe((idLavagna: number) => {
        //prima volta che ascolto
        this.subScribeIdLavagnaActive = true //mi assicuro che non ripeta la sub
        //Quando studente e docente entrano in aula
        //oppure quando si apre la seconda finestra, ci sarà
        //una variazione della variabili idAula$
        if (idLavagna) {
          //la idLavagna è -1 siamo usciti dalla classroom
          if (idLavagna == -1) {
            this.resetSubLavagna()
            return
          }
          //se idLavagna non è null
          //avvio il sottocanale
          if (this.isSocketWhiteboardActive) {
            return
          }
          this.isSocketWhiteboardActive = true //evito subscribe doppie
          //Se idLavagna è diverso da null rimango in ascolto del sottocanale
          this.subscriptionWhiteboard = this.stompClient.subscribe(`/topic/lavagna/${idLavagna}`, (resLavagna) => {
            //lato BE mi invia un json che ha 3 radici (error,body,code) nel body
            //c'è info
            let body = JSON.parse(resLavagna.body)
            //imageString corrisponde all'immagine su tela, inviata solo la prima volta
            //poi dopo il BE fornisce gli aggiornamenti della tela
            //id editor sarà null finchè il docente non scegli chi modificare
            let idEditor = body.idEditor as number || null;
            this.lavagnaService.enableEditor(idEditor)
            //imageString è l'immagine su tela
            let imageString = body.imageString as string || null
            //se null, non è detto che la lavagna non sia attiva
            if (imageString) {
              this.lavagnaService.changeBackgroundImageWhiteboard(imageString);
            }
            //aggiornamenti della tela
            let updateBackgroundCanvas = body.updateBackgroundCanvas as string || null
            if (updateBackgroundCanvas === "END") {
              //Il docente esce chiude la lavagna
              //invio al componente lavagna il messaggio per chiudere la lavagna
              this.lavagnaService.updateImage(updateBackgroundCanvas)
              localStorage.removeItem("backgroundWhiteboard")
              localStorage.removeItem("idAula")
            } else {
              //altrimenti invia update dei disegni sull'immagine
              this.lavagnaService.updateImage(updateBackgroundCanvas)
            }
          }
          );
        } else {
          //se idAula torna null, lo studente è uscito dall'aula
          //oppure il docente ha chiuso la lavagna
          if (this.subscriptionWhiteboard) {
            this.subscriptionWhiteboard.unsubscribe()
            this.subscriptionWhiteboard = null
            //abilitiamo di nuovo la subscribe per il canale
          }
          //Potrebbe essere utile per pulire il canale
        }
      })
    }
    if (this.subscriptionWhiteboard == null) {
      this.isSocketWhiteboardActive = false
      if (this.lavagnaService.socketProblem) {
        this.subScribeIdLavagnaActive = false
        this.subscriptionidLavagna.unsubscribe()
      }
    }
    //#endregion
  }

  /**
   * metodo chiamato quando si cambia classe
   */
  resetSubLavagna() {
    if (this.subscriptionidLavagna && this.subscriptionidLavagna != undefined && !this.subscriptionidLavagna.closed) {
      this.lavagnaService.changeidAula(null)
      this.subscriptionidLavagna.unsubscribe()
      this.subscriptionidLavagna = null
    } else {
      this.subscriptionidLavagna = null
    }
    if (this.subscriptionidLavagna && this.subscriptionWhiteboard != undefined && !this.subscriptionWhiteboard.closed) {
      this.subscriptionWhiteboard.unsubscribe()
      this.subscriptionWhiteboard = null
    } else {
      this.subscriptionWhiteboard = null
    }
    this.isSocketWhiteboardActive = false
    this.subScribeIdLavagnaActive = false
  }

  onLogout() {
    this.accountService.logout();
  }

  vediPagina = true;
  checkSameLocation(event) {
    this.vediPagina = false;
    setTimeout(() => {
      this.vediPagina = true;
    }, 1)
  }

  openChatBot() {
    this.closeSidebar();
    this.chatbotOpen = true;
    this.chatliveOpen = false;
  }

  openLiveChat() {
    this.closeSidebar();
    this.chatliveOpen = true;
    this.chatbotOpen = false;
  }

  gestioneNotifiche(notifica: Notifica) {
    if (notifica.tipo === 'chat') {
      this.chatliveOpen = true;
      this.apriChatMsg = notifica.messaggio;
      setTimeout(() => {
        this.apriChatMsg = null;
      }, 1000);
    }
    if (notifica.tipo === 'controlRoomAssistenza') {
      this.notificaCRCliccata(notifica);
    }
    this.listaNotifiche = this.listaNotifiche.filter(x => x !== notifica)
  }

  rimuoviNotifiche() {
    this.listaNotifiche = [];
    this.audio.pause();
  }

  deviceFunction() {
    this.deviceService.browser_version
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    if (this.isTablet) {
      this.isDesktopDevice = true;
    }
    if (this.MOBILEVIEW) {
      this.isDesktopDevice = true;
    }
  }

  alertCliccato() {
    if (this.alertControlRoom) {
      this.confirmationService.confirm({
        message: "Vuoi avviare l'assistenza?",

        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.router.navigate(['controlroom']);
          this.alertControlRoom = false;
          this.confirmationService.close();
          this.operatorService.callUser(this.idUtenteChiamante, this.accountService.userValue.data.id).subscribe((res) => {
            this.alertService.success("Chiamata inoltrata");
          })
        },
        reject: () => {
          this.confirmationService.close();
          this.alertControlRoom = false;
        }
      })
    }
  }


  notificaCRCliccata(notifica) {
    this.audio.pause();
    this.confirmationService.confirm({
      message: "Vuoi avviare l'assistenza?",

      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.router.navigate(['controlroom']);
        this.confirmationService.close();
        this.operatorService.callUser(notifica.idChiamante, this.accountService.userValue.data.id).subscribe((res) => {
        })
      },
      reject: () => {
        this.confirmationService.close();
      }
    })
  }

  // POPUP ALLARMI
  showAllarmi() {
    this.displayAlarm = true;
  }

  resetAllarmi() {
    this.displayAlarm = false;
    this.listaAllarmi = [];
  }
  tastoLeft() {
    this.menuLat = !this.menuLat;
    if (this.menuLat && this.showMenu) {
      let a = document.getElementById('apriPannello');
      this.isClickPannello = false;
      a.click();
      this.isClickPannello = true;
      this.showMenu = false;
    }
  }

  menuPiccolo() {
    if (!this.notPropagade) {
      this.varMenu = true;
      if (this.menuLat) {
        this.menuLat = false;
      }
      let e = document.getElementById('idHtml');
      if (e)
        e.classList.add('nav-open')
    } else {
      this.notPropagade = false;
    }
  }
  closeMenuSidebar(route?: string) {
    if(route === this.router.url) {
      this.varMenu = false;
      this.menuLat = true;
      this.closeMenu();
    }
  }

  closeSidebar() {
    if (!this.menuLat) {
      this.varMenu = false;
    }
  }

  notPropagade = false;
  closeMenu() {
    this.varMenu = false;
    if (this.menuLat) {
      this.menuLat = false;
    }
    let e = document.getElementsByClassName('nav-open');
    if (e && e[0])
      e[0].classList.remove('nav-open')
    let b = document.getElementById('button-navbar-toggle');
    this.notPropagade = true;
    b.click();
  }

  mostraPannello() {
    if (this.isClickPannello) {
      setTimeout(() => {
        let a = document.getElementById('apriPannello');
        let isExpanded = a.getAttribute("aria-expanded");
        if (isExpanded === "true") {
          this.showMenu = true;
        }
        if (isExpanded === "false") {
          this.showMenu = false;
        }
      }, 500);
      if (this.menuLat) {
        this.menuLat = false;
      }
    }
  }

  openFeedbackDialog() {
    this.selectedFeedback = true;
  }

  rateCall(rate) {
    this.crService.callRatePost(rate).subscribe(
      (res) => {
        this.selectedFeedback = false;
      }
    )
  }

  newRoute(isAula?: string) {
    if (this.crService.feedBack) {
      this.crService.feedBack = false;
      this.openFeedbackDialog();
    }
    this.lastUrl = isAula
  }

  rememberLastSize;
  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event.target.innerWidth >= 991) {
      this.varMenu = false;
    }
    if (event.target.innerWidth == 991)
      this.varMenu = false;

    if (event.target.innerWidth < 991 && this.rememberLastSize > 991)
      this.varMenu = false;
    // if (this.rememberLastSize > 991 && event.target.innerWidth < 991) {
    //   this.varMenu = true;
    // }
    if (!this.varMenu) {
      let e = document.getElementsByClassName('nav-open');
      if (e && e[0]) {
        e[0].classList.remove('nav-open')
        let b = document.getElementById('button-navbar-toggle');
        this.notPropagade = true;
        b.click();
      }
    }
    this.rememberLastSize = event.target.innerWidth
  }
  getHeight(){

    let pageHeight = 1000;

//     function findHighestNode(nodesList) {
//         for (var i = nodesList.length - 1; i >= 0; i--) {
//             if (nodesList[i].scrollHeight && nodesList[i].clientHeight) {
//                 var elHeight = Math.max(nodesList[i].scrollHeight, nodesList[i].clientHeight);
//                 pageHeight = Math.max(elHeight, pageHeight);
//             }
//             if (nodesList[i].childNodes.length) findHighestNode(nodesList[i].childNodes);
//         }
//     }

//     findHighestNode(document.documentElement.childNodes);
// //  let altezza :any=document.documentElement.childNodes
// //  altezza[2].scrollHeight
    return  pageHeight
  }
  responsiveWidth() {    }
}
