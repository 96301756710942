<div class="container-fluid">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="tab-pane active" id="pill1">
          <div>
            <div class="spinner">
              <mat-spinner *ngIf="!showTable" [diameter]="20"></mat-spinner>
            </div>
            <div class="xScroll" *ngIf="showTable" id="scrollableTableFarmacie">
              <table mat-table [dataSource]="dataSource">
                <ng-container *ngFor="let item of displayedColumns">
                  <ng-container
                    [matColumnDef]="item"
                    [sticky]="item == 'ragione_Sociale_Farmacia' ? true : false"
                  >
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-form-field appearance="standard">
                        <mat-label>
                          <i class="material-icons no-translate fSmall"
                            >search</i
                          >
                          {{ getLabel(item) }}
                        </mat-label>
                        <input
                          matInput
                          (keyup)="applyFilter($event, item)"
                          placeholder="Cerca ..."
                          #input
                        />
                      </mat-form-field>
                    </th>
                    <td
                      mat-cell
                      (click)="emitEvent(element)"
                      *matCellDef="let element"
                    >
                      <ng-container>
                        <div
                          class="h-21"
                          [ngClass]="{
                            'one-line':
                              (element[item] && element[item].length > 30) ||
                              (!checkType(element[item]) &&
                                element[item] &&
                                element[item].length > 2)
                          }"
                          (dblclick)="modifyRow(element)"
                        >
                          <span
                            *ngIf="
                              checkType(element[item]) &&
                              element[item] !== true &&
                              element[item] !== false
                            "
                          >
                            {{ element[item] }}
                          </span>
                          <span *ngIf="element[item] === true"> Si </span>
                          <span *ngIf="element[item] === false"> No </span>

                          <span *ngIf="!checkType(element[item])">
                            <ng-container
                              *ngFor="
                                let itemList of element[item];
                                let i = index
                              "
                            >
                              {{ itemList }}
                              <span
                                *ngIf="
                                  itemList !==
                                  element[item][element[item].length - 1]
                                "
                              >
                                ,
                              </span>
                            </ng-container>
                          </span>
                        </div>
                      </ng-container>
                    </td></ng-container
                  >
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
            <div>
              <mat-paginator
                *ngIf="lunghezzaPaginator"
                [length]="lunghezzaPaginator"
                class="ml-auto"
                (page)="changeVisual($event)"
                [pageSizeOptions]="[5, 10, 25, 100]"
              ></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
