import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from '../GENERAL/env.service';
import { AccountService } from '../USER/account.service';


@Injectable({
  providedIn: 'root'
})
export class ScormService {
  baseUrl: string;
  constructor(private http: HttpClient, private accountService: AccountService, private env: EnvService) {
    this.baseUrl = this.env.apiUrl;
  }

  // @GetMapping(value = "/lista_corsi")
  // public ResponseEntity<?> listaCorsi(@RequestParam(required = false) Integer page, 
  // @RequestParam(required = false) Integer size, @RequestParam(required = false) String ricerca) {
  getLista(page?,size?,ricerca?): Observable<any> {
    if ((page || page == 0) && size && ricerca) {
      return this.http.get<any>(this.baseUrl + '/lista_corsi?page=' + page + "&size=" + size + "&ricerca=" + ricerca)
    } else if ((page || page == 0) && size && !ricerca) {
      return this.http.get<any>(this.baseUrl + '/lista_corsi?page=' + page + "&size=" + size)
    }else{
      return this.http.get<any>(this.baseUrl + '/lista_corsi')
    }
  }

  getListaCestino(page?,size?,ricerca?): Observable<any> {
    if ((page || page == 0) && size && ricerca) {
      return this.http.get<any>(this.baseUrl + '/lista_corsi_eliminati?page=' + page + '&size=' + size + '&ricerca=' + ricerca)
    } else if ((page || page == 0) && size && !ricerca) {
      return this.http.get<any>(this.baseUrl + '/lista_corsi_eliminati?page=' + page + '&size=' + size)
    }else{
      return this.http.get<any>(this.baseUrl + '/lista_corsi_eliminati')
    }
  }

  creaCorso(listaId,file): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/creazione_corso/?listaId='+listaId,file)
  }

  verifica_corso_file(id): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/verifica_corso_file?id='+id, null)
  }

  modificaCorso(corso,aule): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/modifica_file_corso?auleId='+aule, corso)
  }

  listaCorsiPerAula(idAula,studenteId): Observable<any> {
    return this.http.get<any>(this.baseUrl +'/lista_corsi_per_aula/?aulaId='+idAula+"&idUtente="+studenteId)
  }

  listaCorsiPerAssociazione(idAula): Observable<any> {
    return this.http.get<any>(this.baseUrl +'/lista_corsi_non_associati/?aulaId='+idAula)
  }
  
  associaCorsi(idAula,lista): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/associa_corsi/?aulaId='+idAula+'&listaIdCorsi='+lista,null)
  }
 
  associaCorsiAdUtente(idAula,corsoId,idUtente): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/associa_corso_utente/?aulaId='+idAula+'&corsoId='+corsoId+'&idUtente='+idUtente,null)
  }

  aggiungi_dati_modulo_utente(moduloStudenteForm): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/aggiungi_dati_modulo_utente',moduloStudenteForm)
  }

  testUpload(file): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/download_corso',file)
  }
  cestino(corsoId): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/eliminazione_logica_corso?corsoId='+corsoId,null)
  }
  ripristinoDaCestino(corsoId): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/ripristina_corso?corsoId='+corsoId,null)
  }

  eliminazioneFisicaCorso(corsoId): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/eliminazione_fisica_corso?corsoId='+corsoId,null)
  }

  creaCorsoDue(listaId,file): Observable<any> {
  return this.http.post(this.baseUrl + '/creazione_corso/?listaId='+listaId, file, { reportProgress: true, observe: 'events' })}

  disassocia_corsi_aule(aulaId,corsoId): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/disassocia_corsi_aule?aulaId='+aulaId+"&corsoId="+corsoId,null)
  }

info_corso(aulaId,corsoId,idUtente): Observable<any> {
  return this.http.get<any>(this.baseUrl +'/info_corso/?aulaId='+aulaId+'&corsoId='+corsoId+"&idUtente="+idUtente)
}
lista_classroom_flaggate(corsoId): Observable<any> {
  return this.http.get<any>(this.baseUrl +'/info_aule_corso/?corsoId='+corsoId)
}
info_lista_corsi(aulaId): Observable<any> {
  return this.http.get<any>(this.baseUrl +'/info_lista_corsi/?aulaId='+aulaId)
}

}