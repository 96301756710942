import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Id_gruppo } from 'src/app/MODELS/USER/id_gruppo';
import { Utente } from 'src/app/MODELS/USER/utente';
import { AlertService, UsersService } from 'src/app/SERVICES';
import {Message} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
@Component({
  selector: 'app-modifica-gruppo',
  templateUrl: './modifica-gruppo.component.html',
  styleUrls: ['./modifica-gruppo.component.scss']
})
export class ModificaGruppoComponent implements OnInit {

  @Input() gruppo: Id_gruppo;
  @Output() vaiGestioneGruppo= new EventEmitter<boolean>();

  gruppoModForm: FormGroup;
  searchForm: FormGroup;
  searchForm2: FormGroup;

  listautenti=[]//totale per aggiungere utente
listautentiGruppo=[]
  allListautenti: Utente[]=[];

  idGruppo: string;
  users: Utente[]=[];//filtrati
  allUser: Utente[];
  allUserGruppo: Utente[];
  submitted = false;
  //visibilità html
  modifica=true;
  add=false;
  flag=false;
  cols: any[];
 msgs: Message[] = [];
  selectedUsers3: Utente[];
  utentiSelezionati: Utente[];
  idUtentiSelezionati: String[];
  nomeGruppo: string;
  descrizioneGruppo: string;

    constructor(
      private userService: UsersService,
      private formBuilder: FormBuilder,
      private alertService: AlertService,
      private primengConfig: PrimeNGConfig,
      private confirmationService: ConfirmationService
      ) {

      this.gruppoModForm=this.formBuilder.group({
        nomeMod: ['', Validators.required],
        descrizioneMod: ['', Validators.required],
      })

    this.cols = [
      { field: 'email', header: 'Email' },
      { field: 'nome', header: 'Nome' },
      { field: 'cognome', header: 'Cognome' },
      { field: 'gruppos[0].nomeGruppo', header: 'Gruppo' }
    ];
      this.searchForm = this.formBuilder.group({
        search: ['', Validators.required]
        })
        this.searchForm2 = this.formBuilder.group({
          search2: ['', Validators.required]
          })

     }

    ngOnInit(): void {
      this.primengConfig.ripple = true;
        this.userService.getAll().subscribe((res) =>{
          this.listautenti=res.data.listaUtentiAttivi;
          this.allUser=this.listautenti

        }),

      this.modificaGruppo(this.gruppo.id,this.gruppo.nomeGruppo,this.gruppo.descrizione);

    }

    get l() { return this.gruppoModForm.controls; }


    modificaGruppo(id: string, nomeGruppo : string, descrizioneGruppo : string) {

      this.userService.getAllGruppo(id).subscribe((res) => {

        let temp;
        temp = res.data;
        this.users = temp;
        this.allListautenti=this.users

        this.idGruppo = this.gruppo.id;
        this.nomeGruppo = nomeGruppo;
        this.descrizioneGruppo = descrizioneGruppo;
        this.gruppoModForm = this.formBuilder.group({
        nomeMod: [this.gruppo.nomeGruppo, [Validators.required, Validators.pattern(/.*\S.*/)]],
        descrizioneMod: [this.gruppo.descrizione,  [Validators.required, Validators.pattern(/.*\S.*/)]]
        });

      }, (error) => {

      });
    }


    eliminaUtenteGruppo(id: string,i) {//elimina utente da gruppo

      const user = this.users.find(x => x.id === id);
      //user.isDeleting = true;
      this.confirmationService.confirm({
        message: 'Sicuro di voler rimuovere l\'utente dal gruppo?',

        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.userService.deleteUtenteGruppo(id, this.idGruppo).subscribe((res) => {

          this.users = this.users.filter(x => x.id !== id)
          this.allListautenti = this.allListautenti.filter(x => x.id !== id)
          this.alertService.success(res.status.description);
          // this.users= this.users.splice(i, 1);
          // this.allListautenti = this.allListautenti.splice(i,1)
          this.confirmationService.close();
          },(error)=>{

          });
            //this.msgs = [{severity:'info', summary:'Utente rimosso!'}];
        },
        reject: () => {
          this.confirmationService.close();
           //this.msgs = [{severity:'error', summary:'Rejected'}];
        }
    })

    }

    tornaGesGruppi(vai){//riporta gestione gruppi

      this.vaiGestioneGruppo.emit(vai);

    }

    aggiungi(vai){//compare cmp per aggiungere utente
      this.userService.notGetAllGruppo(this.gruppo.id).subscribe(
        (res)=>{
          this.listautentiGruppo=res.data;
          this.allUserGruppo=this.listautentiGruppo;
          this.modifica=false;
      this.add=true;
        }

        )


    }

    salvaGruppo(){

      this.submitted = true;
      if (this.gruppoModForm.invalid) {
        return;
      }
      let nome = this.gruppoModForm.value.nomeMod;

      let descrizione = this.gruppoModForm.value.descrizioneMod;
      this.userService.salvaGruppoService(this.gruppo.id, nome, descrizione).subscribe(
        (res) => {
          //this.msgs = [{severity:'info', summary:'Gruppo modificato!'}];
          this.alertService.success(res.status.description);
          this.userService.getAllGruppo(this.gruppo.id).subscribe((res) => {

            let temp;
            temp = res.data;
            this.users = temp;
            this.allListautenti=this.users

            this.idGruppo = this.gruppo.id;
            this.gruppo.nomeGruppo = nome;
             this.gruppo.descrizione = descrizione;
            this.gruppoModForm = this.formBuilder.group({
            nomeMod: [nome, Validators.required],
            descrizioneMod: [descrizione, Validators.required]
            });

          }, (error) => {

          });
        },
          (error) => {

          });

    }
    searchInUser() {

      let valore: string = this.searchForm.value.search;


      this.users = this.allListautenti;

      if (valore) {
        let users = []
        this.users.forEach(user => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;



        if (cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
            nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
            user.nome.toLowerCase().includes(valore.toLowerCase()) ||
            user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
            user.email.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!(users.find(x => x === user))) {
          users.push(user);
          }
        }
      });
      this.users = users;

      } else if (!valore) {
        this.users ;
      }
    }

    searchInUser2() {


      let valore: string = this.searchForm2.value.search2;

      this.listautentiGruppo=this.allUserGruppo


      if (valore) {
        let users = []
        this.listautentiGruppo.forEach(user => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;

        user.gruppos.forEach(gruppo => {
          if (gruppo.nomeGruppo.toLowerCase().includes(valore.toLowerCase())) {
            if (!(users.find(x => x === user))) {
            users.push(user); }
          }
          })


        if (cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
            nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
            user.nome.toLowerCase().includes(valore.toLowerCase()) ||
            user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
            user.email.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!(users.find(x => x === user))) {
          users.push(user);
          }
        }
      });
      this.listautentiGruppo = users;

      } else if (!valore) {
        this.listautentiGruppo ;
      }
    }
//METODI SECONDA PARTE AGGIUNTA UTENTE


    avanti() {

      this.alertService.clear();
      if (this.selectedUsers3 && this.selectedUsers3.length > 0) {

        this.utentiSelezionati = this.selectedUsers3;
        this.idUtentiSelezionati = new Array;
        this.selectedUsers3.forEach(user => {

          let id: string;
          id = user.id;
          this.idUtentiSelezionati.push(id);

        });

        this.userService.associaUtentiGruppo(this.idGruppo, this.idUtentiSelezionati).subscribe(
          (res)=>{
            this.alertService.success(res.status.description);



            this.selectedUsers3 = [];
            this.idUtentiSelezionati = [];
            this.modificaGruppo(this.gruppo.id,this.gruppo.nomeGruppo,this.gruppo.descrizione);
            this.modifica=true;
        this.add=false;
          }
        )

      }
      else {
        this.alertService.error("Seleziona uno o più utenti");
      }

    }

  indietro2(){//fa ricomparire modifica

    this.add=false;
    this.modifica=true;

  }
  responsiveWidth() {    }


}
