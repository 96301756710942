import { Component, Input, OnInit, Output, EventEmitter, DoCheck, OnChanges, SimpleChanges } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { UsersService } from 'src/app/SERVICES/USER/user.service';
import { DatePipe } from '@angular/common';
import { NewsLetterService } from 'src/app/SERVICES/NEWSLETTER/newsLetter.service';
import { AlertService, EnvService } from 'src/app/SERVICES';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-edit-news-letter',
  templateUrl: './add-edit-news-letter.component.html',
  styleUrls: ['./add-edit-news-letter.component.scss']
})
export class AddEditNewsLetterComponent implements OnInit, DoCheck {
  @Input() whatToDoObject;
  @Output() backLista = new EventEmitter<any>()

  richiestaObject = false;
  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  submit = true;
  loading
  listaUtenti = [];
  listaTemplate = [];
  selectedUtente = [];
  submittedStep1 = false;
  submittedStep2 = false;
  submittedStep3 = false;
  submittedStep4 = false;
  loadingListaUtenti = true;
  allListaUtenti = [];
  dataScelta
  ita
  today = new Date();
  templatesEmail; //INCREMENTA QUESTO NUMERO QUANDO SI AGGIUNGE UN NUOVO TEMPLATE
  errore = false;
  html;
  oggettodiRitorno;
  valueStep;
  dataSceltaParsata
  erroreListaUtenti = false;
  loadingTemplate = false;
  jsonDaInviare;
  templateScelto;

  reminder = false;
  idNewsLetter;
  idReminder;
  crea = false;
  modifica = false;
  creaRem = false
  modificaRem = false;
  primaVolta = true;
  figlioCaricato = true;
  //lista Utenti
  pagine = []
  appoggioclick
  k = 0
  pagina
  selectedValue
  appoggioPagina
  abilita
  disabilitaAvanti
  cliccato
  numeroUtenti;
  ordinaNome = false;
  ordinaData = false;
  ordinaemail = false;
  searchform: FormGroup;
  nomeForm: FormGroup;
  ricordaPagina
  ricordaLunghezza
  flag
  secondo
  listaUtentiappoggio = []
  chekAll = false;
  showTable = true;
  listaUtentiDaDb
  listaIdUtenti = []
  step3Salvato = false;
  alfaintes = false;
  oggiMezzora: Date

  constructor(private userService: UsersService,
    private confirmationService: ConfirmationService,
    private dataPipe: DatePipe,
    private newsLetterService: NewsLetterService,
    private alertService: AlertService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private env: EnvService
  ) {
    this.dataScelta = this.today
    this.dataScelta.setMinutes(0)
    this.alfaintes = env.alfaintes;
    this.templatesEmail = this.env.templatesEmail
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required]
    })
    this.oggiMezzora = new Date();
    if (this.oggiMezzora.getMinutes() <= 30) {
      this.oggiMezzora.setMinutes(30)
    } else if (this.oggiMezzora.getMinutes() > 30) {
      this.oggiMezzora.setHours(this.oggiMezzora.getHours() + 1)
      this.oggiMezzora.setMinutes(0)
    }
    this.dataScelta=this.oggiMezzora
  }


  refreshSelezionati(e) {
    setTimeout(() => {
      for (let index = 0; index < this.listaUtenti.length; index++) {
        if (!this.listaUtenti[index].isSelected) {
          this.chekAll = false;
          return;
        }
      }
    });
  }


  ngDoCheck(): void {
    if (this.pagina === 0) {
      this.pagina = 1;
    }
  }

  ngOnInit(): void {


    //  this.oggiMezzora =new Date( oggi.setMinutes( oggi.getMinutes() + 30 ));
    this.ita = {
      firstDayOfWeek: 1,
      dayNames: ["Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
      dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
      monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
      monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
    };
    switch (this.whatToDoObject.whatTodo) {
      case "crea": {
        this.crea = true;
        this.getlistaUtenti()
        this.nomeForm = this.formBuilder.group({
          nome: ['', [Validators.required, Validators.pattern('^[^\s][a-z A-Z0-9!@#$%^&*()]+$')]]
        })
        break;
      }
      case "modifica": {
        this.loadingTemplate = true;
        this.newsLetterService.manda_json(this.whatToDoObject.news.id).subscribe((res) => {
          if (res.data) {
            this.crea = true;
            this.modifica = true;
            this.step1 = true;
            this.idNewsLetter = this.whatToDoObject.news.id;
            this.dataSceltaParsata = this.whatToDoObject.news.dataInoltro
            //CHECCKA TUTTI GLI UTENTI
            this.listaUtentiDaDb = res.data.listaUtenti
            this.templateScelto = JSON.parse(res.data.json)

            this.listaTemplate.push({ path: this.templateScelto, nome: "Template " + this.whatToDoObject.news.nome, nomeT: "TemplateMio" })
            this.loadingTemplate = false;
            this.getlistaUtenti()
            this.nomeForm = this.formBuilder.group({
              nome: [this.whatToDoObject.news.nome, [Validators.required, Validators.pattern('^[^\s][a-z A-Z0-9!@#$%^&*()]+$')]]
            })
          }
        }, (error) => {
          this.loadingTemplate = false;
        })
        break;
      }
      case "creaReminder": {
        this.loadingTemplate = true;
        this.newsLetterService.manda_json(this.whatToDoObject.news.id).subscribe((res) => {
          this.creaRem = true;
          this.step1 = true;
          this.reminder = true;
          this.idNewsLetter = this.whatToDoObject.news.id;
          this.dataSceltaParsata = this.whatToDoObject.news.dataInoltro
          this.listaUtentiDaDb = res.data.listaUtenti
          this.templateScelto = JSON.parse(res.data.json);
          this.listaTemplate.push({ path: this.templateScelto, nome: "Template " + this.whatToDoObject.news.nome,  nomeT: "TemplateMio"})
          this.loadingTemplate = false;
          this.getlistaUtenti()
          this.nomeForm = this.formBuilder.group({
            nome: [this.whatToDoObject.news.nome, [Validators.required, Validators.pattern('^[^\s][a-z A-Z0-9!@#$%^&*()]+$')]]
          })
        })
        break;
      }
      case "modificaReminder": {
        this.loadingTemplate = true;
        this.newsLetterService.manda_json_modificaRem(this.whatToDoObject.news.id).subscribe((res) => {
          this.creaRem = true;
          this.modificaRem = true;
          this.reminder = true;
          this.idReminder = this.whatToDoObject.news.id;
          this.dataSceltaParsata = this.whatToDoObject.news.dataInoltro;
          this.listaUtentiDaDb = res.data.listaUtenti
          this.step1 = true;
          this.templateScelto = JSON.parse(res.data.json);
          this.listaTemplate.push({ path: this.templateScelto, nome: "Template " + this.whatToDoObject.news.nome, preview: false, nomeT: "TemplateMio" })
          this.loadingTemplate = false;
          this.getlistaUtenti()
          this.nomeForm = this.formBuilder.group({
            nome: [this.whatToDoObject.news.nome, [Validators.required, Validators.pattern('^[^\s][a-z A-Z0-9!@#$%^&*()]+$')]]
          })
        })
        break;
      }
      default:
        break;
    }

    this.listaTemplate.push({ nomeT: "TemplateVuoto",path:"./assets/newsLetterTemplate/templateVuoto.json" })

    for (let index = 0; index < this.templatesEmail; index++) {
      this.listaTemplate.push({
        path: "./assets/newsLetterTemplate/TemplateNr" + index + ".json",
        nome: "Template" + index,
        preview: true,
        img: "assets/newsLetterTemplate/TemplateNr" + index + ".png"
      });
    }
  }

  getlistaUtenti() {
    if (!this.alfaintes) {
      this.userService.getAllAttivi().subscribe((res) => {
        this.pagina = 1;
        this.listaUtenti = res.data;
        this.allListaUtenti = res.data;
        this.selectedValue = 10;
        this.getNumeroPagine();
        this.appoggioclick = 1;

        this.allListaUtenti.forEach(e => {
          e.isSelected = false;
        })
        this.listaUtenti.forEach(e => {
          e.isSelected = false;
        })
        if (this.modifica || this.creaRem || this.modificaRem) {
          this.checkDaDb(this.listaUtentiDaDb)
        }
        this.loadingListaUtenti = false;
      }, (error) => {
        this.loadingListaUtenti = false;
      });
    } else {
      this.userService.lista_afi_consenso().subscribe((res) => {
        this.pagina = 1;
        this.listaUtenti = res.data;
        this.allListaUtenti = res.data;
        this.selectedValue = 10;
        this.getNumeroPagine();
        this.appoggioclick = 1;

        this.allListaUtenti.forEach(e => {
          e.isSelected = false;
        })
        this.listaUtenti.forEach(e => {
          e.isSelected = false;
        })
        if (this.modifica || this.creaRem || this.modificaRem) {
          this.checkDaDb(this.listaUtentiDaDb)
        }
        this.loadingListaUtenti = false;
      }, (error) => {
        this.loadingListaUtenti = false;
      });
    }
  }
  goBack() {
    if (this.reminder) {
      this.backLista.emit("rem");
    } else {
      this.backLista.emit("news")
    }
  }
  checkDaDb(list: [any]) {
    this.showTable = false;
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < this.listaUtenti.length; j++) {
        if (this.listaUtenti[j].id == list[i].id) {
          this.listaUtenti[j].isSelected = true;
        }
      }
    }
    this.showTable = true;
  }
  get f() { return this.nomeForm.controls; }
  //MENU STEPPER
  //bottoni in basso
  nextStep() {
    if (this.step1) {
      this.goStep2();
      return;
    }
    if (this.step2) {
      this.goStep3();
      return;
    }
    else if (this.step3) {
      this.goStep4();
      return;
    }
  }

  previusStep() {
    if (this.step2) {
      this.goStep1();
      return;
    }
    else if (this.step3) {
      this.goStep2();
      return;
    }
    else if (this.step4) {
      this.goStep3();
      return;
    }
  }
  //------------------
  //accensioni spegnimento variabili
  spegnimento() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
  }
  stepper1() {
    this.spegnimento()
    this.templateScelto=null
    this.step1 = true;
  }
  stepper2() {
    this.spegnimento()
    this.step2 = true;
    this.oggettodiRitorno = null;
    this.figlioCaricato = false;
    this.submittedStep3 = false;
    let top = document.getElementById('top');

    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    this.submittedStep3 = false;

  }
  stepper3() {
    this.spegnimento()
    this.step3 = true;
  }
  stepper4() {
    this.spegnimento()
    this.step4 = true;
  }
  //accensioni spegnimento variabili

  //metodi di avanzamento
  goStep1() {

    this.salvaStep(1)
    if (this.primaVolta && !this.step2) {
      this.stepper1()
    } else {
      if (this.oggettodiRitorno && !this.step2) {
        this.alertService.warn("Attenzione, selezionando un altro template i cambiamenti attuali andranno persi");
        this.stepper1();
      }
    }

  }

  goStep2() {
    this.salvaStep(2)
    this.stepper2()
  }

  goStep3() {

    this.salvaStep(3)
    if (this.oggettodiRitorno && !this.step2) {
      this.stepper3();
    }

  }

  goStep4() {
    this.salvaStep(4)

    if (this.oggettodiRitorno && this.step3Salvato && !this.step2) {
      this.stepper4();
    }
  }

  salvaStep(i) {
    // value step è l'indicatore della pagina di arrivo
    this.valueStep = i
    if (this.step1) {
      this.salvaStep1()
    }
    else if (this.step2) {
      this.salvaStep2()
    }
    else if (this.step3) {
      this.salvaStep3()
    }
    else if (this.step4) {
      this.salvaStep4()
    }
  }
  salvaStep1() {
    if (this.primaVolta) {
      this.primaVolta = false;
    }
  }
  salvaStep2() {
    this.richiestaObject = true;
  }


  salvaStep3() {
    this.submittedStep3 = true;

    if (!this.dataSceltaParsata) {
      return
    }
    let today = new Date
    let dataInoltro = new Date
    let datascelta = new Date(this.dataSceltaParsata);
    if (this.whatToDoObject.news)

      dataInoltro = new Date(this.whatToDoObject.dataNews)

    if (!datascelta || datascelta < today ||(this.reminder && datascelta < dataInoltro )) {
      this.erroreData = true;
      this.step3Salvato = false;
    } else {
      this.step3Salvato = true;
      this.erroreData = false;
    }

  }
  salvaStep4() {


  }

  //menu stepper fine
  checkControll() {
    this.allListaUtenti.forEach((element) => {
      if (element.isSelected) {
        if (!this.selectedUtente.find((x) => x == element)) {
          this.listaIdUtenti.push(element.id)
        }
      }
    })
  }

  onSubmit() {

      if ((((this.f.nome && this.f.nome.value.trim().length === 0) || (this.f.idDevice && this.f.idDevice.value.trim().length === 0))) && this.step4){
        this.alertService.error('Inserire un campo nome valido');
      } else {
      this.submittedStep4 = true;
      this.listaIdUtenti = []
      this.checkControll();
      this.salvaStep4();
      if (this.listaIdUtenti.length < 1) {
        this.erroreListaUtenti = true;
        return
      } else {
        this.erroreListaUtenti = false;
      }
      if (this.nomeForm.invalid) {
        return
      }
      if (!this.errore && !this.erroreListaUtenti) {
        this.loading = true;
        //CREAZIONE NEWS LETTER
        let news = {
          html: this.html,
          json: this.jsonDaInviare,
          nome: this.nomeForm.value.nome,
          dataInoltro: this.dataSceltaParsata,
          listaIdUtenti: this.listaIdUtenti,
        }
        // CREAZIONE REMINDER
        if (this.crea && !this.modifica) {
          this.newsLetterService.salvaNewsLetter(news).subscribe((res) => {
            this.alertService.success(res.status.description);
            this.goBack();
            this.loading = false;
          }, (error) => {
            this.loading = false;
          })
        }
        //MODIFICA NEWS LETTER
        else if (this.modifica && this.crea) {
          this.newsLetterService.modifica_news(this.idNewsLetter, news).subscribe((res) => {
            this.alertService.success(res.status.description);
            this.goBack();
            this.loading = false;
          }, (error) => {
            this.loading = false;
          })
        } // crea REMINDER
        else if (this.creaRem && !this.modificaRem) {
          this.newsLetterService.salva_remainder(this.idNewsLetter, news).subscribe((res) => {
            this.alertService.success(res.status.description);
            this.loading = false;
            this.goBack()
          }, (error) => {
            this.loading = false;
          })
        }
        //MODIFICA REMINDER
        else if (this.creaRem && this.modificaRem) {
          this.newsLetterService.modifica_remainder(this.idReminder, news).subscribe((res) => {
            this.alertService.success(res.status.description);
            this.goBack()
            this.loading = false;
          }, (error) => {
            this.loading = false;
          })
        }
      } else {
        //se non ci sono le varibili del primo if esce dal metodo di submit
        return;
      }
    }
  }
  vaiStepuno() {
    this.spegnimento()
    this.step1 = true;
  }
  erroreData = false
  selezionaData() {
    this.dataSceltaParsata = this.dataPipe.transform(this.dataScelta, 'yyyy/MM/dd HH:mm');

  }
  //metodi necessari per poter passare il template prende un file json e lo trasforma in un oggetto
  getPath(template) {
    if (template.path ) {
    if(template.nomeT !="TemplateMio"){
      this.getJSON(template.path).subscribe(data => {
        this.templateScelto = data;
      })
    }
    else{
      this.templateScelto = template.path
    }
    }
    this.stepper2();
  }
  public getJSON(template): Observable<any> {
    return this.http.get(template);
  }
  //---------------------------------------------
  //metodo figlio padre per controllo dell oggetto html e json e reinderizzamento alla pagina di destinzazione
  controlloObjDiRitorno(event) {
    if (this.richiestaObject) {
      this.oggettodiRitorno = event
      this.html = this.oggettodiRitorno.html
      // this.json = JSON.stringify(this.oggettodiRitorno.design);
      this.jsonDaInviare = JSON.stringify(this.oggettodiRitorno.design);
      this.richiestaObject = false
      this.templateScelto = event.design
      switch (this.valueStep) {
        case 1: {
          this.stepper1()
          this.alertService.warn("Attenzione, selezionando un altro template i cambiamenti attuali andranno persi");

          break;
        }
        case 2: {
          this.stepper2()
          break;
        }
        case 3: {
          this.stepper3()
          break;
        }
        case 4: {
          if (this.dataSceltaParsata && this.step3Salvato) {
            this.stepper4()
          }
          break;
        }
        default:
          break;
      }
    }
  }
  chiudipopup() {
    this.confirmationService.close();
  }

  //paginazione ricerca lista utenti
  getNumeroPagine() {
    this.pagine.length = 0;//array per paginazione, richiamato in html per far comparire numero di pagine
    this.k = 1;//var per la paginazione
    this.appoggioclick = this.pagina;//si imposta la classe active sulla pagina in modo tale che risulta evidente quella su cui ci si trova
    if (this.listaUtenti) {
      if (this.listaUtenti.length % this.selectedValue === 0) {//divide numero utenti per il valare da mostrare scelto per capire quante pagine servono
        this.appoggioPagina = this.listaUtenti.length / this.selectedValue;
      }
      else {
        this.appoggioPagina = Math.trunc((this.listaUtenti.length / this.selectedValue)) + 1;
      }
      for (let i = 0; i < this.appoggioPagina; i++) {//scorre appggioPagina che contiene il numero totale di pagine che bisogna generare
        this.pagine.push(this.k);
        this.k++;
      }
    }
  }


  //STESSA LOGICA DI GETNUMEROPAGINE, MA STAVOLTA RICHIAMATO OGNI VOLTA CHE SI CAMBIA SELECTED VALUE
  cambiaValue() {

    if (this.listaUtenti) {
      if (this.listaUtenti.length % this.selectedValue === 0) {
        this.appoggioPagina = this.listaUtenti.length / this.selectedValue;
        this.pagina = 1;//ogni volta che si cambia il valore si fa partire l'utente da pagina 1
      }
      else {
        this.appoggioPagina = Math.trunc((this.listaUtenti.length / this.selectedValue)) + 1;
        this.pagina = 1;
      }
      for (let i = 0; i < this.appoggioPagina; i++) {
        this.pagine.push(this.k);
        this.k++;
      }
    }
  }

  //CLICK SU TASTO AVANTI
  next() {
    if (!(this.pagina * this.selectedValue > this.listaUtenti.length - 1))//se la pagina su cui ci troviamo moltiplicato per il valore da mostrare scelto è minore della quantità di utenti, allora va avanti; quindi non va avanti se stai all'ultima pagina
    {
      this.pagina++;
      this.appoggioclick = this.pagina;//per bottone attivo
    }
  }

  //CLICK SU TASTO INDIETRO PAGINAZIONE
  back() {
    if (this.pagina > 1) {
      this.pagina--;
    }
    if (this.pagina === 1) {
      this.abilita = true;
    }
    if (this.pagina * this.selectedValue <= this.listaUtenti.length - 1) {
      this.disabilitaAvanti = false;
    }

    this.appoggioclick = this.pagina;
  }


  //METODI PER CAMBIARE PAGINE QUANDO SI CLICCA PROPRIO SULLE PAGINE
  cambiaPagina(num) {
    this.cliccato = true;

    if (this.listaUtenti.length > num * this.selectedValue) {
      this.pagina = num + 1;
      this.appoggioclick = this.pagina;
    }

  }

  //CLICK INIZIO PAGINAZIONE
  paginaUno() {

    this.pagina = 1;
    this.appoggioclick = this.pagina;
  }

  //CLICK FINE PAGINAZIONE
  fine() {

    if (this.listaUtenti.length % this.selectedValue === 0) {
      this.pagina = this.listaUtenti.length / this.selectedValue;
      this.appoggioclick = this.pagina;
    } else {
      this.pagina = Math.trunc((this.listaUtenti.length / this.selectedValue)) + 1;
      this.appoggioclick = this.pagina;
    }
  }

  //RICERCA FILTRO PER LISTA UTENTI TAB

  searchInNews() {
    this.ricordaPagina = 1;
    if (this.searchform.value.search.length > 0) {
      this.ricordaLunghezza = this.searchform.value.search.length;
      this.flag = true
      if (this.pagina !== 1) {
        this.secondo = true
        this.ricordaPagina = this.pagina;
      }
      if (!this.secondo) {
        this.pagina = 1;
      }
    } else if (this.ricordaLunghezza !== this.searchform.value.search.length && this.flag) {
      this.ricordaPagina = this.pagina;
      this.flag = false
    }
    let valore: string = this.searchform.value.search;
    this.listaUtenti = this.allListaUtenti;
    if (valore) {
      this.listaUtentiappoggio = []
      this.listaUtenti.forEach(user => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;
        user.gruppos.forEach(gruppo => {
          if (gruppo.nomeGruppo.toLowerCase().includes(valore.toLowerCase())) {
            if (!(this.listaUtentiappoggio.find(x => x === user))) {
              this.listaUtentiappoggio.push(user);
            }
          }
        })
        if (cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email.toLowerCase().includes(valore.toLowerCase())
        ) {
          if (!(this.listaUtentiappoggio.find(x => x === user))) {
            this.listaUtentiappoggio.push(user);
          }
        }
      });

      this.listaUtenti = this.listaUtentiappoggio;
      this.numeroUtenti = this.listaUtenti.length
      this.getNumeroPagine();
      if (this.pagina > this.appoggioPagina) {
        this.pagina = this.appoggioPagina;
        this.appoggioclick = this.pagina;
      }
    } else if (!valore) {
      this.listaUtenti = this.allListaUtenti;
      if (this.listaUtenti) {
        this.numeroUtenti = this.listaUtenti.length;
      }
      else {
        this.numeroUtenti = 0;
      }
      this.getNumeroPagine();
    }
  }
  ordinaArray(filtro) {
    if (filtro === 'nome') {
      if (!this.ordinaNome) {
        this.listaUtenti.sort((a, b) => a.nome.toLowerCase() < b.nome.toLowerCase() ? -1 : a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : 0);
        this.ordinaNome = true;
      }
      else {
        this.listaUtenti.sort((a, b) => a.nome.toLowerCase() > b.nome.toLowerCase() ? -1 : a.nome.toLowerCase() < b.nome.toLowerCase() ? 1 : 0);
        this.ordinaNome = false;
      }
      // this.allListaUtenti = this.listaUtenti
      this.ordinaemail = false;
      this.ordinaData = false;
    }
    if (filtro === 'email') {
      if (!this.ordinaemail) {
        this.listaUtenti.sort((a, b) => a.email.toLowerCase() < b.email.toLowerCase() ? -1 : a.email.toLowerCase() > b.email.toLowerCase() ? 1 : 0);
        this.ordinaemail = true;
      }
      else {
        this.listaUtenti.sort((a, b) => a.email.toLowerCase() > b.email.toLowerCase() ? -1 : a.email.toLowerCase() < b.email.toLowerCase() ? 1 : 0);
        this.ordinaemail = false;
      }
      // this.allListaUtenti = this.listaUtenti
      this.ordinaNome = false;
      this.ordinaData = false;
    }
    if (filtro === 'gruppo') {
      if (!this.ordinaData) {


        // this.allListaUtenti.sort(function (x, y) { return x.power != 101 ? -1 : y.power != 101 ? 1 : 0; });
        this.listaUtenti.sort((a, b) => a.gruppos[0]?.nomeGruppo.toLowerCase() != b.gruppos[0]?.nomeGruppo.toLowerCase() ? -1 : a.gruppos[0]?.nomeGruppo.toLowerCase() != b.gruppos[0]?.nomeGruppo.toLowerCase() ? 1 : 0);
        // this.listaUtenti.sort((a, b) => a.gruppos[0].nome > b.gruppos.length ? -1 : a.gruppos.length < b.gruppos.length ? 1 : 0);
        this.ordinaData = true;
      }
      else {
        this.listaUtenti.sort((a, b) => a.gruppos[0]?.nomeGruppo.toLowerCase() != b.gruppos[0]?.nomeGruppo.toLowerCase() ? -1 : a.gruppos[0]?.nomeGruppo.toLowerCase() != b.gruppos[0]?.nomeGruppo.toLowerCase() ? 1 : 0);
        // this.listaUtenti.sort((a, b) => a.gruppos.length < b.gruppos.length ? -1 : a.gruppos.length > b.gruppos.length ? 1 : 0);
        this.ordinaData = false;
      }
      // this.allListaUtenti = this.listaUtenti
      this.ordinaNome = false;
      this.ordinaemail = false;
    }
  }

  //METODI LISTA NEWS FINE
  checkAll(evt) {
    this.showTable = false;
    let appoggio = [];
    if (!this.chekAll) {
      this.chekAll = !this.chekAll;
      this.listaUtenti.forEach((c) => {
        c.isSelected = true
        this.allListaUtenti.forEach(element => {
          if (c.id == element.id) {
            element.isSelected = true;
          }
        });
      })
    }
    else {
      this.chekAll = !this.chekAll;
      this.listaUtenti.forEach((c) => {
        c.isSelected = false
        this.allListaUtenti.forEach(element => {
          if (c.id == element.id) {
            element.isSelected = false;
          }

        });
      })
    }

    this.showTable = true;
  }
  checkOne(id) {
    let controllo = this.allListaUtenti.find(x => x.id.toString() === id.toString()).isSelected
    if (!controllo) {
      this.allListaUtenti.find(x => x.id.toString() === id.toString()).isSelected = true;
      this.listaUtenti.find(x => x.id.toString() == id.toString()).isSelected = true;
    }
    else {
      this.allListaUtenti.find(x => x.id.toString() === id.toString()).isSelected = false;
      this.listaUtenti.find(x => x.id.toString() == id.toString()).isSelected = false;
      if(this.chekAll){
        this.chekAll= false;
              }
    }
    let counter = 0;
    this.listaUtenti.forEach(element => {
      if(element.isSelected == false){
        counter++
      }
    })
    if(counter==0){
      this.chekAll= true;
    };
  }

  caricaFiglio(f) {
    this.figlioCaricato = true;
  }
  responsiveWidth() {    }

}
