import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { AccountService, EnvService, UsersService } from 'src/app/SERVICES';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { ScormService } from 'src/app/SERVICES/CLASSROOM/scorm.service';

@Component({
  selector: 'app-info-aula',
  templateUrl: './info-aula.component.html',
  styleUrls: ['./info-aula.component.scss']
})
export class InfoAulaComponent implements OnInit {
  @Output() tornaLista = new EventEmitter<void>();
  @Input() aula;
  visualizzaInfoDocente = false;
  visualizzaInfoStudente = false;
  studenteVisualizzato;
  docenteVisualizzato;
  caricato = false;
  alfaintes
  afiStudente
  afiDocente
  listaCorsi=[]
  corsi=false;
  constructor(
    private formBuilder: FormBuilder,
    private utenteService: UsersService,
    private routing: Router,
    private accountService: AccountService,
    private classService: ClassroomService,
    private env: EnvService,
    private scormService: ScormService
  ) {
    this.alfaintes = env.alfaintes;
this.corsi =env.SCORM

  }
  ngOnInit(): void {
    this.classService.lista_dettagli_classroom(this.aula.id, true).subscribe((res) => {
      this.caricato = true;
      this.aula.listaDocenti = res.data.listaDocenti;
      this.aula.listaUtenti = res.data.listaStudenti;
    })
    this.scormService.info_lista_corsi(this.aula.id).subscribe((res)=>{
      this.listaCorsi = res.data;
    })
  }
  vaiInfoDocente(idDocente) {
    if (!this.visualizzaInfoDocente) {
      if (this.alfaintes) {
        this.utenteService.get_user_afi(idDocente.id).subscribe((res) => {
          this.caricato = true;
          this.visualizzaInfoDocente = true;
          this.docenteVisualizzato = res.data;
          this.afiDocente=res.data.afi
        })
      } else {
          this.utenteService.info(idDocente.id).subscribe((res) => {
            this.caricato = true;
            this.visualizzaInfoDocente = true;
            this.docenteVisualizzato = res.data;
          })
      }
    } else {
      this.visualizzaInfoDocente = false;
    }
  }

  vaiInfoStudente(idStudente) {
    if (!this.visualizzaInfoStudente) {
      if(this.alfaintes){
        this.utenteService.get_user_afi(idStudente.id).subscribe((res) => {
          this.caricato = true;
          this.visualizzaInfoStudente = true;
          this.studenteVisualizzato = res.data;
          this.afiStudente=res.data.afi
        })
      }else{
        this.utenteService.info(idStudente.id).subscribe((res) => {
          this.caricato = true;
          this.visualizzaInfoStudente = true;
          this.studenteVisualizzato = res.data;
        })
      }


    } else {
      this.visualizzaInfoStudente = false;
    }

  }


  goBack() {//torna ad elenco aula virtuale
    this.tornaLista.emit();
  }
  responsiveWidth() {    }
}
