import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { Utente } from 'src/app/MODELS/USER/utente';
import {
  AccountService,
  UsersService,
  ShareService,
  AlertService,
  EnvService,
} from 'src/app/SERVICES';

import { Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Router } from '@angular/router';
import { TecnicoService } from 'src/app/SERVICES/PRENOTAZIONETECNICO/tecnico.service';
@Component({
  selector: 'app-crea-tecnico',
  templateUrl: './crea-tecnico.component.html',
  styleUrls: ['./crea-tecnico.component.scss'],
})
export class CreaTecnicoComponent implements OnInit {
  @Input() utenteId;
  @Output() tornaProfilo = new EventEmitter<boolean>();
  fileFototessera = null;
  linkFototessera = null;
  rimuoviFoto = false;
  createForm: FormGroup;
  submitted = false;
  loading = false;
  loadingModifica = true;
  crea: boolean = false;
  validatorRuolo: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private tecnicoService: TecnicoService,
    private alertService: AlertService,
    private primengConfig: PrimeNGConfig,
    //private messageService: MessageService,
    private accountService: AccountService,
    private routing: Router,
    private env: EnvService
  ) {
    this.alertService.clear;
  }
  ngDoCheck(): void {
    if (this.accountService.listaEnv && this.accountService.ruolo !== 1) {
      this.routing.navigate(['./']);
    }
  }
  ngOnInit() {
    // if (this.accountService.ruolo !== 1) {
    //   this.routing.navigate([''])
    // }
    this.createForm = this.formBuilder.group({
      nome: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
        ],
      ],
      cognome: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ],
      ],
    });

    if (this.utenteId) {
      //console.log("Ottengo i dati del tecnico da modificare");
      this.tecnicoService.getInfoTecnico(this.utenteId).subscribe((res) => {
        this.f.nome.setValue(res.data.nomeTecnico);
        this.f.cognome.setValue(res.data.cognomeTecnico);
        this.f.email.setValue(res.data.emailTecnico);
        this.linkFototessera = res.data.linkThumbnail;
        this.loadingModifica = false;
      });
    }
    this.alertService.openAlert(null);
    this.primengConfig.ripple = true;
    this.crea = true;
  }

  // •	NOME                      campo alfabetico
  // •	COGNOME                   campo alfabetico
  // •	EMAIL                     email

  get f() {
    return this.createForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    if (this.createForm.invalid) {
      if (!this.createForm.value.ruolo) {
        this.validatorRuolo = true;
      }
      this.loading = false;
      return;
    }

    let tecnico = {
      nomeTecnico: this.createForm.value.nome,
      cognomeTecnico: this.createForm.value.cognome,
      emailTecnico: this.createForm.value.email,
    };

    let id = 1;
    if (!this.utenteId) {
      this.tecnicoService.addTecnico(tecnico, this.fileFototessera).subscribe(
        (res) => {
          this.loading = false;
          this.alertService.success(res.status.description);
          this.indietroDaCreazione();
        },
        (error) => {
          this.loading = false;
        }
      );
    } else {
      //console.log("Modifico il tecnico già esistente");
      this.tecnicoService
        .editTecnico(
          this.utenteId,
          tecnico,
          this.fileFototessera,
          this.rimuoviFoto
        )
        .subscribe(
          (res) => {
            this.loading = false;
            this.alertService.success(res.status.description);
            this.indietroDaCreazione();
          },
          (error) => {
            this.loading = false;
          }
        );
    }
  }

  clear() {
    this.createForm.reset();
  }

  utenteRegistratoView = false;
  checkOn() {
    setTimeout(() => {
      if (this.createForm.value.consenso) {
        this.utenteRegistratoView = true;
      } else {
        this.utenteRegistratoView = false;
      }
    });
  }

  indietroDaCreazione() {
    // console.log("Indietro da creazione");
    this.tornaProfilo.emit(true);
  }

  fototesseraCaricata(event) {
    this.fileFototessera = event.target.files[0];
    this.rimuoviFoto = false;
    this.linkFototessera = null;
  }

  rimuoviImmagine() {
    this.fileFototessera = null;
    this.rimuoviFoto = true;
    this.linkFototessera = null;
  }
}
