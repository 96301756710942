import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomPaginator } from 'src/app/SERVICES/USER/CustomPaginato';

import { MatNativeDateModule } from '@angular/material/core';
import { CampagneRoutingModule } from './campagne-routing.module';
import { CampagneHomePageComponent } from './campagne-home-page/campagne-home-page.component';
import { CampagneAddComponent } from './campagne-add/campagne-add.component';
import { ExternalComponentsModule } from 'src/app/MODELS/COMPONENTS/externalComponents.module';

import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';

@NgModule({
  declarations: [CampagneHomePageComponent, CampagneAddComponent],
  imports: [
    CommonModule,
    MatTableModule,
    ExternalComponentsModule,
    MatPaginatorModule,
    TableModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
    CheckboxModule,
    DialogModule,
    MatNativeDateModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatStepperModule,
    CampagneRoutingModule
  ],
  providers: [
    {
    provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
    useValue: {
      collapsedHeight: '100px',
      expandedHeight: '100px',
      hideToggle: true
    }
  },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' }
  ]
})
export class CampagneModule { }
