<div class="container-fluid">
    <div class="content">
        <div class="row">
            <div class="col-md-12 ">
                <div class="card">
                    <div class="card-header">
                        <!-- INIZIO WIZARD -->
                        <div class="card-title">
                            <button (click)="onComeBack()" id="back-button" type="button"
                                class="btn btn-round  btn-just-icon"
                                style="margin-top: 3px; color: rgb(54, 54, 54); box-shadow:none!important">
                                <i class="material-icons notranslate">arrow_back</i>
                            </button>
                            <!-- step1 -->
                            <h4 style="display: inline;">{{title}}</h4>
                            <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '6px'}">
                            </p-progressBar>
                        </div>
                        <div id="formwizard_validation" class="form-wizard form-wizard-horizontal">
                            <form class="form floating-label form-validation" role="form" novalidate="novalidate">
                            </form>
                            <div class="form-wizard-nav">
                                <div class="progress" id="respoBar" style="width: 100%;">
                                    <div class="progress-bar progress-bar-primary"
                                        [ngClass]="{'primo' : step1,'secondo' : step2, 'terzo' : step3}">
                                    </div>
                                </div>
                                <ul class="nav nav-justified nav-pills" id="respo">
                                    <li><a data-toggle="tab"><span class="step"
                                                [ngClass]="{'attiva' : !step1,'disattiva' : step1}">1</span>
                                            <span class="title"
                                                [ngClass]="{'attivaTitolo' : !step1,'disattivaTitolo' : step1}">
                                                {{titleStep1}}
                                            </span></a>
                                    </li>
                                    <li><a data-toggle="tab"><span class="step"
                                                [ngClass]="{'attiva' : !step2,'disattiva' : step2}">2</span>
                                            <span class="title"
                                                [ngClass]="{'attivaTitolo' : !step2,'disattivaTitolo' : step2}">Definizione
                                                Stanze</span></a>
                                    </li>
                                    <li><a data-toggle="tab"><span class="step"
                                                [ngClass]="{'attiva' : !step3,'disattiva' : step3}">3</span>
                                            <span class="title"
                                                [ngClass]="{'attivaTitolo' : !step3,'disattivaTitolo' : step3}">Inserimento
                                                Marker</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!--end .form-wizard-nav -->
                        <div class="tab-content clearfix p-30">
                            <div *ngIf="step1">
                                <form [formGroup]="formPiano">
                                    <div class="row">
                                        <label class="col-sm-2 label-on-left" style="margin-top: 30px;">Nome</label>
                                        <div class="col-sm-10">
                                            <div class="form-group label-floating is-empty">
                                                <label class="control-label"></label>
                                                <input type="text" placeholder="Nome piano" formControlName="nomePiano"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submittedStep1 && f.nomePiano.errors }"
                                                    maxlength="30" />
                                                <div *ngIf="submittedStep1 && f.nomePiano.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.nomePiano.errors.required">Campo ID obbligatorio</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" [hidden]="isEdit">
                                        <label style="margin-top: 21px" class="col-sm-3 label-on-left">Seleziona il
                                            file</label>
                                        <button #closeModal style="display: none;" type="button" class="close"
                                            data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <div class="col-sm-9">
                                            <div>
                                                <input style="display: none" type="file"
                                                    (change)="onFileChanged($event)" accept="image/*" #fileInput>
                                                <button (click)="fileInput.click()" class="btn btn-secondary"
                                                    type="button">Scegli
                                                    File</button>
                                                <div class="img-space">
                                                </div>
                                            </div>
                                            <p *ngIf="nomeFile" style="margin-top: 8px">{{nomeFile}}</p>
                                            <p *ngIf="!nomeFile && !submittedStep1" style="margin-top: 8px">Nessun file
                                                selezionato
                                            </p>
                                            <p *ngIf="!nomeFile && submittedStep1"
                                                style="margin-top: 8px; color: rgb(189, 0, 0); font-size: xx-small;">
                                                Campo File
                                                obbligatorio</p>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- step 2 -->
                            <div *ngIf="step2">
                                <div class="row">
                                    <div class="col-12 col-sm-3">
                                        <div class="tab-content">
                                            <div class="tab-pane active" id="pill1">
                                                <div class="material-datatables">
                                                    <div class="scrollable">
                                                        <div class="table-responsive">
                                                            <p *ngIf="stanzeMap === undefined || stanzeMap.size < 1">
                                                                Nessuna stanza inserita
                                                            </p>
                                                            <table id="datatables"
                                                                class="table table-striped table-no-bordered table-hover table-responsive"
                                                                cellspacing="0" width="100%" style="width:100%">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th style="cursor: pointer;">Stanza
                                                                            <i class="material-icons notranslate"
                                                                                style="font-size: 15px;">unfold_more</i>
                                                                        </th>
                                                                        <th class="text-right">Azioni</th>
                                                                    </tr>
                                                                </thead>
                                                                <tfoot>
                                                                    <tr>
                                                                        <th style="width: 20px;">#</th>
                                                                        <th>Stanza</th>
                                                                        <th class="text-right">Azioni</th>
                                                                    </tr>
                                                                </tfoot>
                                                                <tbody>

                                                                    <tr
                                                                        *ngFor="let stanza of stanzeMap | keyvalue; let i = index;">
                                                                        <ng-container>
                                                                            <td style="font-size: xx-small;"></td>
                                                                            <td style="font-size: medium;">
                                                                                {{stanza.value.nomeStanza}}</td>
                                                                            <td class="text-right">
                                                                                <a (click)="openModaleStanza(stanza.value, stanza.key)"
                                                                                    rel="tooltip" title="Modifica File"
                                                                                    class="btn btn-success btn-simple"
                                                                                    style="padding: 2px 2px 2px 2px;"><i
                                                                                        class="material-icons pointer"
                                                                                        style="font-size: 20px;">edit</i></a>
                                                                                <a rel="tooltip"
                                                                                    (click)="deleteStanza(stanza.key, stanza.value)"
                                                                                    title="Elimina Struttura"
                                                                                    class="btn btn-simple btn-danger btn-icon remove"
                                                                                    style="padding: 2px 2px 2px 2px;"><i
                                                                                        class="material-icons pointer"
                                                                                        style="font-size: 20px;">close</i></a>
                                                                            </td>
                                                                        </ng-container>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-7" style="height: 550px;">
                                        <svg width="100%" height="100%" #svgPlanimetria>
                                            <image (click)="addMarker($event)" width="100%" height="100%"
                                                preserveAspectRatio="xMidYMid meet"
                                                [attr.xlink:href]="imgUrlPlanimetria" />
                                        </svg>
                                    </div>
                                    <div class="col-12 col-sm-2">
                                        <div class="row">
                                            <div (click)="deleteAllStanze()" class="btn btn-danger btn-round"
                                                style="padding: 8px 8px 8px 8px ; margin-left: 35px;">
                                                <i class="material-icons notranslate">delete_forever</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="step3">
                                <div class="row">
                                    <div class="col-12 col-sm-9" style="height: 600px;">
                                        <div [hidden]="currentIdx === -1" #viewer id="viewer"></div>
                                    </div>
                                    <div class="col-12 col-sm-3">
                                        <div class="list-group">
                                            <a *ngFor="let stanza of stanzeMap | keyvalue; let i = index;"
                                                (click)="callLoadViewer(stanza.value, i)"
                                                class="list-group-item list-group-item-action"
                                                [ngClass]="i === currentIdx ? 'active' : ''">
                                                {{stanza.value.nomeStanza}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" style="margin-top: 2%">
                            <div class="col-xs-6">
                                <button *ngIf="!step1" type="button" (click)="previusStep()" class="btn btn-secondary"
                                    style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Indietro</button>
                            </div>
                            <div *ngIf="!step3" class="col-xs-6 text-right">
                                <button [disabled]="(step2 || step3) && loading" type="button" (click)="nextStep()"
                                    class="btn btn-primary">
                                    Avanti
                                    <span *ngIf="(step2 || step3) && loading">
                                        <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                                            styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                                    </span>
                                </button>
                            </div>
                            <div *ngIf="step3" class="col-xs-6 text-right">
                                <button [disabled]="loading" type="button" (click)="onSubmit()"
                                    class="btn btn-primary">Salva
                                    <span *ngIf="loading">
                                        <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                                            styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Button trigger modal -->
<button #modaleStanza type="button" style="display: none;" class="btn btn-default" data-toggle="modal"
    data-target="#myModal">

</button>
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> -->
                <h4 class="modal-title" id="myModalLabel">Dettagli Stanza</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="formStanza">
                    <div class="row">
                        <label style="margin-top: 21px" class="col-sm-3 label-on-left">Seleziona immagine stanza</label>
                        <button #closeModal style="display: none;" type="button" class="close" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="col-sm-9">
                            <div>
                                <!-- [(ngModel)]="corso.file" -->
                                <input style="display: none" type="file" accept="image/*"
                                    (change)="onFileChangedStanza($event)" #fileInput>
                                <button (click)="fileInput.click()" class="btn btn-secondary" type="button">Scegli
                                    File</button>
                                <div class="img-space">
                                </div>
                            </div>
                            <p *ngIf="nomeFileStanza" style="margin-top: 8px">{{nomeFileStanza}}</p>
                            <p *ngIf="!nomeFileStanza" style="margin-top: 8px">Nessun file selezionato
                            </p>
                            <p *ngIf="!nomeFileStanza && submittedModalStanza"
                                style="margin-top: 8px; color: rgb(189, 0, 0); font-size: xx-small;">Campo File
                                obbligatorio</p>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-sm-2 label-on-left" style="margin-top: 30px;">Nome Stanza</label>
                        <div class="col-sm-10">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label"></label>
                                <input type="text" placeholder="Nome Stanza" formControlName="nomeStanza"
                                    class="form-control" [ngClass]="{ 'is-invalid': submittedModalStanza
                                    && ms.nomeStanza.errors }" maxlength="30" />
                                <div *ngIf="submittedModalStanza" class="invalid-feedback">
                                    <div *ngIf="ms.nomeStanza.errors.required">Campo nome stanza obbligatorio</div>
                                    <div
                                        *ngIf="!ms.nomeStanza.errors.required && ms.nomeStanza.errors.noWhiteSpaceValidator">
                                        Campo nome stanza invalido
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button #closeModal type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                <button type="button" (click)="saveModalStanza()" class="btn btn-primary">Salva</button>
            </div>
        </div>
    </div>
</div>

<!-- marker menu -->
<p-slideMenu #menu [model]="items" [popup]="true" [backLabel]="'Indietro'" [viewportHeight]="150"
    (onHide)="onHideMenu()"></p-slideMenu>
<!-- marker note dialog -->

<p-dialog [draggable]="false" [resizable]="false" header="Nota marker" [(visible)]="displayDialog">
    <h5 class="nota-marker-label">Titolo</h5>
    <input style="width: 100%; font-size: medium;" type="text" pInputText [(ngModel)]="markerNoteTitle" maxlength="20">
    <h5 class="nota-marker-label">Nota</h5>
    <textarea rows="5" cols="30" [(ngModel)]="markerNoteBody" style="resize: none; width: 100%; font-size: medium;"
        pInputTextarea maxlength="400"></textarea>

    <div style="width: 100%; text-align: center; margin-top: 10%;">
        <p-button icon="pi pi-check" (click)="addNote()" label="Salva" styleClass="p-button-text"></p-button>
    </div>
</p-dialog>
