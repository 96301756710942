import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvService } from '../GENERAL/env.service';
import { AccountService } from '../USER/account.service';

@Injectable({
  providedIn: 'root'
})
export class LavagnaService {

  //Osservatori delle variabili per la whiteboard
  private idAula = new BehaviorSubject(null); //ha lo stesso id dell'aula
  private backgroundImage = new BehaviorSubject(null);
  private idEditor = new BehaviorSubject(null);
  private updateCanvas = new BehaviorSubject(null);

  /**
   * Contiene il file e le info per salvare l'immagine
   */
  private formDataRequest:BehaviorSubject<boolean> = new BehaviorSubject(null);

  /**
   * ha lo stesso id dell'aula, null se utente non sta in una classe.
   *
   * Serve per effettuare la sub sulla socket
   */
  idAula$ = this.idAula.asObservable();
  /**
   * Immagine in background della lavagna.
   *
   */
  backgroundImage$ = this.backgroundImage.asObservable();
  /**
   * corrisponde al proprio id, se è uguale l'utente è abilitato
   */
  idEditor$ = this.idEditor.asObservable();
  /**
   * Aggiornamento dei disegni sulla tela
   */
  updateCanvas$ = this.updateCanvas.asObservable()
  /**
   * Se è vero la socket non è collegata e un certo timer proverà ad connettersi
   */
  socketProblem = false

 /**
  * AggiornamentoFormData
  */
  formDataRequest$: Observable<boolean> = this.formDataRequest.asObservable()

  idUser:number = null

  constructor(
    private http: HttpClient,
    private env : EnvService,
    private accountService: AccountService) {

      let userJSON = JSON.parse(localStorage.getItem("user"))

      if(userJSON){
      let info = userJSON.data
      this.idUser = info.id as number || -1
      }

    }

  //metodi che effettuano l'aggiornamento dei valori per la whiteboard
  /**
   * Serve per cambiare socket al quale ascoltare
   * @param idAula utile per la creazione della socket
   */
  changeidAula(idAula:number){
    this.idAula.next(idAula)
  }
  /**
   * Metodo richiamato dal componente Padre della Lavagna, in modo da dire che deve
   * fornire il formData
   * @param request vero il padre chiede il formData al componente lavagna
   */
  richiestaFormData(request:boolean){
    this.formDataRequest.next(request)
  }

  /**
   * Queste metodo serve per aggiornare il valore delle azioni svolte sull'immagine
   * @param updateBackgroundCanvas le varie azione svolte sull'immagine
   */
  updateImage(updateBackgroundCanvas:string){
    this.updateCanvas.next(updateBackgroundCanvas)
  }
  /**
   * Questo metodo aggiorna l'immagine background, assume valore solo all'inizio
   * poi dopo rimane null.
   * Per riprendere immagine si dovrà chiamare oppurtamente un servizio
   * @param imageString immagine da modificare
   * @see findImageWhiteboard
   */
  changeBackgroundImageWhiteboard(imageString:string){
    this.backgroundImage.next(imageString)
  }
  /**
   * Questo metodo serve per indicare quale studente può modificare la tela
   * @param idEditor id dello studente al quale si vuole dare possibilità di modificare
   */
  enableEditor(idEditor:number){
    this.idEditor.next(idEditor)
  }

  //METODI REST invioImmagine e idEditor
  /**
   * REST, questo metodo permette di inviare nella socket chi è abilitato alla modifica dell'immagine
   * @param idEditor utente da abilitare, se viene ripassato il valore verrà disabilitato
   * @param idAula indica su quale canale socket cui viaggia idEditor
   * @returns response dal server
   */
  assegnaEditor(idEditor: number,idAula:number){

    return this.http.post(`${this.env.apiUrl}/assegna_editor?idAula=${idAula}&idEditor=${idEditor}`,null);
  }
  /**
   * Questo metodo serve ad inviare al BE tutte le modifiche fatte sulla lavagna
   * tranne undo,redo e clear
   * @param updateBackgroundCanvas
   * @param idAula
   * @returns respose dal server
   */
  updateBackgroundCanvas(updateBackgroundCanvas: string,idAula:number){
    return this.http.post(`${this.env.apiUrl}/update_background_canvas?idAula=${idAula}`,updateBackgroundCanvas);
  }
  getBackgroundCanvas(idAula:number){
    return this.http.get(`${this.env.apiUrl}/get_background_canvas?idAula=${idAula}`);
  }

  /**
   * Questo metodo crea il canale a cui tutti devono iscriversi
   * @param idAula canale socket da creare
   * @returns response del server
   */
  creaCanaleLavagna(idAula: number){
    return this.http.post(`${this.env.apiUrl}/crea_canale_lavagna?idAula=${idAula}`,null);
  }

  /**
   * Invio immagine nella socket, in questo modo chi sta in ascolto apre la finestra.
   * Questo metodo lato BE dovrà salvare il record contenente immagine oltre che propagare informazione
   * sulla socket, al fine di permettere poi di essere recuperato.
   * Inoltre serve per inviare le modifiche redo, undo e clear
   * @param imageString immagine della lavagna da modificare
   * @param idAula canale su cui prorogare info e chiave secondaria lato BE per poi recuperare immagine
   * @returns response dal BE
   * @see findImageWhiteboard
   */
  imageBackgroundCanvas(imageString:string,idAula:number){
    var image = "null"
    if(imageString){
      image=imageString
    }
    return this.http.post(`${this.env.apiUrl}/image_background_canvas?idAula=${idAula}`,image);
  }
  /**
   * Metodo per recuperare l'eventuale immagine salvata al BE per apertura della lavagna
   * @param idAula chiave secondarie per il recupero dell'immagine rispettiva della lavagna
   * @returns la response restituisce come chiave imageString l'immagine se la lavagna è attiva oppure null
   */
  findImageWhiteboard(idAula:number){

    return this.http.post(`${this.env.apiUrl}/find_image_whiteboard?idAula=${idAula}`,null);
  }

  /**
   * Metodo per chiedere la situazione attuale della lavagna, per gli studenti che entrano dopo.
   *
   * Caso studente:
   *
   * il parametro body è null, la stinga verso BE è questa
   * (align###idMittente###Request)
   *
   * Caso docente:
   *
   * body contiene (align###idMittente###idDestinatario###Stage.toJson###ArrayInPending)
   *
   * @param body varie informazioni: idMittene, idDestinario?, OggettoStage, arrayPending il tutto diviso da ###
   * @param idAula idAula
   * @param nPacchetto ultimo pacchetto arrivato
   * @returns risposta dal server
   */
  reAlign(idAula:number,nPacchetto:number,body?:string|null){
    let info:string = nPacchetto+"###align###" + this.mioId()
    if(body){
      //body diverso da null
     info = info + "###" + body
    }else{
      //caso studente
      info = info + "###request"
    }
    return this.http.post<any>(`${this.env.apiUrl}/re_align?idAula=${idAula}`,info)
  }

  /**
   * Metodo per verificare se l'utente è un docente
   * @returns vero se l'utente è il docente
   */
  isDocente():boolean{
    let idDocente = this.accountService.ruolo as number || null
    //4 corrisponde ad insegnante
    if(idDocente&&(idDocente==4||idDocente==1)){
    return true
    }
    return false
  }

  mioId():number{
    if(this.idUser){
      return this.idUser
    }else{
      let userJSON = JSON.parse(localStorage.getItem("user"))
      if(userJSON){
      let info = userJSON.data
      this.idUser = info.id as number || -1
      }
      return this.idUser
    }
  }

  nImmagini():number{
    return this.env.immaginiLavagna
  }

   /**
   * Visto che il form-data richiede l'invio di un file binario occorre convertire
   * @param dataURI di tipo String, di solito una immagine
   * @returns il file in binario
   */
    dataURItoBlob(dataURI):Blob {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1]);
      else
          byteString = unescape(dataURI.split(',')[1]);

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {type:mimeString});
  }

  /**
   * Metodo che salva in esercitazioni l'immagine della lavagna
   * @param stringBase64 immagine della lavagna con tanto di disegni
   * @param idAula utile per il BE
   * @param nomeAula
   */
  sendFileToTutorials(stringBase64:String,idAula:number,nomeAula:string):Observable<any> {
    const uploadFile = new FormData();
    const fileBlob = this.dataURItoBlob(stringBase64)
    //uploadFile.append('idEsercitazione', id); //il BE dovrebbe crearlo in automatico
    let ora = formatDate(new Date(), 'dd-MM-yyyy-hh_mm_ss', 'en-US', '+2');
    let title = 'Lavagna '+ nomeAula + " " + ora
    uploadFile.append('title', title);
    const file = new File([fileBlob], title+".png");
    uploadFile.append('imageFile', file, file.name);
    uploadFile.append('visibGruppo', "");
    uploadFile.append('visibRuolo', "");
    uploadFile.append('visibUtente', "");
    uploadFile.append('idAula', ""+idAula);
    uploadFile.append('category', "Immagine")
    uploadFile.append('group', "Aula Virtuale")
    uploadFile.append('owner', this.accountService.userValue.data.id)
    uploadFile.append('author', "")
    uploadFile.append('index', "0")
    return this.http.post<any>(this.env.apiUrl+"/elastic/save_doc_body", uploadFile)
  }

  sendFile(formDataImageWhiteboard:FormData){
    return this.http.post<any>(this.env.apiUrl+"/elastic/save_doc_body", formDataImageWhiteboard)
  }

  creaFormData(stringBase64:string,idAula:number,nomeAula:string):FormData{
    const formDataImageWhiteboard = new FormData();
    const fileBlob = this.dataURItoBlob(stringBase64)
    //uploadFile.append('idEsercitazione', id); //il BE dovrebbe crearlo in automatico
    let ora = formatDate(new Date(), 'dd-MM-yyyy-hh_mm_ss', 'en-US', '+1');
    let title = 'Lavagna '+ nomeAula + " " + ora
    formDataImageWhiteboard.append('title', title);
    const file = new File([fileBlob], title+".png");
    formDataImageWhiteboard.append('imageFile', file, file.name);
    formDataImageWhiteboard.append('visibGruppo', "");
    formDataImageWhiteboard.append('visibRuolo', "");
    formDataImageWhiteboard.append('visibUtente', "");
    formDataImageWhiteboard.append('idAula', ""+idAula);
    formDataImageWhiteboard.append('category', "Lavagna")
    formDataImageWhiteboard.append('group', "Lavagna")
    formDataImageWhiteboard.append('owner', this.accountService.userValue.data.id)
    formDataImageWhiteboard.append('author', "")
    formDataImageWhiteboard.append('index', "0")
    return formDataImageWhiteboard
  }


  chiudiLavagna(idAula) {
    return this.http.get<any>(this.env.apiUrl+"/chiudi_lavagna?idAula="+idAula);
  }
  /**
   * Metodo che salva in esercitazioni l'immagine della lavagna
   * @param idAula utile per il BE
   * @param file contiene il file da inviare
   */
   sendFileToTutorialsWithFile(idAula:number,file:File):Observable<any> {
    const uploadFile = new FormData();
    //mi ricavo il titolo dal nome del file
    let title = file.name.slice(0, file.name.length-4)
    uploadFile.append('title', title);
    uploadFile.append('imageFile', file, file.name);
    uploadFile.append('visibGruppo', "");
    uploadFile.append('visibRuolo', "");
    uploadFile.append('visibUtente', "");
    uploadFile.append('idAula', ""+idAula);
    uploadFile.append('category', "Lavagna")
    uploadFile.append('group', "Lavagna")
    uploadFile.append('owner', this.accountService.userValue.data.id)
    uploadFile.append('author', "")
    uploadFile.append('index', "0")
    return this.http.post<any>(this.env.apiUrl+"/elastic/save_doc_body", uploadFile)
  }

}
