import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { tap } from 'rxjs/operators';
import { FarmaciaService } from 'src/app/SERVICES/FARMACIE/farmacie';

@Component({
  selector: 'app-farmacie-list',
  templateUrl: './farmacie-list.component.html',
  styleUrls: ['./farmacie-list.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'translateY(100%)' }),
        animate(200),
      ]),
      transition('* => void', [
        animate(200, style({ opacity: 0, transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class FarmacieListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() farmaciaSelectedEmit = new EventEmitter<any>();
  displayedColumns: string[] = [
    'ragione_Sociale_Farmacia',
    'codice_AlfaIntes',
    'elaborazione_Report',
    'partita_Iva',
    'indirizzo',
    'cap',
    'comune',
    'provincia',
    'regione',
    'ricerca_Di_Mercato',
    'class_Enasarco',
    'nominativo_Contatto',
    'numero_Telefonico_Fisso',
    'numero_Telefonico_Cellulare',
    'email',
    'data_Ultimo_Ordine',
    'importo_Ultimo_Ordine',
    'classe_Principale',
    'classe_Clienti',
    'classe_Mercurio',
    'classe_Di_Provenienza',
    'email_Listino_Inviata',
    'data_Invio_EmailListino',
    'contratto',
    'data_Invio_Contratto',
    'data_Ricezione_Contratto',
    'proposta_Ordine',
    'data_Proposta_Ordine',
    'motivi_No_Ordine',
    'nome_Centro_Acquisto',
    'note',
    'ultima_Chiamata_Effettuata',
    'stato',
  ];

  searchBox: any = {
    Ragione_Sociale_Farmacia: '',
    Codice_AlfaIntes: '',
    Partita_Iva: '',
    Indirizzo: '',
    elaborazione_Report: '',
    CAP: '',
    Comune: '',
    Provincia: '',
    Regione: '',
    ricerca_Di_Mercato: '',
    Class_Enasarco: '',
    Nominativo_Contatto: '',
    Numero_Telefonico_Fisso: '',
    Numero_Telefonico_Cellulare: '',
    Email: '',
    data_Ultimo_Ordine: '',
    Importo_Ultimo_Ordine: '',
    Classe_Principale: '',
    Classe_Clienti: '',
    Classe_Mercurio: '',
    Classe_Di_Provenienza: '',
    Email_Listino_Inviata: '',
    Data_Invio_EmailListino: '',
    Contratto: '',
    Data_Invio_Contratto: '',
    Data_Ricezione_Contratto: '',
    Proposta_Ordine: '',
    Data_Proposta_Ordine: '',
    Motivi_No_Ordine: '',
    Nome_Centro_Acquisto: '',
    Note: '',
    Ultima_chiamata_effettuata: '',
    Stato: '',
  };

  dataSource: any;
  dataSourceDeleteItems: any;
  showAdd = false;
  listIdToModify = [];
  listToModify = [];
  listSelectedItems = [];
  isModify = false;
  isFunctionAlreadyCalled = false;
  showModalElimina = false;
  lunghezzaPaginator;
  subscriptionToModify;
  showTable = true;
  lastValueSearched = '';

  constructor(private farmaciaService: FarmaciaService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.showTable = false;
    this.farmaciaService
      .getAllFile(0, 5)
      .pipe(tap(() => (this.showTable = true)))
      .subscribe((res) => {
        this.dataSource = new MatTableDataSource(res.data.pageList);
        setTimeout(() => {
          this.lunghezzaPaginator = res.data.nrOfElements;
          // this.dataSource.paginator = this.paginator;
        }, 100);
      });
  }

  applyFilter(event: Event, column) {
    this.lastValueSearched = column.toLowerCase();
    column = this.lastValueSearched;
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchBox[column] = filterValue.trim().toLowerCase();
    if (!this.searchBox[column]) {
      this.lastValueSearched = null;
      column = this.lastValueSearched;
    }
    let endpoint = column + '=' + this.searchBox[column];
    if (column === 'nominativo_contatto') {
      this.farmaciaService
        .ricercaEsclusiva('ricerca_nominativi', 0, 5, this.searchBox[column])
        .subscribe((res) => {
          this.dataSource = new MatTableDataSource(
            this.getFarmaciaData(res.data.pageList)
          );
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
          }, 100);
        });
    } else if (column === 'numero_telefonico_fisso') {
      this.farmaciaService
        .ricercaEsclusiva('ricerca_numeri_fissi', 0, 5, this.searchBox[column])
        .subscribe((res) => {
          this.dataSource = new MatTableDataSource(
            this.getFarmaciaData(res.data.pageList)
          );
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
          }, 100);
        });
    } else if (column === 'numero_telefonico_cellulare') {
      this.farmaciaService
        .ricercaEsclusiva(
          'ricerca_numeri_cellulare',
          0,
          5,
          this.searchBox[column]
        )
        .subscribe((res) => {
          this.dataSource = new MatTableDataSource(
            this.getFarmaciaData(res.data.pageList)
          );
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
          }, 100);
        });
    } else {
      this.farmaciaService
        .getAllFile(0, 5, column, this.searchBox[column])
        .subscribe((res) => {
          this.dataSource = new MatTableDataSource(res.data.pageList);
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
          }, 100);
        });
    }
  }

  getFarmaciaData(res) {
    let listaToReturn = [];
    res.forEach((element) => {
      listaToReturn.push(element.farmacia);
    });
    listaToReturn.forEach((element) => {
      let nominativoContatto = [];
      element.nominativoContatto.forEach((element) => {
        nominativoContatto.push(element.contatto);
      });
      element.ragione_Sociale_Farmacia = element.ragioneSocialeFarmacia;
      element.codice_AlfaIntes = element.codiceAlfaintes;
      element.partita_Iva = element.partitaIva;
      element.data_Ultimo_Ordine = element.dataUltimoOrdine;
      element.importo_Ultimo_Ordine = element.importoUltimoOrdine;
      element.classe_Principale = element.classePrincipale;
      element.classe_Clienti = element.classeClienti;
      element.classe_Mercurio = element.classeMercurio;
      element.classe_Di_Provenienza = element.classeClienti;
      element.email_Listino_Inviata = element.dataInvioEmailListino;
      element.data_Invio_Contratto = element.dataInvioContratto;
      element.data_Ricezione_Contratto = element.dataRicezioneContratto;
      element.proposta_Ordine = element.propostaOrdine;
      element.data_Proposta_Ordine = element.dataPropostaOrdine;
      element.motivi_No_Ordine = element.motiviNoOrdine;
      element.nome_Centro_Acquisto = element.nomeCentroAcquisto;
      element.ultima_Chiamata_Effettuata = element.ultimaChiamataEffettuata;
      element.stato = element.stato;
      element.nominativoContatto = nominativoContatto;
    });
    listaToReturn.forEach((element) => {
      let numeroFisso = [];
      element.numero_fisso.forEach((element) => {
        numeroFisso.push(element.numero_fisso);
      });
      element.numero_Telefonico_Fisso = numeroFisso;
    });
    listaToReturn.forEach((element) => {
      let numeroCell = [];
      element.numero_cellulare.forEach((element) => {
        numeroCell.push(element.numero_cellulare);
      });
      element.numero_Telefonico_Cellulare = numeroCell;
    });
    return listaToReturn;
  }

  getEndpoint() {
    let endpoint = '?';
    this.displayedColumns.forEach((element) => {
      if (this.searchBox[element]) {
        endpoint = endpoint + element + '=' + this.searchBox[element] + '&';
      }
    });
    return endpoint.slice(0, -1);
  }

  getLabel(value: string) {
    try {
      return value.replace(/_/g, ' ');
    } catch (error) {
      return value;
    }
  }

  scrollTable(value) {
    let div = document.getElementById('scrollableTableFarmacie');
    div.scrollTop = value;
  }

  modifyRow(element) {
    this.listIdToModify.push(element.id);
    this.listToModify.push(element);
    this.isModify = true;
  }

  changeVisual(event) {
    let column;
    try {
      column = this.lastValueSearched.toLowerCase();
    } catch (error) {
      column = null;
    }
    if (column === 'nominativo_contatto') {
      this.farmaciaService
        .ricercaEsclusiva(
          'ricerca_nominativi',
          event.pageIndex,
          event.pageSize,
          this.searchBox[column]
        )
        .subscribe((res) => {
          this.dataSource = new MatTableDataSource(
            this.getFarmaciaData(res.data.pageList)
          );
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            this.scrollTable(0)
            // this.dataSource.paginator = this.paginator;

          }, 100);
        });
    } else if (column === 'numero_telefonico_fisso') {
      this.farmaciaService
        .ricercaEsclusiva(
          'ricerca_numeri_fissi',
          event.pageIndex,
          event.pageSize,
          this.searchBox[column]
        )
        .subscribe((res) => {
          this.dataSource = new MatTableDataSource(
            this.getFarmaciaData(res.data.pageList)
          );
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            this.scrollTable(0)
            // this.dataSource.paginator = this.paginator;
          }, 100);
        });
    } else if (column === 'numero_telefonico_cellulare') {
      this.farmaciaService
        .ricercaEsclusiva(
          'ricerca_numeri_cellulare',
          event.pageIndex,
          event.pageSize,
          this.searchBox[column]
        )
        .subscribe((res) => {
          this.dataSource = new MatTableDataSource(
            this.getFarmaciaData(res.data.pageList)
          );
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
          }, 100);
        });
    } else {
      this.farmaciaService
        .getAllFile(
          event.pageIndex,
          event.pageSize,
          column,
          this.searchBox[column]
        )
        .subscribe((res) => {
          this.dataSource = new MatTableDataSource(res.data.pageList);
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
          }, 100);
        });
    }
  }

  rowSelected(id) {
    if (!this.isFunctionAlreadyCalled) {
      this.isFunctionAlreadyCalled = true;
      setTimeout(() => {
        if (!this.isModify) {
          let index = this.listSelectedItems.indexOf(id);
          if (index === -1) {
            this.listSelectedItems.push(id);
          } else {
            this.listSelectedItems.splice(index, 1);
          }
        } else {
          this.isModify = false;
        }
        this.isFunctionAlreadyCalled = false;
      }, 200);
    }
  }

  checkType(variabile) {
    return (
      typeof variabile === 'string' ||
      typeof variabile === 'number' ||
      typeof variabile === 'boolean'
    );
  }
  deleteFiles() {
    this.farmaciaService.deleteFile(this.listSelectedItems).subscribe((res) => {
      this.listSelectedItems = [];
      this.listIdToModify = [];
      this.dataSourceDeleteItems = undefined;
      this.listToModify = [];
      this.showModalElimina = false;
      this.lunghezzaPaginator = undefined;
      this.changeVisual({ pageIndex: 0, pageSize: 5 });
    });
  }
  modalElimina() {
    this.showModalElimina = true;
    this.farmaciaService
      .getAllFileById(this.listSelectedItems)
      .subscribe((res) => {
        this.dataSourceDeleteItems = new MatTableDataSource(res.data);
      });
  }
  modifyRows(index) {
    if (this.subscriptionToModify) {
      this.subscriptionToModify.unsubscribe();
    }
    try {
      this.listToModify[index].nominativo_Contatto =
        this.listToModify[index].nominativo_Contatto.split(',');
    } catch (error) {}
    try {
      this.listToModify[index].numero_Telefonico_Fisso = this.listToModify[
        index
      ].numero_Telefonico_Fisso
        .trim()
        .split(',')
        .map((i) => Number(i));
    } catch (error) {}
    try {
      this.listToModify[index].numero_Telefonico_Cellulare = this.listToModify[
        index
      ].numero_Telefonico_Cellulare
        .trim()
        .split(',')
        .map((i) => Number(i));
    } catch (error) {}
    this.subscriptionToModify = this.farmaciaService
      .modificaFarmacia(this.listToModify[index])
      .subscribe((element) => {
        if (index < this.listToModify.length - 1) {
          this.modifyRows(index + 1);
        } else {
          this.listSelectedItems = [];
          this.listIdToModify = [];
          this.dataSourceDeleteItems = undefined;
          this.listToModify = [];
          this.showModalElimina = false;
          this.lunghezzaPaginator = undefined;
          this.changeVisual({ pageIndex: 0, pageSize: 5 });
        }
      });
  }
  typeOf(type) {
    return typeof type;
  }
  goBackAdd() {
    this.farmaciaService.getAllFile(0, 5).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.data.pageList);
      this.showAdd = false;
      this.showTable = true;
      setTimeout(() => {
        this.lunghezzaPaginator = res.data.nrOfElements;
        // this.dataSource.paginator = this.paginator;
      }, 100);
    });
  }
  annullaModifyRows() {
    this.lunghezzaPaginator = undefined;
    this.listIdToModify = [];
    this.showTable = false;
    this.goBackAdd();
  }
  emitEvent(id) {
    this.farmaciaSelectedEmit.emit(id);
  }
}
