import { Component, OnInit } from '@angular/core';
import { IOTService } from '../serviceIOT.service';


@Component({
  selector: 'app-lista-allarmi',
  templateUrl: './lista-allarmi.component.html',
  styleUrls: ['./lista-allarmi.component.scss']
})
export class ListaAllarmiComponent implements OnInit {

  jsonDati = [
    {
      allarme:'Allarme per il dispositivo Smartwatch di Roberto! Valore di BodyTemperature uguale a 35.4. Sotto il limite di 80.0.',
      data: new Date(),
      tipologia: 'WEARABLE',
      dispositivo: 'Smartwatch di Roberto',
      icona: 'co2',
      nota: 'Questa è una nota'
    },
    {
      allarme:'Allarme per il dispositivo Smartwatch di Roberto! Valore di BodyTemperature uguale a 35.4. Sotto il limite di 80.0.',
      data: new Date(),
      tipologia: 'WEARABLE',
      dispositivo: 'Smartwatch di Roberto',
      icona: 'co2'

    },
    {
      allarme:'Allarme per il dispositivo Smartwatch di Roberto! Valore di BodyTemperature uguale a 35.4. Sotto il limite di 80.0.',
      data: new Date(),
      tipologia: 'WEARABLE',
      dispositivo: 'Smartwatch di Roberto',
      icona: 'co2',
      nota: 'Questa è una nota'

    },
    {
      allarme:'Allarme per il dispositivo Smartwatch di Roberto! Valore di BodyTemperature uguale a 35.4. Sotto il limite di 80.0.',
      data: new Date(),
      tipologia: 'WEARABLE',
      dispositivo: 'Smartwatch di Roberto',
      icona: 'co2'

    },
    {
      allarme:'Allarme per il dispositivo Smartwatch di Roberto! Valore di BodyTemperature uguale a 35.4. Sotto il limite di 80.0.',
      data: new Date(),
      tipologia: 'WEARABLE',
      dispositivo: 'Smartwatch di Roberto',
      icona: 'co2',
      nota: 'Questa è una nota'

    },
    {
      allarme:'Allarme per il dispositivo Smartwatch di Roberto! Valore di BodyTemperature uguale a 35.4. Sotto il limite di 80.0.',
      data: new Date(),
      tipologia: 'WEARABLE',
      dispositivo: 'Smartwatch di Roberto',
      icona: 'co2'

    },
    {
      allarme:'Allarme per il dispositivo Smartwatch di Roberto! Valore di BodyTemperature uguale a 35.4. Sotto il limite di 80.0.',
      data: new Date(),
      tipologia: 'WEARABLE',
      dispositivo: 'Smartwatch di Roberto',
      icona: 'co2',
      nota: 'Questa è una nota'

    },
    {
      allarme:'Allarme per il dispositivo Smartwatch di Roberto! Valore di BodyTemperature uguale a 35.4. Sotto il limite di 80.0.',
      data: new Date(),
      tipologia: 'WEARABLE',
      dispositivo: 'Smartwatch di Roberto',
      icona: 'co2'

    },
    {
      allarme:'Allarme per il dispositivo Smartwatch di Roberto! Valore di BodyTemperature uguale a 35.4. Sotto il limite di 80.0.',
      data: new Date(),
      tipologia: 'WEARABLE',
      dispositivo: 'Smartwatch di Roberto',
      icona: 'co2',
      nota: 'Questa è una nota'

    }
  ]

  constructor(public iotService: IOTService) { }

  ngOnInit(): void {
  }

}
