import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Posizione } from 'src/app/MODELS/ASSISTENZA/posizione';
import { Sessione } from 'src/app/MODELS/ASSISTENZA/sessione';
import { Utente } from 'src/app/MODELS/USER/utente';
import { UsersService, AccountService, AlertService, ShareService } from 'src/app/SERVICES';
import { ExportExcelService } from 'src/app/SERVICES/FILE/excel.service';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { DatePipe } from '@angular/common';

import * as L from 'leaflet'
import { Map } from 'leaflet';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-report-geo',
  templateUrl: './report-geo.component.html',
  styleUrls: ['./report-geo.component.scss']

})
export class ReportGeoComponent implements OnInit {

  hoveredDate: Date | null = null;
  tutti;
  fromDate;

  newDate: Date;

  toDate;


  dayDate: Date;

  cols: any[];

  users: Utente[];

  allUser: Utente[];

  searchform: FormGroup;

  selectedUsers3: Utente[] = [];

  selezionaUtente: boolean = true;

  utentiSelezionati: Utente[];

  idUtentiSelezionati: String[];
  today = new Date();
  oggi;
  dd = String(this.today.getDate()).padStart(2, '0');
  mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
  yyyy = this.today.getFullYear();
  todayDay = this.dd;
  todayMonth = this.mm;
  todayYear = this.yyyy;

  calendar: FormGroup;
  toDay;
  toMonth;
  toYear;
  fromDay;
  fromMonth;
  fromYear;
  usersVideo;
  usersGeo;
  dataOK: boolean = true;
  value1: Date;
  value2: Date;
  dates: Date[];
  rangeDates: Date[];
  date1: string;
  report: boolean = false;
  loading = false;
  loading2 = false;
  ita;

// -------------------mappa report ----------------------------------------

caricato = false;
idUserSelected;
selectedMappa = true;
selezionaDataReport = false;
utenteMappa: Utente = {};
usersGeoMappa;
listaUtentiTracciamentoMappa = [];
mappaCaricata= false;
visioneMappa = false;
  map :any /*Map*/;
  public fullscreenOptions: {[key:string]:any} = {
    position: 'topleft',
    title: 'View Fullscreen',
    titleCancel: 'Exit Fullscreen',
  };
  usersMappa = [];
  intervallo : any;
  allUserMappa;
  cords = [];
  open = true;
  traccia = false;
  allcords;
  fullScreenMap : boolean = false;
  i = 0;
  mainLayer;
  markers = [];
  firstTime= false;
  subscription : Subscription;
  nome;
  colorArray2 = ['#0000ff', '#0300fc', '#0500fa', '#0800f7', '#0a00f5', '#0d00f2', '#0f00f0', '#1200ed', '#1500ea', '#1700e8', '#1a00e5', '#1c00e3', '#1f00e0', '#2100de', '#2400db', '#2700d8', '#2900d6', '#2c00d3', '#2e00d1', '#3100ce', '#3400cb', '#3600c9', '#3900c6', '#3b00c4', '#3e00c1', '#4000bf', '#4300bc', '#4600b9', '#4800b7', '#4b00b4', '#4d00b2', '#5000af', '#5200ad', '#5500aa', '#5800a7', '#5a00a5', '#5d00a2', '#5f00a0', '#62009d', '#64009b', '#670098', '#6a0095', '#6c0093', '#6f0090', '#71008e', '#74008b', '#760089', '#790086', '#7c0083', '#7e0081', '#81007e', '#83007c', '#860079', '#890076', '#8b0074', '#8e0071', '#90006f', '#93006c', '#95006a', '#980067', '#9b0064', '#9d0062', '#a0005f', '#a2005d', '#a5005a', '#a70058', '#aa0055', '#ad0052', '#af0050', '#b2004d', '#b4004b', '#b70048', '#b90046', '#bc0043', '#bf0040', '#c1003e', '#c4003b', '#c60039', '#c90036', '#cb0034', '#ce0031', '#d1002e', '#d3002c', '#d60029', '#d80027', '#db0024', '#de0021', '#e0001f', '#e3001c', '#e5001a', '#e80017', '#ea0015', '#ed0012', '#f0000f', '#f2000d', '#f5000a', '#f70008', '#fa0005', '#fc0003', '#ff0000']
  colorArray1 = ['#FF0000', '#FF8B00', '#0800FF', '#70FF00', '#00FFC9',
    '#0080FF', '#F0D218', '#9E00FF', '#FF00B9', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];
  colorArray = this.colorArray1;
  usersStatic = [];
  searchformMappa: FormGroup;
  markerHtmlStyles = `
  color: ${this.colorArray[this.i]};
  font-size: xx-large;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  -webkit-text-stroke: 1px black;
  `
  
  smallIcon = L.divIcon({
    className: "my-custom-pin",
    iconAnchor: [0, 24],
    popupAnchor: [0, -36],
    html: `<i class="material-icons notranslate" style="${this.markerHtmlStyles}">place</i>`
  });
  

  constructor(private userService: UsersService, private formBuilder: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private alertService: AlertService,
    private fileService: FileService,
    private excelService: ExportExcelService,
    private datePipe : DatePipe,
    private shareService : ShareService) {

      
      this.mainLayer = L.tileLayer('https://{s}.tile.openStreetMap.org/{z}/{x}/{y}.png', {
        minZoom: 6,
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      });
      

    this.searchformMappa = this.formBuilder.group({
      searchMappa: ['', Validators.required]
    })

  }

  ngOnInit() {

    this.ita = {
      firstDayOfWeek: 1,
      dayNames: ["Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
      dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
      monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
      monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
    };



    this.buildForm();
    this.userService.getAllReport().subscribe((res) => {
      this.users = res.data.listaUtentiAttivi;
      res.data.listaUtentiCancellati.forEach(element => {
        this.users.push(element)
      });
      this.allUser = this.users;
      this.report = true;

    }, (error) => {

    });

    this.cols = [
      { field: 'email', header: 'Email' },
      { field: 'nome', header: 'Nome' },
      { field: 'cognome', header: 'Cognome' },
      { field: 'gruppos[0].nomeGruppo', header: 'Gruppo' }
    ];

    this.searchform = this.formBuilder.group({
      search: ['', Validators.required]
    })

  }
  buildForm(): void {
    this.calendar = this.formBuilder.group({

      calendario: new FormControl('', Validators.required),

    });
  }
  selectAll() { }

  searchInUser() {
    let valore: string = this.searchform.value.search;
    this.users = this.allUser;
    if (valore) {
      let users = []
      this.users.forEach(user => {
        user.gruppos.forEach(gruppo => {
          if (gruppo.nomeGruppo.includes(valore) || gruppo.nomeGruppo.toLowerCase().includes(valore) || gruppo.nomeGruppo.toUpperCase().includes(valore)) {
            if (!(users.find(x => x === user))) {
              users.push(user);
            }
          }
        })

        if (user.nome.includes(valore) || user.nome.toLowerCase().includes(valore.toLowerCase()) || user.cognome.includes(valore) || user.cognome.toLowerCase().includes(valore.toLowerCase())
          || user.nome.toUpperCase().includes(valore) || user.cognome.toUpperCase().includes(valore) ||
          user.email.includes(valore) || user.email.toLowerCase().includes(valore.toLowerCase()) || user.email.toUpperCase().includes(valore) ||
          (user.nome + ' ' + user.cognome).toLocaleLowerCase().includes(valore.toLowerCase()) || (user.cognome + ' ' + user.nome).toLocaleLowerCase().includes(valore.toLowerCase())
        ) {
          if (!(users.find(x => x === user))) {
            users.push(user);
          }
        }
      });
      this.users = users;
    } else if (this.users !== this.allUser) {
      this.users = this.allUser;
    }
  }

  avanti() {
    this.alertService.clear();
    if (this.selectedUsers3.length > 0) {
      this.utentiSelezionati = this.selectedUsers3;
      this.selezionaUtente = false;
      this.selezionaDataReport = true;
    } 
    else {
      this.alertService.error("Seleziona uno o più utenti");
    }
  }

  indietro() {
    this.selezionaUtente = true;
    this.selectedUsers3 = [];
    this.visioneMappa = false;
    this.selezionaDataReport = false;
  }
  selezionaData() {


    if (this.rangeDates !== undefined && this.rangeDates[0] !== null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.fromDay = dd;
      this.fromMonth = mm;
      this.fromYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] !== null) {
      var dd = String(this.rangeDates[1].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[1].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[1].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] === null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates === undefined) {
      this.fromDay = "01";
      this.fromMonth = "01";
      this.fromYear = "1990";

      this.toDay = this.todayDay;
      this.toMonth = this.todayMonth;
      this.toYear = this.todayYear;
    }

  }

 

  exportExcelGeo() {

    let usersGeo = this.usersGeo.data;


    let usersLoc: Posizione[] = new Array;

    usersGeo.forEach(user => {
      let posizione: Posizione = new Posizione;

      posizione.data = this.datePipe.transform(user.createdAt, 'dd/MM/yy HH:mm:ss') ;
      posizione.latitudine = user.latitudine;
      posizione.longitudine = user.longitudine;
      posizione.comune = user.capComune;
      posizione.indirizzo = user.indirizzo;
      posizione.nome = user.utente.nome + ' ' + user.utente.cognome;
      posizione.gruppo = '';
      user.utente.gruppos.forEach(gruppo => {
        posizione.gruppo = posizione.gruppo + gruppo.nomeGruppo;
      });

      posizione.dettaglio = user.utente.dettaglio[0]?.dettaglioAggiuntivo;
     
      usersLoc.push(posizione);
    });

    if (usersLoc.length >= 1) {
      this.loading2 = false;
      this.exportToExcel(usersLoc);

    }
    else {
      this.loading2 = false;
      if (this.dataOK) {
        this.alertService.info('Nessun report presente per gli utenti e/o le date selezionate');
      }
    }

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }



  inviaDati() {
    
    this.visioneMappa = true;
    

    this.loading2 = true;
    this.selezionaData();

    this.idUtentiSelezionati = new Array;
    this.utentiSelezionati.forEach(user => {
      let id: string;
      id = user.id;
      this.idUtentiSelezionati.push(id);

      let utente = {
        id: user.id,
        nome:  user.nome,
        cognome:  user.cognome

      }

    });

    this.fileService.inviaDatiReport2(
      this.idUtentiSelezionati,
      this.fromDay, this.fromMonth, this.fromYear,
      this.toDay, this.toMonth, this.toYear).
      subscribe((res) => {

        this.usersGeo = res;
        this.usersGeoMappa = res.data;
        this.exportExcelGeo();
        this.getLista();
      }, (error) => {
        this.loading2 = false;
      }
      );

      this.selezionaDataReport = false;
      this.visioneMappa = true;
  }

  dataForExcel = [];


  exportToExcel(usersGeo) {
    this.dataForExcel = [];
   usersGeo.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })

    let reportData = {
      title: 'Report Geolocalizzazione',
      data: this.dataForExcel,
      headers: Object.keys(usersGeo[0])
    }

    this.excelService.exportExcel(reportData);
  }

  // ----------------------------------------------------mappa report

  searchInUserMappa() {
    if (this.usersStatic !== null){
    let valore: string = this.searchformMappa.value.searchMappa;
    this.usersStatic = this.allUserMappa;
    if (valore) {
      let usersMappaTemp = []
      this.usersStatic.forEach(user => {
        let nomeCognome = user.nome + ' ' + user.cognome;
        let cognomeNome = user.cognome + ' ' + user.nome;

        if (cognomeNome.toLocaleLowerCase().includes(valore.toLocaleLowerCase()) || nomeCognome.toLocaleLowerCase().includes(valore.toLocaleLowerCase()) 
         || user.nome.toLowerCase().includes(valore.toLowerCase()) || user.cognome.toLowerCase().includes(valore.toLowerCase())
         
        ) {
          if (!(usersMappaTemp.find(x => x === user))) {
          usersMappaTemp.push(user);
          }
        }
      });
      this.usersStatic = usersMappaTemp;
    } else if (this.usersMappa !== this.allUserMappa) {
      this.usersStatic = this.allUserMappa;
    }}
  }



  getLista() {
 this.setLista(); this.creaMappa();
  }


  setLista() {
    let i = 0;
    this.usersStatic = [];
    let userIdAppoggio;
    let arraylat = [];
    let arraylong = [];
    let arrayComune = [];
    let arrayTime = [];
    let arrayIndirizzo = [];
    let utenteMappa = {
      id: null,
      nome: null,
      cognome: null,
      gruppos: null,
      capcomune: [],
      token: i.toString(),
      latitudine: [],
      longitudine: [],
      tempo: [],
      indirizzo: []
    };
    // sort userMappa
    
    this.usersGeoMappa.forEach(userMappa => {
      let data = this.datePipe.transform(userMappa.createdAt, 'dd/MM/yy HH:mm:ss') 

      if (userMappa.utente.id !== userIdAppoggio) {

        arraylat = []
        arraylong = []
        arrayComune = [];
        arrayIndirizzo = [];
        arrayTime = [];
        utenteMappa = {
          id: userMappa.utente.id,
          nome: userMappa.utente.nome,
          cognome: userMappa.utente.cognome,
          gruppos: userMappa.utente.gruppos,
          capcomune: [],

          token: i.toString(),
          latitudine: [],
          longitudine: [],
          tempo: [],
          indirizzo: []
          
        };

        arrayComune.push(userMappa.capComune);
        arraylat.push(userMappa.latitudine);
        arraylong.push(userMappa.longitudine);
        arrayTime.push(data);
        arrayIndirizzo.push(userMappa.indirizzo);
        userIdAppoggio = userMappa.utente.id;
        utenteMappa.latitudine = arraylat;
        utenteMappa.longitudine = arraylong;
        utenteMappa.capcomune = arrayComune;
        utenteMappa.tempo = arrayTime;
        utenteMappa.indirizzo = arrayIndirizzo;
        this.usersStatic.push(utenteMappa);
        i++
      }else{
        arraylat.push(userMappa.latitudine);
        arraylong.push(userMappa.longitudine);
        arrayComune.push(userMappa.capComune);
        arrayTime.push(data);
        arrayIndirizzo.push(userMappa.indirizzo);
        utenteMappa.latitudine = arraylat;
        utenteMappa.longitudine = arraylong;
        utenteMappa.capcomune = arrayComune;
        utenteMappa.tempo = arrayTime;
        utenteMappa.indirizzo = arrayIndirizzo;

  }
    })

        this.allUserMappa = this.usersStatic;
  }

  creaMappa() {
    let cordinate = {
      lat: 40.86,
      lng: 14.28
    }
    navigator.geolocation.getCurrentPosition(res => {
      cordinate.lat = res.coords.latitude;
      cordinate.lng = res.coords.longitude;
      let a = 14
      let zoomLevel = a;

      this.map = L.map('map', {
        center: [cordinate.lat, cordinate.lng],
        zoom: zoomLevel,
        dragging: true
      })

      if(Object.keys(L.control).includes("fullscreen")) {
        L.control["fullscreen"]().addTo(this.map);
      }     
      
      this.mainLayer.addTo(this.map);
      
      let w = 0;
      this.tracciamento(this.usersStatic[0].id, this.usersStatic[0].nome, this.usersStatic[0], 0);

      this.firstTime = true;
      this.mappaCaricata = true;
    },
    error => {
      this.mappaCaricata = true;
      this.alertService.error('Per continuare, è necessario fornire i permessi di geolocalizzazione')
      this.router.navigate([''])
  })
      this.caricato = true;
  }

  addMarker(cordinate1, cordinate2, option?, lunghezza?) {
    if(lunghezza===1){
      this.i = 99
    }
    let markerHtmlStyles = `
    color: ${this.colorArray[this.i]};
    font-size: xx-large;
    display: block;
    left: -1.5rem;
    top: -1.5rem;
    position: relative;
    -webkit-text-stroke: 1px black;
    `
      
    let smallIcon = L.divIcon({
      className: "my-custom-pin",
      iconAnchor: [0, 24],

      popupAnchor: [0, -36],
      html: `<i class="material-icons notranslate" style="${markerHtmlStyles}">place</i>`
    })
    let marker;
 
    if(option){
      marker = L.marker([cordinate1, cordinate2], { icon: smallIcon }).addTo(this.map).bindPopup(option).openPopup();
    }
    else{
      marker = L.marker([cordinate1, cordinate2], { icon: smallIcon }).addTo(this.map);
    }

    this.markers.push(marker);
    if(lunghezza){
      this.i = this.i + (Math.floor(100 / lunghezza) + 1)
      if(lunghezza===1){
        this.i = 99
      }
      if(this.i > 99){
        this.i = 99
      }
    }
    else{
      this.i = this.i + 1;
    }
    
  }

  getRandomColor() {
    var color = this.colorArray[this.i];
    
    return color;
  }

  zoom(lat, lng) {
    
    var latlng = L.latLng(lat, lng);
    this.map.setView(latlng, 10);
    
  }

  onMapReady(map: any/*Map*/) {
    this.map = map;
  }

  tracciamento(id, nome, user, idSelezionato){

    this.idUserSelected = user.id;
    this.nome = nome;
    this.open=false;
    let lista : string[] = new Array;
    lista.push(id);
    
          this.traccia = true;
          this.colorArray = this.colorArray2;
          this.i = 0;
          let o = 0;
          this.markers.forEach(marker => {
            this.map.removeLayer(marker);
          })
            this.markers = [];
            let primoRisultato = true;
            user.latitudine.forEach(user1 => {
          if(primoRisultato){
            this.zoom(user.latitudine[o], user.longitudine[o])
          }
          primoRisultato = false;

          let option;
          if(!user.capcomune[o] && !user.indirizzo[o]){
            option = "Ora: " + user.tempo[o];
            
          } else if(!user.indirizzo[o]){
            "Indirizzo: " + user.capcomune[o]+ ", Ora: " + user.tempo[o];
          }
          else if(!user.capcomune[o]){
            option = "Indirizzo: " +user.indirizzo[o]+ ", Ora: " + user.tempo[o];
          } else {
            option = "Indirizzo: " + user.indirizzo[o] + ', ' +user.capcomune[o]+ ", Ora: " + user.tempo[o];
          }
          
          this.addMarker(user.latitudine[o], user.longitudine[o], option, user.latitudine.length);
          o++;
        });

}

clearMarkers(){
  if(this.open){
  this.i = 0;
  this.markers.forEach(marker => {
    this.map.removeLayer(marker);
  })
  this.markers = [];
}
}

indietroMappa() {
  this.selezionaUtente = false;
  this.selectedUsers3 = null;
  this.visioneMappa = false;
  this.caricato = false;
  this.mappaCaricata = false;
  this.selezionaDataReport = true;
  this.stopTracciamento(); 
  this.mainLayer = undefined
  
  this.mainLayer = L.tileLayer('https://{s}.tile.openStreetMap.org/{z}/{x}/{y}.png', {
    minZoom: 6,
    maxZoom: 19,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  });
  
}

stopTracciamento(){
  this.colorArray = this.colorArray1;
  this.i = 0;
  this.markers.forEach(marker => {
    this.map.removeLayer(marker);
  })
  this.markers = [];
  let primaVolta = true;
  this.cords.forEach(user => {
    if(primaVolta){
      this.zoom(user.ultimaPosizione.latitudine, user.ultimaPosizione.longitudine)
    }
    primaVolta = false;
    this.addMarker(user.ultimaPosizione.latitudine, user.ultimaPosizione.longitudine);
  });
  this.open = true;
  this.traccia = false;
}

}




