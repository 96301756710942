<div class="content">
  <div class="container-fluid">
    <div *ngIf="!caricato">
      <div class="text-center">
        <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5" styleClass="custom-spinner2"
          animationDuration=".5s"></p-progressSpinner>
      </div>
    </div>
    <ng-container *ngIf="report && caricato">
      <div class="col-xs-12">


        <ng-container *ngIf="selezionaAula">
          <h4>Seleziona {{ambiente.NOMEAULA}}</h4>
          <h5 *ngIf="listaVuota"> {{ambiente.nessunEducation}} disponibile</h5>
          <div *ngIf="!listaVuota">
            <div id="datatables_filter" class="dataTables_filter">
              <form [formGroup]="searchform">
                <label class="form-group" style="width: 100%;">
                  <input type="text" formControlName="search" class="form-control" placeholder="Cerca..."
                    maxlength="30">
                </label>
              </form>
              <div *ngIf="searchInAula()"></div>
            </div>
          </div>
          <div class="table-responsive "[ngClass]="{'scrollable':aule.length >=10,'scrollFix':aule.length<10}">

            <p-table *ngIf="!listaVuota" class="table table-striped " [value]="aule" [(selection)]="selectedAule"
              dataKey="nomeAula">

              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 3rem !important" class="pl-6">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th>Titolo</th>
                  <th>Descrizione</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-aula>
                <tr>
                  <td style="width: 3rem !important" class="pl-6">
                    <p-tableCheckbox [value]="aula"></p-tableCheckbox>
                  </td>
                  <td>{{aula.nomeAula}}</td>
                  <td>{{aula.descrizioneAula}}</td>


                </tr>

              </ng-template>

            </p-table>

          </div>


          <div class="text-right" *ngIf="!listaVuota">
            <a class="btn btn-primary" (click)="avanti()"
              style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Avanti</a>
          </div>
          <!-- <ng-template pTemplate="summary"> -->

          <!-- </ng-template> -->
        </ng-container>
      </div>

      <div *ngIf="sceltaDate">
        <div class="col-xs-12">

          <div class="card-content " class="text-center" style="margin-bottom: 3%;">
            <h4 style="margin-bottom: 3%;">Seleziona la data</h4>
            <!--<form [formGroup]="calendar" > -->
            <!-- <p-calendar [(ngModel)]="value1" [numberOfMonths]="2" [inline]="true" (onSelect)="onDateSelection($event)" ></p-calendar> -->
            <!-- </form> -->

            <form [formGroup]="calendar" class="box-model form-support-margin overflowAuto">
              <p-calendar view="date" formControlName="calendario" (onSelect)="selezionaData()" [maxDate]="today"
                [readonlyInput]="true" [numberOfMonths]="2" yearRange="2000:2030" [(ngModel)]="rangeDates"
                [showOtherMonths]="false" placeholder="Select time period" selectionMode="range" [inline]="true"
                dateFormat="dd/mm/yy" [locale]="ita">

              </p-calendar>
              <div class="card-footer text-right">
                <a class="btn btn-secondary" (click)="indietro()" style="color: white;">Indietro</a>


                <button [disabled]="loading" class="btn btn-primary" rel="tooltip" title="Scarica Report"
                  (click)="inviaDati()" style="max-width: 2%;"><i class="fa fa-folder"><span *ngIf="loading">
                      <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                        styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                    </span></i>

                </button>

              </div>
            </form>


            <!-- -----------------Grafici---------------- -->


            <!-- -----------------Grafici---------------- -->

          </div>


        </div>

      </div>
    </ng-container>

    <div *ngIf="grafici">
      <button (click)="indietroGrafici()" id="back-button" class="btn btn-round  btn-just-icon"
        style="margin-top: 3px; color: rgb(54, 54, 54); box-shadow:none!important">
        <i class="material-icons notranslate">arrow_back</i>
      </button>
      <h4 style="margin-bottom: 3%; display: inline;">Report da {{inizioPipe | date: 'dd/MM/yyyy'}} a {{finePipe | date:
        'dd/MM/yyyy'}}</h4>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="card-header">
            <div class="flexWrap">

            <h4 >Partecipazione Gruppi: {{nomeMostraChartGruppi}}</h4>

            <div class="dropdown" *ngIf="selectedAule.length>1"
              >
              <a class="collapsed" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                scegli {{ambiente.NOMEAULA}}
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu2"
                style="overflow-x: auto; max-height: 1400%">
                <li *ngFor="let aula of selectedAule; let i = index"><a
                    (click)="creaGraficogruppi(aula.id,aula.nomeAula)">{{aula.nomeAula}}</a>
                </li>
              </ul>
            </div>
            <i class="material-icons notranslate" (click)="scegliDownload('gruppi')"  style="cursor: pointer; color:#2196F3">download</i>

            </div>
            <div *ngIf="!mostraChartGruppi" class="m-20" style="justify-content: center; text-align: center;"><span>
                <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                  styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
              </span></div>
            <p-chart *ngIf="mostraChartGruppi" type="pie" [height]="300" [data]="dataGroup" #gruppi></p-chart>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="card-header ">
            <div class="flexWrap">

            <h4 >Report Entrate: {{nomeGraficoEntrate}}</h4>

            <div class="dropdown" *ngIf="selectedAule.length>1"
             >
              <a class="collapsed" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                scegli {{ambiente.NOMEAULA}}
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu2"
                style="overflow-x: auto; max-height: 1400%">
                <li *ngFor="let aula of selectedAule; let i = index"><a
                    (click)="creaGraficoEntrate(aula.id,aula.nomeAula)">{{aula.nomeAula}}</a>
                </li>
              </ul>
            </div>
            <i class="material-icons notranslate" (click)="scegliDownload('entrate')"  style=" cursor: pointer; color:#2196F3">download</i>

            </div>
            <div class="card-content table-responsive min-max-h-370 of-x-scrollHidden">
              <div *ngIf="entrateBianco" class="tuttoBianco">
                <div class="text-center" >
                    <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                    styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                </div>
            </div>
              <div *ngIf="!graficoEntrate" class="m-20 centered"><span>
                  <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                    styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                </span></div>
              <div [ngClass]="{'absolute': accendi}" style="margin-left: -20PX;">
                <p-chart *ngIf="graficoEntrate" type="line" [options]="optionsEntrate2" [height]="300"
                  [data]="dataGraficoEntrate2" ></p-chart>
              </div>
              <div class="w-100 relative bg-white ml-20 z-1 of-x-scroll ">
                <div [ngStyle]="{'width.px': widthGraficoEntrate}">
                  <p-chart *ngIf="graficoEntrate" type="line" [options]="optionsEntrate" [height]="300"
                    [data]="dataGraficoEntrate" #entrate></p-chart>
                  <!-- (onDataSelect)="selectData($event, 1)" -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="!graficoEntrate" class="m-20"
                                style="justify-content: center; text-align: center;"><span>
                                    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                                        styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                                </span></div>
     <p-chart *ngIf="graficoEntrate" type="line" [height]="300" [data]="dataGraficoEntrate"  #chart></p-chart>
 </div> -->


      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="card-header ">
            <div class="flexWrap">

            <h4>Partecipazioni espresse in Minuti: {{NomeGraficoDurata}}</h4>

            <div class="dropdown" *ngIf="selectedAule.length>1"
             >
              <a class="collapsed" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                scegli aula
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu2"
                style="overflow-x: auto; max-height: 1400%">
                <li *ngFor="let aula of selectedAule; let i = index"><a
                    (click)="creaGraficoDurata(aula.id,aula.nomeAula)">{{aula.nomeAula}}</a>
                </li>
              </ul>
            </div>
            <i class="material-icons notranslate" (click)="scegliDownload('durata')"  style="cursor: pointer; color:#2196F3">download</i>

</div>
            <div class="card-content table-responsive of-x-scrollHidden">
              <div *ngIf="durataBianco" class="tuttoBianco">
                <div class="text-center" >
                    <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                    styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                </div>
            </div>
              <div *ngIf="!graficoDurata" class="m-20 centered"><span>
                  <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                    styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                </span></div>
              <div [ngClass]="{'absolute': accendi}" style="margin-left: -12px;">
                <p-chart *ngIf="graficoDurata" type="bar" [options]="optionsDurata2" [height]="300"
                  [data]="dataGraficoDurata" ></p-chart>
              </div>
              <div class=" of-x-scroll relative bg-white  z-1" style="margin-left: 30px;">
                <div [ngStyle]="{'width.px': graficoDurataWeight}">
                  <p-chart *ngIf="graficoDurata" type="bar" [options]="optionsDurata" [height]="300"
                    [data]="dataGraficoDurata" #durata></p-chart>
                  <!-- (onDataSelect)="selectData($event, 1)" -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="!graficoDurata" class="m-20"
style="justify-content: center; text-align: center;"><span>
    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
        styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
</span></div>
   <p-chart *ngIf="graficoDurata" type="bar" [height]="300" [data]="dataGraficoDurata"   #chart></p-chart>
 </div> -->


      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="card-header">
            <div class="flexWrap">

            <h4 >Quantità Download per Esercitazione: {{NomeGraficoDwEs}}</h4>
  <!-- style="display: inline; position: absolute; right: 6%; margin-top: 3px;;" -->
            <div class="dropdown" *ngIf="selectedAule.length>1" >
              <a class="collapsed" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                scegli {{ambiente.NOMEAULA}}
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu2"
                style="overflow-x: auto; max-height: 1400%">
                <li *ngFor="let aula of selectedAule; let i = index"><a
                    (click)="creaGraficoDwEsercitazioni(aula.id,aula.nomeAula)">{{aula.nomeAula}}</a>
                </li>
              </ul>
            </div>
            <i class="material-icons notranslate" (click)="scegliDownload('download')"  style="cursor: pointer; color:#2196F3">download</i>

</div>
            <div class="card-content table-responsive of-x-scrollHidden">
              <div *ngIf="downloadBianco" class="tuttoBianco">
                <div class="text-center" >
                    <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                    styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                </div>
            </div>
              <div *ngIf="!graficoDownloadEsercitazioni" class="m-20 centered"><span>
                  <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                    styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                </span></div>
              <div [ngClass]="{'absolute': accendi}">
                <p-chart type="bar" [options]="optionsDownload2" [height]="300" [data]="dataDownloadEs" ></p-chart>
              </div>
              <div class="w-100 of-x-scroll relative bg-white ml-50 z-1">
                <div [ngStyle]="{'width.px': graficoDwEsercitazioniWeight}">
                  <p-chart type="bar" [options]="optionsDownload" [height]="300" [data]="dataDownloadEs" #nrDownload></p-chart>
                  <!-- (onDataSelect)="selectData($event, 1)" -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="!graficoDownloadEsercitazioni" class="m-20"
style="justify-content: center; text-align: center;"><span>
    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
        styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
</span></div>
   <p-chart *ngIf="graficoDownloadEsercitazioni" [height]="300" type="bar" [data]="dataDownloadEs"  #chart></p-chart>
 </div> -->


      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="card-header">
            <div class="flexWrap">
            <h4 >Quantità di Risposte per {{ambiente.ESERCITAZIONI}}: {{NomeGraficoEsNrRisposte}}</h4>

            <div class="dropdown" *ngIf="selectedAule.length>1"
              >
              <a class="collapsed" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                scegli {{ambiente.NOMEAULA}}
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu2"
                style="overflow-x: auto; max-height: 1400%">
                <li *ngFor="let aula of selectedAule; let i = index"><a
                    (click)="creaGraficoNrRisposte(aula.id,aula.nomeAula)">{{aula.nomeAula}}</a>
                </li>
              </ul>
            </div>
            <i class="material-icons notranslate" (click)="scegliDownload('risposte')"  style="cursor: pointer; color:#2196F3">download</i>

</div>
            <div class="card-content table-responsive of-x-scrollHidden">
              <div *ngIf="risposteBianco" class="tuttoBianco">
                <div class="text-center" >
                    <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                    styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                </div>
            </div>
              <div *ngIf="!graficoEsercitazioniNrRisposte" class="m-20 centered"><span>
                  <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                    styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                </span></div>
              <div class="absolute">
                <p-chart *ngIf="graficoEsercitazioniNrRisposte" type="bar" [options]="optionsNrRisposte2" [height]="300"
                  [data]="dataNrrisposteEs" ></p-chart>
              </div>
              <div class="w-100 of-x-scroll relative bg-white ml-50 z-1">
                <div [ngStyle]="{'width.px': graficoLezioniAsincroneWidth}">
                  <p-chart *ngIf="graficoEsercitazioniNrRisposte" type="bar" [options]="optionsNrRisposte" [height]="300"
                    [data]="dataNrrisposteEs" #risposte></p-chart>
                  <!-- (onDataSelect)="selectData($event, 1)" -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="!graficoEsercitazioniNrRisposte" class="m-20"
style="justify-content: center; text-align: center;"><span>
    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
        styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
</span></div>
   <p-chart *ngIf="graficoEsercitazioniNrRisposte" [height]="300" type="bar" [data]="dataNrrisposteEs" #chart></p-chart>
 </div> -->
      <!-- ---------nuove aggiunte----- -->

      <div *ngIf="videoOn" class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="card-header ">
            <div class="flexWrap">
            <h4 >Report Visualizzazione {{ambiente.LEZIONIREGISTRATE}}:
              {{nomeGraficoVideoAsincorni}}</h4>
              <h4>Lezione: {{nomeVideo}}</h4>
<!-- [ngClass]="{'visualListaClassiVideo': listaVideo.length>0,'visualVideo':listaVideo.length <1}" -->
            <div class="dropdown" *ngIf="selectedAule.length>0" >
              <a class="collapsed" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                scegli {{ambiente.NOMEAULA}}
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu2"
                style="overflow-x: auto; max-height: 1400%">
                <li *ngFor="let aula of selectedAule; let i = index"><a
                    (click)="getListaVideo(aula.id,aula.nomeAula)">{{aula.nomeAula}}</a>
                </li>
              </ul>
            </div>
            <div class="dropdown " *ngIf="listaVideo.length>0" >
              <a class="collapsed" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                scegli Video
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu2"
                style="overflow-x: auto; max-height: 1400%">
                <li *ngFor="let video of listaVideo; let i = index"><a
                    (click)="graficoLezioniAsincrone(video)">{{video.title}}</a>
                </li>
              </ul>
            </div>
            <i class="material-icons notranslate" (click)="scegliDownload('video')"  style="cursor: pointer; color:#2196F3">download</i>

          </div>
          <div class="card-content table-responsive of-x-scrollHidden">
            <div *ngIf="videoBianco" class="tuttoBianco">
              <div class="text-center" >
                  <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                  styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
              </div>
          </div>
            <div *ngIf="!graficoVideoAsincorno" class="m-20 centered"><span>
                <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8" styleClass="custom-spinner"
                  animationDuration=".5s"></p-progressSpinner>
              </span></div>
            <div [ngClass]="{'absolute':accendi , 'relative':!accendi}">
              <p-chart *ngIf="graficoVideoAsincorno" type="bar" [options]="optionsVideo2" [height]="300"
                [data]="dataVideoAsincroni" ></p-chart>
            </div>
            <div class="w-100 of-x-scroll relative bg-white ml-50 z-1">
              <div [ngStyle]="{'width.px': graficoLezioniAsincroneWidth}">
                <p-chart *ngIf="graficoVideoAsincorno" type="bar" [options]="optionsVideo" [height]="300"
                  [data]="dataVideoAsincroni" #video></p-chart>
                <!-- (onDataSelect)="selectData($event, 1)" -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="card-header">
            <div class="flexWrap">
            <h4 >Report Risposte ai sondaggi: {{nomeGraficoSondaggio}}</h4>
 <!-- [ngClass]="{'visualClassiSondaggio':listaSondaggio.length > 0,'visualSondaggio': listaSondaggio.length < 1 }" -->
            <div class="dropdown" *ngIf="selectedAule.length>0"
              >
              <a class="collapsed" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                Scegli {{ambiente.NOMEAULA}}
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu " role="menu" aria-labelledby="dropdownMenu2"
                style="overflow-x: auto; max-height: 1400%">
                <li *ngFor="let aula of selectedAule; let i = index"><a
                    (click)="getListaSondaggio(aula.id,aula.nomeAula)">{{aula.nomeAula}}</a>
                </li>
              </ul>
            </div>
            <div class="dropdown " *ngIf="listaSondaggio.length>0" >
              <a class="collapsed" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                Scegli Sondaggio
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu2"
                style="overflow-x: auto; max-height: 1400%">
                <li *ngFor="let sondaggio of listaSondaggio; let i = index"><a
                    (click)="graficoSondaggi(sondaggio.idAula,sondaggio.titolo)">{{sondaggio.titolo}}</a>
                </li>
              </ul>
            </div>
            <i class="material-icons notranslate" (click)="scegliDownload('sondaggi')"  style="cursor: pointer; color:#2196F3">download</i>

          </div>
            <div *ngIf="!graficoSondaggio" class="m-20" style="justify-content: center; text-align: center;"><span>
                <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                  styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
              </span></div>
            <p-chart *ngIf="graficoSondaggio" type="bar" [height]="300" [data]="dataSondaggio" #sondaggi></p-chart>
          </div>

        </div>

      </div>

    </div>
