import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Sondaggio } from 'src/app/MODELS/CLASSROOM/sondaggio';


@Injectable({ providedIn: 'root' })
export class ShareService {
  private chatbotOpen = new BehaviorSubject(false);
  private chatliveOpen = new BehaviorSubject(false);
  private changePass = new BehaviorSubject(false);
  private idModifica = new BehaviorSubject(null);
  private whiteListChange = new BehaviorSubject(null);
  private idCanale = new BehaviorSubject(null);
  private chatMessage = new BehaviorSubject(null);
  private idDestinatario = new BehaviorSubject(null);
  private messaggio = new BehaviorSubject(null);
  private isLoggedIn = new BehaviorSubject(true);
  private listaPartecipanti = new BehaviorSubject(null);
  private listaGeolocalizzazione =  new BehaviorSubject(null);
  private applicaColore = new BehaviorSubject(null);
  private provaColore = new BehaviorSubject(null);
  private cambiaImmagine = new BehaviorSubject(null);
  private passaImmagine = new BehaviorSubject(null);
  private segnalaPresenza = new BehaviorSubject(null);
  private cambiaSchedule = new BehaviorSubject(false);
  private datiEmbedded = new BehaviorSubject(null);
  private scegliAula  = new BehaviorSubject(null);
  private idDispositivo = new BehaviorSubject(null);
  private paginaAllarmi  = new BehaviorSubject(false);
  private allarme = new BehaviorSubject(null);
  private feedback = new BehaviorSubject(null);
  private registrazione = new BehaviorSubject(null)
  private closeLateralMenu = new BehaviorSubject(null)

  chatbotOpen$ = this.chatbotOpen.asObservable();
  chatliveOpen$ = this.chatliveOpen.asObservable();
  idModifica$ = this.idModifica.asObservable();
  changePass$ = this.changePass.asObservable();
  whiteListChange$ = this.whiteListChange.asObservable();
  idCanale$ = this.idCanale.asObservable();
  chatMessage$ = this.chatMessage.asObservable();
  idDestinatario$ = this.idDestinatario.asObservable();
  messaggio$ = this.messaggio.asObservable();
  isLoggedIn$ = this.isLoggedIn.asObservable();
  listaPartecipanti$ = this.listaPartecipanti.asObservable();
  listaGeolocalizzazione$ = this.listaGeolocalizzazione.asObservable();
  applicaColore$ = this.applicaColore.asObservable();
  provaColore$ = this.provaColore.asObservable();
  cambiaImmagine$ = this.cambiaImmagine.asObservable();
  passaImmagine$ = this.passaImmagine.asObservable();
  segnalaPresenza$ = this.segnalaPresenza.asObservable();
  cambiaSchedule$ = this.cambiaSchedule.asObservable();
  datiEmbedded$ = this.datiEmbedded.asObservable();
  scegliAula$ = this.scegliAula.asObservable();
  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();
  idDispositivo$ = this.idDispositivo.asObservable();
  paginaAllarmi$ = this.paginaAllarmi.asObservable();
  allarme$ = this.allarme.asObservable();
  feedback$ = this.feedback.asObservable();
  registrazione$ =this.registrazione.asObservable();
  closeLateralMenu$ =this.closeLateralMenu.asObservable();

  //#region Sondaggio
  private sondaggio = new BehaviorSubject(null); 
  sondaggio$=this.sondaggio.asObservable()
  //#endregion

  changeRegistrazione(registrazione: any) {
    this.registrazione.next(registrazione)
  }

  changeData(chatbotOpen: boolean) {
    this.chatbotOpen.next(chatbotOpen)
  }

  changeDataChat(chatliveOpen: boolean) {
    this.chatliveOpen.next(chatliveOpen)
  }

  changeId(idModifica: string){
    this.idModifica.next(idModifica)
  }

  changePassword(changePass : boolean){
    this.changePass.next(changePass)
  }

  changeWhiteList(stringa : string|null){
    if(stringa==null)return
    this.whiteListChange.next(stringa)
  }

  changeIdCanale(idCanale: string) {
    this.idCanale.next(idCanale)
  }

  changeMessage(messaggio: any){
    this.chatMessage.next(messaggio)
  }

  changeIdDestinatario(idDestinatario: any){
    this.idDestinatario.next(idDestinatario)
  }
  
  changeMessaggio(messaggio: any){
    this.messaggio.next(messaggio)
  }

  changeIsLoggedIn(boolean : boolean){
    this.isLoggedIn.next(boolean);
  }

  changeListaPartecipanti(listaPartecipanti :any){
    this.listaPartecipanti.next(listaPartecipanti)
  }

  changeListaGeolocalizzazione(listaGeolocalizzazione :any){
    this.listaGeolocalizzazione.next(listaGeolocalizzazione)
  }

  changeColor(bool: boolean){
    this.applicaColore.next(bool);
  }

  tryColor(colore: String){
    this.provaColore.next(colore);
  }

  changeImage(bool: boolean){
    this.cambiaImmagine.next(bool);
  }

  changePassaImmagine(any : any){
    this.passaImmagine.next(any);
  }

  changeSegnalaPresenza(id : string){
    this.segnalaPresenza.next(id);
  }

  changeSchedule(bool : boolean){
    this.cambiaSchedule.next(bool);
  }

  changeEmbedded(any: any){
    this.datiEmbedded.next(any)
  }

  changeAula(any: any){
    this.scegliAula.next(any)
  }

  changeIdDispositivo(any: any){
    this.idDispositivo.next(any)
  }

  changePaginaAllarmi(bool : boolean){
    this.paginaAllarmi.next(bool)
  }

  changeAllarme(allarme : any){
    this.allarme.next(allarme)
  }

  changeFeedback(feedback : any){
    this.feedback.next(feedback)
  }

  changeCloseLateralMenu(closeLateralMenu : any){
    this.closeLateralMenu.next(closeLateralMenu)
  }

  //#region sondaggio
  /**
   * Qui effettuiamo la variazione del sondaggio
   * @param sondaggio di tipo interfaccia
   */
  changeSondaggio(sondaggio:Sondaggio){
    this.sondaggio.next(sondaggio)
  }
  //#endregion
}