import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/HELPERS/auth.guard';
import { NewsLetterComponent } from './news-letter.component';


const routes: Routes = [
   { 
       path: '', component: NewsLetterComponent, canActivate: [AuthGuard],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class newsLetterRoutingModule { }