import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AfterViewInit, Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AccountService, AlertService, EnvService, UsersService } from 'src/app/SERVICES';
import { CampagneService } from 'src/app/SERVICES/FARMACIE/campagne';

@Component({
  selector: 'app-campagne-home-page',
  templateUrl: './campagne-home-page.component.html',
  styleUrls: ['./campagne-home-page.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'translateY(100%)' }),
        animate(200),
      ]),
      transition('* => void', [
        animate(200, style({ opacity: 0, transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class CampagneHomePageComponent implements OnInit {@ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    'dataInizio',
    'dataFine',
    'listaQuestionari',
    'listaTargetCampagna',
    'azioni'
  ];

  searchBox: any = {
    dataInizio: '',
    dataFine: '',
    listaQuestionari: '',
    listaTargetCampagna: '',
  };
  ruolo;
  dataSource: any;
  showAdd = false;
  showModificaInfo = false;
  listIdToModify = [];
  listToModify = [];
  lunghezzaPaginator;
  subscriptionToModify;
  showTable = true;
  lastValueSearched

  constructor(private campagneService: CampagneService, private alertService: AlertService, private accountService: AccountService,
    private router: Router, private env: EnvService) {
    }

  ngDoCheck(): void {
    if(this.accountService.listaEnv && (!this.env.ALFACALLCENTER)){
      this.router.navigate(['./'])
    }
  }
  ngOnInit(): void {}

  ngAfterViewInit() {
    this.ruolo = this.accountService.ruolo
    this.campagneService.getAllFile(0, 5).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.data.pageList);
      setTimeout(() => {
        this.lunghezzaPaginator = res.data.nrOfElements
        // this.dataSource.paginator = this.paginator;
      }, 100);
    });
  }
  subscription;
  applyFilter(event: Event, column) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.lastValueSearched = column.toLowerCase();
    column = this.lastValueSearched
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchBox[column] = filterValue.trim().toLowerCase();
    if(!this.searchBox[column]) {
      this.lastValueSearched = null
      column = this.lastValueSearched
    }
    if(column === 'domande_associate') {
      this.subscription = this.campagneService.getRicercaDomande(0, 5, this.searchBox[column]).subscribe((res)=>{
        this.dataSource = new MatTableDataSource(this.getCampagnaData(res.data.pageList));
        setTimeout(() => {
          this.lunghezzaPaginator = res.data.nrOfElements
          // this.dataSource.paginator = this.paginator;
        }, 100);
      })
    } else {
      this.subscription = this.campagneService.getAllFile(0, 5, column, this.searchBox[column]).subscribe((res)=>{
        this.dataSource = new MatTableDataSource(res.data.pageList);
        setTimeout(() => {
          this.lunghezzaPaginator = res.data.nrOfElements
          // this.dataSource.paginator = this.paginator;
        }, 100);
      })
    }
  }


  getCampagnaData(res) {
    let listaToReturn = [];
    res.forEach((element)=>{
      listaToReturn.push(element.campagna)
    })
    listaToReturn.forEach((element)=>{
      let nominativoContatto = [];
      element.listaDomande.forEach((element)=>{
        nominativoContatto.push(element.contatto)
      })
      element.data_Inizio = element.dataInizio
      element.data_Fine = element.dataFine
      element.numero_Minimo_Chiamate = element.numChiamate
      element.domande_Associate = nominativoContatto;
    })
    return listaToReturn;
  }
  getEndpoint() {
    let endpoint = '?';
    this.displayedColumns.forEach((element) => {
      if (this.searchBox[element]) {
        endpoint = endpoint + element + '=' + this.searchBox[element] + '&';
      }
    });
    return endpoint.slice(0, -1);
  }

  getLabel(value: string) {
    try {
      return value.replace(/([a-z])([A-Z])/g, '$1 $2')
    } catch (error) {
      return value;
    }
  }

  modifyRow(element) {
    if(this.ruolo === 1){
      this.listIdToModify.push(element.id);
      this.listToModify.push(element);
    }
  }

  changeVisual(event) {

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    let column;
    try {
      column = this.lastValueSearched.toLowerCase();
    } catch (error) {
      column = null
    }
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.searchBox[column] = filterValue.trim().toLowerCase();
    if(column === 'domande_associate') {
      this.subscription = this.campagneService.getRicercaDomande(event.pageIndex, event.pageSize, this.searchBox[column]).subscribe((res)=>{
        let data = this.getCampagnaData(res.data.pageList);
        data.forEach((element, index)=>{
          if (this.listIdToModify.indexOf(element.id) !== -1) {
            data[index] = this.listToModify[this.listIdToModify.indexOf(element.id)];
          }
        })
        this.dataSource = new MatTableDataSource(this.getCampagnaData(res.data.pageList));
        setTimeout(() => {
          this.lunghezzaPaginator = res.data.nrOfElements
          // this.dataSource.paginator = this.paginator;
        }, 100);
      })
    }
    this.subscription = this.campagneService.getAllFile(event.pageIndex, event.pageSize, column, this.searchBox[column]).subscribe((res)=>{
      res.data.pageList.forEach((element, index)=>{
          if (this.listIdToModify.indexOf(element.id) !== -1) {
            res.data.pageList[index] = this.listToModify[this.listIdToModify.indexOf(element.id)];
          }
        })
      this.dataSource = new MatTableDataSource(res.data.pageList);
      setTimeout(() => {
        this.lunghezzaPaginator = res.data.nrOfElements
        // this.dataSource.paginator = this.paginator;
      }, 100);
    })

  }


  checkType(variabile) {
    return (typeof(variabile) === 'string' || typeof(variabile) === 'number' || typeof(variabile) === 'boolean');
  }
  modifyRows(index) {
    if(this.subscriptionToModify) {
      this.subscriptionToModify.unsubscribe();
    }
    try {
      this.listToModify[index].domande_Associate = this.listToModify[index].domande_Associate.split(',');
    } catch (error) {

    }
    this.subscriptionToModify = this.campagneService.modificaFarmacia(this.listToModify[index]).subscribe((element)=>{
      if(index < this.listToModify.length-1) {
        this.modifyRows(index+1);
      } else {
        this.listIdToModify = [];
        this.listToModify = [];
        this.lunghezzaPaginator = undefined;
        this.changeVisual({pageIndex: 0, pageSize: 5});
      }
    })
  }
  deleteCampagna(id) {
    this.campagneService.deleteFile(id).subscribe((res)=>{
      this.listIdToModify = [];
      this.listToModify = [];
      this.lunghezzaPaginator = undefined;
    this.campagneService.getAllFile(0, 5).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.data.pageList);
      setTimeout(() => {
        this.lunghezzaPaginator = res.data.nrOfElements
        // this.dataSource.paginator = this.paginator;
      }, 100);
    });
      this.alertService.success('Campagna eliminata con successo!');
    });
  }

  typeOf(type) {
    return typeof(type);
  }
  backAdd() {
    this.campagneService.getAllFile(0, 5).subscribe((res) => {
      this.showAdd=false
      this.showModificaInfo = false;
      this.isInfo = false;
      this.dataSource = new MatTableDataSource(res.data.pageList);
      this.showTable = true;
      setTimeout(() => {
        this.lunghezzaPaginator = res.data.nrOfElements
        // this.dataSource.paginator = this.paginator;
      }, 100);
    });
  }

  annullaModifyRows() {
    this.lunghezzaPaginator = undefined;
    this.listIdToModify = [];
    this.showTable = false;
    this.backAdd();
  }
  elementToModify;
  dataEdit(element) {
    element.startDate = new Date(element.dataInizio);
    element.endDate = new Date(element.dataFine);
    element.listaTargetCampagna.forEach((element)=>{
      element.id = element.targetCampagna.id;
      element.nomeTarget = element.targetCampagna.nomeTarget;
      element.targetCampagna = null;
    })
    element.listaTarget = element.listaTargetCampagna;
    return element;
  }
  showEdit(element) {
    this.elementToModify = this.dataEdit(element);
    this.showModificaInfo = true;
  }
  isInfo = false;
  showInfo(element) {
    this.elementToModify = this.dataEdit(element);
    this.showModificaInfo = true;
    this.isInfo = true;
  }
}
