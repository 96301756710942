import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { Utente } from 'src/app/MODELS/USER/utente';
import { AccountService, UsersService, ShareService, AlertService, EnvService } from 'src/app/SERVICES';

import {Message} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Router } from '@angular/router';
@Component({
  selector: 'app-crea-utente',
  templateUrl: './crea-utente.component.html',
  styleUrls: ['./crea-utente.component.scss'],
  providers: [MessageService]
})
export class CreaUtenteComponent implements OnInit {
  createForm: FormGroup;
  submitted = false;
  loading = false;
  listaRuoli = [];
  msgs: Message[] = [];
  nomeRuolo;
  ruoli: SelectItem[];
  validatorRuolo: boolean = false;
  crea : boolean = false;
  alfaintes
  createFormAlfaintes:FormGroup;
  listaCarica = []
  listaAttivita=[]
  gruppi=[]
  alfaCall
  indexAttivita;
  indexCarica
  listaStrutture=[]
  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private alertService: AlertService,
   private primengConfig: PrimeNGConfig,
   private messageService: MessageService,
    private accountService : AccountService,
    private routing : Router,
    private env :EnvService
    )
    {
      this.alfaintes=env.alfaintes;
      this.alfaCall=env.ALFACALLCENTER;
      // this.alfaintes=true
    this.alertService.clear;
    }
    ngDoCheck(): void {
      if(this.accountService.listaEnv && (this.accountService.ruolo !== 1)){
        this.routing.navigate(['./'])
      }  }
  ngOnInit() {
    // if (this.accountService.ruolo !== 1) {
    //   this.routing.navigate([''])
    // }
    this.userService.getListaGruppi().subscribe((res) => {
      this.gruppi = res.data;
    }, (error) => {

    });
    if(this.alfaintes && !this.alfaCall){
      //chimate Liste
      this.userService.lista_carica().subscribe((res)=>{
        this.listaCarica=res.data;
      })
      this.userService.lista_attivita().subscribe((res)=>{
        this.listaAttivita=res.data;
      })
      this.userService.lista_strutture_afi().subscribe((res)=>{
        this.listaStrutture=res.data;
      })

      this.createForm = this.formBuilder.group({
        nome: ['', [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
        cognome: ['',[Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
        email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
        recapito: ['',],
        ruolo: ['', [Validators.required, Validators.min(1)]],
        descrizione: [''],
        gruppo: [''],
        carica: [''],
        attivita: [''],
        isf: ['',[ Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
        struttura: ['',[Validators.required]],
        consenso: [''],
        idmedico: ['',[Validators.pattern("([0-9])+"),Validators.max(2147483647)]],
        utenteRegistrato:[''],
      })
    }

    this.alertService.openAlert(null)
    this.primengConfig.ripple = true;
    this.userService.getListaRuoli().subscribe((res) => {
      this.ruoli = [];
      this.listaRuoli = res.data;

      this.listaRuoli.forEach(ruolo => {
        this.ruoli.push(
          {label:ruolo.nomeRuolo, value:{id:ruolo.id}}
        )
      })
      this.crea = true;
    }, (error) => {

    });
    if(!this.alfaintes || this.alfaCall){
    this.createForm = this.formBuilder.group({
      nome: ['', [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      cognome: ['',[Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      recapito: ['',],
      ruolo: ['', [Validators.required, Validators.min(1)]],
      descrizione: [''],
      gruppo: [],
    });
  }


// •	NOME                      campo alfabetico
// •	COGNOME                   campo alfabetico
// •	EMAIL                     email
// •	CARICA                    select
// •	ATTIVITA                  select
// •	Informatore Scientifico   campo Alfabetico
// •	STRUTTURA                 campo libero
// •	CONSENSO                  checkbox
// •	IDentificativo MEDICO

  }

  get f() { return this.createForm.controls; }


  onSubmit() {
    this.submitted = true;
    this.loading = true;
    let gruppos
       if (this.createForm.invalid) {
      if (!this.createForm.value.ruolo) {
        this.validatorRuolo = true;
      }
      this.loading = false;
      return;
    }
    if(this.createForm.value.gruppo){
      gruppos=this.createForm.value.gruppo
    }else{
      gruppos=[]
    }
    if(!this.alfaintes || this.alfaCall){


    let utente = {
      nome: this.createForm.value.nome,
      cognome: this.createForm.value.cognome,
      email: this.createForm.value.email,
      telefono: this.createForm.value.recapito,
      ruolo: this.createForm.value.ruolo,
      gruppos: gruppos,
    }
    let id = 1;
    let descrizione= this.createForm.value.descrizione;

    this.userService.create(utente, id,descrizione).subscribe((res) => {
      this.loading = false;
 this.alertService.success(res.status.description);
    },(error) => {
      this.loading = false;
    })
  }
  else{

    let consenso;
    let utenteRegistrato;
if(this.createForm.value.consenso == true){
  consenso=1;
  if(this.createForm.value.utenteRegistrato == true){
    utenteRegistrato=1;
  }else{
    utenteRegistrato=0;
  }
}else{
  consenso=0;
  utenteRegistrato=0;
}


    let userAlfaItes = {
      nome: this.createForm.value.nome,
      cognome: this.createForm.value.cognome,
      email: this.createForm.value.email,
      telefono: this.createForm.value.recapito,
      ruolo: this.createForm.value.ruolo.id,
      listaGruppi: gruppos,
      idCarica: this.createForm.value.carica.id,
      idAttivita: this.createForm.value.attivita.id,
      isf: this.createForm.value.isf,
      idStruttura: this.createForm.value.struttura,
      consenso: consenso,
      idMedico: this.createForm.value.idmedico,
      dettagliAggiuntivi: this.createForm.value.descrizione,
      utenteRegistrato:utenteRegistrato
    }

  this.userService.crea_utente_afi(userAlfaItes).subscribe((res)=>{
  this.alertService.success(res.status.description);
  this.loading = false;

 },(error)=>{
  this.loading = false;

 })
  }

  }

  clear(){
    this.createForm.reset();
  }
  clickListaAttivita(e){
    if(e.index == this.indexAttivita){
      this.createForm.controls.attivita.setValue(null)
      this.indexAttivita=null;
    }else{
      this.indexAttivita = e.index;
    }
  }
  clickListaCarica(e){
   if(e.index == this.indexCarica){
     this.createForm.controls.carica.setValue(null)
     this.indexCarica=null;
   }else{
     this.indexCarica = e.index;
   }
 }

 utenteRegistratoView=false
 checkOn(){
   setTimeout(() => {
    if(this.createForm.value.consenso){
    this.utenteRegistratoView=true;
    }else{
   this.utenteRegistratoView=false;
    }
   },);

 }
}

