<div *ngIf="loading" class="text-center" style="margin-top: 5%">
  <p-progressSpinner
    [style]="{ width: '40px', height: '40px' }"
    strokeWidth="5"
    styleClass="custom-spinner2"
    animationDuration=".5s"
  ></p-progressSpinner>
</div>
<div class="content" *ngIf="!loading">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div
            class="card-header card-header-text"
            style="margin-top: 2%; justify-content: center"
          >
            <h5 class="card-title">Info assistito</h5>
            <br />
          </div>
          <div class="content">
            <mat-horizontal-stepper>
              <mat-step label="Anagrafica" state="form">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th style="width: 20%"></th>
                      <th style="width: 80%"></th>
                    </tr>
                  </thead>
                  <tbody class="animation3">
                    <tr>
                      <td class="text-right" style="max-width: 30%">
                        <h6>Nome</h6>
                      </td>
                      <td>{{ dettaglioCliente?.nome | titlecase }}</td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Cognome</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.cognome | titlecase }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Codice fiscale</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.codiceFiscale }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Età</h6>
                      </td>
                      <td class="col-10">{{ dettaglioCliente?.eta }}</td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Sesso</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.sesso | titlecase }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Città</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.citta | titlecase }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Occupazione</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.occupazione | titlecase }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Livello di istruzione</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.livelloDiIstruzione }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Situazione economica</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.situazioneEconomica | titlecase }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Diagnosi</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.diagnosi | titlecase }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Anno di presa in carico dal servizio</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.annoDiPresaInCaricoDalServizio }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>
                          L'utente attualmente è in carico presso un altro
                          servizio?
                        </h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.infoCliente | titlecase
                        }}<ng-container *ngIf="showAltroServizio">{{
                          ", " + dettaglioCliente?.altroServizio
                        }}</ng-container>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Modalità di accesso al servizio</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.inviante | titlecase }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mat-step>
              <mat-step label="Servizi" state="form">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th style="width: 20%"></th>
                      <th style="width: 80%"></th>
                    </tr>
                  </thead>
                  <tbody class="animation3">
                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Servizio di appartenenza</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.serviziDiAppartenenza }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>
                          Tipologia prevalente di attività svolte dalla coop Era
                        </h6>
                      </td>
                      <!-- <ng-container *ngFor="let attivita of this.dettaglioCliente.tipologiaAttivita">
                            <td class="col-10">{{attivita}}</td>
                          </ng-container> -->
                      <td class="col-10">{{ tipologieFullString }}</td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Tipologia di laboratori/interventi attivati</h6>
                      </td>
                      <!-- <div *ngFor="let laboratori of this.dettaglioCliente.tipologiaLaboratoriInterventi">
                          <td class="col-10">{{tipologieLaboratoriInterventiFullString}}</td>
                        </div> -->
                      <td class="col-10">
                        {{ tipologieLaboratoriInterventiFullString }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>
                          Le attività vengono svolte con altre
                          istituzioni/associazioni?
                        </h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.infoAltraAssociazione | titlecase
                        }}<span *ngIf="showAltraAssociazione">{{
                          ", " + dettaglioCliente?.altraAssociazione
                        }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>
                          Tali partenariati sono formalizzati da protocolli di
                          intesa con la cooperativa Era?
                        </h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.partenariati | titlecase }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mat-step>
              <mat-step label="Analisi" state="form">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th style="width: 20%"></th>
                      <th style="width: 80%"></th>
                    </tr>
                  </thead>
                  <tbody class="animation3">
                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Elementi storici, note e specifiche particolari</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.note | titlecase }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Analisi dei bisogni</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.analisiBisogni | titlecase }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Analisi delle risorse</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.analisiRisorse | titlecase }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Obiettivi generali</h6>
                      </td>
                      <td class="col-10">{{ obiettiviGeneraliFullString }}</td>
                    </tr>

                    <tr
                      *ngFor="
                        let obiettivo of dettaglioCliente.obiettiviSpecifici;
                        let i = index
                      "
                    >
                      <td class="text-right vl-bas" style="font-weight: bold">
                        <h6>
                          Obiettivi specifici e <br />
                          Azioni utili per la realizzazione
                        </h6>
                      </td>
                      <td class="col-10">
                        <span class="flex">
                          <span>
                            <i
                              [@rotatedState]="stato[i]"
                              class="material-icons notranslate middle"
                              style="cursor: pointer"
                              (click)="showAzioni(i)"
                            >
                              chevron_right
                            </i>
                          </span>
                          <span>
                            {{ obiettivo.title | titlecase }}
                          </span>
                        </span>
                        <div *ngIf="stato[i] === 'rotated'">
                          <ul>
                            <li
                              *ngFor="
                                let azione of obiettivo.azioniRealizzazione
                              "
                            >
                              {{ azione | titlecase }}
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Metodologia specifica</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.metodologiaSpecifica | titlecase }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Obiettivi raggiunti?</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.obiettiviRaggiunti | titlecase }}
                      </td>
                    </tr>

                    <tr>
                      <td class="text-right" style="font-weight: bold">
                        <h6>Breve descrizione</h6>
                      </td>
                      <td class="col-10">
                        {{ dettaglioCliente?.descrizioneObiettivi | titlecase }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mat-step>
            </mat-horizontal-stepper>

            <hr />
            <div class="text-right" style="margin-right: 5%">
              <button
                (click)="indietro(true)"
                class="btn btn-primary"
                style="margin-left: 46%; margin-bottom: 3%"
              >
                Indietro
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
