import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupName } from '@angular/forms';
//import { NgScormPlayerService, ScormResult } from 'ng-scorm-player';  #SCORM
//import { Subscriber } from 'openvidu-angular'; #OPV
import { ConfirmationService } from 'primeng/api';
import { Scorm } from 'src/app/MODELS/CLASSROOM/Scorm';
import { ScormService } from 'src/app/SERVICES/CLASSROOM/scorm.service';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { AccountService } from 'src/app/SERVICES/index';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-corsi',
  templateUrl: './corsi.component.html',
  styleUrls: ['./corsi.component.scss']
})
export class CorsiComponent implements OnInit  {
  @Input() idAula;
  url;
  ruolo;
  step1 = true;
  stepAddCorso = false;
  createForm: FormGroup;
  loading;
  submitted;
  nomeFile;
  stepScorm = false;
  selectedFile;
  formConvalidato;
  fileId;
  listaCorsi = [];
  listaModuli = [];
  listaCorsiAdd = [];
  listaAuleAdd = [];
  stepDettaglio = false;
  nomeCorsoSelect;
  idUtente;
  get;
  set;
  sub;
  oggettoScorm=new Scorm;
  corsoId
  cancellaCorso=false;
  caricatoInfo=false;
  ambiente;
  constructor(private accountService: AccountService,
    private formBuilder: FormBuilder,
    private fileService: FileService,
    private alertService: AlertService,
    private scormService: ScormService,
    private envService: EnvService,
    private confirmationService:ConfirmationService,
  ) {
    this.ruolo = this.accountService.ruolo;
    this.idUtente= this.accountService.userValue.data.id;
    this.ambiente = environment;
    //----
  }


  ngOnInit(): void {
    this.refreshListCorsi();

  }

  onSubmit() {
    if(this.listaAuleAdd.length>0){
   this.scormService.associaCorsi(this.idAula.id,this.listaAuleAdd).subscribe((res) => {
    this.listaAuleAdd = []
    this.loading = false;
    this.alertService.success(res.status.description);
    this.refreshListCorsi();
        this.listaCorsi = res.data;
         this.step1 = true;
         this.stepAddCorso = false;
  }, (error) => {
    this.loading = false;
  })
    }
  else {
    this.alertService.error('Seleziona almeno un corso')
  }}

  clear() {
    this.step1 = true;
    this.stepAddCorso = false;
  }


  //MENU
  aggiungiCorso() {
    this.scormService.listaCorsiPerAssociazione(this.idAula.id).subscribe((res) => {
      this.listaCorsiAdd = res.data;
      this.step1 = false;
      this.stepAddCorso = true;
    });
  }

  vaiScorm(corso) {
  // this.url= '/assets/scormDefinitivo'
 this.url=corso.path.substring(corso.path.indexOf("/assets"))

  this.oggettoScorm.nomeCorso=corso.nomeCorso;
  this.oggettoScorm.idModulo = corso.listaIdModuli[0];
  this.oggettoScorm.idCorso = corso.corsoId;
  this.oggettoScorm.idAula = this.idAula.id;
  this.oggettoScorm.path = this.url;
  this.oggettoScorm.stringa = corso.recuperoPercorso;
  this.oggettoScorm.tempo =corso.tempo;

      this.scormService.associaCorsiAdUtente(this.idAula.id,corso.corsoId,this.idUtente).subscribe((res) => {
        this.alertService.success(res.status.description);
        this.step1 = false;
        this.stepScorm = true;
        this.stepDettaglio = false;
      }, (error) => {
        this.step1 = false;
        this.stepDettaglio = false;
        this.loading = false;
        this.stepScorm = true;
      })
  }

  goBackScorm() {
    this.refreshListCorsi()
    this.stepScorm = false;
    this.step1 = true;
    this.stepDettaglio = false;
  }
  vaiDettaglio(corso) {
    this.caricatoInfo=false;
    this.nomeCorsoSelect = corso.nomeCorso;
    this.scormService.info_corso(this.idAula.id,corso.corsoId, this.idUtente).subscribe((res) => {
      if(res.data){
      this.listaModuli=res.data;
      this.caricatoInfo=true;
  }
      },(error) => {
       this.caricatoInfo=true;
      })
    this.step1 = false;
    this.stepDettaglio = true;

  }
  goBackDettaglio() {
    this.step1 = true;
    this.stepDettaglio = false;
  }

  //metodi Scorm
  aggiungiCorsoAula(idCorso) {
    if (this.listaAuleAdd.find(x => x == idCorso)) {
      this.listaAuleAdd.splice(this.listaAuleAdd.findIndex(x => x == idCorso),1);
    }
    else {
      this.listaAuleAdd.push(idCorso)
    }
  }
refreshListCorsi(){
  this.scormService.listaCorsiPerAula(this.idAula.id, this.idUtente).subscribe((res) => {
    if (res.data) {
      this.listaCorsi = res.data;
    }
  })
}
disassociaCorso(){
this.loading=true;
this.scormService.disassocia_corsi_aule(this.idAula.id,this.corsoId).subscribe((res) => {
  this.alertService.success(res.status.description);
  this.refreshListCorsi();
  this.loading=false;
  this.confirmationService.close();
}, (error) => {
  this.confirmationService.close();
})
  }
cancella(corsoId){
  this.corsoId=corsoId
  this.cancellaCorso=true;
  this.confirmationService.confirm({
    key:"cancella",
    message: 'Sicuro di voler continuare? '+ '<br>' +' I dati verranno persi!',
    icon: 'pi pi-exclamation-triangle',
    reject: () => {
      this.cancellaCorso=false;
      this.loading=false;
      this.confirmationService.close();
    }
  });
}
checkStatus(info){
if(info == "passed"){
  return "Completo"
}else{
  return "Incompleto"
}
}
responsiveWidth() {    }

}
