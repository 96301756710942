import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from '../GENERAL/env.service';


@Injectable({
  providedIn: 'root'
})

export class PrenotazioneTecnicoService {
  baseUrl: String;
  constructor(private env: EnvService, private http: HttpClient) {
    this.baseUrl = this.env.apiUrl;
  }

  salvaTipologia(obj) {
    return this.http.post<any>(this.baseUrl + '/salva_tipologia', obj);
  }

  salvaEvento(evento, copertina)
  {
    const jsonEvento = JSON.stringify(evento);
    const input = new FormData();
    input.append('evento', new Blob([jsonEvento],{ type: 'application/json' }));
    if(copertina)
    {
      input.append('photo', copertina, copertina.name);
    }
    return this.http.post<any>(this.baseUrl + '/eventi', input);
  }

  modificaEvento(payload, id, copertina?)
  {
    const jsonEvento = JSON.stringify(payload);
    const input = new FormData();
    input.append('evento', new Blob([jsonEvento],{ type: 'application/json' }));
    if(copertina && copertina.name)
    {
      input.append('photo', copertina, copertina.name);
    }
    return this.http.post<any>(this.baseUrl + '/eventi/' + id, input);
  }

  gestisciPartecipazione(idEvento, azione, idPrenotazione?)
  {
    let payload;
    if(idPrenotazione)
    {
      payload = {
        action: azione,
        id: idPrenotazione
      }
    }
    else
    {
      payload = {
        action: azione
      }
    }
    return this.http.post<any>(this.baseUrl + '/eventi/' + idEvento + '/partecipazioni/azione', payload);
  }

  eliminaEvento(id)
  {
    return this.http.post<any>(this.baseUrl + '/eventi/' + id + '/cancella', null);
  }

  recuperaEventi() {
    return this.http.get<any>(this.baseUrl + '/eventi');
  }

  dettaglioEvento(id) {
    return this.http.get<any>(this.baseUrl + '/eventi/' + id);
  }

  recuperaPrenotazioni(idEvento)
  {
    return this.http.get<any>(this.baseUrl + '/eventi/' + idEvento + "/" + "partecipazioni");
  }

  recuperaInfoPrenotazione(idEvento, idPrenotazione)
  {
    return this.http.get<any>(this.baseUrl + '/eventi/' + idEvento + "/" + "partecipazioni/" + idPrenotazione);
  }

  cambiaStatoEvento(id, stato) {
    return this.http.get<any>(this.baseUrl + '/cambia_stato_evento?idEvento=' + id + '&stato=' + stato)
  }

  aggiornaEvento(reminder) {
    return this.http.post<any>(this.baseUrl + '/aggiorna_evento', reminder)
  }

  listaTipologia(page, size, ricerca) {
    if (ricerca) return this.http.get<any>(this.baseUrl + '/lista_tipologia?size=' + size + '&page='+page + '&ricerca=' + ricerca);
    return this.http.get<any>(this.baseUrl + '/lista_tipologia?size=' + size + '&page='+page);
  }

  listaTipologiaAll() {
    return this.http.get<any>(this.baseUrl + '/lista_tipologia');
  }

  deleteTipologia(id) {
    return this.http.get<any>(this.baseUrl + '/elimina_tipologia?idTipologia=' + id)
  }
}
