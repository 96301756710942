import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexStroke,
  ApexMarkers,
  ApexGrid,
  ApexYAxis,
  ApexTooltip,
  ApexTheme,
  ApexNoData,
  ApexAnnotations,
} from "ng-apexcharts";
import { async } from "rxjs/internal/scheduler/async";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  stroke: ApexStroke;
  markers: ApexMarkers;
  grid: ApexGrid;
  tooltip: ApexTooltip;
  theme: ApexTheme
  noData: ApexNoData;
  annotations: ApexAnnotations;

};

@Component({
  selector: 'app-grafico-iot-v2[nomeTelemetria]',
  templateUrl: './grafico-iot-v2.component.html',
  styleUrls: ['./grafico-iot-v2.component.scss']
})
export class GraficoIotV2Component implements OnInit, OnChanges {
  @Input() nomeTelemetria:string;
  @Input() dataIot;
  @Input() date;
  @Input() dispositivo;
  @Input() mostraChartIot;

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() { }
  ngOnInit(): void {
    this.inizializzaOptions();
  }

  ngOnChanges(changes: SimpleChanges): void { //TODO Investigare sulla possibilità di aggiornare il grafico in tempo reale
      // console.log("Change detected!");
      this.inizializzaOptions();
  }

  // printDataInConsole()
  // {
  //   console.log("Data: ", this.dataIot?.datasets[0].data);
  //   //this.inizializzaOptions();
  // }

  inizializzaOptions()
  {
    let max, min, stepSize, tickAmount;
    let recognizedTelemetry:boolean=true;

    //In base alla telemetria ricevuta vengono impostati i valori mostrati sull'asse delle y.
    switch(this.nomeTelemetria)
    {
      case "Battito Cardiaco":
        max = 210;
        min = 30;
        stepSize = 30;
        break;
      case "Pressione":
        max = 270;
        min = 30;
        stepSize = 30;
        break;
      
      case "Temperatura":
        max = 40;
        min = 32;
        stepSize = 2;
        break;

      case "Contapassi":
        max = 1000;
        min = 0;
        stepSize = 100;
        break;

      case "Ossigenazione del Sangue":
        max = 100;
        min = 0;
        stepSize = 10;
        break;
      case "Respirazione":
        max = 40;
        min = 0;
        stepSize = 5;
        break;
      default:
        recognizedTelemetry=false;
        tickAmount=8;
        break;
    }
    
    //Prima di procedere col prendere questi valori per buoni, si verifica che nell'array non siano presenti valori al di fuori del range specificato.
    if(this.dataIot && recognizedTelemetry)
    {
      let minArray = Math.min(this.findMinimumYToDisplay(this.dataIot?.datasets[0].data), this.dataIot?.datasets[0].sogliaMinima??Infinity);
      // console.log("minArray: ", minArray);
      if(minArray < min)
      {
        min-= stepSize*(Math.ceil((min-minArray)/stepSize));
      }
      let maxArray = Math.max(this.findMaximumYToDisplay(this.dataIot?.datasets[0].data), this.dataIot?.datasets[0].sogliaMassima??-Infinity);
      if(maxArray > max)
      {
        max+= stepSize*(Math.ceil((maxArray-max)/stepSize));
      }
      tickAmount = Math.floor((max-min)/stepSize); //Con questo passaggio, invece di indicare quanti tick vogliamo, indichiamo l'intervallo tra un tick e l'altro
    }
    

    // console.log("Min-Max: " + min + "-" + max);
    // console.log("Data: ", this.dataIot);
    //Ottengo la localizzazione italiana 'ufficiale'
    var it = require("apexcharts/dist/locales/it.json")
    //Personalizzo la localizzazione italiana
    it.options.toolbar.reset="Centra dati";
    // console.log("Locale:" , it);
    // console.log("Soglie: ", this.dataIot?.datasets[0].sogliaMinima, " ", this.dataIot?.datasets[0].sogliaMassima);
    // console.log("Data IoT: ", this.dataIot?.datasets[0].data);
    
    // Dichiarazione di una variabile per memorizzare il contesto corrente
    const self = this;
    
    this.chartOptions = {
      
      chart: {
        //Aspetto Grafico
        height: 350,
        type: "line",

        //Localizzazione grafico
        locales: [it],
        defaultLocale: 'it',

        //Toolbar
        toolbar: {
          tools:{
            //Icona mirino per "Centra dati"
            reset: `
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24"><path d="M321.85,250.69c-4-33.61-30.39-61-65.85-61-36,0-66.34,30.31-66.34,66.34S220,322.34,256,322.34c35.47,0,61.84-27.41,65.85-61a18.39,18.39,0,0,0,.49-5.32A18.71,18.71,0,0,0,321.85,250.69ZM225.12,256c0-39.95,59.88-39.6,61.76,0C285,295.55,225.12,296,225.12,256Z"></path><path d="M433.3,238.27H395c-7.27-51.81-41.57-96.15-91.93-114.52a133.34,133.34,0,0,0-29.29-7v-38c0-22.82-35.46-22.86-35.46,0V117c-34.91,4.65-68,22.22-90.69,50.08a141.57,141.57,0,0,0-30.44,71.24H78.7c-22.82,0-22.86,35.46,0,35.46H117a137.24,137.24,0,0,0,18.61,54.45c22.57,37.45,60.88,61.14,102.69,66.63V433.3c0,22.82,35.46,22.86,35.46,0V395.07c2.92-.35,5.85-.75,8.76-1.28,60.25-10.79,104-61.36,112.44-120.06H433.3C456.12,273.73,456.16,238.27,433.3,238.27ZM291.38,354.83a106,106,0,0,1-115.8-31.39c-62-73.5,5.19-188.93,99.84-170.61,49.75,9.63,84.51,53,85.5,103.17C360.05,299.87,333.32,340,291.38,354.83Z"></path></svg>            `
          }
        },

        //Eventi
        events: {
          click: function(event, chartContext, config) {
            // config contiene informazioni su quale punto dati è stato cliccato
            if (self.dataIot && config.dataPointIndex !== undefined && config.seriesIndex !== undefined) {
              const seriesName = config.seriesIndex;
              const dataPointIndex = config.dataPointIndex;
              const dataValue = chartContext.w.globals.series[seriesName][dataPointIndex];
              
              // console.log("Serie:", seriesName);
              // console.log("Id:", self.dataIot.datasets[0].data[dataPointIndex].id);
              // console.log("Valore del Punto Dati:", dataValue);
            }
          }
        }
      },

      //Soglie
      annotations: 
      (this.dataIot?.datasets[0].sogliaMinima && this.dataIot?.datasets[0].sogliaMassima)?
      {  
        yaxis: [
          {
            y: this.dataIot?.datasets[0].sogliaMinima, // Sostituisci con il tuo valore minimo previsto
            //y2: 180 //Per colorare un'area da y a y2
            borderColor: '#FF0000', // Colore della linea
            borderWidth: 2, // Larghezza della linea
            label: {
              borderColor: '#FF0000', // Colore del testo
              borderWidth: 2, // Larghezza del bordo del testo
              text: 'Soglia minima', // Testo da visualizzare accanto alla linea
              position: 'right', // Posizione del testo ('top', 'bottom', 'left', 'right')
              offsetY: 5, // Offset verticale del testo
            },
          },
          {
            y: this.dataIot?.datasets[0].sogliaMassima, // Sostituisci con il tuo valore massimo previsto
            borderColor: '#FF0000', // Colore della linea
            borderWidth: 2, // Larghezza della linea
            label: {
              borderColor: '#FF0000', // Colore del testo
              borderWidth: 2, // Larghezza del bordo del testo
              text: 'Soglia massima', // Testo da visualizzare accanto alla linea
              position: 'right', // Posizione del testo ('top', 'bottom', 'left', 'right')
              offsetY: 5, // Offset verticale del testo
            },
          },
        ],
      }
      :undefined,

      theme: {
        monochrome: {
          enabled: true,
          color: this.dataIot?.datasets[0].borderColor, //Pallino nel tooltip
        },
      },


      //Etichette
      tooltip: {
        x: {
          show: false,
          format: this.date? 'HH:mm':"dd/MM/yy HH:mm",
          formatter: undefined,
        },
      },
      //Dati grafico
      series: [
        {
          name: this.nomeTelemetria,
          data: this.dataIot?.datasets[0].data
        }
      ],
      


      // title: {
      //   text: "Grafico " + this.nomeTelemetria
      // },
      
      xaxis: {
        min: this.date?new Date(new Date().setHours(0, 0, 0, 0)).getTime():undefined, // start date
        max: this.date?new Date(new Date().setHours(24, 0, 0, 0)).getTime():undefined,// end date
        type: "datetime",
        labels: {
          datetimeUTC: false,
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM \'yy',
            day: 'dd MMM',
            hour: 'HH:mm'
          }
        },
      },

      yaxis: {
        min: min,
        max: max,
        tickAmount: tickAmount
      },

      stroke: {
        curve: 'straight',
        width: 4,
        colors: [this.dataIot?.datasets[0].borderColor]
      },

      markers: {
        size: 5,
        colors: [this.dataIot?.datasets[0].borderColor]
      },

      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
    };
  }

  findMinimumYToDisplay(data)
  {
    let min = data[0].y;

    for (let i = 1; i < data.length; i++) {
      if (data[i].y < min) {
        min = data[i].y;
      }
    }

    return min;
  }

  findMaximumYToDisplay(data)
  {
    let max = data[0].y;

    for (let i = 1; i < data.length; i++) {
      if (data[i].y > max) {
        max = data[i].y;
      }
    }

    return max;
  }
}
