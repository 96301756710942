import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AlertService, EnvService } from 'src/app/SERVICES';
import { PrenotazioneTecnicoService } from 'src/app/SERVICES/PRENOTAZIONETECNICO/prenotazione.service';
import { TecnicoModule } from '../form-creator-component/form-creator-component.component';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-visualizzazione-form',
  templateUrl: './visualizzazione-form.component.html',
  styleUrls: ['./visualizzazione-form.component.scss'],
})
export class VisualizzazioneFormComponent implements OnInit {
  // 0 - rosso ; 1 - verde ; 2 - giallo
  @Input() id;
  @Output() backEmit = new EventEmitter<any>();
  displayedColumnsSmartwatch: string[] = [
    'name',
    'azioni'
  ];
  todayDate;
  link;
  sliderValue = 1;
  taglia = null;
  maxStepper = 1;
  loading = false;
  checkedReminder = false;
  showPage = false;
  editMode = false;
  sizes = [
    { id: 1, size: 'S' },
    { id: 2, size: 'M' },
    { id: 3, size: 'L' },
    { id: 4, size: 'XL' },
    { id: 5, size: 'XXL' },
    { id: null, size: '---' },
  ];
  textTooltipButtonReminderDisabled =
    "Non puoi scegliere un reminder a meno di un ora dall'evento";
  showReminderModal = false;
  labelFn(value: number): string {
    switch (value) {
      case 1:
        return '1h';
      case 2:
        return '3h';
      case 3:
        return '12h';
      case 4:
        return '1g';
      case 5:
        return '3g';
      case 6:
        return '1w';
      case 7:
        return '2w';
      case 8:
        return '1m';
      default:
        return '1h';
    }
  }
  arrayEquipments: TecnicoModule[] = [
    { name: 'Abbattitore', selected: false, id: 15 },
    { name: 'Cella di lievitazione con umidità', selected: false, id: 13 },
    { name: 'Cella di lievitazione senza umidità', selected: false, id: 14 },
    { name: 'Erogatore acqua temp. Controllata', selected: false, id: 19 },
    { name: 'Forno a Legna', selected: false, id: 4 },
    { name: 'Forno da Pizzeria', selected: false, id: 5 },
    { name: 'Forno Rotonial', selected: false, id: 6 },
    { name: 'Forno Rotor', selected: false, id: 2 },
    { name: 'Forno Statico', selected: false, id: 1 },
    { name: 'Forno Tubi', selected: false, id: 7 },
    { name: 'Forno Ventilato', selected: false, id: 3 },
    { name: 'Freezer', selected: false, id: 16 },
    { name: 'Frigo', selected: false, id: 17 },
    { name: 'Impastatrice da Pizzeria', selected: false, id: 12 },
    { name: 'Impastatrice Forcella', selected: false, id: 11 },
    { name: 'Impastatrice Planetaria', selected: false, id: 10 },
    { name: 'Impastatrice Spirale', selected: false, id: 8 },
    { name: 'Impastatrice Tuffanti', selected: false, id: 9 },
    { name: 'Microonde', selected: false, id: 21 },
    { name: 'Sfogliatrice', selected: false, id: 18 },
    { name: 'Temperatrice', selected: false, id: 20 },
  ];
  forms = [];
  attrezzature;
  prodotti = [];
  responseEvent;
  prenotazioni;
  partecipazioneSelezionata;
  showInfo = false;


  constructor(
    private service: PrenotazioneTecnicoService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private prenotazioneService: PrenotazioneTecnicoService,
    private env: EnvService
  ) {
    this.link = this.env.apiUrl + '/elastic/download_doc?id=';
  }

  ngOnInit(): void {
    this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    this.loading = true;
    this.service.dettaglioEvento(this.id).subscribe((res) => {
      this.responseEvent = res.data;
      this.setStepper();
      this.formBuilder();
      if (this.responseEvent.reminder) this.checksliderValue();

      // this.productsBuilder(); Il servizio serve per generare una lista di id in caso in cui il BE non riesca a fornirne una
      this.showPage = true;
      this.loading = false;
      this.service.recuperaPrenotazioni(this.id).subscribe((res) =>
      {
        this.prenotazioni = res.data;
        if(this.responseEvent.sede==0)
        {
          this.partecipazioneSelezionata = this.prenotazioni.participations[0];
          //console.log(this.partecipazioneSelezionata);
          // console.log(this.prenotazioni);
          // console.log(this.formSelezionato);
        }
      })
    });



  }

  // eventEnded()
  // {
  //   if(this.todayDate >= this.responseEvent.dataFine)
  //   {
  //     return true;
  //   }
  //   return false;
  // }

  checksliderValue() {
    this.checkedReminder = true;
    let dataReminder = new Date(this.responseEvent.reminder);
    let dataInizio = new Date(this.responseEvent.dataInizio);
    let difference = dataInizio.getTime() - dataReminder.getTime();
    if (difference === 3600000) {
      this.sliderValue = 0;
    }
    if (difference === 3600000) {
      this.sliderValue = 1;
    }
    if (difference === 10800000) {
      this.sliderValue = 2;
    }
    if (difference === 43200000) {
      this.sliderValue = 3;
    }
    if (difference === 86400000) {
      this.sliderValue = 4;
    }
    if (difference === 259200000) {
      this.sliderValue = 5;
    }
    if (difference === 604800000) {
      this.sliderValue = 6;
    }
    if (difference === 1209600000) {
      this.sliderValue = 7;
    }
    if (difference === 2419200000) {
      this.sliderValue = 8;
    }
  }

  setStepper() {
    let today = new Date();
    let startingDate: Date = new Date(this.responseEvent.dataInizio);
    let difference = startingDate.getTime() - today.getTime();
    if (difference > 3600000) {
      this.maxStepper = 0;
    }
    if (difference > 3600000) {
      this.maxStepper = 1;
    }
    if (difference > 10800000) {
      this.maxStepper = 2;
    }
    if (difference > 43200000) {
      this.maxStepper = 3;
    }
    if (difference > 86400000) {
      this.maxStepper = 4;
    }
    if (difference > 259200000) {
      this.maxStepper = 5;
    }
    if (difference > 604800000) {
      this.maxStepper = 6;
    }
    if (difference > 1209600000) {
      this.maxStepper = 7;
    }
    if (difference > 2419200000) {
      this.maxStepper = 8;
    }
  }

  formBuilder() {
    //let list = [];
    //if (this.responseEvent.giacca) this.taglia = this.responseEvent.giacca.id;
    // this.forms = Object.keys(this.responseEvent.tipologia.formCombinazioni);
    // this.forms.forEach((objectName) => {
    //   if (objectName == 'attrezzature')
    //     this.attrezzature = this.responseEvent.form[objectName];
    //   if (objectName == 'prodotti')
    //     this.prodotti = this.responseEvent.form[objectName];
    //   if (
    //     objectName !== 'id' &&
    //     this.responseEvent.tipologia.formCombinazioni[objectName] &&
    //     !Array.isArray(this.responseEvent.tipologia.formCombinazioni[objectName])
    //   )
    //     list.push(objectName);
    // });
    // this.forms = list;
    if(this.responseEvent.sede===0)
    {
      this.attrezzature = this.responseEvent.tipologia.formCombinazioni.attrezzature;

      this.equipmentBuilder();
      this.prodotti = this.responseEvent.tipologia.formCombinazioni.prodotti;
    }
    this.forms = this.responseEvent.tipologia.formCombinazioni.combinazioniForm;
  }

  equipmentBuilder() {
    this.arrayEquipments.forEach((equipment) => {
      this.attrezzature.forEach((equipmentSelected) => {
        if (equipment.id == equipmentSelected.id) {
          equipment.selected = true;
        }
      });
    });
  }

  productsBuilder() {
    let listIdProducts = [];
    this.prodotti.forEach((product) => {
      listIdProducts.push(product.id);
    });
    this.prodotti = listIdProducts;
  }

  indietro() {
    this.backEmit.emit();
  }

  getDateValue(value) {
    let startingDate: Date = new Date(this.responseEvent.dataInizio);
    let returnDate = new Date();
    switch (value) {
      case 1:
        returnDate.setTime(startingDate.getTime() - 3600000);
        return returnDate;
      case 2:
        returnDate.setTime(startingDate.getTime() - 10800000);
        return returnDate;
      case 3:
        returnDate.setTime(startingDate.getTime() - 43200000);
        return returnDate;
      case 4:
        returnDate.setTime(startingDate.getTime() - 86400000);
        return returnDate;
      case 5:
        returnDate.setTime(startingDate.getTime() - 259200000);
        return returnDate;
      case 6:
        returnDate.setTime(startingDate.getTime() - 604800000);
        return returnDate;
      case 7:
        returnDate.setTime(startingDate.getTime() - 1209600000);
        return returnDate;
      case 8:
        returnDate.setTime(startingDate.getTime() - 2419200000);
        return returnDate;
      default:
        returnDate.setTime(startingDate.getTime() - 3600000);
        return returnDate;
    }
  }

  aggiornaEvento() {
    let obj: any = {};
    let dateMillis;
    dateMillis = this.getDateValue(this.sliderValue).getTime();
    let date: Date = new Date(dateMillis);
    obj.dataRemainder = date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2)+ '-' + date.getDate() + ' ' + ('0' + (date.getHours())).slice(-2) + ':' + ('0' + (date.getMinutes())).slice(-2) + ':' + ('0' + (date.getSeconds())).slice(-2);
    this.prenotazioneService.modificaEvento(obj, this.responseEvent.id).subscribe((res)=>{
      this.alertService.success(res.status.description)
      this.backEmit.emit();
    });

  }

  confirmEvent(stato) {
    this.loading = true;
    this.service.cambiaStatoEvento(this.id, stato).subscribe((res) => {
      let obj: any = {};
      obj.idEvento = this.id;
      obj.giacca = this.taglia;
      obj.data = null;
      if (this.checkedReminder) {
        obj.data = this.getDateValue(this.sliderValue).getTime();
      }
      if (stato === 1) {
        this.service.aggiornaEvento(obj).subscribe((res2) => {
          this.alertService.success(
            "L'evento è stato " +
              (stato == 1 ? 'confermato' : 'eliminato') +
              ' con successo! '
          );
          this.loading = false;
          this.backEmit.emit();
        });
      } else {
        this.alertService.success(
          "L'evento è stato " +
            (stato == 1 ? 'confermato' : 'eliminato') +
            ' con successo!'
        );
        this.loading = false;
        this.backEmit.emit();
      }
    });
  }

  eliminaEvento(id)
  {
    this.service.eliminaEvento(id).subscribe((res)=>{
      this.alertService.success(res.status.description);
      this.backEmit.emit();
    })
  }

  gestisciPartecipazione(azione, idPrenotazione?)
  {
    this.service.gestisciPartecipazione(this.responseEvent.id, azione, idPrenotazione).subscribe((res)=>{
      this.alertService.success(res.status.description);
      this.service.recuperaPrenotazioni(this.responseEvent.id).subscribe((res)=>
      {
        this.prenotazioni = res.data;
      })
    })
  }

  infoPrenotazione(id)
  {
    this.loading = true;
    this.showInfo = true;
    this.service.recuperaInfoPrenotazione(this.responseEvent.id, id).subscribe((res)=>
    {
      this.partecipazioneSelezionata = res.data;
      this.loading = false;
    }
    )
  }


  vaiModifica()
  {
    this.editMode = true;
    this.showPage = false;
  }

  indietroDaModifica()
  {
    this.editMode = false;
    this.showPage = true;
  }

}
