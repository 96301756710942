import { Component, OnInit,EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService, AlertService, UsersService } from 'src/app/SERVICES';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { IotService } from 'src/app/SERVICES/IOT/iot.service';
import { configurazioneKetron } from 'src/app/MODELS/IOT/configurazioneKetron';


@Component({
  selector: 'app-crea-configurazione',
  templateUrl: './crea-configurazione.component.html',
  styleUrls: ['./crea-configurazione.component.scss']
})
export class CreaConfigurazioneComponent implements OnInit {

listaTipi = [];
opzioniInd: SelectItem[];
selectedOptionInd: number;
submitted=false;
listaDitipi=false;
configurazioneform: FormGroup;
form=false;
tipoDevice = 'H002';
requiredTempo=false;
requiredVolume=false;
requiredRedNumber=false;
requiredYellowNumber=false;
requiredGreenNumber=false;
requiredWhiteList=false;
confGenerale= new configurazioneKetron;
loading=false;
valoreBpm = 1;
valoreTemp = 1;
valoreVolume = 0;

@Output() tornaLista = new EventEmitter<void>();//torna ad elenco aula virtuale
  
  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private accountService : AccountService,
    private dispositiviService: IotService
  ) {
    this.opzioniInd = [{ label: "Sì", value: "1" }, { label: "No", value: "0" }];
 this.dispositiviService.getTipoDevice().subscribe((res)=>{
      res.data.forEach(dispositiviTipi => {
          this.listaTipi.push(dispositiviTipi);
      })

    })

   }

  ngOnInit(): void {

  this.configurazioneform= this.formBuilder.group({
      redNumber:['', [Validators.required, Validators.pattern('^[0-9]+$')]],  
      yellowNumber:['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      greenNumber:['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      whiteList:['', [Validators.required, Validators.pattern('[- ,0-9]+')]],
      sleepTime:['']
    });

  }
 get h(){return this.configurazioneform.controls}

 vaiCrea(tipo){
   this.tipoDevice=tipo;
   this.listaDitipi=false;
 }
 
  onSubmit(){
    this.submitted = true;
  if(this.configurazioneform.invalid){

  return;
  }
  this.loading=true;

  let numeriWhiteListDivisi = this.configurazioneform.value.whiteList.split(",")
  this.confGenerale = {
    heartRateInterval: this.valoreBpm,
    temperatureInterval:this.valoreTemp,
    volume: this.valoreVolume,
    redNumber: this.configurazioneform.value.redNumber,
    yellowNumber: this.configurazioneform.value.yellowNumber,
    greenNumber: this.configurazioneform.value.greenNumber,
    whitelist: numeriWhiteListDivisi,
    sleepTime:this.configurazioneform.value.sleepTime
  }

        this.dispositiviService.creaConfGlobale(this.confGenerale).subscribe((res) => {
          this.alertService.success(res.status.description);
          this.tornaLista.emit();
          this.loading=false;
        }, (error) => {
          this.loading=false;
        })
      }
goBack(){//torna ad elenco aula virtuale
  this.tornaLista.emit();
}
formatLabelVolume(value: number) {
  if (value >= 11) {
    value = 11

    return value;

  }


  return value;
}
formatLabel(value: number) {

  if (value >= 1) {


    return Math.round(value) + ' h';

  }


  return value;
}
}
