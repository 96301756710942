import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import * as fs from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-download-report-image',
  templateUrl: './download-report-image.component.html',
  styleUrls: ['./download-report-image.component.scss']
})
export class DownloadReportImageComponent implements OnInit, AfterViewInit {

  id;
  immagine;
  blob;
  imageUrl;
  displayedImage;

  static primaVolta:Boolean = true;
  constructor(
    private _Activatedroute: ActivatedRoute,
    private fileService: FileService,
    private sanitizer: DomSanitizer,
    private router: Router) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.id = this._Activatedroute.snapshot.paramMap.get("id");
    this.fileService.getReportImage(this.id).subscribe((res)=>{
      this.immagine = res.data.image.replace("data:image/jpeg;base64," , "");
      if(DownloadReportImageComponent.primaVolta)
      {
        DownloadReportImageComponent.primaVolta = false;
        this.blob = this.b64toBlob(this.immagine, "image/jpeg");
        //let imageURL = URL.createObjectURL(this.blob);       
        //this.displayedImage = this.sanitizer.bypassSecurityTrustUrl(imageURL);
        fs.saveAs(this.blob, "Immagine Report Controllo Accessi - " + this.id);
        this.router.navigate(['/pannellocontrollo/report']);        
      }
      })
    }


    b64toBlob(b64Data=this.immagine, contentType='', sliceSize=512)
    {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
    
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
    
      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }
}
