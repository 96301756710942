import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { CanComponentDeactivate } from 'src/app/HELPERS/candeactivate-guard.service';
import {ConfirmationService} from 'primeng/api';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CallRateService } from 'src/app/SERVICES/CLASSROOM/callRate.service';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/SERVICES/USER/account.service';
import { AlertService } from 'src/app/SERVICES';
import { GoToClassroomService } from 'src/app/SERVICES/CLASSROOM/go-to-classroom.service';
import { FormControl } from '@angular/forms';
import { ClassRoomInterface } from 'src/app/SERVICES/CLASSROOM/classRoomInterface';

@Component({
  selector: 'app-classroom-homepage',
  templateUrl: './classroom-homepage.component.html',
  styleUrls: ['./classroom-homepage.component.scss'],
  providers: [ConfirmationService],
})
export class ClassroomHomepageComponent
  implements OnInit, CanComponentDeactivate, OnDestroy
{
  // @Input() aulaInfo;
  // @Output() vaiIndietro = new EventEmitter<boolean>();
  @ViewChild('modale') modale: ElementRef<HTMLElement>;
  @ViewChild('closeModal') closeModal: ElementRef<HTMLElement>;
  openedFeedbackDialog = false;
  aulaInfo;
  lezione = false;
  caricato = false;
  flagSchedule;
  schedule = [];
  intervallo: any;
  pil1 = true;
  pil2 = false;
  pil3 = false;
  pil4 = false;
  ruoloUser;
  isDesktop = true;
  envScorm;
  selectedFeedback = false;
  ambiente;
  recording;

  popupInsegnanteSondaggio = false;
  sondaggioQuestionControl = new FormControl();
  isWebinarActive = false;
  webinarUrl:string;


  constructor(
    private deviceService: DeviceDetectorService,
    private classroomService: ClassroomService,
    private accountService: AccountService,
    private alertService: AlertService,
    private service: ShareService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private crService: CallRateService,
    public envService: EnvService
  ) {
    this.ambiente = environment;
    this.envScorm = this.envService.SCORM;
    this.recording = this.envService.RECORDING;
    this.isDesktop = this.deviceService.isDesktop();
    this.service.scegliAula$.subscribe((res) => {
      this.aulaInfo = res;
    });
    if (!this.aulaInfo) {
      this.router.navigate(['education']);
    }
    this.service.cambiaSchedule$.subscribe((res) => {
      if (res) {
        this.getLezioni();
      }
    });
  }
  ngOnDestroy(): void {
    // if(this.selectedFeedback) {
    //   this.rateCall(0);
    // }
    this.service.changeAula(null);
    clearInterval(this.intervallo);
  }


  ngOnInit(): void {
    this.ruoloUser = this.accountService.ruolo;
    this.classroomService
      .lista_dettagli_classroom(this.aulaInfo.id)
      .subscribe((res) => {
        this.aulaInfo.listaDocenti = res.data.listaDocenti;
        this.aulaInfo.listaUtenti = res.data.listaStudenti;
        this.caricato = true;
      });

    this.getLezioni();
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.aulaInfo) {
      this.confirmationService.confirm({
        key: 'uscita',
        message: 'Sei sicuro di voler uscire?',

        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.service.navigateAwaySelection$.next(true);
          this.service.changeFeedback(true);
        },
        reject: () => {
          this.service.navigateAwaySelection$.next(false);
        },
      });
      return this.service.navigateAwaySelection$;
    } else {
      return true;
    }
  }
  indietro() {
    this.router.navigate(['education']);
  }

  salaAttesa = false;
  avviaLezione() {
    if (this.envService.goTo) {
      const classroomService = this.classroomService as unknown;
      if (classroomService instanceof GoToClassroomService) {
        this.isWebinarActive = false;
        classroomService
          .entraInClassroom(
            this.aulaInfo.id,
            this.accountService.userValue.data
          )
          .subscribe((res) => {
            this.isWebinarActive = true;
             window.open(res.data)
             this.webinarUrl = res.data;
          });
      }
    } else {
      localStorage.removeItem('openviduCallVideoDevice');
      localStorage.removeItem('openviduCallAudioDevice');
      if (this.ruoloUser === 1 || this.ruoloUser === 4) {
        this.lezione = true;
      } else {
        this.salaAttesa = true;
        this.classroomService
          .salaAttesa(
            this.aulaInfo.id,
            this.accountService.userValue.data.id,
            this.aulaInfo.nomeAula
          )
          .subscribe(
            (res) => {
              this.checkAttesa();
            },
            (error) => {
              this.lezione = false;
              this.salaAttesa = false;
            }
          );
      }
    }
  }

  subStato;
  checkAttesa() {
    if (this.subStato) {
      this.subStato.unsubscribe();
    }
    this.subStato = this.classroomService
      .getStatoStudenteAttesa(
        this.aulaInfo.id,
        this.accountService.userValue.data.id
      )
      .subscribe(
        (res) => {
          let value = res.data;

          if (value === 2) {
            setTimeout(() => {
              this.checkAttesa();
            }, 1000);
          } else if (value === 0) {
            this.alertService.error("Non sei stato ammesso all'aula");
            setTimeout(() => {
              this.lezione = false;
              this.salaAttesa = false;
            }, 3000);
          } else {
            this.salaAttesa = false;
            this.lezione = true;
          }
        },
        (error) => {
          this.salaAttesa = false;
          this.lezione = false;
        }
      );
  }

  indietroLezione() {
    this.lezione = false;
  }

  getLezioni() {
    this.classroomService.getLezioneOggi(this.aulaInfo.id).subscribe((res) => {
      this.schedule = res.data?.ore;
      this.schedule?.forEach((element) => {
        element.inCorso = false;
        let array = element.start.split(':');
        if (array[0].length < 2) {
          array[0] = '0' + array[0];
        }
        if (array[1].length < 2) {
          array[1] = '0' + array[1];
        }
        element.start = array[0] + ':' + array[1];
        let array2 = element.end.split(':');
        if (array2[0].length < 2) {
          array2[0] = '0' + array2[0];
        }
        if (array2[1].length < 2) {
          array2[1] = '0' + array2[1];
        }
        element.end = array2[0] + ':' + array2[1];
      });
      if (!this.schedule || this.schedule.length < 1) {
        this.flagSchedule = 'noSchedule';
      } else {
        this.flagSchedule = 'siSchedule';
      }
      if (this.schedule?.length > 0) {
        this.intervallo = setInterval(() => {
          let date = new Date();
          this.schedule.forEach((elemento) => {
            let array1 = elemento.start.split(':');
            let array2 = elemento.end.split(':');
            let dataInizio = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              array1[0],
              array1[1],
              0
            );
            let dataFine = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              array2[0],
              array2[1],
              0
            );
            if (dataInizio < date && date < dataFine) {
              elemento.inCorso = true;
            } else {
              elemento.inCorso = false;
            }
          });
        }, 1);
      }
      if (this.schedule) {
        this.schedule = this.schedule.sort((a, b) =>
          a.start < b.start ? -1 : a.start > b.start ? 1 : 0
        );
      }
    });
  }

  cambiaPill(val) {
    if (val == 1) {
      this.pil1 = true;
      this.pil2 = false;
      this.pil3 = false;
      this.pil4 = false;
    } else if (val == 2) {
      this.pil1 = false;
      this.pil2 = true;
      this.pil3 = false;
      this.pil4 = false;
    } else if (val == 3) {
      this.pil1 = false;
      this.pil2 = false;
      this.pil3 = true;
      this.pil4 = false;
    } else if (val == 4) {
      this.pil1 = false;
      this.pil2 = false;
      this.pil3 = false;
      this.pil4 = true;
    }
  }

  responsiveWidth() {}
  openFeedbackDialog() {
    this.selectedFeedback = true;
  }
  rateCall(rate) {
    this.crService.feedBack = false;
    this.crService.callRatePost(rate).subscribe((res) => {
      this.selectedFeedback = false;
    });
  }
  pill4Var;
  clickEsercitazioni = false;
  cambioPill(i) {
    if (i == 4) {
      this.pill4Var = true;
      this.clickEsercitazioni = false;
    } else if (i == 3) {
      this.clickEsercitazioni = true;
      this.pill4Var = false;
    } else {
      this.pill4Var = false;
      this.clickEsercitazioni = false;
    }
  }

  apriPopUpSondaggio() {
    if (!(this.classroomService instanceof GoToClassroomService)) return;
    this.popupInsegnanteSondaggio = true;
  }

  inviaSondaggio(shouldSend: boolean) {
      const classroomService = this.classroomService as unknown;
      if (classroomService instanceof GoToClassroomService) {
        if (!shouldSend) {
          this.popupInsegnanteSondaggio = false;
          return;
        }

        //chiusura della finestra
        if (this.sondaggioQuestionControl.invalid) {
          this.alertService.error('Messaggio invalido');
          return;
        }
        const message = this.sondaggioQuestionControl.value as string;

        classroomService
          .sendPool(this.aulaInfo.id, message)
          .subscribe((res) => {
            this.sondaggioQuestionControl.reset();
            this.popupInsegnanteSondaggio = false;
          });  
        ;
      }
      return;
  }
}
