<div class="col-xs-12 col-sm-12">
    <div class="card min-max-h-370">
      <div class="card-header card-header-text w-100">
        <h4 class="card-title display-inline">Grafico {{nomeTelemetria}} <span *ngIf="date">del {{ date | date:'dd/MM/yyyy'}}</span></h4>
          <span><small *ngIf="dispositivo" class="text-muted"> {{dispositivo.utente?.nome}} {{dispositivo.utente?.cognome}} -
            {{dispositivo.deviceName}} </small></span>
            <app-zoombar *ngIf="dataIot?.datasets[0].data.length>0" class="zoom-grafico" (currentZoomEmitter)="graficoWidth=modificaZoomGrafico($event)"></app-zoombar>
            <i *ngIf="dataIot?.datasets[0].data.length>0" class="material-icons notranslate pulsante-download" (click)="richiediDownload()">download</i>
          </div>
          <div class="legend-wrapper">
              <ng-content></ng-content>
          </div>
      <div class="pos-rel">
        <div *ngIf="dataIot?.datasets[0].data.length==0" class="no-data-overlay">
          <p class="no-data-message" *ngIf="date">Nessun dato registrato nella giornata odierna.</p>
          <p class="no-data-message" *ngIf="!date">Nessun dato registrato nel periodo richiesto.</p>
        </div>
        <div [ngClass]="{'no-data': dataIot?.datasets[0].data.length==0}" class="card-content table-responsive delete-overflow">
          <div *ngIf="!mostraChartIot" class="m-20 centered">
            <span>
              <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
            </span>
          </div>
          <div class="absolute">
            <p-chart *ngIf="mostraChartIot" type="line" [options]="options2"
              [height]="300" [data]="dataIot2"></p-chart>
          </div>
          <div [ngClass]="{'of-x-scroll': dataIot?.datasets[0].data.length>0}" class="w-95 relative bg-white ml-50 z-1" #zoomDiv>
            <div id="chart" [ngStyle]="{'width.px': graficoWidth}" class="sticky-y-axis">
              <p-chart *ngIf="mostraChartIot" class="sticky-y-axis" type="line" [options]="options"
                [height]="300" [data]="dataIot" #chart></p-chart>
            </div>
          </div>
        </div>
    </div>
    </div>
  </div>