
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { File as File2} from 'src/app/MODELS/FILE/file';
import { Utente } from 'src/app/MODELS/USER/utente';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { AccountService } from 'src/app/SERVICES/index';
import { UsersService } from 'src/app/SERVICES/index';
@Component({
  selector: 'app-add-edit-esercitazione',
  templateUrl: './add-edit-esercitazione.component.html',
  styleUrls: ['./add-edit-esercitazione.component.scss']
})
export class AddEditEsercitazioneComponent implements OnInit {

  @Input() idAula;
  @Input() fileId;
  @Output() tornaGestione = new EventEmitter<boolean>()
  addMode=false;
  editMode=false;
  infoMode=false;
  createForm: FormGroup;
  submitted = false;
  loading = false;
  listaRuoli = [];
  listaGruppi = [];
  listaUtenti = [];
  allListaUtenti = [];
  searchUtentiForm: FormGroup;//cerca in lista studenti
  selectedUtenti = [];
  opzioni: SelectItem[];
  selectedOption: string;
  opzioniInd: SelectItem[];
  selectedOptionInd: number;
  nomeRuolo;
  ruoli: SelectItem[];
  gruppi: SelectItem[];
  crea : boolean = false;
  nomeFile;
  selectedFile: File=null;
  utentiSelezionati = [];
  formConvalidato = false;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private alertService: AlertService,
   private primengConfig: PrimeNGConfig,
   private fileService : FileService,
   private accountService : AccountService
    )

    {
      this.opzioni = [{ label: "All", value: "0" }, { label: "Gruppi", value: "1" }, { label: "Profili", value: "2" }, { label: "Utenti", value: "3" }];
      this.opzioniInd = [{ label: "Sì", value: "1" }, { label: "No", value: "0" }];

    this.alertService.clear;
    }

  ngOnInit() {
    if(!this.fileId){
      this.addMode = true;
    }
    if(this.fileId){
      this.editMode = true;
    }
    this.alertService.openAlert(null)
    this.primengConfig.ripple = true;
    this.userService.getAllAttivi().subscribe((res)=>{
      this.listaUtenti=res.data;
      this.allListaUtenti = this.listaUtenti;
      if(this.editMode){
      if(this.fileId.visibUtente){
        this.listaUtenti.forEach(element =>{
          this.fileId.visibUtente.forEach(id => {
            if(element.id.toString() === id){
              this.selectedUtenti.push(element)
            }
          });
        })

      }
    }
      this.crea = true;
    })
    this.userService.getListaGruppi().subscribe((res) => {
      this.gruppi = [];
      this.listaGruppi = res.data;
      this.listaGruppi.forEach(gruppo =>{
        this.gruppi.push(
          {label:gruppo.nomeGruppo, value:gruppo.id}
        )
      });
    }, (error) => {

    });
    this.userService.getListaRuoli().subscribe((res) => {
      this.ruoli = [];
      this.listaRuoli = res.data;

      this.listaRuoli.forEach(ruolo => {
        this.ruoli.push(
          {label:ruolo.nomeRuolo, value:ruolo.id}
        )
      })

    }, (error) => {

    });
    this.searchUtentiForm = this.formBuilder.group({
      search: ['', Validators.required]
    });
    //Validators.pattern("^[a-z A-Z 0-9_-]+$")
    this.createForm = this.formBuilder.group({
      titolo: ['', [Validators.required]],
      // titolo: ['', [Validators.required, Validators.pattern("^[a-z A-ZÀ-ú0-9'_-]+$")]],
      autore: ['',[ Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      categoria: ['', [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      gruppo: ['', [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      visibRuolo: [''],
      visibGruppo: [''],
      // indicizza: ['', Validators.required]
    });

    if(this.editMode){
      this.createForm = this.formBuilder.group({
      titolo: [this.fileId.title, [Validators.required, Validators.pattern("^[a-z A-Z 0-9_-]+$")]],
      autore: [this.fileId.authors[0]?.name,[ Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      categoria: [this.fileId.category, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      gruppo: [this.fileId.group, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      visibRuolo: [this.fileId.visibRuolo],
      visibGruppo: [this.fileId.visibGruppo],
    });
    if(this.fileId.visibGruppo){
      this.selectedOption = "1"
    }
    else if(this.fileId.visibRuolo){
      this.selectedOption = "2"
    }
    else if(this.fileId.visibUtente){
      this.selectedOption = "3"
    }
    else {
      this.selectedOption = "0"
    }
    }

  }

  get f() { return this.createForm.controls; }


  convalidaForm(){
    this.formConvalidato=true;
  }
  onSubmit() {
    this.submitted = true;
    this.loading = true;

    if (this.createForm.invalid) {
      this.loading = false;
      return;
    }
    if(this.addMode){
      if(!this.selectedOptionInd){
        this.loading = false;
        return;
      }
    const uploadFile = new FormData();
    let idUtenti = [];
    this.selectedUtenti.forEach(element =>{
      idUtenti.push(element.id)
    })
    let file = {
      title: this.createForm.value.titolo,
      // title: encodeURI(this.createForm.value.titolo),
      autore: this.createForm.value.autore,
      category: this.createForm.value.categoria,
      group: this.createForm.value.gruppo,
      visibGruppo: this.createForm.value?.visibGruppo,
      visibilitàProfilo: this.createForm.value?.visibRuolo,
      visibilitàUtenti: idUtenti,
      indicizza: this.selectedOptionInd,
      owner: this.accountService.userValue.data.id.toString(),
    }
    if(this.selectedFile){
      uploadFile.append('imageFile', this.selectedFile, this.selectedFile.name);
      uploadFile.append('visibGruppo', JSON.stringify(file.visibGruppo));
      uploadFile.append('visibRuolo', JSON.stringify(file.visibilitàProfilo));
      uploadFile.append('visibUtente', JSON.stringify(file.visibilitàUtenti));
      uploadFile.append('idAula', this.idAula);
      uploadFile.append('category', file.category)
      uploadFile.append('group', file.group)
      uploadFile.append('owner', file.owner)
      uploadFile.append('title', file.title)
      uploadFile.append('author', file.autore)
      uploadFile.append('index', file.indicizza.toString())
      }

      else{
        this.loading = false;
      return;
      }


    if(this.formConvalidato){
      if (uploadFile.get('group').toString().includes('registrazioni')) {
        this.alertService.warn('Il file verrà salvato nella sezione registrazioni.')
      }
    this.fileService.upload( uploadFile ).subscribe((res) => {
      this.loading = false;
 this.alertService.success(res.status.description);
 this.fileId = null;
 this.tornaGestione.emit(true)
    },(error) => {
      this.loading = false;
    })}

  }
  if(this.editMode){

      let idUtenti = [];
      this.selectedUtenti.forEach(element =>{
        idUtenti.push(element.id)
      })

        this.fileId.title= this.createForm.value.titolo
        let autore = {
          name: this.createForm.value.autore
        }
        this.fileId.authors= []
        this.fileId.authors.push(autore)
        this.fileId.category= this.createForm.value.categoria
        this.fileId.group= this.createForm.value.gruppo
        this.fileId.visibGruppo= this.createForm.value?.visibGruppo
        this.fileId.visibRuolo= this.createForm.value?.visibRuolo
        this.fileId.visibUtente = idUtenti


      this.fileService.edit(this.fileId).subscribe((res) => {
        this.loading = false;
   this.alertService.success(res.status.description);
   this.fileId = null;
   this.tornaGestione.emit(true)
      },(error) => {
        this.loading = false;
      })


  }
  }

  clear(){
    this.fileId = null;
    this.tornaGestione.emit(true)
  }

  searchInUtenti(){
    let valore: string = this.searchUtentiForm.value.search;


    this.listaUtenti = this.allListaUtenti;

    if (valore) {
      let users = []
      this.listaUtenti.forEach(user => {
      let nomeCognome = user.nome + ' ' + user.cognome;
      let cognomeNome = user.cognome + ' ' + user.nome;



      if (cognomeNome.toLowerCase().includes(valore.toLowerCase()) ||
          nomeCognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.nome.toLowerCase().includes(valore.toLowerCase()) ||
          user.cognome.toLowerCase().includes(valore.toLowerCase()) ||
          user.email.toLowerCase().includes(valore.toLowerCase())
      ) {
        if (!(users.find(x => x === user))) {
        users.push(user);
        }
      }
    });
    this.listaUtenti = users;

    } else if (!valore) {
      this.listaUtenti ;
    }
  }

  public onFileChanged(event) {
    this.selectedFile=null;

        this.nomeFile=event.target.files[0].name;
        this.selectedFile = event.target.files[0];

      }
}

