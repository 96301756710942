import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dateAgoIOT',
    pure: false
})
export class DateAgoIOTPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            const seconds =( Math.floor((+new Date() - +new Date(value)) / 1000));
            if (seconds < 60) 
                return '< 1 min';
            const intervals = {
                'anno': 31536000,
                'mese': 2592000,
                'settimana': 604800,
                'giorno': 86400,
                'ora': 3600,
                'minuto': 60,
                'secondo': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + i; // singular (1 day ago)
                    } else {
                        if(i === 'anno'){
                            return counter + ' ' + 'anni'; // plural (2 days ago)
                        }
                        if(i === 'mese'){
                            return counter + ' ' + 'mesi'; // plural (2 days ago)
                        }
                        if(i === 'settimana'){
                            return counter + ' ' + 'settimane'; // plural (2 days ago)
                        }
                        if(i === 'giorno'){
                            return counter + ' ' + 'giorni'; // plural (2 days ago)
                        }
                        if(i === 'ora'){
                            return counter + ' ' + 'ore'; // plural (2 days ago)
                        }
                        if(i === 'minuto'){
                            return counter + ' ' + 'min'; // plural (2 days ago)
                        }
                    }
            }
        }
        return value;
    }

}