import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { Password } from 'src/app/MODELS/USER/password';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { AccountService, EnvService } from 'src/app/SERVICES/index';
import { UsersService } from 'src/app/SERVICES/index';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html' ,
styleUrls: ['./login.component.scss']})
export class LoginComponent implements OnInit {
    form: FormGroup;
    formPassword: FormGroup;
    loading = false;
    submitted = false;
    hide = true;
    hide1 = true;
    hide2 = true;
    hide3 = true;
    returnUrl: string;
    lat : number;
    lng : number;
    id : number;
    email : string;
    modificaPassword = false;
    login= true;
    assistenza=false
    retrievedImage;
    env
    constructor(
        private formBuilder: FormBuilder,
        // private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService,
        private userService : UsersService,
        private service : ShareService,
        private location : Location,
    ) {
        this.env=environment
        this.service.passaImmagine$.subscribe(res=>{
            this.retrievedImage = res;

        })

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.formPassword = this.formBuilder.group({
            oldpassword: ['', Validators.required],
            newpassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
            confermapassword: ['', Validators.required]
        }, {
            validator: this.MustMatch('newpassword', 'confermapassword')
        });

        // get return url from route parameters or default to '/'
        //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }
    get g() { return this.formPassword.controls; }

    MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
          const control = formGroup.controls[controlName];
          const matchingControl = formGroup.controls[matchingControlName];

          if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
          }

          // set error on matchingControl if validation fails
          if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
          } else {
            matchingControl.setErrors(null);
          }
        }
      }

    onSubmit() {

        this.submitted = true;
        this.g.oldpassword.setValue(null)
        this.g.newpassword.setValue(null)
        this.g.confermapassword.setValue(null)

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;

        this.email = this.f.email.value;
        this.accountService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {


                    this.id = data.data.id;
                    navigator.geolocation.getCurrentPosition(res => {
                        this.lat = res.coords.latitude;
                        this.lng = res.coords.longitude;
                        let user = this.accountService.userValue;

                    this.userService.salvaCoordinate(this.id, this.lat, this.lng).subscribe((res)=>{
                        this.location.replaceState('')
                        location.reload();

                        },
                        error => {
                            this.location.replaceState('')
                            location.reload();
                        })
                    },
                    error => {
                        this.location.replaceState('')
                        location.reload();
                    },
                    {
                        timeout: 10000
                    }
                    )

                },
                error => {
                    this.submitted = false;
                    this.loading = false;
                    this.service.changePass$.subscribe(res => this.modificaPassword = res)
                });
    }

    vaiEmail(){
        this.router.navigate(['assistenza']);
    }
    indietro(vai){
        this.login=true;
        this.assistenza=false
    }
    onSubmitPassword() {

        this.submitted = true;

        let string1: string = this.formPassword.value.newpassword;
        let string2: string = this.formPassword.value.confermapassword;
        let string3: string = this.formPassword.value.oldpassword;
        let passwords : Password = new Password;
        passwords.newPassword=string1;
        passwords.currentPassword=string2;
        passwords.oldPassword= string3;


        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
      if (this.formPassword.invalid) {
            return;
        }

        this.loading = true;

        this.accountService.PrimoAccesso(this.email, passwords)
        .pipe(first())
        .subscribe(
            data => {

                this.modificaPassword = false;
                this.loading = false;
                this.alertService.success( data.status.description );
                this.f.password.setValue(null)
                this.submitted= false;
            },
            error => {
                this.loading = false;
                this.submitted = false;
            });


    }
  password='password'
  showPw=false
showPassword(v){
this.showPw=v
if(v==true){
this.password = "text";
}else{
    this.password  = "password";
}
}
}
