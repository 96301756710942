import { HttpClient } from '@angular/common/http';
import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
import {
  AccountService,
  AlertService,
  ShareService,
  UsersService,
} from 'src/app/SERVICES';
import { PrimeNGConfig } from 'primeng/api';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-configurazione',
  templateUrl: './configurazione.component.html',
  styleUrls: ['./configurazione.component.scss'],
  providers: [ConfirmationService],
})
export class ConfigurazioneComponent implements OnInit, DoCheck {
  applicato = false;
  dataFile;
  listamotivi = [];
  listaQuestionario = [];
  listamotiviOrdine = [];
  listamotiviRicercaMercato = [];
  listaTipologiaAttivita = [];
  listaTipologiaLaboratori = [];
  listaObiettivi = [];
  cambiaImmagina = false;
  motivoChiamata = true;
  selectedMotivo;
  colore: String;
  msgs: Message[] = [];
  motivoForm: FormGroup;
  motivoFormMercatoOrdine: FormGroup;
  tipologiaAttivitaForm:FormGroup;
  tipologiaLaboratoriForm:FormGroup;
  obiettiviGeneraliForm:FormGroup;
  loading;
  ricercaQuestionario = '';
  isModificaQuestionario = false;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  nomeFile;
  selectedFile: File = null;
  retrievedImage: any;
  base64Data: any;
  refreshView = true;
  showAddQuestionario = false;
  retrieveResonse: any;
  message: string;
  imageName: any;
  immagine;
  classroom;
  isClickableQuestionari = true;
  cr;
  iot;
  badge;
  alfa;
  alfaCallCenter;
  clienti;
  listaTarget=[];
  maxPagine = 1;
  questionarioToAdd: any = {
    nomeQuestionario: '',
    listaDomande: [],
    listaTarget: []
  }
  isLoading=false;
  constructor(
    private userService: UsersService,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private confirmationService: ConfirmationService,
    private alertService: AlertService,
    private primengConfig: PrimeNGConfig,
    private shareservice: ShareService,
    private env: EnvService,
    private accountService: AccountService,
    private fileService: FileService,
    private routing: Router
  ) {
    this.classroom = this.env.CLASSROOM;
    this.cr = this.env.CR;
    this.iot = this.env.IOT;
    this.badge = this.env.BADGE;
    this.alfa = this.env.alfaintes;
    this.alfaCallCenter = this.env.ALFACALLCENTER;
    this.clienti=this.env.CLIENTI;
    this.userService.getMotivi().subscribe((res) => {
      this.listamotivi = res.data;
    });
    if (this.alfaCallCenter) {
      setTimeout(() => {
        document.getElementById('ricercaMercato').click();
      }, 100);

      this.userService.getOrdine().subscribe((res) => {
        this.listamotiviOrdine = res.data;
      });
      this.userService.getRicercaMercato().subscribe((res) => {
        this.listamotiviRicercaMercato = res.data;
      });
      this.userService.getQuestionari(0).subscribe((res) => {
        this.maxPagine = res.data.pageCount;
        this.listaQuestionario = res.data.pageList;
      });
      this.userService.getTarget().subscribe((res) => {
        this.listaTarget = res.data;
      });
    }
    if(this.clienti){
      this.userService.getTipologiaAttivita().subscribe((res) => {
        this.listaTipologiaAttivita = res.data;
      })

      this.userService.getTipologiaLaboratori().subscribe((res) => {
        this.listaTipologiaLaboratori = res.data;
      })

      this.userService.getObiettiviGenerali().subscribe((res) => {
        this.listaObiettivi = res.data;
      })

    }
  }

  ngDoCheck(): void {
    if (this.accountService.listaEnv && this.accountService.ruolo !== 1) {
      this.routing.navigate(['']);
    }
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.motivoForm = this.formBuilder.group({
      motivo: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
        ],
      ],
    });
    this.motivoFormMercatoOrdine = this.formBuilder.group({
      mercatoOrdine: ['', [Validators.required]],
    });
    this.tipologiaAttivitaForm = this.formBuilder.group({
      nomeAttivita: ['', [Validators.required]],
    });
    this.tipologiaLaboratoriForm = this.formBuilder.group({
      nomeLaboratoriInterventi: ['', [Validators.required]],
    });
    this.obiettiviGeneraliForm = this.formBuilder.group({
      nomeObiettivo: ['', [Validators.required]],
    });
  }

  //PARTE COLORE TEMA
  cambiaColore(colore: String) {
    this.colore = colore;
    this.shareservice.tryColor(colore);
    //this.applicato=false;
    //this.userService.cambiaColore.emit(colore);
  }

  applica() {
    this.confirmationService.confirm({
      message: 'Applicare il tema scelto?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.userService.applicaColore(this.colore).subscribe((res) => {
          // this.userService.coloreApplicato.emit(true);
          this.shareservice.changeColor(true);
          this.applicato = true;
          this.alertService.success(res.status.description);
        });
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      },
    });
  }

  ngOnDestroy() {
    if (!this.applicato) {
      this.colore = localStorage.getItem('colore');
      this.shareservice.tryColor(this.colore);
      //this.userService.cambiaColore.emit('blu');
    }
  }

  //PARTE MOTIVI CHIAMATA
  rimuoviMotivo(id) {
    this.confirmationService.confirm({
      message: 'Rimuovere il motivo?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.userService.removeMotivo(id).subscribe((res) => {
          this.alertService.success(res.status.description);
          this.userService.getMotivi().subscribe((res) => {
            this.listamotivi = res.data;
          });
        });
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      },
    });
    // this.userService.removeMotivo(id).subscribe((res) => {
    //   this.userService.getMotivi().subscribe((res) => {
    //     this.listamotivi = res.data;

    //   })

    // })
  }
  modificaQuestionario(id) {
    this.userService.getQuestionarioById(id).subscribe((res)=>{
      this.questionarioToAdd = res.data;
      this.isModificaQuestionario = true;
      this.showAddQuestionario = true;
    })
  }
  rimuoviMotivoMercato(id) {
    this.confirmationService.confirm({
      message: 'Rimuovere la ricerca di mercato selezionata?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.userService.removeRicercaMercato(id).subscribe((res) => {
          this.alertService.success(res.status.description);
          this.userService.getRicercaMercato().subscribe((res) => {
            this.listamotiviRicercaMercato = res.data;
          });
        });
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      },
    });
    // this.userService.removeMotivo(id).subscribe((res) => {
    //   this.userService.getMotivi().subscribe((res) => {
    //     this.listamotivi = res.data;

    //   })

    // })
  }
  rimuoviQuestionario(id) {
    this.confirmationService.confirm({
      message: 'Rimuovere il Questionario?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.refreshView = false;
        this.userService.removeQuestionario(id).subscribe((res) => {
          this.alertService.success(res.status.description);
          this.ricercaQuestionario = '';
          this.userService.getQuestionari(0).subscribe((res) => {
            this.maxPagine = res.data.pageCount;
            this.refreshView = true;
            this.listaQuestionario = res.data.pageList;
          });
        });
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      },
    });
  }
  rimuoviMotivoOrdine(id) {
    this.confirmationService.confirm({
      message: "Rimuovere l'ordine selezionato?",
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.userService.removeOrdine(id).subscribe((res) => {
          this.alertService.success(res.status.description);
          this.userService.getOrdine().subscribe((res) => {
            this.listamotiviOrdine = res.data;
          });
        });
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      },
    });
    // this.userService.removeMotivo(id).subscribe((res) => {
    //   this.userService.getMotivi().subscribe((res) => {
    //     this.listamotivi = res.data;

    //   })

    // })
  }
  //aggiungimotivo
  onSubmit() {
    if (this.motivoForm.invalid) {
      this.alertService.error('Motivo chiamata invalido');
      return;
    }
    let motivo = this.motivoForm.controls.motivo.value;
    this.motivoForm.reset();
    this.userService.aggiungiMotivo(motivo).subscribe((res) => {
      this.userService.getMotivi().subscribe((res) => {
        this.listamotivi = res.data;
        this.alertService.success(res.status.description);
      });
    });
  }

  onSubmitMercato() {
    if (this.motivoFormMercatoOrdine.invalid) {
      this.alertService.error('Motivo chiamata invalido');
      return;
    }
    let motivo = this.motivoFormMercatoOrdine.controls.mercatoOrdine.value;
    this.motivoFormMercatoOrdine.reset();
    this.userService.aggiungiRicercaMercato(motivo).subscribe((res) => {
      this.userService.getRicercaMercato().subscribe((res) => {
        this.listamotiviRicercaMercato = res.data;
        this.alertService.success(res.status.description);
      });
    });
  }
  onSubmitOrdini() {
    if (this.motivoFormMercatoOrdine.invalid) {
      this.alertService.error('Motivo chiamata invalido');
      return;
    }
    let motivo = this.motivoFormMercatoOrdine.controls.mercatoOrdine.value;
    this.motivoFormMercatoOrdine.reset();
    this.userService.aggiungiOrdine(motivo).subscribe((res) => {
      this.userService.getOrdine().subscribe((res) => {
        this.listamotiviOrdine = res.data;
        this.alertService.success(res.status.description);
      });
    });
  }

  onSubmitTipologiaAttivita() {
    if (this.tipologiaAttivitaForm.invalid) {
      this.confirmationService.close();
      this.loading = false;
      this.alertService.error('Tipologia invalida');
      return;
    }
    let tipologia = this.tipologiaAttivitaForm.controls.nomeAttivita.value;
    this.tipologiaAttivitaForm.reset();
    this.userService.aggiungiTipologiaAttivita({nomeAttivita:tipologia}).subscribe((res) => {
      this.confirmationService.close();
      this.loading = false;
      this.userService.getTipologiaAttivita().subscribe((res) => {
        this.listaTipologiaAttivita = res.data;
        this.alertService.success(res.status.description);
      });
    });
  }
  onSubmitTipologiaLaboratori() {
    if (this.tipologiaLaboratoriForm.invalid) {
      this.confirmationService.close();
      this.loading = false;
      this.alertService.error('Tipologia invalida');
      return;
    }
    let tipologia = this.tipologiaLaboratoriForm.controls.nomeLaboratoriInterventi.value;
    this.tipologiaLaboratoriForm.reset();
    this.userService.aggiungiTipologiaLaboratori({nomeLaboratoriInterventi:tipologia}).subscribe((res) => {
      this.confirmationService.close();
      this.loading = false;
      this.userService.getTipologiaLaboratori().subscribe((res) => {
        this.listaTipologiaLaboratori = res.data;
        this.alertService.success(res.status.description);
      });
    });
  }
  onSubmitObiettivi() {
    if (this.obiettiviGeneraliForm.invalid) {
      this.confirmationService.close();
      this.loading = false;
      this.alertService.error('Obiettivo invalido');
      return;
    }
    let obiettivo = this.obiettiviGeneraliForm.controls.nomeObiettivo.value;
    this.obiettiviGeneraliForm.reset();
    this.userService.aggiungiObiettiviGenerali({nomeObiettivo:obiettivo}).subscribe((res) => {
      this.confirmationService.close();
      this.loading = false;
      this.userService.getObiettiviGenerali().subscribe((res) => {
        this.listaObiettivi = res.data;
        this.alertService.success(res.status.description);
      });
    });
  }

  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required]),
  });
  get f() {
    return this.myForm.controls;
  }
  //CARICA LOGO

  public onFileChanged(event) {
    this.selectedFile = null;
    this.nomeFile = event.target.files[0].name;
    this.selectedFile = event.target.files[0];
    if (event.target.files && this.selectedFile) {
      // Size Filter Bytes
      const max_size = 1000000;
      //const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 125;
      const max_width = 400;
      if (this.selectedFile.size > max_size) {
        alert('Peso massimo deve essere di 1MB');
        this.selectedFile = null;
        this.nomeFile = null;
        this.isImageSaved = false;
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height !== max_height && img_width !== max_width) {
            alert("L'immagine deve avere dimensioni 400x125");
            this.selectedFile = null;
            this.nomeFile = null;
            this.isImageSaved = false;
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
          }
        };
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  onUpload() {
    if (this.selectedFile) {
      const uploadImageData = new FormData();
      uploadImageData.append(
        'imageFile',
        this.selectedFile,
        this.selectedFile.name
      );
      this.userService.caricaImmagine(uploadImageData).subscribe((res) => {
        this.alertService.success(res.status.description);
        this.shareservice.changeImage(true);
      });
    } else {
      alert('Nessun file caricato');
    }
  }

  onRemove() {
    this.loading = true;
    this.userService.rimuoviImmagine().subscribe(
      (res) => {
        this.alertService.success(res.status.description);
        this.loading = false;
        this.shareservice.changeImage(true);
        this.confirmationService.close();
      },
      (error) => {
        this.loading = false;
        this.confirmationService.close();
      }
    );
  }

  cancella() {
    this.confirmationService.confirm({
      key: 'cancella',
      message:
        'Sicuro di voler continuare? ' + '<br>' + ' I dati verranno persi!',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.loading = false;
        this.confirmationService.close();
      },
    });
  }
  responsiveWidth() {}
  paginaCorrente = 0
  subscription;
  search() {
    if(this.subscription) this.subscription.unsubscribe();
    this.subscription = this.userService.getQuestionari(this.paginaCorrente,this.ricercaQuestionario).subscribe((res) => {
      this.listaQuestionario = res.data.pageList;
      this.maxPagine = res.data.pageCount;
      this.isClickableQuestionari = true;
    });
  }
  cambiaPaginaQuestionari(event) {
    this.paginaCorrente = event;
    this.isClickableQuestionari = false;
    this.userService.getQuestionari(event,this.ricercaQuestionario).subscribe((res) => {
      this.listaQuestionario = res.data.pageList;
      this.maxPagine = res.data.pageCount;
      this.isClickableQuestionari = true;
    });
  }

  addDomanda() {
    this.questionarioToAdd.listaDomande.push({});
  }

  addQuestionario() {
    this.isLoading=true;
    if(this.questionarioToAdd.id) {
      this.userService.modificaQuestionari(this.questionarioToAdd.id, this.questionarioToAdd).subscribe((res)=>{
        this.procedureCreateModify()
        this.isLoading=false;
        this.alertService.success('Questionario modificato con successo.');
      },
      (error)=>{
        this.isLoading=false;
      });
    } else {
      this.userService.creaQuestionari(this.questionarioToAdd).subscribe((res)=>{
        this.procedureCreateModify()
        this.isLoading=false;
        this.alertService.success('Questionario creato con successo.');
      },
      (error)=>{
        this.isLoading=false;
      });
    }
  }
  procedureCreateModify() {
    this.questionarioToAdd = {
      nomeQuestionario: '',
      listaDomande: [],
      listaTarget: []
    };
    this.showAddQuestionario=false;
    this.isModificaQuestionario=false;
    this.userService.getQuestionari(0,this.ricercaQuestionario).subscribe((res) => {
      this.maxPagine = res.data.pageCount;
      this.listaQuestionario = res.data.pageList;
    });
  }
  removeDomanda(i) {
    this.questionarioToAdd.listaDomande.splice(i, 1);
  }
  compareItems(i1, i2) {
    return i1 && i2 && i1.id===i2.id;
  }

  accettaTipologiaAttivita(){
    this.confirmationService.confirm({
      key: 'accettaTipologiaAttivita',
      message:
        'Questi sono i dati che stai inserendo: "' + this.tipologiaAttivitaForm.controls.nomeAttivita.value +'"'+ '<br>' + ' Vuoi continuare?',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.tipologiaAttivitaForm.reset();
        this.loading = false;
        this.confirmationService.close();
      },
    });
  }

  accettaTipologiaLaboratori(){
    this.confirmationService.confirm({
      key: 'accettaTipologiaLaboratori',
      message:
        'Questi sono i dati che stai inserendo: "' + this.tipologiaLaboratoriForm.controls.nomeLaboratoriInterventi.value +'"'+ '<br>' + ' Vuoi continuare?',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.tipologiaLaboratoriForm.reset();
        this.loading = false;
        this.confirmationService.close();
      },
    });
  }

  accettaObiettiviGenerali(){
    this.confirmationService.confirm({
      key: 'accettaObiettiviGenerali',
      message:
        'Questi sono i dati che stai inserendo: "' + this.obiettiviGeneraliForm.controls.nomeObiettivo.value +'"'+ '<br>' + ' Vuoi continuare?',
      icon: 'pi pi-exclamation-triangle',
      reject: () => {
        this.obiettiviGeneraliForm.reset();
        this.loading = false;
        this.confirmationService.close();
      },
    });
  }
}
