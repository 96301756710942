import { Component, Input, OnInit } from '@angular/core';
import { AlertService, EnvService } from 'src/app/SERVICES';

@Component({
  selector: 'app-smart-conf',
  templateUrl: './smart-conf.component.html',
  styleUrls: ['./smart-conf.component.scss']
})
export class SmartConfComponent implements OnInit {
  SERVER = "192.168.2.107";
  PORT = 4443;

  serviceUuid = 0xfff0;
  rxCharacteristicUuid = 0xfff7;
  txCharacteristicUuid = 0xfff6;

  GET_DEVICE_FW_VER = 39;
  GET_DEVICE_CON_STATS = 24;

  SET_DEVICE_WIFI_NAME = 6;
  FAIL_WIFI_NAME = 38;

  SET_DEVICE_WIFI_PASS = 7;
  FAIL_WIFI_PASS = 39;

  SET_DEVICE_SERVER_ADDR = 9;
  FAIL_SERVER_ADDR = 41;

  SET_DEVICE_SERVER_PORT = 51;
  FAIL_SERVER_PORT = 42;

  Encoder = new TextEncoder();

  dev;
  ser;
  rxCharacteristic;
  txCharacteristic;

  //check SET
  setDeviceWifiName = false;
  setDeviceWifiPass = false;
  setDeviceWifiAddress = false;
  setDeviceWifiPort = false;

  // variabili non-logiche

  @Input() showAllComponent = false;
  constructor(private alertService: AlertService, private env: EnvService) { }

  ngOnInit(): void {
    if (this.env.serverIot)
      this.SERVER = this.env.serverIot;
  }

  logHex(data) {
    let str = ""
    data.forEach(element => {
      str += " " + element.toString(16);
    });
  }

  caricato
  connectToDevice() {
    this.showAllComponent = !this.showAllComponent;
    if (this.showAllComponent) {
      let mobileNavigatorObject: any = window.navigator;
      if (mobileNavigatorObject && mobileNavigatorObject.bluetooth) {
        mobileNavigatorObject.bluetooth.requestDevice({
          filters: [{
            services: [this.serviceUuid]
          }]
        })
          .then(device => {
            this.dev = device;
            return device.gatt.connect();
          })
          .then(server => {
            return server.getPrimaryService(this.serviceUuid);
          })
          .then(service => {
            this.ser = service
            return service.getCharacteristic(this.rxCharacteristicUuid);
          })
          .then(characteristic => {
            this.rxCharacteristic = characteristic;
            return this.rxCharacteristic.startNotifications().then(_ => {
              this.rxCharacteristic.addEventListener('characteristicvaluechanged',
                this.handleNotifications.bind(this));

            });
          })
          .then(_ => {
            this.ser.getCharacteristic(this.txCharacteristicUuid).then(characteristic => {
              this.txCharacteristic = characteristic;
            }).catch(error => {

            })
          })
          .catch(error => {
            this.updateStatus(false, "Connessione fallita. Riprovare");
          });
      }
    }

  }
  smartbedConfigured = false;
  handleNotifications(event) {
    let value = event.target.value;
    let v = value.getUint8(0);
    try {
      switch (v) {
        case this.SET_DEVICE_WIFI_NAME:
          this.setDeviceWifiName = true;
          break;
        case this.SET_DEVICE_WIFI_PASS:
          this.setDeviceWifiPass = true;
          break;
        case this.FAIL_WIFI_NAME:
          this.updateStatus(false, "Errore nell'impostazione della Password Wifi");
          this.setDeviceWifiName = false;
          break;
        case this.FAIL_WIFI_PASS:
          this.updateStatus(false, "Errore nell'impostazione del nome Wifi");
          this.setDeviceWifiPass = false;
          break;
        case this.SET_DEVICE_SERVER_ADDR:
          this.setDeviceWifiAddress = true;
          break;
        case this.SET_DEVICE_SERVER_PORT:
          this.setDeviceWifiPort = true;
          if (this.setDeviceWifiAddress && this.setDeviceWifiName &&
            this.setDeviceWifiPass && this.setDeviceWifiPort) {
            this.smartbedConfigured = true;
            if(this.smartbedConfigured)
            this.updateStatus(true, "Smartbed configurata con successo")
            setTimeout(()=>{this.smartbedConfigured=false},5000)
          }
          this.resetSetDeviceWifiStatus()
          break;
        case this.FAIL_SERVER_ADDR:
          this.updateStatus(false, "Errore nell'impostazione dell'Indirizzo server")
          this.setDeviceWifiAddress = false;
          break;
        case this.GET_DEVICE_CON_STATS:
          let wifiStat = value.getUint8(1);
          let serverStat = value.getUint8(2);
          this.updateStatus(true, "WiFI: " + wifiStat + " Server: " + serverStat);
          break;
        case this.FAIL_SERVER_PORT:
          this.updateStatus(false, "Porta server");
          break;
        default:
          break;
      }
    } catch (e) {
    }
  }
  resetSetDeviceWifiStatus() {
    this.setDeviceWifiAddress = false;
    this.setDeviceWifiName = false;
    this.setDeviceWifiPass = false;
    this.setDeviceWifiPort = false;
  }

  dealyMs(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  sendValue(value) {
    return this.txCharacteristic.writeValueWithoutResponse(value)
      .catch(err => {
      });
  }

  /*
      Funzioni per generazione messaggi di configurazione wifi
  */

  calculateCrc(message) {
    let crc = 0;
    message.forEach(element => {
      crc += element;
    });
    message[message.length - 1] = crc;
  }

  /**
   * Genera il messaggio per la configurazione della password dell'access point
   * Ex:
   *      Password: =2E?_(d8, Message: 07-3D-32-45-3F-5F-28-64-38-00-00-00-00-00-00-00-00-00-00-1D
   * @param {*} password
   * @returns
   */
  makeApPasswordMessage(password) {
    if (password.length > 14) {
      let passMsg1 = new Uint8Array(20);
      let passMsg2 = new Uint8Array(20);

      let passPart1 = password.slice(0, 14);
      let passPart2 = password.slice(14, 28);

      passMsg1[0] = this.SET_DEVICE_WIFI_PASS;
      passMsg1[1] = 0x00;
      passMsg1[2] = password.length;
      passMsg1.set(this.Encoder.encode(passPart1), 3);
      this.calculateCrc(passMsg1);

      passMsg2[0] = this.SET_DEVICE_WIFI_PASS;
      passMsg2[1] = 0x01;
      passMsg2[2] = password.length;
      passMsg2.set(this.Encoder.encode(passPart2), 3);
      this.calculateCrc(passMsg2);

      return {
        passMsg1,
        passMsg2
      };

    } else {
      let passMsg1 = new Uint8Array(20);

      passMsg1[0] = this.SET_DEVICE_WIFI_PASS;
      passMsg1.set(this.Encoder.encode(password), 1);
      this.calculateCrc(passMsg1);

      return {
        passMsg1
      };
    }
  }


  /**
   * Genera il messaggio per la configurazione del nome dell'access point
   * Ex:
   *      Name: OpenWrt, Message: 06-4F-70-65-6E-57-72-74-00-00-00-00-00-00-00-00-00-00-00-D5
   * @param {*} name
   * @returns
   */
  makeApNameMessage(name) {
    if (name.length > 14) {
      let nameMsg1 = new Uint8Array(20);
      let nameMsg2 = new Uint8Array(20);

      let namePart1 = name.slice(0, 14);
      let namePart2 = name.slice(14, 28);

      nameMsg1[0] = this.SET_DEVICE_WIFI_NAME;
      nameMsg1[1] = 0x00;
      nameMsg1[2] = name.length;
      nameMsg1.set(this.Encoder.encode(namePart1), 3);
      this.calculateCrc(nameMsg1);

      nameMsg2[0] = this.SET_DEVICE_WIFI_NAME;
      nameMsg2[1] = 0x01;
      nameMsg2[2] = name.length;
      nameMsg2.set(this.Encoder.encode(namePart2), 3);
      this.calculateCrc(nameMsg2);

      return {
        nameMsg1,
        nameMsg2
      };

    } else {
      let nameMsg1 = new Uint8Array(20);
      nameMsg1[0] = this.SET_DEVICE_WIFI_NAME;
      nameMsg1.set(this.Encoder.encode(name), 1);
      this.calculateCrc(nameMsg1);

      return {
        nameMsg1
      };
    }
  }


  /*
      Funzioni per generazione messaggi di configurazione Server
  */

  /**
   * Generazione messaggi per configurazione hostname server
   * Ex:
   *      Ip: 192.168.2.107, Message: 09-31-39-32-2E-31-36-38-2E-32-2E-31-30-37-00-00-00-00-00-98
   * @param {*} hostname
   * @returns
   */
  makeServerIpMessage(hostname) {
    let serverIpMsg = new Uint8Array(20);

    serverIpMsg[0] = this.SET_DEVICE_SERVER_ADDR;
    serverIpMsg.set(this.Encoder.encode(hostname), 1)
    this.calculateCrc(serverIpMsg);

    return {
      serverIpMsg
    };
  }

  /**
   * Generazione messaggio per configurazione porta server
   * Ex:
   *      Port: 4444, Message: 33-5C-11-00-00-00-00-00-00-00-00-00-00-00-00-A0
   * @param {*} port
   * @returns
   */
  makeServerPortMessage(port) {
    let serverPortMsg = new Uint8Array(16);

    //Prepare Server Port message
    serverPortMsg[0] = this.SET_DEVICE_SERVER_PORT;
    serverPortMsg[1] = (port & 0xff);
    serverPortMsg[2] = ((port >> 8) & 0xff);
    this.calculateCrc(serverPortMsg);

    return {
      serverPortMsg
    };
  }

  async sendApName(apNamePkt) {
    if (apNamePkt.nameMsg1 && apNamePkt.nameMsg2 == null) {
      await this.sendValue(apNamePkt.nameMsg1);
    } else {
      await this.sendValue(apNamePkt.nameMsg1);
      await this.dealyMs(500);
      await this.sendValue(apNamePkt.nameMsg2);
    }
  }

  async sendApPass(apPassPkt) {
    if (apPassPkt.passMsg1 && apPassPkt.passMsg2 == null) {
      await this.sendValue(apPassPkt.passMsg1);
    } else {
      await this.sendValue(apPassPkt.passMsg1);
      await this.dealyMs(500);
      await this.sendValue(apPassPkt.passMsg2);
    }
  }

  async configure() {
    this.caricato = true
    try {
      let apName: any = document.getElementById('apname');
      let apPass: any = document.getElementById('appass');
      // apName = apName.value;
      // apPass = apPass.value;
      if (apName.value && apPass.value){
      let apNamePkt = this.makeApNameMessage(apName.value);
      let apPassPkt = this.makeApPasswordMessage(apPass.value);

      let srvIpPkt = this.makeServerIpMessage(this.SERVER);
      let srvPortMsg = this.makeServerPortMessage(this.PORT);

      /*
       Start sending data
      */
      await this.sendApName(apNamePkt);
      await this.dealyMs(500);
      await this.sendApPass(apPassPkt);
      await this.dealyMs(500);
      await this.sendValue(srvIpPkt.serverIpMsg);
      await this.dealyMs(500);
      await this.sendValue(srvPortMsg.serverPortMsg);
      this.caricato = false;
    } else {
      this.alertService.error("Errore durante l'inserimento dei dati")
      this.caricato = false;
    }

    } catch (error) {
      this.alertService.error("Errore durante l'inserimento dei dati")
    }
  }

  updateStatus(status, message) {
    /*
    let statusDiv = document.getElementById("status-div");

    let statIcon = status ? String.fromCodePoint(0x2714) : String.fromCodePoint(0x274c);

    let node = document.createElement("p");
    let content = document.createTextNode(statIcon + ":" + message);
    node.appendChild(content);

    statusDiv.appendChild(node);
    */
    if (!status) { this.alertService.error(message) } else { this.alertService.success(message) }
  }

  clearStatus() {
    let statusDiv = document.getElementById("status-div");
    while (statusDiv.firstChild) {
      statusDiv.removeChild(statusDiv.lastChild);
    }
  }


  passwordType="password"
  visibility="visibility"
  passwordTypeB=true;
  showPassword(){
    this.passwordTypeB =!this.passwordTypeB
    if(this.passwordTypeB){
      this.passwordType="password"
 this.visibility="visibility"
    }else{
      this.passwordType="text"
      this.visibility="visibility_off"
    }
  }
}
