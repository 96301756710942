import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { deprecate } from 'util';
import { EnvService } from '../GENERAL/env.service';

@Injectable({
  providedIn: 'root'
})
export class ArService {

  constructor(private http: HttpClient, private env : EnvService) { }
  /**
   * @deprecated Adesso è possibile aggiungere il colore
   * Invia il punto e il suo sessionID all'operatore che ha aperto la sessione AR, in modo che,
   * possa creare il segna posto
   * @param x coordinate x
   * @param y coordinate y
   * @param larghezza larghezza della finestra video
   * @param altezza  altezza della finestra video
   * @param email l'utente che ha avviato la sessione id
   */
  invia_punto(x,y,larghezza,altezza,email){
    return this.http.post(`${this.env.apiUrl}/invia_punto?x=${x}&y=${y}&larghezza=${larghezza}&altezza=${altezza}&email=${email}`, null);
  }

  //implementare funzione per settare i valori booleani a chi ha la sessione AR, come parametro un array di eventStreams

  /**
   * Invio al BE, i dati nel momento in cui l'utente clicca sullo schermo per creare una immmagine 3D
   * @param x coordinate x
   * @param y coordinate y
   * @param larghezza larghezza della finestra video
   * @param altezza  altezza della finestra video
   * @param email l'utente che ha avviato la sessione id
   * @param colore in HEX, il colore dell'oggetto 3D che andiamo a collogare sullo schermo deve essere privo di #
  * @param forma forma dell'oggetto 3D che apparirà sullo schermo
  */
 aggiungi_forma(x,y,larghezza,altezza,email,colore,forma){
   return this.http.post(`${this.env.apiUrl}/aggiungi_forma?x=${x}&y=${y}&larghezza=${larghezza}&altezza=${altezza}&email=${email}&colore=${colore}&forma=${forma}`, null);
 }

  /**
   * Invio al BE, i dati nel momento in cui l'utente clicca sullo schermo per eliminare un'immmagine 3D,
   * l'immagine viene eliminata senza contare il colore
   * @param x coordinate x
   * @param y coordinate y
   * @param larghezza larghezza della finestra video
   * @param altezza  altezza della finestra video
   * @param email l'utente che ha avviato la sessione id
   */
  elimina_forma(x,y,larghezza,altezza,email){
    return this.http.post(`${this.env.apiUrl}/elimina_forma?x=${x}&y=${y}&larghezza=${larghezza}&altezza=${altezza}&email=${email}`, null);
  }


  /**
   * Cancella tutte le immagini 3d create prima
   * @param email
   */
  cancella_tutto(email){
    return this.http.post(`${this.env.apiUrl}/cancella_tutto?x=${0}&y=${0}&larghezza=${0}&altezza=${0}&email=${email}`, null);
  }

  sendZoom(email,zoom){
    return this.http.post<any>(
      `${
        this.env.apiUrl
      }/zoom?&email=${email}&zoomLevel=${zoom}`,
      null
    );
  }

}
