<div
  *ngIf="!spegniPerloginSilente"
  [ngClass]="{
    alfaintes: afi || alfaCallCenter,
    alfaintesCallCenter: alfaCallCenter
  }"
>
  <ng-container *ngIf="login && isDesktopDevice && !lavagna">
    <!-- POPUP ALLARMI -->
    <p-dialog
      [(visible)]="displayAlarm"
      *ngIf="!paginaAllarmi"
      [draggable]="false"
      [resizable]="false"
    >
      <ng-template pTemplate="header">
        <div style="color: red !important">
          <i
            class="material-icons no-translate"
            style="font-size: xx-large !important"
            >report_problem</i
          >
          <p style="font-size: medium !important">ALLARME</p>
        </div>
      </ng-template>
      <table class="table table-responsive scroll">
        <tr *ngFor="let allarme of listaAllarmi" style="font-size: small">
          <span style="font-weight: bold">{{
            allarme.time | date : "dd/MM/yyyy HH:mm"
          }}</span>
          -
          {{
            allarme.messaggio
          }}
        </tr>
      </table>
      <p-footer>
        <p-button
          icon="pi pi-check"
          (click)="resetAllarmi()"
          label="Ok"
          styleClass="p-button-text"
        ></p-button>
      </p-footer>
    </p-dialog>
    <!-- POPUP ALLARMI -->
    <div
      class="wrapper notranslate"
      [ngClass]="{ 'sidebar-mini': menuLat }"
      style="background: white"
    >
      <div class="sidebar">
        <div class="logo" style="text-align: center">
          <img
            *ngIf="!afi && !novamaint && !alfaCallCenter"
            style="max-width: 80%; min-height: auto"
            src="../assets/img/GAP_logo_sfumatura.png"
          />
          <img
            *ngIf="afi && !novamaint && !alfaCallCenter"
            style="width: 200px; height: auto"
            src="../assets/img/AFI_logo.png"
          />
          <img
            *ngIf="!afi && novamaint && !alfaCallCenter"
            style="max-width: 80%; min-height: auto"
            src="../assets/img/logonovHome.png"
          />
          <img
            *ngIf="alfaCallCenter"
            style="max-width: 80%; min-height: auto"
            src="../assets/img/logo.png"
          />
        </div>
        <div class="logo logo-mini">
          <img
            *ngIf="!afi && !alfaCallCenter"
            style="max-width: 50px; min-height: auto"
            src="../assets/img/GAP_logo_sfumatura_piccolo.png"
          />
          <img
            *ngIf="afi && !alfaCallCenter"
            style="max-width: 100%; min-height: auto"
            src="../assets/img/AFI_logo_piccolo.png"
          />
          <img
            *ngIf="!afi && alfaCallCenter"
            style="max-width: 100%; min-height: auto"
            src="../assets/img/logo.png"
          />
        </div>
        <div class="sidebar-wrapper">
          <ul class="nav" style="background-color: white">
            <li class="collapse menuProfilieLogout" id="navigationbar">
              <ul class="no-right nav navbar-nav navbar-right">
                <li
                  class="hidden-lg hidden-md"
                  style="
                    margin-left: 25px !important;
                    margin-top: 20px !important;
                  "
                >
                  <div
                    id="fix768"
                    class="dropdown fix768"
                    (click)="showButton768 = !showButton768"
                  >
                    <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Dropdown button
                                </button> -->
                    <a
                      class="dropdown-toggle flex"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style="color: black"
                    >
                      <i class="material-icons notranslate">person</i>
                      <div>{{ nomeUtente }}</div></a
                    >
                    <div
                      class="dropdown-menu buttonFix"
                      aria-labelledby="dropdownMenuButton"
                      [ngClass]="{ displayNone: showButton768 }"
                      style="margin-left: 45px !important"
                    >
                      <div>
                        <a
                          class="dropdown-item"
                          routerLink="profilo"
                          style="color: black"
                          (click)="closeMenuSidebar('/profilo')"
                        >
                          <p>Profilo</p>
                        </a>
                      </div>
                      <div>
                        <a
                          class="dropdown-item"
                          routerLink="cambiaPassword"
                          style="color: black"
                          (click)="closeMenuSidebar('/cambiaPassword')"
                        >
                          <p>Cambia Password</p>
                        </a>
                      </div>
                      <div>
                        <a
                          class="dropdown-item"
                          routerLink="logout"
                          id="#logout"
                          (click)="closeMenuSidebar('/logout')"
                          style="color: black"
                        >
                          <p>Logout</p>
                        </a>
                      </div>
                      <div>
                        <a
                          class="dropdown-item"
                          id="#version"
                          style="color: black"
                        >
                          <p>{{versione}}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="hidden-sm hidden-xs p-10-25">
                  <div
                    id="fix768"
                    class="dropdown fix768"
                    (click)="showButton768 = !showButton768"
                  >
                    <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Dropdown button
                                </button> -->
                    <a
                      class="dropdown-toggle flex"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style="color: black"
                    >
                      <i class="material-icons notranslate">person</i>
                      <div>{{ nomeUtente }}</div></a
                    >
                    <div
                      class="dropdown-menu buttonFix"
                      aria-labelledby="dropdownMenuButton"
                      [ngClass]="{ displayNone: showButton768 }"
                      style="margin-left: 45px !important"
                    >
                      <div>
                        <a
                          class="dropdown-item"
                          routerLink="profilo"
                          style="color: black"
                          (click)="closeMenuSidebar('/profilo')"
                        >
                          <p>Profilo</p>
                        </a>
                      </div>
                      <div>
                        <a
                          class="dropdown-item"
                          routerLink="cambiaPassword"
                          style="color: black"
                          (click)="closeMenuSidebar('/cambiaPassword')"
                        >
                          <p>Cambia Password</p>
                        </a>
                      </div>
                      <div>
                        <a
                          class="dropdown-item"
                          routerLink="logout"
                          id="#logout"
                          (click)="closeMenuSidebar('/logout')"
                          style="color: black"
                        >
                          <p>Logout</p>
                        </a>
                      </div>
                      <div>
                        <a
                          class="dropdown-item"
                          id="#version"
                          style="color: black"
                        >
                          <p>{{versione}}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>

                <!-- <li class="dropdown hidden-sm hidden-xs">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                                    <i class="material-icons notranslate">notifications</i>
                                    <span class="notification" *ngIf="numeroNotifiche > 0">{{numeroNotifiche}}</span>
                                    <p class="hidden-lg hidden-md" style="color: black;">
                                        Notifiche
                                        <b class="caret"></b>
                                    </p>
                                </a>
                                <ul class="dropdown-menu scroll" style="overflow: auto; max-height: 300px;">

                                    <ng-container *ngFor="let notifica of listaNotifiche">
                                        <li>
                                            <a (click)="gestioneNotifiche(notifica)">{{notifica.notifica}}</a>
                                        </li>
                                    </ng-container>

                                    <li *ngIf="numeroNotifiche === 0">
                                        <a>Non ci sono nuove notifiche</a>
                                    </li>
                                    <div *ngIf="numeroNotifiche > 0"
                                        style="text-align: right; justify-content: right; font-size: smaller; margin-right: 10px;">
                                        <a (click)="rimuoviNotifiche()">Rimuovi notifiche</a></div>
                                </ul>
                            </li> -->
              </ul>
            </li>
            <li
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a routerLink="" class="chiudi" (click)="closeMenuSidebar('/')">
                <p><i class="material-icons notranslate">dashboard</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Dashboard"
                  data-placement="right"
                  >dashboard</i
                >
                <p>Dashboard</p>
              </a>
            </li>
            <li
              routerLinkActive="active"
              *ngIf="ruolo === 1"
              [routerLinkActiveOptions]="{ exact: true }"
              class="pannello-button"
              (click)="mostraPannello()"
            >
              <!-- <a class="collapsed" aria-expanded="false" *ngIf="menuLat" (click)="menuLat=false">
                            <i class="material-icons notranslate">build</i>
                            <p>Pannello di Controllo
                                <b class="caret"></b>
                            </p>
                        </a> -->
              <a
                data-toggle="collapse"
                href="#layouts"
                class="collapsed"
                aria-expanded="false"
                id="apriPannello"
                [ngClass]="{ collapsed: menuLat }"
              >
                <i class="material-icons notranslate">build</i>
                <p>
                  Pannello di Controllo
                  <b class="caret"></b>
                </p>
              </a>
              <div
                class="collapse"
                id="layouts"
                aria-expanded="false"
                style="height: 0px"
              >
                <ul
                  class="nav"
                  *ngIf="!menuLat"
                  [ngClass]="{ ' sidebar-mini collapse margine': menuLat }"
                >
                  <li
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <a
                      routerLink="pannellocontrollo/creautente"
                      class="chiudi"
                      (click)="
                        closeMenuSidebar('/pannellocontrollo/creautente')
                      "
                      [ngClass]="{ menuLatText: menuLat }"
                      >Crea Utente</a
                    >
                  </li>
                  <li
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <a
                      [routerLink]="'pannellocontrollo/gestioneutenti'"
                      class="chiudi"
                      (click)="
                        closeMenuSidebar('/pannellocontrollo/gestioneutenti')
                      "
                      [ngClass]="{ menuLatText: menuLat }"
                      >Gestione Utenti</a
                    >
                  </li>
                  <li
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <a
                      routerLink="pannellocontrollo/gestioneprofili"
                      class="chiudi"
                      (click)="
                        closeMenuSidebar('/pannellocontrollo/gestioneprofili')
                      "
                      [ngClass]="{ menuLatText: menuLat }"
                      >Gestione Profili</a
                    >
                  </li>
                  <li
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <a
                      routerLink="pannellocontrollo/gestionegruppi"
                      class="chiudi"
                      (click)="
                        closeMenuSidebar('/pannellocontrollo/gestionegruppi')
                      "
                      [ngClass]="{ menuLatText: menuLat }"
                      >Gestione Gruppi</a
                    >
                  </li>
                  <li
                    *ngIf="prenotazioneTecnico"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <a
                      routerLink="pannellocontrollo/gestionetecnici"
                      class="chiudi"
                      (click)="
                        closeMenuSidebar('/pannellocontrollo/gestionetecnici')
                      "
                      [ngClass]="{ menuLatText: menuLat }"
                      >Gestione Tecnici</a
                    >
                  </li>
                  <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                              <a routerLink="pannellocontrollo/report" class="chiudi" >Report</a>
                          </li> -->
                  <li
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <a
                      routerLink="pannellocontrollo/configurazione"
                      class="chiudi"
                      (click)="
                        closeMenuSidebar('/pannellocontrollo/configurazione')
                      "
                      [ngClass]="{ menuLatText: menuLat }"
                      >Configurazione</a
                    >
                  </li>
                </ul>
              </div>
            </li>

            <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a routerLink="monitoraggioAmbientale" routerLinkActive="router-link-active" class="chiudi" (click)="closeMenuSidebar('/monitoraggioAmbientale')">
                            <p> <i class="material-icons notranslate">energy_savings_leaf</i></p>
                            <i class="material-icons notranslate tool" rel="tooltip" title="Monitoraggio ambientale"
                                data-placement="right">energy_savings_leaf</i>
                            <p>Monitoraggio ambientale</p>

                        </a>
                    </li> -->
            <li
              routerLinkActive="active"
              *ngIf="(ruolo === 1 || ruolo === 2) && clienti"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="assistiti"
                class="chiudi"
                (click)="closeMenuSidebar('/assistiti')"
              >
                <p><i class="material-icons notranslate">manage_accounts</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Gestione Assistiti"
                  data-placement="right"
                  >manage__accounts</i
                >
                <p>Gestione Assistiti</p>
              </a>
            </li>
            <li
              routerLinkActive="active"
              *ngIf="alfaCallCenter"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="farmacie"
                class="chiudi"
                (click)="closeMenuSidebar('/farmacie')"
              >
                <p><i class="material-icons notranslate">medication</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Farmacie"
                  data-placement="right"
                  >medication</i
                >
                <p>Farmacie</p>
              </a>
            </li>
            <li
              routerLinkActive="active"
              *ngIf="alfaCallCenter"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="campagne"
                class="chiudi"
                (click)="closeMenuSidebar('/campagne')"
              >
                <p><i class="material-icons notranslate">campaign</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="campagne"
                  data-placement="right"
                  >campaign</i
                >
                <p>Campagne</p>
              </a>
            </li>
            <li
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="pannellocontrollo/report"
                class="chiudi"
                (click)="closeMenuSidebar('/pannellocontrollo/report')"
              >
                <p><i class="material-icons notranslate">equalizer</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Reportistica"
                  data-placement="right"
                  >equalizer</i
                >
                <p>Reportistica</p>
              </a>
            </li>
            <li
              *ngIf="(ruolo === 1 || ruolo === 2) && cr"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="controlroom"
                class="chiudi"
                (click)="closeMenuSidebar('/controlroom')"
              >
                <p><i class="material-icons notranslate">headset_mic</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Avvia Assistenza"
                  data-placement="right"
                  >headset_mic</i
                >
                <p>Avvia assistenza</p>
              </a>
            </li>

            <li
              *ngIf="(ruolo === 1 || ruolo === 2) && geo"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="geolocalizzazione"
                class="chiudi"
                (click)="closeMenuSidebar('/geolocalizzazione')"
              >
                <p><i class="material-icons notranslate">place</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Geolocalizzazione"
                  data-placement="right"
                  >place</i
                >
                <p>Geolocalizzazione</p>
              </a>
            </li>
            <li
              *ngIf="(ruolo === 3 || ruolo === 4 || ruolo === 5) && cr"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="videochiamata"
                class="chiudi"
                (click)="closeMenuSidebar('/videochiamata')"
              >
                <p><i class="material-icons notranslate">ondemand_video</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Videochiamata"
                  data-placement="right"
                  >ondemand_video</i
                >
                <p>Videochiamata</p>
              </a>
            </li>
            <li
              *ngIf="classroom"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="education"
                class="chiudi"
                (click)="closeMenuSidebar('/education')"
              >
                <p><i class="material-icons notranslate">school</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Education"
                  data-placement="right"
                  >school</i
                >
                <p>Education</p>
              </a>
            </li>

            <li
              *ngIf="ruolo === 1 && newsletter"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="newsLetter"
                class="chiudi"
                (click)="closeMenuSidebar('/newsLetter')"
              >
                <p><i class="material-icons notranslate">email</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="News Letter"
                  data-placement="right"
                  >email</i
                >
                <p>Newsletter</p>
              </a>
            </li>

            <li
              *ngIf="dms"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="dms"
                class="chiudi"
                (click)="closeMenuSidebar('/dms')"
              >
                <p><i class="material-icons notranslate">menu_book</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Documentazione"
                  data-placement="right"
                  >menu_book</i
                >
                <p>Documentazione</p>
              </a>
            </li>

            <li
              *ngIf="iot"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="remoteControl"
                class="chiudi"
                (click)="closeMenuSidebar('/remoteControl')"
              >
                <p><i class="material-icons notranslate">devices_other</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Dispositivi IoT"
                  data-placement="right"
                  >devices_other</i
                >
                <p>Dispositivi IoT</p>
              </a>
            </li>
            <li
              *ngIf="prenotazioneTecnico"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="eventi"
                class="chiudi"
                (click)="closeMenuSidebar('/eventi')"
              >
                <p><i class="material-icons notranslate">date_range</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Eventi"
                  data-placement="right"
                  >date_range</i
                >
                <p>Eventi</p>
              </a>
            </li>
            <li
              *ngIf="viewer360 && (ruolo === 1 || ruolo === 2 || ruolo === 3)"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                routerLink="planimetrie"
                routerLinkActive="router-link-active"
                class="chiudi"
                (click)="closeMenuSidebar('/planimetrie')"
              >
                <p><i class="material-icons notranslate">maps_home_work</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Planimetrie"
                  data-placement="right"
                  >maps_home_work</i
                >
                <p>Planimetrie</p>
              </a>
            </li>
            <li>
              <a *ngIf="liveChat" (click)="openLiveChat()" class="chiudi">
                <p><i class="material-icons notranslate">account_circle</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Live Chat"
                  data-placement="right"
                  >account_circle</i
                >
                <p>Live Chat</p>
              </a>
            </li>

            <li *ngIf="chatbot">
              <a (click)="openChatBot()" class="chiudi">
                <p><i class="material-icons notranslate">android</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="ChatBot"
                  data-placement="right"
                  >android</i
                >
                <p>Chatbot</p>
              </a>
            </li>

            <li
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              *ngIf="!alfaCallCenter"
            >
              <a
                routerLink="guida"
                routerLinkActive="router-link-active"
                class="chiudi"
                (click)="closeMenuSidebar('/guida')"
              >
                <p><i class="material-icons notranslate">support</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Guida"
                  data-placement="right"
                  >support</i
                >
                <p>Guida</p>
              </a>
            </li>
            <!-- routerLink="" routerLinkActive="router-link-active" -->
            <li
              *ngIf="afi && !alfaCallCenter"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a [href]="cmsAfi" class="chiudi">
                <p><i class="material-icons notranslate">home</i></p>
                <i
                  class="material-icons notranslate tool"
                  rel="tooltip"
                  title="Sito Istituzionale"
                  data-placement="right"
                  >home</i
                >
                <p>Sito Istituzionale</p>
              </a>
            </li>
          </ul>
        </div>
        <div
          *ngIf="nabacom && !retrievedImage"
          class="logo2"
          style="text-align: center; justify-content: center"
        >
          <img style="max-width: 9em" src="../assets/img/nabacom.png" />
        </div>
        <div
          *ngIf="ipem && !retrievedImage"
          class="logo2"
          style="text-align: center; justify-content: center"
        >
          <img style="max-width: 9em" src="../assets/img/ipem.png" />
        </div>
        <div
          *ngIf="ifep && !retrievedImage"
          class="logo2"
          style="text-align: center; justify-content: center"
        >
          <img style="max-width: 9em" src="../assets/img/ifep.png" />
        </div>
        <div
          *ngIf="acque && !retrievedImage"
          class="logo2"
          style="text-align: center; justify-content: center"
        >
          <img style="max-width: 9em" src="../assets/img/pacque.png" />
        </div>
        <div
          *ngIf="(afi || alfaCallCenter) && !retrievedImage"
          class="logo2"
          style="text-align: center; justify-content: center"
        >
          <img
            style="max-width: 16em; margin-right: 30px"
            src="../assets/img/dontmindtheafi.png"
          />
        </div>

        <div
          *ngIf="retrievedImage"
          class="logo2"
          style="text-align: center; justify-content: center"
        >
          <img style="max-width: 9em" [src]="retrievedImage" />
        </div>
        <div
          *ngIf="
            !ifep &&
            !acque &&
            !nabacom &&
            !ipem &&
            !afi &&
            !retrievedImage &&
            !alfaCallCenter
          "
          class="logo2"
          style="text-align: center; justify-content: center"
        >
          <img style="max-width: 15em" src="../assets/img/dontmindthegap.png" />
        </div>
      </div>
      <div class="main-panel" [ngClass]="{ zin9999: isMobile }">
        <nav
          class="navbar navbar-default navbar-absolute"
          data-topbar-color="blue"
          [ngClass]="{
            navbarRed: colore === 'rgb(255, 0, 0)',
            navbarGreen: colore === 'rgb(53, 167, 49)',
            navbarYellow: colore === 'rgb(255, 230, 0)',
            navbarOrange: colore === 'rgb(255, 153, 0)',
            navbarPurple: colore === 'rgb(172,47,163)'
          }"
        >
          <div class="container-fluid">
            <div class="navbar-minimize">
              <button
                id="minimizeSidebar"
                (click)="tastoLeft()"
                class="btn btn-round btn-white btn-fill btn-just-icon"
              >
                <i
                  class="material-icons notranslate visible-on-sidebar-regular f-26"
                  >keyboard_arrow_left</i
                >
                <i
                  class="material-icons notranslate visible-on-sidebar-mini f-26"
                  >keyboard_arrow_right</i
                >
              </button>
            </div>
            <div class="navbar-header">
              <div
                class="close-layer-no-bootstrap"
                [ngStyle]="{ 'height.px': getHeight() }"
                [ngClass]="{ hideModal: !varMenu }"
                (click)="closeMenu()"
              ></div>
              <button
                id="button-navbar-toggle"
                type="button"
                class="navbar-toggle"
                (click)="menuPiccolo()"
                *ngIf="showNavbarMobile"
                [ngClass]="{ toggled: varMenu }"
              >
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>

              <div
                class="dropdown hidden-lg hidden-md pull-right"
                *ngIf="liveChat || cr"
                style="margin-top: 13px; margin-right: 2px"
              >
                <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                  <i class="material-icons notranslate" style="color: white"
                    >notifications</i
                  >
                  <span
                    style="margin-top: -10px; right: -6px"
                    class="notification"
                    *ngIf="numeroNotifiche > 0"
                    >{{ numeroNotifiche }}</span
                  >
                </a>
                <ul
                  class="dropdown-menu scroll"
                  style="overflow: auto; max-height: 300px"
                >
                  <ng-container *ngFor="let notifica of listaNotifiche">
                    <li>
                      <a (click)="gestioneNotifiche(notifica)">{{
                        notifica.notifica
                      }}</a>
                    </li>
                  </ng-container>

                  <li *ngIf="numeroNotifiche === 0">
                    <a>Non ci sono nuove notifiche</a>
                  </li>
                  <div
                    *ngIf="numeroNotifiche > 0"
                    style="
                      text-align: right;
                      justify-content: right;
                      font-size: smaller;
                      margin-right: 10px;
                    "
                  >
                    <a (click)="rimuoviNotifiche()">Rimuovi notifiche</a>
                  </div>
                </ul>
              </div>
              <a class="navbar-brand" href="#"
                >{{ ambiente.nomeLogoAlto
                }}<span>
                  <img
                    *ngIf="!afi && !alfaCallCenter"
                    style="margin-top: -10px"
                    src="../assets/img/protomPoweredBy.png" />
                  <img
                    *ngIf="afi && !alfaCallCenter"
                    style="margin-top: -6px"
                    src="../assets/img/poweredByAfi.png" /></span
              ></a>
            </div>

            <div class="collapse menuProfilieLogoutDesktop" id="navigationbar">
              <ul class="nav navbar-nav navbar-right">
                <li
                  class="hidden-lg hidden-md"
                  style="
                    margin-left: 25px !important;
                    margin-top: 20px !important;
                  "
                >
                  <div class="dropdown">
                    <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Dropdown button
                                </button> -->
                    <a
                      class="dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style="color: black"
                    >
                      <i class="material-icons notranslate">person</i
                      >{{ nomeUtente }}</a
                    >
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                      style="margin-left: 45px !important"
                    >
                      <div>
                        <a
                          class="dropdown-item"
                          routerLink="profilo"
                          style="color: black"
                        >
                          <p>Profilo</p>
                        </a>
                      </div>
                      <div>
                        <a
                          class="dropdown-item"
                          routerLink="cambiaPassword"
                          style="color: black"
                        >
                          <p>Cambia Password</p>
                        </a>
                      </div>
                      <div>
                        <a
                          class="dropdown-item"
                          routerLink="logout"
                          id="#logout"
                          style="color: black"
                        >
                          <p>Logout</p>
                        </a>
                      </div>
                      <div>
                        <a
                          class="dropdown-item"
                          id="#version"
                          style="color: black"
                        >
                          <p>{{versione}}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="hidden-sm hidden-xs">
                  <a class="dropdown-toggle" data-toggle="dropdown">
                    <p class="hidden-sm hidden-xs">
                      {{ nomeUtente }}
                      <i class="material-icons notranslate">person</i>
                    </p>
                    <p class="hidden-lg hidden-md" style="color: black">
                      <i class="material-icons notranslate">person</i
                      >{{ nomeUtente }}
                    </p>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a routerLink="profilo">Profilo Personale</a>
                    </li>
                    <li>
                      <a routerLink="cambiaPassword">Cambia Password</a>
                    </li>
                    <li>
                      <a routerLink="logout" id="#logout">Log out</a>
                    </li>
                    <li>
                      <a id="versione">{{versione}}</a>
                    </li>
                  </ul>
                </li>

                <li *ngIf="liveChat || cr" class="dropdown hidden-sm hidden-xs">
                  <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                    <i class="material-icons notranslate">notifications</i>
                    <span class="notification" *ngIf="numeroNotifiche > 0">{{
                      numeroNotifiche
                    }}</span>
                    <p class="hidden-lg hidden-md" style="color: black">
                      Notifiche
                      <b class="caret"></b>
                    </p>
                  </a>
                  <ul
                    class="dropdown-menu scroll"
                    style="overflow: auto; max-height: 300px"
                  >
                    <ng-container *ngFor="let notifica of listaNotifiche">
                      <li>
                        <a (click)="gestioneNotifiche(notifica)">{{
                          notifica.notifica
                        }}</a>
                      </li>
                    </ng-container>

                    <li *ngIf="numeroNotifiche === 0">
                      <a>Non ci sono nuove notifiche</a>
                    </li>
                    <div
                      *ngIf="numeroNotifiche > 0"
                      style="
                        text-align: right;
                        justify-content: right;
                        font-size: smaller;
                        margin-right: 10px;
                      "
                    >
                      <a (click)="rimuoviNotifiche()">Rimuovi notifiche</a>
                    </div>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <p-confirmDialog
          [style]="{ width: responsiveWidth() }"
          [acceptLabel]="'Si'"
          [baseZIndex]="5"
        ></p-confirmDialog>
        <alert></alert>
        <!-- <div class="popup-pannello" *ngIf="showMenu && menuLat" (mouseleave)="showMenu=false" >
                <div class="contenitore-pannello">
                    <div class="freccia-contenitore">
                    <div class="freccia">
                    </div>
                    </div>
                    ciao
                    <hr>
                    ciao
                    <hr>
                    ciao
                    </div>
            </div> -->
        <!-- Modifica al template per rendere il menù scroll -->
        <!-- <div style="min-height:100vh; display: block; position: relative;  overflow-x: hidden;"> -->
        <div style="display: block; position: relative">
          <div *ngIf="vediPagina">
            <router-outlet></router-outlet>
          </div>
          <app-chatbot
            style="
              box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
                0 10px 10px rgba(0, 0, 0, 0.22);
            "
            *ngIf="chatbotOpen"
          ></app-chatbot>
          <app-chatlive
            *ngIf="chatliveOpen"
            [VarMessaggio]="apriChatMsg"
          ></app-chatlive>
          <footer
            class="footer"
            style="bottom: 0; position: absolute; width: 100%"
          >
            <div class="container-fluid">
              <nav class="pull-left">
                <ul></ul>
                <ul></ul>
              </nav>
              <div class="destra"></div>
            </div>
          </footer>
        </div>
      </div>
      <div class="fixed-plugin">
        <div class="dropdown show-dropdown">
          <ul class="dropdown-menu">
            <li class="header-title">Topbar Filters</li>

            <li class="header-title">Sidebar Background</li>
            <li class="adjustments-line">
              <a
                href="javascript:void(0)"
                class="switch-trigger background-color"
              >
                <div class="text-center">
                  <span
                    class="badge filter badge-gray"
                    data-color="gray"
                  ></span>
                  <span
                    class="badge filter badge-white"
                    data-color="default"
                  ></span>
                </div>
                <div class="clearfix"></div>
              </a>
            </li>
            <li class="adjustments-line">
              <div class="switch-trigger">
                <p>Sidebar Mini</p>
                <div class="togglebutton switch-sidebar-mini">
                  <label>
                    <input type="checkbox" unchecked />
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!login && !lavagna">
    <alert></alert>
    <router-outlet></router-outlet>
  </ng-container>

  <!-- MOBILEVIEW -->
  <ng-container *ngIf="login && !isDesktopDevice && !lavagna">
    <div class="col-md-6 offset-md-3 mt-5" *ngIf="isDesktopDevice">
      <div class="wrapper wrapper-full-page">
        <div
          class="full-page login-page"
          data-color="blue"
          style="background-color: lightgray"
        >
          <div
            class="content"
            style="text-align: center; background-color: lightgray"
          >
            <img
              style="width: 150px; min-height: auto; margin-bottom: 3%"
              src="../../../assets/img/GAP_logo_sfumatura.png"
            />

            <router-outlet></router-outlet>
          </div>
          <footer class="footer" style="background-color: silver">
            <div class="container">
              <nav class="pull-left">
                <ul>
                  <li></li>
                </ul>
              </nav>

              <div class="destra">
                <div *ngIf="retrievedImage" style="display: contents">
                  <img
                    style="max-width: 9em; margin-right: 30px"
                    [src]="retrievedImage"
                  />
                </div>
                <div
                  *ngIf="nabacom && !retrievedImage"
                  style="display: contents"
                >
                  <img
                    style="max-width: 9em; margin-right: 30px"
                    src="../assets/img/nabacom.png"
                  />
                </div>
                <div *ngIf="ipem && !retrievedImage" style="display: contents">
                  <img
                    style="max-width: 9em; margin-right: 30px"
                    src="../assets/img/ipem.png"
                  />
                </div>
                <div *ngIf="acque && !retrievedImage" style="display: contents">
                  <img
                    style="max-width: 9em; margin-right: 30px"
                    src="../assets/img/pacque.png"
                  />
                </div>
                <span *ngIf="ifep && !retrievedImage" style="display: contents">
                  <img style="max-width: 9em" src="../assets/img/ifep.png" />
                </span>
                <img
                  style="max-width: 12em; min-height: auto"
                  src="../../../assets/img/protomPoweredBy.png"
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>

    <ng-container>
      <div class="col-md-6 offset-md-3 mt-5">
        <div class="wrapper wrapper-full-page">
          <div
            class="full-page login-page"
            data-color="blue"
            style="background-color: lightgray"
          >
            <div
              class="content"
              style="text-align: center; background-color: lightgray"
            >
              <img
                style="width: 150px; min-height: auto; margin-bottom: 3%"
                src="../../../assets/img/GAP_logo_sfumatura.png"
              />

              <div class="container box">
                <div class="row">
                  <div
                    class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3"
                  >
                    <div class="card card-login">
                      <div class="card-header text-center">
                        <h4 class="card-title" style="margin-bottom: 2%">
                          Benvenuto su GAP!
                        </h4>
                        <br />
                        <p>
                          Per sfruttare al massimo le potenzialità da mobile, ti
                          invitiamo a scaricare l'app.
                        </p>
                      </div>

                      <div class="buttons">
                        <div style="text-align: center">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.protom.gap"
                            ><button
                              style="
                                padding: 9px 13px 9px 13px;
                                margin-right: 5px;
                              "
                              class="btn btn-primary btn-round"
                            >
                              <i
                                style="font-size: x-large"
                                class="zmdi zmdi-google-play"
                              ></i></button
                          ></a>
                          <a
                            href="https://apps.apple.com/it/app/gap/id1537371216"
                            ><button
                              style="
                                padding: 9px 13px 9px 13px;
                                margin-left: 5px;
                              "
                              class="btn btn-primary btn-round"
                            >
                              <i
                                style="font-size: x-large"
                                class="zmdi zmdi-apple"
                              ></i></button
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer class="footer" style="background-color: silver">
              <div class="container">
                <nav class="pull-left">
                  <ul>
                    <li></li>
                  </ul>
                </nav>

                <div
                  style="
                    text-align: center;
                    justify-content: center;
                    float: center;
                  "
                >
                  <div *ngIf="retrievedImage" style="display: contents">
                    <img
                      style="max-width: 9em; margin-right: 30px"
                      [src]="retrievedImage"
                    />
                  </div>

                  <div
                    *ngIf="nabacom && !retrievedImage"
                    style="display: contents"
                  >
                    <img
                      style="max-width: 9em; margin-right: 30px"
                      src="../assets/img/nabacom.png"
                    />
                  </div>
                  <div
                    *ngIf="ipem && !retrievedImage"
                    style="display: contents"
                  >
                    <img
                      style="max-width: 9em; margin-right: 30px"
                      src="../assets/img/ipem.png"
                    />
                  </div>
                  <div
                    *ngIf="acque && !retrievedImage"
                    style="display: contents"
                  >
                    <img
                      style="max-width: 9em; margin-right: 30px"
                      src="../assets/img/pacque.png"
                    />
                  </div>
                  <span
                    *ngIf="ifep && !retrievedImage"
                    style="display: contents"
                  >
                    <img style="max-width: 9em" src="../assets/img/ifep.png" />
                  </span>

                  <img
                    style="max-width: 12em; min-height: auto"
                    src="../../../assets/img/protomPoweredBy.png"
                  />
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="lavagna">
    <router-outlet></router-outlet>
  </ng-container>

  <!-- <button style="display:none" #modale class="btn btn-success" data-backdrop="false" data-toggle="modal" data-target="#exampleModal"></button> -->

  <p-dialog
    [draggable]="false"
    [resizable]="false"
    [(visible)]="selectedFeedback"
    [closeOnEscape]="true"
    modal="true"
    [dismissableMask]="true"
    (onHide)="rateCall(0)"
  >
    <h5 class="centered">Come valuti la tua esperienza sul sito?</h5>

    <div class="centered">
      <i
        class="material-icons unsatisfied"
        (click)="rateCall(1)"
        data-dismiss="modal"
      >
        sentiment_dissatisfied
      </i>

      <i
        class="material-icons neutral"
        (click)="rateCall(2)"
        data-dismiss="modal"
      >
        sentiment_neutral
      </i>
      <i
        class="material-icons satisfied"
        (click)="rateCall(3)"
        data-dismiss="modal"
      >
        sentiment_satisfied_alt
      </i>
    </div>
  </p-dialog>
  <!-- <div class="modal" data-backdrop="false"  id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" [ngClass]="{hideModal: !selectedFeedback}" #divModale>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title centered" id="exampleModalLabel">Come valuti la tua esperienza sul sito?</h5>

            </div>

            <div class="modal-footer">
            </div>
        </div>
    </div>
</div> -->

  <!-- <button type="button" style="display: none;" #modale class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
</button>

<div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog" role="document" >
    <div class="modal-content">
      <div class="modal-header">
          <button #closeModal style="display: none;" type="button" class="close" data-dismiss="modal"
                          aria-label="Close">
                          <span aria-hidden="true">&times;</span>
          </button>
        <h5 class="modal-title centered" id="exampleModalLabel">Come valuti la tua esperienza sul sito?</h5>
      </div>
      <div class="modal-body centered">
              <i class="material-icons unsatisfied" (click)="rateCall(1)" data-dismiss="modal">
              sentiment_dissatisfied
              </i>
              <i class="material-icons neutral" (click)="rateCall(2)" data-dismiss="modal">
              sentiment_neutral
              </i>
              <i class="material-icons satisfied" (click)="rateCall(3)" data-dismiss="modal">
              sentiment_satisfied
              </i>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div> -->
</div>
