<div class="container-fluid">
<div class="content">
    <!-- Lista Corsi + ADD -->
<div *ngIf="step1">
    <div  class="bugFixAlfa117">
    <h4> {{ambiente.CORSI}} Associati </h4>
    <a *ngIf="ruolo === 1 || ruolo === 4" class="chiudi  right mt-5" (click)="aggiungiCorso()" >
        Associa  {{ambiente.CORSO}}
    </a>
</div>
<!-- <div *ngIf="listaCorsi.length > 0">display-inline absolutedisplay-inline
    <i class="material-icons notranslate" (click)="vaiDettaglio('nomeCorso')">arrow_forward</i> -->

    <div class="card-content mb-2-per overflow-x-auto">
        <h4 class="card-title mt-2-per mb-3-per">Seleziona un {{ambiente.CORSO}}</h4>

        <p *ngIf="listaCorsi.length < 1">Nessun {{ambiente.CORSO}} Associato</p>
          <table *ngIf="listaCorsi.length > 0" id="datatables" class="table table-striped table-no-bordered table-hover w-100" cellspacing="0" width="100%">
                <thead>
                    <tr>
                        <th width="30%">{{ambiente.CORSO}}</th>
                        <th width="30%">Categoria</th>
                        <th width="30%">Completamento</th>
                        <th class="disabled-sorting text-right" width="10%">Azioni</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let corsi of listaCorsi;let i =index;">
                        <td width="30%">{{corsi.nomeCorso}}</td>
                        <td width="30%">{{corsi.categoria}}</td>

                        <td width="30%" *ngIf="!corsi.stato" >Da iniziare
                            <!-- <i class="material-icons notranslate pointer" style="font-size: 29px; margin-left: 20%; margin-top: 1%; color:red;
                            background-color: white;
                            border-radius: 50%;
                            border: 3px solid darkgray;
                            padding: 1px;" > play_arrow</i> --></td>

                        <td width="30%"   *ngIf="corsi.stato == 'incomplete'" >
                            Incompleto
                            <!-- <i class="material-icons notranslate pointer" style="font-size: 44px; margin-left: 19%; margin-top: 1%; color:yellow;
                                                   "
                             >pause_circle_outlined</i>--></td>
                            <td width="30%" *ngIf="corsi.stato == 'passed'" > Completo
                                <!-- <i class="material-icons notranslate pointer" style="font-size: 29px; margin-left: 20%; margin-top: 2%; color:#28a745;
                                 background-color: white;
                            border-radius: 50%;
                            border: 3px solid #28a745;
                            padding: 1px;" >task_alt</i> --></td>

                        <td class="t-right" width="10%">
                            <a  (click)="vaiDettaglio(corsi)" rel="tooltip" title="Informazioni" class="btn btn-warning btn-simple pd-2"><i class="material-icons notranslate pointer fs-20">info</i></a>
                            <a  (click)="vaiScorm(corsi)" rel="tooltip" title="Entra nel  {{ambiente.CORSO}}" class="btn btn-primary btn-simple pd-2" ><i class="material-icons notranslate pointer fs-20">forward</i></a>
                            <a *ngIf="ruolo === 1 || ruolo === 4" (click)="cancella(corsi.corsoId)" rel="tooltip" title="Disassocia  {{ambiente.CORSO}}" class="btn btn-danger btn-simple pd-2" ><i class="material-icons notranslate pointer fs-20">close</i></a>
                        </td>

                    </tr>

                </tbody>
            </table>

    </div>
</div>
<p-confirmDialog key="cancella" #cd  icon="pi pi-exclamation-triangle" [style]="{width: responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
    <p-footer>

        <button [disabled]="loading"  *ngIf="cancellaCorso" type="button" pButton icon="pi pi-check" label="Sì" (click)="disassociaCorso()">
            <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
        </button>
        <button  *ngIf="!cancellaCorso" type="button" pButton icon="pi pi-check" label="Sì" (click)="cd.accept()"></button>
        <button [disabled]="loading && cancellaCorso" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>

    </p-footer>
</p-confirmDialog>


<!-- ---------------------------------- -->


<!-- Visualizzazione Dettaglio Corso -->
<div *ngIf="stepDettaglio">


    <div class="card-title" *ngIf="caricatoInfo">
        <button (click)="goBackDettaglio()" id="back-button" class="btn btn-round  btn-just-icon mt-3 color-54 b-shadow">
            <i class="material-icons notranslate" >arrow_back</i>
        </button>
        <h4 class="display-inline">{{nomeCorsoSelect}}</h4>
    </div>
        <div class="card-content mb-2-per">
        <div *ngIf="ruolo === 1 || ruolo === 4">
            <div *ngIf="listaModuli.length < 1">Nessuna informazione disponibile</div>
            <div *ngIf="listaModuli.length > 0" >
                <div ></div>
                    <p-accordion *ngFor="let info of listaModuli;">
                <p-accordionTab header="{{info.utenteS.nome}} {{info.utenteS.cognome}}">


            <div class="content">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th class="w-20"></th>
                            <th class="w-80"></th>
                        </tr>
                    </thead>
                    <tbody class="animation3">
                        <tr>
                            <td class="text-right mw-30-per">
                                <h6 >Tempo</h6>
                            </td>
                            <td  >{{info.tempoTrascorso | secondi}}</td>
                        </tr>
                        <tr>
                            <td class="text-right fw-b">
                                <h6 >Completamento</h6>
                            </td>
                            <td class="col-10 capitalize">{{checkStatus(info.stato)}}</td>
                        </tr>
                        <tr>
                            <td class="text-right fw-b">
                                <h6 >Voto</h6>
                            </td>
                            <td class="col-10">{{info.infoModuliStudenti[0].voto}}</td>
                        </tr>
                        <tr>
                            <td class="text-right fw-b">
                                <h6 >Data inizio:</h6>
                            </td>
                            <td class="col-10">{{info.dataInizio}}</td>
                        </tr>
                        <tr>
                            <td class="text-right fw-b">
                                <h6 >Data completamento</h6>
                            </td>
                            <td class="col-10">
                                {{info.dataFine}}
                                   </td>
                        </tr>


                    </tbody>
                </table>
                </div>
                </p-accordionTab>
            </p-accordion>

            </div>

        </div>

        <div *ngIf="ruolo !== 1 && ruolo !== 4">

<div *ngIf="listaModuli.length < 1">Nessun Informazione disponibile</div>
<div *ngIf="listaModuli.length > 0" >
   <div *ngFor="let infoS of listaModuli;">

            <!-- <i >Corso: {{info.corso.nomeCorso}}</i> -->
            <div class="card-header card-header-text mt-2-per mb-2-per jc-c">
                <h5 class="card-title" >Info  {{ambiente.CORSO}}</h5>
            </div>
            <div class="content">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th class="w-20"></th>
                            <th class="w-80"></th>
                        </tr>
                    </thead>
                    <tbody class="animation3">
                        <tr>
                            <td class="text-right mw-30-per">
                                <h6 >Tempo</h6>
                            </td>
                            <td  >{{infoS.tempoTrascorso  | secondi}}</td>
                        </tr>
                        <tr>
                            <td class="text-right fw-b">
                                <h6 >Completamento</h6>
                            </td>
                            <td class="col-10">{{checkStatus(infoS.stato)}}</td>
                        </tr>
                        <tr>
                            <td class="text-right fw-b">
                                <h6 >Voto</h6>
                            </td>
                            <td class="col-10">{{infoS.infoModuliStudenti[0].voto}}</td>
                        </tr>
                        <tr>
                            <td class="text-right fw-b">
                                <h6 >Data inizio:</h6>
                            </td>
                            <td class="col-10">{{infoS.dataInizio}}</td>
                        </tr>
                        <tr>
                            <td class="text-right fw-b">
                                <h6 >Data completamento</h6>
                            </td>
                            <td class="col-10">
                                {{infoS.dataFine}}
                                   </td>
                        </tr>


                    </tbody>
                </table>
</div></div></div>



    </div>
</div>
</div>
<!-- -------------------------------- -->


<!-- VISUALIZZAZIONE SCORM -->
<div  *ngIf="stepScorm">
<!-- #SCORM
<app-corsi-player [scorms]="oggettoScorm" (chiudiPlayer)="goBackScorm()"></app-corsi-player>
-->
</div>
<!-- ------------------------------ -->


<!-- Associazione nuovo Corso -->
<div *ngIf="stepAddCorso">



    <div class="card-header card-header-text" >
        <h4 class="card-title text-center"  >Aggiungi un  {{ambiente.CORSO}}</h4>
    </div>

    <div class="card-content">
        <p *ngIf="listaCorsiAdd.length < 1">Nessun  {{ambiente.CORSO}} da associare</p>
          <table *ngIf="listaCorsiAdd.length > 0" id="datatables" class="table table-striped table-no-bordered table-hover w-100" cellspacing="0" width="100%">
                <thead>
                    <tr >
                        <th width="10%">#</th>
                        <th width="30%"> {{ambiente.CORSO}}</th>
                        <th width="30%">Categoria</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let corsi of listaCorsiAdd;let i =index;">
                        <td width="30%"><input type="checkbox" (click)="aggiungiCorsoAula(corsi.id)"></td>
                        <td width="30%">{{corsi.nomeCorso}}</td>
                        <td width="30%">{{corsi.categoria}}</td>
                    </tr>

                </tbody>
            </table>
        <br>
        <div class="text-right">
            <hr>
            <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" (click)="clear()" class="btn btn-secondary"
                    aria-pressed="true">Indietro</button>
            </div>
            <div class="btn-group" role="group" aria-label="Basic example" aria-pressed="true">
                <button [disabled]="loading" type="sumbit" class="btn btn-primary" (click)="onSubmit()">Salva
                    <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
                </button>
            </div>
        </div>


    </div>

</div>

<!-- ------------------------- -->


</div>
</div>
