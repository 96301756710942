<div class="card-header">
  <!-- INIZIO WIZARD -->
  <div class="card-title">
    <button
      (click)="goBack()"
      id="back-button"
      type="button"
      class="btn btn-round btn-just-icon"
      style="
        margin-top: 3px;
        color: rgb(54, 54, 54);
        box-shadow: none !important;
      "
    >
      <i class="material-icons notranslate">arrow_back</i>
    </button>
    <!-- step1 -->
    <h4 *ngIf="step1" style="display: inline">
      Crea un {{ ambiente.NOMEAULA }}
    </h4>
    <!-- step2 -->
    <h4 *ngIf="step2" style="display: inline">Aggiungi Docenti</h4>
    <!-- step3 -->
    <h4 *ngIf="step3" style="display: inline">Aggiungi Studenti</h4>
    <!-- step4 -->
    <h4 *ngIf="step4" style="display: inline">Aggiungi Firma</h4>
  </div>
  <div id="formwizard_validation" class="form-wizard form-wizard-horizontal">
    <form
      class="form floating-label form-validation"
      role="form"
      novalidate="novalidate"
    ></form>
    <div class="form-wizard-nav">
      <div class="progress" id="respoBar" style="width: 100%">
        <div
          *ngIf="corsiAmbiente && afi"
          class="progress-bar progress-bar-primary"
          [ngClass]="{
            primo: step1,
            secondo: step2,
            terzo: step3,
            quarto: step4
          }"
        ></div>
        <div
          *ngIf="!corsiAmbiente && !afi"
          class="progress-bar progress-bar-primary"
          [ngClass]="{
            primoNoCorsi: step1,
            secondoNoCorsi: step2,
            terzoNoCorsi: step3
          }"
        ></div>
        <div
          *ngIf="corsiAmbiente && !afi"
          class="progress-bar progress-bar-primary"
          [ngClass]="{
            primoNoAfi: step1,
            secondoNoAfi: step2,
            quartoNoAfi: step3
          }"
        ></div>
        <div
          *ngIf="!corsiAmbiente && afi"
          class="progress-bar progress-bar-primary"
          [ngClass]="{
            primoNoAfi: step1,
            secondoNoAfi: step2,
            terzoNoAfi: step3,
            quartoNoAfi: step4
          }"
        ></div>
      </div>
      <ul
        class="nav nav-justified nav-pills bugNr1013"
        [ngClass]="{ fixAlfa111: afi }"
        id="respo"
      >
        <li>
          <a (click)="goStep1()" data-toggle="tab"
            ><span class="step" [ngClass]="{ attiva: !step1, disattiva: step1 }"
              >1</span
            >
            <span
              class="title"
              [ngClass]="{ attivaTitolo: !step1, disattivaTitolo: step1 }"
              >Crea {{ ambiente.NOMEAULA }}</span
            ></a
          >
        </li>
        <li>
          <a (click)="goStep2()" data-toggle="tab"
            ><span class="step" [ngClass]="{ attiva: !step2, disattiva: step2 }"
              >2</span
            >
            <span
              class="title"
              [ngClass]="{ attivaTitolo: !step2, disattivaTitolo: step2 }"
              >Docenti</span
            ></a
          >
        </li>
        <li>
          <a (click)="goStep3()" data-toggle="tab"
            ><span class="step" [ngClass]="{ attiva: !step3, disattiva: step3 }"
              >3</span
            >
            <span
              class="title"
              [ngClass]="{ attivaTitolo: !step3, disattivaTitolo: step3 }"
              >Studenti</span
            ></a
          >
        </li>
        <li *ngIf="afi">
          <a (click)="goStep4()" data-toggle="tab"
            ><span class="step" [ngClass]="{ attiva: !step4, disattiva: step4 }"
              >4</span
            >
            <span
              class="title"
              [ngClass]="{
                attivaTitolo: !step4,
                disattivaTitolo: step4,
                oneLineAfi: afi
              }"
              >Firma</span
            ></a
          >
        </li>
      </ul>
    </div>
  </div>
  <!--end .form-wizard-nav -->

  <div class="tab-content clearfix p-30">
    <div *ngIf="step1">
      <form [formGroup]="formAula">
        <div class="row">
          <label class="col-sm-3 label-on-left" style="margin-top: 30px"
            >Identificativo<span class="colorRed"> *</span></label
          >
          <div class="col-sm-9">
            <div class="form-group label-floating is-empty">
              <label class="control-label"></label>
              <input
                type="text"
                placeholder="Identificativo"
                formControlName="nome"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && h.categoria.errors }"
                maxlength="30"
              />
              <div
                *ngIf="submittedStep1 && f.nome.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.nome.errors.required">
                  Campo Identificativo obbligatorio
                </div>
                <div *ngIf="f.nome.errors.pattern">
                  Caratteri speciali non consentiti
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-sm-3 label-on-left" style="margin-top: 30px"
            >Descrizione<span class="colorRed"> *</span></label
          >
          <div class="col-sm-9">
            <div class="form-group label-floating is-empty">
              <label class="control-label"></label>
              <input
                type="text"
                placeholder="Descrizione"
                formControlName="descrizione"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && h.categoria.errors }"
                maxlength="30"
              />
              <div
                *ngIf="submittedStep1 && f.descrizione.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.descrizione.errors.required">
                  Campo descrizione obbligatorio
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-sm-3 label-on-left" style="margin-top: 30px"
            >Temporizzare {{ ambiente.NOMEAULA
            }}<span class="colorRed"> *</span>
            <div *ngIf="submittedStep1" class="invalid-feedback">
              <div *ngIf="!selectedOptionInd">Scelta obbligatoria</div>
            </div>
          </label>
          <div class="col-sm-9 label-on-left">
            <div class="form-group label-floating is-empty char">
              <p-selectButton
                [options]="opzioniInd"
                [(ngModel)]="selectedOptionInd"
                [ngModelOptions]="{ standalone: true }"
              ></p-selectButton>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="selectedOptionInd == 1">
          <label class="col-sm-3 label-on-left" style="margin-top: 30px"
            >Data
            <div *ngIf="submittedStep1" class="invalid-feedback">
              <div *ngIf="selectedOptionInd == 1">
                <div *ngIf="!formAula.value.dataDa || !formAula.value.dataA">
                  Data obbligatoria
                </div>
                <div *ngIf="formAula.value.dataDa >= formAula.value.dataA">
                  Data Invalida
                </div>
              </div>
            </div>
          </label>
          <div class="col-sm-3 label-on-left">
            <div class="form-group label-floating is-empty char">
              <p-calendar
                [locale]="ita"
                placeholder="Da"
                formControlName="dataDa"
                dateFormat="dd.mm.yy"
              >
              </p-calendar>
            </div>
          </div>
          <div class="col-sm-3 label-on-left">
            <div class="form-group label-floating is-empty char">
              <p-calendar
                [locale]="ita"
                placeholder="A"
                formControlName="dataA"
                dateFormat="dd.mm.yy"
              >
              </p-calendar>
            </div>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 label-on-left" style="margin-top: 30px"
            >Dettagli Aggiuntivi
          </label>
          <div class="col-sm-9 label-on-left">
            <div class="form-group label-floating is-empty char">
              <button
                type="button"
                class="btn btn-primary btn-round"
                style="padding: 8px; margin-top: -5px"
                (click)="aggiungiDettaglio()"
              >
                <i class="material-icons notranslate" style="cursor: pointer"
                  >add</i
                >
              </button>
            </div>
          </div>
        </div>
        <div class="row" *ngFor="let dettaglio of listaDettagli">
          <div class="col-sm-3">
            <div class="form-group label-floating is-empty">
              <label class="control-label"></label>
              <input
                type="text"
                class="form-control"
                placeholder="Attributo"
                [(ngModel)]="dettaglio.attributo"
                [ngModelOptions]="{ standalone: true }"
                maxlength="49"
              />
              <div
                *ngIf="submittedStep1 && erroreListaDettagli"
                class="invalid-feedback"
              >
                <div>Aggiungi un attributo</div>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="form-group label-floating is-empty">
              <label class="control-label"></label>
              <input
                type="text"
                class="form-control"
                placeholder="Descrizione"
                [(ngModel)]="dettaglio.valore"
                [ngModelOptions]="{ standalone: true }"
                maxlength="49"
              />
              <div
                *ngIf="submittedStep1 && erroreListaDettagli"
                class="invalid-feedback"
              >
                <div>Aggiungi un valore</div>
              </div>
            </div>
          </div>
          <div class="col-sm-1">
            <div class="form-group label-floating is-empty">
              <label class="control-label"></label>
              <i
                class="material-icons notranslate"
                style="
                  color: red;
                  font-size: medium;
                  margin-top: 20px;
                  cursor: pointer;
                "
                (click)="deleteDettaglio(dettaglio)"
                >clear</i
              >
            </div>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 label-on-left" style="margin-top: 21px"
            >Copertina</label
          >
          <div class="col-sm-9">
            <input
              style="display: none"
              type="file"
              (change)="caricaCopertinaChanged($event)"
              #fileInputCopertina
            />
            <button
              type="button"
              class="btn btn-primary"
              (click)="fileInputCopertina.click()"
            >
              Carica
            </button>
            <p>{{ nomeFileCopertina }}</p>
          </div>
        </div>
      </form>
    </div>

    <!-- qui scegli i Docenti  -->
    <div *ngIf="step2">
      <div class="material-datatables">
        <div *ngIf="loadingListe">
          <div class="text-center">
            <p-progressSpinner
              [style]="{ width: '40px', height: '40px' }"
              strokeWidth="5"
              styleClass="custom-spinner2"
              animationDuration=".5s"
            ></p-progressSpinner>
          </div>
        </div>
        <div *ngIf="allListaDocenti.length < 1 && !loadingListe">
          Nessun'utente disponibile
        </div>
        <div *ngIf="allListaDocenti.length > 0 && !loadingListe" class="row">
          <div class="col-xs-12">
            <form
              [formGroup]="searchDocentiForm"
              class="ng-untouched ng-pristine ng-invalid"
            >
              <input
                type="text"
                formControlName="search"
                placeholder="Cerca docente"
                maxlength="28"
                style="margin-bottom: 2%"
                class="form-control"
                (input)="refreshSelezionatiInsegnante($event)"
              />
            </form>
            <div *ngIf="searchInDocente()"></div>
            <div style="overflow: auto">
              <table
                id="datatables"
                class="table table-striped table-no-bordered table-hover table-fixed"
                cellspacing="0"
                width="100%"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <td style="width: 3rem">
                      <input
                        type="checkbox"
                        [checked]="chekAllInsegnante"
                        (click)="checkAllInsegnante($event)"
                      />
                    </td>
                    <td>Nome</td>
                    <td>Cognome</td>
                  </tr>
                </thead>
                <tbody>
                  <!-- </ng-template> -->
                  <ng-container
                    *ngFor="let user of listaDocenti; let i = index"
                  >
                    <tr>
                      <td style="width: 3rem">
                        <input
                          type="checkbox"
                          [checked]="user.isSelected"
                          (click)="checkOneInsegnante(user.id)"
                          value="{{ user }}"
                        />
                      </td>
                      <td>{{ user.nome }}</td>
                      <td>{{ user.cognome }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <!--
                            </p-table>
                        </div> -->
            </div>
          </div>
        </div>
        <div
          *ngIf="submittedStep2 && listaIdDocenti?.length < 1"
          class="invalid-feedback"
        >
          <div style="margin-left: 80%">Aggiungi almeno un docente</div>
        </div>
      </div>
    </div>
    <!--end #step2 -->

    <div *ngIf="step3">
      <div class="material-datatables">
        <div *ngIf="loadingListe">
          <div class="text-center">
            <p-progressSpinner
              [style]="{ width: '40px', height: '40px' }"
              strokeWidth="5"
              styleClass="custom-spinner2"
              animationDuration=".5s"
            ></p-progressSpinner>
          </div>
        </div>
        <div *ngIf="allListaStudenti.length < 1 && !loadingListe">
          Nessun utente disponibile
        </div>
        <div *ngIf="allListaStudenti.length > 0 && !loadingListe" class="row">
          <div class="col-xs-12">
            <form
              [formGroup]="searchStudentiForm"
              class="ng-untouched ng-pristine ng-invalid"
            >
              <input
                type="text"
                formControlName="search"
                placeholder="Cerca studenti"
                maxlength="28"
                style="margin-bottom: 2%"
                class="form-control"
                (input)="refreshSelezionati($event)"
              />
            </form>
            <div *ngIf="searchInStudenti()"></div>

            <div style="overflow: auto">
              <table
                id="datatables"
                class="table table-striped table-no-bordered table-hover table-fixed"
                cellspacing="0"
                width="100%"
                style="width: 100%"
              >
                <!-- <ng-template pTemplate="header"> -->
                <thead>
                  <tr>
                    <td style="width: 3rem">
                      <input
                        type="checkbox"
                        [checked]="chekAll"
                        (click)="checkAll($event)"
                      />
                    </td>
                    <td>Nome</td>
                    <td>Cognome</td>
                  </tr>
                  <!-- </ng-template> -->
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let user of listaStudenti; let i = index"
                  >
                    <tr>
                      <td style="width: 3rem">
                        <input
                          type="checkbox"
                          [checked]="user.isSelected"
                          (click)="checkOne(user.id)"
                          value="{{ user }}"
                        />
                      </td>
                      <td>{{ user.nome }}</td>
                      <td>{{ user.cognome }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end #step3 -->

    <div *ngIf="step4">
      <h3>Scegli il template</h3>
      <table
        id="datatables"
        class="table table-striped table-no-bordered table-hover table-fixed"
        cellspacing="0"
        width="100%"
        style="width: 100%"
      >
        <tbody>
          <ng-container *ngFor="let template of listaTemplate; let i = index">
            <tr>
              <td style="width: 3rem">
                <input
                  type="checkbox"
                  (click)="templateScelto = template.id"
                  [value]="template.id"
                  [ngClass]="{ notClickable: templateScelto === template.id }"
                  [checked]="templateScelto === template.id"
                />
              </td>
              <td>{{ template.titolo | pipeNameUnderscore }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <!--end #rootwizard -->
  <!-- FINE  WIZARD -->

  <div class="flexSpace" style="margin-top: 2%; width: 100%">
    <div class="alignLeft">
      <button
        *ngIf="!step1"
        type="button"
        (click)="previusStep()"
        class="btn btn-secondary"
        style="color: white"
      >
        Indietro
      </button>
    </div>
    <div class="alignRight">
      <div
        *ngIf="
          (!step4 && corsiAmbiente && !afi && !env.goTo) ||
          (!step3 && !corsiAmbiente && !afi ) ||
          (!step4 && afi ) || 
          (!step3 && env.goTo)
        "
      >
        <button type="button" (click)="nextStep()" class="btn btn-primary">
          Avanti
        </button>
      </div>

      <div
        *ngIf="
          (step4 && corsiAmbiente && !afi) ||
          (step3 && !corsiAmbiente && !afi) ||
          (step4 && afi) ||
          (step3 && env.goTo)
        "
      >
        <button
          [disabled]="loading"
          type="button"
          (click)="onSubmit()"
          class="btn btn-primary"
        >
          Salva
          <span *ngIf="loading">
            <p-progressSpinner
              [style]="{ width: '10px', height: '10px' }"
              strokeWidth="8"
              styleClass="custom-spinner"
              animationDuration=".5s"
            ></p-progressSpinner>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- -------------Modale-------------------- -->

<!-- Modal -->
<p-dialog
  [draggable]="false"
  [resizable]="false"
  [(visible)]="caricaCorsoBol"
  [style]="{ width: '60vw' }"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  [modal]="true"
>
  <h4 class="modal-title" id="myModalLabel">Specifiche Corso</h4>
  <form [formGroup]="createForm">
    <div class="row">
      <label style="margin-top: 21px" class="col-sm-3 label-on-left"
        >Seleziona il file<span class="colorRed"> *</span></label
      >
      <button
        #closeModal
        style="display: none"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="col-sm-9">
        <div>
          <!-- [(ngModel)]="corso.file" -->
          <input
            style="display: none"
            type="file"
            (change)="onFileChanged($event, corso)"
            #fileInput
          />
          <button
            (click)="fileInput.click()"
            class="btn btn-secondary"
            type="button"
          >
            Scegli File
          </button>
          <div class="img-space"></div>
        </div>
        <p *ngIf="nomeFile" style="margin-top: 8px">{{ nomeFile }}</p>
        <p *ngIf="!nomeFile && !submittedStep4" style="margin-top: 8px">
          Nessun file selezionato
        </p>
        <p
          *ngIf="!nomeFile && submittedStep4"
          style="margin-top: 8px; color: rgb(189, 0, 0); font-size: xx-small"
        >
          Campo File obbligatorio
        </p>
      </div>
    </div>
    <div *ngIf="corsoCaricato">
      <div class="text-center">
        <p-progressSpinner
          [style]="{ width: '40px', height: '40px' }"
          strokeWidth="5"
          styleClass="custom-spinner2"
          animationDuration=".5s"
        ></p-progressSpinner>
      </div>
    </div>
    <div class="row">
      <label class="col-sm-3 label-on-left flex" style="margin-top: 30px"
        >Nome File &nbsp;<span class="colorRed"> *</span></label
      >
      <div class="col-sm-9">
        <div class="form-group label-floating is-empty">
          <label class="control-label"></label>
          <input
            type="text"
            placeholder="Nome Corso"
            formControlName="titolo"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && h.titolo.errors }"
            maxlength="30"
          />
          <div
            *ngIf="submittedStep4 && h.titolo.errors"
            class="invalid-feedback"
          >
            <div *ngIf="h.titolo.errors.required">
              Campo nome file obbligatorio
            </div>
            <div *ngIf="h.titolo.errors.pattern">Campo nome file invalido</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-sm-3 label-on-left" style="margin-top: 30px"
        >Categoria<span class="colorRed"> *</span></label
      >
      <div class="col-sm-9">
        <div class="form-group label-floating is-empty">
          <label class="control-label"></label>
          <input
            type="text"
            placeholder="Categoria"
            formControlName="categoria"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && h.categoria.errors }"
            maxlength="30"
          />
          <div
            *ngIf="submittedStep4 && h.categoria.errors"
            class="invalid-feedback"
          >
            <div *ngIf="h.categoria.errors.required">
              Campo categoria obbligatorio
            </div>
            <div *ngIf="h.categoria.errors.pattern">
              Campo categoria invalido
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-sm-3 label-on-left" style="margin-top: 30px"
        >Autore</label
      >
      <div class="col-sm-9">
        <div class="form-group label-floating is-empty">
          <label class="control-label"></label>
          <input
            type="text"
            placeholder="Autore"
            formControlName="autore"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && h.autore.errors }"
            maxlength="30"
          />
          <div
            *ngIf="submittedStep4 && h.autore.errors"
            class="invalid-feedback"
          >
            <div *ngIf="h.autore.errors.pattern">Campo autore invalido</div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="flexLeft">
    <button type="button" class="btn btn-default" (click)="chiudiModal()">
      Chiudi
    </button>
    <button
      type="button"
      (click)="salvaStep4(); chiudiModal(true)"
      class="btn btn-primary"
    >
      Salva
    </button>
  </div>
</p-dialog>
