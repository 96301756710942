<div class="container-fluid" *ngIf="scegliAula">
  <div class="content" *ngIf="!this.goToMode; else goToView">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-content" style="margin-bottom: 2%; overflow-x: auto">
            <h4 class="card-title" style="margin-top: 2%; margin-bottom: 3%">
              Seleziona {{ ambiente.NOMEAULA }}
            </h4>

            <div>
              <div class="material-datatables">
                <div class="row">
                  <div class="col-sm-3 col-xs-3">
                    <div
                      class="dataTables_length"
                      id="datatables_length"
                      [ngClass]="{ disabled: isSelectingValue }"
                    >
                      <div style="display: flex">
                        <label class="form-group"
                          >Limita Risultati
                          <select
                            [(ngModel)]="selectedValue"
                            (ngModelChange)="cambiaValue()"
                            aria-controls="datatablescambiaValue"
                            class="form-control input-sm optionNoGray"
                          >
                            <option class="optionNoGray" value="10">10</option>
                            <option class="optionNoGray" value="20">20</option>
                            <option class="optionNoGray" value="50">50</option>
                          </select>
                          <div
                            *ngIf="isSelectingValue"
                            style="display: flex; align-items: end"
                          >
                            <div
                              class="text-center"
                              style="position: absolute; right: 0px"
                            >
                              <p-progressSpinner
                                [style]="{ width: '20px', height: '20px' }"
                                strokeWidth="5"
                                styleClass="custom-spinner2"
                                animationDuration=".5s"
                              >
                              </p-progressSpinner>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-9 col-xs-9">
                    <div
                      id="datatables_filter"
                      class="dataTables_filter"
                      style="display: flex; justify-content: flex-end"
                    >
                      <form [formGroup]="searchform">
                        <label class="form-group pull-right"
                          >Esegui una ricerca
                          <div style="display: flex">
                            <input
                              (input)="search()"
                              type="text"
                              formControlName="search"
                              class="form-control"
                              placeholder="Cerca..."
                              maxlength="30"
                              style="width: 40vw"
                            />
                            <div
                              *ngIf="isSearchingOn"
                              style="display: flex; align-items: end"
                            >
                              <div
                                class="text-center"
                                style="position: absolute; right: 0px"
                              >
                                <p-progressSpinner
                                  [style]="{ width: '20px', height: '20px' }"
                                  strokeWidth="5"
                                  styleClass="custom-spinner2"
                                  animationDuration=".5s"
                                ></p-progressSpinner>
                              </div>
                            </div>
                          </div>
                        </label>
                      </form>

                      <!-- <div *ngIf="searchInUser()"></div>   -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p *ngIf="listaAule.length < 1">
              Nessun {{ ambiente.NOMEAULA }} disponibile
            </p>
            <table
              *ngIf="listaAule.length > 0"
              id="datatables"
              class="table table-striped table-no-bordered table-hover"
              cellspacing="0"
              width="100%"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <!-- <th>#</th> -->
                  <th>{{ ambiente.NOMEAULA }}</th>
                  <th>Descrizione</th>
                  <th (click)="ordinaArray('stato')" style="cursor: pointer">
                    Stato
                    <i
                      class="material-icons notranslate"
                      style="font-size: 15px"
                      >unfold_more</i
                    >
                  </th>

                  <th class="text-right">Azioni</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th width="30%">{{ ambiente.NOMEAULA }}</th>
                  <th width="30%">Descrizione</th>
                  <th width="30%">Stato</th>
                  <th class="disabled-sorting text-right" width="10%">
                    Azioni
                  </th>
                </tr>
              </tfoot>

              <tbody>
                <tr *ngFor="let aula of listaAule; let i = index">
                  <td width="30%">{{ aula.nomeAula }}</td>
                  <td width="30%">{{ aula.descrizioneAula }}</td>
                  <td *ngIf="!aula.start" width="30%">Attiva</td>
                  <td *ngIf="aula.start && aula.suspended == 0" width="30%">
                    Attiva dal {{ aula.start | date : "dd/MM/yyyy" }} al
                    {{ aula.end | date : "dd/MM/yyyy" }}
                  </td>
                  <td *ngIf="aula.start && aula.suspended == 1" width="30%">
                    Non Attiva
                  </td>
                  <td style="text-align: right" width="10%">
                    <a
                      *ngIf="ruolo === 1"
                      [routerLink]="['/pannellocontrollo/editAula', aula.id]"
                      rel="tooltip"
                      title="Modifica {{ ambiente.NOMEAULA }}"
                      class="btn btn-warning btn-simple"
                      style="padding: 2px 2px 2px 2px"
                      ><i
                        class="material-icons notranslate pointer"
                        style="font-size: 20px"
                        >edit</i
                      ></a
                    >
                    <a
                      *ngIf="aula.suspended == 0"
                      (click)="view(aula)"
                      rel="tooltip"
                      title="Entra in {{ ambiente.NOMEAULA }}"
                      style="padding: 2px 2px 2px 2px"
                      class="btn btn-primary btn-simple"
                    >
                      <i
                        [ngStyle]="{
                          color: aula.suspended == 1 ? 'red' : defaultColor
                        }"
                        class="material-icons notranslate pointer"
                        style="font-size: 20px"
                        >forward</i
                      >
                    </a>
                    <a
                      *ngIf="aula.suspended == 1"
                      class="btn btn-primary btn-simple"
                      style="padding: 2px 2px 2px 2px"
                      [title]="chiuso()"
                    >
                      <i
                        class="material-icons notranslate block"
                        style="font-size: 20px"
                        >lock</i
                      ></a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <div class="row">
              <div class="col-sm-5">
                <div
                  class="dataTables_info"
                  id="datatables_info"
                  role="status"
                  aria-live="polite"
                >
                  {{ items }} di {{ maxItems }} Webinar
                </div>
              </div>
              <div class="col-sm-7 text-right giustificaDestra">
                <app-paginator
                  *ngIf="paginator"
                  [pageSize]="numeroMaxPagine"
                  [(isClickable)]="isClickable"
                  (currentPageEmitter)="cambiaPagina($event)"
                  [startingPage]="pagina + 1"
                ></app-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- go to -->

<ng-template #goToView>
  <app-classroom-lista-aule-go-to></app-classroom-lista-aule-go-to>
</ng-template>
