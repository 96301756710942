import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlanimetriaRoutingModule } from './planimetria-routing.module';
import { EdificioStatoPipe } from 'src/app/HELPERS/edificio-stato.pipe';
import { CreaEdificioComponent } from './crea-edificio/crea-edificio.component';
import { CreaPianoComponent } from './crea-piano/crea-piano.component';
import { ListaEdificiComponent } from './lista-edifici/lista-edifici.component';
import { ProgressBarModule } from "primeng/progressbar";
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';


@NgModule({
  declarations: [
    ListaEdificiComponent,
    CreaEdificioComponent,
    CreaPianoComponent,
    EdificioStatoPipe
  ],
  imports: [
    CommonModule,
    PlanimetriaRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarModule,
    ButtonModule,
    SlideMenuModule,
    DialogModule,
    ProgressSpinnerModule,
    SelectButtonModule,
    CalendarModule
  ]
})
export class PlanimetriaModule { }
