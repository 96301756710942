import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { AccountService, EnvService } from 'src/app/SERVICES/index';

@Component({ 
    selector:'assistenza-component', 
    templateUrl: 'assistenza.component.html',
    styleUrls: ['./assistenza.component.scss'] })
export class AssistenzaComponent implements OnInit {
    form: FormGroup;
    loading = false;
    submitted = false;
    prosegui = false;
    retrievedImage;
     @Output() tornaLogin= new EventEmitter<boolean>();
    constructor(
       
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService,
        private service : ShareService
    ) { 
        this.service.passaImmagine$.subscribe(res=>{
            this.retrievedImage = res;
        })
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }
indietro(){
    this.tornaLogin.emit(true);
}
    onSubmit() {
        this.submitted = true;
        
        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.accountService.sendEmail(this.form.value.email)
            .pipe(first())
            .subscribe(
                data => {
                    this.alertService.info( " Un'email è stata inviata al tuo indirizzo. Per reimpostare la password, segui le istruzioni riportate nell'email" );
                    this.router.navigate(['../login'], { relativeTo: this.route });
                    this.prosegui = true;
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                });
    }
}