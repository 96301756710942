
<div class="card shadow" >

    <div *ngIf="modifica">

      <div class="card-header" style="margin-bottom: 2%;">
          <h5 class="card-title" style="margin-bottom: 2%;" >Modifica Gruppo</h5>
          <form [formGroup]="gruppoModForm">
              <div class="marginBottom2px">
              <label for="nomeMod">Nome<span class="colorRed"> *</span></label>
              <input type="text" placeholder="Nome" formControlName="nomeMod" maxlength="30" class="form-control ng-untouched ng-pristine ng-invalid">
              <div *ngIf="submitted && l.nomeMod.errors" class="invalid-feedback">
                <div *ngIf="l.nomeMod.errors">Campo nome obbligatorio</div>
              </div>
            </div>
            <div >
              <label for="descrizioneMod">Descrizione<span class="colorRed"> *</span></label>
              <input type="text" placeholder="Descrizione" formControlName="descrizioneMod"  maxlength="49" class="form-control ng-untouched ng-pristine ng-invalid">
              <div *ngIf="submitted && l.descrizioneMod.errors" class="invalid-feedback">
                <div *ngIf="l.descrizioneMod.errors">Campo descrizione obbligatorio</div>
              </div>
            </div>
            </form>
            <div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;" >

            <button (click)="salvaGruppo()" class="btn btn-primary">Salva</button>
      </div> </div>
      <div class="card-header">
          <div id="datatables_filter" class="dataTables_filter">
          <form [formGroup]="searchForm">
              <input type="text" formControlName="search" class="form-control" placeholder="Cerca tra gli utenti" maxlength="28" >
          </form>
          <div *ngIf="searchInUser()"></div>
         </div>
      </div>
      <div class="container-fluid">
          <div class="content">

              <div class="row">
                  <!-- <div class="col-md-12"> -->
                      <div class="card">
                          <div class="card-content">

                              <br>

                              <div class="material-datatables">

                                  <div class="scrollable">
                                    <p-confirmDialog [style]="{width:  responsiveWidth() }" [acceptLabel]="'Si'" [baseZIndex]="5"></p-confirmDialog>

                                  <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                                      <thead style="position: sticky; background-color: white; z-index: 1;">
                                          <tr>
                                              <th >Nome</th>
                                              <th >Cognome</th>
                                              <th >Email</th>

                                              <th style="text-align: right;">Rimuovi</th>

                                          </tr>
                                      </thead>

                                      <tbody>
                                          <tr *ngFor="let utente of users;let i =index;" >

                                                      <td style="font-size: medium; " formcontrolname="nome">{{utente.nome}}</td>
                                                      <td style="font-size: medium; " formcontrolname="cognome">{{utente.cognome}}</td>
                                                      <td style="font-size: medium; " formcontrolname="email">{{utente.email}}</td>

                                                          <td style="text-align: right;"><a (click)="eliminaUtenteGruppo(utente.id,i)" ><i class="material-icons notranslate pointer" style="color: red;">delete</i></a></td>

                                      </tr>

                                      </tbody>
                                  </table></div>
                                  <div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;" >
                                      <button (click)="tornaGesGruppi(true)" class="btn btn-secondary">Chiudi</button>
                                      <button  (click)="aggiungi(true)" class="btn btn-primary">Aggiungi Utente</button>
                                                                             </div>

                              </div>
                          </div>

                      </div>

                  </div>

              <!-- </div> -->

      </div>
      </div>

        </div>


        <!---->

    <div *ngIf="add">
      <div class="container-fluid">
        <div class="content">

            <div class="row" >
                <!-- <div class="col-md-12"> -->
                    <div class="card">
                        <div class="card-content">
                            <div class="card-header" >
                                <h5 class="card-title"  style="font-weight: bold;">Seleziona un utente</h5>

                            </div>
                            <br>
                            <div id="datatables_filter" class="dataTables_filter">
                            <form [formGroup]="searchForm2">
                                <input type="text" formControlName="search2" placeholder="Cerca utente" maxlength="28" style="margin-bottom: 2%;" class="form-control ">
                            </form>
                            <div *ngIf="searchInUser2()"></div>
                          </div>
                            <div class="material-datatables">

                                <!-- <div class="scrollable" > -->
    <p-table class="table table-striped " [value]="listautentiGruppo" [(selection)]="selectedUsers3" dataKey="email" [scrollable]="true" scrollHeight="50rem">

        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem !important">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th class="one-line">Nome</th>
                <th class="one-line">Cognome</th>
                <th class="one-line">Email</th>
                <th class="one-line">Gruppo</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-user>
            <tr>
                <td style="width: 3rem !important">
                    <p-tableCheckbox [value]="user" ></p-tableCheckbox>
                </td>
                <td class="one-line"><span>{{user.nome}}</span></td>
                <td class="one-line"><span>{{user.cognome}}</span></td>
                <td class="one-line"><span>{{user.email}}</span></td>
                <td class="one-line"><span *ngFor="let gruppo of user.gruppos">{{gruppo?.nomeGruppo + ' '}}</span></td>
            </tr>

        </ng-template>

    </p-table>
    <div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;" >
        <a  class="btn btn-secondary" (click)="indietro2()" style="color: white;">Indietro</a>
        <a  class="btn btn-primary" (click)="avanti()" style="margin-right: 4%;">Aggiungi Utenti</a>
        <!-- <a  class="btn btn-secondary" (click)="closePopup()" style="color: white;">Chiudi</a> -->
              </div>

    <!-- </div> -->
    </div>

    </div>

    </div>

    </div>

    <!-- </div> -->
    </div>
  </div> </div>
