import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { EventoCalendario } from 'src/app/MODELS/ASSISTENZA/eventoCalendario';
import { AccountService } from 'src/app/SERVICES';
import { CallCenterService } from 'src/app/SERVICES/FARMACIE/callCenter';
import { FarmaciaService } from 'src/app/SERVICES/FARMACIE/farmacie';
import { ExportExcelService } from 'src/app/SERVICES/FILE/excel.service';

@Component({
  selector: 'app-report-callcenter',
  templateUrl: './report-callcenter.component.html',
  styleUrls: ['./report-callcenter.component.scss'],
})
export class ReportCallcenterComponent implements OnInit {
  today = new Date();
  ita;
  loading = false;
  calendar: FormGroup;
  rangeDates: Date[];
  toDay;
  toMonth;
  toYear;
  fromDay;
  fromMonth;
  fromYear;
  yyyy = this.today.getFullYear();
  dd = String(this.today.getDate()).padStart(2, '0');
  mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
  todayDay = this.dd;
  todayMonth = this.mm;
  todayYear = this.yyyy;

  dataForExcel = [];
  inizio;
  fine;

  callCenterData;
  campagne;

  ruolo

  listaDiCampi: string[] = [
    'Data inizio chiamata',
    'Data fine chiamata',
    'Nome operatore',
    'Ragione Sociale Farmacia',
    'Codice Alfa Intes',
    'P.Iva',
    'Provincia',
    'Data ultimo ordine',
    'Importo ultimo ordine',
    'Proposta ordine',
    'Data proposta ordine',
    'Motivi del no ordine',
    "Nome centro d'acquisto",
    'Note',
    'Target descritto',
    'Ricerca di mercato',
    'Ordine',
    'Lista Domande',
    'Lista Risposte',
    'Esito chiamata',
    'Durata della chiamata',
    'Numero telefonico chiamato ',
  ];
  constructor(
    private formBuilder: FormBuilder,
    private excelService: ExportExcelService,
    private callCenterService: CallCenterService,
    private farmaciaService: FarmaciaService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.ita = {
      firstDayOfWeek: 1,
      dayNames: [
        'Domenica',
        'Lunedi',
        'Martedi',
        'Mercoledi',
        'Giovedi',
        'Venerdi',
        'Sabato',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      monthNames: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ],
      monthNamesShort: [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic',
      ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk',
    };
    this.ita = JSON.stringify(this.ita);
    this.buildForm();
    this.ruolo = this.accountService.ruolo;
  }

  buildForm(): void {
    this.calendar = this.formBuilder.group({
      calendario: new FormControl('', Validators.required),
    });
  }

  selezionaData() {
    if (this.rangeDates !== undefined && this.rangeDates[0] !== null) {
      let dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      let mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      let yyyy = this.rangeDates[0].getFullYear();
      this.fromDay = dd;
      this.fromMonth = mm;
      this.fromYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] !== null) {
      let dd = String(this.rangeDates[1].getDate()).padStart(2, '0');
      let mm = String(this.rangeDates[1].getMonth() + 1).padStart(2, '0');
      let yyyy = this.rangeDates[1].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] === null) {
      let dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      let mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      let yyyy = this.rangeDates[0].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates == undefined) {
      this.fromDay = '01';
      this.fromMonth = '01';
      this.fromYear = '1990';
      this.toDay = this.todayDay;
      this.toMonth = this.todayMonth;
      this.toYear = this.todayYear;
    }
  }

  inviaDati() {
    this.loading = true;
    this.selezionaData();
    let id = JSON.parse(localStorage.getItem('user')).data.id;
    this.inizio =
      this.fromYear + '-' + this.fromMonth + '-' + this.fromDay + ' 00:00:00';
    this.fine =
      this.toYear + '-' + this.toMonth + '-' + this.toDay + ' 23:59:00';
    let wildixData: WildixFormatData[] = [];
    let totalResults = 1;
    if (this.toDay == new Date().getDate()) {
      this.farmaciaService.getNumbers().subscribe((resNumeri) => {
        this.farmaciaService.chiamataWildix().subscribe((res) => {
          this.farmaciaService.chiamataTokenWildix().subscribe((resToken) => {
            for (let i = 0; i < totalResults; i = i + 100) {
              this.farmaciaService
                .chiamataCallHistory(
                  this.inizio,
                  this.fine,
                  resToken.result.token,
                  i
                )
                .subscribe((resCallHistory) => {
                  totalResults = resCallHistory.result.total;
                  let telefono = JSON.parse(localStorage.getItem('user')).data
                    .telefono;
                  resCallHistory.result.records.forEach((element) => {
                    resNumeri.data.forEach((resNumero:{telefono:string, id:number})=>{
                      if ( (this.ruolo === 1 || (telefono == element.from_number)) && resNumero.telefono.endsWith(element.to_number))
                      wildixData.push({
                        disposition: element.disposition,
                        dst: element.dst,
                        duration: element.duration,
                        end: element.end,
                        from_name: element.from_name,
                        from_Number: element.from_number,
                        id: element.id,
                        src: element.src,
                        start: element.start,
                        to_name: element.to_name,
                        to_Number: element.to_number,
                        id_user: resNumero.id
                      });
                    })
                  });
                  if (wildixData.length === 0) wildixData = null;
                  this.callCenterService
                    .recuperaDatiReport(this.inizio, this.fine, wildixData, id)
                    .subscribe(
                      (res) => {
                        this.callCenterData = res.data;
                        this.exportToExcel();
                      },
                      (error) => {
                        this.loading = false;
                      }
                    );
                });
            }
          });
        });
      });
    } else {
      this.callCenterService
        .recuperaDatiReport(this.inizio, this.fine, null, id)
        .subscribe(
          (res) => {
            this.callCenterData = res.data;
            this.exportToExcel();
          },
          (error) => {
            this.loading = false;
          }
        );
    }
  }
  exportToExcel() {
    this.dataForExcel = this.transformData();
    let reportData = {};

    // this.callCenterData.forEach((row: any) => {
    //   this.dataForExcel.push(Object.values(row));
    // });
    reportData = {
      title: 'Report Call Center',
      data: this.dataForExcel,
      headers: this.listaDiCampi,
    };

    this.loading = false;

    if (this.rangeDates && this.rangeDates[1]) {
      this.excelService.exportExcel(reportData, undefined, this.rangeDates);
    } else {
      this.excelService.exportExcel(reportData, undefined, [
        new Date(this.inizio),
        new Date(this.fine),
      ]);
    }
  }
  transformData() {
    let obj: EventoCalendario[] = [];
    this.callCenterData.forEach((element, index) => {
      let oggettoCalendario = new EventoCalendario();
      if (element.calendarioReportModel) {
        oggettoCalendario.listaRisposte =
          element.calendarioReportModel.risposte;
        oggettoCalendario.chiamataInizio =
          element.calendarioReportModel.chiamataInizio;
        oggettoCalendario.chiamataFine =
          element.calendarioReportModel.chiamataFine;
        oggettoCalendario.ricercaMercato =
          element.calendarioReportModel.ricercaMercato;
        oggettoCalendario.ordine = element.calendarioReportModel.ordine;
      }
      if (element.operatoreReportModel) {
        oggettoCalendario.nomeOperatore =
          element.operatoreReportModel.nomeOperatore;
      }
      if (element.farmaciaReportModel) {
        oggettoCalendario.ragioneSociale =
          element.farmaciaReportModel.ragioneSociale;
        oggettoCalendario.codiceAlfaIntes =
          element.farmaciaReportModel.codiceAlfaIntes;
        oggettoCalendario.piva = element.farmaciaReportModel.piva;
        oggettoCalendario.provincia = element.farmaciaReportModel.provincia;
        oggettoCalendario.dataUltimoOrdine =
          element.farmaciaReportModel.dataUltimoOrdine;
        oggettoCalendario.importoUltimoOrdine =
          element.farmaciaReportModel.importoUltimoOrdine;
        oggettoCalendario.propostaOrdine =
          element.farmaciaReportModel.propostaOrdine;
        oggettoCalendario.dataPropostaOrdine =
          element.farmaciaReportModel.dataPropostaOrdine;
        oggettoCalendario.motiviNoOrdine =
          element.farmaciaReportModel.motiviNoOrdine;
        oggettoCalendario.nomeCentroAcquisto =
          element.farmaciaReportModel.nomeCentroAcquisto;
        oggettoCalendario.note = element.farmaciaReportModel.note;
      }
      if (element.call) {
        oggettoCalendario.disposition = element.call.disposition;
        oggettoCalendario.duration = element.call.duration;
        oggettoCalendario.to_Number = element.call.to_Number;
      }

      if (element.campagnaReportModel) {
        oggettoCalendario.target = this.listToStringCampagnaReportModel(
          element,
          'listaTarget'
        );
        oggettoCalendario.listaDomande = this.listToStringCampagnaReportModel(
          element,
          'listaDomande'
        );
      }

      obj.push(oggettoCalendario);
    });
    return obj;
  }

  listToStringCampagnaReportModel(element, chiave) {
    let str = '';
    if (element.campagnaReportModel && element.campagnaReportModel[chiave])
      element.campagnaReportModel[chiave].forEach((target, index) => {
        str = str + target;
        if (index !== element.campagnaReportModel[chiave].length - 1)
          str = str + ', ';
      });
    return str;
  }
}
export class WildixFormatData {
  id: string;
  start: string;
  end: string;
  from_Number: string;
  to_Number: string;
  src: string;
  dst: string;
  from_name: string;
  to_name: string;
  duration: string;
  disposition: string;
  id_user: number;
}
