<div class="card shadow ">
  <!-- <div class="card-header"> -->
    <div *ngIf="traccia" class="colorPalette"><span
        style="color: white;font-weight: bold; margin-right: 20px; font-size: small">MENO RECENTE</span><span
        style="color: white;font-weight: bold; font-size: small; margin-left: 20px;">PIÙ RECENTE</span></div>
    <!-- <div class="row" style="margin-right: -5px; margin-left: -5px;"> -->
  <!-- </div> -->
      <!-- [ngClass]="{'col-xs-8': !traccia, 'col-xs-12': traccia}" -->
    <div class="card-body">
      <div id="sidebar" class="leaflet-sidebar collapsed">
        <!-- Nav tabs -->
        <div class="leaflet-sidebar-tabs">
          <ul role="tablist">
            <li>
              <a href="#home" role="tab" (click)="changeRenderedList('home')"><i class="fa fa-bars"></i></a>
            </li>
            <li>
              <a href="#users" role="tab" (click)="changeRenderedList('users')"><i class="fa fa-user"></i></a>
            </li>
            <!-- <li class="disabled">
              <a href="#messages" role="tab"><i class="fa fa-envelope"></i></a>
            </li> -->
            <li>
              <a href="#devices" role="tab" class="material-icons" (click)="changeRenderedList('devices')">devices_other</a>
            </li>
            <!-- <li>
              <a href="#settings" role="tab" (click)="changeRenderedList('settings')"><i class="fa fa-gear"></i></a>
            </li> -->
          </ul>
      
          <!-- <ul role="tablist">
            <li>
              <a href="#settings" role="tab"><i class="fa fa-gear"></i></a>
            </li>
          </ul> -->
        </div>
      
        <!-- Tab panes -->
        <div class="leaflet-sidebar-content">
          <div class="leaflet-sidebar-pane" id="home">
            <h1 class="leaflet-sidebar-header">
              Generale
              <span class="leaflet-sidebar-close"
                ><i class="fa fa-caret-right"></i
              ></span>
            </h1>
          <div>
      
        <div *ngIf="selectedPanel=='home'" class="coordinates-container">
              <!-- <div *ngIf="!fullScreenMap"> -->
                <!-- <span *ngIf="spinner"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span> -->
    
                <div *ngFor="let user of usersStatic; let j= index" class="mat-elevation-z8 mb-5 mt-5" style="background-color: #eee;">
                  <div class="rowGrid m-0 bg-color">
                    <div class="arrowFirstGrid iconCenter">
                      <i *ngIf="user.tracker || showUser" class="material-icons pointer notranslate"
                        (click)="rotate(j,state[j], user.id, showUser)" [@rotatedState]='state[j]'
                        [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">arrow_right</i>
                      <i *ngIf="!user.tracker && !showUser" class="material-icons notranslate" style="margin-top: 10px;"
                        [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                    </div>
                    <div class="nameSecondGrid">
                      <div class="mb-10" [ngClass]="{'bold': state[j] === 'rotated'}">
                        {{user.nome}} {{user.cognome}}
                      </div>
                      <div>
                        <p *ngIf="user.capcomune.length === 1">{{user.capcomune[0]}}</p>
                        <p *ngIf="user.capcomune.length > 1" data-mdb-toggle="tooltip" data-bs-html="true"
                          [title]="user.capcomune[1]">{{user.capcomune[0]}}...</p>
                      </div>
                    </div>
                    <div class="actionTerzioGrid">
    
                      <div class="row m-0 iconCenter">
    
                        <a *ngIf="state[j] === 'rotated'" [ngClass]="{'d-none': !user.tracker}" class="geo">
                          <span *ngIf="batteryLevel >= 95"><i class="material-icons notranslate"
                              [matTooltip]="batteryLevel">battery_full</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 79 && batteryLevel < 95">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_6_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 63 && batteryLevel < 79">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_5_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 47 && batteryLevel < 63">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_4_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 31 && batteryLevel < 47">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_3_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 15 && batteryLevel < 31">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_2_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel > 0 && batteryLevel < 15">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_1_bar</i>
    
                          </span>
    
                          <span *ngIf="batteryLevel == 0 || !batteryLevel">
                            <i class="material-icons notranslate" [matTooltip]="'Non disponibile'">battery_0_bar</i>
    
                          </span>
                        </a>
                        <a [ngClass]="{'d-none': !user.tracker}" class="geo tooltipIcon"><i
                            class="material-icons notranslate"
                            (click)="changeStatusCalendar(this.listToolTip[j], j)">download</i>
    
                          <ng-container
                            *ngTemplateOutlet="tooltipCalendar; context:{tooltip: this.listToolTip[j], user: user}">
                          </ng-container>
    
                        </a>
                        <a class="geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                            class="material-icons notranslate"
                            [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">place</i></a>
                        <a class="track" (click)="tracciamento(user.id, user.nome + ' ' + user.cognome, user.tracker)"
                          [ngStyle]="{'color' : user.counter>=0 ? 'rgb(9, 255, 0)' : 'gray'}"><i
                            class="material-icons notranslate">near_me</i></a>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="state[j] === 'rotated'">
                    <div class="m-0 bg-color titolo" *ngIf="!showUser">
                      Utente associato:
                    </div>
                    <div *ngIf="user.user && !showUser">
                      <div class="rowGrid m-0 bg-color">
                        <div class="arrowFirstGrid iconCenter">
                          <!-- <i *ngIf="user.tracker" class="material-icons pointer notranslate" (click)="rotate(j,user.id)" [@rotatedState]='state[j]'
                            [ngStyle]="{'color' : colorArray[user.token]}">arrow_right</i> -->
                          <i class="material-icons notranslate" style="margin-top: 10px;"
                            [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                        </div>
                        <div class="nameSecondGrid">
                          <div class="mb-10">
                            {{user.user.nome}} {{user.user.cognome}}
                          </div>
                        </div>
                        <div class="actionTerzioGrid">
    
                          <div class="row m-0 iconCenter">
                            <a class=" geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                                class="material-icons notranslate">place</i></a>
                            <a class="track" style="color: greenyellow;"
                              (click)="tracciamento(user.id, user.nome + ' ' + user.cognome)"><i
                                class="material-icons notranslate">near_me</i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              <!-- </div> -->
              <div class="stopTrackContainer">
                <button class="btn btn-light stopTrack" *ngIf="traccia" (click)="stopTracciamento()">Stop Tracciamento
                  {{nome}}</button>
              </div>
            </div>
          </div>
      
          </div>
      
          <div class="leaflet-sidebar-pane" id="users">
            <h1 class="leaflet-sidebar-header">
              Utenti<span class="leaflet-sidebar-close"
                ><i class="fa fa-caret-right"></i
              ></span>
            </h1>
            <div *ngIf="selectedPanel=='users'" class="coordinates-container">
              <!-- <div *ngIf="!fullScreenMap"> -->
                <span *ngIf="spinner"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
    
                <div *ngFor="let user of usersStatic; let j= index" class="mat-elevation-z8 mb-5 mt-5" style="background-color: #eee;">
                  <div class="rowGrid m-0 bg-color">
                    <div class="arrowFirstGrid iconCenter">
                      <!-- <i *ngIf="user.tracker || showUser" class="material-icons pointer notranslate"
                        (click)="rotate(j,state[j], user.id, showUser)" [@rotatedState]='state[j]'
                        [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">arrow_right</i> -->
                      <!-- *ngIf="!user.tracker && !showUser" -->
                        <i class="material-icons notranslate" style="margin-top: 10px;"
                        [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                    </div>
                    <div class="nameSecondGrid">
                      <div class="mb-10" [ngClass]="{'bold': state[j] === 'rotated'}">
                        {{user.nome}} {{user.cognome}}
                      </div>
                      <div>
                        <p *ngIf="user.capcomune.length === 1">{{user.capcomune[0]}}</p>
                        <p *ngIf="user.capcomune.length > 1" data-mdb-toggle="tooltip" data-bs-html="true"
                          [title]="user.capcomune[1]">{{user.capcomune[0]}}...</p>
                      </div>
                    </div>
                    <div class="actionTerzioGrid">
    
                      <div class="row m-0 iconCenter">
    
                        <a *ngIf="state[j] === 'rotated'" [ngClass]="{'d-none': !user.tracker}" class="geo">
                          <span *ngIf="batteryLevel >= 95"><i class="material-icons notranslate"
                              [matTooltip]="batteryLevel">battery_full</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 79 && batteryLevel < 95">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_6_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 63 && batteryLevel < 79">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_5_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 47 && batteryLevel < 63">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_4_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 31 && batteryLevel < 47">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_3_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 15 && batteryLevel < 31">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_2_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel > 0 && batteryLevel < 15">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_1_bar</i>
    
                          </span>
    
                          <span *ngIf="batteryLevel == 0 || !batteryLevel">
                            <i class="material-icons notranslate" [matTooltip]="'Non disponibile'">battery_0_bar</i>
    
                          </span>
                        </a>
                        <a [ngClass]="{'d-none': !user.tracker}" class="geo tooltipIcon"><i
                            class="material-icons notranslate"
                            (click)="changeStatusCalendar(this.listToolTip[j], j)">download</i>
    
                          <ng-container
                            *ngTemplateOutlet="tooltipCalendar; context:{tooltip: this.listToolTip[j], user: user}">
                          </ng-container>
    
                        </a>
                        <a class="geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                            class="material-icons notranslate"
                            [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">place</i></a>
                        <a class="track" (click)="tracciamento(user.id, user.nome + ' ' + user.cognome, user.tracker)"
                          [ngStyle]="{'color' : user.counter>=0 ? 'rgb(9, 255, 0)' : 'gray'}"><i
                            class="material-icons notranslate">near_me</i></a>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="state[j] === 'rotated'">
                    <div class="m-0 bg-color titolo" *ngIf="!showUser">
                      Utente associato:
                    </div>
                    <div *ngIf="user.user && !showUser">
                      <div class="rowGrid m-0 bg-color">
                        <div class="arrowFirstGrid iconCenter">
                          <i class="material-icons notranslate" style="margin-top: 10px;"
                            [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                        </div>
                        <div class="nameSecondGrid">
                          <div class="mb-10">
                            {{user.user.nome}} {{user.user.cognome}}
                          </div>
                        </div>
                        <div class="actionTerzioGrid">
    
                          <div class="row m-0 iconCenter">
                            <a class=" geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                                class="material-icons notranslate">place</i></a>
                            <a class="track" style="color: greenyellow;"
                              (click)="tracciamento(user.id, user.nome + ' ' + user.cognome)"><i
                                class="material-icons notranslate">near_me</i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="m-0 bg-color titolo" *ngIf="!showUser && listaBeacons && listaBeacons.length > 0">
                      Beacon associati:
                    </div>
                    <div *ngIf="listaBeacons && listaBeacons.length > 0">
                      <ng-container *ngFor="let beacon of listaBeacons; let c = index">
                        <ng-container *ngIf="!showUser">
                          <div (click)="zoom(user.latitudine, user.longitudine)" class="rowGrid m-0 bg-color">
                            <div class="arrowFirstGrid iconCenter">
                              <i style="width: 24px;" [matTooltip]="'Ottimo'" class="material-icons notranslate"
                                *ngIf="beacon.rssi > -55 && beacon.rssi !== null">wifi</i>
                              <i style="width: 24px;" [matTooltip]="'Buono'" class="material-icons notranslate"
                                *ngIf="beacon.rssi >= -80 && beacon.rssi <= -55">wifi_2_bar</i>
                              <i style="width: 24px;" [matTooltip]="'Scarso'" class="material-icons notranslate"
                                *ngIf="beacon.rssi < -80">wifi_1_bar</i>
                              <i style="width: 24px;" [matTooltip]="'Fuori Portata'" class="material-icons notranslate"
                                *ngIf="!beacon.rssi">wifi_off</i>
                            </div>
                            <div class="nameSecondGrid">
                              <div class="mb-10">
                                {{beacon.device.deviceName}}
                              </div>
                            </div>
    
                            <div class="actionTerzioGrid">
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="showUser">
                          <div (click)="zoom(beacon.ultimaPosizione.latitudine, beacon.ultimaPosizione.longitudine)"
                            class="rowGrid m-0 bg-color" style="border-bottom: 0px">
                            <div class="arrowFirstGrid iconCenter">
                              <i class="material-icons pointer notranslate"
                                [ngStyle]="{'color' : beacon.counter>=0 ? colorArray[beacon.counter]: 'gray'}">people</i>
                            </div>
                            <div class="nameSecondGrid">
                              <div class="mb-10">
                                {{beacon.nome}}
                              </div>
                            </div>
    
                            <div class="actionTerzioGrid">
    
                      <div class="row m-0 iconCenter">
    
                        <a [ngClass]="{'d-none': !beacon.tracker}" class="geo tooltipIcon"><i
                            class="material-icons notranslate"
                            (click)="changeStatusCalendar(this.listToolTip[c], c)">download</i>
    
                          <ng-container
                            *ngTemplateOutlet="tooltipCalendar; context:{tooltip: this.listToolTip[c], user: beacon}">
                          </ng-container>
    
                        </a>
                        <a class="track" (click)="tracciamento(beacon.id, beacon.nome + ' ' + beacon.cognome, true)"
                          [ngStyle]="{'color' : beacon.counter>=0 ? 'rgb(9, 255, 0)' : 'gray'}"><i
                            class="material-icons notranslate">near_me</i></a>
                      </div>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
    
                    </div>
                  </ng-container>
                </div>
              <!-- </div> -->
            </div>
            <div class="stopTrackContainer">
              <button class="btn btn-light stopTrack" *ngIf="traccia" (click)="stopTracciamento()">Stop Tracciamento
                {{nome}}</button>
            </div>
          </div>
      
          <div class="leaflet-sidebar-pane" id="devices">
            <h1 class="leaflet-sidebar-header">
              Dispositivi<span class="leaflet-sidebar-close"
                ><i class="fa fa-caret-right"></i
              ></span>
            </h1>
            <div *ngIf="selectedPanel=='devices'" class="coordinates-container">
              <!-- <div *ngIf="!fullScreenMap"> -->
                <span *ngIf="spinner"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
                <div *ngFor="let user of usersStatic; let j= index" class="mat-elevation-z8 mb-5 mt-5" style="background-color: #eee;">
                  <div class="rowGrid m-0 bg-color">
                    <div class="arrowFirstGrid iconCenter">
                      <i *ngIf="user.tracker || showUser" class="material-icons pointer notranslate"
                        (click)="rotate(j,state[j], user.id, showUser)" [@rotatedState]='state[j]'
                        [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">arrow_right</i>
                      <i *ngIf="!user.tracker && !showUser" class="material-icons notranslate" style="margin-top: 10px;"
                        [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                    </div>
                    <div class="nameSecondGrid">
                      <div class="mb-10" [ngClass]="{'bold': state[j] === 'rotated'}">
                        {{user.nome}} {{user.cognome}}
                      </div>
                      <div>
                        <p *ngIf="user.capcomune.length === 1">{{user.capcomune[0]}}</p>
                        <p *ngIf="user.capcomune.length > 1" data-mdb-toggle="tooltip" data-bs-html="true"
                          [title]="user.capcomune[1]">{{user.capcomune[0]}}...</p>
                      </div>
                    </div>
                    <div class="actionTerzioGrid">
    
                      <div class="row m-0 iconCenter">
    
                        <a *ngIf="state[j] === 'rotated'" [ngClass]="{'d-none': !user.tracker}" class="geo">
                          <span *ngIf="batteryLevel >= 95"><i class="material-icons notranslate"
                              [matTooltip]="batteryLevel">battery_full</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 79 && batteryLevel < 95">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_6_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 63 && batteryLevel < 79">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_5_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 47 && batteryLevel < 63">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_4_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 31 && batteryLevel < 47">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_3_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel >= 15 && batteryLevel < 31">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_2_bar</i>
    
                          </span>
                          <span *ngIf="batteryLevel > 0 && batteryLevel < 15">
                            <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_1_bar</i>
    
                          </span>
    
                          <span *ngIf="batteryLevel == 0 || !batteryLevel">
                            <i class="material-icons notranslate" [matTooltip]="'Non disponibile'">battery_0_bar</i>
    
                          </span>
                        </a>
                        <a [ngClass]="{'d-none': !user.tracker}" class="geo tooltipIcon"><i
                            class="material-icons notranslate"
                            (click)="changeStatusCalendar(this.listToolTip[j], j)">download</i>
    
                          <ng-container
                            *ngTemplateOutlet="tooltipCalendar; context:{tooltip: this.listToolTip[j], user: user}">
                          </ng-container>
    
                        </a>
                        <a class="geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                            class="material-icons notranslate"
                            [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">place</i></a>
                        <a class="track" (click)="tracciamento(user.id, user.nome + ' ' + user.cognome, user.tracker)"
                          [ngStyle]="{'color' : user.counter>=0 ? 'rgb(9, 255, 0)' : 'gray'}"><i
                            class="material-icons notranslate">near_me</i></a>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="state[j] === 'rotated'">
                    <div class="m-0 bg-color titolo" *ngIf="!showUser">
                      Utente associato:
                    </div>
                    <div *ngIf="user.user && !showUser">
                      <div class="rowGrid m-0 bg-color">
                        <div class="arrowFirstGrid iconCenter">
                          <i class="material-icons notranslate" style="margin-top: 10px;"
                            [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                        </div>
                        <div class="nameSecondGrid">
                          <div class="mb-10">
                            {{user.user.nome}} {{user.user.cognome}}
                          </div>
                        </div>
                        <div class="actionTerzioGrid">
    
                          <div class="row m-0 iconCenter">
                            <a class=" geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                                class="material-icons notranslate">place</i></a>
                            <a class="track" style="color: greenyellow;"
                              (click)="tracciamento(user.id, user.nome + ' ' + user.cognome)"><i
                                class="material-icons notranslate">near_me</i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="m-0 bg-color titolo" *ngIf="!showUser && listaBeacons && listaBeacons.length > 0">
                      Beacon associati:
                    </div>
                    <div *ngIf="listaBeacons && listaBeacons.length > 0">
                      <ng-container *ngFor="let beacon of listaBeacons; let c = index">
                        <ng-container *ngIf="!showUser">
                          <div (click)="zoom(user.latitudine, user.longitudine)" class="rowGrid m-0 bg-color">
                            <div class="arrowFirstGrid iconCenter">
                              <i style="width: 24px;" [matTooltip]="'Ottimo'" class="material-icons notranslate"
                                *ngIf="beacon.rssi > -55 && beacon.rssi !== null">wifi</i>
                              <i style="width: 24px;" [matTooltip]="'Buono'" class="material-icons notranslate"
                                *ngIf="beacon.rssi >= -80 && beacon.rssi <= -55">wifi_2_bar</i>
                              <i style="width: 24px;" [matTooltip]="'Scarso'" class="material-icons notranslate"
                                *ngIf="beacon.rssi < -80">wifi_1_bar</i>
                              <i style="width: 24px;" [matTooltip]="'Fuori Portata'" class="material-icons notranslate"
                                *ngIf="!beacon.rssi">wifi_off</i>
                            </div>
                            <div class="nameSecondGrid">
                              <div class="mb-10">
                                {{beacon.device.deviceName}}
                              </div>
                            </div>
    
                            <div class="actionTerzioGrid">
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="showUser">
                          <div (click)="zoom(beacon.ultimaPosizione.latitudine, beacon.ultimaPosizione.longitudine)"
                            class="rowGrid m-0 bg-color" style="border-bottom: 0px">
                            <div class="arrowFirstGrid iconCenter">
                              <i class="material-icons pointer notranslate"
                                [ngStyle]="{'color' : beacon.counter>=0 ? colorArray[beacon.counter]: 'gray'}">place</i>
                            </div>
                            <div class="nameSecondGrid">
                              <div class="mb-10">
                                {{beacon.nome}}
                              </div>
                            </div>
    
                            <div class="actionTerzioGrid">
    
                      <div class="row m-0 iconCenter">
                        <a [ngClass]="{'d-none': !beacon.tracker}" class="geo tooltipIcon"><i
                            class="material-icons notranslate"
                            (click)="changeStatusCalendar(this.listToolTip[c], c)">download</i>
    
                          <ng-container
                            *ngTemplateOutlet="tooltipCalendar; context:{tooltip: this.listToolTip[c], user: beacon}">
                          </ng-container>
    
                        </a>
                        <a class="track" (click)="tracciamento(beacon.id, beacon.nome + ' ' + beacon.cognome, true)"
                          [ngStyle]="{'color' : beacon.counter>=0 ? 'rgb(9, 255, 0)' : 'gray'}"><i
                            class="material-icons notranslate">near_me</i></a>
                      </div>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
    
                    </div>
                  </ng-container>
                </div>
              <!-- </div> -->
              <div class="stopTrackContainer">
                <button class="btn btn-light stopTrack" *ngIf="traccia" (click)="stopTracciamento()">Stop Tracciamento
                  {{nome}}</button>
              </div>
            </div>
          </div>
      
          <!-- <div class="leaflet-sidebar-pane" id="settings">
            <h1 class="leaflet-sidebar-header">
              Debug<span class="leaflet-sidebar-close"S
                ><i class="fa fa-caret-right"></i
              ></span>
            </h1>
            <p>
              Questa sezione contiene shortcut utili per testare le funzioni della mappa.
            </p>
            <button (click)="flyTo()">Raggiungi coordinate arbitrarie</button>
            <button (click)="addMarker3()">Aggiungi marker</button>
            <button (click)="debugUserlist()">Stampa lista user in console</button>
          </div> -->
        </div>
      </div>
      
      <div id="map"></div>

      <!-- <div style="z-index: 0;" class="col-xs-8">
        <div class="map3" id="map" (leafletMapReady)="onMapReady($event)"
          [ngClass]="{notMapFullScreen: !fullScreenMap}">
        </div>
        <leaflet-fullscreen-control [map]="map"></leaflet-fullscreen-control>
      </div> -->

      <!-- <div class="col-xs-4" *ngIf="!traccia">
        <div class="flex">
          <form [formGroup]="searchform" *ngIf="!fullScreenMap" style="width:100%">
            <input type="text" formControlName="search" class="form-control" placeholder="Cerca..." style="width: 100%;"
              maxlength="30">
          </form> -->
           <!-- <div style="align-items: center" *ngIf="tracker && showToggle">
            <mat-slide-toggle
              (change)="spinner=true;clearMarkers();resetStateTooltip();changeStatusToFalseCalendar();deleteCerchio();cords=[];chiamataUtenti();" [(ngModel)]="showUser">
            </mat-slide-toggle>
          </div> -->
    </div>     
          <!--
        </div>
        <div *ngIf="searchInUser()"></div>
        <div class="table-responsive scroll">
          <table class="table table-striped" *ngIf="(!fullScreenMap && !tracker) && !showUser">
            <thead>
              <tr *ngIf="usersStatic?.length < 1">Nessun utente online</tr>
              <tr style="display: none;">
                <th style="width: 1%;"></th>
                <th style="width: 79%"></th>
                <th style="width: 20%"></th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let user of usersStatic; let j= index">
                <td style="width: 1%;"><i class="material-icons notranslate" style="margin-top: 10px;"
                    [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i></td>
                <td style="width: 79%;">
              <tr style="background-color: transparent!important; text-align: left;">{{user.nome}} {{user.cognome}}</tr>
              <tr style="background-color: transparent!important;">
                <p *ngIf="user.capcomune.length === 1">{{user.capcomune[0]}}</p>
                <p *ngIf="user.capcomune.length > 1" data-mdb-toggle="tooltip" data-bs-html="true"
                  [title]="user.capcomune[1]">{{user.capcomune[0]}}...</p>
              </tr>
              <tr style="background-color: transparent!important;"><span
                  *ngFor="let gruppo of user.gruppos; let i= index">
                  <span *ngIf="i <= user.gruppos.length-2">{{gruppo?.nomeGruppo + ', '}}</span>
                  <span *ngIf=" i === user.gruppos.length-1">{{gruppo?.nomeGruppo}}</span>
                </span></tr>
              </td>


              <td style="white-space: nowrap; width: 20%;">
                <div class="row">
                  <a class=" geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                      class="material-icons notranslate">place</i></a>
                  <a class="track" style="color: greenyellow;"
                    (click)="tracciamento(user.id, user.nome + ' ' + user.cognome)"><i
                      class="material-icons notranslate">near_me</i></a>
                </div>
              </td>

              </tr>

              <tr *ngIf="!usersStatic">
                <td colspan="4" class="text-center">
                  <span class="spinner-border spinner-border-lg align-center"></span>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="tracker && !fullScreenMap">
            <span *ngIf="spinner"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>

            <div *ngFor="let user of usersStatic; let j= index" class="mat-elevation-z8 mb-5 mt-5" style="background-color: #eee;">
              <div class="rowGrid m-0 bg-color">
                <div class="arrowFirstGrid iconCenter">
                  <i *ngIf="user.tracker || showUser" class="material-icons pointer notranslate"
                    (click)="rotate(j,state[j], user.id, showUser)" [@rotatedState]='state[j]'
                    [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">arrow_right</i>
                  <i *ngIf="!user.tracker && !showUser" class="material-icons notranslate" style="margin-top: 10px;"
                    [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                </div>
                <div class="nameSecondGrid">
                  <div class="mb-10" [ngClass]="{'bold': state[j] === 'rotated'}">
                    {{user.nome}} {{user.cognome}}
                  </div>
                  <div>
                    <p *ngIf="user.capcomune.length === 1">{{user.capcomune[0]}}</p>
                    <p *ngIf="user.capcomune.length > 1" data-mdb-toggle="tooltip" data-bs-html="true"
                      [title]="user.capcomune[1]">{{user.capcomune[0]}}...</p>
                  </div>
                </div>
                <div class="actionTerzioGrid">

                  <div class="row m-0 iconCenter">

                    <a *ngIf="state[j] === 'rotated'" [ngClass]="{'d-none': !user.tracker}" class="geo">
                      <span *ngIf="batteryLevel >= 95"><i class="material-icons notranslate"
                          [matTooltip]="batteryLevel">battery_full</i>

                      </span>
                      <span *ngIf="batteryLevel >= 79 && batteryLevel < 95">
                        <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_6_bar</i>

                      </span>
                      <span *ngIf="batteryLevel >= 63 && batteryLevel < 79">
                        <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_5_bar</i>

                      </span>
                      <span *ngIf="batteryLevel >= 47 && batteryLevel < 63">
                        <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_4_bar</i>

                      </span>
                      <span *ngIf="batteryLevel >= 31 && batteryLevel < 47">
                        <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_3_bar</i>

                      </span>
                      <span *ngIf="batteryLevel >= 15 && batteryLevel < 31">
                        <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_2_bar</i>

                      </span>
                      <span *ngIf="batteryLevel > 0 && batteryLevel < 15">
                        <i class="material-icons notranslate" [matTooltip]="batteryLevel">battery_1_bar</i>

                      </span>

                      <span *ngIf="batteryLevel == 0 || !batteryLevel">
                        <i class="material-icons notranslate" [matTooltip]="'Non disponibile'">battery_0_bar</i>

                      </span>
                    </a>
                    <a [ngClass]="{'d-none': !user.tracker}" class="geo tooltipIcon"><i
                        class="material-icons notranslate"
                        (click)="changeStatusCalendar(this.listToolTip[j], j)">download</i>

                      <ng-container
                        *ngTemplateOutlet="tooltipCalendar; context:{tooltip: this.listToolTip[j], user: user}">
                      </ng-container>

                    </a>
                    <a class="geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                        class="material-icons notranslate"
                        [ngStyle]="{'color' : user.counter>=0 ? colorArray[user.counter]: 'gray'}">place</i></a>
                    <a class="track" (click)="tracciamento(user.id, user.nome + ' ' + user.cognome, true)"
                      [ngStyle]="{'color' : user.counter>=0 ? 'rgb(9, 255, 0)' : 'gray'}"><i
                        class="material-icons notranslate">near_me</i></a>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="state[j] === 'rotated'">
                <div class="m-0 bg-color titolo" *ngIf="!showUser">
                  Utente associato:
                </div>
                <div *ngIf="user.user && !showUser">
                  <div class="rowGrid m-0 bg-color">
                    <div class="arrowFirstGrid iconCenter">
                      <i class="material-icons notranslate" style="margin-top: 10px;"
                        [ngStyle]="{'color' : colorArray[user.token]}">more_vert</i>
                    </div>
                    <div class="nameSecondGrid">
                      <div class="mb-10">
                        {{user.user.nome}} {{user.user.cognome}}
                      </div>
                    </div>
                    <div class="actionTerzioGrid">

                      <div class="row m-0 iconCenter">
                        <a class=" geo" (click)="zoom(user.latitudine, user.longitudine)"><i
                            class="material-icons notranslate">place</i></a>
                        <a class="track" style="color: greenyellow;"
                          (click)="tracciamento(user.id, user.nome + ' ' + user.cognome)"><i
                            class="material-icons notranslate">near_me</i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="m-0 bg-color titolo" *ngIf="!showUser && listaBeacons && listaBeacons.length > 0">
                  Beacon associati:
                </div>
                <div *ngIf="listaBeacons && listaBeacons.length > 0">
                  <ng-container *ngFor="let beacon of listaBeacons; let c = index">
                    <ng-container *ngIf="!showUser">
                      <div (click)="zoom(user.latitudine, user.longitudine)" class="rowGrid m-0 bg-color">
                        <div class="arrowFirstGrid iconCenter">
                          <i style="width: 24px;" [matTooltip]="'Ottimo'" class="material-icons notranslate"
                            *ngIf="beacon.rssi > -55 && beacon.rssi !== null">wifi</i>
                          <i style="width: 24px;" [matTooltip]="'Buono'" class="material-icons notranslate"
                            *ngIf="beacon.rssi >= -80 && beacon.rssi <= -55">wifi_2_bar</i>
                          <i style="width: 24px;" [matTooltip]="'Scarso'" class="material-icons notranslate"
                            *ngIf="beacon.rssi < -80">wifi_1_bar</i>
                          <i style="width: 24px;" [matTooltip]="'Fuori Portata'" class="material-icons notranslate"
                            *ngIf="!beacon.rssi">wifi_off</i>
                        </div>
                        <div class="nameSecondGrid">
                          <div class="mb-10">
                            {{beacon.device.deviceName}}
                          </div>
                        </div>

                        <div class="actionTerzioGrid">
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="showUser">
                      <div (click)="zoom(beacon.ultimaPosizione.latitudine, beacon.ultimaPosizione.longitudine)"
                        class="rowGrid m-0 bg-color" style="border-bottom: 0px">
                        <div class="arrowFirstGrid iconCenter">
                          <i class="material-icons pointer notranslate"
                            [ngStyle]="{'color' : beacon.counter>=0 ? colorArray[beacon.counter]: 'gray'}">place</i>
                        </div>
                        <div class="nameSecondGrid">
                          <div class="mb-10">
                            {{beacon.nome}}
                          </div>
                        </div>

                        <div class="actionTerzioGrid">

                  <div class="row m-0 iconCenter">
                    <a [ngClass]="{'d-none': !beacon.tracker}" class="geo tooltipIcon"><i
                        class="material-icons notranslate"
                        (click)="changeStatusCalendar(this.listToolTip[c], c)">download</i>

                      <ng-container
                        *ngTemplateOutlet="tooltipCalendar; context:{tooltip: this.listToolTip[c], user: beacon}">
                      </ng-container>

                    </a>
                    <a class="track" (click)="tracciamento(beacon.id, beacon.nome + ' ' + beacon.cognome, true)"
                      [ngStyle]="{'color' : beacon.counter>=0 ? 'rgb(9, 255, 0)' : 'gray'}"><i
                        class="material-icons notranslate">near_me</i></a>
                  </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-4" *ngIf="traccia">

        <div class="col-xs-12">
          <div class="card" style="min-height: 465px; max-height: 465px">
            <div class="card-header">
              <h4 class="card-title">Ultime chiamate</h4>
            </div>
            <div class="card-content">
              <div style="justify-content: center; text-align: center">
              </div>
              <div *ngIf="listaChiamateTracciamento">
                <div class="streamline" *ngFor="
                    let posizione of listaChiamateTracciamento;
                    let i = index
                  ">
                  <div class="sl-item sl-primary" (click)="zoom(posizione.latitudine, posizione.longitudine)">
                    <div class="sl-content">
                      {{posizione.indirizzo}} - {{posizione.capComune}}
                      <p>
                        <small class="text-muted">
                          {{posizione.timestamp}}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->


<ng-template #tooltipCalendar let-tooltip="tooltip" let-user="user">
  <div class="tooltiptext" [ngClass]="{'tooltiptextVisible':tooltip}">
    <p-calendar #myCalendar view="date" (onSelect)="selezionaData()" [maxDate]="today" [readonlyInput]="true"
      [numberOfMonths]="1" yearRange="2000:2030" [(ngModel)]="rangeDates" [showOtherMonths]="false"
      placeholder="Select time period" selectionMode="range" [inline]="true" dateFormat="dd/mm/yy" [locale]="ita">

    </p-calendar>
    <div class="rightmr-10">
      <button class="btnDwn" (click)="exportToExcel(user)">
        <!-- <i class="fa fa-download"></i> -->
        Conferma Download
      </button>
    </div>
  </div>
</ng-template>
