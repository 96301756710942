<div class="container-fluid">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="card z-index-0">
          <div class="card-content" *ngIf="!showAdd">
            <h4 class="card-title">Gestione Farmacie</h4>
            <ul class="nav nav-pills nav-pills-primary">
              <li class="active">
                <a href="#pill1" data-toggle="tab">Farmacia</a>
              </li>
            </ul>
            <hr />
            <div class="tab-content">
              <div class="tab-pane active" id="pill1">
                <div class="rigaPulsanti">
                  <button
                    class="btn btn-primary ml-auto"
                    (click)="showAdd = true"
                  >
                    Aggiungi Farmacia
                  </button>
                </div>

                <mat-spinner *ngIf="isLoading" class="spinner"></mat-spinner>
                <div *ngIf="!isLoading">
                  <div class="xScroll" *ngIf="showTable" id="scrollableTableFarmacieAll">
                    <table mat-table [dataSource]="dataSource">
                      <ng-container *ngFor="let item of displayedColumns">
                        <ng-container
                          [matColumnDef]="item"
                          [sticky]="
                            item == 'ragione_Sociale_Farmacia' ? true : false
                          "
                        >
                          <th mat-header-cell *matHeaderCellDef>
                            <mat-form-field appearance="standard">
                              <mat-label>
                                <i class="material-icons no-translate fSmall"
                                  >search</i
                                >
                                {{ getLabel(item) }}
                              </mat-label>
                              <input
                                matInput
                                (keyup)="applyFilter($event, item)"
                                placeholder="Cerca ..."
                                #input
                              />
                            </mat-form-field>
                          </th>

                          <td
                            [ngClass]="{
                              selected:
                                this.listSelectedItems &&
                                element &&
                                this.listSelectedItems.indexOf(element.id) !==
                                  -1
                            }"
                            mat-cell
                            *matCellDef="let element"
                            (click)="rowSelected(element.id)"
                          >
                            <ng-container
                              *ngIf="listIdToModify.indexOf(element.id) === -1"
                            >
                              <div
                                class="h-21"
                                [ngClass]="{
                                  'one-line':
                                    (element[item] &&
                                      element[item].length > 30) ||
                                    (!checkType(element[item]) &&
                                      element[item] &&
                                      element[item].length > 2)
                                }"
                                (dblclick)="modifyRow(element)"
                              >
                                <span
                                  *ngIf="
                                    checkType(element[item]) &&
                                    element[item] !== true &&
                                    element[item] !== false
                                  "
                                >
                                  {{ element[item] }}
                                </span>
                                <span *ngIf="element[item] === true"> Si </span>

                                <span *ngIf="element[item] === false">
                                  No
                                </span>
                                <span *ngIf="!checkType(element[item])">
                                  <ng-container
                                    *ngFor="
                                      let itemList of element[item];
                                      let i = index
                                    "
                                  >
                                    {{ itemList }}
                                    <span
                                      *ngIf="
                                        itemList !==
                                        element[item][element[item].length - 1]
                                      "
                                    >
                                      ,
                                    </span>
                                  </ng-container>
                                </span>
                              </div>
                            </ng-container>

                            <mat-form-field
                              appearance="standard"
                              *ngIf="listIdToModify.indexOf(element.id) !== -1"
                            >
                              <input
                                matInput
                                [type]="typeOf(element[item])"
                                [(ngModel)]="element[item]"
                                #input
                                *ngIf="
                                  item !== 'ricerca_Di_Mercato' &&
                                  item !== 'partita_Iva' &&
                                  item !== 'stato' &&
                                  !item.toLowerCase().includes('data')
                                "
                              />
                              <input
                                matInput
                                [type]="typeOf(element[item])"
                                [(ngModel)]="element[item]"
                                #input
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                *ngIf="item === 'partita_Iva'"
                              />

                              <mat-select
                                [(ngModel)]="element[item]"
                                name="Ricerca"
                                *ngIf="item === 'ricerca_Di_Mercato'"
                              >
                                <mat-option [value]="''"> Nessuno </mat-option>
                                <mat-option
                                  *ngFor="let ricerca of listaMercato"
                                  [value]="ricerca.nome"
                                >
                                  {{ ricerca.nome }}
                                </mat-option>
                              </mat-select>

                              <mat-select
                                [(ngModel)]="element[item]"
                                name="stato"
                                *ngIf="item === 'stato'"
                              >
                                <mat-option [value]="'Incompleto'">
                                  Incompleto
                                </mat-option>
                                <mat-option [value]="'Completo'">
                                  Completo
                                </mat-option>
                              </mat-select>

                              <input
                                disabled
                                matInput
                                [(ngModel)]="element[item]"
                                *ngIf="item.toLowerCase().includes('data')"
                                [matDatepicker]="datepicker"
                              />
                              <mat-hint
                                *ngIf="item.toLowerCase().includes('data')"
                                >GG/MM/AAAA</mat-hint
                              >
                              <mat-datepicker-toggle
                                *ngIf="item.toLowerCase().includes('data')"
                                matSuffix
                                [for]="datepicker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #datepicker disabled="false">
                                <button mat-button matDatepickerCancel>
                                  Cancel
                                </button>
                                <button
                                  mat-raised-button
                                  color="primary"
                                  matDatepickerApply
                                >
                                  Apply
                                </button>
                              </mat-datepicker>
                            </mat-form-field>
                          </td>
                        </ng-container>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: true"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                      ></tr>
                    </table>
                  </div>
                  <div class="flex">
                    <button
                      [@flyInOut]
                      *ngIf="listIdToModify.length > 0"
                      class="btn btn-primary saveButton"
                      (click)="modifyRows(0)"
                    >
                      Salva
                    </button>
                    <button
                      [@flyInOut]
                      *ngIf="listIdToModify.length > 0"
                      class="btn btn-primary saveButton"
                      (click)="annullaModifyRows()"
                    >
                      Annulla
                    </button>
                    <button
                      [@flyInOut]
                      *ngIf="listSelectedItems.length > 0 && ruolo === 1"
                      class="btn btn-danger saveButton"
                      (click)="modalElimina()"
                    >
                      Elimina
                    </button>
                    <mat-paginator
                      *ngIf="lunghezzaPaginator"
                      [pageIndex]="pageIndex"
                      [pageSize]="pageSize"
                      [length]="lunghezzaPaginator"
                      class="ml-auto"
                      (page)="changeVisual($event)"
                      [pageSizeOptions]="[5, 10, 25, 100]"
                    ></mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-content" *ngIf="showAdd">
            <app-farmacie-add (goBackAdd)="goBackAdd()"></app-farmacie-add>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  [draggable]="false"
  [resizable]="false"
  [(visible)]="showModalElimina"
  [closeOnEscape]="true"
  modal="true"
  [dismissableMask]="true"
>
  <h5 class="centered">
    {{
      listSelectedItems.length === 1
        ? "Sicuro di voler eliminare la seguente farmacia?"
        : "Sicuro di voler eliminare la seguenti farmacie?"
    }}
  </h5>

  <div class="centered mw-750">
    <div>
      <div class="xScroll">
        <table mat-table [dataSource]="dataSourceDeleteItems">
          <ng-container *ngFor="let item of displayedColumns">
            <ng-container [matColumnDef]="item">
              <th mat-header-cell *matHeaderCellDef class="capitalize">
                {{ getLabel(item) }}
              </th>
              <td mat-cell *matCellDef="let element">
                <span>{{ element[item] }}</span>
              </td>
            </ng-container>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="flex mt-10">
      <button class="btn saveButton" (click)="showModalElimina = false">
        Annulla
      </button>
      <button
        class="btn btn-primary saveButton ml-auto"
        (click)="deleteFiles()"
      >
        Elimina
      </button>
    </div>
  </p-footer>
</p-dialog>
