import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from '../GENERAL/env.service';

@Injectable({
  providedIn: 'root',
})
export class BadgeService {
  baseUrl: String;
  constructor(private env: EnvService, private http: HttpClient) {
    this.baseUrl = this.env.apiUrl;
  }

  /*getFotoObbligatorie(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/isPhotoRequired")
  }

  getDistanzaMassima(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/badge/configuration")
  }*/

  getConfiguration(): Observable<any> {
    // console.log("Url: " + this.baseUrl + "/badge/configuration");
    return this.http.get<any>(this.baseUrl + '/badge/configuration');
  }

  updateConfiguration(configurazione): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + '/badge/configuration',
      configurazione
    );
  }
}
