import { Component, HostListener, OnInit } from '@angular/core';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AccountService, AlertService, UsersService } from 'src/app/SERVICES';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { PrenotazioneTecnicoService } from 'src/app/SERVICES/PRENOTAZIONETECNICO/prenotazione.service';
import { stringify } from 'querystring';
import { CallCenterService } from 'src/app/SERVICES/FARMACIE/callCenter';
import { FormControl } from '@angular/forms';
import { FarmaciaService } from 'src/app/SERVICES/FARMACIE/farmacie';
import { CampagneService } from 'src/app/SERVICES/FARMACIE/campagne';
const colors: any = {
  3: {
    primary: '#D3D3D3',
    secondary: '#D3D3D3',
  },
  4: {
    primary: '#D3D3D3',
    secondary: '#D3D3D3',
  },
  2: {
    primary: '#FFCE56',
    secondary: '#FDF1BA',
  },
  0: {
    primary: '#FF6384',
    secondary: '#ffc2cf',
  },
  1: {
    primary: '#bce08c',
    secondary: '#e3ffbd',
  },
};
@Component({
  selector: 'app-calendar-call-center',
  templateUrl: './calendar-call-center.component.html',
  styleUrls: ['./calendar-call-center.component.scss'],
})
export class CalendarCallCenterComponent implements OnInit {
  filtroObj = {
    active: false,
    idFarmacia: null,
    da: null,
    a: null,
    soloPrecedenti: false,
    soloProssime: false,
  };
  isDesktop = true;
  listaInputFarmacia;
  farmaciaSelector = false;
  dateInizioRimando: any = new Date();
  farmaciaSelected = undefined;
  dateFineRimando: any = new Date();
  listaOrdini = [];
  listaMercato = [];
  showFarmacia;
  farmacieList = [];
  selectedComunicazione = 1;
  selectedValue;
  campagnaScelta;
  showRimanda = false;
  lockRimanda = false;
  startEndingDate;
  startInputDate;
  idDaVisualizzare;
  showModalSearch = false;
  showModalModify = false;
  selectedOrdine = '';
  selectedRicerca = '';
  showForm = false;
  activeDayIsOpen: boolean = true;
  ruolo;
  animation = false;
  showModal = false;
  costanteInizio = 1;
  showModifica = false;
  idModifica;
  CalendarView = CalendarView;
  startOfMonth;
  endOfMonth;
  listaComunicazione = [
    {
      id: 4,
      name: 'Online',
    },
    {
      id: 3,
      name: 'E-Mail',
    },
    {
      id: 1,
      name: 'WhatsApp',
    },
    {
      id: 2,
      name: 'Wildix',
    },
  ];
  actions: CalendarEventAction[] = [
    {
      label:
        '<i class="material-icons notranslate" style="color: blue;">info</i>',
      cssClass: 'iconaModTecnico',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.vaiModifica(event, true);
      },
    },
  ];

  actionsMail: CalendarEventAction[] = [
    {
      label:
        '<i class="material-icons notranslate" style="color: red;">mail</i>',
      cssClass: 'iconaModTecnico',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.vaiInfo(event);
      },
    },
  ];
  actionsWA: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-whatsapp" style="color:lime"></i>',
      cssClass: 'iconaModTecnico',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.vaiInfo(event);
      },
    },
  ];
  actionsWildix: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-w" style="color:blue">W</i>',
      cssClass: 'iconaModTecnico',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.vaiInfo(event);
      },
    },
  ];
  actionsOnline: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-w" style="color:blue">Online</i>',
      cssClass: 'iconaModTecnico',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.vaiInfo(event);
      },
    },
  ];
  showCalendar = false;
  timeOutReminder;
  events: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();
  schedule = false;
  firstView = true;
  viewDate: Date = new Date();
  todayDate: Date = new Date();
  view: CalendarView = CalendarView.Month;
  isFromElimina: boolean = false;
  showInfo: boolean = false;
  constructor(
    private callCenterService: CallCenterService,
    private deviceService: DeviceDetectorService,
    private accountService: AccountService,
    private alertService: AlertService,
    private farmacieService: FarmaciaService,
    private campagnaService: CampagneService,
    private userService: UsersService
  ) {
    this.isDesktop = this.deviceService.isDesktop();
    this.ruolo = this.accountService.ruolo;
  }

  ngOnInit(): void {
    let oggi = new Date();
    this.getFarmacie();
    this.startOfMonth = new Date(oggi.getFullYear(), oggi.getMonth(), 1);
    this.startOfMonth =
      this.startOfMonth.getFullYear().toString() +
      '-' +
      ('0' + this.startOfMonth.getMonth()).slice(-2) +
      '-' +
      ('0' + this.startOfMonth.getDate()).slice(-2) +
      ' 00:00:00';
    this.endOfMonth = new Date(oggi.getFullYear(), oggi.getMonth() + 2, 7);
    this.endOfMonth =
      this.endOfMonth.getFullYear().toString() +
      '-' +
      ('0' + this.endOfMonth.getMonth()).slice(-2) +
      '-' +
      ('0' + this.endOfMonth.getDate()).slice(-2) +
      ' 00:00:00';
    this.getEvents(this.startOfMonth, this.endOfMonth);
    let timeOut = undefined;
    if (this.callCenterService.orarioProssimaChiamata)
      timeOut =
        this.callCenterService.orarioProssimaChiamata.getTime() -
        oggi.getTime();
    if (timeOut)
      this.timeOutReminder = setTimeout(async () => {
        this.idModifica = { id: this.callCenterService.idProssimaChiamata };
        await this.vaiModifica({
          id: this.callCenterService.idProssimaChiamata,
          idCampagna: this.callCenterService.idCampagna,
          farmacia: { id: this.callCenterService.idFarmacia },
        });
        this.showModalModify = true;
        this.resetTimeOutReminder();
      }, timeOut);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1280) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
    }
  }

  getFarmacie() {
    this.callCenterService.getFarmacie().subscribe((res) => {
      this.farmacieList = res.data;
    });
  }

  getEvents(startDate, endDate, farmacia?) {
    if (this.filtroObj.active) {
      let oggi = new Date();
      if (this.filtroObj.soloPrecedenti) {
        let newStartDate = new Date(startDate);
        let newEndDate = new Date(endDate);
        if (newStartDate > oggi) {
          startDate = new Date(oggi.getFullYear(), oggi.getMonth(), 1);
          startDate =
            startDate.getFullYear().toString() +
            '-' +
            ('0' + startDate.getMonth()).slice(-2) +
            '-' +
            ('0' + startDate.getDate()).slice(-2) +
            ' 00:00:00';
        }
        if (newEndDate > oggi) {
          endDate = new Date(
            oggi.getFullYear(),
            oggi.getMonth() + 1,
            oggi.getDate()
          );
          endDate =
            endDate.getFullYear().toString() +
            '-' +
            ('0' + endDate.getMonth()).slice(-2) +
            '-' +
            ('0' + endDate.getDate()).slice(-2) +
            ' 00:00:00';
        }
      } else if (this.filtroObj.soloProssime) {
        let newStartDate = new Date(startDate);
        let newEndDate = new Date(endDate);
        if (newStartDate < oggi) {
          startDate = new Date(
            oggi.getFullYear(),
            oggi.getMonth() + 1,
            oggi.getDate()
          );
          startDate =
            startDate.getFullYear().toString() +
            '-' +
            ('0' + startDate.getMonth()).slice(-2) +
            '-' +
            ('0' + startDate.getDate()).slice(-2) +
            ' 00:00:00';
        }
        if (newEndDate > oggi) {
          endDate = new Date(oggi.getFullYear(), oggi.getMonth() + 2, 7);
          endDate =
            endDate.getFullYear().toString() +
            '-' +
            ('0' + endDate.getMonth()).slice(-2) +
            '-' +
            ('0' + endDate.getDate()).slice(-2) +
            ' 00:00:00';
        }
      } else if (this.filtroObj.da && this.filtroObj.a) {
        let newStartDate = new Date(startDate);
        let newEndDate = new Date(endDate);
        if (newStartDate > this.filtroObj.a) {
          startDate = new Date(
            this.filtroObj.a.getFullYear(),
            this.filtroObj.a.getMonth(),
            1
          );
          startDate =
            startDate.getFullYear().toString() +
            '-' +
            ('0' + startDate.getMonth()).slice(-2) +
            '-' +
            ('0' + startDate.getDate()).slice(-2) +
            ' 00:00:00';
        }
        if (newEndDate > this.filtroObj.a) {
          endDate = new Date(
            this.filtroObj.a.getFullYear(),
            this.filtroObj.a.getMonth() + 1,
            this.filtroObj.a.getDate()
          );
          endDate =
            endDate.getFullYear().toString() +
            '-' +
            ('0' + endDate.getMonth()).slice(-2) +
            '-' +
            ('0' + endDate.getDate()).slice(-2) +
            ' 00:00:00';
        }
        if (newStartDate < this.filtroObj.da) {
          startDate = new Date(
            this.filtroObj.da.getFullYear(),
            this.filtroObj.da.getMonth() + 1,
            this.filtroObj.da.getDate()
          );
          startDate =
            startDate.getFullYear().toString() +
            '-' +
            ('0' + startDate.getMonth()).slice(-2) +
            '-' +
            ('0' + startDate.getDate()).slice(-2) +
            ' 00:00:00';
        }
        if (newEndDate > this.filtroObj.da) {
          endDate = new Date(
            this.filtroObj.da.getFullYear(),
            this.filtroObj.da.getMonth() + 2,
            7
          );
          endDate =
            endDate.getFullYear().toString() +
            '-' +
            ('0' + endDate.getMonth()).slice(-2) +
            '-' +
            ('0' + endDate.getDate()).slice(-2) +
            ' 00:00:00';
        }
      }
      farmacia = this.filtroObj.idFarmacia;
    }
    this.callCenterService
      .getAllFile(startDate, endDate, farmacia)
      .subscribe((res) => {
        this.showCalendar = false;
        this.events = [];
        res.data.forEach((element) => {
          let action = null;
          if (this.ruolo === 1 || this.ruolo === 2) {
            action = this.actions;
            if (element.idModalitaComunicazione === 4) {
              action = this.actionsOnline;
            }
            if (element.idModalitaComunicazione === 3) {
              action = this.actionsMail;
            }
            if (element.idModalitaComunicazione === 2) {
              action = this.actionsWildix;
            }
            if (element.idModalitaComunicazione === 1) {
              action = this.actionsWA;
            }
          }
          let ntf = '';
          element.farmacia.numeroTelefonicoFisso.forEach((el, index) => {
            ntf =
              ntf +
              el.numero_fisso +
              `${
                index == element.farmacia.numeroTelefonicoFisso.length - 1
                  ? ''
                  : ','
              }`;
          });
          ntf.slice(0, -1);
          element.farmacia.numeroTelefonicoFisso.forEach;
          element.farmacia.numeroTelefonicoFisso;
          let event: any = {
            farmacia: element.farmacia,
            idCampagna: element.idCampagna,
            id: element.id,
            start: new Date(element.chiamataInizio),
            end: new Date(element.chiamataFine),
            title:
              new Date(element.chiamataInizio).getHours() +
              ':' +
              (new Date(element.chiamataInizio).getMinutes() < 10
                ? '0' + new Date(element.chiamataInizio).getMinutes()
                : new Date(element.chiamataInizio).getMinutes()) +
              ' ' +
              element.farmacia.ragioneSocialeFarmacia +
              '- ' +
              ntf,
            color:
              colors[
                new Date(element.chiamataInizio) < new Date()
                  ? element.idModalitaComunicazione
                    ? 1
                    : 3
                  : 2
              ],
            actions: action,
            allDay: false,
            resizable: {
              beforeStart: false,
              afterEnd: false,
            },
            draggable: false,
          };
          //fix 1315
          // if(this.todayDate > event.end)
          // {
          //   event.color = colors[3];
          //   //event.actions = null;
          // }
          this.events.push(event);
        });
        this.showCalendar = true;
        this.refresh.next(null);
      });
  }
  stampa(event) {
    // console.log(event)
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (events === undefined || !(events.length > 0)) {
      this.showModal = true;
    }
    if (isSameDay(this.viewDate, date) && this.activeDayIsOpen) {
      this.showModal = true;
    }
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events === undefined ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    this.startOfMonth = new Date(
      this.viewDate.getFullYear(),
      this.viewDate.getMonth(),
      1
    );
    this.startOfMonth =
      this.startOfMonth.getFullYear().toString() +
      '-' +
      ('0' + this.startOfMonth.getMonth()).slice(-2) +
      '-' +
      ('0' + this.startOfMonth.getDate()).slice(-2) +
      ' 00:00:00';
    this.endOfMonth = new Date(
      this.viewDate.getFullYear(),
      this.viewDate.getMonth() + 2,
      7
    );
    this.endOfMonth =
      this.endOfMonth.getFullYear().toString() +
      '-' +
      ('0' + this.endOfMonth.getMonth()).slice(-2) +
      '-' +
      ('0' + this.endOfMonth.getDate()).slice(-2) +
      ' 00:00:00';
    this.getEvents(this.startOfMonth, this.endOfMonth);
  }
  creaSchedule() {
    this.schedule = true;
    this.firstView = false;
  }
  setView(view: CalendarView) {
    this.view = view;
  }
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    // this.handleEvent('Dropped or resized', event);
  }
  creaForm(isFromElimina = false) {
    this.isFromElimina = isFromElimina;
    this.showForm = !this.showForm;
  }
  async vaiModifica(id, fromClick = false) {
    this.idModifica = id;
    this.showModalModify = true;
    let oggi = new Date();
    if (id.start && id.start.getTime() > oggi.getTime()) {
      this.lockRimanda = fromClick;
      this.showRimanda = true;
    }
    this.farmacieService.getAllFileById([id.farmacia.id]).subscribe((res) => {
      {
        this.listaInputFarmacia = res.data[0];
      }
    });
    this.userService.getOrdine().subscribe((res) => {
      this.listaOrdini = res.data;
    });
    this.userService.getRicercaMercato().subscribe((res) => {
      this.listaMercato = res.data;
    });

    if (id.idCampagna) {
      this.campagnaService
        .recuperaCampagna(id.idCampagna)
        .subscribe(async (res) => {
          this.campagnaScelta = res.data;
          this.campagnaScelta.domande_Associate = [];
          await this.populateDomande(0);
          this.campagnaScelta.domande_Associate.forEach((element, index) => {
            this.campagnaScelta.domande_Associate[index] = {
              domanda: element,
              risposta: '',
            };
          });
        });
    } else {
      this.campagnaScelta = {
        domande_Associate: [],
      };
    }
  }
  populateDomande(i) {
    this.userService
      .getQuestionarioById(this.campagnaScelta.listaQuestionari[i].id)
      .subscribe((res) => {
        res.data.listaDomande.forEach((element) => {
          this.campagnaScelta.domande_Associate.push(element);
        });
        if (this.campagnaScelta.listaQuestionari[i + 1])
          this.populateDomande(i + 1);
      });
    return;
  }
  backModifica() {
    this.getEvents(this.startOfMonth, this.endOfMonth);
    this.showModifica = false;
  }
  saveFiles() {
    let date =
      this.viewDate.getFullYear().toString() +
      '-' +
      ('0' + (this.viewDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + this.viewDate.getDate()).slice(-2) +
      ' ';
    if (this.startInputDate && this.startEndingDate && this.selectedValue) {
      let calendario = {
        idOperatore: this.accountService.userValue.data.id,
        chiamataInizio: date + this.startInputDate + ':00',
        chiamataFine: date + this.startEndingDate + ':00',
        idFarmacia: this.selectedValue,
      };
      this.callCenterService.salvaCalendario(calendario).subscribe((res) => {
        this.showModal = false;
        this.getEvents(this.startOfMonth, this.endOfMonth);
        this.resetTimeOutReminder();
      });
    } else {
      this.alertService.error('Dati non inseriti correttamente');
    }
  }
  resetTimeOutReminder() {
    this.callCenterService.getProssimaChiamata().subscribe((res) => {
      let oggi = new Date();
      let idChiamataAttuale = this.callCenterService.idProssimaChiamata;
      if (res.data) {
        this.callCenterService.orarioProssimaChiamata = new Date(
          res.data.chiamataInizio
        );
        this.callCenterService.idProssimaChiamata = res.data.id;
        this.callCenterService.idFarmacia = res.data.farmacia.id;
        this.callCenterService.idCampagna = res.data.idCampagna;
        this.idModifica = { id: this.callCenterService.idProssimaChiamata };
        let timeOut =
          this.callCenterService.orarioProssimaChiamata.getTime() -
          oggi.getTime();
        if (this.timeOutReminder) {
          clearTimeout(this.timeOutReminder);
        }
        this.timeOutReminder = setTimeout(() => {
          this.showModalModify = true;
          this.vaiModifica({
            id: this.callCenterService.idProssimaChiamata,
            idCampagna: this.callCenterService.idCampagna,
            farmacia: { id: this.callCenterService.idFarmacia },
          });
        }, timeOut);
      } else {
        this.callCenterService.idCampagna = undefined;
        this.callCenterService.orarioProssimaChiamata = undefined;
        this.callCenterService.idFarmacia = undefined;
        this.callCenterService.idProssimaChiamata = undefined;
      }
    });
  }
  backAddFarmacia(event) {
    this.showFarmacia = false;
    this.listaInputFarmacia = event;
  }

  saveChiamata() {
    if (!this.showRimanda) {
      let risposte = '';
      this.campagnaScelta.domande_Associate.forEach((element) => {
        try {
          element.risposta.replace(',', '¸');
        } catch (error) {}
        if (!element.risposta) element.risposta = ' ';
        risposte = risposte + element.risposta + ',';
      });
      let body = {
        modComunicazione: this.selectedComunicazione,
        ordine: this.selectedOrdine,
        ricercaMercato: this.selectedRicerca,
        listaRisposte: risposte,
      };
      this.farmacieService
        .modificaFarmacia(this.listaInputFarmacia)
        .subscribe((res) => {
          this.callCenterService
            .aggiungiInformazioni(this.idModifica.id, body)
            .subscribe((res) => {
              this.resetTimeOutReminder();
              this.getEvents(this.startOfMonth, this.endOfMonth);
              this.showModalModify = false;
            });
        });
    } else {
      this.dateInizioRimando = this.dateInizioRimando.replace('T', ' ') + ':00';
      let newDate = new Date(this.dateInizioRimando);
      let dateFineRimando =
        newDate.getFullYear().toString() +
        '-' +
        ('0' + (newDate.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + newDate.getDate()).slice(-2) +
        ' ' +
        this.dateFineRimando +
        ':00';
      let body = {
        chiamataInizio: this.dateInizioRimando,
        chiamataFine: dateFineRimando,
      };
      this.callCenterService
        .modificaChiamata(this.idModifica.id, body)
        .subscribe((res) => {
          this.resetTimeOutReminder();
          this.getEvents(this.startOfMonth, this.endOfMonth);
          this.showModalModify = false;
          this.showRimanda = false;
        });
    }
  }

  vaiInfo(id) {
    this.idDaVisualizzare = id;
    this.showInfo = true;
  }
  indietroInfo() {
    this.getEvents(this.startOfMonth, this.endOfMonth);
    this.showInfo = false;
  }
  resetValue() {
    this.selectedValue = undefined;
    this.startInputDate = undefined;
    this.startEndingDate = undefined;
    this.farmaciaSelector = false;
    this.farmaciaSelected = undefined;
    this.showFarmacia = false;
    this.dateInizioRimando = new Date();
    this.dateFineRimando = new Date();
  }

  elimina() {
    this.callCenterService
      .eliminaChiamata(this.idModifica.id)
      .subscribe((res) => {
        this.showModalModify = false;
        this.alertService.success('Chiamata eliminata con successo');
        this.resetTimeOutReminder();
        this.indietroInfo();
      });
  }
}
