<ng-container>
  <div class="flex">
    <button (click)="indietro()" id="back-button" class="btn btn-round  btn-just-icon" *ngIf="showPage">
      <i class="material-icons notranslate">arrow_back</i>
    </button>
    <h3 class="title m-7" *ngIf="showPage">{{responseEvent.nomeEvento}}</h3>
    <div class="right">
      <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
    </div>
  </div>

  <mat-tab-group *ngIf="showPage">
    <mat-tab label="Info evento">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="content">
              <div *ngIf="responseEvent.sede===1 && responseEvent.datiEventoInterno.linkThumbnail"
                style="text-align:center">
                <img src="{{responseEvent.datiEventoInterno.linkThumbnail}}" class="fototessera">
              </div>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="width: 20%;"></th>
                    <th style="width: 80%;"></th>
                  </tr>
                </thead>
                <tbody class="animation3">
                  <tr>
                    <td class="text-right" style="max-width: 30%;">
                      <h6>Tipo evento</h6>
                    </td>
                    <td *ngIf="responseEvent.sede==1">In sede</td>
                    <td *ngIf="responseEvent.sede==0">Fuori sede</td>
                  </tr>
                  <tr *ngIf="this.responseEvent.sede===1">
                    <td class="text-right flex-end" style="font-weight: bold;">
                      <h6>Descrizione</h6>
                    </td>
                    <td class="col-10">

                      <p-accordion>
                        <p-accordionTab header="">
                          <div [innerHtml]="responseEvent.datiEventoInterno.body"></div>

                        </p-accordionTab>
                      </p-accordion>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right" style="font-weight: bold;">
                      <h6>Data</h6>
                    </td>
                    <td class="col-10">{{responseEvent.dataInizio | date:"dd-MM-yyyy"}}</td>
                  </tr>
                  <tr>
                    <td class="text-right" style="font-weight: bold;">
                      <h6>Ora inizio</h6>
                    </td>
                    <td class="col-10">{{responseEvent.dataInizio | date:"HH:mm"}}</td>
                  </tr>


                  <tr>
                    <td class="text-right" style="font-weight: bold;">
                      <h6>Ora fine</h6>
                    </td>
                    <td class="col-10">{{responseEvent.dataFine | date:"HH:mm"}}</td>
                  </tr>
                  <tr *ngIf="responseEvent.sede==1">
                    <td class="text-right" style="font-weight: bold;">
                      <h6>Posti totali</h6>
                    </td>
                    <td class="col-10">{{responseEvent.datiEventoInterno.posti}}</td>
                    <!-- {{prenotazioni.postiDisponibili}} /  -->
                  </tr>
                  <tr *ngIf="responseEvent.sede===0">
                    <td class="text-right" style="font-weight: bold;">
                      <h6>Tecnico</h6>
                    </td>
                    <td class="col-10">
                      <div *ngIf="responseEvent.tecnico.linkThumbnail" style="text-align: center;" class="col-sm-3">
                        <img class="fototessera bordo-grigio" src="{{responseEvent.tecnico.linkThumbnail}}">
                        <br>
                        {{responseEvent.tecnico.nomeTecnico}} {{responseEvent.tecnico.cognomeTecnico}}
                      </div>
                      <span *ngIf="!responseEvent.tecnico.linkThumbnail">{{responseEvent.tecnico.nomeTecnico}}
                        {{responseEvent.tecnico.cognomeTecnico}}</span>
                  </tr>
                </tbody>
              </table>

              <hr>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="!responseEvent.sede" label="Lista Attrezzature">

      <div class="flex f-wrap">
        <ng-container *ngFor="let attrezzatura of arrayEquipments">
          <div class="container m-0 pr-0 flex w-fit-content mt-10">
            <p-checkbox [(ngModel)]="attrezzatura.selected" [binary]="true" disabled="true"> </p-checkbox>
            <label class="names">{{attrezzatura.name}}</label>
          </div>
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab label="Dettagli Form">
      <ul class="names">
        <li *ngFor="let campo of this.forms">
          <ul>•{{campo.nome}}</ul>
        </li>
      </ul>
    </mat-tab>
    <!-- <mat-tab *ngIf="!responseEvent.sede" label="Lista Prodotti">
      <div *ngIf="prodotti.length==0">
        Nessun prodotto da mostrare
      </div>
      <table mat-table [dataSource]="prodotti" matSort *ngIf="prodotti.length>0">

        <ng-container matColumnDef="name">
          <th class="w-100" mat-header-cell *matHeaderCellDef mat-sort-header>
            Nome Prodotto
          </th>
          <td class="w-100 left center-vertically" mat-cell *matCellDef="let element">
            {{element.nomeProdotto}}
          </td>

        </ng-container>
        <ng-container matColumnDef="azioni">
          <th mat-header-cell *matHeaderCellDef >
            Download
          </th>
          <td mat-cell *matCellDef="let element" class="iconaElimina">

            <a [href]="link + element.url" rel="tooltip" title="Download"
            class="btn btn-primary btn-simple" style="padding: 2px 2px 2px 2px;"><i
              class="material-icons pointer" style="font-size: 20px;">get_app</i></a>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsSmartwatch"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsSmartwatch;"></tr>
      </table>
      <app-lista-file [disabled]="true" [prodotti]="prodotti" [fromPrenotaTecnico]="true"></app-lista-file>
    </mat-tab>  -->

    <mat-tab label='Partecipazioni'>
      <div class="row">

        <div class="col-md-12">
          <div class="card">
            <div class="content" *ngIf="(responseEvent.sede===0 || showInfo) && !loading && partecipazioneSelezionata">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="width: 20%;"></th>
                    <th style="width: 80%;"></th>
                  </tr>
                </thead>
                <tbody class="animation3">

                  <tr>
                    <td class="text-right" style="max-width: 30%;">
                      <h6>Agente</h6>
                    </td>
                    <td>{{partecipazioneSelezionata.agente.nome}} {{partecipazioneSelezionata.agente.cognome}}</td>
                  </tr>


                  <tr>
                    <td class="text-right" style="max-width: 30%;">
                      <h6>Stato partecipazione</h6>
                    </td>
                    <td *ngIf="partecipazioneSelezionata.participationStatus=='PENDING'"
                      style="color: rgb(253, 216, 31);">In attesa</td>
                    <td *ngIf="partecipazioneSelezionata.participationStatus=='CONFIRMED'" style="color: limegreen;">
                      Confermata</td>
                    <td *ngIf="partecipazioneSelezionata.participationStatus=='REFUSED'" style="color: red;">Rifiutata
                    </td>
                  </tr>

                  <tr>
                    <td class="text-right" style="max-width: 30%;">
                      <h6>Tipologia form</h6>
                    </td>
                    <td>{{responseEvent.tipologia.name}}</td>
                  </tr>
                  <tr *ngFor="let campo of partecipazioneSelezionata.form | keyvalue">
                    <td class="text-right" style="max-width: 30%;">
                      <h6 *ngIf="campo.key.toLowerCase() != 'lista attrezzatura'">{{campo.key}}</h6>
                      <h6 *ngIf="campo.key.toLowerCase() == 'lista attrezzatura'">Attrezzatura</h6>
                    </td>
                    <!-- Generico-->
                    <td *ngIf="campo.key.toLowerCase() != 'lista attrezzatura'">{{campo.value}}</td>
                    <!-- lista attrezzatura -->
                    <td *ngIf="campo.key.toLowerCase() == 'lista attrezzatura'">
                      <ul *ngFor="let attrezzo of campo.value" style="margin-top:10px;">
                        <li>
                          {{attrezzo.nomeAttrezzatura}}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style="text-align:center;">
                <button *ngIf="showInfo" [disabled]="loading" (click)="this.showInfo = false;"
                  class="btn btn-primary">Torna all'elenco</button>
              </div>


              <hr>
              <div class="flex ">
                <span *ngIf="responseEvent.sede === 0 && prenotazioni && prenotazioni.participations.length>0">
                  <button [disabled]="loading" (click)="gestisciPartecipazione(1)"
                    *ngIf="prenotazioni.participations[0].participationStatus === 'PENDING' || prenotazioni.participations[0].participationStatus == 'PENDING'" class="btn btn-primary">Rifiuta</button>
                  <button [disabled]="loading" (click)="gestisciPartecipazione(0)"
                    *ngIf="prenotazioni.participations[0].participationStatus === 'PENDING'" class="btn btn-primary">Conferma</button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Dettagli Form -->

        <div *ngIf="!prenotazioni || prenotazioni.participations.length==0" style="margin-left: 1.5%;">
          Nessuna prenotazione effettuata per questo evento.
        </div>

        <div class="col-sm-3 col-xs-3">
          <div class="dataTables_length" id="datatables_length">
          </div>
        </div>
      </div>
      <div *ngIf="responseEvent.sede===1 && !showInfo && prenotazioni && prenotazioni.participations.length>0"
        class="scrollable">
        <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%"
          style="width:100%">
          <thead style="position: sticky; background-color: white;z-index: 1;">
            <tr style="position: sticky; z-index: 1; background-color: white;">
              <th style="position: sticky; z-index: 1; background-color: white;">
                Nome Agente</th>
              <th style="position: sticky; z-index: 1; background-color: white;">
                Email Cliente</th>
              <!-- <th style="position: sticky; z-index: 1; background-color: white;">
                                            Gruppo</th> -->
              <th style="position: sticky; z-index: 1; background-color: white;">
                Stato</th>
              <th class="text-right" style="position: sticky; z-index: 1; background-color: white;">
                Azioni</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Nome Agente</th>
              <th>Email Cliente</th>
              <th>Stato</th>
              <th class="text-right">Azioni</th>
            </tr>
          </tfoot>
          <tbody>
            <ng-container *ngIf="prenotazioni && prenotazioni.participations.length>0">
              <tr *ngFor="let prenotazione of prenotazioni.participations">
                <ng-container>
                  <td style="font-size: medium;">{{prenotazione.agente.nome + " " +
                    prenotazione.agente.cognome}}</td>
                  <td style="font-size: medium;">{{prenotazione.emailCliente}}</td>
                  <td>
                    <span *ngIf="prenotazione.participationStatus=='PENDING'" style="color: rgb(253, 216, 31);">In
                      attesa</span>
                    <span *ngIf="prenotazione.participationStatus=='CONFIRMED'"
                      style="color: limegreen;">Confermata</span>
                    <span *ngIf="prenotazione.participationStatus=='REFUSED'" style="color: red;">Rifiutata</span>
                  </td>
                  <td class="text-right">
                    <span *ngIf="prenotazione.participationStatus == 'PENDING'">
                      <a (click)="gestisciPartecipazione(0, prenotazione.id)" rel="tooltip"
                        title="Conferma partecipazione"><i class="material-icons notranslate pointer"
                          style="font-size: 22px; padding: 2px 2px 2px 2px; color: limegreen;">check</i></a>
                    </span>
                    <span *ngIf="prenotazione.participationStatus == 'PENDING'">
                      <a (click)="gestisciPartecipazione(1, prenotazione.id)" rel="tooltip"
                        title="Rifiuta partecipazione"><i class="material-icons notranslate pointer"
                          style="font-size: 22px; padding: 2px 2px 2px 2px; color: red;">cancel</i></a>
                    </span>
                    <span>
                      <a (click)="infoPrenotazione(prenotazione.id)" rel="tooltip" title="Visualizza form"><i
                          class="material-icons notranslate pointer"
                          style="font-size: 22px; padding: 2px 2px 2px 2px; color: lightskyblue;">info</i></a>
                    </span>
                    <!-- <p-button (click)="confirm1()" icon="pi pi-check" label="Confirm"></p-button> -->
                    <!-- <p-confirmDialog [style]="{width: '50vw'}" key="positionDialog" [position]="position" [baseZIndex]="10000"></p-confirmDialog>

<p-messages [value]="msgs"></p-messages> -->
                  </td>
                </ng-container>

              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </mat-tab>


    <mat-tab *ngIf="responseEvent.stato === 0" label="Causale">
      <h2 class="title">Causale</h2>
      <p class="testoCausale">
        {{responseEvent.causaleAnnullamento}}
      </p>
    </mat-tab>

  </mat-tab-group>

  <div class="flex" *ngIf="showPage && todayDate < responseEvent.dataFine">
    <button [disabled]="loading" (click)="vaiModifica()" class="btn btn-primary">Modifica</button>
    <button [disabled]="loading" (click)="eliminaEvento(responseEvent.id)" class="btn btn-primary">Elimina</button>
    <button [disabled]="loading" (click)="aggiornaEvento()" class="btn btn-primary">Conferma Reminder</button>
    <button [disabled]="loading" *ngIf="maxStepper" class="btn btn-primary right"
      (click)="showReminderModal = true">Reminder</button>
    <button [disabled]="loading" *ngIf="!maxStepper" [matTooltip]="textTooltipButtonReminderDisabled"
      class="btn btn-primary right" disabled>Reminder</button>
  </div>
</ng-container>


<app-creazione-evento *ngIf="editMode" [modifica]="true" [eventoSelezionato]="responseEvent"
  (backEmit)="indietroDaModifica()"></app-creazione-evento>

<p-dialog [(visible)]="showReminderModal" [closeOnEscape]="true" modal="true" [dismissableMask]="true">
  <h4 class="center">Scegli la data del reminder</h4>
  <div class="w-300">
    <mat-slider class="m-7" [max]="maxStepper" [min]="1" [displayWith]="labelFn" [step]="1" [thumbLabel]="true"
      [(ngModel)]="sliderValue" aria-labelledby="example-name-label">
    </mat-slider>
    <!-- <div class="flex">
      Attiva le notifiche &nbsp;
      <mat-slide-toggle [(ngModel)]="checkedReminder" [color]="'primary'"></mat-slide-toggle>
    </div> -->
    <span class="data" *ngIf="showPage">
      Riceverai la notifica il: {{getDateValue(sliderValue) | date: 'dd/MM/yyyy HH:mm'}}
    </span>
  </div>
</p-dialog>
