import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-double-list',
  templateUrl: './double-list.component.html',
  styleUrls: ['./double-list.component.scss']
})
export class DoubleListComponent implements OnInit {

  @Input() nomeLista1;
  @Input() nomeLista2;
  @Input() lista1;
  @Input() lista2;
  @Output() lista1Change = new EventEmitter();
  @Output() lista2Change = new EventEmitter();
  @Input() inAccordion = false;
  @Input() titleAccordion;
  @Input() nomeParametro = '';
  searchValueList1 = '';
  searchValueList2 = '';

  constructor() { }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
  switchArray(i) {
    transferArrayItem(
      this.lista1,
      this.lista2,
      i,
      this.lista2.length
    )
  }
  switchArrayInverse(i) {
    transferArrayItem(
      this.lista2,
      this.lista1,
      i,
      this.lista1.length
    )
  }

  stampa(list) {
    list
    console.log(list)
    return true;
  }
}
