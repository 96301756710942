import {
  Component,
  OnInit,
  AfterViewInit,
  DoCheck,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import 'leaflet-sidebar-v2';
import * as L from 'leaflet';
import 'leaflet-fullscreen';
import { Control, Map, TileLayer } from 'leaflet';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Utente } from 'src/app/MODELS/USER/utente';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { EnvService, UsersService } from 'src/app/SERVICES/index';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { ExportExcelService } from 'src/app/SERVICES/FILE/excel.service';
/* ES5 */
var htmlToImage = require('html-to-image');

@Component({
  selector: 'app-geo3',
  templateUrl: './geolocalizzazione3.component.html',
  styleUrls: ['./geolocalizzazione3.component.less'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('rotated => default', animate('80ms ease-out')),
      transition('default => rotated', animate('80ms ease-in')),
    ]),
  ],
})
export class Geolocalizzazione3Component implements AfterViewInit, OnDestroy {

  public map!:any /*Map #MAPPAL*/;
  
  public sidebar!: Control.Sidebar;
  

  selectedPanel = 'home';

   private initMap(): void {
 
     this.map = new Map('map', {
       center: [39.8282, -98.5795],
       zoom: 3
     });
     const tiles = new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
     maxZoom: 18,
     minZoom: 3,
       attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
     });

     var sidebar = new Control.Sidebar({ container: 'sidebar', position: "right" })
       .addTo(this.map)
       .open('home');

      // sidebar.addPanel({
      //   id: 'js-api',
      //   tab: '<i class="fa fa-gear"></i>',
      //  title: 'JS API',
      //   pane: '<p>The Javascript API allows to dynamically create or modify the panel state.<p/><p><button onclick="sidebar.enablePanel(\'mail\')">enable mails panel</button><button onclick="sidebar.disablePanel(\'mail\')">disable mails panel</button></p><p><button onclick="addUser()">add user</button></b>',
      // });
      
     tiles.addTo(this.map);

   }

  flyTo()
  {
    this.map.flyTo([40.865901, 14.285809]);
  }

  addMarker3()
  {
    
    const redMarker = L.divIcon({
      className: 'material-icons red-marker',
      html: 'place',
      iconSize: [24, 24],
      iconAnchor: [12, 12]
    });
    
    const marker = L.marker([40.865901, 14.285809], { icon: redMarker }).addTo(this.map);
    
  }
  //Fine aggiornamento a nuova versione

  today = new Date();
  dateToShow = new Date();
  @ViewChild('myCalendar') private myCalendar: any;
  @Output() mappaCaricata = new EventEmitter<boolean>();
  //map: Map;
  
  users = [];
  intervallo: any;
  allUser;
  cords = [];
  showToolTip = false;
  open = true;
  traccia = false;
  allcords;
  rangeDates: Date[];
  showToggle = false;
  listToolTip = [];
  //fullScreenMap: boolean = false;
  i = 0;
  mainLayer;
  markers = [];
  firstTime = false;
  subscription: Subscription;
  nome;
  showUser = false;
  colorArray2 = [
    '#0000ff',
    '#0300fc',
    '#0500fa',
    '#0800f7',
    '#0a00f5',
    '#0d00f2',
    '#0f00f0',
    '#1200ed',
    '#1500ea',
    '#1700e8',
    '#1a00e5',
    '#1c00e3',
    '#1f00e0',
    '#2100de',
    '#2400db',
    '#2700d8',
    '#2900d6',
    '#2c00d3',
    '#2e00d1',
    '#3100ce',
    '#3400cb',
    '#3600c9',
    '#3900c6',
    '#3b00c4',
    '#3e00c1',
    '#4000bf',
    '#4300bc',
    '#4600b9',
    '#4800b7',
    '#4b00b4',
    '#4d00b2',
    '#5000af',
    '#5200ad',
    '#5500aa',
    '#5800a7',
    '#5a00a5',
    '#5d00a2',
    '#5f00a0',
    '#62009d',
    '#64009b',
    '#670098',
    '#6a0095',
    '#6c0093',
    '#6f0090',
    '#71008e',
    '#74008b',
    '#760089',
    '#790086',
    '#7c0083',
    '#7e0081',
    '#81007e',
    '#83007c',
    '#860079',
    '#890076',
    '#8b0074',
    '#8e0071',
    '#90006f',
    '#93006c',
    '#95006a',
    '#980067',
    '#9b0064',
    '#9d0062',
    '#a0005f',
    '#a2005d',
    '#a5005a',
    '#a70058',
    '#aa0055',
    '#ad0052',
    '#af0050',
    '#b2004d',
    '#b4004b',
    '#b70048',
    '#b90046',
    '#bc0043',
    '#bf0040',
    '#c1003e',
    '#c4003b',
    '#c60039',
    '#c90036',
    '#cb0034',
    '#ce0031',
    '#d1002e',
    '#d3002c',
    '#d60029',
    '#d80027',
    '#db0024',
    '#de0021',
    '#e0001f',
    '#e3001c',
    '#e5001a',
    '#e80017',
    '#ea0015',
    '#ed0012',
    '#f0000f',
    '#f2000d',
    '#f5000a',
    '#f70008',
    '#fa0005',
    '#fc0003',
    '#ff0000',
  ];
  colorArray1 = [
    '#FF0000',
    '#FF8B00',
    '#0800FF',
    '#70FF00',
    '#00FFC9',
    '#0080FF',
    '#F0D218',
    '#9E00FF',
    '#FF00B9',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
  ];
  colorArray = this.colorArray1;
  usersStatic = [];
  listaBeacons = [];
  searchform: FormGroup;
  markerHtmlStyles = `
  color: ${this.colorArray[this.i]};
  font-size: xx-large;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  -webkit-text-stroke: 1px black;
  `;
  ita;
  
  smallIcon = L.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [0, 24],
    popupAnchor: [0, -36],
    html: `<i class="material-icons notranslate" style="${this.markerHtmlStyles}">place</i>`,
  });

  tracker;
  utenteAssociato;

  state: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private shareService: ShareService,
    private alertService: AlertService,
    private router: Router,
    private fileService: FileService,
    private envService: EnvService,
    private excelService: ExportExcelService
  ) {
    this.ita = {
      firstDayOfWeek: 1,
      dayNames: [
        'Domenica',
        'Lunedi',
        'Martedi',
        'Mercoledi',
        'Giovedi',
        'Venerdi',
        'Sabato',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      monthNames: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ],
      monthNamesShort: [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic',
      ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk',
    };
    this.ita = JSON.stringify(this.ita);
    this.tracker = this.envService.TRACKING;
    
    this.mainLayer = L.tileLayer(
      'https://{s}.tile.openStreetMap.org/{z}/{x}/{y}.png',
      {
        minZoom: 6,
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }
    );
    
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required],
    });
    this.intervallo = setInterval(() => {
      if (this.open) {
        if (this.tracker && !this.showUser) {
          let toReset = true;
          this.listToolTip.forEach((element) => {
            if (element) toReset = false;
          });
          if (toReset)
            this.subscription = this.userService
              .getCordTracker(6, true)
              .subscribe((res) => {
                this.listToolTip = [];
                if (res.data.length === 0) this.tracker = false;
                res.data.forEach((element) => {
                  element.tracker = true;
                  this.listToolTip.push(false);
                });
                this.cords = res.data;
                this.chiamataUtenti();
                // if(this.myCalendar)
                // this.myCalendar.updateInputfield();
              });
        } else {
          this.cords = [];
          this.chiamataUtenti();
        }
      }
    }, 5000);
  }
  spinner = false;
  chiamataUtenti() {
    this.userService.getCord().subscribe((res) => {
      this.i = 0;
      if (this.showUser && this.toCallTrackerInUser) {
        this.userService.getCordTracker(6, true).subscribe((res2) => {
          this.cords = [...this.cords, ...res.data.utentiOnline];
          this.allcords = this.cords;
          this.setLista();
          this.clearMarkers();
          res2.data.forEach((element, i) => {
            element.tracker = true;
            res2.data[i].counter = i + this.cords.length
            this.addMarker2(
              element.ultimaPosizione.latitudine,
              element.ultimaPosizione.longitudine,
              element.counter
            );
          });
          this.listaBeacons = res2.data;
          // if(this.myCalendar)
          // this.myCalendar.updateInputfield();
          this.cords.forEach((user, i) => {
            this.state.push('default');

            if (
              user.ultimaPosizione &&
              user.ultimaPosizione.timestamp &&
              user.ultimaPosizione.timestamp < new Date().getTime() - 18000000
            ) {
              this.cords[i].counter = -1;
              this.usersStatic[i].counter = -1;
            } else {
              this.cords[i].counter = this.i;
              this.usersStatic[i].counter = this.i;
            }
            //L.marker(user.latitudine, user.longitudine).removeFrom(this.map)
            this.addMarker2(
              user.ultimaPosizione.latitudine,
              user.ultimaPosizione.longitudine,
              user.counter
            );
            if (user.counter != -1) this.i += 1;
          });
          this.showToggle = true;
        });
      } else {
        if(this.selectedPanel!='devices')
        {
          this.cords = [...this.cords, ...res.data.utentiOnline];
        }
        this.allcords = this.cords;
        this.setLista();
        this.clearMarkers();
        this.cords.forEach((user, i) => {
          this.state.push('default');

          if (
            user.ultimaPosizione &&
            user.ultimaPosizione.timestamp &&
            user.ultimaPosizione.timestamp < new Date().getTime() - 18000000
          ) {
            this.cords[i].counter = -1;
            this.usersStatic[i].counter = -1;
          } else {
            this.cords[i].counter = this.i;
            this.usersStatic[i].counter = this.i;
          }
          //L.marker(user.latitudine, user.longitudine).removeFrom(this.map)
          this.addMarker2(
            user.ultimaPosizione.latitudine,
            user.ultimaPosizione.longitudine,
            user.counter
          );
          if (user.counter != -1) this.i += 1;
        });
        setTimeout(()=>{
          this.spinner = false;
          this.showToggle = true;
        },1000)
      }
    });
  }
  ngOnDestroy(): void {
    clearInterval(this.intervallo);
    clearInterval(this.intervalBeacon);
    this.open = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  searchInUser() {
    if (this.usersStatic !== null) {
      let valore: string = this.searchform.value.search;
      this.usersStatic = this.allUser;
      if (valore) {
        let users = [];
        this.usersStatic.forEach((user) => {
          let nomeCognome = user.nome + ' ' + user.cognome;
          let cognomeNome = user.cognome + ' ' + user.nome;
          user.gruppos.forEach((gruppo) => {
            if (
              gruppo.nomeGruppo.includes(valore) ||
              gruppo.nomeGruppo.toLowerCase().includes(valore.toLowerCase()) ||
              gruppo.nomeGruppo.toUpperCase().includes(valore)
            ) {
              if (!users.find((x) => x === user)) {
                users.push(user);
              }
            }
          });

          if (
            cognomeNome
              .toLocaleLowerCase()
              .includes(valore.toLocaleLowerCase()) ||
            nomeCognome
              .toLocaleLowerCase()
              .includes(valore.toLocaleLowerCase()) ||
            user.nome.toLowerCase().includes(valore.toLowerCase()) ||
            user.cognome.toLowerCase().includes(valore.toLowerCase())
          ) {
            if (!users.find((x) => x === user)) {
              users.push(user);
            }
          }

          if (
            user.user &&
            (user.user.nome
              .toLocaleLowerCase()
              .includes(valore.toLocaleLowerCase()) ||
              user.user.cognome
                .toLocaleLowerCase()
                .includes(valore.toLocaleLowerCase()))
          ) {
            if (!users.find((x) => x === user)) {
              users.push(user);
            }
          }
        });
        this.usersStatic = users;
      } else if (this.users !== this.allUser) {
        this.usersStatic = this.allUser;
      }
    }
  }

  ngAfterViewInit(): void {
    // this.getLista();
    this.creaMappa();
    this.spinner=true;
  }
  changeMonth(event) {
    this.dateToShow = new Date(event.year, event.month, 0);
  }
  changeStatusCalendar(valueListToolTip, j) {
    this.listToolTip.forEach((element) => {
      element = false;
    });
    this.listToolTip[j] = !valueListToolTip;
  }
  changeStatusToFalseCalendar() {
    this.listToolTip.forEach((element, i) => {
      this.listToolTip[i] = false;
    });
  }

  getLista() {
    if (this.tracker) {
      this.subscription = this.userService
        .getCordTracker(6, true)
        .subscribe((res) => {
          res.data.forEach((element) => {
            this.state.push('default');
            element.tracker = true;
          });
          this.cords = res.data;
          this.userService.getCord().subscribe(
            (res) => {
              this.cords = [...this.cords, ...res.data.utentiOnline];
              this.allcords = this.cords;
              this.setLista();
              this.creaMappa();
            },
            (error) => {}
          );
        });
    } else {
      this.userService.getCord().subscribe(
        (res) => {
          this.cords = res.data.utentiOnline;
          this.allcords = this.cords;
          this.setLista();
          this.creaMappa();
        },
        (error) => {}
      );
    }
  }

  intervalBeacon;
  // rotate(j, state, id, isUser = false) {
  //   this.idUserOpen = null;
  //   if (this.intervalBeacon) {
  //     clearInterval(this.intervalBeacon);
  //   }
  //   this.toCallTrackerInUser = false;
  //   this.state.forEach((element, i) => {
  //     this.state[i] = 'default';
  //   });
  //   this.state[j] = state === 'default' ? 'rotated' : 'default';
  //   if (this.state[j] === 'rotated') {
  //     this.listaBeacons = [];
  //     this.utenteAssociato = '';
  //     this.getBeacons(id);
  //     this.getBattery(id);
  //     this.intervalBeacon = setInterval(() => {
  //       this.getBeacons(id);
  //       if (!this.showUser) this.getBattery(id);
  //     }, 5000);
  //   }
  // }



rotate(j, state, id, isUser = false) {
    this.idUserOpen = null;
    if (this.intervalBeacon) {
      clearInterval(this.intervalBeacon);
    }
    this.toCallTrackerInUser = false;
    this.state.forEach((element, i) => {
      this.state[i] = 'default';
    });
    this.state[j] = state === 'default' ? 'rotated' : 'default';
    if (this.state[j] === 'rotated') {
      this.listaBeacons = [];
      this.utenteAssociato = '';
      this.getBeacons(id);
      this.getBattery(id);
      this.intervalBeacon = setInterval(() => {
        this.getBeacons(id);
        if (!this.showUser) this.getBattery(id);
      }, 5000);
    }
  }
  
  map2;
  fromDay;
  fromMonth;
  fromYear;
  toDay;
  toMonth;
  toYear;

  stampa(beacon) {
    return beacon.nome
  }
  selezionaData() {
    if (this.rangeDates !== undefined && this.rangeDates[0] !== null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.fromDay = dd;
      this.fromMonth = mm;
      this.fromYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] !== null) {
      var dd = String(this.rangeDates[1].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[1].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[1].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] === null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.toDay = dd;
      this.toMonth = mm;
      this.toYear = yyyy;
    }
    if (this.rangeDates == undefined) {
      this.fromDay = '01';
      this.fromMonth = '01';
      this.fromYear = '1990';
      this.toDay = this.today.getDate();
      this.toMonth = this.today.getMonth() + 1;
      this.toYear = this.today.getFullYear();
    }
  }

  idUserOpen;
  toCallTrackerInUser;
  getBeacons(id) {
    this.idUserOpen = id;
    if (!this.showUser) {
      this.userService.getBeaconsTracker(id).subscribe((res) => {
        this.listaBeacons = [];
        this.utenteAssociato = '';
        this.utenteAssociato = res.data.utente;
        this.listaBeacons = res.data;
      });
    } else {
      this.toCallTrackerInUser = true;
    }
  }

  batteryLevel = -1;
  getBattery(id) {
    this.userService.getBatteryTracker(id).subscribe((res) => {
      if(res.data.length === 0) {
        this.batteryLevel = -1;
      } else {
        this.batteryLevel = res.data[0].telemetryData;
      }
    });
  }

  setLista() {
    let i = 0;
    this.usersStatic = [];
    this.cords.forEach((user) => {
      let utente: Utente = new Utente();
      utente.id = user.id;
      utente.nome = user.nome;
      utente.cognome = user.cognome ? user.cognome : '';
      utente.gruppos = user.gruppos ? user.gruppos : [];
      utente.latitudine = user.ultimaPosizione.latitudine;
      utente.longitudine = user.ultimaPosizione.longitudine;
      utente.capcomune = user.ultimaPosizione.capComune;
      utente.user = user.utente;
      let tmp = utente.capcomune;
      utente.capcomune = utente.capcomune.split(',', 2);
      utente.token = i.toString();
      utente.counter = i;
      utente.tracker = user.tracker;
      if (utente.capcomune.length > 1) {
        utente.capcomune[1] = tmp;
      }
      this.usersStatic.push(utente);
      i++;
    });
    this.allUser = this.usersStatic;
  }

  countUsers()
  {
    if(!this.usersStatic)
    {
      return 0;
    }
    return this.usersStatic.filter((user) => !user.tracker).length;
  }

  creaMappa() {
    let cordinate = {
      lat: 40.86,
      lng: 14.28,
    };
    navigator.geolocation.getCurrentPosition(
      (res) => {
        cordinate.lat = res.coords.latitude;
        cordinate.lng = res.coords.longitude;
        let a = 14;
        let zoomLevel = a;

        this.map = new Map('map', {
          center: [39.8282, -98.5795],
          zoom: zoomLevel,
          dragging: true
        });

        const tiles = new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 18,
          minZoom: 3,
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });
        

        //  const fullscreenControl = new L.Control.Fullscreen();
        //  this.map.addControl(fullscreenControl);


        if(Object.keys(L.control).includes("fullscreen")) {
          L.control["fullscreen"]().addTo(this.map);
        }
        
        

        var sidebar = new Control.Sidebar({ container: 'sidebar', position: "right" })
        .addTo(this.map)
        .open('home');
        
        
        tiles.addTo(this.map);

        

        // this.map2 = new L.map('map', {
        //   center: [cordinate.lat, cordinate.lng],
        //   zoom: zoomLevel,
        //   dragging: true,
        // });

        if (this.mainLayer) this.mainLayer.addTo(this.map);
        // if (this.mainLayer) this.mainLayer.addTo(this.map2);
        if (this.usersStatic)
          this.usersStatic.forEach((user, i) => {
            if (
              user.ultimaPosizione &&
              user.ultimaPosizione.timestamp &&
              user.ultimaPosizione.timestamp < new Date().getTime() - 18000000
            ) {
              this.usersStatic[i].counter = -1;
            } else {
              this.usersStatic[i].counter = this.i;
            }
            this.addMarker(user.latitudine, user.longitudine, user.counter);
          });
        this.firstTime = true;
        this.mappaCaricata.emit(true);
      },
      (error) => {
        this.mappaCaricata.emit(true);
        this.alertService.error(
          'Per continuare, è necessario fornire i permessi di geolocalizzazione'
        );
        this.router.navigate(['']);
      }
    );
  }

  addMarker(cordinate1, cordinate2, contatore, option?, lunghezza?) {
    if (lunghezza === 1) {
      this.i = 99;
    }
    let markerHtmlStyles;
    if (contatore >= 0) {
      markerHtmlStyles = `
      color: ${this.colorArray[contatore]};
      font-size: xx-large;
      display: block;
      left: -1.5rem;
      top: -1.5rem;
      position: relative;
      -webkit-text-stroke: 1px black;
      `;
    } else {
      markerHtmlStyles = `
      color: gray;
      font-size: xx-large;
      display: block;
      left: -1.5rem;
      top: -1.5rem;
      position: relative;
      -webkit-textstroke: 1px black;
      `;
    }
    
    let smallIcon =  L.divIcon({
      className: 'my-custom-pin',
      iconAnchor: [0, 24],
      popupAnchor: [0, -36],
      html: `<i class="material-icons notranslate" style="${markerHtmlStyles}">place</i>`,
    });
    let marker;
    if (option) {
      marker = L.marker([cordinate1, cordinate2], { icon: smallIcon })
        .addTo(this.map)
        .bindPopup(option)
        .openPopup();
    } else {
      marker = L.marker([cordinate1, cordinate2], {
        icon: smallIcon,
      });
      if (marker) marker.addTo(this.map);
    }
    this.markers.push(marker);
    if (lunghezza) {
      this.i = this.i + (Math.floor(100 / lunghezza) + 1);
      if (lunghezza === 1) {
        this.i = 99;
      }
      if (this.i > 99) {
        this.i = 99;
      }
    }
    
  }

  cerchio;

  addMarker2(cordinate1, cordinate2, contatore) {
    if (this.open) {
      let markerHtmlStyles;
      if (contatore >= 0) {
        markerHtmlStyles = `
        color: ${this.colorArray[contatore]};
        font-size: xx-large;
        display: block;
        left: -1.5rem;
        top: -1.5rem;
        position: relative;
        -webkit-text-stroke: 1px black;
        `;
      } else {
        markerHtmlStyles = `
        color: gray;
        font-size: xx-large;
        display: block;
        left: -1.5rem;
        top: -1.5rem;
        position: relative;
        -webkit-text-stroke: 1px black;
        `;
      }
      
      let smallIcon = L.divIcon({
        className: 'my-custom-pin',
        iconAnchor: [0, 24],
        popupAnchor: [0, -36],
        html: `<i class="material-icons notranslate" style="${markerHtmlStyles}">place</i>`,
      });
      let marker = L.marker([cordinate1, cordinate2], {
        icon: smallIcon,
      });
      if (marker) marker.addTo(this.map);
      if (this.idUserOpen && this.listaBeacons) {
        let utente = this.usersStatic.filter((res) => {
          if (this.idUserOpen === res.id) return res;
        });
        if (this.cerchio) {
          this.map.removeLayer(this.cerchio);
        }
        this.cerchio = undefined;
        if (this.map.getZoom() > 12 && !this.showUser)
          this.cerchio = L.circle(
            [utente[0].latitudine, utente[0].longitudine],
            100
          ).addTo(this.map);
      }
      this.markers.push(marker);
      
    }
  }

  getRandomColor() {
    var color = this.colorArray[this.i];

    return color;
  }

  zoom(lat, lng) {
    // var latlng = L.latLng(lat, lng);
    // this.map.setView(latlng, this.map.getZoom());
    this.map.flyTo([lat, lng]);
  }

  deleteCerchio() {
    if (this.cerchio) {
      this.map.removeLayer(this.cerchio);
    }
    this.cerchio = undefined;
  }
  //#MAPPAL
  onMapReady(map:any /*Map*/) {
    this.map = map;
  }
  listaChiamateTracciamento;
  tracciamento(id, nome, device?) {
    this.deleteCerchio()
    if (!device) {
      this.nome = nome;
      this.open = false;
      let lista: string[] = new Array();
      lista.push(id);
      let date = new Date();
      let giorno = date.getDate().toString();
      if (Number(giorno) < 10) {
        giorno = '0' + giorno
      }
      let mese = (date.getMonth() + 1).toString();
      if (Number(mese) < 10) {
        mese = '0' + mese;
      }
      let anno = date.getFullYear();
      this.fileService
        .inviaDatiReport2(lista, giorno, mese, anno, giorno, mese, anno)
        .subscribe(
          (res) => {
            this.traccia = true;
            this.colorArray = this.colorArray2;
            this.i = 0;
            this.markers.forEach((marker) => {
              this.map.removeLayer(marker);
            });
            this.markers = [];
            let primoRisultato = true;

            res.data.forEach((posizione, index) => {
              if (primoRisultato) {
                this.zoom(posizione.latitudine, posizione.longitudine);
              }
              primoRisultato = false;
              let ora = posizione.createdAt.split(' ');
              let option =
                'Indirizzo: ' +
                posizione.indirizzo +
                ', ' +
                posizione.capComune +
                ', Ora: ' +
                ora[1];
              this.addMarker(
                posizione.latitudine,
                posizione.longitudine,
                index,
                option,
                res.data.length
              );
            });
          },
          (error) => {
            this.open = true;
            this.alertService.warn('Tracciamento non disponibile');
          }
        );
    } else {
      this.nome = nome;
      this.open = false;
      let today = new Date();
      let startOfToday = new Date(
        today.getTime() - (today.getTime() % 86400000)
      );
      this.fileService
        .downloadGeoLocation(
          id,
          Math.trunc(startOfToday.getTime() / 1000),
          Math.trunc(today.getTime() / 1000)
        )
        .subscribe(
          (res) => {
            this.listaChiamateTracciamento = res.data;
            this.traccia = true;
            this.colorArray = this.colorArray2;
            this.i = 0;
            this.markers.forEach((marker) => {
              this.map.removeLayer(marker);
            });
            this.markers = [];
            let primoRisultato = true;
            res.data.forEach((posizione, index) => {
              if (primoRisultato) {
                this.zoom(posizione.latitudine, posizione.longitudine);
              }
              primoRisultato = false;
              let ora = posizione.timestamp.split(' ');
              let option =
                'Indirizzo: ' +
                posizione.indirizzo +
                ', ' +
                posizione.capComune +
                ', Ora: ' +
                ora[1];
              this.addMarker(
                posizione.latitudine,
                posizione.longitudine,
                index,
                option,
                res.data.length
              );
            });
          },
          (error) => {
            this.open = true;
            this.alertService.warn('Tracciamento non disponibile');
          }
        );
    }
  }

  resetStateTooltip() {
    this.state.forEach((element, i) => {
      this.state[i] = 'default';
    });
  }

  stopTracciamento() {
    this.colorArray = this.colorArray1;
    this.i = 0;
    this.markers.forEach((marker) => {
      this.map.removeLayer(marker);
    });
    this.markers = [];
    let primaVolta = true;
    this.cords.forEach((user) => {
      user.counter = this.i;
      if (primaVolta) {
        this.zoom(
          user.ultimaPosizione.latitudine,
          user.ultimaPosizione.longitudine
        );
      }
      primaVolta = false;
      this.addMarker(
        user.ultimaPosizione.latitudine,
        user.ultimaPosizione.longitudine,
        user.counter
      );
    });
    this.open = true;
    this.traccia = false;
  }

  formatBeaconData(beacons) {
    let returnList = [];
    beacons.forEach((res) => {
      let returnObj: any = {};
      returnObj.Nome = res.device.deviceName ? res.device.deviceName : '';
      returnObj.attivo = res.active ? 'Si' : 'No';
      returnObj.Precisione = res.rssi ? res.rssi : '';
      returnList.push(returnObj);
    });
    return returnList;
  }

  getFullDate(d: Date) {
    return (
      d.getDate() +
      '/' +
      (d.getMonth() + 1) +
      '/' +
      d.getFullYear() +
      ' ' +
      d.getHours() +
      ':' +
      d.getMinutes() +
      ':' +
      d.getSeconds()
    );
  }

  formatLocationData(beacons) {
    let returnList = [];
    beacons.forEach((res) => {
      let returnObj: any = {};
      returnObj.Indirizzo = res.indirizzo ? res.indirizzo : '';
      returnObj.Latitudine = res.latitudine ? res.latitudine : '';
      returnObj.Longitudine = res.longitudine ? res.longitudine : '';
      returnObj.CAP = res.capComune ? res.capComune : '';
      returnObj.Accuratezza = res.accuracy ? res.accuracy : '';
      returnObj.Extra = '';
      if (res.extra) returnObj.Extra = res.extra.LocationType;
      returnObj.Data = this.getFullDate(new Date(res.timestamp));
      returnList.push(returnObj);
    });
    return returnList;
  }

  exportToExcel(user) {
    let dataForExcel: any = {};

    this.userService.getBeaconsTracker(user.id).subscribe((res) => {
      dataForExcel.Beacons = this.formatBeaconData(res.data);
      let start = new Date();
      let end = new Date();
      if(this.fromDay === this.toDay && this.fromMonth === this.toMonth && this.fromYear === this.toYear) {
        start.setDate(Number(this.fromDay));
        start.setMonth(Number(this.fromMonth) - 1);
        start.setFullYear(Number(this.fromYear));
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(1);
        end.setDate(Number(this.toDay));
        end.setMonth(Number(this.toMonth) - 1);
        end.setFullYear(Number(this.toYear));
      } else {
        start.setDate(Number(this.fromDay));
        start.setMonth(Number(this.fromMonth) - 1);
        start.setFullYear(Number(this.fromYear));
        end.setDate(Number(this.toDay) + 1);
        end.setMonth(Number(this.toMonth) - 1);
        end.setFullYear(Number(this.toYear));
      }

      this.fileService
        .downloadGeoLocation(
          user.id,
          Math.trunc(start.getTime() / 1000),
          Math.trunc(end.getTime() / 1000)
        )
        .subscribe(
          async (res2) => {
            this.zoom(user.latitudine, user.longitudine);
            this.clearMarkers();
            if (this.cerchio) {
              this.map.removeLayer(this.cerchio);
            }
            this.cerchio = undefined;
            this.addMarker2(user.latitudine, user.longitudine, 0);
            let node = document.getElementById('map');
            let context = this;
            htmlToImage.toJpeg(node).then(function (dataUrl) {
              dataForExcel.dataUrl = dataUrl;
              dataForExcel.location = context.formatLocationData(res2.data);
              let reportData = {
                title: 'Report Dispositivo: ' + user.nome,
                data: dataForExcel,
                headers: [
                  'Indirizzo',
                  'Latitudine',
                  'Longitudine',
                  'CAP',
                  'Accuratezza',
                  'Extra',
                  'Data',
                ],
                user: user,
              };
              context.excelService.exportExcel(reportData, [
                'Beacons',
                'location',
              ]);

              context.changeStatusToFalseCalendar();
            });
          },
          (error) => {
            this.alertService.error('Inserisci delle date valide');
          }
        );
    });
  }

  clearMarkers() {
    if (this.open) {
      this.i = 0;
      this.markers.forEach((marker) => {
        this.map.removeLayer(marker);
      });
      this.markers = [];
    }
  }

  debugUserlist()
  {
    console.log(this.usersStatic);
  }

  changeRenderedList(id) //usato per alleggerire la pagina
  {
    if(id!=this.selectedPanel)
    {
      if(id!="home" && this.traccia)
      {
        if((this.selectedPanel=="devices" && id=="users") || (this.selectedPanel=="users" && id=="devices"))
        {
          this.stopTracciamento();
        }
      }
      this.selectedPanel = id;
      this.spinner=true;
      this.clearMarkers();
      this.resetStateTooltip();
      this.changeStatusToFalseCalendar();
      this.deleteCerchio();
      this.cords=[];
      this.usersStatic=[];

      if(id=='home')
      {
        this.showUser = false;
        this.chiamataUtenti();
      }

      else if(id=='users')
      {
        this.showUser = true;
        this.chiamataUtenti();
      }

      else if(id=='devices')
      {
        this.showUser = false;
        this.chiamataUtenti();
      }
    }
  }
}
