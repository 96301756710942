import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtentiSelezionati } from 'src/app/MODELS/BADGE/utenti-selezionati';
import { EnvService } from '../GENERAL/env.service';

@Injectable({
  providedIn: 'root',
})
export class ClienteService {
  baseUrl: String;
  constructor(private env: EnvService, private http: HttpClient) {
    this.baseUrl = this.env.apiUrl;
  }

  //Ottiene la lista completa dei clienti
  getAll(size?, page?, ricerca?) {
    if (size && (page || page === 0)) {
      if (ricerca) {
        return this.http.get<any>(
          this.baseUrl +
            '/cliente/lista_assistiti?size=' +
            size +
            '&page=' +
            page +
            '&attivo=true' +
            '&ricerca=' +
            ricerca
        );
      }
      return this.http.get<any>(
        this.baseUrl +
          '/cliente/lista_assistiti?size=' +
          size +
          '&page=' +
          page +
          '&attivo=true'
      );
    } else if (ricerca) {
      return this.http.get<any>(
        this.baseUrl +
          '/cliente/lista_assistiti?ricerca=' +
          ricerca +
          '&attivo=true'
      );
    } else {
      return this.http.get<any>(
        this.baseUrl + '/cliente/lista_assistiti?attivo=true'
      );
    }
  }

  //Ottiene le informazioni di un singolo cliente
  getInfoCliente(id) {
    return this.http.get<any>(this.baseUrl + '/cliente/' + id);
  }

  //Ottiene la lista completa dei clienti eliminati
  getAllEliminati(size, page, ricerca) {
    if (ricerca) {
      return this.http.get<any>(
        this.baseUrl +
          '/cliente/lista_assistiti?size=' +
          size +
          '&page=' +
          page +
          '&attivo=false' +
          '&ricerca=' +
          ricerca
      );
    }
    return this.http.get<any>(
      this.baseUrl +
        '/cliente/lista_assistiti?size=' +
        size +
        '&page=' +
        page +
        '&attivo=false'
    );
  }

  //POST
  //Crea nuovo cliente
  addCliente(cliente) {
    return this.http.post<any>(this.baseUrl + '/cliente/add', cliente);
  }

  //Modifica cliente esistente
  editCliente(id, cliente) {
    return this.http.post<any>(
      this.baseUrl + '/cliente/update?idCliente=' + id,
      cliente
    );
  }

  //Cestina/Ripristina cliente
  deleteCliente(id, elimina) {
    return this.http.post<any>(
      this.baseUrl +
        '/cliente/eliminaCliente?idCliente=' +
        id +
        '&elimina=' +
        elimina,
      null
    );
  }

  getListaServizi() {
    return this.http.get<any>(this.baseUrl + '/servizi/AllServizi');
  }

  getListaEquipe(id) {
    return this.http.get<any>(
      this.baseUrl + '/cliente/assistito/' + id + '/lista_equipe'
    );
  }

  sendList(idAssistito, equipeList) {
    return this.http.post<any>(
      this.baseUrl + '/cliente/assistito/' + idAssistito + '/condividi_con',
      equipeList
    );
  }

  checkPermission(id) {
    return this.http.get<any>(
      this.baseUrl + '/cliente/assistito/' + id + '/permessi'
    );
  }

  getStorico(id, data_inizio?, data_fine?) {
    if (data_inizio && data_fine) {
      return this.http.post<any>(
        this.baseUrl +
          '/cliente/storico_assistiti?id_cliente=' +
          id +
          '&data_inizio=' +
          data_inizio +
          '&data_fine=' +
          data_fine,
        null
      );
    }

    return this.http.post<any>(
      this.baseUrl + '/cliente/storico_assistiti?id_cliente=' + id,
      null
    );
  }

  getStoricoForExcel(listaId: String[], data_inizio?, data_fine?) {
    // let payload: UtentiSelezionati;
    // payload = { reportUsers: listaId };
    return this.http.post<any>(
      this.baseUrl +
        '/cliente/storico_assistiti?&data_inizio=' +
        data_inizio +
        '&data_fine=' +
        data_fine +
        '&report=true',
      listaId
    );
  }
}
