<div class="content" >
    <div class="container-fluid">
  <ng-container *ngIf="report">
     <div class="col-xs-12">


    <ng-container *ngIf="selezionaUtente">
      <h4>Seleziona gli utenti</h4>

          <div id="datatables_filter" class="dataTables_filter">
              <form [formGroup]="searchform">
                <label class="form-group" style="width: 100%;">
                  <input  type="text" formControlName="search" class="form-control" placeholder="Cerca..." maxlength="30" >
                </label>
                </form>
                <div *ngIf="searchInUser()"></div>
              </div>
              <div class="table-responsive scrollable">

      <p-table class="table table-striped " [value]="users" [(selection)]="selectedUsers3" dataKey="email" >

          <ng-template pTemplate="header">
              <tr>
                  <th style="width: 3rem !important" class="pl-6">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th >Nome</th>
                  <th >Cognome</th>
                  <th style="width: 30%;">Email</th>
                  <th>Gruppo</th>
              </tr>
          </ng-template>

          <ng-template pTemplate="body" let-user>
              <tr>
                  <td style="width: 3rem !important" class="pl-6">
                      <p-tableCheckbox [value]="user" ></p-tableCheckbox>
                  </td>
                  <td class="one-line"><span>{{user.nome}}</span></td>
                  <td class="one-line"><span>{{user.cognome}}</span></td>
                  <td style="width: 30%;" class="one-line"><span>{{user.email}}</span></td>
                  <td class="one-line">
                    <span>
                      <ng-container *ngFor="let gruppo of user.gruppos; let i= index">
                        <ng-container *ngIf="i <= user.gruppos.length-2">{{gruppo?.nomeGruppo + ', '}}</ng-container>
                        <ng-container *ngIf=" i === user.gruppos.length-1">{{gruppo?.nomeGruppo}}</ng-container>
                      </ng-container>
                    </span>
                  </td>

              </tr>

          </ng-template>

      </p-table>

    </div>


      <div class="text-right">
        <a  class="btn btn-primary" (click)="avanti()" style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Avanti</a>
      </div>
      <!-- <ng-template pTemplate="summary"> -->

    <!-- </ng-template> -->
      </ng-container>
    </div>

      <div *ngIf="selezionaDataReport">
        <div class="col-xs-12" >

        <div class="card-content" class="text-center" style="margin-bottom: 3%;">
           <h4 style="margin-bottom: 3%;">Seleziona la data</h4>
          <!--<form [formGroup]="calendar" > -->
      <!-- <p-calendar [(ngModel)]="value1" [numberOfMonths]="2" [inline]="true" (onSelect)="onDateSelection($event)" ></p-calendar> -->
    <!-- </form> -->
    <form [formGroup]="calendar" class="box-model form-support-margin overflowAuto">
    <p-calendar
      view="date"
      formControlName="calendario"
      (onSelect)="selezionaData()"
      [maxDate]="today"
      [readonlyInput]="true"
      [numberOfMonths]="2"
      yearRange="2000:2030"
      [(ngModel)]="rangeDates"
      [showOtherMonths]="false"
      placeholder="Select time period"
      selectionMode="range"
      [inline]="true"
      dateFormat="dd/mm/yy"
      [locale]="ita">

  </p-calendar>
  <div class="card-footer text-right">
    <a  class="btn btn-secondary" (click)="indietro()" style="color: white;">Indietro</a>
    <button [disabled]="loading2"  class="btn btn-primary"  rel ="tooltip"  title="Scarica Report" (click)="inviaDati()" style="max-width: 2%;"><i class="fa fa-folder" ><span *ngIf="loading2"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span></i>

  </button>



      </div>
  </form>
    </div>


  </div>
  </div>
  </ng-container>
  </div>
  </div>
<!--------------------------------- mappa report -------------------------------->
<div *ngIf="visioneMappa && !caricato">
  <div class="text-center" >
      <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
      styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
  </div>
</div>


<div *ngIf="visioneMappa && caricato" class="card-title">
  <button (click)="indietroMappa()" id="back-button" class="btn btn-round  btn-just-icon"
      style="margin-top: 3px; color: rgb(54, 54, 54); box-shadow:none!important">
      <i class="material-icons notranslate">arrow_back</i>
  </button>
  <h4 *ngIf="visioneMappa" style="display: inline;"> Report dal {{fromDay}}/{{fromMonth}}/{{fromYear}} al {{toDay}}/{{toMonth}}/{{toYear}}</h4>
</div>
<div *ngIf="visioneMappa  && caricato" class=" shadow ">
<div class="card-header">
  <div *ngIf="traccia" class="colorPalette"><span style="color: white;font-weight: bold; margin-right: 20px; font-size: small">MENO RECENTE</span><span style="color: white;font-weight: bold; font-size: small; margin-left: 20px;">PIÙ RECENTE</span></div>
  <div class="row" style="margin-right: -5px; margin-left: -5px;">
    <div style="z-index: 0;" class="col-xs-8">
      <div class="map3" id="map" (leafletMapReady)="onMapReady($event)" [ngClass]="{notMapFullScreen: !fullScreenMap}">
      </div>
    <!--  #MAPPAL
      <leaflet-fullscreen-control [map]="map"></leaflet-fullscreen-control>
    -->
    </div>

    <div class="col-xs-4">
      <form [formGroup]="searchformMappa" *ngIf="!fullScreenMap">

          <input type="text" formControlName="searchMappa" class="form-control" placeholder="Cerca..."
           style="width: 100%;" maxlength="30">

      </form>
      <div *ngIf="searchInUserMappa()"></div>
      <div class="table-responsive scroll" style="overflow-x: hidden; max-height: 450px;">
        <table class="table table-no-bordered table-hover" *ngIf="!fullScreenMap">
          <thead>
            <tr *ngIf="usersStatic?.length < 1">Nessun utente online</tr>
            <tr style="display: none;">
              <th style="width: 1%;"></th>
              <th style="width: 79%"></th>
              <th style="width: 20%"></th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let user of usersStatic; let j= index" id = j [ngClass]="{active: user.id === idUserSelected}" >
              <td style="width: 79%;">
            <tr style="background-color: transparent!important; text-align: left;">{{user.nome}} {{user.cognome}}</tr>
            <tr style="background-color: transparent!important;">

          </tr>
            <tr style="background-color: transparent!important;"><span
                *ngFor="let gruppo of user.gruppos; let i= index">

              </span></tr>
            </td>


            <td style="white-space: nowrap; width: 10%;">

                  <a class="track" style="color: greenyellow;" (click)="tracciamento(user.id, user.nome + ' ' + user.cognome, user, j)"><i
                    class="material-icons notranslate">near_me</i></a>
            </td>

            </tr>

            <tr *ngIf="!usersStatic">
              <td colspan="4" class="text-center">
                <span class="spinner-border spinner-border-lg align-center"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</div>




