import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Cliente } from 'src/app/MODELS/USER/cliente';
import { ClienteService } from 'src/app/SERVICES/CLIENTE/cliente.service';

@Component({
  selector: 'app-dettagli-cliente',
  templateUrl: './dettagli-cliente.component.html',
  styleUrls: ['./dettagli-cliente.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(90deg)' })),
      transition('rotated => default', animate('120ms ease-out')),
      transition('default => rotated', animate('120ms ease-in')),
    ]),
  ],
})
export class DettagliClienteComponent implements OnInit {
  @Output() cambia = new EventEmitter<boolean>();
  @Input() clienteInfo;
  dettaglioCliente: Cliente = {};
  showAltraAssociazione = false;
  showAltroServizio = false;
  tipologieFullString = '';
  tipologieLaboratoriInterventiFullString = '';
  obiettiviGeneraliFullString = '';
  stato = ['default'];

  constructor(private clienteService: ClienteService) {}

  ngOnInit(): void {
    this.clienteService.getInfoCliente(this.clienteInfo).subscribe((res) => {
      // console.log(res)
      this.dettaglioCliente.nome = res.nome;
      this.dettaglioCliente.cognome = res.cognome;
      this.dettaglioCliente.codiceFiscale = res.codiceFiscale;
      this.dettaglioCliente.eta = res.eta;
      this.dettaglioCliente.sesso = res.sesso;
      this.dettaglioCliente.citta = res.citta;
      this.dettaglioCliente.occupazione = res.occupazione;
      this.dettaglioCliente.livelloDiIstruzione = res.livelloDiIstruzione;
      this.dettaglioCliente.situazioneEconomica = res.situazioneEconomica;
      this.dettaglioCliente.diagnosi = res.diagnosi;
      this.dettaglioCliente.annoDiPresaInCaricoDalServizio =
        res.annoDiPresaInCaricoDalServizio;
      this.dettaglioCliente.infoAltraAssociazione = res.infoAltraAssociazione;
      this.dettaglioCliente.altraAssociazione = res.altraAssociazione;
      this.dettaglioCliente.infoCliente = res.infoCliente;
      this.dettaglioCliente.altroServizio = res.altroServizio;
      this.dettaglioCliente.serviziDiAppartenenza = res.serviziDiAppartenenza;
      this.dettaglioCliente.tipologiaAttivita = this.getListFromArray(
        res.tipologiaAttivita,
        'nomeAttivita'
      );
      this.tipologieFullString = this.getFullString(
        this.dettaglioCliente.tipologiaAttivita
      );
      this.dettaglioCliente.tipologiaLaboratoriInterventi =
        this.getListFromArray(
          res.tipologiaLaboratoriInterventi,
          'nomeLaboratoriInterventi'
        );
      this.tipologieLaboratoriInterventiFullString = this.getFullString(
        this.dettaglioCliente.tipologiaLaboratoriInterventi
      );
      this.dettaglioCliente.partenariati = res.partenariati;
      this.dettaglioCliente.inviante = res.inviante;
      this.dettaglioCliente.note = res.note;
      this.dettaglioCliente.analisiBisogni = res.analisiBisogni;
      this.dettaglioCliente.analisiRisorse = res.analisiRisorse;
      this.dettaglioCliente.obiettiviGenerali = this.getListFromArray(
        res.obiettiviGenerali,
        'nomeObiettivo'
      );
      this.obiettiviGeneraliFullString = this.getFullString(
        this.dettaglioCliente.obiettiviGenerali
      );
      this.dettaglioCliente.obiettiviSpecifici = res.obiettiviSpecifici;
      this.dettaglioCliente.metodologiaSpecifica = res.metodologiaSpecifica;
      this.dettaglioCliente.obiettiviRaggiunti = res.obiettiviRaggiunti;
      this.dettaglioCliente.descrizioneObiettivi = res.descrizioneObiettivi;
    });
  }

  getListFromArray(element, key) {
    let array = [];
    element.forEach((element) => {
      array.push(element[key]);
    });
    return array;
  }

  getFullString(array: any[]): string {
    let fullString = '';
    array.forEach((el, index) => {
      if (index === array.length - 1) fullString = fullString += el;
      else fullString = fullString += el + ', ';
    });

    return fullString;
  }

  indietro(chiudi: boolean) {
    this.cambia.emit(chiudi);
  }

  checkInfoAssociazione() {
    if (this.dettaglioCliente.infoAltraAssociazione === 'si') {
      this.showAltraAssociazione = true;
    }
  }

  checkInfoServizi() {
    if (this.dettaglioCliente.infoCliente === 'si') {
      this.showAltroServizio = true;
    }
  }

  showAzioni(i) {
    this.stato[i] = this.stato[i] === 'default' ? 'rotated' : 'default';
  }
}
