export class CartellaFiglia {
  id:number;
  nome:string;
  persistent_folder: boolean;

  constructor(id, nome, persistent = false)
  {
      this.id = id;
      this.nome = nome;
      this.persistent_folder = persistent;
  }
}


