<ng-container>
  <div style="display:flex">
    <button (click)="indietro()" id="back-button" type="button" class="btn btn-round  btn-just-icon bottoneBack">
      <i class="material-icons notranslate">arrow_back</i>
    </button>
    <h4 class="card-title centered">Crea Evento</h4>
  </div>

  <mat-form-field appearance="fill" *ngIf="!modifica">
    <mat-label>Tipologia</mat-label>
    <mat-select [(ngModel)]="inSede" (selectionChange)="changeSelect()">
      <mat-option [value]="true">In sede</mat-option>
      <mat-option [value]="false">Fuori sede</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Inizio Form Evento Sede -->
  <form *ngIf="inSede" [formGroup]="formEventoSede" (ngSubmit)="onSubmit(true)" class="form-horizontal">
    <!-- Data -->
    <div class="row">
      <label class="col-sm-2 label-on-left">
        <span class="ml-none"><strong>Data</strong></span>

      </label>
      <div class="col-sm-5">
        <div class="form-group label-floating is-empty">
          <label class="control-label"></label>
          <input type="text" [disabled]="true" [value]="formattaData(selectedDate)">
        </div>
      </div>
    </div>
    <!-- Titolo -->
    <div *ngIf="!modifica" class="row">
      <label class="col-sm-2 label-on-left">
        <span class="ml-none"><strong>Titolo</strong><span class="colorRed">*</span></span>

      </label>
      <div class="col-sm-5">
        <div class="form-group label-floating is-empty">
          <label class="control-label"></label>
          <input type="text" formControlName="titoloEvento" class="form-control">
          <div *ngIf="submittedSede && fs.titoloEvento.errors" class="invalid-feedback">
            <div *ngIf="fs.titoloEvento.errors.required">
              Campo nome obbligatorio
            </div>
            <div *ngIf="fs.titoloEvento.errors.pattern">
              Campo nome non valido
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Posti disponibili -->
    <div class="row">
      <label class="col-sm-2 label-on-left"><strong>Posti disponibili</strong><span class="colorRed">*</span></label>
      <div class="col-sm-5">
        <div class="form-group label-floating is-empty">
          <label class="control-label"></label>
          <input type="number" formControlName="postiDisponibili" class="form-control" min="1" />
          <div *ngIf="submittedSede && fs.postiDisponibili.errors" class="invalid-feedback">
            <div *ngIf="fs.postiDisponibili.errors.required">
              Campo posti disponibili obbligatorio
            </div>
            <div *ngIf="fs.postiDisponibili.errors.min">
              Prevedere almeno un posto per l'evento
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Orari -->
    <div *ngIf="!modifica" class="row">
      <label class="col-sm-2 label-on-left"><strong>Orari</strong><span class="colorRed">*</span></label>
      <div class="col-sm-5">
        <div class="form-group label-floating is-empty">
          <label class="control-label"></label>
          <mat-form-field class="ml-auto">
            <input matInput type="time" placeholder="Inizio" formControlName="oraInizio">
          </mat-form-field>

          <mat-form-field style="margin-left:5%">
            <input matInput type="time" placeholder="Fine" formControlName="oraFine">
          </mat-form-field>


          <div *ngIf="submittedSede && (fs.oraInizio.errors || fs.oraFine.errors)" class="invalid-feedback">
            <div *ngIf="fs.oraInizio.errors.required">
              Campo ora inizio obbligatorio
            </div>
            <div *ngIf="fs.oraFine.errors.required">
              Campo ora fine obbligatorio
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Form associato -->
    <div *ngIf="!modifica" class="row">
      <label class="col-sm-2 label-on-left">
        <span class="ml-none"><strong>Form associato</strong><span class="colorRed">*</span></span>

      </label>
      <div class="col-sm-5">
        <div class="form-group label-floating is-empty">
          <label class="control-label"></label>
          <select formControlName="formId" class="form-control">
            <option *ngFor="let form of listaForm" [value]="form.id">{{form.name}}</option>
          </select>
          <div *ngIf="submittedSede && fs.formId.errors" class="invalid-feedback">
            <div *ngIf="fs.formId.errors.required">
              Campo form associato obbligatorio
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Descrizione (provvisorio)-->
    <div class="row">
      <label class="col-sm-2 label-on-left">
        <span class="ml-none"><strong>Descrizione</strong><span class="colorRed">*</span></span>
      </label>
      <div class="col-sm-5">
        <div class="form-group label-floating is-empty">
          <label class="control-label"></label>
          <!-- <input type="text" formControlName="descrizione" class="form-control"> -->

          <div class="grid">
            <div class="col-6">

              <div class="box">
                <p-editor formControlName="descrizione" [style]="{'height':'320px'}"></p-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Taglia -->
    <!-- <div class="row">
              <label class="col-sm-2 label-on-left">
                <span class="ml-none"><strong>Taglia gadget</strong></span>

              </label>
              <div class="col-sm-5">
                <div class="form-group label-floating is-empty">
                  <label class="control-label"></label>
                  <select formControlName="taglia" class="form-control">
                    <option *ngFor="let size of sizes" [value]="size.id">{{size.size}}</option>
                  </select>
                </div>
              </div>
            </div> -->

    <!-- Copertina -->
    <div class="row">
      <label class="col-sm-2 label-on-left"><strong>Copertina</strong></label>
      <div class="col-sm-5">
        <div class="form-group label-floating is-empty">
          <input style="display: none" type="file" (input)="copertinaCaricata($event)" #fileInputCopertina>
          <button type="button" class="btn btn-primary" style="margin-top: -3px;"
            (click)="fileInputCopertina.click()">Carica</button>
          <button *ngIf="fs.copertina.value" type="button" class="btn btn-primary" style="margin-top: -3px;"
            (click)="rimuoviCopertina()">Rimuovi</button>
          <img *ngIf="eventoSelezionato && eventoSelezionato.datiEventoInterno.linkPhoto && !copertinaRimossa"
            src="{{fs.copertina.value}}" class="fototessera">
          <p *ngIf="fs.copertina.value">{{fs.copertina.value.name}}</p>
        </div>
      </div>
    </div>
    <div class="flex">
      <button class="btn btn-primary" (click)="indietro()" type="button">Indietro</button>
      <button class="btn btn-primary ml-auto" type="submit">Salva</button>
    </div>

  </form>
  <!-- Fine Form Evento Sede -->

  <!-- Inizio Form Evento Esterno -->
  <mat-horizontal-stepper *ngIf="inSede===false" orientation="horizontal" #stepper (selectionChange)="canGoToStep2($event)">
    <mat-step>

      <ng-template matStepLabel>Dati Principali</ng-template>
      <form [formGroup]="formEventoEsterno" class="form-horizontal">
        <!-- Data -->
        <div class="row">
          <label class="col-sm-2 label-on-left">
            <span class="ml-none"><strong>Data</strong></span>

          </label>
          <div class="col-sm-5">
            <div class="form-group label-floating is-empty">
              <label class="control-label"></label>
              <input type="text" [disabled]="true" [value]="formattaData(selectedDate)">
            </div>
          </div>
        </div>
        <!-- Titolo -->
        <div class="row" *ngIf="!modifica">
          <label class="col-sm-2 label-on-left">
            <span class="ml-none"><strong>Titolo</strong><span class="colorRed">*</span></span>

          </label>
          <div class="col-sm-5">
            <div class="form-group label-floating is-empty">
              <label class="control-label"></label>
              <input type="text" formControlName="titoloEvento" class="form-control">
              <div *ngIf="submittedEsterno && fe.titoloEvento.errors" class="invalid-feedback">
                <div *ngIf="fe.titoloEvento.errors.required">
                  Campo nome obbligatorio
                </div>
                <div *ngIf="fe.titoloEvento.errors.pattern">
                  Campo nome non valido
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Orari -->
        <div *ngIf="!modifica" class="row">
          <label class="col-sm-2 label-on-left"><strong>Orari</strong><span class="colorRed">*</span></label>
          <div class="col-sm-5">
            <div class="form-group label-floating is-empty flex">
              <label class="control-label"></label>
              <mat-form-field class="ml-auto">
                <input matInput type="time" placeholder="Inizio" formControlName="oraInizio">
              </mat-form-field>

              <mat-form-field style="margin-left:5%">
                <input matInput type="time" placeholder="Fine" formControlName="oraFine">
              </mat-form-field>


            </div>
            <div *ngIf="submittedEsterno && (fe.oraInizio.errors || fe.oraFine.errors)" class="invalid-feedback">
              <div *ngIf="fe.oraInizio.errors.required">
                Campo ora inizio obbligatorio
              </div>
              <div *ngIf="fe.oraFine.errors.required">
                Campo ora fine obbligatorio
              </div>
            </div>
          </div>
        </div>


        <div class="flex">
          <button class="btn btn-primary" (click)="indietro()" type="button">Indietro</button>
          <button class="btn btn-primary ml-auto" matStepperNext>Avanti</button>
        </div>


      </form>
      <!-- Fine Form Evento Esterno -->

    </mat-step>
    <mat-step>

      <ng-template matStepLabel>Dati Tecnico</ng-template>
      <form [formGroup]="formEventoEsterno" (ngSubmit)="onSubmit(false)" class="form-horizontal">
        <!-- Form associato -->
        <div *ngIf="!modifica" class="row flex">
          <label class="col-sm-2 label-on-left center">
            <span class="ml-none"><strong>Form associato</strong><span class="colorRed">*</span></span>

          </label>
          <div class="col-sm-5">
            <div class="form-group label-floating is-empty">


              <mat-form-field appearance="">
                <mat-label>Scegli Form</mat-label>
                <mat-select formControlName="formId">
                  <mat-option *ngFor="let form of listaForm" [value]="form.id">{{form.name}}</mat-option>
                </mat-select>
              </mat-form-field>


              <!-- <label class="control-label"></label>
              <select formControlName="formId" class="form-control">
                <option *ngFor="let form of listaForm" [value]="form.id">{{form.name}}</option>
              </select> -->
              <div *ngIf="submittedEsterno && fe.formId.errors" class="invalid-feedback">
                <div *ngIf="fe.formId.errors.required">
                  Campo form associato obbligatorio
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Tecnico (provvisorio)-->
        <div class="row flex">
          <label class="col-sm-2 label-on-left center">
            <span class="ml-none"><strong>Tecnico</strong><span class="colorRed">*</span></span>

          </label>
          <div class="col-sm-5">
            <div class="form-group label-floating is-empty">

              <mat-form-field appearance="">
                <mat-label>Scegli tecnico</mat-label>
                <mat-select
                 formControlName="tecnicoId">
                  <mat-option *ngFor="let tecnico of listaTecnici" [value]="tecnico.id">{{tecnico.nomeTecnico + " " +
                    tecnico.cognomeTecnico}}</mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <label class="control-label"></label>
              <select formControlName="tecnicoId" class="form-control">
                <option *ngFor="let tecnico of listaTecnici" [value]="tecnico.id">{{tecnico.nomeTecnico + " " +
                  tecnico.cognomeTecnico}}</option>
              </select> -->
              <div *ngIf="submittedEsterno && fe.tecnicoId.errors" class="invalid-feedback">
                <div *ngIf="fe.tecnicoId.errors.required">
                  Campo tecnico obbligatorio
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <button class="btn btn-primary" matStepperPrevious>Indietro</button>
          <button class="btn btn-primary ml-auto" type="submit">Salva</button>
        </div>


      </form>
      <!-- Fine Form Evento Esterno -->

    </mat-step>
  </mat-horizontal-stepper>




  <!--
          <p>Test valori</p>
          Titolo: {{fs.titoloEvento.value}}
          Posti disponibili: {{fs.postiDisponibili.value}}
          Taglia: {{fs.taglia.value}}
          Orario inizio: {{fs.oraInizio.value}}
          Orario fine: {{fs.oraFine.value}}
          Copertina: {{fs.copertina.value}}
          Descrizione: {{fs.descrizione.value}}
          Id form: {{fs.formId.value}} -->
  <br>

</ng-container>
