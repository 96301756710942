<div *ngIf="!loaded" class="text-center" style="position: absolute;right: 0px;">
    <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="5"
      styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
</div>

<div class="content" *ngIf="loaded">
    <div class="container-fluid">
        <div class="row" >
            <div class="col-md-12">
                <div class="card">

    <div class="card-header card-header-text" style="margin-top: 2%;justify-content: center;">
        <h5 class="card-title" >Info tecnico</h5>
     <br>
    </div>
    <div class="content">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th style="width: 20%;"></th>
                    <th style="width: 80%;"></th>
                </tr>
            </thead>
            <tbody class="animation3">
                <tr>
                    <td class="text-right" style="max-width: 30%;">
                        <h6 >Nome</h6>
                    </td>
                    <td  >{{dettaglioTecnico?.nome}}</td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Cognome</h6>
                    </td>
                    <td class="col-10">{{dettaglioTecnico?.cognome}}</td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;"> 
                        <h6 >Email</h6>
                    </td>
                    <td class="col-10">{{dettaglioTecnico?.email}}</td>
                </tr>
                <tr *ngIf="dettaglioTecnico.linkPhoto">
                    <td class="text-right" style="font-weight: bold;"> 
                        <h6 >Fototessera</h6>
                    </td>
                    <td class="col-10"> <img src="{{dettaglioTecnico.linkPhoto}}" class="fototessera bordo-grigio"> </td>
                </tr>
            </tbody>
        </table>
                        
            <hr><div class="text-right" style="margin-right: 5%;">
                <button (click)="indietro(true)" class="btn btn-primary" style="margin-left: 46%;margin-bottom: 3%;">Indietro</button>
           </div>
          
        
    </div> </div> </div> </div> </div> 

    


  