import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SelectButtonModule } from 'primeng/selectbutton';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { GestioneGruppiComponent } from './gestione-gruppi/gestione-gruppi.component';
import { GestioneProfiliComponent } from './gestione-profili/gestione-profili.component';
import { GestioneUtenteComponent } from './gestione-utente/gestione-utente.component';
import { PannelloControlloRoutingModule } from './pannello-controllo-routing.module';
import { LayoutComponentPannello } from './layout.component';
import { JwtInterceptor } from 'src/app/HELPERS/jwt.interceptor';
import { ErrorInterceptor } from 'src/app/HELPERS/error.interceptor';
import { ReportComponent } from './report/report.component';
import { DettagliUtenteComponent } from './gestione-utente/dettagli-utente/dettagli-utente.component';
import { ModificaUtenteComponent } from './gestione-utente/modifica-utente/modifica-utente.component';
import { DettagliProfiloComponent } from './gestione-profili/dettagli-profilo/dettagli-profilo.component';
import { ModificaUtenteProfiloComponent } from './gestione-profili/modifica-utente-profilo/modifica-utente-profilo.component';
import { AggiungiGruppoComponent } from './gestione-gruppi/aggiungi-gruppo/aggiungi-gruppo.component';
import { ModificaGruppoComponent } from './gestione-gruppi/modifica-gruppo/modifica-gruppo.component';
import { CreaUtenteComponent } from './crea-utente/crea-utente.component';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PannelloControlloComponent } from './pannello-controllo.component';
import { ConfigurazioneComponent } from './configurazione/configurazione.component';
import { AulaVirtualeConfigComponent } from './configurazione/aula-virtuale-config/aula-virtuale-config.component';
import { ModificaAulaComponent } from './configurazione/aula-virtuale-config/modifica-aula/modifica-aula.component';
import { InfoAulaComponent } from './configurazione/aula-virtuale-config/info-aula/info-aula.component';
import { CreaAulaComponent } from './configurazione/aula-virtuale-config/crea-aula/crea-aula.component';
import { ReportAssistenzaComponent } from './report/report-assistenza/report-assistenza.component';
import { ReportGeoComponent } from './report/report-geo/report-geo.component';
import { ReportAulaComponent } from './report/report-aula/report-aula.component';
import { ReportIoTComponent } from './report/report-iot/report-iot.component';
import { CreaConfigurazioneComponent } from './configurazione/iot-config/crea-configurazione/crea-configurazione.component';
import { DispositivoComponent } from './configurazione/iot-config/dispositivo/dispositivo.component';
import { DispositivoConfigComponent } from './configurazione/iot-config/dispositivo-config.component';
import { FamilyUtenteComponent } from './gestione-utente/family-utente/family-utente.component';
import { WindowComponent } from 'src/app/HELPERS/window.component';
import { PortalModule } from '@angular/cdk/portal';
//import { NgxLeafletFullscreenModule } from '@runette/ngx-leaflet-fullscreen';
import { ChartModule } from 'primeng/chart';
import { MatSliderModule } from '@angular/material/slider';
import { ReportSatisfactionComponent } from './report/report-satisfaction/report-satisfaction.component';
import { SmartConfComponent } from './configurazione/iot-config/smart-conf/smart-conf.component';
import { ExternalComponentsModule } from 'src/app/MODELS/COMPONENTS/externalComponents.module';
import { DialogModule } from 'primeng/dialog';
import { BadgeConfigComponent } from './configurazione/badge-config/badge-config.component';
import { ReportBadgeComponent } from './report/report-badge/report-badge.component';
import { NgApexchartsModule } from "ng-apexcharts";

import { PipeNameUnderscore } from '../../HELPERS/PIPES/pipeNameUnderscore';
import { GestioneTecniciComponent } from './gestione-tecnici/gestione-tecnici.component';
import { CreaTecnicoComponent } from './crea-tecnico/crea-tecnico.component';
import { DettagliTecnicoComponent } from './gestione-tecnici/dettagli-tecnico/dettagli-tecnico.component';
import { CreaClienteComponent } from './crea-cliente/crea-cliente.component';
import { GestioneClientiComponent } from './gestione-clienti/gestione-clienti.component';
import { DettagliClienteComponent } from './gestione-clienti/dettagli-cliente/dettagli-cliente.component';
import { ModificaClienteComponent } from './gestione-clienti/modifica-cliente/modifica-cliente.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReportCallcenterComponent } from './report/report-callcenter/report-callcenter.component';
import { MatIconModule } from '@angular/material/icon';
import { ListaDispositiviComponent } from './configurazione/iot-config/lista-dispositivi/lista-dispositivi.component';
import { EditorModule } from 'primeng/editor';
import { MatTableModule } from '@angular/material/table';
import { CheckboxModule } from 'primeng/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ReportEracoopComponent } from './report/report-eracoop/report-eracoop.component';
import { AulaVirtualeConfigGoToComponent } from './configurazione/aula-virtuale-config/aula-virtuale-config-go-to/aula-virtuale-config-go-to.component';
import { GoToCreaAulaComponent } from './configurazione/aula-virtuale-config/crea-aula/go-to-crea-aula/go-to-crea-aula.component';
import { Shellybutton1ConfComponent } from './configurazione/iot-config/shellybutton1-conf/shellybutton1-conf/shellybutton1-conf.component';
import { GraficoIotComponent } from '../embed-control/grafico-iot/grafico-iot.component';
import { GraficoIotV2Component } from '../embed-control/grafico-iot-v2/grafico-iot-v2.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    HttpClientModule,
    SelectButtonModule,
    KeyFilterModule,
    CalendarModule,
    MatTooltipModule,
    ProgressSpinnerModule,
    PannelloControlloRoutingModule,
    ConfirmDialogModule,
    ButtonModule,
    MessagesModule,
    ToastModule,
    PortalModule,
  //  NgxLeafletFullscreenModule,#MAPPAL
    ChartModule,
    MatSliderModule,
    ExternalComponentsModule,
    DialogModule,
    MatStepperModule,
    MatIconModule,
    EditorModule,
    MatTableModule,
    CheckboxModule,
    MatPaginatorModule,
    NgApexchartsModule,
  ],

  declarations: [
    PipeNameUnderscore,
    CreaUtenteComponent,
    GestioneUtenteComponent,
    GestioneProfiliComponent,
    GestioneGruppiComponent,
    LayoutComponentPannello,
    ReportComponent,
    ModificaUtenteComponent,
    ModificaUtenteProfiloComponent,
    DettagliUtenteComponent,
    PannelloControlloComponent,
    DettagliUtenteComponent,
    DettagliProfiloComponent,
    AggiungiGruppoComponent,
    ModificaGruppoComponent,
    ConfigurazioneComponent,
    AulaVirtualeConfigComponent,
    ModificaAulaComponent,
    InfoAulaComponent,
    CreaAulaComponent,
    ReportAssistenzaComponent,
    ReportGeoComponent,
    ReportAulaComponent,
    ReportIoTComponent,
    DispositivoConfigComponent,
    CreaConfigurazioneComponent,
    DispositivoComponent,
    FamilyUtenteComponent,
    WindowComponent,
    ReportSatisfactionComponent,
    SmartConfComponent,
    BadgeConfigComponent,
    ReportBadgeComponent,
    GestioneTecniciComponent,
    CreaTecnicoComponent,
    DettagliTecnicoComponent,
    CreaClienteComponent,
    GestioneClientiComponent,
    DettagliClienteComponent,
    ModificaClienteComponent,

    ReportCallcenterComponent,

    ListaDispositiviComponent,

    ReportEracoopComponent,

    AulaVirtualeConfigGoToComponent,

    GoToCreaAulaComponent,
    Shellybutton1ConfComponent,

    GraficoIotComponent,
    GraficoIotV2Component
  ],
  exports: [PannelloControlloComponent, GraficoIotComponent, GraficoIotV2Component],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class PannelloControlloModule {}
