import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '../GENERAL/env.service';

@Injectable({
  providedIn: 'root'
})
export class TecnicoService {

  baseUrl: String;
  constructor(private env: EnvService, private http: HttpClient) {
    this.baseUrl = this.env.apiUrl;
  }

//GET

  //Ottiene la lista completa dei tecnici
  getAll(size, page, ricerca) {
    if(ricerca)
    {
      //console.log("Ricerca effettuata: " + ricerca);
      return this.http.get<any>(this.baseUrl + '/lista_tecnici?size=' + size + '&page=' + page + '&ricerca=' + ricerca);
    }
    //console.log("Ricerca non effettuata.");
    return this.http.get<any>(this.baseUrl + '/lista_tecnici?size=' + size + '&page=' + page);
  }

  //Ottiene le informazioni di un singolo tecnico
  getInfoTecnico(id)
  {
    return this.http.get<any>(this.baseUrl + '/dettaglio_tecnico?idTecnico=' + id);
  }

  //Ottiene la lista completa dei tecnici eliminati
  getAllEliminati(size, page, ricerca){
    if(ricerca)
    {
      return this.http.get<any>(this.baseUrl + '/lista_tecnici_eliminati?size=' + size + '&page=' + page + '&ricerca=' + ricerca);
    }
    return this.http.get<any>(this.baseUrl + '/lista_tecnici_eliminati?size=' + size + '&page=' + page);
  }

  getTecniciDisponibili(dataInizio, dataFine)
  {
    return this.http.get<any>(this.baseUrl + '/lista_tecnici_disponibili?dataInizio=' + dataInizio + '&dataFine=' + dataFine);
  }
//POST
  //Crea nuovo tecnico
  addTecnico(tecnico, fileFototessera)
  {
    const jsonTecnico = JSON.stringify(tecnico);
    const input = new FormData();
    input.append('tecnico', new Blob([jsonTecnico],{ type: 'application/json' }));
    if (fileFototessera)
      input.append('photo', fileFototessera, fileFototessera.name);
    return this.http.post<any>(this.baseUrl + '/salva_tecnico', input);
  }

  //Modifica tecnico esistente
  editTecnico(id, tecnico, fileFototessera, rimuoviFoto)
  {
    const jsonTecnico = JSON.stringify(tecnico);
    const input = new FormData();
    input.append('tecnico', new Blob([jsonTecnico],{ type: 'application/json' }));
    if(fileFototessera)
    {
      input.append('photoOperation', new Blob(['"PHOTO_UPDATE"'],{ type: 'application/json'}));
      input.append('photo', fileFototessera, fileFototessera.name);
    }
    else if(rimuoviFoto)
    {
      input.append('photoOperation', new Blob(['"PHOTO_DELETE"'],{ type: 'application/json'}));
    }
    return this.http.post<any>(this.baseUrl + '/modifica_tecnico?idTecnico=' + id, input);
  }

  //Cestina/Ripristina tecnico
  deleteTecnico(id, elimina)
  {
    return this.http.post<any>(this.baseUrl + '/elimina_tecnico?idTecnico=' + id + "&elimina=" + elimina, null);
  }


}
