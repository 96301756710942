

export class Scorm {
        nomeCorso:any;
        idModulo:any;
        idAula:any;
        path:any;
        stringa:any;
        idCorso:any;
        tempo:any;
   
}