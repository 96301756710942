<div class="container-fluid" *ngIf="gestioneProfili">
   
    <div *ngIf="gestione" class="content">
    <div class="row" >
        <div class="col-md-12">
            <div class="card" style="z-index: 0;">
                <div class="card-content" style="margin-bottom: 2%;">
                    <h4 class="card-title" style="margin-top: 2%; margin-bottom: 3%;">Gestione Profili</h4>
                      <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                            <thead style="position: sticky; z-index: 1; background-color: white;">
                                <tr style="position: sticky; z-index: 1; background-color: white;">
                                    <th style="position: sticky; z-index: 1; background-color: white;">Profilo</th>
                                    <!-- <th style="position: sticky; z-index: 1; background-color: white;">Descrizione</th> -->
                                    <th class="disabled-sorting text-right" style="position: sticky; z-index: 1; background-color: white;" >Visualizza</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                <tr *ngFor="let ruolo of listaRuoli;let i =index;">
                                    <td>{{ruolo.nomeRuolo}}</td>   
                                    <!-- <td>{{ruolo?.descrizioneRuolo}}</td>                                                                -->
                                    <td class="text-right">
                                       <a (click)="view(ruolo.id)" ><i class="material-icons notranslate pointer">remove_red_eye</i></a>
                                      
                                    </td>
                                </tr>
                            
                            </tbody>  
                        </table>
                       
                </div>
              
            </div>
          
         </div>
     
    </div> 

</div>
<div *ngIf="dettagli"><app-dettagli-profilo [profiloInfo]="id" (vaiModifica)="edit($event)" (cambia)="back($event)"></app-dettagli-profilo></div> 
<div *ngIf="modifica"><app-modifica-utente-profilo [utenteId]="idUtente" (tornaGestione)="vaiDettagli($event)"></app-modifica-utente-profilo></div> 
</div>


