import { Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/SERVICES';
@Component({ templateUrl: 'layoutPannello.component.html'})

export class LayoutComponentPannello implements OnInit,DoCheck{
    constructor(private accountService : AccountService, private router : Router){}
    ngOnInit(): void {
      
    }
    ngDoCheck(): void {
      let arrayHref = location.href.split('pannellocontrollo/');
        if(arrayHref && arrayHref[arrayHref.length-1] !== "report" ){
         if (this.accountService.listaEnv && this.accountService.ruolo !== 1) {
           this.router.navigate(['dashboard'])
         }
        } }
}