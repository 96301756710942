<div class="flex f-right">
    <div class="mini-container" (click)="changeZoom(false)">
        <i class="fa fa-minus"></i>
    </div>
    <div class="input-inside flex">
        <div class="expand">
            <input type="number" name="percentage" class="line-animation" [(ngModel)]="percentage" [ngStyle]="{'width.px': width}"
            (paste)="resizeInput(percentage)" (cut)="resizeInput(percentage)" (input)="resizeInput(percentage)" min="20" max="1638">
            <div class="border"></div>
        </div>
        %
        <!-- <i class="fa fa-percent"></i> -->
    </div>
    
    <div class="mini-container" (click)="changeZoom(true)">
        <i class="fa fa-plus"></i>
    </div>

</div>


<span #invisibleText id='invisibleText'>{{percentage}}</span>