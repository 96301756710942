import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Edificio } from 'src/app/MODELS/PLANIMETRIE/edificio';
import { Piano } from 'src/app/MODELS/PLANIMETRIE/piano.model';
import { Stanza } from 'src/app/MODELS/PLANIMETRIE/stanza';
import { AccountService, EnvService } from '..';



@Injectable({
  providedIn: 'root'
})

export class PlanimetrieService {
  baseUrl: string;
  constructor(private http: HttpClient, private env: EnvService, private accountService: AccountService) {
    this.baseUrl = this.env.apiUrl;
  }

  //PIANO SERVICE 
  getAllPianiEdificio(idEdificio: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/planimetrie/piani?edificioId=${idEdificio}`);
  }

  createPiano(data: FormData): Observable<any> {
    return this.http.post(`${this.baseUrl}/planimetrie/piano`, data);
  }

  updatePiano(piano: Piano): Observable<any> {
    return this.http.put(`${this.baseUrl}/planimetrie/piano`, piano);
  }

  deleteFloors(edificioId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/planimetrie/piani?edificioId=${edificioId}`);
  }

  deleteFloor(floorId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/planimetrie/piano/${floorId}`);
  }

  downloadPlanimetria(idDoc: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/planimetrie/downloadPlanimetria?idDoc=${idDoc}`, { responseType: "arraybuffer", observe: 'response' });
  }

  //STANZE SERVICE
  getAllRooms(floorId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/planimetrie/stanze?pianoId=${floorId}`);
  }

  createStanza(data: FormData): Observable<any> {
    return this.http.post(`${this.baseUrl}/planimetrie/stanza`, data);
  }

  uploadStanza(stanza: Stanza): Observable<any> {
    return this.http.put(`${this.baseUrl}/planimetrie/stanza`, stanza);
  }

  downloadPhotoSphere(idDoc: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/planimetrie/stanza/download?idDoc=${idDoc}`, { responseType: "arraybuffer" });
  }

  deleteRooms(floorId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/planimetrie/stanze?floorId=${floorId}`);
  }

  deleteRoom(roomId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/planimetrie/stanza/${roomId}`);
  }

  //EDIFICIO SERVICE
  getAllEdifici(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/planimetrie/edificio`);
  }

  insertEdificio(edificio: Edificio): Observable<any> {
    return this.http.post(`${this.baseUrl}/planimetrie/edificio`, edificio);
  }

  updateEdificio(edificio: Edificio): Observable<any> {
    return this.http.put(`${this.baseUrl}/planimetrie/edificio`, edificio);
  }

  deleteEdificio(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/planimetrie/edificio/${id}`);
  }

  deleteEdifici(): Observable<any> {
    return this.http.delete(`${this.baseUrl}/planimetrie/edificio`);
  }

  //MARKER SERVICE
  saveMarker(marker: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/planimetrie/marker`, marker);
  }

  updateMarker(marker: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/planimetrie/marker`, marker);
  }

  getMarkersRoom(roomId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/planimetrie/markers?roomId=${roomId}`);
  }

  deleteMarker(markerId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/planimetrie/marker/${markerId}`);
  }

  deleteMarkers(roomId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/planimetrie/markers?roomId=${roomId}`);
  }

  /*
    1 Admin
    2 Operatore
    3 User
  */

  isAdmin(): boolean {
    let role = this.accountService.ruolo as number || null;
    return role && role == 1;
  }

  isOperatore(): boolean {
    let role = this.accountService.ruolo as number || null;
    return role && role == 2;
  }

  isUser(): boolean {
    let role = this.accountService.ruolo as number || null;
    return role && role == 3;
  }
}