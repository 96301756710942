<!-- <p-carousel [value]="telemetrie" [numVisible]="1" [numScroll]="1">
  <ng-template let-item pTemplate="item"> -->
<!-- [autoplayInterval]="3000" -->
<div>
  <div class="headerCard">
    <!-- Header -->
    Smartwatch
  </div>

  <div class="bg-f1f1f1 pd20">
    <!-- Content -->
    <div class="row flex">
      <div class="col-xs-4 flex" (click)="showModalBattery = true">
        <div>
          <i class="material-icons fs-25 DodgerBlue flex">battery_charging_full</i>
          <span class="fs-14 ml-5 flex"
            *ngIf="iotService.dispositivoSelezionato && iotService.dispositivoSelezionato.battery">
            {{iotService.dispositivoSelezionato.battery}}
          </span>
          <span class="fs-24 ml-5 flex"
            *ngIf="iotService.dispositivoSelezionato && !iotService.dispositivoSelezionato.battery">
            --
          </span>
        </div>

        <span class="Soglia">
          Soglia: 20-40
        </span>
      </div>
      <div class="col-xs-4 flex" (click)="showModalHR = true">
        <div>
          <i class="material-icons fs-25 DodgerBlue flex">favorite_border</i>
          <span class="fs-14 ml-5 flex"
            *ngIf="iotService.dispositivoSelezionato && iotService.dispositivoSelezionato.HeartRate">
            {{iotService.dispositivoSelezionato.HeartRate}}
          </span>
          <span class="fs-24 ml-5 flex"
            *ngIf="iotService.dispositivoSelezionato && !iotService.dispositivoSelezionato.HeartRate">
            --
          </span>
        </div>

        <span class="Soglia">
          Soglia: 20-40
        </span>
      </div>
      <div class="col-xs-4 flex"  (click)="showModalBR = true">
        <div>
          <i class="material-icons fs-25 DodgerBlue flex">co2</i>
          <span class="fs-14 ml-5 flex"
            *ngIf="iotService.dispositivoSelezionato && iotService.dispositivoSelezionato.breathingRate">
            {{iotService.dispositivoSelezionato.breathingRate}}
          </span>
          <span class="fs-24 ml-5 flex"
            *ngIf="iotService.dispositivoSelezionato && !iotService.dispositivoSelezionato.breathingRate">
            --
          </span>

        </div>
        <span class="Soglia">
          Soglia: 20-40
        </span>
      </div>
    </div>
    <div class="row flex pt-10">
      <div class="col-xs-4 flex" (click)="showModalMovements = true">
        <div>
          <i class="material-icons fs-25 DodgerBlue flex">directions_walk</i>
          <span class="fs-14 ml-5 flex"
            *ngIf="iotService.dispositivoSelezionato && iotService.dispositivoSelezionato.movements">
            {{iotService.dispositivoSelezionato.movements}}
          </span>
          <span class="fs-24 ml-5 flex"
            *ngIf="iotService.dispositivoSelezionato && !iotService.dispositivoSelezionato.movements">
            --
          </span>

        </div>

        <span class="Soglia">
          Soglia: 20-40
        </span>
      </div>
      <div class="col-xs-4 flex" (click)="showModalTemperature = true">
        <div>
          <i class="material-icons fs-25 DodgerBlue flex">thermostat</i>
          <span class="fs-14 ml-5 flex"
            *ngIf="iotService.dispositivoSelezionato && iotService.dispositivoSelezionato.temperature">
            {{iotService.dispositivoSelezionato.temperature}}
          </span>
          <span class="fs-24 ml-5 flex"
            *ngIf="iotService.dispositivoSelezionato && !iotService.dispositivoSelezionato.temperature">
            --
          </span>

        </div>
        <span class="Soglia">
          Soglia: 20-40
        </span>
      </div>
      <div class="col-xs-4 flex" (click)="showModalPression = true">
        <div>
          <i class="material-icons fs-25 DodgerBlue flex">bloodtype</i>
          <span class="fs-14 ml-5 flex"
            *ngIf="iotService.dispositivoSelezionato && iotService.dispositivoSelezionato.pression">
            {{iotService.dispositivoSelezionato.pression}}
          </span>
          <span class="fs-24 ml-5 flex"
            *ngIf="iotService.dispositivoSelezionato && !iotService.dispositivoSelezionato.pression">
            --
          </span>

        </div>
        <span class="Soglia">
          Soglia: 20-40
        </span>
      </div>
    </div>
    <!-- Footer -->
  </div>
  <div>
  </div>
</div>

<!-- </ng-template>
</p-carousel> -->


<!-- BATTERIA -->
<p-dialog [visible]="showModalBattery">
  <p-header class="flex w-100">
    <span>
      Grafico Movimenti di smartwatch
    </span>
    <button mat-button class="close-icon buttonClose ml-auto flex" (click)="showModalBattery = false">
      <mat-icon class="fs-8">close</mat-icon>
    </button>
  </p-header>
  <div class="absolute">
    <p-chart type="line" [options]="optionsBPM" [data]="dataSourceGrafici"></p-chart>
  </div>
</p-dialog>
<!-- HR -->
<p-dialog [visible]="showModalHR">
  <p-header class="flex w-100">
    <span>
      Grafico Movimenti di smartwatch
    </span>
    <button mat-button class="close-icon buttonClose ml-auto flex" (click)="showModalHR = false">
      <mat-icon class="fs-8">close</mat-icon>
    </button>
  </p-header>
  <div class="absolute">
    <p-chart type="line" [options]="optionsBPM" [data]="dataSourceGrafici"></p-chart>
  </div>
</p-dialog>
<!-- BR -->
<p-dialog [visible]="showModalBR">
  <p-header class="flex w-100">
    <span>
      Grafico Movimenti di smartwatch
    </span>
    <button mat-button class="close-icon buttonClose ml-auto flex" (click)="showModalBattery = false">
      <mat-icon class="fs-8">close</mat-icon>
    </button>
  </p-header>
  <div class="absolute">
    <p-chart type="line" [options]="optionsBPM" [data]="dataSourceGrafici"></p-chart>
  </div>
</p-dialog>
<!-- Movements -->
<p-dialog [visible]="showModalMovements">
  <p-header class="flex w-100">
    <span>
      Grafico Movimenti di smartwatch
    </span>
    <button mat-button class="close-icon buttonClose ml-auto flex" (click)="showModalMovements = false">
      <mat-icon class="fs-8">close</mat-icon>
    </button>
  </p-header>
  <div class="absolute">
    <p-chart type="line" [options]="optionsBPM" [data]="dataSourceGrafici"></p-chart>
  </div>
</p-dialog>
<!-- Temperature -->
<p-dialog [visible]="showModalTemperature">
  <p-header class="flex w-100">
    <span>
      Grafico Movimenti di smartwatch
    </span>
    <button mat-button class="close-icon buttonClose ml-auto flex" (click)="showModalBattery = false">
      <mat-icon class="fs-8">close</mat-icon>
    </button>
  </p-header>
  <div class="absolute">
    <p-chart type="line" [options]="optionsBPM" [data]="showModalTemperature"></p-chart>
  </div>
</p-dialog>
<!-- Pressione -->
<p-dialog [visible]="showModalPression">
  <p-header class="flex w-100">
    <span>
      Grafico Movimenti di smartwatch
    </span>
    <button mat-button class="close-icon buttonClose ml-auto flex" (click)="showModalBattery = false">
      <mat-icon class="fs-8">close</mat-icon>
    </button>
  </p-header>
  <div class="absolute">
    <p-chart type="line" [options]="optionsBPM" [data]="showModalPression"></p-chart>
  </div>
</p-dialog>
