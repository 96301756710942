<div class="content">
  <div class="container-fluid">
    <div *ngIf="!caricato">
      <div class="text-center" >
          <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
          styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
      </div>
  </div>
    <ng-container *ngIf="report && caricato">
      <div class="col-xs-12">


        <ng-container *ngIf="selezionaDispositivo">
          <h4>Seleziona i dispositivi</h4>
          <h5 *ngIf="listaVuota"> Nessun dispositivo associato</h5>

          <div *ngIf="!listaVuota" >
            <div id="datatables_filter" class="dataTables_filter">
              <form [formGroup]="searchform">
                <label class="form-group" style="width: 100%;">
                  <input  type="text" formControlName="search" class="form-control" placeholder="Cerca..." maxlength="30" >
                </label>
                </form>
                <div *ngIf="searchInDispositivo()"></div>
              </div>
          </div>
          <div class="table-responsive scrollable">
            <p-table *ngIf="!listaVuota" class="table table-striped " [value]="dispositivi" [(selection)]="selectedDispositivi"
              dataKey="id">

              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 3rem !important" class="pl-6">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th>Nome</th>
                  <th>Tipo</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-dispositivo>
                <tr *ngIf="dispositivo.deviceType.tipoDevice!='Tracker' && dispositivo.deviceType.tipoDevice!='Beacon'">
                  <td style="width: 3%" class="pl-6">
                    <p-tableCheckbox [value]="dispositivo"></p-tableCheckbox>
                  </td>
                  <td>{{dispositivo.deviceName}}</td>
                  <td>{{dispositivo.deviceType.tipoDevice}}</td>


                </tr>

              </ng-template>

            </p-table>

          </div>


          <div class="text-right"  *ngIf="!listaVuota" >
            <a class="btn btn-primary" (click)="avanti('dispositivo')"
              style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Avanti</a>
          </div>
        </ng-container>
      </div>

      <div class="col-xs-12">


        <ng-container *ngIf="selezionaTelemetria">
          <h4>Seleziona le telemetrie</h4>

          <div class="table-responsive scrollable">

            <p-table class="table table-striped " [value]="telemetrie" [(selection)]="selectedTelemetrie"
              dataKey="telemetry">

              <ng-template pTemplate="header" let-telemetria>
                <tr >
                  <th style="width: 3rem !important"  class="pl-6">
                    <p-tableHeaderCheckbox (click)="selectedTelemetriaAll($event)"></p-tableHeaderCheckbox>
                  </th>
                  <th>Telemetria</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-telemetria>
                <ng-container *ngIf="telemetria.telemetryName !== 'Report completo' || (telemetria.telemetryName === 'Report completo' && includesSmartbed() && selectedDispositivi.length<2)">
                <tr *ngIf="telemetria.telemetryName !== 'Stato movimenti'">
                  <td style="width: 3%" class="pl-6">
                    <p-tableCheckbox (click)="selectedTelemetria(telemetria)" [value]="telemetria"></p-tableCheckbox>
                  </td>
                  <td>{{telemetria.telemetryName}}</td>
                </tr>
              </ng-container>
              </ng-template>

            </p-table>

          </div>


          <div class="text-right">
            <a class="btn btn-secondary" (click)="indietro('dispositivo')" style="color: white;">Indietro</a>
            <a class="btn btn-primary" (click)="avanti('telemetria')"
              style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Avanti</a>
          </div>
        </ng-container>
      </div>

      <div *ngIf="selezionaDate">
        <div class="col-xs-12">

          <div class="card-content" class="text-center" style="margin-bottom: 3%;">
            <h4 style="margin-bottom: 3%;">Seleziona la data</h4>
            <form [formGroup]="calendar" class="box-model form-support-margin">
              <p-calendar view="date" formControlName="calendario" (onSelect)="selezionaData()" [maxDate]="today"
                [readonlyInput]="true" [numberOfMonths]="2" yearRange="2000:2030" [(ngModel)]="rangeDates"
                [showOtherMonths]="false" placeholder="Select time period" selectionMode="range" [inline]="true"
                dateFormat="dd/mm/yy" [locale]="ita">

              </p-calendar>
              <div class="card-footer text-right">
                <a class="btn btn-secondary" (click)="tracking? indietro('tracker') : indietro('telemetria')" style="color: white;">Indietro</a>


                <button [disabled]="loading" class="btn btn-primary" rel="tooltip" title="Scarica Report"
                  (click)="inviaDati()" style="max-width: 2%;"><i class="fa fa-folder"><span *ngIf="loading">
                      <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                        styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                    </span></i>

                </button>

              </div>
            </form>
          </div>


        </div>
      </div>

      <ng-container *ngIf="selezionaGrafici">
        <button (click)="indietro('')" id="back-button" class="btn btn-round  btn-just-icon"
        style="margin-top: 3px; color: rgb(54, 54, 54); box-shadow:none!important">
        <i class="material-icons notranslate">arrow_back</i>
      </button>
        <h4 style="margin-bottom: 3%; display: inline;">Report da {{fromDay}}/{{fromMonth}}/{{fromYear}} a {{toDay}}/{{toMonth}}/{{toYear}}</h4>
        <div class="dropdown" *ngIf="selectedDispositivi.length>1"
          style="display: inline; position: absolute; right: 6%; margin-top: 15px;;">
          <a class="collapsed" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="true">
            {{dispositivoSelezionato}}
            <span class="caret"></span>
          </a>
          <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu2"
            style="overflow-x: auto; max-height: 1400%">
            <li *ngFor="let dispositivo of selectedDispositivi; let i = index"><a
                (click)="cambiaGrafici(dispositivo)">{{dispositivo.deviceName}}</a>
            </li>
          </ul>
        </div>


        <div class="row" *ngIf="(stringheSelezionate.includes('SBP') || stringheSelezionate.includes('DBP'))">
          <!-- <app-grafico-iot [date]="date" [dispositivo]="dispositivo" [mostraChartIot]="mostraChartPressioneIot" [nomeTelemetria]="'Pressione'" [graficoWidthConst]="graficoPressioneWidthConst" [dataIot]="dataPressioneIot" [dataIot2]="dataPressioneIot2" [options]="optionsPressione" [options2]="optionsPressione2"></app-grafico-iot> -->

          <div class="col-xs-12 col-sm-12">
            <app-grafico-iot [hasLegend]='true' [mostraChartIot]="mostraChartPressioneIot" [nomeTelemetria]="'Pressione'" [graficoWidthConst]="graficoPressioneWidthConst" [dataIot]="dataPressioneIot" [dataIot2]="dataPressioneIot2" [options]="optionsPressione">
              <div class="chart-legend pressione-min"></div>
                <span [ngStyle]="{'text-decoration': pressioneMinimaMarked ? 'line-through' : null}">Pressione minima</span>
                <div class="chart-legend pressione-max"></div>
                <span [ngStyle]="{'text-decoration': pressioneMassimaMarked ? 'line-through' : null}">Pressione massima</span>
            </app-grafico-iot>

            <!-- <div class="card" style="min-height:370px; max-height: 370px;">
              <div class="card-header card-header-text">
                <h4 class="card-title" style="display: inline;">Grafico pressione</h4>
                <i class="material-icons notranslate" (click)="scegliDownload('pressione')"  style="position: absolute; right: 20px; cursor: pointer; color:#2196F3">download</i>
              </div>
              <div class="legend-wrapper">
                <div class="chart-legend pressione-min"></div>
                <span [ngStyle]="{'text-decoration': pressioneMinimaMarked ? 'line-through' : null}" (click)="onClickLabelPressione(true)">Pressione minima</span>
                <div class="chart-legend pressione-max"></div>
                <span [ngStyle]="{'text-decoration': pressioneMassimaMarked ? 'line-through' : null}" (click)="onClickLabelPressione(false)">Pressione massima</span>
              </div>
              <div class="card-content table-responsive p-30-40" style="overflow: hidden;">
                <div *ngIf="pressione"class="tuttoBianco">
                  <div class="text-center" >
                      <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                      styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                  </div>
              </div>
                <div *ngIf="!mostraChartPressioneIot" class="m-20" style="justify-content: center; text-align: center;">
                  <span>
                    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                      styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                  </span>
                </div>
                <div [ngClass]="{'absolute':problemGraphAsync,'relative':!problemGraphAsync}" style="max-width: 100%; background-color: white;margin-left: -20px;">
                  <div [ngStyle]="{'width.px': graficoPressioneWidthFunction()}">
                    <p-chart  *ngIf="mostraChartPressioneIot" type="line" [options]="optionsPressione2" [height]="300" [data]="dataPressioneIot2" #chartPressione2 ></p-chart>
                  </div>
                </div>
                  <div style="max-width: 100%; overflow-x: scroll; position: relative; background-color: white; margin-left: 65px; z-index: 1;">
                  <div [ngStyle]="{'width.px': graficoPressioneWidth}" id="graficoPressioneWidth">
                    <p-chart *ngIf="mostraChartPressioneIot" type="line" [options]="optionsPressione" [height]="300" [data]="dataPressioneIot" #chartPressione ></p-chart>
                  </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <!-- Grafco Battito Cardiaco - TODO: Reintegrare "Misurazioni" -->

        <div class="row" *ngIf="stringheSelezionate.includes('HeartRate')">
          <!-- <app-grafico-iot [mostraChartIot]="mostraChartBattitoCardiacoIot" [nomeTelemetria]="'Battito Cardiaco'" [graficoWidthConst]="graficoBattitoWidthConst" [dataIot]="dataBattitoCardiacoIot" [dataIot2]="dataBattitoCardiacoIot2" [options]="optionsBPM"></app-grafico-iot> -->

          <!-- Test nuovo grafico -->
          <app-grafico-iot-v2 [nomeTelemetria]="'Battito Cardiaco'" [dataIot]="dataBattitoCardiacoIot" [mostraChartIot]="mostraChartBattitoCardiacoIot"></app-grafico-iot-v2>
           <!-- <div class="col-xs-12 col-sm-12">
            <div class="card" style="min-height:370px; max-height: 370px; ">
              copro i grafici per farli scaricare

              fine
              <div class="card-header card-header-text">
                <h4 class="card-title" style="display: inline;">Grafico battito cardiaco</h4>
                <mat-form-field style="position: absolute; right: 45px; top: 0px;">
                  <mat-label>Misurazioni</mat-label>
                  <mat-select>
                    <mat-option (onSelectionChange)="cambiaBattitoEvent(dispositivoSelezionatoFull? dispositivoSelezionatoFull.id : dispositiviSelezionati[0].id, $event.source.value,$event.isUserInput)" *ngFor="let misurazioni of numMisurazioneCardiache" [value]="misurazioni">
                      {{getDateFromMillis(misurazioni)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <i class="material-icons notranslate" (click)="scegliDownload('BPM')"  style="position: absolute; right: 20px; cursor: pointer;color:#2196F3">download</i>
              </div>
              <div class="card-content table-responsive" [ngClass]="{'p-0': tipologiaDispositivo === 'smartbed'}" style="overflow: hidden;padding-right: 0px !important;">
                <div *ngIf="bpmBianco" class="tuttoBianco">
                  <div class="text-center" >
                      <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                      styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                  </div>
              </div>
                <div *ngIf="!mostraChartBattitoCardiacoIot" class="m-20"
                  style="justify-content: center; text-align: center;"><span>
                    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                      styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                  </span></div>
                  <div [ngClass]="{'absolute':problemGraphAsync,'relative':!problemGraphAsync}" [ngStyle]="{'width.px': graficoBattitoWidth}"><p-chart *ngIf="mostraChartBattitoCardiacoIot" type="line" [options]="optionsBPM2" [height]="300"
                  [data]="dataBattitoCardiacoIot2"></p-chart></div>
                  <div style="max-width: 100%; overflow-x: scroll; position: relative; background-color: white; margin-left: 90px; z-index: 1;">
                  <div [ngStyle]="{'width.px': graficoBattitoWidth}">
                    <p-chart *ngIf="mostraChartBattitoCardiacoIot" type="line" [options]="optionsBPM" [height]="300"
                    [data]="dataBattitoCardiacoIot" #chartBPM ></p-chart>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <!-- Grafico FREQUENZA RESPIRATORIA ToDo: Aggiungere finestra misurazioni -->

        <div class="row" *ngIf="stringheSelezionate.includes('BreathingRate')">
          <!-- <app-grafico-iot [mostraChartIot]="mostraChartFrequenzaRespiratoriaIot" [nomeTelemetria]="'Respirazione'" [graficoWidthConst]="graficoRespirazioneWidthConst" [dataIot]="dataFrequenzaRespiratoriaIot" [dataIot2]="dataFrequenzaRespiratoriaIot2" [options]="optionsBRPM" [options2]="optionsBRPM2"></app-grafico-iot> -->
          <app-grafico-iot-v2 [mostraChartIot]="mostraChartFrequenzaRespiratoriaIot" [nomeTelemetria]="'Respirazione'" [dataIot]="dataFrequenzaRespiratoriaIot"></app-grafico-iot-v2>

          <!-- <div class="col-xs-12 col-sm-12">
            <div class="card" style="min-height:370px; max-height: 370px; ">
              copro i grafici per farli scaricare

              fine
              <div class="card-header card-header-text">

                <h4 class="card-title" style="display: inline;">Grafico Frequenza Respiratoria</h4>

                <mat-form-field style="position: absolute; right: 45px; top: 0px;">
                  <mat-label>Misurazioni</mat-label>
                  <mat-select>
                    <mat-option (onSelectionChange)="cambiaBattitoEvent(dispositivoSelezionatoFull? dispositivoSelezionatoFull.id : dispositiviSelezionati[0].id, $event.source.value,$event.isUserInput)" *ngFor="let misurazioni of numMisurazioneRespirazione" [value]="misurazioni">
                      {{getDateFromMillis(misurazioni)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <i class="material-icons notranslate" (click)="scegliDownload('BRPM')"  style="position: absolute; right: 20px; cursor: pointer;color:#2196F3">download</i>
              </div>
              <div class="card-content table-responsive" style="overflow: hidden;">
                <div *ngIf="brpmBianco" class="tuttoBianco">
                  <div class="text-center" >
                      <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                      styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                  </div>
              </div>
                <div *ngIf="!mostraChartFrequenzaRespiratoriaIot" class="m-20"
                  style="justify-content: center; text-align: center;"><span>
                    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                      styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                  </span></div>
                  <div [ngClass]="{'absolute':problemGraphAsync,'relative':!problemGraphAsync}"  class="w-4000"><p-chart *ngIf="mostraChartFrequenzaRespiratoriaIot" type="line" [options]="optionsBRPM2" [height]="321"
                  [data]="dataFrequenzaRespiratoriaIot2"></p-chart></div>
                  <div style="max-width: 100%; overflow-x: scroll; position: relative; background-color: white; margin-left: 83px; z-index: 1;">
                  <div [ngStyle]="{'width.px': graficoRespirazioneWidth}">
                    <p-chart *ngIf="mostraChartFrequenzaRespiratoriaIot" type="line" [options]="optionsBRPM" [height]="300"
                    [data]="dataFrequenzaRespiratoriaIot" #chartBRPM ></p-chart>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <!-- fine grafico respiratorio -->
        <div class="row" *ngIf="stringheSelezionate.includes('BodyTemperature')">
          <app-grafico-iot [mostraChartIot]="mostraChartTemperaturaIot" [nomeTelemetria]="'Temperatura'" [graficoWidthConst]="graficoTemperaturaWidthConst" [dataIot]="dataTemperaturaIot" [dataIot2]="dataTemperaturaIot2" [options]="optionsTemp" [options2]="optionsTemp2"></app-grafico-iot>
          <!-- <div class="col-xs-12 col-sm-12">
            <div class="card" style="min-height:370px; max-height: 385px;">
              <div class="card-header card-header-text">
                <h4 class="card-title" style="display: inline;">Grafico temperatura</h4>
                <i class="material-icons notranslate" (click)="scegliDownload('temperatura')" style="position: absolute; right: 20px; cursor: pointer; color: #2196F3 ;">download</i>
              </div>
              <div class="card-content table-responsive p-30-25"  style="overflow: hidden;">
                <div *ngIf="temperatura" class="tuttoBianco" style="margin-left: -33px;">
                  <div class="text-center" >
                      <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                      styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                  </div>
              </div>
                <div *ngIf="!mostraChartTemperaturaIot" class="m-20" style="justify-content: center; text-align: center;">
                  <span>
                    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                      styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                  </span></div>
                  <div [ngClass]="{'absolute':problemGraphAsync,'relative':!problemGraphAsync}" [ngStyle]="{'width.px': graficoTemperaturaWidth}">
                    <p-chart *ngIf="mostraChartTemperaturaIot" type="line" [options]="optionsTemp2" [height]="300" [data]="dataTemperaturaIot2" #chartTemp2 ></p-chart>
                  </div>
                    <div style="max-width: 100%; overflow-x: scroll; position: relative; background-color: white; margin-left: 90px; z-index: 1;">
                    <div [ngStyle]="{'width.px': graficoTemperaturaWidth}">
                      <p-chart *ngIf="mostraChartTemperaturaIot" type="line" [options]="optionsTemp" [height]="300" [data]="dataTemperaturaIot" #chartTemp ></p-chart>
                    </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <!-- Grafico Blood oxygen -->


        <div class="row" *ngIf="stringheSelezionate.includes('BloodOxygen')">
          <!-- <app-grafico-iot [mostraChartIot]="mostraChartBloodOxygenIot" [nomeTelemetria]="'Ossigenazione del sangue'" [graficoWidthConst]="graficoBloodOxygenWidthConst" [dataIot]="dataBloodOxygenIot" [dataIot2]="dataBloodOxygenIot2" [options]="optionsSPO2" [options2]="optionsSPO22"></app-grafico-iot> -->
          <app-grafico-iot-v2 [mostraChartIot]="mostraChartBloodOxygenIot" [nomeTelemetria]="'Ossigenazione del sangue'" [dataIot]="dataBloodOxygenIot"></app-grafico-iot-v2>
          <!-- <div class="col-xs-12 col-sm-12">
            <div class="card" style="min-height:370px; max-height: 385px;">
              <div class="card-header card-header-text">
                <h4 class="card-title" style="display: inline;">Grafico Blood oxygen</h4>
                <i class="material-icons notranslate" (click)="scegliDownload('BloodOxygen')" style="position: absolute; right: 20px; cursor: pointer; color: #2196F3 ;">download</i>
              </div>
              <div class="card-content table-responsive p-30-25"  style="overflow: hidden;">
                <div *ngIf="bloodOxygen" class="tuttoBianco" style="margin-left: -33px;">
                  <div class="text-center" >
                      <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                      styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                  </div>
              </div>
                <div *ngIf="!mostraChartBloodOxygenIot" class="m-20" style="justify-content: center; text-align: center;">
                  <span>
                    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                      styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                  </span></div>
                  <div [ngClass]="{'absolute':problemGraphAsync,'relative':!problemGraphAsync}" [ngStyle]="{'width.px': graficoBloodOxygenWidth}">
                    <p-chart *ngIf="mostraChartBloodOxygenIot" type="line" [options]="optionsSPO22" [height]="300" [data]="dataBloodOxygenIot2" #chartBloodOxygen2 ></p-chart>
                  </div>
                    <div style="max-width: 100%; overflow-x: scroll; position: relative; background-color: white; margin-left: 90px; z-index: 1;">
                    <div [ngStyle]="{'width.px': graficoBloodOxygenWidth}">
                      <p-chart *ngIf="mostraChartBloodOxygenIot" type="line" [options]="optionsSPO2" [height]="300" [data]="dataBloodOxygenIot" #chartBloodOxygen ></p-chart>
                    </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <!-- Fine grafico blood oxygen -->
        <div class="row" *ngIf="stringheSelezionate.includes('StepCounter')">
          <!-- <app-grafico-iot [mostraChartIot]="mostraChartContaPassiIot" [nomeTelemetria]="'Contapassi'" [graficoWidthConst]="graficoPassiWidthConst" [dataIot]="dataContaPassiIot" [dataIot2]="dataContaPassiIot2" [options]="optionsPassi" [options2]="optionsPassi2"></app-grafico-iot> -->
          <app-grafico-iot-v2 [mostraChartIot]="mostraChartContaPassiIot" [nomeTelemetria]="'Contapassi'" [dataIot]="dataContaPassiIot"></app-grafico-iot-v2>

          <!-- <div class="col-xs-12 col-sm-12">
            <div class="card" style="min-height:370px; max-height: 370px;">
              <div class="card-header card-header-text">
                <h4 class="card-title" style="display: inline;">Grafico Conta Passi</h4>
                <i class="material-icons notranslate" (click)="scegliDownload('passi')"  style="position: absolute; right: 20px; cursor: pointer;color: #2196F3">download</i>
              </div>
              <div class="card-content table-responsive"  style="overflow: hidden;">
                <div *ngIf="passi" class="tuttoBianco" >
                  <div class="text-center" >
                      <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                      styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                  </div>
              </div>
                <div *ngIf="!mostraChartContaPassiIot" class="m-20" style="justify-content: center; text-align: center;">
                  <span>
                    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                      styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                  </span></div>
                  <div [ngClass]="{'absolute':problemGraphAsync,'relative':!problemGraphAsync}" [ngStyle]="{'width.px': graficoPassiWidth}">
                    <p-chart *ngIf="mostraChartContaPassiIot" type="line" [options]="optionsPassi2" [height]="300" [data]="dataContaPassiIot2" #chartContapassi2 ></p-chart>
                  </div>
                    <div style="max-width: 100%; overflow-x: scroll; position: relative; background-color: white; margin-left: 83px; z-index: 1;">
                    <div [ngStyle]="{'width.px': graficoPassiWidth}">
                      <p-chart *ngIf="mostraChartContaPassiIot" type="line" [options]="optionsPassi" [height]="300" [data]="dataContaPassiIot" #chartContapassi ></p-chart>
                    </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>


        <div class="row" *ngIf="stringheSelezionate.includes('Alert')">
          <div class="col-xs-12 col-sm-12">
            <div class="card" style="min-height:370px; max-height: 370px;">
              <div class="card-header card-header-text">
                <h4 class="card-title" style="display: inline;">Grafico Alert</h4>
                <select [(ngModel)]="selectedVisual" (ngModelChange)="changeVisual()" *ngIf="allarmi && allarmi.length > 0"
                    aria-controls="datatables" style="position: absolute; right: 52px; cursor: pointer;color: #2196F3;height: 32px; width: auto;
                    top: 5px;" class="form-control input-sm">
                    <option value="Giorno">Giorno</option>
                    <option value="Mese">Mese</option>
                    <option value="Anno">Anno</option>

                </select>
                <i class="material-icons notranslate" (click)="scegliDownload('alert')"  style="position: absolute; right: 20px; cursor: pointer;color: #2196F3">download</i>
              </div>
              <div class="card-content table-responsive"  style="overflow: hidden;">
                <div *ngIf="passi" class="tuttoBianco" >
                  <div class="text-center" >
                      <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                      styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                  </div>
              </div>
                <div *ngIf="!mostraChartAlertIot" class="m-20" style="justify-content: center; text-align: center;">
                  <span>
                    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                      styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                  </span></div>
                  <div [ngClass]="{'absolute':problemGraphAsync,'relative':!problemGraphAsync}" [ngStyle]="{'width.px': graficoAlertWidth}">
                    <p-chart type="bar" *ngIf="mostraChartAlertIot" [options]="optionsAlert2" [height]="300" [data]="dataAlertIot2" (onDataSelect)="selectData($event)"></p-chart>
                  </div>
                    <div style="max-width: 100%; overflow-x: scroll; position: relative; background-color: white; margin-left: 30px; z-index: 1;">
                    <div [ngStyle]="{'width.px': graficoAlertWidth}">
                      <p-chart type="bar" *ngIf="mostraChartAlertIot" [options]="optionsAlert" [height]="300" [data]="dataAlertIot" #chartContaalert (onDataSelect)="selectData($event)"></p-chart>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <!-- <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="card" style="min-height:370px; max-height: 370px;">
              <div class="card-header card-header-text">
                <h4 class="card-title" style="display: inline;">Grafico Battito Cardiaco</h4>

                <div class="dropdown" style="display: inline; position: absolute; right: 2%; margin-top: 3px;">
                  <a class="collapsed" id="dropdownMenu4" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="true">
                    {{BattitoVisual}}
                    <span class="caret"></span>
                  </a>
                  <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu4"
                    style="margin-left: -177%;">
                    <li>
                      <p-calendar [(ngModel)]="dataBattito" (click)="cambiaBattito()" [inline]="true"></p-calendar>

                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content table-responsive">
                <div *ngIf="!mostraChartBattitoCardiacoIot" class="m-20"
                  style="justify-content: center; text-align: center;"><span>
                    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                      styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                  </span></div>
                <p-chart *ngIf="mostraChartBattitoCardiacoIot" type="line" [options]="optionsBPM" [height]="300"
                  [data]="dataBattitoCardiacoIot"></p-chart>
              </div>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-xs-12 col-sm-12">

            <div class="card">
              <div class="card-header card-header-text">
                <h4 class="card-title" style="display: inline;">Grafico temperatura</h4>

                <div class="dropdown" style="display: inline; position: absolute; right: 2%; margin-top: 3px;">
                  <a class="collapsed" id="dropdownMenu5" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="true">
                    {{TempVisual}}
                    <span class="caret"></span>
                  </a>
                  <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu5"
                    style="margin-left: -177%;">
                    <li>
                      <p-calendar [(ngModel)]="dataTemperatura" (click)="cambiaTemp()" [inline]="true"></p-calendar>

                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content table-responsive">
                <div *ngIf="!mostraChartTemperaturaIot" class="m-20"
                  style="justify-content: center; text-align: center;">
                  <span>
                    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                      styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                  </span>
                </div>
                <p-chart *ngIf="mostraChartTemperaturaIot" type="line" [options]="optionsTemp" [height]="300"
                  [data]="dataTemperaturaIot"></p-chart>
              </div>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="card" style="min-height:370px; max-height: 370px;">
              <div class="card-header card-header-text">
                <h4 class="card-title" style="display: inline;">Grafico ContaPassi</h4>

                <div class="dropdown" style="display: inline; position: absolute; right: 2%; margin-top: 3px;">
                  <a class="collapsed" id="dropdownMenu7" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="true">
                    {{PassiVisual}}
                    <span class="caret"></span>
                  </a>
                  <ul class="dropdown-menu scrollable-menu" role="menu" aria-labelledby="dropdownMenu7"
                    style="margin-left: -177%;">
                    <li>
                      <p-calendar [(ngModel)]="dataPassi" (click)="cambiaPassi()" [inline]="true"></p-calendar>

                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content table-responsive">
                <div *ngIf="!mostraChartContaPassiIot" class="m-20"
                  style="justify-content: center; text-align: center;">
                  <span>
                    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8"
                      styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                  </span>
                </div>
                <p-chart *ngIf="mostraChartContaPassiIot" type="line" [options]="optionsPassi" [height]="300"
                  [data]="dataContaPassiIot"></p-chart>
              </div>
            </div>
          </div>
        </div> -->
      </ng-container>
    </ng-container>
  </div>
</div>

<div *ngIf="dataAll && dataAll.datasets && dataAll.datasets[0] && dataAll.datasets[0].data" style="
overflow-x: auto;opacity: 0;position: absolute;">
  <div [ngStyle]="{'width.px': (5*dataAll.datasets[0].data.length)}" style="height: 300px;min-width: 300px;">
    <p-chart type="line" [height]="300" [data]="dataAll" [options]="optionsAll" #chartAll></p-chart>
  </div>
</div>

