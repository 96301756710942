import { Component, OnInit, Output,EventEmitter, Input, ViewChild } from '@angular/core';
import { ClienteService } from 'src/app/SERVICES/CLIENTE/cliente.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cliente } from 'src/app/MODELS/USER/cliente';
import { AssociaGruppo } from 'src/app/MODELS/USER/associaGruppo';

@Component({
  selector: 'app-associa-gruppo',
  templateUrl: './associa-gruppo.component.html',
  styleUrls: ['./associa-gruppo.component.scss']
})
export class AssociaGruppoComponent implements OnInit {
  @Output() tornaGestioneAssistiti= new EventEmitter<boolean>();
  @Input() assistito:Cliente;
  
  listaGruppi:any=[];
  listaComandi = [];
  displayedColumns: string[] = [
    'nome_equipe',
    'visualizza',
    'scrittura',
  ];

  refreshView = true;
  isClickable = true;
  caricato=false;
  showTable = true;
  valoreRicerca;
  listaToSend=[];
  listaConst=[];
  user;

  loading=false;
  constructor(
    private clienteService:ClienteService,
   ) {}

  ngOnInit(): void {
    
    this.getListaEquipe(this.assistito.id)
  }

  tornaGesAssistiti(vai){
    this.tornaGestioneAssistiti.emit(vai);
  }

  getListaEquipe(id) {
    this.refreshView = false;

    this.clienteService.getListaEquipe(id).subscribe((res) =>{
      this.listaGruppi = JSON.parse(JSON.stringify(res.data.lista)); 
      this.listaConst = JSON.parse(JSON.stringify(res.data.lista));
      this.caricato = true;
    },
    (error) => {
      this.tornaGesAssistiti(true);
    })
       
        this.isClickable = true;
        this.refreshView = true;
        this.showTable = true;
    }

    sendList(){
      this.loading=true;

      this.listaGruppi.forEach(element=>{
       let associaGruppo:AssociaGruppo=new AssociaGruppo();
        associaGruppo.idEquipe = element.idGruppoCondiviso;
        associaGruppo.read = element.read;
        associaGruppo.write = element.write;
        this.listaToSend.push(associaGruppo)
      })

      this.clienteService.sendList(this.assistito.id,this.listaToSend).subscribe((res) => {
        this.loading=false;
        this.tornaGesAssistiti(true)
      },
      (error) => {
        this.loading = false;
      }
    );
    this.listaToSend=[];
    }


}