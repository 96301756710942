import { Component, HostListener, OnInit } from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AccountService, AlertService } from 'src/app/SERVICES';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { PrenotazioneTecnicoService } from 'src/app/SERVICES/PRENOTAZIONETECNICO/prenotazione.service';
import { stringify } from 'querystring';

const colors: any = {

  0: {
    primary: '#D3D3D3',
    secondary: '#D3D3D3',
  },
  4: {
    primary: '#D3D3D3',
    secondary: '#D3D3D3',
  },
  2: {
    primary: '#FFCE56',
    secondary: '#FDF1BA',
  },
  1: {
    primary: '#FF6384',
    secondary: '#ffc2cf',
  },
  3:{
    primary: '#bce08c',
    secondary: '#e3ffbd'
  }
};
@Component({
  selector: 'app-prenotazione-tecnico',
  templateUrl: './prenotazione-tecnico.component.html',
  styleUrls: ['./prenotazione-tecnico.component.scss']
})
export class PrenotazioneTecnicoComponent implements OnInit {

  isDesktop = true;
  showForm = false;
  showCreazioneEventi = false;
  activeDayIsOpen: boolean = true;
  ruolo
  animation = false;
  costanteInizio=1;
  showModifica = false;
  idModifica;
  dataSelezionata;
  CalendarView = CalendarView;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="material-icons notranslate" style="color: blue;">home</i>',
      cssClass: 'iconaModTecnico',
      onClick: ({ event }: { event: CalendarEvent }): void => {
          this.vaiModifica(event.id);
      },
    }
  ]

  actionsOutbound: CalendarEventAction[] = [
    {
      label: '<i class="material-icons notranslate" style="color: blue;">outbound</i>',
      cssClass: 'iconaModTecnico',
      onClick: ({ event }: { event: CalendarEvent }): void => {
          this.vaiModifica(event.id);
      },
    }
  ]
  showCalendar = false;
  events: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();
  schedule=false;
  firstView=true;
  viewDate: Date = new Date();
  todayDate: Date = new Date();
  view: CalendarView = CalendarView.Month;
  isFromElimina: boolean = false;
  addMode = false;
  editMode = false;
  idEventoSelezionato;

  constructor(
    private tecnicoService: PrenotazioneTecnicoService,
    private deviceService: DeviceDetectorService,
    private accountService: AccountService,
    private alertService: AlertService
    ) {
    this.isDesktop = this.deviceService.isDesktop();
    this.ruolo = this.accountService.ruolo;
  }

  ngOnInit(): void {
    this.getEvents();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth<1280) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
    }
  }

  getEvents() {

    this.tecnicoService.recuperaEventi().subscribe((res)=>{
      this.showCalendar = false;
      this.events = [];
      res.data.forEach((element)=>{
        let action = null;
        let photocard: CalendarEventAction[];
        let tmpLinkPhoto;
        if(element.sede===0 && element.tecnico.linkThumbnail)
        {
          tmpLinkPhoto = element.tecnico.linkThumbnail;
        }
        else
        {
          if(element.linkThumbnailEvento) {
            tmpLinkPhoto = element.linkThumbnailEvento;
          } else {
            tmpLinkPhoto = "/assets/img/faces/user.jpg";
          }
        }
        photocard = [
          {
            label: '<img src="' + tmpLinkPhoto + '" class="fototessera bordo-azzurro">',
            cssClass: 'fototessera',
            onClick: ({ event }: { event: CalendarEvent }): void => {

            },
          }
        ]
        if (this.ruolo === 1) {
          action = [this.actions[0], photocard[0]];
          if(element.sede === 0) {
            action = [this.actionsOutbound[0], photocard[0]];
          }
        }
        else
        {
          action = [photocard[0]];
        }
      let event: CalendarEvent = {
        id: element.id,
        start: new Date(element.dataInizio),
        end: new Date(element.dataFine),
        title: new Date(element.dataInizio).getHours()+':'+ (new Date(element.dataInizio).getMinutes()<10? '0'+new Date(element.dataInizio).getMinutes():new Date(element.dataInizio).getMinutes()) + ' ' + element.nomeEvento,
        color: colors[element.stato],
        //fototessera: element.tecnico.linkPhoto,
        actions: action,
        allDay: false,
        resizable: {
          beforeStart: false,
          afterEnd: false,
        },
        draggable: false,
      }
      //fix 1315
      if(this.todayDate > event.end)
      {
        event.color = colors[3];
        //event.actions = null;
      }
      this.events.push(event)

      })
      this.showCalendar = true;
    })
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if(!this.addMode)
    {
      if (isSameMonth(date, this.viewDate)) {
        if (
          (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
          events.length === 0
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen = true;
        }
        this.viewDate = date;
      }
    }
    else
    {
      if(date >= startOfDay(this.todayDate))
      {
        this.dataSelezionata = date;
        this.showCreazioneEventi = true;
      }
      else
      {
        this.alertService.error("Non è possibile creare eventi per una data passata.");
      }
    }
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  creaSchedule(){
    this.schedule=true;
    this.firstView =false;
  }
  setView(view: CalendarView) {
    this.view = view;
  }
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    // this.handleEvent('Dropped or resized', event);
  }
  creaForm(isFromElimina = false) {
    this.isFromElimina = isFromElimina;
    this.showForm = !this.showForm
  }
  vaiModifica(id) {
    this.idModifica = id;
    this.showModifica = true;
    this.showForm = false;
  }
  backModifica() {
    this.getEvents();
    this.showModifica = false;
  }

  changeMode()
  {
    this.addMode = !this.addMode;
    this.activeDayIsOpen = false;
    if(this.addMode)
    {
      this.alertService.infoNotSticky("Modalità creazione attiva");
    }
    else
    {
      this.alertService.infoNotSticky("Modalità visualizzazione attiva");
    }
  }

  backCreazioneEvento()
  {
    this.getEvents()
    this.showCreazioneEventi = false;
  }
}
