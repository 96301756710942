<div class="content" *ngIf="login">
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-lg-{{ 12 / contatoreModuli }} col-md-{{
          12 / contatoreModuli
        }} col-sm-12 widget"
      >
        <div class="card card-stats">
          <div class="card-header">
            <div class="icon icon-warning">
              <i class="zmdi zmdi-equalizer"></i>
            </div>
          </div>
          <div class="card-content">
            <p class="category"><strong>Visite</strong></p>
            <h5 class="card-title">{{ visite }}</h5>
          </div>
          <div class="card-footer">
            <div class="stats" *ngIf="!visitView">
              <i class="material-icons text-info notranslate">arrow_back</i>
              <a
                (click)="cambiaView('visit')"
                class="sizeFontTorna"
                style="cursor: pointer"
              >
                Torna alla dashboard</a
              >
            </div>
            <div class="stats flex" *ngIf="visitView">
              <i class="material-icons">update</i>
              <p
                style="padding: 0; margin: 0; max-width: initial"
                class="one-line"
              >
                <span> Ultimo update: {{ dataVisite | dateAgo }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-{{ 12 / contatoreModuli }} col-md-{{
          12 / contatoreModuli
        }} col-sm-12 widget"
        *ngIf="classroom"
      >
        <div class="card card-stats">
          <div class="card-header">
            <div class="icon icon-rose">
              <i class="material-icons notranslate">school</i>
            </div>
          </div>
          <div class="card-content">
            <p class="category"><strong>Modulo:</strong></p>
            <h5 class="card-title sizeFontEducation">Education</h5>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons text-info notranslate">info</i>
              <a (click)="cambiaView('aula')" style="cursor: pointer"
                >Report dettagliato</a
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-{{ 12 / contatoreModuli }} col-md-{{
          12 / contatoreModuli
        }} col-sm-12 widget"
        *ngIf="iot"
      >
        <div class="card card-stats">
          <div class="card-header">
            <div class="icon icon-success">
              <i class="material-icons notranslate">devices_other</i>
            </div>
          </div>
          <div class="card-content">
            <p class="category"><strong>Modulo:</strong></p>
            <h5 class="card-title">IoT</h5>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons text-info">info</i>
              <a (click)="cambiaView('iot')" style="cursor: pointer"
                >Report dettagliato</a
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-{{ 12 / contatoreModuli }} col-md-{{
          12 / contatoreModuli
        }} col-sm-12 widget"
        *ngIf="assistenza"
      >
        <div class="card card-stats">
          <div class="card-header">
            <div class="icon icon-info">
              <i _ngcontent-gdv-c329="" class="material-icons notranslate"
                >headset_mic</i
              >
            </div>
          </div>
          <div class="card-content">
            <p class="category"><strong>Modulo:</strong></p>
            <h5 class="card-title sizeFontAssistenza">Assistenza</h5>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons text-info">info</i>
              <a (click)="cambiaView('assistenza')" style="cursor: pointer"
                >Report dettagliato</a
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-{{ 12 / contatoreModuli }} col-md-{{
          12 / contatoreModuli
        }} col-sm-12 widget"
        *ngIf="alfaCallCenter"
      >
        <div class="card card-stats">
          <div class="card-header">
            <div class="icon icon-info">
              <i _ngcontent-gdv-c329="" class="material-icons notranslate"
                >phone</i
              >
            </div>
          </div>
          <div class="card-content">
            <p class="category"><strong>Modulo:</strong></p>
            <h5 class="card-title sizeFontAssistenza">Call Center</h5>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons text-info">info</i>
              <a (click)="cambiaView('callCenter')" style="cursor: pointer"
                >Visualizza calendario</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="visitView">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Task List</h4>
            <div style="position: absolute; top: 10px; right: 20px">
              <button
                (click)="vaiCreazione()"
                type="button"
                class="btn btn-primary btn-round"
                style="padding: 8px 8px 8px 8px; margin-left: 35px"
                [disabled]="!crea"
              >
                <i class="material-icons notranslate">add</i>
              </button>
            </div>
          </div>
          <div class="card-content">
            <h5 *ngIf="listaTasksVerifica.length < 1">
              Inizia aggiungendo una task...
            </h5>
            <table
              *ngIf="listaTasksVerifica.length > 0"
              id="datatables"
              class="table table-no-bordered table-hover"
              cellspacing="0"
              width="100%"
              style="width: 100%"
            >
              <tbody>
                <tr *ngFor="let task of listaTasksVerifica; let i = index">
                  <td>
                    <div class="checkbox">
                      <label>
                        <input
                          [(ngModel)]="task.stato"
                          type="checkbox"
                          name="optionsCheckboxes"
                          (click)="checked(task)"
                          [disabled]="!task.descrizione || task.modifica"
                        /><span class="checkbox-material"
                          ><span class="check"></span
                        ></span>
                      </label>
                    </div>
                  </td>
                  <td style="width: 85%">
                    <div class="form-group label-floating is-empty">
                      <input
                        type="text"
                        placeholder="Aggiungi una task"
                        [(ngModel)]="task.descrizione"
                        name="formTask"
                        class="form-control"
                        *ngIf="task.modifica"
                        #myInput
                        maxlength="100"
                      />
                      <p *ngIf="!task.modifica">{{ task.descrizione }}</p>
                    </div>
                  </td>
                  <td class="td-actions text-right">
                    <a
                      *ngIf="!task.modifica"
                      (click)="vaiModifica(task)"
                      rel="tooltip"
                      title="Modifica task"
                      class="btn btn-success btn-simple"
                      style="padding: 2px 2px 2px 2px"
                      ><i
                        class="material-icons notranslate pointer"
                        style="font-size: 20px"
                        >edit</i
                      ></a
                    >

                    <button
                      (click)="vaiInfo(task)"
                      [disabled]="!task.descrizione"
                      *ngIf="task.modifica"
                      rel="tooltip"
                      title="Verifica"
                      class="btn btn-success btn-simple"
                      style="padding: 2px 2px 2px 2px"
                    >
                      <i
                        class="material-icons notranslate pointer"
                        style="font-size: 20px"
                        >done</i
                      >
                    </button>

                    <a
                      (click)="elimina(task)"
                      rel="tooltip"
                      title="Elimina task"
                      class="btn btn-simple btn-danger btn-icon remove"
                      style="padding: 2px 2px 2px 2px"
                      ><i
                        class="material-icons notranslate pointer"
                        style="font-size: 20px"
                        >close</i
                      ></a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="aulaView">
      <!-- TAB STATICHE -->
      <div class="row">
        <div
          class="col-lg-6 col-md-12 col-sm-12 widget"
          style="margin-bottom: 70px"
        >
          <div class="card cardClassroom">
            <div class="row h-106">
              <div
                class="col-lg-{{ 1 }} col-md-{{ 1 }} col-sm-{{ 1 }} col-xs-{{
                  1
                }}"
              ></div>
              <div
                class="col-lg-{{ 4 }} col-md-{{ 4 }} col-sm-{{ 4 }} col-xs-{{
                  4
                }}"
              >
                <div class="marginTop_8">
                  <p>EVENTI SEGUITI</p>
                  <p style="font-size: x-large">
                    <strong>{{ counterEventiSeguiti }}</strong>
                  </p>
                </div>
              </div>
              <div
                class="col-lg-{{ 3 }} col-md-{{ 2 }} col-sm-{{ 0 }} col-xs-{{
                  0
                }}"
              ></div>
              <div
                class="col-lg-{{ 3 }} col-md-{{ 4 }} col-sm-{{ 6 }} col-xs-{{
                  6
                }} allineaDestra"
              >
                <div class="quadratoEvento">
                  <i
                    class="material-icons notranslate pointer"
                    style="font-size: 50px; color: #2c42e5"
                  >
                    event_available</i
                  >
                </div>
              </div>
              <div
                class="col-lg-{{ 1 }} col-md-{{ 1 }} col-sm-{{ 1 }} col-xs-{{
                  1
                }}"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-{{ 6 }} col-md-{{ 12 }} col-sm-{{ 12 }} widget"
          style="margin-bottom: 70px"
        >
          <div class="card cardClassroom">
            <div class="row h-106">
              <div
                class="col-lg-{{ 1 }} col-md-{{ 1 }} col-sm-{{ 1 }} col-xs-{{
                  1
                }}"
              ></div>
              <div
                class="col-lg-{{ 5 }} col-md-{{ 5 }} col-sm-{{ 5 }} col-xs-{{
                  5
                }}"
              >
                <div class="marginTop_8">
                  <p>EVENTI IN PROGRAMMA</p>
                  <p style="font-size: x-large">
                    <strong>{{ counterEventiInProgramma }}</strong>
                  </p>
                </div>
              </div>
              <div
                class="col-lg-{{ 2 }} col-md-{{ 2 }} col-sm-{{ 0 }} col-xs-{{
                  0
                }}"
              ></div>
              <div
                class="col-lg-{{ 3 }} col-md-{{ 3 }} col-sm-{{ 5 }} col-xs-{{
                  5
                }} allineaDestra"
              >
                <div class="quadratoProgramma">
                  <i
                    class="material-icons notranslate pointer"
                    style="font-size: 50px; color: #35bc77"
                    >book</i
                  >
                </div>
              </div>
              <div
                class="col-lg-{{ 1 }} col-md-{{ 1 }} col-sm-{{ 1 }} col-xs-{{
                  1
                }}"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- TAB STATICHE FINE -->

      <div *ngIf="listaAule.length > 0" class="row">
        <div
          *ngFor="let aula of listaAule.slice().reverse(); let i = index"
          class="col-lg-{{ 6 }} col-md-{{ 12 }} col-sm-{{ 12 }} widget"
          style="margin-bottom: 70px"
        >
          <div
            *ngIf="aula"
            (click)="vaiAula(aula)"
            class="card cardClassroom scaleHover"
            [ngClass]="{ bloccoClickClasse: controlloBloccoClass(aula.end) }"
          >
            <div class="row h-106">
              <div
                class="col-lg-{{ 1 }} col-md-{{ 1 }} col-sm-{{ 1 }} col-xs-{{
                  1
                }}"
              ></div>
              <div
                class="col-lg-{{ 5 }} col-md-{{ 5 }} col-sm-{{ 5 }} col-xs-{{
                  5
                }}"
              >
                <div class="marginTop_8">
                  <p class="one-line" style="font-size: large">
                    <span>{{ aula.nomeAula }}</span>
                  </p>
                  <p
                    *ngIf="aula.start"
                    class="one-line"
                    style="font-size: small; margin-top: -12px"
                  >
                    <span
                      >dal {{ aula.start | date: "dd-MM-yyyy" }} all
                      {{ aula.end | date: "dd-MM-yyyy" }}</span
                    >
                  </p>

                  <p class="one-line">
                    <span>{{ aula.descrizioneAula }}</span>
                  </p>
                </div>
              </div>
              <div
                class="col-lg-{{ 2 }} col-md-{{ 2 }} col-sm-{{ 0 }} col-xs-{{
                  0
                }}"
              ></div>
              <div
                class="col-lg-{{ 3 }} col-md-{{ 3 }} col-sm-{{ 5 }} col-xs-{{
                  5
                }} allineaDestra"
              >
                <div class="quadratoImmagine">
                  <img [src]="aula.copertina" />
                </div>
              </div>
              <div
                class="col-lg-{{ 1 }} col-md-{{ 1 }} col-sm-{{ 1 }} col-xs-{{
                  1
                }}"
              ></div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-{{6}} col-md-{{12}} col-sm-{{12}} widget">
                        <div *ngIf="aula" class="card cardClassroom">
                            <div class="row">
                                <div class="col-lg-{{1}} col-md-{{1}} col-sm-{{1}} col-xs-{{1}}"></div>
                                <div class="col-lg-{{4}} col-md-{{4}} col-sm-{{4}} col-xs-{{4}}">
                                    <div class="marginTop_8">
                                        <p style="font-size: x-large;">{{aula.nomeAula}} - {{aula?.start}}</p>
                                        <p>{{aula.descrizioneAula}}</p>
                                    </div>
                                </div>
                                <div class="col-lg-{{3}} col-md-{{3}} col-sm-{{0}} col-xs-{{0}}"></div>
                                <div class="col-lg-{{3}} col-md-{{3}} col-sm-{{6}} col-xs-{{6}} allineaDestra">
                                    <div class="quadratoImmagine">
                                        <img [src]="aula.copertina">
                                    </div>
                                </div>
                                <div class="col-lg-{{1}} col-md-{{1}} col-sm-{{1}} col-xs-{{1}}"></div>
                            </div>
                        </div>
                    </div> -->
      </div>
      <br />
      <br />
    </div>

    <div class="row" *ngIf="iotView">
      <div class="col-xs-12 col-sm-12 col-md-8">
        <div class="card" style="min-height: 370px; max-height: 370px">
          <div class="card-header">
            <h4 class="card-title" style="display: inline">
              Grafico pressione
            </h4>

            <div
              *ngIf="listaDispositivi.length > 0"
              class="dropdown"
              style="
                display: inline;
                position: absolute;
                right: 3%;
                margin-top: 3px; ;
              "
            >
              <a
                class="collapsed"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {{ scegliDispGrafPress }}
                <span class="caret"></span>
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="dropdownMenu1"
                style="overflow-x: auto; max-height: 1400%; left: -74%"
              >
                <li *ngFor="let dispositivo of listaDispositivi; let i = index">
                  <a
                    (click)="
                      scegliGraficoPressione(
                        dispositivo.id,
                        dispositivo.deviceName
                      )
                    "
                    >{{ dispositivo.deviceName }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="card-content table-responsive">
            <div
              *ngIf="!mostraChartPressioneIot"
              class="m-20"
              style="justify-content: center; text-align: center"
            >
              <span>
                <p-progressSpinner
                  [style]="{ width: '30px', height: '30px' }"
                  strokeWidth="8"
                  styleClass="custom-spinner"
                  animationDuration=".5s"
                ></p-progressSpinner>
              </span>
            </div>
            <p-chart
              *ngIf="mostraChartPressioneIot"
              type="bar"
              [height]="300"
              [data]="dataPressioneIot"
              #chart
            >
            </p-chart>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4">
        <div class="card" style="min-height: 370px; max-height: 370px">
          <div class="card-header card-header-text">
            <h4 class="card-title" style="display: inline">
              Grafico telemetria
            </h4>
            <div
              *ngIf="arrayobject.length > 0"
              class="dropdown"
              style="
                display: inline;
                position: absolute;
                right: 3%;
                margin-top: 3px; ;
              "
            >
              <a
                class="collapsed"
                id="dropdownMenu3"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                Scelta dispositivi
                <span class="caret"></span>
              </a>
              <ul
                class="dropdown-menu scrollable-menu"
                role="menu"
                aria-labelledby="dropdownMenu3"
                style="
                  overflow-x: auto;
                  max-height: 1400%;
                  min-height: 120%;
                  left: -84%;
                "
              >
                <li
                  *ngFor="let dispositivo of arrayobject; let i = index"
                  class="no-wrap"
                >
                  <div class="form-check">
                    <input
                      style="margin-left: 10%"
                      class="form-check-input"
                      type="checkbox"
                      (click)="
                        creagraficoTelemetria(dispositivo, $event.srcElement)
                      "
                      class="form-check-input"
                      name="idDevice"
                    /><label class="form-check-label" style="margin-left: 5px">
                      {{ dispositivo.nomeDevice }}</label
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="card-content table-responsive">
            <div
              *ngIf="!mostraChartTelemetriaIot"
              class="m-20"
              style="justify-content: center; text-align: center"
            >
              <span>
                <p-progressSpinner
                  [style]="{ width: '30px', height: '30px' }"
                  strokeWidth="8"
                  styleClass="custom-spinner"
                  animationDuration=".5s"
                ></p-progressSpinner>
              </span>
            </div>
            <p-chart
              *ngIf="mostraChartTelemetriaIot"
              type="radar"
              [height]="300"
              [data]="dataTelemetriaIot"
            ></p-chart>
          </div>
        </div>
      </div>

      <!-- Grafico Rilevazioni -->

      <div class="col-xs-12 col-sm-12" *ngIf="iot">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title" style="display: inline">
              Grafico rilevazioni
            </h4>

            <div
              *ngIf="listaDispositivi.length > 0"
              class="dropdown"
              style="
                display: inline;
                position: absolute;
                right: 3%;
                margin-top: 3px; ;
              "
            >
              <a
                class="collapsed"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {{ scegliDispGrafBatt }}
                <span class="caret"></span>
              </a>
              <ul
                class="dropdown-menu scrollable-menu pull-right"
                role="menu"
                aria-labelledby="dropdownMenu2"
                style="overflow-x: auto; max-height: 1400%; min-height: 120%"
              >
                <li *ngFor="let dispositivo of listaDispositivi; let i = index">
                  <a
                    (click)="
                      scegliGraficoBatteria(
                        dispositivo.id,
                        dispositivo.deviceName
                      )
                    "
                    >{{ dispositivo.deviceName }}</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="card-content table-responsive">
            <div
              *ngIf="!mostraChartBatteriaIot"
              class="m-20"
              style="justify-content: center; text-align: center"
            >
              <span>
                <p-progressSpinner
                  [style]="{ width: '60px', height: '280px' }"
                  strokeWidth="8"
                  styleClass="custom-spinner2"
                  animationDuration=".5s"
                ></p-progressSpinner>
              </span>
            </div>
            <p-chart
              *ngIf="mostraChartBatteriaIot"
              type="line"
              [height]="300"
              [data]="dataBatteriaIot"
            >
            </p-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="assistenzaView">
      <div class="col-xs-12 col-sm-12 col-md-8">
        <div class="card" style="min-height: 370px; max-height: 370px">
          <div class="card-header card-header-text">
            <h4 class="card-title">Trend annuale assistenza</h4>
          </div>
          <div class="card-content table-responsive">
            <div
              *ngIf="!mostraChart2"
              class="m-20"
              style="justify-content: center; text-align: center"
            >
              <span>
                <p-progressSpinner
                  [style]="{ width: '30px', height: '30px' }"
                  strokeWidth="8"
                  styleClass="custom-spinner"
                  animationDuration=".5s"
                ></p-progressSpinner>
              </span>
            </div>
            <p-chart
              *ngIf="mostraChart2"
              type="line"
              [height]="300"
              [data]="data2"
              #chart
            ></p-chart>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4">
        <div class="card" style="min-height: 370px; max-height: 370px">
          <div class="card-header card-header-text">
            <h4 class="card-title">Trend globale assistenza</h4>
          </div>
          <div class="card-content table-responsive">
            <div
              *ngIf="!mostraChart"
              class="m-20"
              style="justify-content: center; text-align: center"
            >
              <span>
                <p-progressSpinner
                  [style]="{ width: '30px', height: '30px' }"
                  strokeWidth="8"
                  styleClass="custom-spinner"
                  animationDuration=".5s"
                ></p-progressSpinner>
              </span>
            </div>
            <p-chart
              *ngIf="mostraChart"
              type="doughnut"
              [height]="300"
              [data]="data"
            ></p-chart>
          </div>
        </div>
      </div>
      <div>
        <!--OPERATORE E ADMIN-->
        <div *ngIf="!utente">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="card" style="min-height: 465px; max-height: 465px">
              <div class="card-header card-header-text">
                <h4 class="card-title">Ultimi utenti loggati</h4>
                <!-- <p class="category">New employees on 15th December, 2016</p> -->
              </div>
              <div
                class="card-content table-responsive"
                style="max-height: 403px"
              >
                <div style="justify-content: center; text-align: center">
                  <span *ngIf="loading">
                    <p-progressSpinner
                      [style]="{ width: '30px', height: '30px' }"
                      strokeWidth="8"
                      styleClass="custom-spinner"
                      animationDuration=".5s"
                    ></p-progressSpinner>
                  </span>
                </div>
                <table *ngIf="!loading" class="table table-hover">
                  <thead class="text-primary">
                    <th>Nome</th>
                    <th>Cognome</th>
                    <th>Indirizzo</th>
                    <th>Comune</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let utente of listaUltimiUtenti">
                      <td>{{ utente.utente.nome }}</td>
                      <td>{{ utente.utente.cognome }}</td>
                      <td>{{ utente.indirizzo }}</td>
                      <td>
                        <p *ngIf="utente.capComune.length === 1">
                          {{ utente.capComune[0] }}
                        </p>
                        <p
                          *ngIf="utente.capComune.length > 1"
                          data-mdb-toggle="tooltip"
                          data-bs-html="true"
                          [title]="utente.capComune[1]"
                        >
                          {{ utente.capComune[0] }}...
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            *ngIf="assistenza"
          >
            <div class="card" style="min-height: 465px; max-height: 465px">
              <div class="card-header">
                <h4 class="card-title">Ultime chiamate</h4>
              </div>
              <div class="card-content">
                <div style="justify-content: center; text-align: center">
                  <span *ngIf="loading2">
                    <p-progressSpinner
                      [style]="{ width: '30px', height: '30px' }"
                      strokeWidth="8"
                      styleClass="custom-spinner"
                      animationDuration=".5s"
                    ></p-progressSpinner>
                  </span>
                </div>
                <div class="streamline" *ngIf="!loading2">
                  <div
                    class="streamline"
                    *ngFor="let chiamata of listaUltimeChiamate; let i = index"
                  >
                    <div
                      class="sl-item sl-primary"
                      *ngIf="chiamata.motivoChiamata !== 'Persa'"
                    >
                      <div class="sl-content">
                        <p>
                          {{ chiamata.nome }} {{ chiamata.cognome }} -
                          {{ chiamata.motivoChiamata }}
                          <small class="text-muted">{{
                            chiamata.data | date: "dd-MM-yyyy HH:mm:ss"
                          }}</small>
                        </p>
                      </div>
                    </div>
                    <div
                      class="sl-item sl-danger"
                      *ngIf="chiamata.motivoChiamata === 'Persa'"
                    >
                      <div class="sl-content">
                        <p>
                          {{ chiamata.nome }} {{ chiamata.cognome }}
                          <small class="text-muted">{{
                            chiamata.data
                              | date
                                : "dd-MM-yyyy
                            HH:mm:ss"
                          }}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--UTENTE-->

      <div *ngIf="utente">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="card" style="min-height: 465px; max-height: 465px">
            <div class="card-header card-header-text">
              <h4 class="card-title">Ultime posizioni</h4>
              <!-- <p class="category">New employees on 15th December, 2016</p> -->
            </div>
            <div
              class="card-content table-responsive"
              style="max-height: 403px"
            >
              <div style="justify-content: center; text-align: center">
                <span *ngIf="loading">
                  <p-progressSpinner
                    [style]="{ width: '30px', height: '30px' }"
                    strokeWidth="8"
                    styleClass="custom-spinner"
                    animationDuration=".5s"
                  ></p-progressSpinner>
                </span>
              </div>
              <table *ngIf="!loading" class="table table-hover">
                <thead class="text-primary">
                  <!-- <th>Nome</th>
                                        <th>Cognome</th> -->
                  <th>Indirizzo</th>
                  <th>Comune</th>
                </thead>
                <tbody>
                  <tr *ngFor="let utente of listaUltimePosizioni">
                    <td>{{ utente.indirizzo }}</td>
                    <td>
                      <p *ngIf="utente.capComune.length === 1">
                        {{ utente.capComune[0] }}
                      </p>
                      <p
                        *ngIf="utente.capComune.length > 1"
                        data-mdb-toggle="tooltip"
                        data-bs-html="true"
                        [title]="utente.capComune[1]"
                      >
                        {{ utente.capComune[0] }}...
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          *ngIf="assistenza"
        >
          <div class="card" style="min-height: 465px; max-height: 465px">
            <div class="card-header">
              <h4 class="card-title">Ultime chiamate</h4>
            </div>
            <div class="card-content">
              <div style="justify-content: center; text-align: center">
                <span *ngIf="loading2">
                  <p-progressSpinner
                    [style]="{ width: '30px', height: '30px' }"
                    strokeWidth="8"
                    styleClass="custom-spinner"
                    animationDuration=".5s"
                  ></p-progressSpinner>
                </span>
              </div>
              <div *ngIf="!loading2">
                <div
                  class="streamline"
                  *ngFor="
                    let chiamata of listaUltimeChiamateUtente;
                    let i = index
                  "
                >
                  <div
                    class="sl-item sl-primary"
                    *ngIf="chiamata.motivoChiamata !== 'Persa'"
                  >
                    <div class="sl-content">
                      <p>
                        {{ chiamata.nome }} {{ chiamata.cognome }} -
                        {{ chiamata.motivoChiamata }}
                        <small class="text-muted">{{
                          chiamata.data | date: "dd-MM-yyyy HH:mm:ss"
                        }}</small>
                      </p>
                    </div>
                  </div>
                  <div
                    class="sl-item sl-danger"
                    *ngIf="chiamata.motivoChiamata === 'Persa'"
                  >
                    <div class="sl-content">
                      <p>
                        {{ chiamata.nome }} {{ chiamata.cognome }}
                        <small class="text-muted">{{
                          chiamata.data | date: "dd-MM-yyyy HH:mm:ss"
                        }}</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="viewCallCenter">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="card">
          <div class="card-header card-header-text">
            <app-calendar-call-center></app-calendar-call-center>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
