<div class="content">
    <div class="container-fluid">
        <!-- <p-messages [value]="msgs" ></p-messages> -->

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <form [formGroup]="createForm" (ngSubmit)="onSubmit()" class="form-horizontal">
                        <div class="card-header">
                            <div class="card-title">
                                <button (click)="goBack()" id="back-button" type="button"
                                    class="btn btn-round  btn-just-icon"
                                    style="margin-top: 3px; color: rgb(54, 54, 54); box-shadow:none!important">
                                    <i class="material-icons notranslate">arrow_back</i>
                                </button>
                                <!-- step1 -->
                                <h4 style="display: inline;">{{title}}</h4>
                            </div>
                        </div>
                        <div class="card-content">
                            <div class="row">
                                <label class="col-sm-2 label-on-left">Nome</label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" placeholder="Nome" formControlName="nome"
                                            class="form-control" [ngClass]="{ 'is-invalid': f.nome.errors }"
                                            maxlength="30" />
                                        <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                                            <div *ngIf="f.nome.errors.required">Campo nome obbligatorio</div>
                                            <div *ngIf="!f.nome.errors.required && f.nome.errors.noWhiteSpaceValidator">
                                                Campo nome invalido</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left">Indirizzo</label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" placeholder="Indirizzo" formControlName="indirizzo"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.indirizzo.errors }"
                                            maxlength="100" />
                                        <div *ngIf="submitted && f.indirizzo.errors" class="invalid-feedback">
                                            <div *ngIf="f.indirizzo.errors.required">Campo indirizzo obbligatorio</div>
                                            <div
                                                *ngIf="!f.indirizzo.errors.required && f.indirizzo.errors.noWhiteSpaceValidator">
                                                Campo indirizzo
                                                invalido
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left">Cliente</label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" placeholder="Cliente" formControlName="cliente"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.cliente.errors }"
                                            maxlength="50" />
                                        <div *ngIf="submitted && f.cliente.errors" class="invalid-feedback">
                                            <div *ngIf="f.cliente.errors.required">Campo cliente
                                                obbligatorio</div>
                                            <div
                                                *ngIf="!f.cliente.errors.required && f.cliente.errors.noWhiteSpaceValidator">
                                                Campo cliente invalido
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="stati">
                                <label class="col-sm-2 label-on-left">Stato</label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty char">
                                        <p-selectButton [options]="stati" formControlName="stato" name="stato">
                                        </p-selectButton>
                                        <div *ngIf="submitted && f.stato.errors" class="invalid-feedback-2">
                                            <div *ngIf="f.stato.errors.required">Campo stato obbligatorio</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div [hidden]="f.stato.value != 'completato'" class="row">
                                <label class="col-sm-2 label-on-left">Ultimo Sopralluogo</label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <p-calendar formControlName="dataSopralluogo" name="dataSopralluogo"
                                            dateFormat="dd/mm/yy" [required]="f.stato.value == 'completato'">
                                        </p-calendar>
                                        <div *ngIf="submitted && f.dataSopralluogo.errors" class="invalid-feedback-2">
                                            <div *ngIf="f.dataSopralluogo.errors.required">Campo Ultimo Sopralluogo
                                                obbligatorio</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="text-right" style="margin-right: 5%;">
                                <hr>
                                <div class="btn-group" role="group" aria-label="Basic example">

                                    <button type="button" (click)="clear()" class="btn btn-secondary"
                                        aria-pressed="true" [disabled]="loading">Cancella</button>
                                </div>
                                <div *ngIf="!isEdit" class="btn-group" role="group" aria-label="Basic example"
                                    aria-pressed="true">
                                    <button [disabled]="loading" type="sumbit" class="btn btn-primary">Salva
                                        <span *ngIf="loading">
                                            <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                                                styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                                        </span>
                                    </button>
                                </div>
                                <div *ngIf="isEdit" class="btn-group" role="group" aria-label="Basic example"
                                    aria-pressed="true">
                                    <button [disabled]="loading" type="sumbit" class="btn btn-primary">Aggiorna
                                        <span *ngIf="loading">
                                            <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                                                styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                                        </span>
                                    </button>
                                </div>
                            </div><br>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>