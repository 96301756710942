import { Injectable, Host } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Utente } from 'src/app/MODELS/USER/utente';
import { AccountService } from './account.service';
import { EnvService } from '../GENERAL/env.service';
import { Pageable } from '../GENERAL/pageable';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  baseUrl: string;
  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private env: EnvService
  ) {
    this.baseUrl = this.env.apiUrl;
  }

  //controllo paginazione
  //se tutto null richiede tutta la lista senza paginazione
  //@param page: numero pagina desiderirata
  //@param size: numero di quanti risultati vuoi
  //@param attivi: true se vuoi la lista di utenti attivi
  //@param utenteChiamante: true filtra se stesso
  //@param ricerca: parametro per cercare su tutta la lista
  getAll(page?, size?, attivo?, utenteChiamante?, ricerca?): Observable<any> {
    if ((page || page == 0) && size && ricerca && utenteChiamante) {
      return this.http.get<any>(
        this.baseUrl +
          '/lista_utenti?page=' +
          page +
          '&size=' +
          size +
          '&attivo=' +
          attivo +
          '&utenteChiamante=' +
          utenteChiamante +
          '&ricerca=' +
          ricerca
      );
    } else if ((page || page == 0) && size && ricerca && !utenteChiamante) {
      return this.http.get<any>(
        this.baseUrl +
          '/lista_utenti?page=' +
          page +
          '&size=' +
          size +
          '&attivo=' +
          attivo +
          '&ricerca=' +
          ricerca
      );
    } else if ((page || page == 0) && size && utenteChiamante && !ricerca) {
      return this.http.get<any>(
        this.baseUrl +
          '/lista_utenti?page=' +
          page +
          '&size=' +
          size +
          '&attivo=' +
          attivo +
          '&utenteChiamante=' +
          utenteChiamante
      );
    } else if ((page || page == 0) && size && !utenteChiamante && !ricerca) {
      return this.http.get<any>(
        this.baseUrl +
          '/lista_utenti?page=' +
          page +
          '&size=' +
          size +
          '&attivo=' +
          attivo
      );
    } else {
      return this.http.get<any>(this.baseUrl + '/lista_utenti');
    }
  }

  getAllReport(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_utenti_report');
  }

  getAllAttivi(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_utenti_attivi');
  }

  getAllGruppo(id): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/lista_utenti_associati?id=${id}`
    );
  }

  getListaGruppi(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_gruppi');
  }

  getListaRuoli(): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/visualizza_ruoli', null);
  }

  getListaRuoliModifica(id): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/visualizza_ruoli', id);
  }

  getAllRuolo(id): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/lista_utenti_ruolo?idRuolo=${id}`
    );
  }

  notGetAllGruppo(id): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/lista_utenti_non_associati?id=${id}`
    );
  }

  associaUtentiGruppo(id, lista): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/associazione_utente?id=${id}`,
      lista
    );
  }

  deleteGruppo(id): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/eliminazione_logica_gruppo?id=${id}`,
      null
    );
  }

  deleteUtenteGruppo(id, idGruppo): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/rimuovi_utente_gruppo?gruppoId=${idGruppo}&utenteId=${id}`,
      null
    );
  }

  createGruppo(nome, descrizione, lista): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/crea_gruppo?nomeGruppo=${nome}&descrizione=${descrizione}`,
      lista
    );
  }

  salvaGruppoService(id, nome, descrizione): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/modifica_gruppo?id=${id}&nomeGruppo=${nome}&descrizioneGruppo=${descrizione}`,
      null
    );
  }

  getCord(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_coordinate_utenti');
  }

  getCordTracker(idDevice, ignoreUnassignedDevices): Observable<any> {
    return this.http.get<any>(
      this.baseUrl +
        '/device/location?deviceType=' +
        idDevice +
        '&ignoreUnassignedDevices=' +
        ignoreUnassignedDevices
    );
  }

  getBeaconsTracker(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/device/' + id + '/beacons');
  }

  getBatteryTracker(id): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + '/device/' + id + '/telemetry/Power'
    );
  }

  getMotivi(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_motivi');
  }
  getRicerche(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_motivi');
  }
  getOrdine(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_ordini');
  }

  getTipologiaAttivita(id?): Observable<any> {
    if (id) {
      return this.http.get<any>(this.baseUrl + '/tipologia_attivita/' + id);
    } else {
      return this.http.get<any>(this.baseUrl + '/tipologia_attivita/getAll');
    }
  }

  aggiungiTipologiaAttivita(tipologiaAttivita) {
    return this.http.post<any>(
      this.baseUrl + '/tipologia_attivita/add',
      tipologiaAttivita
    );
  }

  getTipologiaLaboratori(id?): Observable<any> {
    if (id) {
      return this.http.get<any>(this.baseUrl + '/tipologia_laboratori/' + id);
    } else {
      return this.http.get<any>(this.baseUrl + '/tipologia_laboratori/getAll');
    }
  }

  aggiungiTipologiaLaboratori(tipologiaLaboratori) {
    return this.http.post<any>(
      this.baseUrl + '/tipologia_laboratori/add',
      tipologiaLaboratori
    );
  }

  getObiettiviGenerali(id?): Observable<any> {
    if (id) {
      return this.http.get<any>(this.baseUrl + '/obiettivi_generali/' + id);
    } else {
      return this.http.get<any>(this.baseUrl + '/obiettivi_generali/getAll');
    }
  }

  aggiungiObiettiviGenerali(obiettivo) {
    return this.http.post<any>(
      this.baseUrl + '/obiettivi_generali/add',
      obiettivo
    );
  }

  removeMotivo(id): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/cancella_motivo?id=${id}`,
      null
    );
  }
  aggiungiMotivo(motivo) {
    return this.http.post<any>(
      `${this.baseUrl}/aggiungi_motivo?motivo=${motivo}`,
      null
    );
  }

  getRicercaMercato(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_ricerca_mercato');
  }
  getTarget(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_target');
  }
  getQuestionari(page, ricercaQuestionario = ''): Observable<any> {
    if (ricercaQuestionario !== '')
      return this.http.get<any>(
        this.baseUrl +
          '/lista_questionari?size=10&page=' +
          page +
          '&ricerca=' +
          ricercaQuestionario
      );
    return this.http.get<any>(
      this.baseUrl + '/lista_questionari?size=10&page=' + page
    );
  }
  getQuestionariPerTarget(page, id, ricerca = ''): Observable<any> {
    if (ricerca !== '')
      return this.http.post<any>(
        this.baseUrl +
          '/questionario_per_target?size=10&page=' +
          page +
          '&ricerca=' +
          ricerca,
        id
      );
    return this.http.post<any>(
      this.baseUrl + '/questionario_per_target?size=10&page=' + page,
      id
    );
  }
  creaQuestionari(questionario): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + '/crea_questionario',
      questionario
    );
  }
  modificaQuestionari(id, questionario): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + '/modifica_questionario?id=' + id,
      questionario
    );
  }
  removeRicercaMercato(id): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/elimina_ricerca_mercato?id=${id}`,
      null
    );
  }
  getQuestionarioById(id): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/recupero_questionario?id=${id}`);
  }
  removeQuestionario(id): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/elimina_questionario`, [id]);
  }
  aggiungiRicercaMercato(motivo) {
    return this.http.post<any>(`${this.baseUrl}/crea_ricerca_mercato`, {
      nome: motivo,
    });
  }
  removeOrdine(id): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/elimina_ordine?id=${id}`, null);
  }
  aggiungiOrdine(motivo) {
    return this.http.post<any>(`${this.baseUrl}/crea_ordine`, {
      nomeOrdine: motivo,
    });
  }

  delete(id): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/eliminazione_logica_utente?id=${id}`,
      null
    );
  }

  info(id): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/visualizza_info?id=${id}&idUtente=${this.accountService.userValue.data.id}`
    );
  }

  update(user: Utente, id, descrizione): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/aggiorna_utente?id=${id}&dettaglioAggiuntivo=${descrizione}&idAdmin=${this.accountService.userValue.data.id}`,
      user
    );
  }

  abilitaUser(id): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/abilitazione_utente?id=${id}`,
      null
    );
  }

  createSession(ciao) {
    return this.http.post(`${this.baseUrl}/session`, ciao);
  }

  salvaCoordinate(id, lat, lng) {
    lat = parseFloat(lat).toFixed(7);
    lng = parseFloat(lng).toFixed(7);
    lat = lat.toString();
    lng = lng.toString();
    return this.http.post(
      `${this.baseUrl}/salva_coordinate?utente_id=${id}&latitudine=${lat}&longitudine=${lng}`,
      null
    );
  }

  create(user: Utente, id, descrizione): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/crea_utente?id=${id}&dettaglioAggiuntivo=${descrizione}`,
      user
    );
  }

  restore(id): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/riabilitazione_logica_utente?id=${id}`,
      null
    );
  }
  inviaEmail(email): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/reinvio_email?email=${email}`,
      null
    );
  }

  ultimiDieci(): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + '/anagrafica_posizione_ultimi_dieci'
    );
  }

  ultimeChiamate(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/ultime_chiamate_effettuate');
  }
  ultimeDieciPosizioniUtente(id): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/ultime_dieci_posizioni_utente?id=${id}`
    );
  }

  ultimeDieciChiamateUtente(id): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/ultime_chiamate_effettuate_utente?id=${id}`
    );
  }

  applicaColore(rgb: String): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/cambia_colore?rgb=${rgb}`,
      null
    );
  }
  coloreScelto(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/recupero_colore');
  }

  caricaImmagine(img): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/upload`, img);
  }

  rimuoviImmagine(): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/rimuovi_logo`, null);
  }

  prendiImmagine(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/recupero_logo');
  }

  dataMonth(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/statistiche_mensili_chiamate');
  }

  dataTotal(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/statistiche_globali_chiamate');
  }

  getCordinateInCr(): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + '/lista_coordinate_utenti_sessione'
    );
  }

  getListaTask(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_task_utente?id=' + id);
  }

  creaTask(task): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/crea_task', task);
  }

  editTask(task): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/modifica_task', task);
  }

  deleteTask(id): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/elimina_task?id=' + id, null);
  }

  contaAccessi(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/conta_accessi');
  }
  //alfaintes
  crea_utente_afi(utente): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/registrazione_afi', utente);
  }

  lista_attivita(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_attivita');
  }

  lista_carica(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_carica');
  }

  get_user_afi(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/get_user_afi?id=' + id);
  }
  modifica_afi(utente, idUtente): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + '/modifica_afi?idUtente=' + idUtente,
      utente
    );
  }

  lista_afi_consenso(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_afi_consenso');
  }

  lista_strutture_afi(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lista_strutture_afi');
  }

  lista_dispositivi(id): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + '/lista_dispositivi?user_id=' + id
    );
  }
}
