import { Component, DoCheck, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import {
  AccountService,
  AlertService,
  ShareService,
  UsersService,
} from 'src/app/SERVICES';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-classroom-lista-aule',
  templateUrl: './classroom-lista-aule.component.html',
  styleUrls: ['./classroom-lista-aule.component.scss'],
})
export class ClassroomListaAuleComponent implements OnInit, DoCheck {
  listaAule = [];
  aula;
  visualizzaAula = false;
  scegliAula = false;
  ruolo;
  ambiente;
  defaultColor = '#2196F3';
  // PAGINAZIONE LISTA
  selectedValue;
  isSelectingValue = false;
  isSearchingOn = false;
  isClickable = true;
  searchform: FormGroup;
  pagina;
  maxItems;
  items;
  numeroMaxPagine;
  paginator = true;
  subscriptionSearch: Subscription;
  goToMode: boolean;

  // PAGINAZIONE LISTA FINE
  constructor(
    private classroomService: ClassroomService,
    private env: EnvService,
    private router: Router,
    private accountService: AccountService,
    private shareService: ShareService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.goToMode = this.env.goTo;
    this.ruolo = this.accountService.ruolo;
    this.ambiente = environment;

    this.pagina = 0;
    this.selectedValue = 10;
    this.searchform = this.formBuilder.group({
      search: ['', Validators.required],
    });
    this.chiamataPaginata(this.pagina, this.selectedValue);

    // else

    // );
  }
  ngDoCheck(): void {
    if (this.accountService.listaEnv && !this.env.CLASSROOM) {
      this.router.navigate(['./']);
    }
  }

  ngOnInit(): void {
    if (this.env.alfaintes) {
      this.defaultColor = 'white';
    }
  }
  chiuso() {
    if (this.ambiente.NOMEAULA == 'Webinar') {
      return 'Webinar chiuso';
    } else {
      return 'Aula chiusa';
    }
  }
  chiamataPaginata(pagina, quantità, ricerca?, campo?, cardinalita?) {
    this.paginator = false;
    this.subscriptionSearch = this.classroomService
      .lista_classroom_leggera(pagina, quantità, ricerca, campo, cardinalita)
      .subscribe((res) => {
        if (res.data.content.length > 0) {
          this.listaAule = res.data.content;
        } else {
          this.listaAule = [];
        }
        this.numeroMaxPagine = Number(res.data.totalPages);
        this.maxItems = res.data.totalElements;
        this.items = res.data.numberOfElements;

        this.isClickable = true;
        this.paginator = true;
        this.isSearchingOn = false;
        this.isSelectingValue = false;
        this.scegliAula = true;
      });
  }

  cambiaPagina(event) {
    this.isClickable = false;
    this.pagina = event;
    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.chiamataPaginata(
        this.pagina,
        this.selectedValue,
        this.valoreRicerca
      );
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue);
    }
  }

  cambiaValue() {
    this.isClickable = false;
    this.paginator = false;
    this.pagina = 0;
    this.isSelectingValue = true;
    this.isSearchingOn = true;
    this.chiamataPaginata(this.pagina, this.selectedValue);
  }
  valoreRicerca;
  search() {
    let valoreRicerca;
    this.pagina = 0;
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe();
    }
    this.isSearchingOn = true;
    valoreRicerca = this.searchform.value.search;
    this.valoreRicerca = valoreRicerca;
    if (valoreRicerca && valoreRicerca.length > 0) {
      this.chiamataPaginata(this.pagina, this.selectedValue, valoreRicerca);
    } else {
      this.chiamataPaginata(this.pagina, this.selectedValue);
    }
  }

  view(aula) {
    //TMS 6-10-2021 GAP-442
    //start
    let sospeso = (aula.suspended as number) || null;
    if (sospeso && sospeso == 1) {
      //esempio di dati
      // end: "2021-09-30 00:00:00"
      // start: "2021-08-02 00:00:00"
      let start = aula.start as string;
      let dataStart = start.split(' ')[0];
      let annoInizio = dataStart.split('-')[0];
      let meseInizio = dataStart.split('-')[1];
      let giornoInizio = dataStart.split('-')[2];
      let end = aula.end as string;
      let dataEnd = end.split(' ')[0];
      let annoFine = dataEnd.split('-')[0];
      let meseFine = dataEnd.split('-')[1];
      let giornoFine = dataEnd.split('-')[2];
      dataStart = giornoInizio + '/' + meseInizio + '/' + annoInizio;
      dataEnd = giornoFine + '/' + meseFine + '/' + annoFine;
      this.alertService.error('Attiva dal ' + dataStart + ' al ' + dataEnd);
      return;
    } //end
    this.aula = aula;
    this.scegliAula = false;
    this.visualizzaAula = true;
    this.shareService.changeAula(this.aula);
    this.router.navigate(['webinar']);
  }

  indietro() {
    this.scegliAula = true;
    this.visualizzaAula = false;
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
  ordinaEtichetta = 0;
  ordinaArray(lable) {
    if (this.ordinaEtichetta == 0 || this.ordinaEtichetta == 2) {
      this.ordinaEtichetta = 1;
    } else {
      this.ordinaEtichetta = 2;
    }
    this.chiamataPaginata(
      this.pagina,
      this.selectedValue,
      null,
      lable,
      this.ordinaEtichetta
    );
  }
}
