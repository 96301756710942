export class SessioneBadge {
    data?: string;
    operatore?: string;
    operazione?: string;
    latitudine?: string;
    longitudine?: string;
    comune?: string;
    indirizzo?: string;
    foto?: string;
    nota?: string;
    fuoriRange?: boolean;

    constructor({data = "", operatore ="", operazione ="", latitudine = "", longitudine = "", comune = "", indirizzo ="", foto ="N/A", nota="", fuoriRange=false} = {})
    {
        this.data = data;
        this.operatore = operatore;
        this.operazione = operazione;
        this.latitudine = latitudine;
        this.longitudine = longitudine;
        this.comune = comune;
        this.indirizzo = indirizzo;
        this.foto = foto;
        this.nota = nota;
        this.fuoriRange = fuoriRange;
    }
}