<div class="container-fluid">
  <div class="content">
    <div class="row">
      <div class="col-md-12 ">
        <div class="card z-index-0">
          <div class="card-content" *ngIf="!showAdd && !showModificaInfo">
            <h4 class="card-title">Gestione Campagne</h4>
            <ul class="nav nav-pills nav-pills-primary">
              <li class="active">
                <a href="#pill1" data-toggle="tab">Campagne</a>
              </li>

            </ul>
            <hr>
            <div class="tab-content">
              <div class="tab-pane active" id="pill1">

                <div *ngIf="ruolo===1" class="rigaPulsanti">
                  <button class="btn btn-primary ml-auto" (click)="showAdd=true">Aggiungi Campagna</button>
                </div>

                <div>
                  <div class="xScroll">
                    <table mat-table [dataSource]="dataSource" *ngIf="showTable">

                      <ng-container *ngFor="let item of displayedColumns">
                        <ng-container [matColumnDef]="item">
                          <ng-container *ngIf="item==='azioni'">
                            <th mat-header-cell *matHeaderCellDef>
                              <mat-label>
                                Azioni
                              </mat-label>

                            </th>
                            <td mat-cell *matCellDef="let element">
                              <i title="Elimina" *ngIf="ruolo === 1" class="material-icons deleteIcon no-translate fSmall"
                                (click)="deleteCampagna(element.id)">delete</i>
                              <i title="Modifica" *ngIf="ruolo === 1" class="material-icons modifyIcon no-translate fSmall"
                                (click)="showEdit(element)">edit</i>
                              <i title="Info" *ngIf="ruolo === 1" class="material-icons infoIcon no-translate fSmall"
                                (click)="showInfo(element)">info</i>
                            </td>
                          </ng-container>
                          <ng-container *ngIf="item!=='azioni'">
                            <th mat-header-cell *matHeaderCellDef>
                              <mat-form-field appearance="standard" *ngIf="item!=='listaQuestionari' && item!=='listaTargetCampagna'">
                                <mat-label>
                                  <i class="material-icons no-translate fSmall">search</i> {{getLabel(item)}}
                                </mat-label>
                                <input matInput [type]="item==='numero_Minimo_Chiamate'? 'number':'text'"
                                  (keyup)="applyFilter($event, item)" placeholder="Cerca ..." #input>
                              </mat-form-field>
                              <div *ngIf="item==='listaQuestionari' || item==='listaTargetCampagna'" style="text-transform: capitalize;">
                                {{getLabel(item)}}
                              </div>

                            </th>
                            <td mat-cell *matCellDef="let element">
                              <ng-container *ngIf="listIdToModify.indexOf(element.id)===-1">
                                <div
                                  [ngClass]="{'one-line': (element[item] && element[item].length > 30) || (!checkType(element[item]) && element[item] && element[item].length > 2)}">
                                  <span
                                    *ngIf="checkType(element[item]) && element[item] !== true && element[item] !== false">
                                    {{element[item].split(' ')[0]}}
                                  </span>
                                  <span *ngIf="element[item] === true">
                                    Si
                                  </span>
                                  <span *ngIf="element[item] === false">
                                    No
                                  </span>
                                  <span *ngIf="!checkType(element[item])">
                                    <ng-container *ngFor="let itemList of element[item], let i = index">
                                      <ng-container *ngIf="item === 'listaQuestionari'">
                                        {{itemList.nomeQuestionario}}
                                        <span *ngIf="itemList !== element[item][element[item].length-1]">
                                          ,
                                        </span>
                                      </ng-container>
                                      <ng-container *ngIf="item === 'listaTargetCampagna'">

                                        {{itemList.targetCampagna.nomeTarget}}
                                        <span *ngIf="itemList !== element[item][element[item].length-1]">
                                          ,
                                        </span>
                                      </ng-container>
                                    </ng-container>
                                  </span>
                                </div>
                              </ng-container>
                            </td>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                  </div>
                  <div class="flex">
                    <button [@flyInOut] *ngIf="listIdToModify.length>0" class="btn btn-primary saveButton"
                      (click)="modifyRows(0)">Salva</button>
                    <button [@flyInOut] *ngIf="listIdToModify.length>0" class="btn btn-primary saveButton"
                      (click)="annullaModifyRows()">Annulla</button>
                    <mat-paginator *ngIf="lunghezzaPaginator" [length]="lunghezzaPaginator" class="ml-auto"
                      (page)="changeVisual($event)" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-content" *ngIf="showAdd">
            <app-campagne-add (backEmit)="backAdd()"></app-campagne-add>
          </div>
          <div class="card-content" *ngIf="showModificaInfo">
            <app-campagne-add [isInfo]="isInfo" [campagnaModel]="elementToModify" (backEmit)="backAdd()"></app-campagne-add>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
