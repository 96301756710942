import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { Cliente, ObiettiviSpecifici } from 'src/app/MODELS/USER/cliente';
import { AlertService } from 'src/app/SERVICES';
import { ClienteService } from 'src/app/SERVICES/CLIENTE/cliente.service';
import { MatStepper } from '@angular/material/stepper';
import { DeviceDetectorService } from 'ngx-device-detector';
import JsonCitta from 'src/assets/json/italian_cities.json';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FlatTreeControl } from '@angular/cdk/tree';
import { elementAt } from 'rxjs/operators';

@Component({
  selector: 'app-modifica-cliente',
  templateUrl: './modifica-cliente.component.html',
  styleUrls: ['./modifica-cliente.component.scss'],
  animations: [
    trigger('rotatedState',[
      state('default', style({transform:'rotate(0)'})),
      state('rotated', style({transform: 'rotate(90deg)'})),
      transition('rotated => default', animate('120ms ease-out')),
      transition('default => rotated', animate('120ms ease-in'))
    ])
  ]
})
export class ModificaClienteComponent implements OnInit {
  @Output() tornaGestioneCliente = new EventEmitter<Cliente>();
  @Input() clienteId;
  @ViewChild('stepper') stepper: MatStepper;
  id;
  listaTipologiaAttivitaToSend = [];
  listaLaboratoriInterventiToSend = [];
  listaObiettiviToSend = [];
  editForm: FormGroup;
  showInputAssociazione = false;
  showInputServizio = false;
  submitted = false;
  cliente: Cliente;
  stato = ['default'];
  loading = false;
  listaServizi = [];
  currentYear;
  years: Number[] = [];
  isDesktop = true;
  obiettiviSpecifici: ObiettiviSpecifici[] = [];

  arrInvianti = [
    'Servizi sociali territoriali',
    'Scuola',
    'Accesso diretto',
    'Medico curante/psichiatra',
    'Tribunale',
    'UEPE',
    'Accesso spontaneo',
  ];

  arrObiettivi = [
    'Autonomia abitativa',
    'Autonomia formativa',
    'Autonomia lavorativa',
    'Autonomia delle "abilità sociali"',
    'Autonomia personale',
    'Autoregolazione',
  ];

  arrTipologiaAttivita = [
    'Riabilitative' ,
    'Prevenzione' ,
    'Socio-educative' ,
    'Sostegno psico-sociale' ,
    'Segretariato sociale' ,
    'Socio assistenziale' ,
    'Orientamento' ,
  ];

  arrTipologiaLaboratori= [
    'Laboratori specifici per l’autonomia',
    'Laboratori artistici creativi',
    'Laboratori sportivi',
    'Gruppi di auto/muto aiuto',
    'Interventi domiciliari',
    'Interventi individuali',
  ];
  showDiagnosi = false;
  statoDiagnosi = 'default';
  showNote=false;
  statoNote = 'default';
  showAnalisi=false;
  statoAnalisi = 'default';
  showAnalisiRisorse=false;
  statoAnalisiRisorse = 'default';
  showMetodologiaSpecifica=false;
  statoMetodologiaSpecifica = 'default';
  showDescrizioneObiettivi = false;
  statoDescrizioneObiettivi= 'default';


  arrCitta = JsonCitta;

  constructor(
    private primengConfig: PrimeNGConfig,
    private clienteService: ClienteService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private deviceService: DeviceDetectorService,
  ) {
    this.editForm = this.formBuilder.group({
      nome: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
        ],
      ],
      cognome: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
        ],
      ],
      codiceFiscale: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$'
          ),
        ],
      ],
      eta: [''],
      sesso: [''],
      citta: [''],
      occupazione: [''],
      livelloDiIstruzione: [''],
      situazioneEconomica: [''],
      diagnosi: ['', Validators.maxLength(10)],
      annoDiPresaInCaricoDalServizio: [''],
      infoAltraAssociazione: [''],
      altraAssociazione: [''],
      infoCliente: [''],
      altroServizio: [''],
      serviziDiAppartenenza: [''],
      tipologiaAttivita: [[]],
      tipologiaLaboratoriInterventi: [[]],
      partenariati: [''],
      inviante: [null],
      note: [''],
      analisiBisogni: [''],
      analisiRisorse: [''],
      obiettiviGenerali: [[]],
      metodologiaSpecifica: [''],
      obiettiviRaggiunti: [''],
      descrizioneObiettivi: [''],
    });
  }

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();
    this.currentYear = new Date().getFullYear();
    for(let year = this.currentYear; year>=1980; year--)
    {
      this.years.push(year);
    }

    this.clienteService.getListaServizi().subscribe(
      (res) => {
        this.listaServizi = res;

      },
      (error) => {}
    );

    this.primengConfig.ripple = true;
    this.id = this.clienteId;

    this.clienteService.getInfoCliente(this.clienteId).subscribe((res) => {

      this.cliente = res;
      if(res.obiettiviSpecifici) {
        this.obiettiviSpecifici = res.obiettiviSpecifici;
      } else {
        this.obiettiviSpecifici.push({
          title: '',
          azioniRealizzazione: ['']
        })
      }
      this.f.nome.setValue(res.nome);
      this.f.cognome.setValue(res.cognome);
      this.f.codiceFiscale.setValue(res.codiceFiscale);
      this.f.eta.setValue(res.eta);
      this.f.sesso.setValue(res.sesso.charAt(0).toUpperCase() + res.sesso.substr(1).toLowerCase());
      this.f.citta.setValue(res.citta);
      this.f.occupazione.setValue(res.occupazione);
      this.f.livelloDiIstruzione.setValue(res.livelloDiIstruzione);
      this.f.situazioneEconomica.setValue(res.situazioneEconomica);
      this.f.diagnosi.setValue(res.diagnosi);
      this.f.annoDiPresaInCaricoDalServizio.setValue(
        res.annoDiPresaInCaricoDalServizio
      );
      this.f.infoAltraAssociazione.setValue(res.infoAltraAssociazione);
      this.f.altraAssociazione.setValue(res.altraAssociazione);
      this.f.infoCliente.setValue(res.infoCliente);
      this.f.altroServizio.setValue(res.altroServizio);
      this.f.serviziDiAppartenenza.setValue(res.serviziDiAppartenenza);
      this.f.tipologiaAttivita.setValue(this.getListFromArray(res.tipologiaAttivita,'nomeAttivita'));
      this.f.tipologiaLaboratoriInterventi.setValue(this.getListFromArray(res.tipologiaLaboratoriInterventi,'nomeLaboratoriInterventi')
      );
      this.listaTipologiaAttivitaToSend = this.getListFromArray(res.tipologiaAttivita,'nomeAttivita');
      this.checkArray(this.listaTipologiaAttivitaToSend,this.arrTipologiaAttivita)

      this.listaLaboratoriInterventiToSend = this.getListFromArray(res.tipologiaLaboratoriInterventi,'nomeLaboratoriInterventi')
      this.checkArray(this.listaLaboratoriInterventiToSend,this.arrTipologiaLaboratori)
      
      this.f.partenariati.setValue(res.partenariati);
      this.f.inviante.setValue(res.inviante);
      this.f.note.setValue(res.note);
      this.f.analisiBisogni.setValue(res.analisiBisogni);
      this.f.analisiRisorse.setValue(res.analisiRisorse);
      this.f.obiettiviGenerali.setValue(this.getListFromArray(res.obiettiviGenerali, 'nomeObiettivo'));

      this.listaObiettiviToSend = this.getListFromArray(res.obiettiviGenerali, 'nomeObiettivo');
      this.checkArray(this.listaObiettiviToSend,this.arrObiettivi)

      this.f.metodologiaSpecifica.setValue(res.metodologiaSpecifica);
      this.f.obiettiviRaggiunti.setValue(res.obiettiviRaggiunti);
      this.f.descrizioneObiettivi.setValue(res.descrizioneObiettivi);

      this.loading = false;
    });

    this.primengConfig.ripple = true;
  }

  getListFromArray(element, key) {
    let array = []
    element.forEach((element)=>{
      array.push(element[key])
    })
    return array;
  }
  getArrayFromlist(element, key) {
    let array = []
    element.forEach((element)=>{
      array.push({cliente:{idCliente:this.clienteId}})
      array[array.length-1][key] = element;
    })
    return array;
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;

    if (this.editForm.invalid) {
      this.loading = false;
      return;
    }

    let newCliente = {
      idCliente: this.clienteId,
      nome: this.editForm.value.nome,
      cognome: this.editForm.value.cognome,
      codiceFiscale: this.editForm.value.codiceFiscale,
      sesso: this.editForm.value.sesso,
      eta: this.editForm.value.eta,
      citta: this.editForm.value.citta,
      occupazione: this.editForm.value.occupazione,
      livelloDiIstruzione: this.editForm.value.livelloDiIstruzione,
      situazioneEconomica: this.editForm.value.situazioneEconomica,
      diagnosi: this.editForm.value.diagnosi,
      annoDiPresaInCaricoDalServizio: String(this.editForm.value.annoDiPresaInCaricoDalServizio),
      infoAltraAssociazione: this.editForm.value.infoAltraAssociazione,
      altraAssociazione: this.editForm.value.altraAssociazione,
      infoCliente: this.editForm.value.infoCliente,
      altroServizio: this.editForm.value.altroServizio,
      serviziDiAppartenenza: this.editForm.value.serviziDiAppartenenza,
      tipologiaAttivita: this.getArrayFromlist(this.listaTipologiaAttivitaToSend, 'nomeAttivita'),
      tipologiaLaboratoriInterventi:this.getArrayFromlist(this.listaLaboratoriInterventiToSend, 'nomeLaboratoriInterventi'),
      partenariati: this.editForm.value.partenariati,
      inviante: this.editForm.value.inviante,
      note: this.editForm.value.note,
      analisiBisogni: this.editForm.value.analisiBisogni,
      analisiRisorse: this.editForm.value.analisiRisorse,
      obiettiviGenerali: this.getArrayFromlist(this.listaObiettiviToSend, 'nomeObiettivo'),
      // obiettiviSpecifici: this.editForm.value.obiettiviSpecifici,
      // azioniRealizzazione: this.editForm.value.azioniRealizzazione,
      obiettiviSpecifici: this.obiettiviSpecifici,
      metodologiaSpecifica: this.editForm.value.metodologiaSpecifica,
      obiettiviRaggiunti: this.editForm.value.obiettiviRaggiunti,
      descrizioneObiettivi: this.editForm.value.obiettiviRaggiunti,
    };
console.log(newCliente);

    this.clienteService.editCliente(this.clienteId, newCliente).subscribe(
      (res) => {
        this.loading = false;
        this.alertService.success(res.status.description);
        this.indietro();
      },
      (error) => {
        this.loading = false;
      }
    );
  }



  get f() {
    return this.editForm.controls;
  }


  showServizio() {
    if (this.editForm.value.infoCliente === 'si') {
      this.showInputServizio = true;
    } else {
      this.showInputServizio = false;
    }
  }

  showAssociazione() {
    if (this.editForm.value.infoAltraAssociazione === 'si') {
      this.showInputAssociazione = true;
    } else {
      this.showInputAssociazione = false;
    }
  }

  setValidator(paramname, obj) {
    const form = this.editForm;
    const campo = form.get(paramname);
    const answer = form.get(obj);

    if (campo.value === 'si') {
      answer.setValidators(Validators.required);
    } else {
      answer.clearValidators();
      answer.reset();
    }
    answer.updateValueAndValidity();
  }

  indietro(vai?) {
    this.tornaGestioneCliente.emit(this.cliente);
  }

  goBack(stepper: MatStepper){
    stepper.previous();
  }

  goForward(stepper: MatStepper){
    stepper.next();
}

showAzioni(i){
  this.stato[i] = (this.stato[i] === 'default' ? 'rotated' : 'default')
}

cambiaStatoDiagnosi(){
  this.statoDiagnosi = (this.statoDiagnosi === 'default' ? 'rotated' : 'default')
}

cambiaStatoNote(){
  this.statoNote = (this.statoNote === 'default' ? 'rotated' : 'default')
}

cambiaStato(stato){
this[stato] = (this[stato] === 'default' ? 'rotated' : 'default')
}

addObiettivo(){
  this.obiettiviSpecifici.push(new ObiettiviSpecifici('', ['']))
}

removeObiettivo(i:number){
  if(this.obiettiviSpecifici.length>1) {
    this.obiettiviSpecifici.splice(i,1)
  } else {
    this.alertService.error(
      "Impossibile eliminare, è richiesto almeno un campo obiettivo specifico"
    );
    }
}

addAzione(obiettivo:ObiettiviSpecifici){
  obiettivo.azioniRealizzazione.push('')
}

removeAzione(obiettivo:ObiettiviSpecifici,j:number){
  if(obiettivo.azioniRealizzazione.length > 1){
    obiettivo.azioniRealizzazione.splice(j,1)
  } else {
    this.alertService.error(
      "E' richiesto almeno un campo azioni per ogni obiettivo specifico"
    );
  }
}
returnTrack(index, item) {
  return index;
}

showBorderBottom(j, l) {
  return j === (l - 1)
}


checkArray(arrToSend,arrToCheck){
  arrToSend.forEach(element=>{
    if(arrToCheck.includes(element)) {
      arrToCheck.splice(arrToCheck.indexOf(element), 1);
    }
})
}

removeOneLine(id){
  let element = document.getElementById(id)
  element.classList.remove("one-line")

}

}

