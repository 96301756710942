import { Injectable, Host } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { EnvService } from '../GENERAL/env.service';
import { Utente } from 'src/app/MODELS/USER/utente';
import { AttendeeChime, ChimeJoinResponse, ChimeJoinResponseBE, MeetingChime, Recording } from 'npx-gap-chime';



@Injectable({
  providedIn: 'root',
})
export class OperatorService {
  baseUrl: string;
  constructor(private http: HttpClient, private env: EnvService) {}

  callUser(id, idOP): Observable<any> {
    return this.http.post<any>(
      `${this.env.apiUrl}/chiama_utente?id=${id}&controlRoom=${idOP}`,
      null
    );
  }

  callUserDevice(id, idOP, dispositivo): Observable<any> {
    return this.http.post<any>(
      `${this.env.apiUrl}/chiama_dispositivo?id=${id}&dispositivo=${dispositivo}&controlRoom=${idOP}`,
      null
    );
  }

  postUsername(user: Utente) {
    return this.http.post(`${this.env.apiUrl}/getUsername`, user);
  }

  /*getToken(openvidu : Openvidu){
    return this.http.post(this.env.apiUrl+"/creazione_sessione", openvidu);
  }*/

  createSession(user: Utente): Observable<any> {
    return this.http.post<any>(this.env.apiUrl + '/creazione_sessione', user);
  }

  joinSession(user: Utente, motivo): Observable<any> {
    return this.http.post<any>(
      `${this.env.apiUrl}/join_sessione?motivoChiamata=${motivo}`,
      user
    );
  }

  leaveSession(user: Utente) {
    return this.http.post(this.env.apiUrl + '/eliminazione_sessione', user);
  }

  leaveVideochiamata(id) {
    return this.http.post<any>(
      `${this.env.apiUrl}/conferma_uscita?idUtente=${id}`,
      null
    );
  }
  inviaDati() {
    return this.http.post(this.env.apiUrl + '/visualizza_dati_sessione', null);
  }

  limitAccess(email) {
    return this.http.post(
      `${this.env.apiUrl}/limita_accesso?email=${email}`,
      null
    );
  }

  updateWhitelist(id, lista) {
    return this.http.post(
      `${this.env.apiUrl}/aggiorna_whitelist?id=${id}`,
      lista
    );
  }

  pulisciWhiteList(id) {
    return this.http.post(
      `${this.env.apiUrl}/pulisci_whitelist?id=${id}`,
      null
    );
  }

  changeStatus(id, status) {
    return this.http.post(
      `${this.env.apiUrl}/occupato?id=${id}&occupato=${status}`,
      null
    );
  }

  confermaAccesso(id, lat, long, mot) {
    return this.http.post(
      `${this.env.apiUrl}/conferma_accesso?idUtente=${id}&latitudine=${lat}&longitudine=${long}&motivoChiamata=${mot}`,
      null
    );
  }

  aperturaWebsocket() {
    return this.http.get(`${this.env.apiUrl}/apertura_websocket`);
  }

  letturaNotifica(lista) {
    return this.http.post(`${this.env.apiUrl}/letto`, lista);
  }

  accendi_luci(url) {
    return this.http.post(`${this.env.apiUrl}/accendi_luci`, url);
  }

  spegni_luci(url) {
    return this.http.post(`${this.env.apiUrl}/spegni_luci`, url);
  }

  postJoin(user:Utente):Promise<ChimeJoinResponse | any>|null{
    let thisClass = this
    return new Promise<ChimeJoinResponse>(
      (resolve,rej) =>{
        thisClass.http.post(thisClass.env.apiUrl+"/chime/join?name="+user.email!+"&region=eu-central-1",null).subscribe(
          obj =>{      
            const data:ChimeJoinResponseBE = <ChimeJoinResponseBE>obj
            const meeting:MeetingChime = JSON.parse(data.meeting)
            const attendee:AttendeeChime = JSON.parse(data.attendee)
            let res = new ChimeJoinResponse()
            res.attendee = attendee
            res.meeting = meeting
            console.log("Meeting id", meeting)
            resolve(res)
          },
          error=>{
            rej(error)
          }
        )
      }    
    )
  }

  postJoinMeeting(meetingId: string, user:Utente): Promise<ChimeJoinResponse | any> | null {
    return new Promise<ChimeJoinResponse>((resolve, rej) => {
      this.http.post(
        this.env.apiUrl+"/chime/join?name="+user.email!+"&region=eu-central-1&meetingId="+meetingId,
        null,
      ).subscribe(
        (obj) => {
          const data: ChimeJoinResponseBE = <ChimeJoinResponseBE>obj;
          const meeting: MeetingChime = JSON.parse(data.meeting);
          const attendee: AttendeeChime = JSON.parse(data.attendee);
          let res = new ChimeJoinResponse();
          res.attendee = attendee;
          res.meeting = meeting;
          resolve(res);
        },
        (error) => {
          rej(error);
        }
      );
    });
  }

  getControlRooms(): Promise<Map<string, string>> {
    return new Promise<Map<string, string>>(
      (resolve, reject) => {
        this.http.get(`${this.env.apiUrl}/chime/lista_control_rooms`)
          .subscribe(
            (data: any) => {
              resolve(data);
            },
            error => {
              reject(error);
            }
          );
      }
    );
  }

  startRecording(record : Recording):Promise<any> {
    return new Promise<any>(
      (resolve, reject) => {
        this.http.post(`${this.env.apiUrl}/chime/startCapture?meetingId=`+record.meetingId,null)
        .subscribe((data: any) => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
      }
    );
    }

    stopRecording(record : Recording):Promise<any> {
      return new Promise<any>(
        (resolve, reject) => {
          this.http.post(`${this.env.apiUrl}/chime/stopCapture?meetingId=`+record.meetingId,null)
          .subscribe((data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
        }
      );
      }

}
