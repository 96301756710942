import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipeNameUnderscore',
  pure: false
})
export class PipeNameUnderscore implements PipeTransform {

  transform(value: string, args?: any): any {
    return value.replace('_', ' ');
  }
}
