import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  DoCheck,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Route, Router } from '@angular/router';
import {
  AccountService,
  AlertService,
  EnvService,
  UsersService,
} from 'src/app/SERVICES';
import { FarmaciaService } from 'src/app/SERVICES/FARMACIE/farmacie';

@Component({
  selector: 'app-farmacie-list-component',
  templateUrl: './farmacie-list-component.component.html',
  styleUrls: ['./farmacie-list-component.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'translateY(100%)' }),
        animate(200),
      ]),
      transition('* => void', [
        animate(200, style({ opacity: 0, transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class FarmacieListComponentComponent
  implements OnInit, AfterViewInit, DoCheck
{
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    'ragione_Sociale_Farmacia',
    'ricerca_Di_Mercato',
    'codice_AlfaIntes',
    'partita_Iva',
    'numero_Telefonico_Fisso',
    'numero_Telefonico_Cellulare',
    'email',
    'indirizzo',
    'elaborazione_Report',
    'cap',
    'comune',
    'provincia',
    'regione',
    'class_Enasarco',
    'classe_Principale',
    'classe_Clienti',
    'classe_Mercurio',
    'classe_Di_Provenienza',
    'nominativo_Contatto',
    'data_Ultimo_Ordine',
    'importo_Ultimo_Ordine',
    'email_Listino_Inviata',
    'data_Invio_EmailListino',
    'contratto',
    'data_Invio_Contratto',
    'data_Ricezione_Contratto',
    'proposta_Ordine',
    'data_Proposta_Ordine',
    'motivi_No_Ordine',
    'nome_Centro_Acquisto',
    'note',
    'ultima_Chiamata_Effettuata',
    'stato',
  ];

  searchBox: any = {
    Ragione_Sociale_Farmacia: '',
    Ricerca_Di_Mercato: '',
    Codice_AlfaIntes: '',
    Partita_Iva: '',
    Indirizzo: '',
    Elaborazione_Report: '',
    CAP: '',
    Comune: '',
    Provincia: '',
    Regione: '',
    Class_Enasarco: '',
    Nominativo_Contatto: '',
    Numero_Telefonico_Fisso: '',
    Numero_Telefonico_Cellulare: '',
    Email: '',
    data_Ultimo_Ordine: '',
    Importo_Ultimo_Ordine: '',
    Classe_Principale: '',
    Classe_Clienti: '',
    Classe_Mercurio: '',
    Classe_Di_Provenienza: '',
    Email_Listino_Inviata: '',
    Data_Invio_EmailListino: '',
    Contratto: '',
    Data_Invio_Contratto: '',
    Data_Ricezione_Contratto: '',
    Proposta_Ordine: '',
    Data_Proposta_Ordine: '',
    Motivi_No_Ordine: '',
    Nome_Centro_Acquisto: '',
    Note: '',
    Ultima_chiamata_effettuata: '',
    stato: '',
  };

  dataSource: any;
  dataSourceDeleteItems: any;
  showAdd = false;
  ruolo;
  listIdToModify = [];
  listToModify = [];
  lastValueSearched;
  listSelectedItems = [];
  isModify = false;
  isFunctionAlreadyCalled = false;
  showModalElimina = false;
  lunghezzaPaginator;
  subscriptionToModify;
  showTable = true;
  listaMercato;
  pageSize = 100;
  pageIndex = 0;
  isLoading = true;




  scrollTable(value) {
    let div = document.getElementById('scrollableTableFarmacieAll');
    div.scrollTop = value;
  }

  constructor(
    private farmaciaService: FarmaciaService,
    private accountService: AccountService,
    private alertService: AlertService,
    private env: EnvService,
    private router: Router,
    private userService: UsersService
  ) {
    this.userService.getRicercaMercato().subscribe((res) => {
      this.listaMercato = res.data;
    });
  }

  ngOnInit(): void {}

  ngDoCheck(): void {
    if (this.accountService.listaEnv && !this.env.ALFACALLCENTER) {
      this.router.navigate(['./']);
    }
  }
  ngAfterViewInit() {
    this.ruolo = this.accountService.ruolo;
    this.isLoading = true;
    this.farmaciaService.getAllFile(0, this.pageSize).subscribe((res) => {
      this.isLoading = false;

      this.dataSource = new MatTableDataSource(res.data.pageList);
      setTimeout(() => {
        this.lunghezzaPaginator = res.data.nrOfElements;
        // this.dataSource.paginator = this.paginator;
      }, 100);
    });
  }

  searchSubcription;
  applyFilter(event: Event, column) {
    if(this.searchSubcription) {
      this.searchSubcription.unsubscribe();
    }
    this.lastValueSearched = column.toLowerCase();
    column = this.lastValueSearched;
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchBox[column] = filterValue.trim().toLowerCase();
    if (!this.searchBox[column]) {
      this.lastValueSearched = null;
      column = this.lastValueSearched;
    }
    let endpoint = column + '=' + this.searchBox[column];
    this.isLoading = true;

    if (column === 'nominativo_contatto') {
      this.isLoading = false;

      this.searchSubcription = this.farmaciaService
        .ricercaEsclusiva(
          'ricerca_nominativi',
          0,
          this.pageSize,
          this.searchBox[column]
        )
        .subscribe((res) => {
          this.dataSource = new MatTableDataSource(
            this.getFarmaciaData(res.data.pageList)
          );
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
          }, 100);
        });
    } else if (column === 'numero_telefonico_fisso') {
      this.isLoading = false;
      this.searchSubcription = this.farmaciaService
        .ricercaEsclusiva(
          'ricerca_numeri_fissi',
          0,
          this.pageSize,
          this.searchBox[column]
        )
        .subscribe((res) => {
          this.dataSource = new MatTableDataSource(
            this.getFarmaciaData(res.data.pageList)
          );
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
          }, 100);
        });
    } else if (column === 'numero_telefonico_cellulare') {
      this.isLoading = false;
      this.searchSubcription = this.farmaciaService
        .ricercaEsclusiva(
          'ricerca_numeri_cellulare',
          0,
          this.pageSize,
          this.searchBox[column]
        )
        .subscribe((res) => {
          this.dataSource = new MatTableDataSource(
            this.getFarmaciaData(res.data.pageList)
          );
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
          }, 100);
        });
    } else {
      this.isLoading = false;
      this.searchSubcription = this.farmaciaService
        .getAllFile(0, this.pageSize, column, this.searchBox[column])
        .subscribe((res) => {
          this.dataSource = new MatTableDataSource(res.data.pageList);
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
          }, 100);
        });
    }
  }

  getFarmaciaData(res) {
    let listaToReturn = [];
    res.forEach((element) => {
      listaToReturn.push(element.farmacia);
    });
    listaToReturn.forEach((element) => {
      let nominativoContatto = [];
      element.nominativoContatto.forEach((element) => {
        nominativoContatto.push(element.contatto);
      });
      element.elaborazione_Report = element.elaborazioneReport;
      element.ragione_Sociale_Farmacia = element.ragioneSocialeFarmacia;
      element.codice_AlfaIntes = element.codiceAlfaIntes;
      element.partita_Iva = Number(element.partitaIva);
      element.data_Ultimo_Ordine = element.dataUltimoOrdine;
      element.importo_Ultimo_Ordine = element.importoUltimoOrdine;
      element.classe_Principale = element.classePrincipale;
      element.classe_Clienti = element.classeClienti;
      element.classe_Mercurio = element.classeMercurio;
      element.classe_Di_Provenienza = element.classeClienti;
      element.ricerca_Di_Mercato = element.idRicercaMercato;
      element.email_Listino_Inviata = element.dataInvioEmailListino;
      element.data_Invio_Contratto = element.dataInvioContratto;
      element.data_Ricezione_Contratto = element.dataRicezioneContratto;
      element.proposta_Ordine = element.propostaOrdine;
      element.data_Proposta_Ordine = element.dataPropostaOrdine;
      element.motivi_No_Ordine = element.motiviNoOrdine;
      element.nome_Centro_Acquisto = element.nomeCentroAcquisto;
      element.ultima_Chiamata_Effettuata = element.ultimaChiamataEffettuata;
      element.nominativo_Contatto = nominativoContatto;
      element.stato = element.stato;
    });
    listaToReturn.forEach((element) => {
      let numeroFisso = [];
      element.numeroTelefonicoFisso.forEach((element) => {
        numeroFisso.push(element.numero_fisso);
      });
      element.numero_Telefonico_Fisso = numeroFisso;
    });
    listaToReturn.forEach((element) => {
      let numeroCell = [];
      element.numeroTelefonicoCellulare.forEach((element) => {
        numeroCell.push(element.numero_cellulare);
      });
      element.numero_Telefonico_Cellulare = numeroCell;
    });
    return listaToReturn;
  }

  getEndpoint() {
    let endpoint = '?';
    this.displayedColumns.forEach((element) => {
      if (this.searchBox[element]) {
        endpoint = endpoint + element + '=' + this.searchBox[element] + '&';
      }
    });
    return endpoint.slice(0, -1);
  }

  getLabel(value: string) {
    try {
      return value.replace(/_/g, ' ');
    } catch (error) {
      return value;
    }
  }

  modifyRow(element) {
    this.listIdToModify.push(element.id);
    this.listToModify.push(element);
    this.isModify = true;
  }

  changeVisual(event) {
    this.pageIndex = event.pageIndex;
    let column;
    try {
      column = this.lastValueSearched.toLowerCase();
    } catch (error) {
      column = null;
    }
    this.pageSize = event.pageSize;
    this.isLoading = true;
    if (column === 'nominativo_contatto') {
      this.isLoading = false;
      this.farmaciaService
        .ricercaEsclusiva(
          'ricerca_nominativi',
          event.pageIndex,
          event.pageSize,
          this.searchBox[column]
        )
        .subscribe((res) => {
          let data = this.getFarmaciaData(res.data.pageList);
          data.forEach((element, index) => {
            if (this.listIdToModify.indexOf(element.id) !== -1) {
              res.data.pageList[index] =
                this.listToModify[this.listIdToModify.indexOf(element.id)];
            }
          });
          this.dataSource = new MatTableDataSource(
            this.getFarmaciaData(res.data.pageList)
          );
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
            this.scrollTable(0)
          }, 100);
        });
    } else if (column === 'numero_telefonico_fisso') {
      this.isLoading = false;
      this.farmaciaService
        .ricercaEsclusiva(
          'ricerca_numeri_fissi',
          event.pageIndex,
          event.pageSize,
          this.searchBox[column]
        )
        .subscribe((res) => {
          let data = this.getFarmaciaData(res.data.pageList);
          data.forEach((element, index) => {
            if (this.listIdToModify.indexOf(element.id) !== -1) {
              res.data.pageList[index] =
                this.listToModify[this.listIdToModify.indexOf(element.id)];
            }
          });
          this.dataSource = new MatTableDataSource(
            this.getFarmaciaData(res.data.pageList)
          );
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
            this.scrollTable(0)
          }, 100);
        });
    } else if (column === 'numero_telefonico_cellulare') {
      this.isLoading = false;
      this.farmaciaService
        .ricercaEsclusiva(
          'ricerca_numeri_cellulare',
          event.pageIndex,
          event.pageSize,
          this.searchBox[column]
        )
        .subscribe((res) => {
          let data = this.getFarmaciaData(res.data.pageList);
          data.forEach((element, index) => {
            if (this.listIdToModify.indexOf(element.id) !== -1) {
              res.data.pageList[index] =
                this.listToModify[this.listIdToModify.indexOf(element.id)];
            }
          });
          this.dataSource = new MatTableDataSource(
            this.getFarmaciaData(res.data.pageList)
          );
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;

            this.scrollTable(0)
          }, 100);
        });
    } else {
      this.isLoading = false;
      this.farmaciaService
        .getAllFile(
          event.pageIndex,
          event.pageSize,
          column,
          this.searchBox[column]
        )
        .subscribe((res) => {
          res.data.pageList.forEach((element, index) => {
            if (this.listIdToModify.indexOf(element.id) !== -1) {
              res.data.pageList[index] =
                this.listToModify[this.listIdToModify.indexOf(element.id)];
            }
          });
          this.dataSource = new MatTableDataSource(res.data.pageList);
          setTimeout(() => {
            this.lunghezzaPaginator = res.data.nrOfElements;
            // this.dataSource.paginator = this.paginator;
            this.scrollTable(0)
          }, 100);
        });
    }
  }
  rowSelected(id) {
    if (!this.isFunctionAlreadyCalled) {
      this.isFunctionAlreadyCalled = true;
      setTimeout(() => {
        if (!this.isModify) {
          let index = this.listSelectedItems.indexOf(id);
          if (index === -1) {
            this.listSelectedItems.push(id);
          } else {
            this.listSelectedItems.splice(index, 1);
          }
        } else {
          this.isModify = false;
        }
        this.isFunctionAlreadyCalled = false;
      }, 200);
    }
  }

  checkType(variabile) {
    return (
      typeof variabile === 'string' ||
      typeof variabile === 'number' ||
      typeof variabile === 'boolean'
    );
  }
  deleteFiles() {
    this.farmaciaService.deleteFile(this.listSelectedItems).subscribe((res) => {
      this.listSelectedItems = [];
      this.listIdToModify = [];
      this.dataSourceDeleteItems = undefined;
      this.listToModify = [];
      this.showModalElimina = false;
      this.lunghezzaPaginator = undefined;

      this.farmaciaService.getAllFile(0, this.pageSize).subscribe((res) => {
        this.dataSource = new MatTableDataSource(res.data.pageList);
        setTimeout(() => {
          this.lunghezzaPaginator = res.data.nrOfElements;
          // this.dataSource.paginator = this.paginator;
        }, 100);
      });
      this.alertService.success('Farmacia eliminata con successo!');
    });
  }
  modalElimina() {
    this.showModalElimina = true;
    this.farmaciaService
      .getAllFileById(this.listSelectedItems)
      .subscribe((res) => {
        this.dataSourceDeleteItems = new MatTableDataSource(res.data);
      });
  }
  modifyRows(index) {
    let obj = this.listToModify[index];
    if (this.subscriptionToModify) {
      this.subscriptionToModify.unsubscribe();
    }
    let a: any = Number(obj.cap);
    a;
    if (a.toString().toLowerCase() == 'nan') {
      this.alertService.error(
        'Cap non valido per la farmacia con ragione sociale: ' +
          obj.ragione_Sociale_Farmacia
      );
      return;
    }
    try {
      obj.nominativo_Contatto = obj.nominativo_Contatto.split(',');
    } catch (error) {}
    try {
      obj.numero_Telefonico_Fisso = obj.numero_Telefonico_Fisso
        .trim()
        .split(',')
        .map((i) => i);
    } catch (error) {}
    try {
      obj.numero_Telefonico_Cellulare = obj.numero_Telefonico_Cellulare
        .trim()
        .split(',')
        .map((i) => i);
    } catch (error) {}

    if (obj.elaborazione_Report !== '') {
      obj.elaborazione_Report = 'Si';
    } else {
      obj.elaborazione_Report = 'No';
    }
    this.listaMercato.forEach((element) => {
      if (element.nome === obj.ricerca_Di_Mercato) {
        obj.ricerca_Di_Mercato = element.id;
      }
    });
    this.subscriptionToModify = this.farmaciaService
      .modificaFarmacia(obj)
      .subscribe((element) => {
        this.alertService.success(
          'La farmacia:' +
            obj.ragione_Sociale_Farmacia +
            ' è stata modificata con successo!'
        );
        if (index < this.listToModify.length - 1) {
          this.modifyRows(index + 1);
        } else {
          this.listSelectedItems = [];
          this.listIdToModify = [];
          this.dataSourceDeleteItems = undefined;
          this.listToModify = [];
          this.showModalElimina = false;
          this.lunghezzaPaginator = undefined;
          this.changeVisual({
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
          });
        }
      });
  }
  typeOf(type) {
    return typeof type;
  }
  goBackAdd() {
    this.farmaciaService.getAllFile(0, this.pageSize).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.data.pageList);
      this.showAdd = false;
      this.showTable = true;
      setTimeout(() => {
        this.lunghezzaPaginator = res.data.nrOfElements;
        // this.dataSource.paginator = this.paginator;
      }, 100);
    });
  }
  annullaModifyRows() {
    this.listIdToModify = [];
    this.showTable = false;
    this.goBackAdd();
  }
}
