import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AccountService, AlertService } from 'src/app/SERVICES';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import ImageEditor from 'tui-image-editor';
import customTheme from './editorTheme';
@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.scss'],
})
export class EditImageComponent implements AfterViewInit {
  @Input() file;
  changingText = false;
  sendObjMoved;
  @ViewChild('imageEditor') _tuiRef: ElementRef;
  liveButton = false;
  indexLiveButton = 0;
  instance: ImageEditor;
  ishandButtonClicked = false;
  isdrawButtonClicked = false;
  istextButtonClicked = false;
  monthNamesShort: [
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Ago',
    'Set',
    'Ott',
    'Nov',
    'Dic'
  ];

  ngAfterViewInit(): void {
    this.initEditor();
  }
  constructor(
    private fileService: FileService,
    private accountStorage: AccountService,
    private alertService: AlertService
  ) {}

  initEditor() {
    this.fileService.isFirstTimeCr = true;
    this.instance = new ImageEditor(this._tuiRef.nativeElement, {
      includeUI: {
        loadImage: {
          path: this.file,
          name: 'SampleImage',
        },
        theme: customTheme,
        usageStatistics: false,
        menu: ['shape', 'draw', 'crop', 'text'],
        uiSize: {
          width: '100%',
          height: '85vh',
        },
        menuBarPosition: 'bottom',
      },
    });
    let pos = { top: 0, left: 0, x: 0, y: 0 };
    let imageEditorElement = document.getElementsByClassName(
      'tui-image-editor-wrap'
    )[0];
    const mouseMoveHandler = function (e) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // Scroll the element
      if (pos.top === 0) {
        pos.top = dy;
      }
      imageEditorElement.scrollTop = pos.top - dy;
      imageEditorElement.scrollLeft = pos.left - dx / 2;
    };
    const mouseUpHandler = function () {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);

      // imageEditorElement.style.cursor = 'grab';
      // imageEditorElement.style.removeProperty('user-select');
    };
    const mouseDownHandler = function (e) {
      pos = {
        // The current scroll
        left: imageEditorElement.scrollLeft,
        top: imageEditorElement.scrollTop,
        // Get the current mouse position
        x: e.x,
        y: e.y,
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    let handButton = document.getElementsByClassName('tie-btn-hand')[0];

    handButton.addEventListener('click', () => {
      this.ishandButtonClicked = !this.ishandButtonClicked;
      // if (this.ishandButtonClicked) {
      //   document.addEventListener('mousemove', mouseMoveHandler);
      //   document.addEventListener('mouseup', mouseUpHandler);
      // } else {
      //   document.removeEventListener('mousemove', mouseMoveHandler);
      //   document.removeEventListener('mouseup', mouseUpHandler);
      // }
    });

    let historyListButton =
      document.getElementsByClassName('tie-btn-history')[0];
    historyListButton.addEventListener('click', () => {
      this.ishandButtonClicked = false;
      setTimeout(() => {
        let historyList: any = document.getElementsByClassName('history-item');
        for (let i = 0; i < historyList.length; i++) {
          let elementList = historyList[i];
          elementList.addEventListener('click', () => {
            this.refresView();
            if (this.liveButton)
              setTimeout(() => {
                this.inviaFileLive();
              }, 100);
          });
        }
      }, 100);
    });
    let colorPickerButton =
      document.getElementsByClassName('color-picker-value');

    let shape = document.getElementsByClassName('tie-btn-shape')[0];
    shape.addEventListener('click', () => {
      this.ishandButtonClicked = false;
      for (let j = 0; j < colorPickerButton.length; j++) {
        colorPickerButton[j].addEventListener('click', () => {
          setTimeout(() => {
            let colorList: any = document.getElementsByClassName(
              'tui-colorpicker-palette-button'
            );
            for (let i = 0; i < colorList.length; i++) {
              let elementColor = colorList[i];
              elementColor.addEventListener('click', () => {
                setTimeout(() => {
                  if (this.liveButton) this.inviaFileLive();
                });
              });
            }
          }, 100);
        });
      }
    });
    for (let j = 0; j < colorPickerButton.length; j++) {
      colorPickerButton[j].addEventListener('click', () => {
        this.ishandButtonClicked = false;
        setTimeout(() => {
          let colorList: any = document.getElementsByClassName(
            'tui-colorpicker-palette-button'
          );
          for (let i = 0; i < colorList.length; i++) {
            let elementColor = colorList[i];
            elementColor.addEventListener('click', () => {
              if (this.liveButton) this.inviaFileLive();
            });
          }
        }, 100);
      });
    }
    this.instance.on('addText', () => {
      if (this.liveButton) this.inviaFileLive();
    });
    this.instance.on('objectActivated', () => {
      //console.log('objectActivated');
      if (this.liveButton) {
        document
          .getElementsByClassName('tie-btn-draw')[0]
          .classList.remove('active');
        document
          .getElementsByClassName('tui-image-editor-main')[0]
          .classList.remove('tui-image-editor-menu-draw');
        document
          .getElementsByClassName('tie-btn-text')[0]
          .classList.remove('active');
        document
          .getElementsByClassName('tui-image-editor-main')[0]
          .classList.remove('tui-image-editor-menu-text');

        //this.inviaFileLive();
      }
    });
    this.instance.on('objectAdded', () => {
      if (this.liveButton) this.inviaFileLive();
    });
    this.instance.on('objectMoved', () => {
      setTimeout(() => {
        if (!this.liveButton) {
          this.isdrawButtonClicked = true;
        }
        // document.getElementsByClassName('tie-btn-draw')[0].classList.remove('active');
        // document.getElementsByClassName('tui-image-editor-main')[0].classList.remove('tui-image-editor-menu-draw');
      }, 1);
      if (this.sendObjMoved) clearTimeout(this.sendObjMoved);
      this.sendObjMoved = setTimeout(() => {
        if (this.liveButton) this.inviaFileLive();
      }, 1000);
    });
    this.instance.on('mousedown', (event, originPointer) => {
      //console.log("isHandButtonClicked: " + this.ishandButtonClicked);
      if (this.ishandButtonClicked) mouseDownHandler(originPointer);
      if (this.liveButton && this.changingText) {
        this.inviaFileLive();
        this.changingText = false;
      }
    });
    this.instance.on('objectRotated', () => {
      if (this.liveButton) this.inviaFileLive();
    });
    this.instance.on('objectScaled', () => {
      if (this.liveButton) this.inviaFileLive();
    });
    this.instance.on('textEditing', (res) => {
      this.changingText = true;
    });
    this.instance.on('redoStackChanged', () => {
      if (this.liveButton) this.inviaFileLive();
    });
    let drawButton: any = document.getElementsByClassName('tie-btn-draw')[0];
    drawButton.addEventListener('click', () => {
      this.ishandButtonClicked = false;
      this.isdrawButtonClicked = !this.isdrawButtonClicked;
      this.istextButtonClicked = false;
    });
    let textButton: any = document.getElementsByClassName('tie-btn-text')[0];
    textButton.addEventListener('click', () => {
      this.ishandButtonClicked = false;
      this.isdrawButtonClicked = false;
      this.istextButtonClicked = !this.istextButtonClicked;
    });
    // this.instance.on('undoStackChanged', ()=>{console.log('undoStackChanged');if(this.liveButton)this.inviaFileLive()});
    let element = document.createElement('li');
    let element2 = document.createElement('div');
    let element3 = document.createElement('div');
    let element4 = document.createElement('div');
    let deleteButton = document.getElementsByClassName('tie-btn-deleteAll')[0];
    let undo = document.getElementsByClassName('tie-btn-undo')[0];
    let redo = document.getElementsByClassName('tie-btn-redo')[0];
    deleteButton.addEventListener('click', () => {
      this.ishandButtonClicked = false;
      if (this.liveButton)
        setTimeout(() => {
          this.inviaFileLive();
        }, 100);
    });
    undo.addEventListener('click', () => {
      this.ishandButtonClicked = false;
      if (this.liveButton)
        setTimeout(() => {
          this.inviaFileLive();
          this.refresView();
        }, 100);
    });
    redo.addEventListener('click', () => {
      this.ishandButtonClicked = false;
      if (this.liveButton)
        setTimeout(() => {
          this.inviaFileLive();
          this.refresView();
        }, 100);
    });
    element.classList.add('tui-image-editor-item');
    element.classList.add('tui-image-editor-item');
    element.classList.add('help');
    element2.classList.add('disp-inline');
    element3.classList.add('disp-inline');
    element4.classList.add('disp-inline');
    element2.classList.add('d-none');
    element3.classList.add('d-none');
    element4.classList.add('d-none');
    element2.innerHTML =
      '<div title="Non puoi eseguire questa azione in live Mode" style="display: inline-block;margin: 0 4px;padding: 7px 8px 3px;opacity: 0.5;"><li tooltip-content="Crop" style="pointer-events:none" class="tie-btn-crop tui-image-editor-item normal"><svg class="svg_ic-menu"><use xlink:href="#ic-crop" class="normal use-default"></use><use xlink:href="#ic-crop" class="active use-default"></use><use xlink:href="#ic-crop" class="hover use-default"></use></svg></li></div>';
    element3.innerHTML =
      '<div title="Non puoi eseguire questa azione in live Mode" style="display: inline-block;margin: 0 4px;padding: 7px 8px 3px;opacity: 0.5;"><li tooltip-content="Draw" style="pointer-events:none" class="tie-btn-draw tui-image-editor-item normal"><svg class="svg_ic-menu"><use xlink:href="#ic-draw" class="normal use-default"></use><use xlink:href="#ic-draw" class="active use-default"></use><use xlink:href="#ic-draw" class="hover use-default"></use></svg></li></div>';
    element.innerHTML = '<i class="material-icons notranslate">circle</i>';
    element4.innerHTML =
      '<div title="Non puoi eseguire questa azione in live Mode" style="display: inline-block;margin: 0 4px;padding: 7px 8px 3px;opacity: 0.5;"><li tooltip-content="Text" style="pointer-events:none" class="tie-btn-text tui-image-editor-item normal"><svg class="svg_ic-menu"><use xlink:href="#ic-text" class="normal use-default"></use><use xlink:href="#ic-text" class="active use-default"></use><use xlink:href="#ic-text" class="hover use-default"></use></svg></li></div>';
    element.onclick = () => {
      this.liveButton = !this.liveButton;
      if (this.isdrawButtonClicked) {
        drawButton.click();
        var event = new Event('click');
        handButton.dispatchEvent(event);
      }
      if (this.istextButtonClicked) {
        textButton.click();
      }
      if (this.liveButton) {
        this.inviaFileLive();
        document
          .getElementsByClassName('tie-btn-draw')[0]
          .classList.remove('active');
        document
          .getElementsByClassName('tui-image-editor-main')[0]
          .classList.remove('tui-image-editor-menu-draw');
        document
          .getElementsByClassName('tie-btn-text')[0]
          .classList.remove('active');
        document
          .getElementsByClassName('tui-image-editor-main')[0]
          .classList.remove('tui-image-editor-menu-text');
        element.innerHTML =
          '<i style="color: red" class="material-icons notranslate">circle</i>';
        document
          .getElementsByClassName('tie-btn-crop')[0]
          .classList.add('d-none');
        document
          .getElementsByClassName('tie-btn-draw')[0]
          .classList.add('d-none');
        document
          .getElementsByClassName('tie-btn-text')[0]
          .classList.add('d-none');
        element2.classList.remove('d-none');
        element3.classList.remove('d-none');
        element4.classList.remove('d-none');
      } else {
        // document.getElementsByClassName('tui-image-editor-main')[0].classList.add('tui-image-editor-menu-draw');
        // document.getElementsByClassName('tui-image-editor-main')[0].classList.add('tui-image-editor-menu-text');
        element.innerHTML = '<i class="material-icons notranslate">circle</i>';
        element2.classList.add('d-none');
        element3.classList.add('d-none');
        element4.classList.add('d-none');
        document
          .getElementsByClassName('tie-btn-crop')[0]
          .classList.remove('d-none');
        document
          .getElementsByClassName('tie-btn-draw')[0]
          .classList.remove('d-none');
        document
          .getElementsByClassName('tie-btn-text')[0]
          .classList.remove('d-none');
      }
    };
    document.getElementsByClassName('tie-btn-text')[0].classList.add('f-right');
    document
      .getElementsByClassName('tui-image-editor-menu')[0]
      .classList.add('d-inh');
    document
      .getElementsByClassName('tui-image-editor-menu')[0]
      .appendChild(element3);
    document
      .getElementsByClassName('tui-image-editor-menu')[0]
      .appendChild(element2);
    document
      .getElementsByClassName('tui-image-editor-menu')[0]
      .appendChild(element4);
    document
      .getElementsByClassName('tui-image-editor-help-menu')[0]
      .appendChild(element);

    let zoomIn = document.getElementsByClassName('tie-btn-zoomIn')[0];
    let zoomOut = document.getElementsByClassName('tie-btn-zoomIn')[0];
    zoomIn.addEventListener('click', () => {
      this.ishandButtonClicked = false;
      if (this.liveButton) this.inviaFileLive();
    });
    zoomOut.addEventListener('click', () => {
      this.ishandButtonClicked = false;
      if (this.liveButton) this.inviaFileLive();
    });

    setTimeout(() => {
      element.click();
    }, 1000);
  }

  refresView() {
    let shape: any = document.getElementsByClassName('tie-btn-shape')[0];
    shape.click();
    setTimeout(() => {
      shape.click();
    });
  }

  downloadFile() {
    var aDownloadEditedImage = document.createElement('a');
    aDownloadEditedImage.href = this.instance.toDataURL();
    aDownloadEditedImage.download = 'Image.png';
    aDownloadEditedImage.click();
  }
  inviaFileLive() {
    fetch(this.instance.toDataURL()).then((res) => {
      res.blob().then((res2) => {
        this.fileService
          .condividiImagineCr(
            this.accountStorage.userValue.data.id,
            new File([res2], 'image.png')
          )
          .subscribe((res) => {
            if (!this.liveButton) {
              //this.fileService.isFirstTimeCr = true;
            }
          });
      });
    });
  }

  isToggled = false;

  toggleMenu() {
    this.isToggled = !this.isToggled;
    if (this.isToggled) {
      document
        .getElementById('tui-image-editor-submenu')
        .classList.add('d-none');
    } else {
      document
        .getElementById('tui-image-editor-submenu')
        .classList.remove('d-none');
    }
  }
  inviaFile() {
    fetch(this.instance.toDataURL()).then((res) => {
      res.blob().then((res2) => {
        this.fileService
          .condividiFileCr(
            this.accountStorage.userValue.data.id,
            new File([res2], 'image.png')
          )
          .subscribe((res) => {
            this.alertService.success('Immagine inviata con successo');
          });
      });
    });
  }

  salvaFile(folderId) {
    let data = new Date();
    let body = {
      folder_name:
        'Control Room di: ' +
        JSON.parse(localStorage.getItem('user')).data.nome,
      parent_id: folderId,
      permission: [],
    };
    this.fileService.createFolder(body).subscribe(
      (res) => {
        folderId = res.data;

        fetch(this.instance.toDataURL({format: 'png'})).then((response) => {
          response.blob().then((res2) => {
            let uploadFile = new FormData();
            uploadFile.append('folder', folderId);
            uploadFile.append('file', res2);
            uploadFile.append('name', 'nome Provvisorio');
            uploadFile.append('extension', 'png');
            this.fileService.uploadDms(uploadFile).subscribe((res) => {
              this.alertService.success('File salvato nel cloud');
            });
          });
        });
      },
      (err) => {
        folderId = err.data;

        fetch(this.instance.toDataURL({format: 'png'})).then((res) => {
          res.blob().then((res2) => {
            let uploadFile = new FormData();
            uploadFile.append('folder', folderId);
            uploadFile.append('file', res2);
            uploadFile.append('extension', data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds());
            uploadFile.append('extension', 'png');

            this.fileService.uploadDms(uploadFile).subscribe((res) => {
              this.alertService.success('File salvato nel cloud');
            });
          });
        });
      }
    );
  }

  salvaInCloud() {
    this.fileService.getIdCR().subscribe((res) => {
      let data = new Date();
      let monthNamesShort = [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic'
      ];
      let body = {
        folder_name:
          data.getDate() +
          '/' +
          monthNamesShort[data.getMonth()] +
          '/' +
          data.getFullYear(),
        parent_id: res.data.id,
        permission: [],
      };
      this.fileService.createFolder(body).subscribe(
        (res) => {
          this.salvaFile(res.data.id);
        },
        (err) => {
          this.salvaFile(err.data.id);
        }
      );
    });
  }
}
