import { User } from './user';
import { Utente } from './utente';

export class Messaggio{
    id?: number;
    idCanale: String;
    idUtente: String ;
    idDest:String;
    messaggio : string;
    dataMessaggio : any;
    listaButtons? : any;
    mappa? : boolean;
}