
        <!--MODIFICA-->
<div *ngIf="!modificaRouting && caricato">
    <div *ngIf="modifica">
        <div class="card-header" style="margin-bottom: 2%;">
            <h5 class="card-title" style="margin-bottom: 2%;">
                Modifica {{ambiente.NOMEAULA}}
            </h5>
            <form [formGroup]="aulaModForm">
                <label for="nomeMod">Identificativo<span class="colorRed"> *</span></label>
                <input type="text" placeholder="Identificativo del corso"  [ngClass]="{'mb-2p': !((submitted && l.nomeMod.errors) && l.nomeMod.errors.required)}" formControlName="nomeMod"
                    maxlength="30" class="form-control ng-untouched ng-pristine ng-invalid">
                <div *ngIf="submitted && l.nomeMod.errors" class="invalid-feedback">
                    <div *ngIf="l.nomeMod.errors.required">Campo ID obbligatorio</div>
                    <div *ngIf="l.nomeMod.errors.pattern">Caratteri speciali non consentiti</div>

                </div>
                <label for="descrizioneMod">Descrizione<span class="colorRed"> *</span></label>
                <input type="text" placeholder="Descrizione" formControlName="descrizioneMod" maxlength="100"
                    class="form-control ng-untouched ng-pristine ng-invalid">
                <div *ngIf="submitted && l.descrizioneMod.errors" class="invalid-feedback">
                    <div *ngIf="l.descrizioneMod.errors.required">Campo Descrizione obbligatorio</div>
                </div>
                <div class="row" >
                    <label class="col-sm-2 label-on-left" style="margin-top: 30px ;    white-space: nowrap;
                    ">Temporizzare {{ambiente.NOMEAULA}}<span class="colorRed"> *</span>
                      <div *ngIf="submitted" class="invalid-feedback">
                          <div *ngIf="!selectedOptionInd">Scelta obbligatoria</div>
                      </div>
                  </label>

                    <div class="col-sm-10  label-on-left">
                        <div class="form-group label-floating is-empty char">

                            <p-selectButton [options]="opzioniInd" [(ngModel)]="selectedOptionInd" [ngModelOptions]="{standalone: true}"></p-selectButton>

                        </div>
                    </div></div>
                    <div class="row" *ngIf="selectedOptionInd == 1">
                      <label class="col-sm-2 label-on-left" style="margin-top: 30px ;">Data<span class="colorRed"> *</span>
                          <div *ngIf="submitted" class="invalid-feedback">
                              <div *ngIf="selectedOptionInd == 1">
                                 <div *ngIf="!aulaModForm.value.dataDa || !aulaModForm.value.dataA">Data obbligatoria</div>
                                 <div *ngIf="aulaModForm.value.dataDa >= aulaModForm.value.dataA">Data Invalida</div>
                                </div>
                          </div></label>

                      <div class="col-sm-2  label-on-left">
                          <div class="form-group label-floating is-empty char">

                              <p-calendar [locale]="ita" placeholder="Da" formControlName="dataDa" dateFormat="dd.mm.yy"></p-calendar>


                          </div>
                      </div>

                          <div class="col-sm-2  label-on-left" >
                              <div class="form-group label-floating is-empty char">

                                  <p-calendar [locale]="ita" placeholder="A" formControlName="dataA" dateFormat="dd.mm.yy"></p-calendar>

                              </div>
                          </div>
                          </div>

                          <div class="row" >
                            <label class="col-sm-2 label-on-left" style="margin-top: 30px ;">Dettagli Aggiuntivi
                          </label>

                            <div class="col-sm-10  label-on-left" >
                                <div class="form-group label-floating is-empty char">

                                    <button class="btn btn-primary btn-round" style="padding: 8px; margin-top: -5px;" (click)="aggiungiDettaglio()"><i class="material-icons notranslate" style="cursor: pointer;">add</i></button>

                                </div>
                            </div></div>
                          <div class="row" *ngFor="let dettaglio of listaDettagli">
                            <div class="col-sm-3">
                                <div class="form-group label-floating is-empty">
                                    <label class="control-label"></label>
                                    <input type="text" class="form-control" placeholder="Attributo" [(ngModel)]="dettaglio.attributo"
                                    [ngModelOptions]="{standalone: true}" maxlength="49">
                                    <div *ngIf="submitted && !dettaglio.attributo" class="invalid-feedback">
                                        <div >Aggiungi un attributo</div>
                                      </div>
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="form-group label-floating is-empty">
                                    <label class="control-label"></label>
                                    <input type="text" class="form-control" placeholder="Descrizione" [(ngModel)]="dettaglio.valore"
                                    [ngModelOptions]="{standalone: true}" maxlength="49">
                                    <div *ngIf="submitted && !dettaglio.valore" class="invalid-feedback">
                                        <div >Aggiungi un valore</div>
                                      </div>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="form-group label-floating is-empty">
                                    <label class="control-label"></label>
                                    <i class="material-icons notranslate" style="color: red; font-size: medium; margin-top: 20px; cursor: pointer;" (click)="deleteDettaglio(dettaglio)">clear</i>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <label class="col-sm-2 label-on-left" style="margin-top: 21px;">Copertina</label>
                            <div class="col-sm-10 ">
                            <input style="display: none" type="file" (change)="caricaCopertinaChanged($event)"
                            #fileInputCopertina>
                            <div class="flex">
                            <button type="button"  class="btn btn-primary buttonFitContent"  (click)="fileInputCopertina.click()">Carica</button>

                            <div class="quadratoImmagine" >
                                <img *ngIf="!selectedFileCopertina" [src]="aula.copertina">
                                <img *ngIf="selectedFileCopertina" [src]="path">

                                <br style="width: 100%;">

                                <p class="textOneLine">{{nomeFileCopertina}}</p>
                            </div>

                        </div>
        <!-- [disabled]="loading" -->
                    </div>
                            </div>


            </form>
        </div>
        <br>

        <button class="btn btn-primary" (click)="editDocente()">Modifica Docenti</button>
        <button class="btn btn-primary" (click)="editStudente()">Modifica Studenti</button>
        <button class="btn btn-primary" *ngIf="afi" (click)="editTemplate()">Modifica Template</button>
        <ng-container *ngIf="docenteEdit">
        <h5 class="card-title" style="margin-left: 15px;">
            Docenti<span class="colorRed"> *</span>
        </h5>
        <div class="card-header">
            <div id="datatables_filter" class="dataTables_filter">
                <form [formGroup]="searchDocentiForm">
                    <input type="text" formControlName="search" class="form-control" placeholder="Cerca tra i docenti"
                        maxlength="28">
                </form>
                <div *ngIf="searchInDocenti()"></div>
            </div>
        </div>


                            <br>

                            <div class="material-datatables">

                                <div class="scrollable" style="border: 1px solid lightgray;
                                border-radius: 5px;margin-left: 15px;">
                                    <p-confirmDialog #cd  icon="pi pi-exclamation-triangle" [style]="{width: responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
                                        <p-footer>

                                            <button [disabled]="loading"  *ngIf="cancellaDocente" type="button" pButton icon="pi pi-check" label="Sì" (click)="accettaCancellaDocente()">
                                                <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
                                            </button>
                                            <button *ngIf="!cancellaDocente" type="button" pButton icon="pi pi-check" label="Sì" (click)="cd.accept()"></button>
                                            <button [disabled]="loading && cancellaDocente" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>

                                        </p-footer>
                                    </p-confirmDialog>

                                    <table id="datatables" class="table table-striped table-no-bordered table-hover table-fixed"
                                        cellspacing="0" width="100%" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Cognome</th>

                                                <th style="text-align: right;">Rimuovi</th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let docente of docenti;let i =index;">
                                                <td style="font-size: medium; " formcontrolname="nome">{{docente.nome}}
                                                </td>
                                                <td style="font-size: medium; " formcontrolname="cognome">
                                                    {{docente.cognome}}</td>
                                                <td style="text-align: right;"><a
                                                        (click)="eliminaDocente(docente.id,i)"><i
                                                            class="material-icons notranslate pointer"
                                                            style="color: red;">delete</i></a></td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;">
                                    <button (click)="aggiungiDocente()" *ngIf="disableButton" class="btn btn-primary">Inserisci
                                        Docente</button>
                                </div>

                            </div>
                        </ng-container>
        <ng-container *ngIf="studenteEdit">
        <h5 class="card-title" style="margin-left: 15px;;">
            Studenti
        </h5>
        <div class="card-header">
            <div id="datatables_filter" class="dataTables_filter">
                <form [formGroup]="searchStudentiForm">
                    <input type="text" formControlName="search" class="form-control"
                        placeholder="Cerca tra gli studenti" maxlength="28">
                </form>
                <div *ngIf="searchInStudenti()"></div>
            </div>
        </div>


                            <br>

                            <div class="material-datatables">

                                <div class="scrollable" style="border: 1px solid lightgray;
                                border-radius: 5px;margin-left: 15px;">
                                   <p-confirmDialog #cd  icon="pi pi-exclamation-triangle" [style]="{width: responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
                                    <p-footer>

                                        <button [disabled]="loading"  *ngIf="cancellaStudente" type="button" pButton icon="pi pi-check" label="Sì" (click)="accettaCancellaStudente()">
                                            <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
                                        </button>
                                        <button *ngIf="!cancellaStudente" type="button" pButton icon="pi pi-check" label="Sì" (click)="cd.accept()"></button>
                                        <button [disabled]="loading && cancellaStudente" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>

                                    </p-footer>
                                </p-confirmDialog>

                                    <table id="datatables" class="table table-striped table-no-bordered table-hover table-fixed"
                                        cellspacing="0" width="100%" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Cognome</th>

                                                <th style="text-align: right;">Rimuovi</th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let studente of studenti;let i =index;">

                                                <td style="font-size: medium; " formcontrolname="nome">{{studente.nome}}
                                                </td>
                                                <td style="font-size: medium; " formcontrolname="cognome">
                                                    {{studente.cognome}}</td>

                                                <td style="text-align: right;"><a
                                                        (click)="eliminaStudente(studente.id,i)"><i
                                                            class="material-icons notranslate pointer"
                                                            style="color: red;">delete</i></a></td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;">
                                    <button (click)="aggiungiStudente()" *ngIf="disableButton" class="btn btn-primary">Inserisci
                                        Studente</button>
                                </div>

                            </div>
                        </ng-container>

        <ng-container *ngIf="templateEdit">

      <table id="datatables" class="table table-striped table-no-bordered table-hover table-fixed" cellspacing="0"
      width="100%" style="width:100%">
      <!-- <p-table class="table table-striped table-no-bordered table-hover" [value]="listaDocenti"
                                [(selection)]="selectedDocente" dataKey="email" width="100%" style="width:100%"> -->
      <tbody>
        <!-- </ng-template> -->
        <ng-container *ngFor="let template of listaTemplate;let i =index;">
          <tr>
            <td style="width: 3rem">
              <input type="checkbox" (click)="aula.attestato.id = template.id" [ngClass]="{'notClickable': aula.attestato.id === template.id}" [value]="template.id" [checked]="aula.attestato.id === template.id">
            </td>
            <td>{{template.titolo | pipeNameUnderscore}}</td>

          </tr>
        </ng-container>
      </tbody>
    </table>
        </ng-container>
                            <div class="pull-right ">
        <button (click)="goBack()" class="btn btn-secondary"
            style="color: white;">Indietro</button>
            <button [disabled]="loadingSave" (click)="salvaAula()" class="btn btn-primary" style="color: white;margin-left: 15px;">Salva
                <span *ngIf="loadingSave">
                    <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                        styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                </span>
            </button>
            </div>
    </div>


    <!--AGGIUNTA DOCENTE-->


    <div *ngIf="addDocente">

                            <div class="card-header">
                                <h5 class="card-title" >Seleziona un docente</h5>

                            </div>
                            <br>
                            <div id="datatables_filter" class="dataTables_filter">
                                <form [formGroup]="searchDocentiForm2">
                                    <input type="text" formControlName="search" placeholder="Cerca Docente"
                                        maxlength="28" style="margin-bottom: 2%;" class="form-control ">
                                </form>
                                <div *ngIf="searchInDocenti2()"></div>
                            </div>
                            <div class="material-datatables" style="border: 1px solid lightgray;
                            border-radius: 5px;">

                                <p-table class="table table-striped " [value]="listaTotaleDocenti"
                                    [(selection)]="selectedAddDocenti" dataKey="email" [scrollable]="true"
                                    scrollHeight="50rem">

                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 3rem">
                                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                            </th>
                                            <th>Nome</th>
                                            <th>Cognome</th>


                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body" let-docente>
                                        <tr>
                                            <td style="width: 3rem">
                                                <p-tableCheckbox [value]="docente"></p-tableCheckbox>
                                            </td>
                                            <td>{{docente.nome}}</td>
                                            <td>{{docente.cognome}}</td>

                                        </tr>

                                    </ng-template>

                                </p-table>


                            </div>
                            <div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;">
                                <a class="btn btn-secondary" (click)="backToModifica()"
                                    style="color: white;">Indietro</a>
                                <a class="btn btn-primary" *ngIf="disableButton" (click)="inserisciDocente()"
                                    style="margin-right: 4%;">Aggiungi Docente</a>
                            </div>
    </div>

    <!--AGGIUNTA STUDENTE-->

    <div *ngIf="addStudente">
                            <div class="card-header">
                                <h5 class="card-title" >Seleziona gli studenti</h5>

                            </div>
                            <br>
                            <div id="datatables_filter" class="dataTables_filter">
                                <form [formGroup]="searchStudentiForm2">
                                    <input type="text" formControlName="search" placeholder="Cerca Studente"
                                        maxlength="28" style="margin-bottom: 2%;" class="form-control ">
                                </form>
                                <div *ngIf="searchInStudenti2()"></div>
                            </div>
                            <div class="material-datatables" style="border: 1px solid lightgray;
                            border-radius: 5px;">

                                <p-table class="table table-striped " [value]="listaTotaleStudenti"
                                    [(selection)]="selectedAddStudenti" dataKey="email" [scrollable]="true"
                                    scrollHeight="50rem">

                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 3rem">
                                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                            </th>
                                            <th>Nome</th>
                                            <th>Cognome</th>

                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body" let-studente>
                                        <tr>
                                            <td style="width: 3rem">
                                                <p-tableCheckbox [value]="studente"></p-tableCheckbox>
                                            </td>
                                            <td>{{studente.nome}}</td>
                                            <td>{{studente.cognome}}</td>
                                        </tr>

                                    </ng-template>

                                </p-table>


                            </div>
                            <div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;">
                                <a class="btn btn-secondary" (click)="backToModifica()"
                                    style="color: white;">Indietro</a>
                                <a class="btn btn-primary" *ngIf="disableButton" (click)="inserisciStudente()"
                                    style="margin-right: 4%;">Aggiungi Studente</a>
                            </div>
    </div></div>


<!-- MODIFICA DA ROUTING -->
<div class="container-fluid" *ngIf="modificaRouting && caricato">
    <div class="content">
        <div class="row" >
            <div class="col-md-12">
                <div class="card p-10" >
    <div *ngIf="modifica">
        <div class="card-header" style="margin-bottom: 2%;">
            <h5 class="card-title" style="margin-bottom: 2%;">
                Modifica {{ambiente.NOMEAULA}}
            </h5>
            <form [formGroup]="aulaModForm">
                <label for="nomeMod">Identificativo<span class="colorRed"> *</span></label>
                <input type="text" placeholder="Identificativo" [ngClass]="{'mb-2p': !((submitted && l.nomeMod.errors) && l.nomeMod.errors.required)}"   formControlName="nomeMod"
                    maxlength="30" class="form-control ng-untouched ng-pristine ng-invalid">
                <div *ngIf="submitted && l.nomeMod.errors" class="invalid-feedback">
                    <div *ngIf="l.nomeMod.errors.required">Campo Identificativo obbligatorio</div>
                </div>
                <label for="descrizioneMod">Descrizione<span class="colorRed"> *</span></label>
                <input type="text" placeholder="Descrizione" formControlName="descrizioneMod" maxlength="150"
                    class="form-control ng-untouched ng-pristine ng-invalid">
                <div *ngIf="submitted && l.descrizioneMod.errors" class="invalid-feedback">
                    <div *ngIf="l.descrizioneMod.errors.required">Campo descrizione obbligatorio</div>
                </div>
                <div class="row" >
                    <label class="col-sm-2 label-on-left" style="margin-top: 30px ;    white-space: nowrap;
                    ">Temporizzare {{ambiente.NOMEAULA}}<span class="colorRed"> *</span>
                      <div *ngIf="submitted" class="invalid-feedback">
                          <div *ngIf="!selectedOptionInd">Scelta obbligatoria</div>
                      </div>
                  </label>

                    <div class="col-sm-10  label-on-left">
                        <div class="form-group label-floating is-empty char">

                            <p-selectButton [options]="opzioniInd" [(ngModel)]="selectedOptionInd" [ngModelOptions]="{standalone: true}"></p-selectButton>

                        </div>
                    </div></div>
                    <div class="row" *ngIf="selectedOptionInd == 1">
                      <label class="col-sm-2 label-on-left" style="margin-top: 30px ;">Data<span class="colorRed"> *</span>
                          <div *ngIf="submitted" class="invalid-feedback">
                              <div *ngIf="selectedOptionInd == 1">
                                 <div *ngIf="!aulaModForm.value.dataDa || !aulaModForm.value.dataA">Data obbligatoria</div>
                                 <div *ngIf="aulaModForm.value.dataDa >= aulaModForm.value.dataA">Data Invalida</div>
                                </div>
                          </div></label>

                      <div class="col-sm-2  label-on-left" >
                          <div class="form-group label-floating is-empty char">

                              <p-calendar [locale]="ita" placeholder="Da" formControlName="dataDa" dateFormat="dd.mm.yy"></p-calendar>


                          </div>
                      </div>

                          <div class="col-sm-2  label-on-left" >
                              <div class="form-group label-floating is-empty char">

                                  <p-calendar [locale]="ita" placeholder="A" formControlName="dataA" dateFormat="dd.mm.yy"></p-calendar>

                              </div>
                          </div>
                          </div>
                          <div class="row">

                            <label class="col-sm-2 label-on-left" style="margin-top: 21px;">Copertina</label>
                            <div class="col-sm-10 ">
                            <input style="display: none" type="file" (change)="caricaCopertinaChanged($event)"
                            #fileInputCopertina>
                            <div class="flex">
                            <button type="button"  class="btn btn-primary buttonFitContent"  (click)="fileInputCopertina.click()">Carica</button>

                            <div class="quadratoImmagine" >
                                <img *ngIf="!selectedFileCopertina" [src]="aula.copertina">
                                <img *ngIf="selectedFileCopertina" [src]="path">

                                <br style="width: 100%;">

                                <p class="textOneLine">{{nomeFileCopertina}}</p>
                            </div>

                        </div>
        <!-- [disabled]="loading" -->
                    </div>
                            </div>
            </form>
        </div>



        <button class="btn btn-primary" (click)="editDocente()">Modifica Docenti</button>
        <button class="btn btn-primary" (click)="editStudente()">Modifica Studenti</button>
        <button class="btn btn-primary" *ngIf="afi" (click)="editTemplate()">Modifica Template</button>
        <ng-container *ngIf="docenteEdit">
        <h5 class="card-title" style="margin-left: 15px;">
            Docenti<span class="colorRed"> *</span>
        </h5>
        <div class="card-header">
            <div id="datatables_filter" class="dataTables_filter">
                <form [formGroup]="searchDocentiForm">
                    <input type="text" formControlName="search" class="form-control" placeholder="Cerca tra i docenti"
                        maxlength="28">
                </form>
                <div *ngIf="searchInDocenti()"></div>
            </div>
        </div>


                            <br>

                            <div class="material-datatables">

                                <div class="scrollable" style="border: 1px solid lightgray;
                                border-radius: 5px;margin-left: 15px;">
                                    <p-confirmDialog #cd  icon="pi pi-exclamation-triangle" [style]="{width: responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
                                        <p-footer>

                                            <button [disabled]="loading"  *ngIf="cancellaDocente" type="button" pButton icon="pi pi-check" label="Sì" (click)="accettaCancellaDocente()">
                                                <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
                                            </button>
                                            <button *ngIf="!cancellaDocente" type="button" pButton icon="pi pi-check" label="Sì" (click)="cd.accept()"></button>
                                            <button [disabled]="loading && cancellaDocente" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>

                                        </p-footer>
                                    </p-confirmDialog>

                                    <table id="datatables" class="table table-striped table-no-bordered table-hover table-fixed"
                                        cellspacing="0" width="100%" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Cognome</th>

                                                <th style="text-align: right;">Rimuovi</th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let docente of docenti;let i =index;">
                                                <td style="font-size: medium; " formcontrolname="nome">{{docente.nome}}
                                                </td>
                                                <td style="font-size: medium; " formcontrolname="cognome">
                                                    {{docente.cognome}}</td>
                                                <td style="text-align: right;"><a
                                                        (click)="eliminaDocente(docente.id,i)"><i
                                                            class="material-icons notranslate pointer"
                                                            style="color: red;">delete</i></a></td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;">
                                    <button (click)="aggiungiDocente()" *ngIf="disableButton" class="btn btn-primary">Inserisci
                                        Docente</button>
                                </div>

                            </div>
                        </ng-container>
        <ng-container *ngIf="studenteEdit">
        <h5 class="card-title" style="margin-left: 15px;;">
            Studenti
        </h5>
        <div class="card-header">
            <div id="datatables_filter" class="dataTables_filter">
                <form [formGroup]="searchStudentiForm">
                    <input type="text" formControlName="search" class="form-control"
                        placeholder="Cerca tra gli studenti" maxlength="28">
                </form>
                <div *ngIf="searchInStudenti()"></div>
            </div>
        </div>


                            <br>

                            <div class="material-datatables">

                                <div class="scrollable" style="border: 1px solid lightgray;
                                border-radius: 5px;margin-left: 15px;">
                                   <p-confirmDialog #cd  icon="pi pi-exclamation-triangle" [style]="{width: responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
                                    <p-footer>

                                        <button [disabled]="loading"  *ngIf="cancellaStudente" type="button" pButton icon="pi pi-check" label="Sì" (click)="accettaCancellaStudente()">
                                            <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
                                        </button>
                                        <button *ngIf="!cancellaStudente" type="button" pButton icon="pi pi-check" label="Sì" (click)="cd.accept()"></button>
                                        <button [disabled]="loading && cancellaStudente" type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>

                                    </p-footer>
                                </p-confirmDialog>

                                    <table id="datatables" class="table table-striped table-no-bordered table-hover table-fixed"
                                        cellspacing="0" width="100%" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Cognome</th>

                                                <th style="text-align: right;">Rimuovi</th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let studente of studenti;let i =index;">

                                                <td style="font-size: medium; " formcontrolname="nome">{{studente.nome}}
                                                </td>
                                                <td style="font-size: medium; " formcontrolname="cognome">
                                                    {{studente.cognome}}</td>

                                                <td style="text-align: right;"><a
                                                        (click)="eliminaStudente(studente.id,i)"><i
                                                            class="material-icons notranslate pointer"
                                                            style="color: red;">delete</i></a></td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;">
                                    <button (click)="aggiungiStudente()" *ngIf="disableButton" class="btn btn-primary">Inserisci
                                        Studente</button>
                                </div>

                            </div>
                        </ng-container>

                        <ng-container *ngIf="templateEdit">

                          <table id="datatables" class="table table-striped table-no-bordered table-hover table-fixed" cellspacing="0"
                          width="100%" style="width:100%">
                          <!-- <p-table class="table table-striped table-no-bordered table-hover" [value]="listaDocenti"
                                                    [(selection)]="selectedDocente" dataKey="email" width="100%" style="width:100%"> -->
                          <tbody>
                            <!-- </ng-template> -->
                            <ng-container *ngFor="let template of listaTemplate;let i =index;">
                              <tr *ngIf="listaTemplate">
                                <td style="width: 3rem">
                                  <input type="checkbox" (click)="aula.attestato.id = template.id" [value]="template.id" [checked]="aula.attestato.id === template.id">
                                </td>
                                <td>{{template.titolo | pipeNameUnderscore}}</td>

                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                            </ng-container>
                            <div class="pull-right">
        <button (click)="goBackLista()" class="btn btn-secondary"
            style="color: white;">Indietro</button>
                <button  [disabled]="loading" (click)="salvaAula()" class="btn btn-primary"  style="color: white;margin-left: 15px;">Salva
                     <span *ngIf="loading">
                    <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                        styleClass="custom-spinner" animationDuration=".5s">
                    </p-progressSpinner>
                </span>
                </button>
            </div>
    </div>


    <!--AGGIUNTA DOCENTE-->


    <div *ngIf="addDocente">

                            <div class="card-header">
                                <h5 class="card-title" >Seleziona un docente</h5>

                            </div>
                            <br>
                            <div id="datatables_filter" class="dataTables_filter">
                                <form [formGroup]="searchDocentiForm2">
                                    <input type="text" formControlName="search" placeholder="Cerca Docente"
                                        maxlength="28" style="margin-bottom: 2%;" class="form-control ">
                                </form>
                                <div *ngIf="searchInDocenti2()"></div>
                            </div>
                            <div class="material-datatables" style="border: 1px solid lightgray;
                            border-radius: 5px;">

                                <p-table class="table table-striped " [value]="listaTotaleDocenti"
                                    [(selection)]="selectedAddDocenti" dataKey="email" [scrollable]="true"
                                    scrollHeight="50rem">

                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 3rem">
                                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                            </th>
                                            <th>Nome</th>
                                            <th>Cognome</th>


                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body" let-docente>
                                        <tr>
                                            <td style="width: 3rem">
                                                <p-tableCheckbox [value]="docente"></p-tableCheckbox>
                                            </td>
                                            <td>{{docente.nome}}</td>
                                            <td>{{docente.cognome}}</td>

                                        </tr>

                                    </ng-template>

                                </p-table>


                            </div>
                            <div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;">
                                <a class="btn btn-secondary" (click)="backToModifica()"
                                    style="color: white;">Indietro</a>
                                <a class="btn btn-primary" *ngIf="disableButton" (click)="inserisciDocente()"
                                    style="margin-right: 4%;">Aggiungi Docente</a>
                            </div>
    </div>

    <!--AGGIUNTA STUDENTE-->

    <div *ngIf="addStudente">
                            <div class="card-header">
                                <h5 class="card-title" >Seleziona gli studenti</h5>

                            </div>
                            <br>
                            <div id="datatables_filter" class="dataTables_filter">
                                <form [formGroup]="searchStudentiForm2">
                                    <input type="text" formControlName="search" placeholder="Cerca Studente"
                                        maxlength="28" style="margin-bottom: 2%;" class="form-control ">
                                </form>
                                <div *ngIf="searchInStudenti2()"></div>
                            </div>
                            <div class="material-datatables" style="border: 1px solid lightgray;
                            border-radius: 5px;">

                                <p-table class="table table-striped " [value]="listaTotaleStudenti"
                                    [(selection)]="selectedAddStudenti" dataKey="email" [scrollable]="true"
                                    scrollHeight="50rem">

                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 3rem">
                                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                            </th>
                                            <th>Nome</th>
                                            <th>Cognome</th>

                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body" let-studente>
                                        <tr>
                                            <td style="width: 3rem">
                                                <p-tableCheckbox [value]="studente"></p-tableCheckbox>
                                            </td>
                                            <td>{{studente.nome}}</td>
                                            <td>{{studente.cognome}}</td>
                                        </tr>

                                    </ng-template>

                                </p-table>


                            </div>
                            <div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;">
                                <a class="btn btn-secondary" (click)="backToModifica()"
                                    style="color: white;">Indietro</a>
                                <a class="btn btn-primary" *ngIf="disableButton" (click)="inserisciStudente()"
                                    style="margin-right: 4%;">Aggiungi Studente</a>
                            </div>
    </div>
</div></div></div>
</div></div>
