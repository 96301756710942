import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/HELPERS/auth.guard';
import { FarmacieListComponentComponent } from './farmacie-list-component/farmacie-list-component.component';


const routes: Routes = [
  { path: '', component: FarmacieListComponentComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FarmacieCallCenterRoutingModule { }
