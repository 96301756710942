<div id="main-div">
    <!-- Creazione -->
    <span *ngIf="crea" style="color:#2596be">Configurazione non disponibile in fase di creazione.<br>E' necessario procedere in fase di modifica, da mobile.<br>Da Desktop sarà invece possibile verificare lo stato del dispositivo in tempo reale.<br></span>
    <!-- Creazione -->


    <!-- Desktop | Edit ; Info -->
    <span style="color:#2596be" *ngIf="isDesktop && edit">Configurazione non disponibile da Desktop.<br>E' necessario procedere da mobile, ma è possibile verificare di seguito lo stato del dispositivo:<br></span>
    <div style="margin-top:1%">
        <div *ngIf="(info || (isDesktop && edit)) && deviceConfigured">
            <i data-toggle="tooltip" class="material-icons notranslate" style="font-size: small; color:#689f38">circle</i>
            <span style="margin-top:2%">Il dispositivo è stato configurato correttamente.</span>
        </div>
        <div *ngIf="(info || (isDesktop && edit)) && !deviceConfigured">
            <i data-toggle="tooltip" class="material-icons notranslate" style="font-size: small; color:#a09c9c">circle</i>
            <span style="margin-top:2%">Il dispositivo non ha ancora effettuato la sua prima connessione a GAP.</span>
        </div>
    </div>
    <!-- Desktop | Edit -->


    <!-- Mobile | Edit -->
    <div *ngIf="!isDesktop && edit">
        <!-- Dispositivo non configurato -->
        <span style="color:#2596be" *ngIf="resetAvvenuto && !deviceConfigured">Se il reset è avvenuto per errore, rimanere su questa pagina e premere lo Shelly Button1.<br></span>
        <button *ngIf="!deviceConfigured" class="btn btn-primary" [disabled]="caricamentoConnessione" (click)="checkShellyButton1Connection()">Connect <span *ngIf="caricamentoConnessione"> <p-progressSpinner  [style]="{width: '15px', height: '15px'}" strokeWidth="5"
            styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner></span></button>
        <!-- Dispositivo non configurato -->

        <!-- Dispositivo configurato -->
        <span style="color:#2596be" *ngIf="deviceConfigured">Il dispositivo è già stato configurato.<br></span>
        <button *ngIf="deviceConfigured" class="btn btn-primary" (click)="resetConfiguration()">Reset</button>
        <!-- Dispositivo configurato -->


        <p-dialog [draggable]="false" [resizable]="false" [(visible)]="wifiConfigDialog" [closeOnEscape]="false" modal="true">
            <h5 class="centered h-5">Configurazione Wi-Fi</h5>
            <div class="tab-content clearfix p-30">
                <span style="color:#2596be" *ngIf="deviceConfigured">Il dispositivo è stato configurato correttamente.<br>Collegarsi alla propria rete e cliccare "Continua".</span>
                <form *ngIf="!deviceConfigured">
                    <div class="row flex">
                        <label class="col-sm-2 label-on-left responsiveLabel">Nome</label>
                        <div class="col-sm-10">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label"></label>
                                <input type="text" placeholder="Nome" class="form-control" id="apname" name="fname" />
                            </div>
                        </div>
                    </div>
                    <div class="row flex">
                        <label class="col-sm-2 label-on-left responsiveLabel">Password   <a (click)="showPassword()" rel="tooltip" title="Modifica Dispositivo"
                            class="btn btn-simple" style="padding: 2px 2px 2px 2px;"><i
                                class="material-icons notranslate pointer" style="font-size: 20px;">{{visibility}}</i></a></label>
                        <div class="col-sm-10">
                            <div class="form-group label-floating is-empty">
                                <label class="control-label"></label>
                                <input [type]="passwordType" placeholder="Password" class="form-control" id="appass" name="lname" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        <button *ngIf="!deviceConfigured" class="btn btn-primary" [disabled]="caricamentoConfigurazione" (click)="configure()">Configura <span *ngIf="caricamentoConfigurazione"> <p-progressSpinner  [style]="{width: '15px', height: '15px'}" strokeWidth="5"
            styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner></span></button>

        <button [ngClass]="deviceConfigured ? 'btn btn-primary' : 'btn'" (click)="indietro()">
            <span>
                <span *ngIf="!deviceConfigured">Indietro</span>
                <span *ngIf="deviceConfigured">Continua</span>
                    <span *ngIf="caricamentoIndietro">
                    <p-progressSpinner  [style]="{width: '15px', height: '15px'}" strokeWidth="5" styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                    </span>
            </span>
        </button>
        </p-dialog>
    </div>
    <!-- Mobile | Edit -->
</div>

<p-confirmDialog
  key="resetConfiguration"
  #cdik
  icon="pi pi-exclamation-triangle"
  [style]="{ width: responsiveWidth() }"
  [acceptLabel]="'Si'"
  [baseZIndex]="5"
>
  <p-footer>
    <button
      [disabled]="dialogLoading"
      type="button"
      pButton
      icon="pi pi-check"
      label="Sì"
      (click)="accettaResetConfigurazione()"
    >
      <span *ngIf="dialogLoading">
        <p-progressSpinner
          [style]="{ width: '10px', height: '10px' }"
          strokeWidth="8"
          styleClass="custom-spinner"
          animationDuration=".5s"
        ></p-progressSpinner>
      </span>
    </button>
    <button
      [disabled]="dialogLoading"
      type="button"
      pButton
      icon="pi pi-times"
      label="No"
      (click)="cdik.reject()"
    ></button>
  </p-footer>
</p-confirmDialog>

<p-confirmDialog
  key="syncDeviceId"
  #cdi
  icon="pi pi-question-circle"
  [style]="{ width: responsiveWidth() }"
  [acceptLabel]="'Si'"
  [baseZIndex]="5"
>
  <p-footer>
    <button
      [disabled]="dialogLoading"
      type="button"
      pButton
      icon="pi pi-check"
      label="Sì"
      (click)="accettaSincronizzazioneID()"
    >
      <span *ngIf="dialogLoading">
        <p-progressSpinner
          [style]="{ width: '10px', height: '10px' }"
          strokeWidth="8"
          styleClass="custom-spinner"
          animationDuration=".5s"
        ></p-progressSpinner>
      </span>
    </button>
    <button
      [disabled]="dialogLoading"
      type="button"
      pButton
      icon="pi pi-times"
      label="No"
      (click)="cdi.reject()"
    ></button>
  </p-footer>
</p-confirmDialog>

