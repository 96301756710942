
<div class="col-md-6 offset-md-3 mt-5 " *ngIf="isDesktopDevice">
    <div class="wrapper wrapper-full-page">

        <div class="full-page login-page" data-color="blue" style="background-color:lightgray;">

            <div class="content" [ngClass]="{'nova':novamaint}" style="text-align: center; background-color: lightgray;">

                <img *ngIf="!afi && !alfaCallCenter " style=" width:150px; min-height: auto; margin-bottom: 3%; "
                    src="../../../assets/img/GAP_logo_sfumatura.png">
                    <img *ngIf="afi && !alfaCallCenter " style="width: 200px;min-height: auto; "
                    src="../../../assets/img/AFI_logo.png">
                    <img *ngIf="afi && alfaCallCenter " style="width: 200px;min-height: auto; "
                    src="../../../assets/img/logo.png">
    <router-outlet></router-outlet>
</div>
<footer class="footer" style="background-color: silver;">
    <div class="container">
        <nav class="pull-left">
            <ul>
                <li>

                </li>


            </ul>
        </nav>

        <div class="destra">

            <div *ngIf="retrievedImage" style="display: contents; ">
                <img style=" max-width: 9em; margin-right: 30px;" [src]=retrievedImage>
            </div>
            <div *ngIf="acque && !retrievedImage" style="display: contents; ">
                <img style=" max-width: 9em; margin-right: 30px;" src="../assets/img/pacque.png">
            </div>
            <div *ngIf="nabacom && !retrievedImage" style="display: contents; ">
                <img style=" max-width: 9em; margin-right: 30px;" src="../assets/img/nabacom.png">
            </div>
            <div *ngIf="ipem && !retrievedImage" style="display: contents; ">
                <img style=" max-width: 9em; margin-right: 30px;" src="../assets/img/ipem.png">
            </div>
            <span *ngIf="ifep && !retrievedImage" style="display: contents;">
                <img style=" max-width: 9em;" src="../assets/img/ifep.png">
            </span>
            <div *ngIf="novamaint" class="centra ">
            <div style="display: contents; ">
                <img style=" max-width: 9em; margin-right: 30px;     width: 40px;" src="../../../assets/img/logorep.png">
            </div>
            <div style="display: contents; ">
                <img style=" max-width: 9em; margin-right: 30px;     width: 40px;" src="../../../assets/img/logoeur.png">
            </div>
            <div style="display: contents; ">
                <img style=" max-width: 9em; margin-right: 30px;" src="../../../assets/img/logopon.png">
            </div>
            <div style="display: contents; ">
                <img style=" max-width: 9em; margin-right: 30px;" src="../../../assets/img/logocontact.png">
            </div>
            <div style="display: contents; ">
                <img style=" max-width: 12em; min-height: auto;" src="../../../assets/img/protomPoweredBy.png">

            </div>
           </div>
            <img *ngIf="!afi && !novamaint" style=" max-width: 12em; min-height: auto;" src="../../../assets/img/protomPoweredBy.png">
            <img *ngIf="afi" style=" max-width: 12em; min-height: auto;" src="../../../assets/img/poweredByAfi.png">
        </div>

    </div>
</footer>
</div>

</div>
</div>

<ng-container *ngIf="(!isDesktopDevice)">
<div class="col-md-6 offset-md-3 mt-5 " >
    <div class="wrapper wrapper-full-page">

        <div class="full-page login-page" data-color="blue" style="background-color:lightgray;">

            <div class="content" [ngClass]="{'nova':novamaint}" style="text-align: center; background-color: lightgray;">

                <img style=" width:150px; min-height: auto; margin-bottom: 3%; "
                    src="../../../assets/img/GAP_logo_sfumatura.png">
    <ng-container *ngIf="activeRoute === 'recuperapassword'" ><router-outlet></router-outlet></ng-container>
    <ng-container  *ngIf="activeRoute !== 'recuperapassword'" ><app-device-view></app-device-view></ng-container>
</div>
<footer class="footer" style="background-color: silver;">
    <div class="container">
        <nav class="pull-left">
            <ul>
                <li>

                </li>


            </ul>
        </nav>

        <div class="destra">

            <div *ngIf="retrievedImage" style="display: contents; ">
                <img style=" max-width: 9em; margin-right: 30px;" [src]=retrievedImage>
            </div>
            <div *ngIf="acque && !retrievedImage" style="display: contents; ">
                <img style=" max-width: 9em; margin-right: 30px;" src="../assets/img/pacque.png">
            </div>
            <div *ngIf="nabacom && !retrievedImage" style="display: contents; ">
                <img style=" max-width: 9em; margin-right: 30px;" src="../assets/img/nabacom.png">
            </div>
            <div *ngIf="ipem && !retrievedImage" style="display: contents; ">
                <img style=" max-width: 9em; margin-right: 30px;" src="../assets/img/ipem.png">
            </div>
            <span *ngIf="ifep && !retrievedImage" style="display: contents;">
                <img style=" max-width: 9em;" src="../assets/img/ifep.png">
            </span>
            <div *ngIf="novamaint" class="centra ">
                <div style="display: contents; ">
                    <img style=" max-width: 9em; margin-right: 30px;     width: 40px;" src="../../../assets/img/logorep.png">
                </div>
                <div style="display: contents; ">
                    <img style=" max-width: 9em; margin-right: 30px;     width: 40px;" src="../../../assets/img/logoeur.png">
                </div>
                <div style="display: contents; ">
                    <img style=" max-width: 9em; margin-right: 30px;" src="../../../assets/img/logopon.png">
                </div>
                <div style="display: contents; ">
                    <img style=" max-width: 9em; margin-right: 30px;" src="../../../assets/img/logocontact.png">
                </div>
                <div style="display: contents; ">
                    <img style=" max-width: 12em; min-height: auto;" src="../../../assets/img/protomPoweredBy.png">

                </div>
               </div>
            <img style=" max-width: 12em; min-height: auto;" src="../../../assets/img/protomPoweredBy.png">
        </div>

    </div>
</footer>
</div>

</div>
</div>
</ng-container>
