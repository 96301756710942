//ng build --configuration=production
//ng serve --configuration=production
export const environment = {
  production: true,
  AMBIENTE:'GAP',
  NOMEAULA:'Aula',
  NOMEAULAIL:'le Aule',
  NOMEAULANEL:"nell' aula",
  LEZIONI:"Lezioni",
  LEZIONIDELLE:"delle lezioni",
  LEZIONILE:"le lezioni",
  LEZIONIINCORSO: 'Lezioni in corso',
  LEZIONILA:'la lezione',
  LEZIONE:"Lezione",
  LEZIONIUNA: 'una lezione',
  CALENDARIOLEZIONI:'Calendario delle lezioni',
  NOMEAULAALLE:' alle lezioni',
  ESERCITAZIONI:'Esercitazioni',
  DOCUMENTAZIONE:'Documentazione',
  LEZIONIREGISTRATE:'Lezioni Registrate',
  CORSI:'Corsi',
  CORSO:'Corso',
  PARTECIPAZIONEAULA:'Partecipa Alla lezione',
  nomeLogin:'Benvenuto in GAP!',
  nomeLogoAlto:'Guided Activities Platform',
  nessunEducation:'Nessuna Aula',
  colore:'#2196f3',
  percorsiFormativi:'Lezione',
  go_to_client_id: '8fd579d4-1e2a-4a53-afbc-c59ae080431f',
};
