

<div class="content" *ngIf="modificaUtenteProfilo">
    <div class="container-fluid">
        <!-- <p-messages [value]="msgs" ></p-messages> -->
        <div class="row" >
            <div class="col-md-12">
                <div class="card">
                  <form [formGroup]="editForm" (ngSubmit)="onEdit()" class="form-horizontal">
                        <div class="card-header card-header-text">
                            <h4 class="card-title" >Modifica Utente</h4>
                        </div>
                        <div class="card-content">
                            <div class="row">
                                <label class="col-sm-2 label-on-left" >Nome<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" class="form-control" placeholder="Nome"
                                          formControlName="nome"

                                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" maxlength="30">
                                        <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                                          <div *ngIf="f.nome.errors.required">Campo nome obbligatorio</div>
                                          <div *ngIf="f.nome.errors.pattern">Campo nome invalido</div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left" >Cognome<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" class="form-control" placeholder="Cognome"

                                        formControlName="cognome"
                                        [ngClass]="{ 'is-invalid': submitted && f.cognome.errors }"
                                        maxlength="30" />
                                        <div *ngIf="submitted && f.cognome.errors" class="invalid-feedback">
                                            <div *ngIf="f.cognome.errors.required">Campo cognome obbligatorio</div>
                                            <div *ngIf="f.cognome.errors.pattern">Campo cognome invalido</div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left" >Email<span class="colorRed"> *</span></label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="email" class="form-control" placeholder="Email"
                                        formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" maxlength="50">
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                          <div *ngIf="f.email.errors.email">Formato email non corretto</div>
                                          <div *ngIf="f.email.errors.required">Campo email obbligatorio</div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-2 label-on-left" >Recapito Telefonico</label>
                                <div class="col-sm-10">
                                    <div class="form-group label-floating is-empty">
                                        <label class="control-label"></label>
                                        <input type="text" class="form-control"
                                        pKeyFilter="pint" placeholder="Recapito Telefonico"
                                        formControlName="recapito"
                                        [ngClass]="{ 'is-invalid': submitted && f.recapito.errors }"
                                        maxlength="11" >

                                    </div>
                                </div></div>
                                <div class="row">
                                    <label class="col-sm-2 label-on-left" >Dettagli Aggiuntivi</label>
                                    <div class="col-sm-10">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" class="form-control"
                                            placeholder="Descrizione"
                                            formControlName="descrizione"
                                            maxlength="99"
                                            >

                                        </div>
                                    </div></div>


                           <div class="row">
                            <label class="col-sm-2 label-on-left" >Profilo<span class="colorRed"> *</span></label>
                            <div class="col-sm-10">
                                <div class="form-group label-floating is-empty">

                                    <p-selectButton [options]="ruoli" formControlName="ruolo"></p-selectButton>

                                <div *ngIf="submitted && f.ruolo.errors" class="invalid-feedback-2">
                                    <div *ngIf="f.ruolo.errors.required">Campo ruolo obbligatorio</div>
                                </div>
                            </div></div>
                        </div>
                        <div class="row" >
                          <label class="col-sm-2 label-on-left" >Gruppo</label>
                          <div class="col-sm-10">
                              <div class="form-group label-floating is-empty">

                                  <p-selectButton [options]="gruppi" formControlName="gruppo" multiple="multiple"></p-selectButton>

                              <div *ngIf="submitted && f.gruppo.errors" class="invalid-feedback-2">
                                  <div *ngIf="f.gruppo.errors.required">Campo gruppo obbligatorio</div>
                              </div>
                          </div></div>
                      </div>
                      <div *ngIf="alfaintes && !alfaCall">
                        <div class="row">
                            <label class="col-sm-2 label-on-left">Informatore Scientifico</label>
                            <div class="col-sm-10">
                                <div class="form-group label-floating is-empty">
                                    <label class="control-label"></label>
                                    <input type="text" placeholder="Informatore Scientifico"
                                        formControlName="isf" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.isf.errors }"
                                        maxlength="30" />
                                    <div *ngIf="submitted && f.isf.errors" class="invalid-feedback">
                                        <!-- <div *ngIf="f.isf.errors.required">Campo  obbligatorio</div> -->
                                        <div *ngIf="f.isf.errors.pattern">Campo invalido</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-sm-2 label-on-left noWrap" >Ragione Sociale Struttura<span class="colorRed"> *</span></label>
                            <div class="col-sm-10">
                                <div class="form-group label-floating is-empty char">

                                    <div  class="select flex" style="margin-top: -8px;">
                                        <select class="form-control" formControlName="struttura"
                                        placeholder="Ragione Sociale Struttura" style="margin-top: 13px;"  >
                                            <ng-container *ngFor="let struttura of listaStrutture">
                                                <option value="{{struttura.id}}">{{struttura.struttura}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="submitted && f.struttura.errors" class="invalid-feedback">
                                            <div *ngIf="f.struttura.errors.required">Campo  obbligatorio</div>
                                            </div>
                                    </div>
                            </div></div>
                        </div>
                        <div class="row">
                            <label class="col-sm-2 label-on-left">Consenso</label>
                            <div class="col-sm-10">
                                <div class="form-group label-floating is-empty">
                                    <label class="control-label"></label>
                                    <input type="checkbox" placeholder="Consenso" formControlName="consenso"
                                        class="form-check-input" style="margin-top: 12px;"
                                        [checked]="consenso" maxlength="30" (click)="checkOn()" />
                                    <!-- [ngClass]="{ 'is-invalid': submitted && f.consenso.errors }"<div *ngIf="submitted && f.consenso.errors" class="invalid-feedback">
                         <div *ngIf="f.consenso.errors.required">Campo  obbligatorio</div>
                         <div *ngIf="f.consenso.errors.pattern">Campo invalido</div>
                     </div> -->
                                </div>
                            </div>
                        </div>
                        <div *ngIf="utenteRegistratoView" class="row">
                            <label class="col-sm-2 label-on-left" >Utente Registrato</label>
                            <div class="col-sm-10">
                                <div class="form-group label-floating is-empty">
                                    <label class="control-label"></label>
                                    <input type="checkbox" placeholder="Utente Registrato" formControlName="utenteRegistrato"
                                    class="form-check-input" style="margin-top: 12px;"
                                        [ngClass]="{ 'is-invalid': submitted && f.consenso.errors }" maxlength="30" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-sm-2 label-on-left">Identificativo Medico</label>
                            <div class="col-sm-10">
                                <div class="form-group label-floating is-empty">
                                    <label class="control-label"></label>
                                    <input type="number" min="0" max="2147483647" placeholder="Identificativo Medico"
                                        formControlName="idmedico" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.idmedico.errors }" />
                                        <div *ngIf="submitted && f.idmedico.errors" class="invalid-feedback">
                                            <div *ngIf="f.idmedico.errors.pattern">Campo invalido</div>
                                            <div *ngIf="f.idmedico.errors.max">Campo invalido</div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-sm-2 label-on-left">Carica</label>
                            <div class="col-sm-10">
                                <div class="form-group label-floating is-empty char">

                                    <p-selectButton (onOptionClick)="clickListaCarica($event)"
                                        [options]="listaCarica" optionLabel="nomeCarica"
                                        formControlName="carica"></p-selectButton>

                                    <!-- <div *ngIf="submitted && f.ruolo.errors" class="invalid-feedback-2">
                     <div *ngIf="f.ruolo.errors.required">Campo ruolo obbligatorio</div>
                 </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-sm-2 label-on-left">Attività</label>
                            <div class="col-sm-10">
                                <div class="form-group label-floating is-empty char">

                                    <p-selectButton (onOptionClick)="clickListaAttivita($event)"
                                        [options]="listaAttivita" optionLabel="nomeAttivita"
                                        formControlName="attivita"></p-selectButton>

                                    <!-- <div *ngIf="submitted && f.ruolo.errors" class="invalid-feedback-2">
                     <div *ngIf="f.ruolo.errors.required">Campo ruolo obbligatorio</div>
                 </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                               <br>

                        </div>
                        <div class="text-right" style="margin-right: 5%;">
                        <hr>
                        <div class="btn-group" role="group" aria-label="Basic example">

                          <a (click)="indietro(true)"><button type="button" class="btn btn-secondary" aria-pressed="true">Indietro</button></a>
                        </div>
                    <div class="btn-group" role="group" aria-label="Basic example">

                        <a (click)="cancella()"><button type="button" class="btn btn-secondary" aria-pressed="true" [disabled]="loading">Cancella</button></a>
                      </div>
                      <div class="btn-group" role="group" aria-label="Basic example" aria-pressed="true">
                        <button [disabled]="loading"  (click)="onEdit()" class="btn btn-primary">Salva
                            <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
                        </button>

                      </div></div>
                          <br>
                    </form>
                </div></div>
            </div> </div> </div>
