<div class="container-fluid">
    <div class="content">

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content">
                        <div class="card-header" style="padding: 0 0 0 0 ;">
                            <button (click)="indietro(true)" id="back-button" class="btn btn-round  btn-just-icon"
                            style="margin-top: 3px; color: rgb(54, 54, 54); box-shadow:none!important">
                            <i class="material-icons notranslate">arrow_back</i>
                        </button>
                            <span class="card-title" style="font-size: medium;">Ruolo </span>

                        </div>
                        
                        <form [formGroup]="searchform" class="ng-untouched ng-pristine ng-invalid">
                            <input type="text" formControlName="search" placeholder="Cerca utente" maxlength="28"
                                style="margin-bottom: 2%;" class="form-control ">
                        </form>
                        <div *ngIf="searchInUser()"></div>
                        <div class="material-datatables">

                            <div class="scrollable">

                                <table id="datatables" class="table table-striped table-no-bordered table-hover"
                                    cellspacing="0" width="100%" style="width:100%">
                                    <thead style="position: sticky; z-index: 1; background-color: white;">
                                        <tr  style="position: sticky; z-index: 1; background-color: white;" >
                                            <th style="position: sticky; z-index: 1; background-color: white;">Nome</th>
                                            <th style="position: sticky; z-index: 1; background-color: white;">Cognome</th>
                                            <th style="position: sticky; z-index: 1; background-color: white;">Email</th>
                                            <th style="position: sticky; z-index: 1; background-color: white;">Gruppo</th>
                                            <th style="text-align: right; position: sticky; z-index: 1; background-color: white;">Modifica</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let utente of users;let i =index;">

                                            <td style="font-size: medium; " formcontrolname="nome">{{utente.nome}}</td>
                                            <td style="font-size: medium; " formcontrolname="cognome">{{utente.cognome}}
                                            </td>
                                            <td style="font-size: medium; " formcontrolname="email">{{utente.email}}
                                            </td>
                                            <td><span *ngFor="let gruppo of utente.gruppos; let j= index">

                                                    <span *ngIf="j <= utente.gruppos.length-2"
                                                        style="font-size: medium; ">{{gruppo?.nomeGruppo + ', '}}</span>
                                                    <span *ngIf=" j === utente.gruppos.length-1"
                                                        style="font-size: medium; ">{{gruppo?.nomeGruppo}}</span>

                                                </span></td>
                                            <td style="text-align: right;"> <a (click)="modificaUtente(utente.id)"><i
                                                        class="material-icons notranslate pointer">create</i></a> </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    </div>

</div>
