import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Injectable()
export class BodyCodiceFiscale implements HttpInterceptor {
  constructor(
  ) {}


  intercept( request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
    return next.handle(request).pipe(map( (event:any):HttpEvent<any> => {
      if (event && event.url && event.url.includes('lista_assistiti')) {
        if (event.url.includes('page')) {
          event.body.data.content.forEach((assistito) => {
            if (!Number.isNaN(Number(assistito.codiceFiscale))) {
              assistito.codiceFiscale = '';
            }
          });
        } else {
          event.body.data.forEach((assistito) => {
            if (!Number.isNaN(Number(assistito.codiceFiscale))) {
              assistito.codiceFiscale = '';
            }
          });
        }
      } else if (event && event.url && event.url.includes('storico_assistiti')) {
        if(event.body.data.storia){
          event.body.data.storia.forEach((storia)=> {
            if(!storia.assistito.codiceFiscale || !Number.isNaN(Number(storia.assistito.codiceFiscale))) {
              storia.assistito.codiceFiscale = '';
            }
          });
        } else if(event.body.data){
          event.body.data.forEach((storia)=> {
            if(!storia.codiceFiscale || !Number.isNaN(Number(storia.codiceFiscale))) {
              storia.codiceFiscale = '';
            }
          });
        }
      } else if (event && event.url && event.url.includes('cliente/') && event.body && event.body.data && event.body.data.codiceFiscale) {
        if (!Number.isNaN(Number(event.body.data.codiceFiscale))) {
          event.body.data.codiceFiscale = '';
        }
      }
      return event;
    }))
  }


}
