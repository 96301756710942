<!-- <p-messages [value]="msgs" ></p-messages> -->



<div class="container-fluid">
    <div class="content" >

        <div class="row" >
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content">
                        <div class="card-header" >
                            <h5 class="card-title"  style="font-weight: bold;">Crea nuovo Gruppo</h5>
                            <form [formGroup]="form" >
                                <div class="marginBottom2px">
                                    <label for="nomeMod">Nome<span class="colorRed"> *</span></label>
                                    <input  type="text" placeholder="Nome" formControlName="nome" maxlength="30" class="form-control ng-untouched ng-pristine ng-invalid">
                                    <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                                        <div *ngIf="f.nome.errors">Campo nome obbligatorio</div>
                                      </div>
                                  </div>
                                  <div >
                                    <label for="descrizioneMod">Descrizione<span class="colorRed"> *</span></label>
                                    <input type="text" placeholder="Descrizione" formControlName="descrizione" maxlength="49" class="form-control ng-untouched ng-pristine ng-invalid">
                                <div *ngIf="submitted && f.descrizione.errors" class="invalid-feedback">
                                    <div *ngIf="f.descrizione.errors">Campo descrizione obbligatorio</div>
                                  </div>
                                  </div>
                               
                               
                            </form>
                        </div>
                        <br>
                        <form [formGroup]="searchform" class="ng-untouched ng-pristine ng-invalid">
                            <input type="text" formControlName="search" placeholder="Cerca utente" maxlength="28" style="margin-bottom: 2%;" class="form-control ">
                        </form>
                        <div *ngIf="searchInUser()"></div>

                        <div class="material-datatables">

                            <!-- <div class="scrollable"> -->
<p-table class="table table-striped table-no-bordered table-hover" [value]="listautenti" [(selection)]="selectedUsers3" dataKey="email" [responsive]=true [scrollable]="true" scrollHeight="40rem" scrollWidth="100rem">

    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3rem !important">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>Nome</th>
            <th>Cognome</th>
            <th>Email</th>
            <th>Gruppo</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-user>
        <tr>
            <td style="width: 3rem !important">
                <p-tableCheckbox [value]="user" ></p-tableCheckbox>
            </td>
            <td class="one-line"><span>{{user.nome}}</span></td>
            <td class="one-line"><span>{{user.cognome}}</span></td>
            <td class="one-line"><span>{{user.email}}</span></td>
            <td class="one-line"><span *ngFor="let gruppo of user.gruppos; let i= index">
                <span *ngIf="i <= user.gruppos.length-2">{{gruppo?.nomeGruppo + ', '}}</span>
            <span *ngIf=" i === user.gruppos.length-1">{{gruppo?.nomeGruppo}}</span>
            </span></td>
        </tr>

    </ng-template>
   <!-- <ng-template pTemplate="summary">
        <div class="text-right">
            <a  class="btn btn-primary" (click)="avanti()" style="color: white;">Avanti</a>
          </div>
    </ng-template> -->
</p-table>


<!-- </div> -->
<div class="text-right" style=" margin-top: 20px;margin-bottom: 20px;" >
    <button (click)="tornaGestioneGruppi(true)" class="btn">Chiudi</button>
    <button (click)="onSubmit()" class="btn btn-primary">Crea</button>
        </div>
</div>

</div>

</div>

</div>

</div>
</div>
