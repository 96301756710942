<div *ngIf="family" class="card">
    <div class="card-title">
        <button (click)="goBack(true)" id="back-button" class="btn btn-round  btn-just-icon"
            style="margin-top: 3px; color: rgb(54, 54, 54); box-shadow:none!important">
            <i class="material-icons notranslate">arrow_back</i>
        </button>
        <h4 style="display: inline;"> Famiglia {{utentefamily.nome}} {{utentefamily.cognome}}</h4>
    </div>
    <div class="card-content">
        <div class="card-header">



            <!-- ----inizio lista -->

            <div *ngIf="!add">
                <div *ngIf="!caricatoFamiliari">
                    <div class="text-center">
                        <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                            styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                    </div>
                </div>
                <div *ngIf="caricatoFamiliari">
                    <p *ngIf="!listaFamiliari || listaFamiliari.length < 1">Nessun familiare associato</p>
                </div>
                <div *ngIf="listaFamiliari && listaFamiliari.length > 0">


                    <div class="row">
                        <!-- <div class="col-md-12"> -->
                        <br>
                        <div class="material-datatables">
                            <div class="scrollable">
                                <p-confirmDialog [style]="{width: responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
                                </p-confirmDialog>
                                <table id="datatables" class="table table-striped table-no-bordered table-hover"
                                    cellspacing="0" width="100%" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Cognome</th>
                                            <th>Email</th>
                                            <th style="text-align: right;">Rimuovi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let utente of listaFamiliari;let i =index;">
                                            <td style="font-size: medium; " formcontrolname="nome">{{utente.familiare.nome}}</td>
                                            <td style="font-size: medium; " formcontrolname="cognome">{{utente.familiare.cognome}}
                                            </td>
                                            <td style="font-size: medium; " formcontrolname="email">{{utente.familiare.email}}
                                            </td>
                                            <td style="text-align: right;"><a
                                                    (click)="eliminaUtenteFamily(utente.familiare.id)"><i
                                                        class="material-icons notranslate pointer"
                                                        style="color: red;">close</i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="caricatoFamiliari" class="text-right" style=" margin-top: 20px;margin-bottom: 20px;">
                    <button (click)="aggiungi(true)" class="btn btn-success">Modifica Famiglia</button>
                </div>

            </div>
            <!---->

            <div *ngIf="add">
                <div *ngIf="!caricatoUser">
                    <div class="text-center">
                        <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                            styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                    </div>
                </div>
                <div *ngIf="caricatoUser">
                    <p *ngIf="!allUser || allUser.length < 1">Nessun utente da associare</p>

                    <div *ngIf="allUser.length > 0">
                        <div class="row">
                            <div class="col-md-12">
                                <h5 class="card-title" style="margin-left: -15px;">Seleziona un utente</h5>
                            </div>
                            <br>
                            <div id="datatables_filter" class="dataTables_filter">
                                <form [formGroup]="searchForm2">
                                    <input type="text" formControlName="search2" placeholder="Cerca utente"
                                        maxlength="28" style="margin-bottom: 2%;" class="form-control ">
                                </form>
                                <div *ngIf="searchInUser2()"></div>
                            </div>
                            <div class="material-datatables">
                                <!-- <div class="scrollable" > -->
                                <p-table class="table table-striped " [value]="listautenti"
                                    [(selection)]="selectedUsers3" dataKey="email" [scrollable]="true"
                                    scrollHeight="50rem">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 3rem">
                                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                            </th>
                                            <th>Nome</th>
                                            <th>Cognome</th>
                                            <th>Email</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-user>
                                        <tr>
                                            <td style="width: 3rem">
                                                <p-tableCheckbox [value]="user"></p-tableCheckbox>
                                            </td>
                                            <td>{{user.nome}}</td>
                                            <td>{{user.cognome}}</td>
                                            <td>{{user.email}}</td>
                                        </tr>
                                    </ng-template>

                                </p-table>
                                <h5 *ngIf="listautenti.length < 1">Utente non trovato</h5>
                                <div class="row" style="margin-top: 2%">
                                    <div class="col-xs-6">
                                        <button (click)="indietro2()" class="btn btn-secondary"
                                            style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Indietro</button>

                                    </div>
                                    <div class="col-xs-6 text-right">
                                        <button [disabled]="loading" (click)="salvaFamiliari()" class="btn btn-primary">Aggiungi
                                            Familiari
                                            <span *ngIf="loading">
                                                <p-progressSpinner [style]="{width: '10px', height: '10px'}"
                                                    strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s">
                                                </p-progressSpinner>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- fine add -->

        </div>
    </div>
</div>