import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { newArray } from '@angular/compiler/src/util';
import { environment } from 'src/environments/environment';
import { Password } from 'src/app/MODELS/USER/password';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { AccountService } from 'src/app/SERVICES/index';


@Component({
  selector: 'app-recupera-password',
  templateUrl: './recupera-password.component.html',
  styleUrls: ['./recupera-password.component.scss']
})
export class RecuperaPasswordComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  lat : number;
  lng : number;
  id : number;
  resetToken;
  retrievedImage;
  hide1 = true;
  hide2 = true;
  icona="eye-off";
  tokenSplit=[]
  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private accountService: AccountService,
      private alertService: AlertService,
      private service : ShareService
  ) {
    this.service.passaImmagine$.subscribe(res=>{
      this.retrievedImage = res;
  })
    this.route.params.subscribe(params => {
      this.resetToken = params.token;
      this.tokenSplit= params.token.split("&");
    });
   }

  ngOnInit() {
      this.form = this.formBuilder.group({
        password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
        confermapassword: ['', Validators.required]
    }, {
        validator: this.MustMatch('password', 'confermapassword')
    });


      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {

      this.submitted = true;

      let string1: string = this.form.value.password;
      let string2: string = this.form.value.confermapassword;
      let passwords : Password = new Password;
      passwords.newPassword=string1;
      passwords.currentPassword=string2;


      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }

      this.loading = true;

      this.accountService.changePassword(this.tokenSplit[0], passwords,this.tokenSplit[1])
      .pipe(first())
      .subscribe(
          data => {

              this.router.navigate(['../account/login']);
              this.loading = false;
              this.alertService.success( data.status.description);
          },
          error => {
              this.loading = false;
          });


  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

}
