import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, ShareService } from 'src/app/SERVICES';
import { Location } from '@angular/common'

@Component({
  selector: 'app-logout',
  template: `
    <style>
    :host ::ng-deep .custom-spinner .p-progress-spinner-circle {
      animation: custom-progress-spinner-dash 1s ease-in-out infinite, custom-progress-spinner-color 5s ease-in-out infinite;
  }
  
  @keyframes custom-progress-spinner-color {
      100%,
      0% {
          stroke: rgb(207, 207, 207);
      }
      40% {
          stroke: rgb(136, 143, 233);
      }
      66% {
          stroke: rgb(55, 142, 255);
      }
      80%,
      90% {
          stroke: rgb(0, 11, 165);
      }
  }
  </style>
    <div style="justify-content: center; text-align: center; margin-top:20px">
    <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
    </div> 
    `


})
export class LogoutComponent {

  constructor(
    private accountService: AccountService,
    private router: Router,
    private location2 : Location,
  ) {
    
    if (this.accountService.userValue) {
      this.accountService.logout();
      this.router.navigate(['/account/login'])
    }
    else {
      this.location2.replaceState('/account/login');
      setTimeout(()=>{location.reload();},1000)
      
    }
  }

}