import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { EnvService } from '../GENERAL/env.service';
import { AccountService } from '../USER/account.service';
import { ClassRoomInterface } from './classRoomInterface';
import { GoToClassroomService } from './go-to-classroom.service';


export function classroomFactory(
  injector: Injector, 
  httpClient:HttpClient
): ClassroomService | GoToClassroomService {
  const envService = injector.get(EnvService);

  const goToClassroomService = injector.get(GoToClassroomService);
  const classroomService = new ClassroomService(httpClient, envService);

  /** ? goToClassroomService : classroomService  */
  return envService.goTo ? goToClassroomService : classroomService;
} 

@Injectable({
  providedIn: 'root', 
  useFactory: classroomFactory,
  deps:[Injector, HttpClient]
  
})
export class ClassroomService implements ClassRoomInterface {
  baseUrl: string;
  constructor(private http: HttpClient, private env: EnvService) {
    this.baseUrl = this.env.apiUrl;
  }

  //#region Chiamata ai servizi
  getListaAule(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/lista_classroom")
  }

  revertRegistrazioni(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/elastic/change_group/?id=" + id)
  }

  getListaAlunni(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/lista_alunni/?id=" + id)
  }

  createAula(aula, wantFirma?): Observable<any> {
    let endpoint = wantFirma!='-1' ?  '/crea_classroom' : '/crea_classroom?noTemplate=true';
    return this.http.post<any>(this.baseUrl + endpoint, aula)
  }

  editAula(aula, wantFirma?): Observable<any> {
    let endpoint = wantFirma!='-1' ?  '/modifica_classroom' : '/modifica_classroom?noTemplate=true';
    return this.http.post<any>(this.baseUrl + endpoint, aula)
  }

  deleteAula(id): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/elimina_classroom/?id=" + id, null)
  }

  joinAula(id, utente, isFromChangeCam = false): Observable<any> {
    let str = this.baseUrl + "/entra_in_classroom/?idAula=" + id + '&utenteRelatore=' + isFromChangeCam;
    return this.http.post<any>(str, utente)
  }

  confermaPresenzaStudente(id, utente): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/conferma_presenza/?idAula=" + id, utente)
  }

  confermaAssenzaStudente(id, nomeAula, utente): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/conferma_assenza/?idAula=" + id + "&nomeAula=" + nomeAula, utente)
  }

  listaStudentiPresenti(idAula, nomeAula): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/appello/?idAula=" + idAula + "&nomeAula=" + nomeAula)
  }

  listaRelatori(idAula): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/lista_relatori/?idAula=" + idAula)
  }

  editRelatori(idAula, idUtente, stato, aulaId): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/modifica_relatori/?idAula=" + idAula + "&idRelatore=" + idUtente + "&stato=" + stato + "&aulaId=" + aulaId, null)
  }

  recuperaConnessioni(idAula): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/recupera_connessioni/?idAula=" + idAula)
  }

  createLezione(idAula, lezione): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/aggiorna_schedule/?idAula=" + idAula, lezione)
  }

  getLezioni(idAula): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/recupera_schedule/?idAula=" + idAula)
  }

  getLezioneOggi(idAula): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/recupera_schedule_oggi/?idAula=" + idAula)
  }

  eliminaLezione(idLezione): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/elimina_lezione/?idLezione=" + idLezione, null)
  }

  contatorePresenza(idAula): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/addMin/?idAula=" + idAula, null)
  }

  GraficoGruppi(aula): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/GraficoGruppi", aula)
  }

  GraficoEntrate(aula): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/GraficoEntrate", aula)
  }

  GraficoDurata(aula): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/GraficoDurata", aula)
  }

  GraficoDownloadEsercitazioni(aula): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/GraficoDownloadEsercitazioni", aula)
  }

  GraficoEsercitazioniNrRisposte(aula): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/GraficoEsercitazioniNrRisposte", aula)
  }

  graficoRelatori(aula): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/graficoRelatori", aula)
  }

  /**
   * controllo paginazione
   * se tutto null richiede tutta la lista senza paginazione
   * @param page: numero pagina desiderirata
   * @param size: numero di quanti risultati vuoi
   * @param ricerca: parametro per cercare su tutta la lista
  **/
  lista_classroom_leggera(page?, size?, ricerca?,campo?,cardinalita?): Observable<any> {
    if ((page || page == 0) && size && ricerca && !campo) {
      return this.http.get<any>(this.baseUrl + "/lista_classroom_leggera?page=" + page + "&size=" + size + "&ricerca=" + ricerca)
    } else if ((page || page == 0) && size && !ricerca && !campo) {
      return this.http.get<any>(this.baseUrl + "/lista_classroom_leggera?page=" + page + "&size=" + size)
    } else if ((page || page == 0) && size && !ricerca && campo && cardinalita) {
      return this.http.get<any>(this.baseUrl + "/lista_classroom_leggera?page=" + page + "&size=" + size+"&campo="+campo+"&cardinalita="+cardinalita)
    } else if ((page || page == 0) && size && !ricerca && campo) {
      return this.http.get<any>(this.baseUrl + "/lista_classroom_leggera?page=" + page + "&size=" + size+"&campo="+campo)
    } else {
      return this.http.get<any>(this.baseUrl + "/lista_classroom_leggera")
    }
  }

  lista_dettagli_classroom(idAula,flag?): Observable<any> {
    if(flag){
      return this.http.get<any>(this.baseUrl + "/info_classroom_leggera/?id=" + idAula+"&flag="+flag)
    }else{
      return this.http.get<any>(this.baseUrl + "/info_classroom_leggera/?id=" + idAula)
    }
  }
  //#endregion

  toggle_Registrazione(endpoint): Observable<any> {
    return this.http.post<any>(this.baseUrl + endpoint, null)
  }

  getListaFile(id) {
    return this.http.get<any>(this.baseUrl + '/registrazione/' + id)
  }

  deleteFile(id) {
    return this.http.delete<any>(this.baseUrl + '/registrazione/' + id)
  }

  update_mani_alzate(idAula, studente): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/update_mani_alzate/?idAula=' + idAula + '&studente=' + studente, null)
  }

  update_tempoVideoPassato(idUtente, idRegistrazione, idAula): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/registrazione/update_tempo_visualizzazione/?idUtente=' + idUtente + '&idRegistrazione=' + idRegistrazione + '&idAula=' + idAula, null)
  }

  graficoVideo(idRegistrazione, aula): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/graficoRegistrazioni/?idRegistrazione=" + idRegistrazione, aula)
  }
  // grafico_risposte_sondaggio(idAula,dataInizio,dataFine): Observable<any> {
  //   return this.http.post<any>(this.baseUrl + '/grafico_risposte_sondaggio?idAula='+idAula+'&dataInizio='+dataInizio+'&dataFine='+dataFine,null )
  // }

  getSondaggio(idAula) {
    return this.http.get<any>(this.baseUrl + '/getSondaggio/?idAula=' + idAula)
  }
  grafico_risposte_sondaggio_aula(titolo, RequestStartEndIdForm): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/grafico_risposte_sondaggio_aula?titolo=' + titolo, RequestStartEndIdForm)
  }


  grafico_report_valutazione(data1, data2) {
    return this.http.get<any>(this.baseUrl + '/grafico_report_valutazione/?data1=' + data1 + '&data2=' + data2)
  }


  getVideo(id) {
    return this.http.get<any>(this.baseUrl + '/elastic/get_doc_url/?id=' + id)
  }

  lista_insegnanti(id) {
    return this.http.get<any>(this.baseUrl + '/lista_insegnanti?id=' + id)
  }



  // getBlob(url: string): Observable<any> {
  //   let options = new HttpHeaders({
  //     'Authorization': 'Basic T1BFTlZJRFVBUFA6SmxqUHdPSVRXbTc='
  //   });

  //   let headers = {
  //     headers: options
  //   }


  //   return this.http.get<any>('https://dev-openvidu.protom.com' + '/api/recordings/AulaBellina-9', headers)
  // }

  //#region SubScribe e Socket
  /**
   * Chiamata al BE da parte del docente per apertura del popup degli studenti
   *
   * @param messaggio del docente
   * @param sondaggio vero si chiede una risposta del tipo si o no verso il partecipante
   * @param idMittende del docente o admin che invia la richiesta
   * @param classroom idAula
   * @returns
   */
  sendPool(messaggio: string, sondaggio: boolean, idMittende: number, classroom: number): Observable<any> {
    const oggettoSondaggio = {
      messaggio: messaggio,
      sondaggio: sondaggio,
      idMittende: idMittende
    }
    return this.http.post<any>(this.env.apiUrl + `/invia_sondaggio?classroom=${classroom}`, oggettoSondaggio)
  }

  /**
   * Chiamata al BE da parte dello studente per fornire la sua decisione
   * @param classroom idAula
   * @param messaggio titolo del popup sondaggio fornito dal docente al momento della creazione
   * @param sondaggio 'true' il partecipante ha detto sì
   * @param tempoInattivita intervallo di tempo trascorso tra invio del sondaggio e la risposta in intero
   * @returns
   */
  answerPool(classroom: number, messaggio: string, sondaggio: string, tempoInattivita: number): Observable<any> {
    const oggettoSondaggio = {
      messaggio: messaggio,
      sondaggio: sondaggio,
      classroom: classroom,
      tempoInattivita: tempoInattivita
    }
    return this.http.post<any>(this.env.apiUrl + `/risposta_sondaggio`, oggettoSondaggio)
  }

  /**
   * Chiedo al BE di salvare a DB inattività
   */
  saveDBPool(classroom: number): Observable<any> {
    return this.http.get<any>(this.env.apiUrl + `/salva_sondaggio?classroom=${classroom}`)
  }
  //#endregion

  upload_afi(idAula, file): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/upload_afi?idAula=' + idAula, file)
  }
  modifica_img_afi(idAula, file): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/modifica_img_afi?idAula=' + idAula, file)
  }

  conta_eventi_seguiti() {
    return this.http.get<any>(this.baseUrl + '/conta_eventi_seguiti')
  }
  conta_eventi_programmati() {
    return this.http.get<any>(this.baseUrl + '/conta_eventi_programmati')
  }
  lista_attestati() {
    return this.http.get<any>(this.baseUrl + '/lista_attestati')
  }

  modifica_schedule(idAula, idGruppo,lezione): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/modifica_schedule?idAula=' + idAula+'&idGruppo='+idGruppo, lezione)
  }
  recupera_schedule_gruppo(idGruppo) {
    return this.http.get<any>(this.baseUrl + '/recupera_schedule_gruppo?idGruppo=' + idGruppo)
  }
  elimina_schedule_gruppo(idGruppo): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/elimina_schedule_gruppo?idGruppo='+idGruppo, null)
  }
  checkRec(idAula): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/is_registrando?idAula=' + idAula)
  }

  salaAttesa(idAula, idStudente, nomeAula) {
    return this.http.get<any>(this.baseUrl + '/sala_attesa?idAula=' + idAula + '&idStudente=' + idStudente + '&nomeAula=' + nomeAula);
  }

  impostaStato(idAula, stato, body) {
    if(stato) {
      return this.http.post<any>(this.baseUrl + '/accetta_studente?idAula=' + idAula, body);
    } else {
      return this.http.post<any>(this.baseUrl + '/rifiuta_studente?idAula=' + idAula, body);
    }
  }

  getStatoStudenteAttesa(idAula, idStudente) {
    return this.http.get<any>(this.baseUrl + '/recupera_stato_studente?idAula=' + idAula + '&idStudente=' + idStudente);
  }

}
