
export class configurazioneKetron {
  type?:any;
  heartRateInterval?: any;
  temperatureInterval?: any;
  interval?:number;
  volume?:any;
  redNumber?:any;
  yellowNumber?:any;
  greenNumber?:any;
  whitelist?:any;
  sleepTime?: any;
  pingInterval?:any;
  idUser?: string;
  trackerId?: string;
  configured?: boolean;
  triggerId?: number;
}
