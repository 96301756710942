import { Injectable, Host } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs'; 
import { environment } from 'src/environments/environment';
import { EnvService } from '../GENERAL/env.service';



@Injectable({
  providedIn: 'root'
})
export class CallRateService {

  feedBack = false;
  
  constructor(private http: HttpClient, private env : EnvService) {
     
  }

  callRatePost(m) : Observable<any>{
    return this.http.post<any>(`${this.env.apiUrl}/valutazione_chiamata?valutazione=${m}`, null) 
  }
  
}