import { CartellaFiglia } from "./cartella-figlia";
import { File } from "./file";

export class Cartella {
    id: number;
    nome: string;
    cartelle: Array<CartellaFiglia> = new Array;
    file: Array<File> = new Array;
    parentId: number = null;
    canWrite?: Boolean = false;
}
