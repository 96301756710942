import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Id_gruppo } from 'src/app/MODELS/USER/id_gruppo';
import { ruolo } from 'src/app/MODELS/USER/ruolo';
import { Utente } from 'src/app/MODELS/USER/utente';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { ShareService } from 'src/app/SERVICES/GENERAL/share.service';
import { AccountService } from 'src/app/SERVICES/index';
import { UsersService } from 'src/app/SERVICES/index';

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.scss']
})
export class ProfiloComponent implements OnInit {
  utenteId
  utente;
  ruolo;
  gruppiInfo=[];
  dettaglio;
  listaUltimeChiamateUtente=[];
  modificaUser=false;
  profilo=true;
  editForm: FormGroup;
  ruoli: SelectItem[];
  listaRuoli = [];
 listaGruppi=[]
 submitted=false;
 loading;
 modifica
 gruppi: SelectItem[];
flag=false;
assistenza;
alfaintes
alfaCallCenter;
utenteAfi
consenso
utenteRegistrato
indexAttivita
indexCarica
utenteRegistratoView
listaStrutture
listaCarica
listaAttivita
consensoLable
utenteRegistratoLable
  constructor(
    private accountService: AccountService,
    private userService: UsersService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private service : ShareService,
    private envService : EnvService
  ) { 
    this.assistenza = this.envService.CR
    this.alfaintes = this.envService.alfaintes
    this.alfaCallCenter = this.envService.ALFACALLCENTER
  } 

  ngOnInit(): void {

    this.utenteId=this.accountService.userValue.data.id;
    if(this.alfaintes){
      this.userService.get_user_afi(this.utenteId).subscribe((res) => {
        this.utente=res.data;//per info
        this.ruolo=res.data.ruolo.nomeRuolo;//per info
        this.gruppiInfo=res.data.gruppos;//per info
        this.userService.lista_carica().subscribe((res) => {
          this.listaCarica = res.data;
        })
        this.userService.lista_attivita().subscribe((res) => {
          this.listaAttivita = res.data;
        })
        this.userService.lista_strutture_afi().subscribe((res)=>{
          this.listaStrutture=res.data;
        })
        if( res.data.afi && res.data.afi.consenso == 1){
          this.consensoLable = "si" 
          if( res.data.afi.utenteRegistrato == 1){
          this.utenteRegistratoLable = "si"
        }else{
          this.utenteRegistratoLable = "no"
         
        }
        }else{
          this.consensoLable = "no"
          this.utenteRegistratoLable = "no"
        }
        let gruppos = [];
        this.utente.gruppos.forEach(gruppo => {
          let id;
          id = gruppo.id;
          gruppos.push(id);
  
        });
        let utenteRegistrato
        if (res.data.afi) {
          if(this.utente.afi.attivita){
          this.indexAttivita = this.utente.afi.attivita.id - 1;
          }
          if(this.utente.afi.carica){
          this.indexCarica = this.utente.afi.carica.id - 1;
          }
          if (res.data.afi.consenso == 1) {
            this.consenso = true;
            this.utenteRegistratoView = true;
          } else {
            this.consenso = false;
          }
          if (res.data.afi.utenteRegistrato == 1) {
            utenteRegistrato = true;
          } else {
            utenteRegistrato = false;
          }
        }
        this.editForm = this.formBuilder.group({
          cognome: [this.utente.cognome, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
          email: [this.utente.email, [Validators.required, Validators.email]],
          recapito: [this.utente.telefono,],
          ruolo: [this.utente.ruolo.id, Validators.required],
          gruppo: [gruppos],
          descrizione: [this.utente.dettaglio[0]?.dettaglioAggiuntivo],
          carica: [this.utente.afi?.carica],
          attivita: [this.utente.afi?.attivita],
          isf: [this.utente.afi?.isf, [Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
          struttura: [this.utente.afi?.idStruttura,[Validators.required]],
          nome: [this.utente.nome, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
          consenso: [this.consenso],
          idmedico: [this.utente.afi?.idMedico,[Validators.pattern("([0-9])+"),Validators.max(2147483647)]],
          utenteRegistrato: [utenteRegistrato],
        })
        this.modifica = true;
      })
    }
  else{
    this.userService.info(this.utenteId).subscribe((res) => {
          
      if(res.data.dettaglio[0]?.dettaglioAggiuntivo!=="null"){
      this.dettaglio=res.data.dettaglio[0]?.dettaglioAggiuntivo;
    }
      this.utente=res.data;//per info
      this.ruolo=res.data.ruolo.nomeRuolo;//per info
      this.gruppiInfo=res.data.gruppos;//per info

      let gruppos = [];
      this.utente.gruppos.forEach(gruppo => {
        let id;
        id = gruppo.id;
        gruppos.push(id);

      });
 
      //creo form per modifica
    this.editForm = this.formBuilder.group({
      nome: [this.utente.nome, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      cognome: [this.utente.cognome, [Validators.required, Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$")]],
      email: [this.utente.email, [Validators.required, Validators.email]],
      recapito: [this.utente.telefono,],
      ruolo: [this.utente.ruolo.id, Validators.required],
      gruppo: [gruppos],
      descrizione: [this.dettaglio]
    });
    //this.service.idModifica$.subscribe(res => this.utenteId = res);
});



  
   

    //ATTIVITà RECENTI
    this.userService.ultimeDieciChiamateUtente(this.utenteId).subscribe((res)=>{             
      this.listaUltimeChiamateUtente=res.data;    
    })
  }

    this.ruoli = [];
    this.gruppi =[];
if(this.accountService.ruolo === 1){
      this.userService.getListaRuoli().subscribe((res) => {
        this.listaRuoli = res.data;
        this.listaRuoli.forEach(ruolo =>{
          this.ruoli.push(
            {label:ruolo.nomeRuolo, value:ruolo.id}
          )
        })

        this.userService.getListaGruppi().subscribe((res) => {
          this.listaGruppi = res.data;
          this.listaGruppi.forEach(gruppo =>{
            this.gruppi.push(
              {label:gruppo.nomeGruppo, value:gruppo.id}
            )
          }); 
          this.modifica = true;
        }, (error) => {
        
        });
      
      }, (error) => {
      
      });
    }
  

  }


  modificaUtente(){
      this.modificaUser=true;
      this.profilo=false;
  }


  get f() { return this.editForm.controls; }
  onEdit() {

         this.submitted = true;
         if (this.alfaintes) {
          let consenso;
          let utenteRegistrato;
         
          if (this.editForm.value.consenso == true) {
            consenso = 1;
            if (this.editForm.value.utenteRegistrato == true) {
             utenteRegistrato = 1;
            } else {
             utenteRegistrato = 0;
              }
          } else {
            consenso = 0;
            utenteRegistrato = 0;
          }
         
          let ruolos: ruolo = {};
          ruolos.id = this.editForm.value.ruolo;;
          let gruppos = [];
          this.editForm.value.gruppo.forEach(gruppo => {
            gruppos.push({nomeGruppo:gruppo.nomeGruppo ,id:gruppo,descrizioneGruppo:gruppo.descrizioneGruppo});
          })
          let userAlfaItes = {
            nome: this.editForm.value.nome,
            cognome: this.editForm.value.cognome,
            email: this.editForm.value.email,
            telefono: this.editForm.value.recapito,
            ruolo: this.editForm.value.ruolo,
            listaGruppi: gruppos,
            idCarica: this.editForm.value?.carica?.id,
            idAttivita: this.editForm.value?.attivita?.id,
            isf: this.editForm.value.isf,
            idStruttura: this.editForm.value.struttura,
            consenso: consenso,
            idMedico: this.editForm.value.idmedico,
            dettagliAggiuntivi: this.editForm.value.descrizione,
            utenteRegistrato: utenteRegistrato
          }
          this.userService.modifica_afi(userAlfaItes, this.utenteId).subscribe((res) => {
            this.alertService.success(res.status.description);
            // this.tornaGestione.emit(true)
            this.loading = false;
          }, (error) => {
            this.loading = false;
          })
    
        }else{
         let ruolos : ruolo = {};
         ruolos.id = this.editForm.value.ruolo;

         let gruppos : [Id_gruppo] = [{}];
         

         this.editForm.value.gruppo.forEach(gruppo => {
         let group : Id_gruppo = {};
         group.id = gruppo;
         gruppos.push(group);

         });
         
         if (this.editForm.invalid) {
           return;
         }
         this.utente= {
           nome: this.editForm.value.nome,
           cognome: this.editForm.value.cognome,
           email: this.editForm.value.email,
           telefono: this.editForm.value.recapito,
           ruolo: ruolos,
           gruppos: gruppos
         }
         
         
         let descrizione= this.editForm.value.descrizione;
   this.userService.update(this.utente, this.utenteId, descrizione).subscribe((res) => { 
    
     this.alertService.success(res.status.description);
    
   }, (error)=>{
   });
  }
   }



   cancella(){
    this.editForm.reset();
    this.editForm.controls.gruppo.setValue([])  }

  indietro(vai){
    this.modificaUser=false;
    this.profilo=true;
    this.userService.info(this.utenteId).subscribe((res) => {
      if(res.data.dettaglio[0]?.dettaglioAggiuntivo!=="null"){
      this.dettaglio=res.data.dettaglio[0]?.dettaglioAggiuntivo;
    }
      this.utente=res.data;
      this.ruolo=res.data.ruolo.nomeRuolo;
      this.gruppiInfo=res.data.gruppos;})
  }



  clickListaAttivita(e) {
    if (e.index == this.indexAttivita) {
      this.editForm.controls.attivita.setValue(null)
      this.indexAttivita = null;
    } else {
      this.indexAttivita = e.index;
    }
  }
  clickListaCarica(e) {
    if (e.index == this.indexCarica) {
      this.editForm.controls.carica.setValue(null)
      this.indexCarica = null;
    } else {
      this.indexCarica = e.index;
    }
  }
  checkOn() {
    setTimeout(() => {
      if (this.editForm.value.consenso) {
        this.utenteRegistratoView = true;
      } else {
        this.utenteRegistratoView = false;
      }
    });

  }
}
