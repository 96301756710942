import { ElementSchemaRegistry } from '@angular/compiler';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from 'src/app/SERVICES';
import { PrenotazioneTecnicoService } from 'src/app/SERVICES/PRENOTAZIONETECNICO/prenotazione.service';

@Component({
  selector: 'app-form-creator-component',
  templateUrl: './form-creator-component.component.html',
  styleUrls: ['./form-creator-component.component.scss'],
})
export class FormCreatorComponentComponent implements OnInit, AfterViewInit {
  @Input() isFromElimina = false;
  @Output() backShowForm = new EventEmitter<any>();
  numeroRisultati = 5
  showTable = false;
  refreshView = true;
  listaTipologie = [];
  displayedColumnsSmartwatch = ['name', 'azioni']
  dataSource
  isClickable = true;
  numeroMaxPagine
  isAgentsSelected;
  isEquipmentsSelected = true;
  isAllTrue = false;
  isAllTrueEquipments = true;
  stepperIndex = 0;
  files = [];
  valoreRicerca = ''
  pagina = 0;
  searchwordAgents = '';
  nameForm = '';
  searchwordEquipments = '';
  arrayAgent: TecnicoModule[] = [
    { name: 'Distributore', selected: false, id:1 },
    // { name: 'Giorno Demo', selected: false },
    // { name: 'Agente', selected: false },
    // { name: 'Numero Agente', selected: false },
    { name: 'Nome Azienda', selected: false, id:5 },
    { name: 'Contatto Cliente', selected: false, id:6 },
    { name: 'E-mail Cliente', selected: false, id: 11},
    { name: 'Indirizzo Cliente', selected: false, id:7 },
    { name: 'Prodotti da Realizzare', selected: false, id:8 },
    { name: 'Prodotti da utilizzare', selected: false, id:9 },
    { name: 'Potenziale del Cliente', selected: false, id:10 },
    // { name: 'Ordine effettuato', selected: false, id: 12},
    // { name: 'Taglia gadget', selected: false, id: 13},

  ];
  arrayEquipments: TecnicoModule[] = [
    { name: 'Abbattitore', selected: true, id:15 },
    { name: 'Cella di lievitazione con umidità', selected: true, id:13 },
    { name: 'Cella di lievitazione senza umidità', selected: true, id:14 },
    { name: 'Erogatore acqua temp. Controllata', selected: true, id:19 },
    { name: 'Forno a Legna', selected: true, id:4 },
    { name: 'Forno da Pizzeria', selected: true, id:5 },
    { name: 'Forno Rational', selected: true, id:6 },
    { name: 'Forno Rotor', selected: true, id:2 },
    { name: 'Forno Statico', selected: true, id:1 },
    { name: 'Forno Tubi', selected: true, id:7 },
    { name: 'Forno Ventilato', selected: true, id:3 },
    { name: 'Freezer', selected: true, id:16 },
    { name: 'Frigo', selected: true, id:17 },
    { name: 'Impastatrice da Pizzeria', selected: true, id:12 },
    { name: 'Impastatrice Forcella', selected: true, id:11 },
    { name: 'Impastatrice Planetaria', selected: true, id:10 },
    { name: 'Impastatrice Spirale', selected: true, id:8 },
    { name: 'Impastatrice Tuffanti', selected: true, id:9 },
    { name: 'Microonde', selected: true, id:21 },
    { name: 'Sfogliatrice', selected: true, id:18 },
    { name: 'Temperatrice', selected: true, id:20 },
  ]
  prodotti = false;

  constructor(private tecnicoService: PrenotazioneTecnicoService, private alertService: AlertService) {}

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    if(this.isFromElimina) {
      this.refreshView = false;
      this.chiamataPaginata(0, null, 5);
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.valoreRicerca = filterValue.trim().toLowerCase();
    this.pagina = 0
    this.refreshView = false;
    this.isClickable = false;
    this.tecnicoService.listaTipologia(this.pagina, this.numeroRisultati, this.valoreRicerca).subscribe((res)=>{

        this.numeroMaxPagine = Number(res.data.totalPages);
        this.dataSource = new MatTableDataSource(res.data.content);
        this.refreshView = true;
        this.isClickable = true;
    })
  }


  cambiaValue() {
    this.isClickable = false;
    this.pagina = 0;
    this.chiamataPaginata(this.pagina,this.valoreRicerca, this.numeroRisultati)
  }
  checkUncheckAllAgents() {
    if (this.isAllTrue) {
      this.arrayAgent.forEach((element, index) => {
        if(element.name.toLowerCase().includes(this.searchwordAgents.toLowerCase())) {
          element.selected = false;
          this.isAllTrue = false;
          this.isAgentsSelected = false;
        }
      });
    } else {
      this.arrayAgent.forEach((element, index) => {
        if(element.name.toLowerCase().includes(this.searchwordAgents.toLowerCase())) {
          element.selected = true;
          this.isAllTrue = true;
          this.isAgentsSelected = true;
        }
      });
    }
  }

  checkallCheckboxAgents() {
    this.isAllTrue = true;
    this.arrayAgent.forEach((element, index) => {
      if (element.name.toLowerCase().includes(this.searchwordAgents.toLowerCase())) {
        if (!element.selected) {
          this.isAllTrue = false;
        }
      }
    });
    if (this.isAllTrue) {
      this.isAgentsSelected = true;
    } else {
      this.isAgentsSelected = false;
    }
  }

  checkUncheckAllEquipments() {
    if (this.isAllTrueEquipments) {
      this.arrayEquipments.forEach((element, index) => {
        if(element.name.toLowerCase().includes(this.searchwordEquipments.toLowerCase())) {
          element.selected = false;
          this.isAllTrueEquipments = false;
          this.isEquipmentsSelected = false;
        }
      });
    } else {
      this.arrayEquipments.forEach((element, index) => {
        if(element.name.toLowerCase().includes(this.searchwordEquipments.toLowerCase())) {
          element.selected = true;
          this.isAllTrueEquipments = true;
          this.isEquipmentsSelected = true;
        }
      });
    }
  }

  checkallCheckboxEquipments() {
    this.isAllTrueEquipments = true;
    this.arrayEquipments.forEach((element, index) => {
      if(element.name.toLowerCase().includes(this.searchwordEquipments.toLowerCase())) {
        if (!element.selected) {
        this.isAllTrueEquipments = false;
      }
    }
    });
    if (this.isAllTrueEquipments) {
      this.isEquipmentsSelected = true;
    } else {
      this.isEquipmentsSelected = false;
    }
  }

  checkLeastOneSelected() {
    let res = false;
    this.arrayAgent.forEach((element) => {
      if (element.selected) res = true;
    });
    return res;
  }
  checkValidityName() {
    return this.nameForm.replace(/[ ]+$/g, '') !== '';
  }
  goBack(stepper: MatStepper) {
    this.stepperIndex = stepper.selectedIndex;
    if (this.stepperIndex === 0) {
      this.backShowForm.emit()
    } else {
      stepper.previous();
    }
  }

  goForward(stepper: MatStepper) {
    if(stepper.selectedIndex === 0) {
      if(!this.checkValidityName()) {
        this.alertService.error('Inserisci un nome valido')
        return;
      }
    }
    if(stepper.selectedIndex === 1) {
      if(!this.checkLeastOneSelected()) {
        this.alertService.error('Seleziona almeno un campo');
        return;
      }
    }
    stepper.next();
    this.stepperIndex = stepper.selectedIndex;
  }

  changeIndex(stepper: MatStepper) {
    this.stepperIndex = stepper.selectedIndex
  }
  saveForm() {
    let obj: any = {};
    let arrayAgentSelected = [];
    this.arrayAgent.forEach(el=>{
      if(el.selected) arrayAgentSelected.push({id:el.id, nome: el.name})
    })
    arrayAgentSelected.push({id:2, nome: "Giorno Demo"});
    arrayAgentSelected.push({id:3, nome: "Agente"});
    arrayAgentSelected.push({id:4, nome: "Numero Agente"});
    let arrayEquipments = [];
    this.arrayEquipments.forEach(el=>{
      if(el.selected) arrayEquipments.push({id:el.id, nomeAttrezzatura : el.name})
    })
    let arrayfiles = [];
    this.files.forEach(el=>{
      arrayfiles.push({url:el.id, nomeProdotto: el.title})
    })

    obj.nomeTipologia = this.nameForm;
    obj.combinazioniForm = arrayAgentSelected;
    obj.attrezzature = arrayEquipments;
    obj.prodotti = this.prodotti;//arrayfiles;
    this.tecnicoService.salvaTipologia(obj).subscribe((res)=>{
      this.backShowForm.emit()
    })
  }
  getFiles(event) {
    this.files = event;
  }
  deleteTipologia(id) {
    this.refreshView = false;
    this.tecnicoService.deleteTipologia(id).subscribe((res)=> {
      this.pagina = 0;
      if (this.valoreRicerca) this.chiamataPaginata(this.pagina, this.valoreRicerca)
      if (!this.valoreRicerca || this.valoreRicerca == '') this.chiamataPaginata(this.pagina)
    })
  }

  cambiaPagina(event) {
    this.isClickable = false;
    this.pagina = event;
    if(this.valoreRicerca && this.valoreRicerca.length >0){
      this.chiamataPaginata(this.pagina, this.valoreRicerca)

    }else{
      this.chiamataPaginata(this.pagina)
    }
  }
  chiamataPaginata(pagina, valore?, numeroRisultati = 5, ) {
    this.refreshView = false;
    this.tecnicoService.listaTipologia(pagina, numeroRisultati, valore).subscribe((res)=>{

        this.numeroMaxPagine = Number(res.data.totalPages);
        this.dataSource = new MatTableDataSource(res.data.content);
        this.refreshView = true;
        this.isClickable = true;
    })
  }
}

export class TecnicoModule {
  id: number;
  name: string;
  selected: boolean;
}

export class AttrezzaturaModule {
  name: string;
  selected: boolean;
}
