<div class="content">
  <div class="container-fluid">
    <ng-container *ngIf="report">
      <div class="col-xs-12">
        <ng-container *ngIf="selezionaAssistito">
          <h4>Seleziona assistito</h4>

          <div id="datatables_filter" class="dataTables_filter">
            <form [formGroup]="searchform">
              <label class="form-group" style="width: 100%">
                <input
                  type="text"
                  formControlName="search"
                  class="form-control"
                  placeholder="Cerca..."
                  maxlength="30"
                  (input)="searchInClients()"
                />
              </label>
            </form>
          </div>
          <div class="table-responsive scrollable">
            <p-table
              class="table table-striped"
              [value]="allAssistiti"
              [(selection)]="selectedAssistiti"
              dataKey="email"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 3rem !important" class="pl-6">
                    <p-checkbox
                      [(ngModel)]="isAllSelected"
                      [binary]="true"
                      (onChange)="selectAll()"
                    ></p-checkbox>
                  </th>
                  <th>Nome</th>
                  <th>Codice Fiscale</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-assistito>
                <tr>
                  <td style="width: 3 !important" class="pl-6">
                    <p-checkbox
                      (onChange)="
                        assistito.selected === true
                          ? selectedAssistiti.push(assistito)
                          : selectedAssistiti.splice(
                              selectedAssistiti.indexOf(assistito),
                              1
                            );
                        allSelected()
                      "
                      [binary]="true"
                      [value]="assistito.selected"
                      [(ngModel)]="assistito.selected"
                    ></p-checkbox>
                  </td>
                  <td class="one-line">
                    <span>{{ assistito.nome + " " + assistito.cognome }}</span>
                  </td>
                  <td class="one-line">
                    <span>{{ assistito.codiceFiscale }}</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div class="text-right">
            <a
              class="btn btn-primary"
              (click)="avanti()"
              style="
                color: white;
                margin-right: 2%;
                margin-bottom: 2%;
                margin-top: 2%;
              "
              >Avanti</a
            >
          </div>
          <!-- <ng-template pTemplate="summary"> -->

          <!-- </ng-template> -->
        </ng-container>
      </div>

      <div *ngIf="!selezionaAssistito">
        <div class="col-xs-12">
          <div
            class="card-content"
            class="text-center"
            style="margin-bottom: 3%"
          >
            <h4 style="margin-bottom: 3%">Seleziona la data</h4>
            <!--<form [formGroup]="calendar" > -->
            <!-- <p-calendar [(ngModel)]="value1" [numberOfMonths]="2" [inline]="true" (onSelect)="onDateSelection($event)" ></p-calendar> -->
            <!-- </form> -->
            <form
              [formGroup]="calendar"
              class="box-model form-support-margin overflowAuto"
            >
              <p-calendar
                view="date"
                formControlName="calendario"
                (onSelect)="selezionaData()"
                [maxDate]="today"
                [readonlyInput]="true"
                [numberOfMonths]="2"
                yearRange="2000:2030"
                [(ngModel)]="rangeDates"
                [showOtherMonths]="false"
                placeholder="Select time period"
                selectionMode="range"
                [inline]="true"
                dateFormat="dd/mm/yy"
                [locale]="ita"
              >
              </p-calendar>
              <div class="card-footer text-right">
                <a
                  class="btn btn-secondary"
                  (click)="indietro()"
                  style="color: white"
                  >Indietro</a
                >

                <button
                  [disabled]="loading"
                  class="btn btn-primary"
                  rel="tooltip"
                  title="Scarica Report"
                  (click)="inviaDati()"
                  style="max-width: 2%"
                >
                  <i class="fa fa-folder"
                    ><span *ngIf="loading">
                      <p-progressSpinner
                        [style]="{ width: '10px', height: '10px' }"
                        strokeWidth="8"
                        styleClass="custom-spinner"
                        animationDuration=".5s"
                      ></p-progressSpinner> </span
                  ></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
