import { Component, ViewChild, OnDestroy, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { OpenviduSessionComponent, StreamEvent, Session, UserModel, OpenViduLayout, OvSettings, OpenViduLayoutOptions, SessionDisconnectedEvent, Publisher } from 'openvidu-angular'; #OPV
import { ResourceLoader } from '@angular/compiler';
import { Router } from '@angular/router';
import { OperatorService } from 'src/app/SERVICES/ASSISTENZA/operator.service';
import { AccountService, AlertService, UsersService, ShareService } from 'src/app/SERVICES';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from 'src/app/HELPERS/candeactivate-guard.service';
import {ConfirmationService} from 'primeng/api';
import { ChimeJoinResponse } from 'npx-gap-chime';
import { UserStream } from 'src/app/MODELS/ASSISTENZA/user_stream.model';
import { Utente } from 'src/app/MODELS/USER/utente';
import {version} from '../../../../node_modules/npx-gap-chime/package.json';

@Component({
  selector: 'app-videochiamata',
  templateUrl: './videochiamata.component.html',
  styleUrls: ['./videochiamata.component.less'],
  providers: [ConfirmationService]
})
export class VideochiamataComponent implements OnDestroy, CanComponentDeactivate, OnInit {
  chimeVersion = version
  motivoChiamata = true;
  motiviChiamata = [];
  selectedMotivo: any;
  selectedRoom: any;
  controlRooms: any;
  controlRoom = false;
  access;
  permessi = false;
  stream;
  isChime = false;
  constraints = {
    video: true,
    audio: true
  }
  userLogged: UserStream;
  selectedMeetingId: any;
  mostraJoinMeetingComponent: boolean = false;
  sendJson = new BehaviorSubject<ChimeJoinResponse | null>(null) 

  constructor(private httpClient: HttpClient, private operatorService: OperatorService,
    private accountService: AccountService,
    private alertService: AlertService,
    private userService: UsersService,
    private service: ShareService,
    private router: Router,
    private env: EnvService,
    private confirmationService: ConfirmationService) {
      this.isChime = this.env.chime
      if(!this.env.CR){
        this.router.navigate([''])
      }
    this.userService.getMotivi().subscribe((res) => {
      this.motiviChiamata = res.data;

    })
  }

  ngOnInit(): void {
    console.log("Chime version", this.chimeVersion);
  }
  ngOnDestroy(): void {
    this.access = false;
    if(this.stream){
    const track = this.stream.getTracks();
    track[0].stop();
    track[1].stop();
  }
  }

  reload() {
    // this.alertService.error("Per continuare, è necessario fornire i permessi")
    setTimeout(() => {
      this.router.navigate(['']);
    }, 3000)
  }
  // inserisciMotivoChiamata() {
  //   if(!this.selectedMotivo){
  //     this.alertService.error('Per continuare, seleziona una motivazione')
  //     return
  //   }
  //   navigator.mediaDevices.getUserMedia(this.constraints).then(res => { this.stream = res; this.motivoChiamata = false; this.access = true; }).catch(err => {
  //     this.alertService.error("Per continuare, è necessario fornire i permessi")

  //     this.reload()});
  // }

  inserisciMotivoChiamata(){
    if(!this.selectedMotivo){
      this.alertService.error('Per continuare, seleziona una motivazione');
      return;
    }
    this.motivoChiamata = false;
    this.controlRoom = true;
    this.receiveControlRooms();
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean  {
    if(this.access){
    this.confirmationService.confirm({
      message: 'Sei sicuro di voler uscire?',

      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.service.navigateAwaySelection$.next(true);
      },
      reject: () => {
        this.service.navigateAwaySelection$.next(false);
      }
    });
    return this.service.navigateAwaySelection$;
  }
  else {
    return true;
  }
  }

  receiveControlRooms() {
    this.operatorService.getControlRooms()
      .then(data => {
        console.log('Control Room', data);
        this.controlRooms = data
        console.log('Data test', this.controlRooms.data);
      })
      .catch(error => {
        console.error('Errore:', error);
      });
  }

  joinRoom(roomKey: string){
    console.log('Joined Control Room: ', roomKey)
  }

  joinMeeting(meetingId: string) {
    this.selectedMeetingId = meetingId;
    this.controlRoom = false;
    this.mostraJoinMeetingComponent = true;
    this.userLogged = new UserStream(
      '#video-user',
      this.accountService.userValue.data.email
    );
  }

  callJoinMeeting() {
    
    if (this.selectedMeetingId) {
      this.operatorService.postJoinMeeting(this.selectedMeetingId, this.userLogged)?.then((json) => {
        if (json instanceof ChimeJoinResponse) {
          this.sendJson.next(json);
          this.mostraJoinMeetingComponent = true;
        } else {
          console.error(json);
        }
      });
    } else {
      console.error('ID del meeting non valido');
    }
  }

editAccess(){
  this.access = false;
  this.router.navigate(['']);
}
responsiveWidth() {    }

}
