export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public apiUrl = '';
  public wssUrl = '';
  public versione = '';
  public acque = false;
  public ifep = false;
  public ipem = false;
  public nabacom = false;
  public alfaintes = false;
  public MOBILEVIEW = false;
  public AR = false;
  public CLASSROOM = false;
  public DMS = false;
  public CR = false;
  public GEO = false;
  public CHATBOT = false;
  public IOT = false;
  public BADGE = false;
  public ALFACALLCENTER = true;
  public VIEWER360 = false;
  public LAVAGNA = false;
  public SCORM = false;
  public NEWSLETTER = false;
  public immaginiLavagna = 0;
  public templatesEmail = 0;
  public RECORDING = false;
  public EVENTI = false;
  public LIVECHAT = false;
  public CMSAFI = '';
  public serverIot = '';
  public chime = false;
  public novamaint = false;
  public alfaCall = false;
  public CLIENTI = false;
  public TRACKING = false;
  public goTo = false;

  constructor() {}
}
