import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-zoombar',
  templateUrl: './zoombar.component.html',
  styleUrls: ['./zoombar.component.scss']
})
export class ZoombarComponent implements OnInit {

  @ViewChild('invisibleText') invTextER: ElementRef;

  percentage: number = 100;

  width: number = 35;

  @Output() currentZoomEmitter = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  changeZoom(zoom) {
    if(zoom && this.percentage < 1638) {
      this.percentage = this.percentage + 10;
      if(this.percentage > 1638) {
        this.percentage = 1638
      }
      this.resizeInput();
      this.currentZoomEmitter.emit(this.percentage);
      return;
    }
    if(this.percentage > 20 && !zoom) {
      this.percentage = this.percentage - 10;
      this.resizeInput();
      this.currentZoomEmitter.emit(this.percentage)
      return;
    }
  }

  resizeInput(inputText?) {
    setTimeout ( () =>{
      if(this.percentage < 20) {
        this.percentage = 20;
      } else if(this.percentage > 1638){
        this.percentage = 1638;
      }
      const minWidth = 30;

      if (this.invTextER.nativeElement.offsetWidth > minWidth) {
        this.width = this.invTextER.nativeElement.offsetWidth + 4;
        this.currentZoomEmitter.emit(this.percentage);
      } else {
        this.width = minWidth;
        this.currentZoomEmitter.emit(this.percentage);
      }

    }, 0);
  }
}
