<ng-container *ngIf="!goToMode; else goToView">
  <div *ngIf="aule">
    <div *ngIf="!caricato">
      <div class="text-center">
        <p-progressSpinner
          [style]="{ width: '40px', height: '40px' }"
          strokeWidth="5"
          styleClass="custom-spinner2"
          animationDuration=".5s"
        ></p-progressSpinner>
      </div>
    </div>
    <div class="card-content scrollMobile mb-2p">
      <p-confirmDialog
        #cd
        key="cancella"
        icon="pi pi-exclamation-triangle"
        [style]="{ width: responsiveWidth() }"
        [acceptLabel]="'Si'"
        [baseZIndex]="5"
      >
        <p-footer>
          <button
            [disabled]="loading"
            *ngIf="cancellaAula"
            type="button"
            pButton
            icon="pi pi-check"
            label="Sì"
            (click)="accettaCancella()"
          >
            <span *ngIf="loading">
              <p-progressSpinner
                [style]="{ width: '10px', height: '10px' }"
                strokeWidth="8"
                styleClass="custom-spinner"
                animationDuration=".5s"
              ></p-progressSpinner>
            </span>
          </button>
          <button
            *ngIf="!cancellaAula"
            type="button"
            pButton
            icon="pi pi-check"
            label="Sì"
            (click)="cd.accept()"
          ></button>
          <button
            [disabled]="loading && cancellaAula"
            type="button"
            pButton
            icon="pi pi-times"
            label="No"
            (click)="cd.reject()"
          ></button>
        </p-footer>
      </p-confirmDialog>

      <div class="text-right">
        <button
          *ngIf="caricato"
          (click)="vaiCreazione()"
          class="btn btn-primary"
          style="
            color: white;
            margin-right: 2%;
            margin-bottom: 2%;
            margin-top: 2%;
          "
        >
          Crea {{ ambiente.NOMEAULA }}
        </button>
      </div>

      <!-- --------------------lista dopo -->

      <div class="tab-pane" id="pill2">
        <div class="material-datatables">
          <div class="row">
            <div class="col-sm-3 col-xs-3">
              <div
                class="dataTables_length"
                id="datatables_length"
                [ngClass]="{ disabled: isSelectingValue }"
              >
                <div style="display: flex">
                  <label class="form-group"
                    >Limita Risultati
                    <select
                      [(ngModel)]="selectedValue"
                      (ngModelChange)="cambiaValue()"
                      aria-controls="datatables"
                      class="form-control input-sm optionNoGray"
                    >
                      <option class="optionNoGray" value="10">10</option>
                      <option class="optionNoGray" value="20">20</option>
                      <option class="optionNoGray" value="50">50</option>
                    </select>
                    <div
                      *ngIf="isSelectingValue"
                      style="display: flex; align-items: end"
                    >
                      <div
                        class="text-center"
                        style="position: absolute; right: 0px"
                      >
                        <p-progressSpinner
                          [style]="{ width: '20px', height: '20px' }"
                          strokeWidth="5"
                          styleClass="custom-spinner2"
                          animationDuration=".5s"
                        >
                        </p-progressSpinner>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-sm-9 col-xs-9">
              <div
                id="datatables_filter"
                class="dataTables_filter"
                style="display: flex; justify-content: flex-end"
              >
                <form [formGroup]="searchform">
                  <label class="form-group pull-right"
                    >Esegui una ricerca
                    <div style="display: flex">
                      <input
                        (input)="search()"
                        type="text"
                        formControlName="search"
                        class="form-control"
                        placeholder="Cerca..."
                        maxlength="30"
                        style="width: 40vw"
                      />
                      <div
                        *ngIf="isSearchingOn"
                        style="display: flex; align-items: end"
                      >
                        <div
                          class="text-center"
                          style="position: absolute; right: 0px"
                        >
                          <p-progressSpinner
                            [style]="{ width: '20px', height: '20px' }"
                            strokeWidth="5"
                            styleClass="custom-spinner2"
                            animationDuration=".5s"
                          ></p-progressSpinner>
                        </div>
                      </div>
                    </div>
                  </label>
                </form>

                <!-- <div *ngIf="searchInUser()"></div>   -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <p *ngIf="listaaule.length < 1 && caricato">
        {{ ambiente.nessunEducation }} disponibile
      </p>

      <table
        *ngIf="listaaule.length > 0"
        id="datatables"
        class="table table-no-bordered table-hover tableAule w-100"
        cellspacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <!-- <th>#</th> -->
            <th>{{ ambiente.NOMEAULA }}</th>
            <th>Descrizione</th>
            <th (click)="ordinaArray('stato')" style="cursor: pointer">
              Stato
              <i class="material-icons notranslate" style="font-size: 15px"
                >unfold_more</i
              >
            </th>

            <th class="text-right">Azioni</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th width="30%">{{ ambiente.NOMEAULA }}</th>
            <th width="30%">Descrizione</th>
            <th width="30%">Stato</th>
            <th width="10%" style="text-align: right">Azioni</th>
          </tr>
        </tfoot>

        <tbody>
          <ng-container
            *ngFor="let aula of listaaule; let i = index"
            id="myGroup"
          >
            <tr>
              <!-- <td style="width: 3rem">
                    <p-tableCheckbox [value]="motivo" ></p-tableCheckbox>
                </td> -->
              <td width="30%">{{ aula.nomeAula }}</td>
              <td width="30%">{{ aula.descrizioneAula }}</td>
              <td *ngIf="!aula.start" width="30%">Attiva</td>
              <td *ngIf="aula.start && aula.suspended == 0" width="30%">
                Attiva dal {{ aula.start | date : "dd/MM/yyyy" }} al
                {{ aula.end | date : "dd/MM/yyyy" }}
              </td>
              <td *ngIf="aula.start && aula.suspended == 1" width="30%">
                Non attiva
              </td>

              <td style="text-align: right" width="10%">
                <a
                  (click)="vaiModifica(aula.id)"
                  rel="tooltip"
                  title="Modifica {{ ambiente.NOMEAULA }}"
                  class="btn btn-success btn-simple"
                  style="padding: 2px 2px 2px 2px"
                  ><i
                    class="material-icons notranslate pointer"
                    style="font-size: 20px"
                    >edit</i
                  ></a
                >
                <a
                  (click)="vaiInfo(aula.id)"
                  rel="tooltip"
                  title="Info {{ ambiente.NOMEAULA }}"
                  style="padding: 2px 2px 2px 2px"
                  class="btn btn-warning btn-simple"
                  ><i
                    class="material-icons notranslate pointer"
                    style="font-size: 20px"
                    >info</i
                  ></a
                >
                <a
                  (click)="elimina(aula.id)"
                  rel="tooltip"
                  title="Elimina {{ ambiente.NOMEAULA }}"
                  class="btn btn-simple btn-danger btn-icon remove"
                  style="padding: 2px 2px 2px 2px"
                  ><i
                    class="material-icons notranslate pointer"
                    style="font-size: 20px"
                    >close</i
                  ></a
                >
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div class="row">
        <div class="col-sm-5">
          <div
            class="dataTables_info"
            id="datatables_info"
            role="status"
            aria-live="polite"
            style="margin-top: 5%"
          >
            {{ items }} di {{ maxItems }} Webinar
          </div>
        </div>
        <div class="col-sm-7 text-right giustificaDestra">
          <app-paginator
            *ngIf="refreshView"
            [pageSize]="numeroMaxPagine"
            [(isClickable)]="isClickable"
            (currentPageEmitter)="cambiaPagina($event)"
            [startingPage]="pagina + 1"
          ></app-paginator>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="crea">
    <app-crea-aula (tornaLista)="vediLista($event)"></app-crea-aula>
  </div>
  <div *ngIf="info">
    <app-info-aula
      (tornaLista)="vediLista($event)"
      [aula]="aula"
    ></app-info-aula>
  </div>
  <div *ngIf="edit">
    <app-modifica-aula
      (tornaLista)="vediLista($event)"
      [aula]="aula"
    ></app-modifica-aula>
  </div>
</ng-container>

<ng-template #goToView>
  <app-aula-virtuale-config-go-to></app-aula-virtuale-config-go-to>
</ng-template>
