export class Alert {
    type: AlertType;
    summary: string;
    detail: string;
    
}

export enum AlertType {
    success= 'success',
    error = 'error',
    info = 'info',
    warn = 'warn'
}