<div class="content" *ngIf="loaded">
    <div class="container-fluid">
        <div class="row" >
            <div class="col-md-12">
                <div class="card">

    <div class="card-header card-header-text" style="margin-top: 2%;justify-content: center;">
        <h5 class="card-title" >Info utente</h5>
     <br>
     <small *ngIf="(alfaintes && !alfaCall) && utenteCondiviso == '1'" class="text-muted" style="color:red">Questo profilo è condiviso</small>

    </div>
    <div class="content">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th style="width: 20%;"></th>
                    <th style="width: 80%;"></th>
                </tr>
            </thead>
            <tbody class="animation3">
                <tr>
                    <td class="text-right" style="max-width: 30%;">
                        <h6 >Nome</h6>
                    </td>
                    <td  >{{dettaglioUtente?.nome}}</td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Cognome</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente?.cognome}}</td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Email</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente?.email}}</td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Telefono</h6>
                    </td>
                    <td class="col-10">{{dettaglioUtente?.telefono}}</td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Dettagli Aggiuntivi</h6>
                    </td>
                    <td class="col-10">
                        {{dettaglio}}
                           </td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Profilo</h6>
                    </td>
                    <td class="col-10">{{ruolo}}

                    </td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Gruppo</h6>
                    </td>
                    <td class="col-10">
                        <span *ngFor="let gruppo of gruppi; let i=index">
                        <span *ngIf="i <= gruppi.length-2">{{gruppo?.nomeGruppo + ', '}}</span>
                            <span *ngIf=" i === gruppi.length-1">{{gruppo?.nomeGruppo}}</span>
                            </span>

                    </td>
                </tr>
                <!-- alfaIntes -->
            </tbody>
        </table>

            <table *ngIf="alfaintes && !alfaCall" class="table table-striped">
                <thead>
                    <tr>
                        <th style="width: 20%;"></th>
                        <th style="width: 80%;"></th>
                    </tr>
                </thead>
        <tbody >

                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Informatore Scientifico</h6>
                    </td>
                    <td class="col-10">{{informatore}}

                    </td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Struttura</h6>
                    </td>
                    <td class="col-10">{{struttura}}

                    </td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Consenso</h6>
                    </td>
                    <td class="col-10">{{consenso}}

                    </td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Utente Registrato</h6>
                    </td>
                    <td class="col-10">{{utenteRegistrato}}

                    </td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Identificativo Medico</h6>
                    </td>
                    <td class="col-10">{{idMedico}}

                    </td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Carica</h6>
                    </td>
                    <td class="col-10">{{carica}}

                    </td>
                </tr>
                <tr>
                    <td class="text-right" style="font-weight: bold;">
                        <h6 >Attività</h6>
                    </td>
                    <td class="col-10">{{attivita}}

                    </td>
                </tr>

            </tbody>
        </table>



            <hr><div class="text-right" style="margin-right: 5%;">
                <button (click)="indietro(true)" class="btn btn-primary" style="margin-left: 46%;margin-bottom: 3%;">Indietro</button>
           </div>


    </div> </div> </div> </div> </div>




