<div class="container-fluid" *ngIf="gestioneGruppi">

    <div *ngIf="gestione">
        <!-- <p-messages [value]="msgs" ></p-messages> -->
        <div class="row" >
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content">
                        <h4 class="card-title" >Gestione Gruppi</h4>
                        <p-confirmDialog [style]="{width: responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5"></p-confirmDialog>
                          <div class="ofx-auto">

                            <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                                  <thead >
                                      <tr >
                                          <th  >Nome</th>
                                          <th >Descrizione</th>
                                          <th class="disabled-sorting text-right" style="margin-right: 20%;">Modifica</th>
                                      </tr>
                                  </thead>

                                  <tbody>
                                      <tr *ngFor="let gruppo of listaGruppi;let i =index;">
                                          <td>{{gruppo.nomeGruppo}} </td>
                                          <td>{{gruppo?.descrizioneGruppo}}</td>
                                          <td class="text-right">

                                            <a (click)="edit(gruppo.id,gruppo.nomeGruppo,gruppo.descrizioneGruppo)" ><i class="material-icons notranslate pointer" rel="tooltip" title="Modifica gruppo">create</i></a>


                                              <a (click)="cancella(gruppo.id)"><i class="material-icons notranslate pointer" style="color:red" rel="tooltip" title="Elimina gruppo">delete</i></a>

                                          </td>
                                      </tr>

                                  </tbody></table>
                          </div>
                         <div class="text-right" style="margin-top: 2%;">

                                <button  (click)="creaGruppo(true)"  class="btn btn-primary" >Crea Gruppo</button>
                            </div>

                    </div>

                </div>
            </div>
            </div>

        </div>
        <div *ngIf="aggiungi"><app-aggiungi-gruppo (backGruppi)="tornaGestione($event)"> </app-aggiungi-gruppo></div>
        <div *ngIf="modifica"> <app-modifica-gruppo [gruppo]="gruppo" (vaiGestioneGruppo)="torna($event)"></app-modifica-gruppo></div>
   <!-- <div *ngIf="crea"><app-crea-utente></app-crea-utente></div> -->
    </div>
