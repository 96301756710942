<div *ngIf="!caricato">
  <div class="text-center" style="margin-top: 5%">
    <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5" styleClass="custom-spinner2"
      animationDuration=".5s"></p-progressSpinner>
  </div>
</div>

<div class="card shadow" *ngIf="caricato">
    <div class="card-header" style="margin-bottom: 2%;">
        <h3 class="card-title" style="margin-bottom: 2%;" >{{assistito.nome+" "+assistito.cognome}}</h3>
        <h5>Lista equipe: </h5>
      <table mat-table [dataSource]="listaGruppi">
        <ng-container matColumnDef="nome_equipe">
          <th class="centered w-7" mat-header-cell *matHeaderCellDef>
            <div class="flex fs10">
              Nome equipe
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="w-7 centered">
            {{element.nomeEquipe}}
          </td>
  
        </ng-container>
  
        <ng-container matColumnDef="visualizza">
          <th class="  w-7 centered" mat-header-cell *matHeaderCellDef>
            <div class="flex fs10 centered" >
              Visualizza
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class=" w-7 center-checkbox">
            <p-checkbox [binary]="true" [(ngModel)]="element.read"
              (onChange)="element.write=false">
            </p-checkbox>
          </td>
  
        </ng-container>
        <ng-container matColumnDef="scrittura">
          <th class=" w-7 centered" mat-header-cell *matHeaderCellDef>
            <div class="flex centered" >
              Scrittura
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="w-7 center-checkbox">
            <p-checkbox [binary]="true" [(ngModel)]="element.write" 
              (onChange)="element.read=true">
            </p-checkbox>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  
      <div *ngIf="listaGruppi.length === 0">Non è possibile impostare i ruoli</div>
      <div class="flex">
        <button class="btn btn-secondary" (click)="tornaGesAssistiti(true)">Indietro</button>
        <button class="btn btn-primary ml-auto" [disabled]="loading" (click)="sendList()">Conferma<span *ngIf="loading"
          ><p-progressSpinner
            [style]="{ width: '10px', height: '10px' }"
            strokeWidth="8"
            styleClass="custom-spinner"
            animationDuration=".5s"
          ></p-progressSpinner
        ></span></button>
      </div>
    </div>
    </div>