<!-- <div class="container-fluid">
    <div class="content">
        <div class="row">
            <div class="col-md-12 ">
                <div class="card">
                    <div class="card-content">
                     <h4 class="card-title">Dispositivi IoT</h4> -->
                        <app-embed-control></app-embed-control>
                    <!-- </div>
                </div>
            </div>
        </div>
    </div>
</div> -->