import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { UsersService, AccountService, AlertService } from 'src/app/SERVICES';
import { FileService } from 'src/app/SERVICES/FILE/file.service';
import { ExportExcelService } from 'src/app/SERVICES/FILE/excel.service';
import { Cliente } from 'src/app/MODELS/USER/cliente';
import { ClienteService } from 'src/app/SERVICES/CLIENTE/cliente.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-report-eracoop',
  templateUrl: './report-eracoop.component.html',
  styleUrls: ['./report-eracoop.component.scss'],
})
export class ReportEracoopComponent implements OnInit {
  isAllSelected = false;
  hoveredDate: Date | null = null;
  tutti;
  fromDate;

  newDate: Date;

  toDate;

  dayDate: Date;

  cols: any[];

  assistiti: Cliente[];

  allAssistiti: Cliente[];

  searchform: FormGroup;

  selectedAssistiti: Cliente[] = [];

  selezionaAssistito = true;

  assistitiSelezionati: Cliente[];

  idAssistitiSelezionati: String[];
  today = new Date();
  oggi;
  dd = String(this.today.getDate()).padStart(2, '0');
  mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
  yyyy = this.today.getFullYear();
  todayDay = this.dd;
  todayMonth = this.mm;
  todayYear = this.yyyy;
  //this.oggi = this.mm + '/' + dd + '/' + yyyy;
  //dataOk : boolean = false;
  calendar: FormGroup;
  toDay;
  toMonth;
  toYear;
  fromDay;
  fromMonth;
  fromYear;
  dataOK: boolean = true;
  value1: Date;
  value2: Date;
  dates: Date[];
  rangeDates: Date[];
  date1: string;
  report: boolean = false;
  loading = false;
  loading2 = false;
  ita;
  valoreRicerca;
  subscriptionListaClienti: Subscription;
  assistitiData;
  listaDiCampi: string[] = [
    'Nome',
    'Codice Fiscale',
    'Data',
    'Ora',
    'Operatore',
    'Azioni',
    'Campi modificati',
    'Età',
    'Sesso',
    'Città',
    'Occupazione',
    'Livello di istruzione',
    'Situazione economica',
    'Diagnosi',
    'Anno di presa in carico dal servizio',
    "L'utente attualmente è in carico presso un altro servizio?",
    'Modalità di accesso al servizio',
    'Servizio di appartenenza',
    'Tipologia prevalente di attività svolte dalla Coop era',
    'Tipologia di laboratori/interventi attivati',
    'Le attività vengono svolte con altre istituzioni/associazioni?',
    'Tali partenariati sono formalizzati da protocolli di intesa con la cooperativa era?',
    'Elementi storici,note e specifiche particolari',
    'Analisi dei bisogni',
    'Analisi delle risorse',
    'Obiettivi generali',
    'Obiettivi specifici e azioni utili per la realizzazione',
    'Metodologia specifica',
    'Obiettivi raggiunti',
    'Breve descrizione',
  ];
  logoEracoop;
  base64Data: any;
  retrieveResonse: any;
  checkboxSelected = false;

  constructor(
    private formBuilder: FormBuilder,
    private clienteService: ClienteService,
    private alertService: AlertService,
    private fileService: FileService,
    private excelService: ExportExcelService,
    private userService: UsersService
  ) {
    //  this.fromDate = calendar.getToday();
    //  this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit() {
    this.ita = {
      firstDayOfWeek: 1,
      dayNames: [
        'Domenica',
        'Lunedi',
        'Martedi',
        'Mercoledi',
        'Giovedi',
        'Venerdi',
        'Sabato',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      monthNames: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ],
      monthNamesShort: [
        'Gen',
        'Feb',
        'Mar',
        'Apr',
        'Mag',
        'Giu',
        'Lug',
        'Ago',
        'Set',
        'Ott',
        'Nov',
        'Dic',
      ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk',
    };

    this.buildForm();
    this.getListaClienti();

    this.cols = [
      { field: 'codiceFiscale', header: 'Codice Fiscale' },
      { field: 'nome', header: 'Nome' },
    ];

    this.searchform = this.formBuilder.group({
      search: ['', Validators.required],
    });

    this.userService.prendiImmagine().subscribe((res) => {
      if (res.data) {
        this.retrieveResonse = res.data;
        this.base64Data = this.retrieveResonse.picByte;
        this.logoEracoop = 'data:image/jpeg;base64,' + this.base64Data;
      }
    });
  }
  buildForm(): void {
    this.calendar = this.formBuilder.group({
      calendario: new FormControl('', Validators.required),
    });
  }

  getListaClienti(ricerca?) {
    // this.refreshView = false;

    this.subscriptionListaClienti = this.clienteService
      .getAll(null, null, ricerca)
      .subscribe((res) => {
        if (res.data.length > 0) {
          this.allAssistiti = res.data;
        } else {
          this.allAssistiti = [];
        }
        this.allAssistiti.forEach((el, i) => {
          this.allAssistiti[i].selected = false;
        });
        this.report = true;
      });
  }

  searchInClients() {
    this.valoreRicerca;
    if (this.subscriptionListaClienti) {
      this.subscriptionListaClienti.unsubscribe();
    }

    this.valoreRicerca = this.searchform.value.search;

    if (this.valoreRicerca && this.valoreRicerca.length > 0) {
      this.getListaClienti(this.valoreRicerca);
    } else {
      this.getListaClienti();
    }
  }

  avanti() {
    this.alertService.clear();
    if (this.selectedAssistiti.length > 0) {
      this.assistitiSelezionati = this.selectedAssistiti;
      this.selezionaAssistito = false;
    } else {
      this.alertService.error('Seleziona uno o più assistiti');
    }
  }

  indietro() {
    this.selezionaAssistito = true;
    this.selectedAssistiti = [];
    this.assistitiSelezionati = [];
    this.allAssistiti.forEach((el, i) => {
      this.allAssistiti[i].selected = false;
    });
  }

  selezionaData() {
    if (this.rangeDates !== undefined && this.rangeDates[0] !== null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.fromDate = yyyy + '-' + mm + '-' + dd;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] !== null) {
      var dd = String(this.rangeDates[1].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[1].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[1].getFullYear();
      this.toDate = yyyy + '-' + mm + '-' + dd;
    }
    if (this.rangeDates !== undefined && this.rangeDates[1] === null) {
      var dd = String(this.rangeDates[0].getDate()).padStart(2, '0');
      var mm = String(this.rangeDates[0].getMonth() + 1).padStart(2, '0');
      var yyyy = this.rangeDates[0].getFullYear();
      this.toDate = yyyy + '-' + mm + '-' + dd;
    }
    if (this.rangeDates === undefined) {
      this.fromDate = '1990-01-01';
      this.fromMonth = '01';
      this.fromYear = '1990';

      this.toDate =
        this.todayYear + '-' + this.todayMonth + '-' + this.todayDay;
    }
  }

  inviaDati() {
    this.loading = true;
    this.selezionaData();

    this.idAssistitiSelezionati = new Array();
    this.assistitiSelezionati.forEach((user) => {
      let id: string;
      id = user.idCliente;
      this.idAssistitiSelezionati.push(id);
    });

    this.clienteService
      .getStoricoForExcel(
        this.idAssistitiSelezionati,
        this.fromDate,
        this.toDate
      )
      .subscribe(
        (res) => {
          this.assistitiData = res.data;
          this.exportToExcel();
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  dataForExcel = [];

  exportToExcel() {
    this.dataForExcel = [];
    this.assistitiData.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row));
    });

    let reportData = {
      title: 'Report Eracoop',
      data: this.dataForExcel,
      headers: this.listaDiCampi,
    };

    this.excelService.exportExcel(reportData, null, null, this.logoEracoop);
  }

  selectAll() {
    if (this.selectedAssistiti.length === this.allAssistiti.length) {
      this.selectedAssistiti = [];
      this.allAssistiti.forEach((el) => {
        el.selected = false;
      });
      return;
    }
    this.selectedAssistiti = [];
    this.allAssistiti.forEach((el, i) => {
      el.selected = true;
      this.selectedAssistiti.push(el);
    });
  }

  allSelected() {
    setTimeout(() => {
      if (this.selectedAssistiti.length === this.allAssistiti.length) {
        this.isAllSelected = true;
        return;
      } else {
        this.isAllSelected = false;
      }
    }, 100);
  }
}
