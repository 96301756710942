import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { AlertService } from 'src/app/SERVICES';
import { ClienteService } from 'src/app/SERVICES/CLIENTE/cliente.service';

import JsonCitta from 'src/assets/json/italian_cities.json';
@Component({
  selector: 'app-crea-assistito',
  templateUrl: './crea-assistito.component.html',
  styleUrls: ['./crea-assistito.component.scss'],
})
export class CreaAssistitoComponent implements OnInit {
  @Output() tornaProfilo = new EventEmitter<boolean>();
  newForm: FormGroup;
  submitted = false;
  loading = false;
  arrCitta = JsonCitta;

  constructor(
    private formBuilder: FormBuilder,
    private clienteService: ClienteService,
    private alertService: AlertService,
    private primengConfig: PrimeNGConfig
  ) {}

  ngOnInit(): void {
    this.newForm = this.formBuilder.group({
      nome: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
        ],
      ],
      cognome: [
        '',
        [
          Validators.required,
          Validators.pattern("^[a-zA-ú-zÀ]+[a-zA-ú-zÀ ']+$"),
        ],
      ],
      codiceFiscale: [
        '',
        [
          Validators.pattern(
            '^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$'
          ),
        ],
      ],
      eta: ['', [Validators.max(99), Validators.min(0)]],
      sesso: [''],
      citta: [''],
    });

    this.primengConfig.ripple = true;
  }

  get f() {
    return this.newForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    if (this.newForm.invalid) {
      this.loading = false;
      return;
    }

    let cliente = {
      nome: this.newForm.value.nome,
      cognome: this.newForm.value.cognome,
      codiceFiscale: this.newForm.value.codiceFiscale,
      sesso: this.newForm.value.sesso,
      eta: this.newForm.value.eta,
      citta: this.newForm.value.citta,
    };

    this.clienteService.addCliente(cliente).subscribe(
      (res) => {
        this.loading = false;
        this.indietroDaCreazione();
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  clear() {
    this.newForm.reset();
  }

  indietroDaCreazione() {
    this.tornaProfilo.emit(true);
  }
}
