<!-- PRIMA PAGINA  --> <div class="container-fluid">
        <div class="card">
            <div class="card-content">
<div *ngIf="primaVisione">

                <div class="material-datatables">
                    <div *ngIf="loadingListaNews">
                        <div class="text-center">
                            <!-- SPINNER CHE GIRA -->
                            <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                                styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                        </div>
                    </div>
                    <!-- POPUP DI DIALOGO PER CANCELLARE I News Letter -->
                    <p-confirmDialog key="cancellaNews" #cd icon="pi pi-exclamation-triangle" [style]="{width:responsiveWidth() }"
                        [acceptLabel]="'Si'" [baseZIndex]="5">
                        <p-footer>
                            <button [disabled]="loading" *ngIf="cancellaNews" type="button" pButton icon="pi pi-check"
                                label="Sì" (click)="cancellaNewsLetter()">
                                <span *ngIf="loading">
                                    <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                                        styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                                </span>
                            </button>
                            <button [disabled]="loading && cancellaNews" type="button" pButton icon="pi pi-times"
                                label="No" (click)="cd.reject()"></button>
                        </p-footer>
                    </p-confirmDialog>
                    <h4>Newsletter</h4>
                    <div class="grande">
                        <!-- class="col-xs-12 col-sm-6" -->
                        <div>
                            <button *ngIf="!loadingListaNews" type="button" (click)="vaiCrea()"
                                style="border-radius: 50px;" class="btn btn-primary">Crea Nuova Campagna</button>
                        </div>
                        <div class="centraTesto">
                            <div class="divCont">
                                <span class="contatoreKnob">
                                    <p-knob [(ngModel)]="emailDaElaborare" [min]="0" [max]="20000" readonly="true"
                                        size="100" valueColor="#7acebe"></p-knob>
                                </span>
                                <p class="contatore">Messaggi programmati</p>
                            </div>
                            <div class="divCont">

                                <span class="contatoreKnob">
                                    <p-knob [(ngModel)]="counterEmail" [min]="0" [max]="20000" readonly="true"
                                        size="100"></p-knob>
                                </span>
                                <p class="contatore">Messaggi elaborati</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <!-- lista mail -->
                        <div class="material-datatables">
                            <div class="row">
                                <div class="col-sm-3 col-xs-3">
                                    <div class="dataTables_length" id="datatables_length">
                                        <div style="display: flex;">
                                        <label class="form-group">Limita Risultati
                                            <select [(ngModel)]="selectedValue" (ngModelChange)="cambiaValue()"
                                                aria-controls="datatables" class="form-control input-sm">
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                            </select>
                                            <div *ngIf="isSelectingValue" style="display: flex;align-items: end;">
                                                <div class="text-center" style="position: absolute;right: 0px;">
                                                    <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="5"
                                                        styleClass="custom-spinner2" animationDuration=".5s">
                                                    </p-progressSpinner>
                                                </div>
                                            </div>
                                        </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-9 col-xs-9">
                                    <div class="pull-right">
                                        <div id="datatables_filter" class="dataTables_filter">
                                            <form [formGroup]="searchform">

                                                <label class="form-group ">Esegui una ricerca
                                                    <div style="display: flex;">
                                                    <div class="row">
                                                        <div class="col-sm-12"><input type="text" (input)="search()"
                                                                formControlName="search" class="form-control"
                                                                placeholder="Cerca..." maxlength="30"
                                                                style="width: 40vw;">
                                                                <div *ngIf="isSearchingOn" style="display: flex;align-items: end;">
                                                                    <div class="text-center" style="position: absolute;right: 15px;">
                                                                        <p-progressSpinner [style]="{width: '20px', height: '20px'}"
                                                                            strokeWidth="5" styleClass="custom-spinner2"
                                                                            animationDuration=".5s"></p-progressSpinner>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    </div>
                                                </label>
                                            </form>
                                            <!-- <div *ngIf="searchInNews()"></div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="scrollable">
                                <div class="table-responsive">
                                    <p *ngIf="listaNewsLetter.length <1 && !loadingListaNews">Nessuna Campagna
                                        disponibile</p>
                                    <table *ngIf="listaNewsLetter.length >0 && !loadingListaNews" id="datatables"
                                        class="table table-striped table-no-bordered table-hover table-responsive"
                                        cellspacing="0" width="100%" style="width:100%">
                                        <thead>
                                            <tr>
                                                <!-- <th (click)="ordinaArray('nome')" class="cursorPointer">Nome
                                                    Campagna<i
                                                        class="material-icons notranslate bottoniListaI">unfold_more</i>
                                                </th>
                                                <th (click)="ordinaArray('data')" class="cursorPointer">Data Creazione
                                                    <i class="material-icons notranslate bottoniListaI">unfold_more</i>
                                                </th> -->
                                                <th>Nome
                                                    Campagna</th>
                                                    <th>Data Creazione</th>
                                                <th (click)="ordinaArrayNews('stato')" class="cursorPointer">Stato
                                                    <i class="material-icons notranslate bottoniListaI">unfold_more</i>
                                                </th>
                                                <th class="text-right">Azioni</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>Nome Campagna</th>
                                                <th>Data Creazione</th>
                                                <th>Stato</th>
                                                <th class="text-right">Azioni</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            <tr *ngFor="let email of listaNewsLetter;let i =index;">
                                                <ng-container>
                                                    <td class="sizeMed">{{email.nome}}</td>
                                                    <td class="sizeMed">{{email.createdAt | date:'dd/MM/yyyy'}} </td>
                                                    <td class="sizeMed">{{email.dataInoltro | statoTemporale}}</td>
                                                    <td class="text-right"><a rel="tooltip"
                                                            (click)="vaiReminderView(email)" title="Reminder"
                                                            class="btn btn-simple btn-primary btn-icon remove iconeA"><i
                                                                class="material-icons pointer iconeI">alarm_add</i></a>
                                                        <a rel="tooltip" (click)="vaiInfoNewsLetter(email) "
                                                            title="info"
                                                            class="btn btn-simple btn-warning btn-icon remove iconeA"><i
                                                                class="material-icons pointer iconeI">info</i></a>
                                                        <a *ngIf="controllomail(email)" rel="tooltip"
                                                            (click)="vaiModifica(email)" title="Modifica"
                                                            class="btn btn-simple btn-success btn-icon remove iconeA"><i
                                                                class="material-icons pointer iconeI">edit</i></a>
                                                        <a *ngIf="controllomail(email)" rel="tooltip"
                                                            (click)="confermaCancellaNewsLetter(email.id)"
                                                            title="Cancella"
                                                            class="btn btn-simple btn-danger btn-icon remove iconeA"><i
                                                                class="material-icons pointer iconeI">close</i>
                                                        </a>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div class="row" [ngClass]="{'displayNone':!primaVisione}">
                                <div class="col-sm-5">
                                    <div class="dataTables_info" id="datatables_info" role="status"
                                        aria-live="polite" >{{items}} di {{maxItems}}
                                            {{maxItems > 1 ? "Campagne" : "Campagna"}}</div>
                                    </div>
                                    <div class="col-sm-7 text-right giustificaDestra">

                                        <app-paginator *ngIf="refreshPaginatorNews" [pageSize]="numeroMaxPagine"
                                            [(isClickable)]="isClickable"
                                            (currentPageEmitter)="cambiaPagina($event)"
                                            [startingPage]="pagina+1"></app-paginator>
                                    </div>
                                    <br>
                            <br>
                                </div>


                            <!-- fine lista mail -->
                        </div>
                    </div>
                </div>



</div>
<div *ngIf="reminderView">

                <!-- SPINNER CHE GIRA -->
                <div *ngIf="loadingListaReminder">
                    <div class="text-center">
                        <p-progressSpinner [style]="{width: '40px', height: '40px'}" strokeWidth="5"
                            styleClass="custom-spinner2" animationDuration=".5s"></p-progressSpinner>
                    </div>
                </div>
                <!-- POPUP DI DIALOGO PER CANCELLARE I REMINDER -->
                <div class="material-datatables">
                    <p-confirmDialog key="cancellaReminder" #cd icon="pi pi-exclamation-triangle"
                        [style]="{width: responsiveWidth()}" [acceptLabel]="'Si'" [baseZIndex]="5">
                        <p-footer>
                            <button [disabled]="loading" *ngIf="cancellaRem" type="button" pButton icon="pi pi-check"
                                label="Sì" (click)="cancellaReminder()">
                                <span *ngIf="loading">
                                    <p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8"
                                        styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner>
                                </span>
                            </button>
                            <button [disabled]="loading && cancellaRem" type="button" pButton icon="pi pi-times"
                                label="No" (click)="cd.reject()"></button>
                        </p-footer>
                    </p-confirmDialog>
                    <div class="card-title">
                        <button (click)="goBackReminder()" id="back-button" type="button"
                            class="btn btn-round  btn-just-icon bottoneBack">
                            <i class="material-icons notranslate">arrow_back</i>
                        </button>
                        <h4 style="display: inline;">Lista Reminder</h4>
                    </div>
                    <div class="col-xs-12 text-right">
                        <button *ngIf="!loadingListaReminder" type="button" (click)="vaiCreaReminder()"
                            class="btn btn-primary">Crea Nuovo Reminder</button>
                    </div>
                    <div class="col-xs-12">
                        <div class="col-xs-12">
                            <!-- lista mail -->
                            <div class="material-datatables">
                                <div class="row">
                                    <div class="col-sm-3 col-xs-3">
                                        <div class="dataTables_length" id="datatables_length">
                                            <div style="display: flex;">
                                            <label class="form-group">Limita Risultati
                                                <select [(ngModel)]="selectedValueReminder"
                                                    (ngModelChange)="cambiaValueReminder()" aria-controls="datatables"
                                                    class="form-control input-sm">
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                </select>
                                                <div *ngIf="isSelectingValueReminder" style="display: flex;align-items: end;">
                                                    <div class="text-center" style="position: absolute;right: 0px;">
                                                        <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="5"
                                                            styleClass="custom-spinner2" animationDuration=".5s">
                                                        </p-progressSpinner>
                                                    </div>
                                                </div>
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-9 col-xs-9">
                                        <div class="pull-right">
                                            <div id="datatables_filter" class="dataTables_filter">
                                                <form [formGroup]="searchformReminder">
                                                    <label class="form-group ">Esegui una ricerca
                                                        <div class="row">
                                                            <div class="col-sm-10">
                                                                <div style="display: flex;">
                                                                    <input (input)="searchReminder()" type="text"
                                                                        formControlName="search" class="form-control"
                                                                        placeholder="Cerca..." maxlength="30"
                                                                        style="width: 40vw;">
                                                                    <div *ngIf="isSearchingOn"
                                                                        style="display: flex;align-items: end;">
                                                                        <div class="text-center"
                                                                            style="position: absolute;right: 0px;">
                                                                            <p-progressSpinner
                                                                                [style]="{width: '20px', height: '20px'}"
                                                                                strokeWidth="5"
                                                                                styleClass="custom-spinner2"
                                                                                animationDuration=".5s">
                                                                            </p-progressSpinner>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </form>
                                                <!-- <div *ngIf="searchInReminder()"></div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="scrollable">
                                    <div class="table-responsive">
                                        <p *ngIf="listaReminder.length <1 && !loadingListaReminder">Nessun Reminder
                                            disponibile</p>
                                        <table *ngIf="listaReminder.length >0 && !loadingListaReminder" id="datatables"
                                            class="table table-striped table-no-bordered table-hover table-responsive"
                                            cellspacing="0" width="100%" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <!-- <th (click)="ordinaArray('nome')" style="cursor: pointer;">Nome
                                                        Reminder<i
                                                            class="material-icons notranslate bottoniListaI">unfold_more</i>
                                                    </th> -->
<th>Nome Reminder</th>
                                                    <!-- <th (click)="ordinaArray('data')" style="cursor: pointer;">Data
                                                        Creazione
                                                        <i
                                                            class="material-icons notranslate bottoniListaI">unfold_more</i> -->
                                                   <th>Data Creazione
                                                        </th>
                                                    <th (click)="ordinaArrayRem('stato')" style="cursor: pointer;">
                                                        Stato
                                                        <i
                                                            class="material-icons notranslate bottoniListaI">unfold_more</i>
                                                    </th>
                                                    <th class="text-right">Azioni</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>Nome Reminder</th>
                                                    <th>Data Creazione</th>
                                                    <th>Stato</th>
                                                    <th class="text-right">Azioni</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr *ngFor="let reminder of listaReminder;let i =index;">
                                                    <ng-container>
                                                        <td class="sizeMed">{{reminder.nome}}</td>
                                                        <td class="sizeMed">{{reminder.createdAt |
                                                            date:'dd/MM/yyyy'}} </td>
                                                        <td class="sizeMed">{{reminder.dataInoltro | statoTemporale}}</td>
                                                        <td class="text-right">
                                                            <a rel="tooltip" (click)="vaiModificaReminder(reminder)"
                                                                title="Modifica"
                                                                class="btn btn-simple btn-success btn-icon remove iconeA"
                                                                *ngIf="controllomail(reminder)"><i
                                                                    class="material-icons pointer iconeI">edit</i></a>
                                                            <a rel="tooltip" (click)="vaiInfoReminder(reminder) "
                                                                title="info"
                                                                class="btn btn-simple btn-warning btn-icon remove iconeA"><i
                                                                    class="material-icons pointer iconeI">info</i></a>
                                                            <a rel="tooltip"
                                                                (click)="confermaCancellaReminder(reminder.id)"
                                                                title="Cancella"
                                                                class="btn btn-simple btn-danger btn-icon remove iconeA"
                                                                *ngIf="controllomail(reminder)"><i
                                                                    class="material-icons pointer iconeI">close</i></a>
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

<div class="row" [ngClass]="{'displayNone':!reminderView}">
    <div class="col-sm-5">
        <div class="dataTables_info" id="datatables_info" role="status"
            aria-live="polite" style="margin-top: 5%;height: 0px;">{{itemsReminder}} di {{maxItemsReminder}}
            Reminder</div>
    </div>
    <div class="col-sm-7 text-right giustificaDestra">

        <app-paginator *ngIf="refreshPaginatorReminder" [pageSize]="numeroMaxPagineReminder"
            [(isClickable)]="isClickableReminder"
            (currentPageEmitter)="cambiaPaginaReminder($event)"
            [startingPage]="paginaReminder+1"></app-paginator>
    </div>
    <br>
        <br>
</div>

</div>


<!-- PRIMA PAGINA  FINE-->
<!-- CREAZIONE DELLA NEWS LETTER/ REMINDER -->
<div *ngIf="creazione">
    <app-add-edit-news-letter [whatToDoObject]="whatToDoObject" (backLista)="backLista($event)">
    </app-add-edit-news-letter>
</div>
<div *ngIf="infoNews">

                <div class="card-title">
                    <button *ngIf="infoReminder" (click)="vaiReminderView()" id="back-button" type="button"
                        class="btn btn-round  btn-just-icon bottoneBack">
                        <i class="material-icons notranslate">arrow_back</i>
                    </button>
                    <button *ngIf="!infoReminder" (click)="goBackReminder()" id="back-button" type="button"
                        class="btn btn-round  btn-just-icon bottoneBack">
                        <i class="material-icons notranslate">arrow_back</i>
                    </button>
                    <h4 style="display: inline;">Informazioni mail: {{info.nome}}</h4>
                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th style="width: 20%;"></th>
                            <th style="width: 80%;"></th>
                        </tr>
                    </thead>
                    <tbody class="animation3">
                        <tr>
                            <td class="text-right " style="max-width: 30%;">
                                <h6>nome</h6>
                            </td>
                            <td>{{info.nome}}</td>
                        </tr>
                        <tr>
                            <td class="text-right fontBold">
                                <h6>Data creazione:</h6>
                            </td>
                            <td class="col-10">{{info.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                        </tr>
                        <tr>
                            <td class="text-right fontBold">
                                <h6>Data invio</h6>
                            </td>
                            <td class="col-10">
                                {{info.dataInoltro | date: 'dd/MM/yyyy HH:mm'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p-accordion>
                    <p-accordionTab header="Lista Utenti">
                        <div class="content">
                            <!-- <table class="table table-striped">
                                <tbody class="animation3">
                                    <div class="table-responsive"> -->
                                        <!-- <p *ngIf="listaUtenti.length <1">Nessun Utente disponibile</p> -->
                                        <div style="overflow: auto;">

                                        <!-- <table id="datatables"
                                            class="table table-striped table-no-bordered table-hover table-responsive"
                                            cellspacing="0"> -->
                                            <table id="datatables" class="table table-striped table-no-bordered table-hover table-fixed"
                                            cellspacing="0" width="100%" style="width:100%">
                                            <thead>
                                                <th>Nome</th>
                                                <th>Email</th>
                                                <th>Gruppo</th>
                                            </thead>

                                            <tbody>
                                                <tr *ngFor="let user of  info.listaUtenti;let i =index;">
                                                    <ng-container>
                                                        <td class="sizeMed">{{user.nome + " " + user.cognome}}
                                                        </td>
                                                        <td class="sizeMed">{{user.email}}</td>
                                                        <td class="sizeMed">
                                                            <span *ngFor="let gruppo of user.gruppos; let j= index">
                                                                <span
                                                                    *ngIf="j <= user.gruppos.length-2 && j < 3">{{gruppo?.nomeGruppo
                                                                    + ', '}}</span>
                                                                <span
                                                                    *ngIf=" j === user.gruppos.length-1 && j < 3">{{gruppo?.nomeGruppo}}</span>
                                                                <span *ngIf=" j === 3">...</span>
                                                            </span>
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>

                                    </div>
                                <!-- </tbody>
                            </table> -->
                        <!-- </div> -->
                    </p-accordionTab>
                </p-accordion>
                <p-accordion>
                    <p-accordionTab header="Preview Email">
                        <div [innerHTML]="info.html"></div>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
</div>
