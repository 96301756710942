import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LavagnaService } from 'src/app/SERVICES/CLASSROOM/lavagna.service';
import { formatDate } from '@angular/common';
import { ClassroomService } from 'src/app/SERVICES/CLASSROOM/classroom.service';
// Konva
import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';
import { Layer } from 'konva/lib/Layer';
import { Shape } from 'konva/lib/Shape';
//animazione
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
//gestionePacchetti
import { LavagnaStorico } from 'src/app/MODELS/CLASSROOM/lavagna';
import { AccountService } from 'src/app/SERVICES';

@Component({
  selector: 'app-lavagna',
  templateUrl: './lavagna.component.html',
  styleUrls: ['./lavagna.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(500, style({ opacity: 0 }))]),
    ]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in')),
    ]),
  ],
})
export class LavagnaComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  // Variabili ottimizzazione lavagna
  bodyDaInviare;
  interval;
  subscriberBg;
  oldBody;
  /**
   * in ms, la frequenza di chiamate al BE
   */
  delayTime: number = 100;

  //#region letiabili di istanza
  showMenu = false;
  state = 'default';
  @Input() ruoloUser;
  @ViewChild('lavagnaMenu') lavagnaMenu;
  @Output() enableImagesEmitter = new EventEmitter<any>();
  /**
   * Appena il formData è pronto lo diciamo al padre, che chiuderà la finestra
   * tramite event emitter
   */
  @Output() formDataDone: EventEmitter<FormData> = new EventEmitter<FormData>();
  //#region letiabili
  //#region letiabili per logica sub
  /**
   *se un utente viene concesso la possibilità di modificare immagine diventa vera
   */
  enableDraw: boolean = false;
  /**
   * immagine della tela
   */
  backgroundWhiteboard: string = '';
  /**
   * Sub che indica chi può modificare
   */
  subscribeEditor: Subscription = null;
  /**
   * Sub che indica la progressione della tela
   */
  subscribeUpdate: Subscription = null;
  /**
   * Sub che indica una richiesta di salvataggio del dato
   */
  subscribeFormData: Subscription = null;
  //#endregion

  //#region let Logica sito
  /**
   * idAula da quale classe proviene aula
   */
  idAula: number = null;
  /**
   * Timer per cercare di connettere la socket
   */
  timerConnection: NodeJS.Timeout;

  /**
   * Immagine della tela proveniente dalla cache
   */
  immagineIniziale: string = '';
  /**
   * width dell'immagine
   */
  width: number = 0;
  /**
   * height dell'immagine
   */
  height: number = 0;
  //#endregion

  //#region Konva let
  /**
   * Oggetto del framework Konva:
   *
   * Costituisce la tela
   */
  stage: Stage;
  /**
   * Oggetto del framework Konva:
   *
   * Lo strato di ogni oggetto
   */
  layer: Layer;

  /**
   * Array che tiene traccia dei disegni attuali su tela
   */
  disegniSuTela: Shape[] = [];
  /**
   * Array che tiene traccia dei disegni conservati a causa dell'azione indietro
   */
  disegniInPending: Shape[] = [];
  /**
   * Rettangolo di selezione degli oggetti
   */
  areaSelezione: Shape;
  /**
   * Oggetti Konva, non tipizzato, che permette la trasformazione degli oggetti:
   *
   * Cioè ridimensionamento, rotazione etc...
   */
  trasformer: any;
  /**
   * idDisegni, tiene traccia di tutti i disegni creati,
   * sia quelli su tela che in Attesa, a causa di "indietro",
   * in questo modo ogni nuova figura ha un id Unico utile
   * per distinguere l'oggetto
   */
  idDisegni: number = 0;
  /**
   * ultima azione eseguita dall'utente, ex: draw undo etc...
   */
  ultimaAzione = '';
  /**
   * Spostamento del cursore
   */
  updateCursor: DatiTrasformazione;

  /**
   * Se la forma attuale è una linea
   */
  linea = false;

  /**
   * Se l'utente sta continuando a tenere premuto il dito sul mouse per disegnare
   */
  mantieniLinea = false;
  /**
   * Se l'utente sta cancellando o disegnando
   */
  modeLinea = 'penna';
  /**
   * Abilita la paletta delle immagini da trascinare nella tela
   */
  mostraImmagini = false;

  /**
   * Se vera l'utente vuole selezionare gli oggetti
   */
  seleziona = false;

  /**
   * Abbiamo i seguenti valori:
   * penna
   * gomma
   * seleziona
   */
  strumentoSelezionato = '';

  /**
   * Oggetto javacript che contiene:
   *
   * Path di assents
   *
   * Id dell'oggetto KonvaCorrispondente
   */
  immagini = [];

  /**
   * Colore delle forme e del testo, accetta:
   *
   * 'color' Ex: 'black'
   * '#esadecimale' Ex: '#0000ff'
   * 'rgb(,,,)' EX: 'rgb(0,0,255)'
   */
  colorShape_Stroke: string = '#000000';

  /**
   * Grandezza del testo
   */
  sizeText_FontSize = 20;

  /**
   * Grandezza del disegno
   */
  sizeShape_StrokeWidth = 4;

  /**
   * Figure selezionate dall'area di selezione
   */
  figureSelezionate: Shape[];

  /**
   * Oggetto fornito da html che corrisponde al valore src assoluto dell'immagine
   */
  itemUrl: any = undefined;

  /**
   * Percorso URL del sito
   */
  pathAbsolute: string = '';

  /**
   * Mappa tra percorso e id, conviene per una questione di
   * semplicità di richiamo
   */
  mappaImmagineToId: Map<string, string> = new Map<string, string>();

  /**
    * Una chiamata al BE che contiene le seguenti informazioni.
    *
    * La letiabile contiene il singolo oggetto inerente a dove ha aperto
    * la lavagna
    * "data": [
        {

            "id": number,
            "nomeAula": string,
            "dettagli": null,
            "creatore": number,
            "descrizioneAula": string,
            "start": "timestamp",
            "end": "timestamp",
            "suspended": number,
            "createdAt": "2021-06-29 14:40:33"
        },
        {...},

      ]
    */
  aulaInfo: any;

  //#endregion

  //#region let responsive
  /**
   * per il padMode
   */
  check = false;

  /**
   * Per il resize, in modo che il mouse disegna giusto sulla tela
   */
  scale: number = 1;
  //#endregion

  //#region let download
  /**
   * file che contiene l'ultimo salvataggio
   */
  fileState: File;

  /**
   * per una possibile funzionalità di download
   * da parte dello studente
   */
  fileString: string;
  //#endregion

  //#region let gestionePacchetti
  /**
   * tutti i pacchetti arrivati dalla socket e non ancora elaborati
   */
  storicoJson: LavagnaStorico[] = [];

  /**
   * numero pacchetto lato docente per invio
   */
  numeroPacchettoDaInviare: number = 0;

  /**
   * Se per 2 volte ci sono elementi di pacchetti con indice di pacchetto + piccolo
   * pulisco lo storico e aspetto nuovi pacchetti
   */
  nFailed: number = 0;

  /**
   * il check serve per controllare che il pacchetto arrivato sia il successivo
   * si incrementa ad ogni ricezione
   */
  checkControlloPacchetto = 0;

  /**
   * Permette di indicizzare il primo valore del pacchetto ricevuto
   */
  isPrimoPaccheto = true;

  /**
   * vero non invia messaggi al server.
   *
   * Serve per diminuire il numero di chiamate al BE
   */
  delay = false;
  ruolo;
  //#endregion

  //#endregion

  //#region INIT Lettura dalla cache, creazione paletta immagini, connessione socket

  /**
   * Inizia la connessione, crea la paletta colori
   * @param lavagnaService per chiamate al BE e accedere alle info account
   */
  constructor(
    private lavagnaService: LavagnaService,
    private classroomService: ClassroomService,
    private accountService: AccountService
  ) {
    this.ruolo = this.accountService.ruolo;
    this.updateCursor = new DatiTrasformazione();
    //iniziamo a connetterci alla socket
    this.startConnectionSocket();
    this.creaPalettaImmagini();
    this.classroomService.lista_classroom_leggera().subscribe((res) => {
      let aule = res.data as [any];
      for (let i = 0; i < aule.length; i++) {
        let idAula = aule[i].id as number;
        if (this.idAula == idAula) {
          this.aulaInfo = aule[i];
          break;
        }
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {}

  /**
   * Carico le immagini della paletta
   */
  creaPalettaImmagini() {
    this.pathAbsolute = document.location.origin;
    const pathRelative = '/assets/img/lavagna/';
    let pathImage = '';
    let id = '';
    for (let i = 0; i < this.lavagnaService.nImmagini(); i++) {
      let img = new Image();
      pathImage = this.pathAbsolute + pathRelative + i + '.png';
      id = '' + i + '.png';
      img.src = pathImage;
      this.immagini.push({
        src: pathImage,
        id: id,
      });
      this.mappaImmagineToId.set(pathImage, '' + id);
    }
  }

  /**
   * Richiesta al server per allineamento con la tela del docente
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.lavagnaService.isDocente()) {
        //se sono uno studente appena entrato chiedo
        //allineamento con il docente
        this.lavagnaService
          .reAlign(this.idAula, this.checkControlloPacchetto)
          .subscribe((res) => {});
      }
    }, 8000);
  }

  /**
   * Creazione:
   *
   * Attraverso i valori della cache,
   * popolo la Tela.Poi inizializzo Konva
   *
   * Subscribe a:
   *
   * update
   *
   * idEditor
   *
   * backgroundImage
   */
  ngOnInit(): void {
    //#region Creazione
    this.readCacheValue();

    this.konvaInit();
    //#endregion

    //#region Subscribe

    //#region Subupdate

    //il padre comunica al figlio la richiesta di salvataggio tramite un Behavior
    //al quale bisogna fare la subscribe
    this.subscribeFormData = this.lavagnaService.formDataRequest$.subscribe(
      (request: boolean) => {
        if (request != null && request) {
          this.salvaImmagine();
        }
      }
    );

    //attivo la subscribe per aggiornamento dei disegni this.lavagnaService.updateCanvas$
    this.interval = setInterval(()=>{

      if (this.subscriberBg) {
        this.subscriberBg.unsubscribe();
      }
      if (this.oldBody !== this.bodyDaInviare) {
        this.oldBody = this.bodyDaInviare;
        this.subscriberBg = this.lavagnaService
          .updateBackgroundCanvas(this.bodyDaInviare, this.idAula)
          .subscribe((res) => {});
      }},5000)
      this.subscribeUpdate = this.lavagnaService.updateCanvas$.subscribe((updateCanvas: string) => {
        try {
          //Messaggio per intero
          //
          //0: azione, 1: mittende, 2: body
          /*
              Azione:
              create+stringColorShape+sizeShapeNumber+fontSizeNumber+itemUrl###mittende###oggetto+mode?###idDisegni mode solo se oggetto = linea
              undo,redo,clear###mittende###suTela.length+inPend.length
              update###mittende###stageObject
              align:
              Studente-> align###mittende###request
              Docente-> align###mittende###destinatario###Konva.Stage.toJson###ArrayPending
           */
          if (updateCanvas && updateCanvas != "END") {
            updateCanvas=this.gestionePacchetti(updateCanvas)
            if(updateCanvas==null){
              //se è null il pacchetto arrivato non va analizzato
              return
            }
            const split: string[] = updateCanvas.split("###")
            const attrs = split[0].split("+")
            const azione: string = attrs[0]
            const idMittente: number = Number(split[1])
            //visto che i valori arrivano possiamo fermare il tentativo di connessione
            clearTimeout(this.timerConnection)
            this.lavagnaService.socketProblem = false

            //Salvataggio dello stato lavagna
            //per inviarlo al Server
            //this.saveState()
            //se informazione ricevuta è mia non andare avanti
            if (this.lavagnaService.mioId() === idMittente) {
              return
            }
            //ci sono degli aggiornamenti
            if (!this.isEnableDraw()) {
              //caso studente
              //Aggiorniamo lo stage
              let idDisegni = Number(split[3]) || -1
              switch (azione) {
                case "create":
                  // create+stringColorShape+sizeShapeNumber+fontSizeNumber+itemUrl
                  this.colorShape_Stroke = attrs[1]
                  this.sizeShape_StrokeWidth = Number(attrs[2])
                  this.sizeText_FontSize = Number(attrs[3])
                  this.itemUrl = attrs[4] || null
                case "undo":
                case "redo":
                case "clear":
                case "update":
                  const body = split[2]
                  // let json = JSON.parse(body);
                  this.loadJson(body, azione, idDisegni)
                  break;
                case "align":
                  //Docente-> align###mittende###destinatario###Konva.Stage.toJson###ArrayPending###idDisegni
                  //Studente-> align###mittende###request
                  //se sono uno studente devo controllare
                  //di essere io il destinatario
                  //vediamo quanti valori di split ci sono
                  //se sono 3 è uno studente che ha inviato info
                  //se sono 5 è il docente
                  if (split.length >= 4) {
                    //una volta verificato che sia il docente
                    //vediamo se siamo noi i destinatari
                    const idDestinario: number = Number(split[2])
                    if (this.lavagnaService.mioId() === idDestinario) {
                      //se sono io il destinatario aggiorniamo le informazioni
                      const jsonStage = split[3]
                      const jsonArrayPending = split[4]
                      const jsonIdDisegni: number = Number(split[5])
                      this.alignWhiteboard(jsonStage, jsonArrayPending, jsonIdDisegni)
                    }
                  }
                  break;
                default:
                  throw new Error("CASO IMPOSSIBILE")
              }

            } else {
              //caso docente
              //l'unica cosa che interessa al docente è una richiesta di allineamento
              if (azione === "align") {
                //Docente-> align###mittende###destinatario###Konva.Stage.toJson###ArrayPending
                const jsonStage = this.stage.toJSON();
                const jsonPending = JSON.stringify(this.disegniInPending)
                const idDest = idMittente
                this.numeroPacchettoDaInviare++
                this.lavagnaService.reAlign(this.idAula,this.numeroPacchettoDaInviare,
                  idDest + "###" +
                  jsonStage + "###" +
                  jsonPending + "###" +
                  this.idDisegni)
                  .subscribe(res => {
                  }
                  )
              }
            }
          } else {
            //solo studente può riceverla
            //Chiudi scheda o cambia immagine tela
            //se il docente ha chiuso la lavanga
            if (updateCanvas === "END") {
              //AVVISO che lavagna è chiusa
              //torno all'immagine default
              this.immagineIniziale = "/assets/img/lavagna.png"
              //abilito enable
              this.enableDraw = true
              //blocco la socket
              this.closeSub()
              //ristarto konva
              this.konvaInit()
              //rimuovo l'elemento della cache
              //in questo modo si apre la lavagna con la socket
              //mentre questa rimane in disparte
              localStorage.removeItem("enableWhiteboard")
              this.lavagnaService.changeBackgroundImageWhiteboard(null)
            }
          }
        } catch (e) {
          // if (!this.isEnableDraw()) {
          //   this.lavagnaService.reAlign(this.idAula,this.checkControlloPacchetto).subscribe(
          //     res => {
          //     }
          //   )
          // }
        }
      })

    // setInterval(()=>{
    //   if (this.subscribeUpdate) {this.subscribeUpdate.unsubscribe()}
    //   this.subscribeUpdate = this.lavagnaService.getBackgroundCanvas(this.idAula).subscribe(
    //     (res: any) => {
    //       let updateCanvas = res.data.datiLavagna
    //       try {
    //         //Messaggio per intero
    //         //
    //         //0: azione, 1: mittende, 2: body
    //         /*
    //           Azione:
    //           create+stringColorShape+sizeShapeNumber+fontSizeNumber+itemUrl###mittende###oggetto+mode?###idDisegni mode solo se oggetto = linea
    //           undo,redo,clear###mittende###suTela.length+inPend.length
    //           update###mittende###stageObject
    //           align:
    //           Studente-> align###mittende###request
    //           Docente-> align###mittende###destinatario###Konva.Stage.toJson###ArrayPending
    //        */
    //         if (updateCanvas && updateCanvas != 'END') {
    //           updateCanvas = this.gestionePacchetti(updateCanvas);
    //           if (updateCanvas == null) {
    //             //se è null il pacchetto arrivato non va analizzato
    //             return;
    //           }
    //           const split: string[] = updateCanvas.split('###');
    //           const attrs = split[0].split('+');
    //           const azione: string = attrs[0];
    //           const idMittente: number = Number(split[1]);
    //           //visto che i valori arrivano possiamo fermare il tentativo di connessione
    //           clearTimeout(this.timerConnection);
    //           this.lavagnaService.socketProblem = false;

    //           //Salvataggio dello stato lavagna
    //           //per inviarlo al Server
    //           //this.saveState()
    //           //se informazione ricevuta è mia non andare avanti
    //           if (this.lavagnaService.mioId() === idMittente) {
    //             return;
    //           }
    //           //ci sono degli aggiornamenti
    //           if (!this.isEnableDraw()) {
    //             //caso studente
    //             //Aggiorniamo lo stage
    //             let idDisegni = Number(split[3]) || -1;
    //             switch (azione) {
    //               case 'create':
    //                 // create+stringColorShape+sizeShapeNumber+fontSizeNumber+itemUrl
    //                 this.colorShape_Stroke = attrs[1];
    //                 this.sizeShape_StrokeWidth = Number(attrs[2]);
    //                 this.sizeText_FontSize = Number(attrs[3]);
    //                 this.itemUrl = attrs[4] || null;
    //               case 'undo':
    //               case 'redo':
    //               case 'clear':
    //               case 'update':
    //                 const body = split[2];
    //                 this.loadJson(body, azione, idDisegni);
    //                 break;
    //               case 'align':
    //                 //Docente-> align###mittende###destinatario###Konva.Stage.toJson###ArrayPending###idDisegni
    //                 //Studente-> align###mittende###request
    //                 //se sono uno studente devo controllare
    //                 //di essere io il destinatario
    //                 //vediamo quanti valori di split ci sono
    //                 //se sono 3 è uno studente che ha inviato info
    //                 //se sono 5 è il docente
    //                 if (split.length >= 4) {
    //                   //una volta verificato che sia il docente
    //                   //vediamo se siamo noi i destinatari
    //                   const idDestinario: number = Number(split[2]);
    //                   if (this.lavagnaService.mioId() === idDestinario) {
    //                     //se sono io il destinatario aggiorniamo le informazioni
    //                     const jsonStage = split[3];
    //                     const jsonArrayPending = split[4];
    //                     const jsonIdDisegni: number = Number(split[5]);
    //                     this.alignWhiteboard(
    //                       jsonStage,
    //                       jsonArrayPending,
    //                       jsonIdDisegni
    //                     );
    //                   }
    //                 }
    //                 break;
    //               default:
    //                 throw new Error('CASO IMPOSSIBILE');
    //             }
    //           } else {
    //             //caso docente
    //             //l'unica cosa che interessa al docente è una richiesta di allineamento
    //             if (azione === 'align') {
    //               //Docente-> align###mittende###destinatario###Konva.Stage.toJson###ArrayPending
    //               const jsonStage = this.stage.toJSON();
    //               const jsonPending = JSON.stringify(this.disegniInPending);
    //               const idDest = idMittente;
    //               this.numeroPacchettoDaInviare++;
    //               this.lavagnaService
    //                 .reAlign(
    //                   this.idAula,
    //                   this.numeroPacchettoDaInviare,
    //                   idDest +
    //                     '###' +
    //                     jsonStage +
    //                     '###' +
    //                     jsonPending +
    //                     '###' +
    //                     this.idDisegni
    //                 )
    //                 .subscribe((res) => {});
    //             }
    //           }
    //         } else {
    //           //solo studente può riceverla
    //           //Chiudi scheda o cambia immagine tela
    //           //se il docente ha chiuso la lavanga
    //           if (updateCanvas === 'END') {
    //             //AVVISO che lavagna è chiusa
    //             //torno all'immagine default
    //             this.immagineIniziale = '/assets/img/lavagna.png';
    //             //abilito enable
    //             this.enableDraw = true;
    //             //blocco la socket
    //             this.closeSub();
    //             //ristarto konva
    //             this.konvaInit();
    //             //rimuovo l'elemento della cache
    //             //in questo modo si apre la lavagna con la socket
    //             //mentre questa rimane in disparte
    //             localStorage.removeItem('enableWhiteboard');
    //             this.lavagnaService.changeBackgroundImageWhiteboard(null);
    //           }
    //         }
    //       } catch (e) {
    //         if (!this.isEnableDraw()) {
    //           this.lavagnaService
    //             .reAlign(this.idAula, this.checkControlloPacchetto)
    //             .subscribe((res) => {});
    //         }
    //       }
    //     }
    //   );
    // },5000)
    //#endregion

    //#region SubEditor
    //Per permettere agli studenti di scrivere codice
    this.subscribeEditor = this.lavagnaService.idEditor$.subscribe(
      (idEditor: number) => {
        if (!this.lavagnaService.isDocente() && idEditor) {
          //se non è un docente e il mio id non è null
          //allora verifichiamo se è uguale al mio
          if (idEditor === -1) {
            this.enableDraw = false;
          }
          if (this.lavagnaService.mioId() === idEditor) {
            //se corrisponde
            //Allora utente è abilitato/disabilitato a modificare immagine
            this.enableDraw = !this.enableDraw;
          }
        }
      }
    );
    //#endregion

    //#endregion
  }



  /**
   * leggo i valori della cache, nello specifico idAula e immagine della tela
   */
  readCacheValue() {
    //scompattiamo dal localstorage idAula e image salvata prima.
    let idAula = (JSON.parse(localStorage.getItem('idAula')) as number) || null;
    if (idAula) {
      this.idAula = idAula;
    }
    let imageUrl =
      (localStorage.getItem('backgroundWhiteboard') as string) || null;
    if (imageUrl) {
      //assegnamola alla tela
      this.backgroundWhiteboard = imageUrl;
      this.immagineIniziale = imageUrl;
    } else {
      //se non trova niente al fine di non fa vedere l'immagine vuota
      //anche per test, aggiungo come immagine un'altra tela
      this.immagineIniziale = '/assets/img/lavagna.png';
    }
  }

  //#region Konva Configuration
  /**
   * Configurazione delle dimenzioni della tela.
   *
   * Degli oggetti Stage, Layer e Trasformer:
   *
   * Stage: La tela vera è propria
   *
   * Layer: Gli altri disegni
   *
   * Trasformer:
   *
   * Oggetti per la trasformazione degli oggetti (dimensionamento, rotazione etc...).
   *
   * Sopratutto dell'area di selezione e quella di drop
   * @see {@link creaDrop(creaDrop)}
   * @see {@link creaAreaSelezione(creaAreaSelezione)}
   */
  konvaInit() {
    let thisClass = this;
    let img = new Image();

    //resize start
    let fitStageIntoParentContainer = function () {
      let screenWidth = window.innerWidth;
      let screenHeight = window.innerHeight;
      if (screenWidth < img.width) {
        let container = document.getElementById('parentElement');
        //TMS 21-09-2021 Fix container null
        if (container != undefined || container != null) {
          // container.style.width=screenWidth +"px"
          let scale = container.offsetWidth / img.width;
          thisClass.scale = scale;
          thisClass.stage.width(img.width * scale);
          thisClass.stage.height(img.height * scale);
          thisClass.stage.scale({ x: scale, y: scale });
        }
      } else {
        let container = document.getElementById('parentElement');
        if (container != undefined || container != null) {
          //TMS 21-09-2021 Fix container null
          // container.style.width=img.width +"px";
          let scale = container.offsetWidth / img.width;
          thisClass.scale = scale;
          thisClass.stage.width(img.width * scale);
          thisClass.stage.height(img.height * scale);
          thisClass.stage.scale({ x: scale, y: scale });
        }
      }
    };
    //resize end
    img.onload = function () {
      //creo lo stage
      thisClass.stage = new Konva.Stage({
        container: 'lavagna', // id of container <div>
        width: img.width,
        height: img.height,
      });
      //resize start
      window.addEventListener('resize', fitStageIntoParentContainer);
      //resize end
      thisClass.width = img.width;
      thisClass.height = thisClass.height;
      //e lo strato sopra
      thisClass.layer = new Konva.Layer();
      thisClass.areaSelezione = new Konva.Rect({
        stroke: '#43aef2',
        strokeWidth: 1,
        visible: false,
      });
      thisClass.layer.add(thisClass.areaSelezione);
      //e la funzionalità trasformer
      thisClass.trasformer = new Konva.Transformer();
      thisClass.layer.add(thisClass.trasformer);
      thisClass.creaAreaSelezione();
      thisClass.creaDrop();
    };
    img.src = this.immagineIniziale;
  }

  /**
   * Questa funzione crea il drop dall'immagine
   * e invio del json in caso di quest'ultimi
   */
  creaDrop() {
    //Ascolto l'evento dragstart
    //prendo l'oggetto
    const thisClass = this;
    let eventDrag = document.getElementById('drag-items');
    if (eventDrag)
    eventDrag.addEventListener('dragstart', function (e: any) {
      //src dell'immagine caricata
      thisClass.itemUrl = e.target.src;
      thisClass.resetStato();
    });

    //dalla demo
    //lo trascino
    let con = this.stage.container();
    con.addEventListener('dragover', function (e) {
      e.preventDefault(); // !important
    });

    //rilascio
    con.addEventListener('drop', function (e) {
      e.preventDefault();
      // now we need to find pointer position
      // we can't use stage.getPointerPosition() here, because that event
      // is not registered by Konva.Stage
      // we can register it manually:
      thisClass.linea = false;
      thisClass.mantieniLinea = false;
      thisClass.stage.setPointersPositions(e);
      Konva.Image.fromURL(thisClass.itemUrl, function (img) {
        let idImg = thisClass.mappaImmagineToId.get(thisClass.itemUrl);
        img.setAttrs({
          x: 200,
          y: 50,
          width: 106,
          height: 118,
          draggable: true,
          id: idImg,
          name: 'figura',
          perfectDrawEnabled: false,
        });
        thisClass.layer.add(img);
        let pos = thisClass.stage.getPointerPosition();
        pos.x = pos.x / thisClass.scale;
        pos.y = pos.y / thisClass.scale;
        img.position(pos);
        //allo studente senza modifiche
        let position = thisClass.stage.getPointerPosition();
        thisClass.stage.add(thisClass.layer);
        let bodyImage = 'immagine_' + position.x + '_' + position.y;
        thisClass.aggiornaStato(img, bodyImage);
      });
    });
  }

  /**
   * Creazione dell'area di selezione in modo che gli oggetti si spostano,
   * ruotano o ridimensionati
   */
  creaAreaSelezione() {
    //quando clicchi su un elemento interno
    this.stage.on('mousedown touchstart', (e) => {
      this.mantieniLinea = true;
      //vai avanti solo se l'utente ha cliccato/premuto sulla linea
      if (e.target != this.stage) {
        return;
      }
      if (this.linea) {
        //se l'utente ha abilitato la linea, allora nel momento che
        //si innesca l'evento creiamola
        this.generateLine(this.modeLinea);
        return;
      }
      //UpdateCursor contiene le coordinate aggiornate Del rettangolo di selezione
      if (this.isEnableDraw() && this.seleziona) {
        this.updateCursor.setX1(this.stage.getPointerPosition().x / this.scale);
        this.updateCursor.setX2(this.stage.getPointerPosition().x / this.scale);
        this.updateCursor.setY1(this.stage.getPointerPosition().y / this.scale);
        this.updateCursor.setY2(this.stage.getPointerPosition().y / this.scale);
        this.areaSelezione.visible(true);
        this.areaSelezione.width(0);
        this.areaSelezione.height(0);
      }
      this.sendJsonBody('update', ' ');
    });

    //quando tieni premuto area di selezione
    this.stage.on('mousemove touchmove', () => {
      if (this.mantieniLinea && this.linea) {
        //se l'utente sta disegnando, mi prende la posizone del punto premuto
        const pos = this.stage.getPointerPosition();
        let indexUltimoDisegno = this.disegniSuTela.length - 1;
        let ultimaLinea = this.disegniSuTela[indexUltimoDisegno] as Konva.Line;
        ultimaLinea.hitStrokeWidth(0);
        ultimaLinea.perfectDrawEnabled(false);
        ultimaLinea.listening(false);
        if (ultimaLinea !== undefined) {
          //e l'aggiungo all'ultimo disegno (la linea precedente)
          let nuovoPunto = ultimaLinea
            .points()
            .concat([pos.x / this.scale, pos.y / this.scale]);
          ultimaLinea.points(nuovoPunto);
        }
        //refresh del punto aggiunto alla linea in modo che diventa visibile all'utente
        //evitando il refresh di tutta la figura
        this.layer.batchDraw();
        //invio al backend
        this.sendJsonBody('update', ' ');
        return;
      }
      //se l'area di selezione non è visibile esci
      if (!this.areaSelezione.visible()) {
        return;
      }
      //aggiorniamo area di selezione
      if (this.seleziona && this.mantieniLinea) {
        this.updateCursor.setX2(this.stage.getPointerPosition().x / this.scale);
        this.updateCursor.setY2(this.stage.getPointerPosition().y / this.scale);
        this.areaSelezione.setAttrs({
          x: Math.min(this.updateCursor.getX1(), this.updateCursor.getX2()),
          y: Math.min(this.updateCursor.getY1(), this.updateCursor.getY2()),
          width: Math.abs(
            this.updateCursor.getX2() - this.updateCursor.getX1()
          ),
          height: Math.abs(
            this.updateCursor.getY2() - this.updateCursor.getY1()
          ),
        });
      }
      this.sendJsonBody('update', ' ');
    });

    //quando l'utente alza il dito sul mouse
    this.stage.on('mouseup touchend', () => {
      this.stage.stopDrag();
      this.mantieniLinea = false;
      if (this.linea) {
        this.mantieniLinea = false;
        this.sendJsonBody('update', ' ');
        return;
      }
      if (!this.areaSelezione.visible()) {
        this.lavagnaService
          .updateBackgroundCanvas(this.bodyDaInviare, this.idAula)
          .subscribe((res) => {});
        this.sendJsonBody('update', ' ');
        return;
      }

      //aggiorna la visibiltà in timeout
      setTimeout(() => {
        this.areaSelezione.visible(false);
      });

      if (!this.seleziona) return;

      //area selezionata
      let area = this.areaSelezione.getClientRect();

      //cerchiamo le figure che rientrano nella seleziona
      this.figureSelezionate = this.disegniSuTela.filter((figura) => {
        if (figura.className == 'Line') {
          return false;
        }
        let clientRect = figura.getClientRect();
        this.sendJsonBody('update', ' ');
        return Konva.Util.haveIntersection(area, clientRect);
      });
      //abilitiamo la trasformazione
      this.trasformer.nodes(this.figureSelezionate);
      this.sendJsonBody('update', ' ');
      this.mantieniLinea = false;
    });

    let thisClass = this;
    //clicchi per selezionare/de-selezionare le forme
    this.stage.on('click tap', function (e) {
      if (thisClass.areaSelezione.visible()) {
        return;
      }

      if (e.target === thisClass.stage) {
        //se clicco fuori, deseleziono tutto
        thisClass.trasformer.nodes([]);
        thisClass.sendJsonBody('update', ' ');
      }

      //se non clicco su una figura esco fuori
      if (!e.target.hasName('figura')) {
        return;
      }

      //gestione della selezione attraverso i pulsanti
      //se premo attraverso il pulsante control o shift
      const pulsantiPremuti = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
      const isSelected = thisClass.trasformer.nodes().indexOf(e.target) >= 0;
      if (!pulsantiPremuti && !isSelected && !thisClass.linea) {
        //se non è stato premuto il pulsante
        // e non è stato selezionato l'oggetto
        // seleziona una solo
        thisClass.trasformer.nodes([e.target]);
      } else if (pulsantiPremuti && isSelected && !thisClass.linea) {
        //se è stato premuto e selezionato
        //dobbiamo rimuoverlo da quelli selezionati
        const figureSottoTrasformazione = thisClass.trasformer.nodes().slice();
        //rimuoviamo
        figureSottoTrasformazione.splice(
          figureSottoTrasformazione.indexOf(e.target),
          1
        );
        thisClass.trasformer.nodes(figureSottoTrasformazione);
      } else if (pulsantiPremuti && !isSelected && !thisClass.linea) {
        //se premuto ma non selezionato
        //aggiungiamo nella trasformazione
        const figure = thisClass.trasformer.nodes().concat([e.target]);
        thisClass.trasformer.nodes(figure);
      }
      thisClass.sendJsonBody('update', ' ');
    });
  }

  //#endregion

  //#endregion

  //#region DESTROY
  //Eliminiamo tutte le subscribe
  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    clearInterval(this.interval);
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (!this.subscribeEditor.closed) {
      this.closeSub();
      this.clearCache();
    }
  }

  /**
   * chiudo tutte le sub
   */
  closeSub() {
    this.subscribeEditor.unsubscribe();
    this.subscribeUpdate.unsubscribe();
    this.subscribeFormData.unsubscribe();
  }

  clearCache() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    localStorage.removeItem('enableWhiteboard');

    //REST per mettere null ad imageStrin solo se docente
    if (this.lavagnaService.isDocente()) {
      //Se sono un docente è sto chiudendo
      this.lavagnaService.assegnaEditor(-1, this.idAula).subscribe((res) => {});
      this.lavagnaService
        .updateBackgroundCanvas('END', this.idAula)
        .subscribe((res) => {});
      this.lavagnaService
        .imageBackgroundCanvas(null, this.idAula)
        .subscribe((res) => {});
      //Salvataggio in esercitazione
      //in ngOnDestroy non funziona
      /*
      this.lavagnaService.sendFileToTutorialsWithFile(this.idAula,this.fileState).subscribe((res)=>{
      })
      */
    }
  }
  //#endregion

  //#region Konva

  /**
   * attiva l'area di selezione
   * @see creaAreaSelezione -> {@link creaAreaSelezione}
   */
  attivaSeleziona() {
    this.strumentoSelezionato = 'seleziona';
    this.seleziona = true;
    this.mantieniLinea = false;
    this.linea = false;
  }

  //#region Konva Draw
  /**
   * Serve per creare immagine
   * @param idDisegniAltro verifica il checkAlign
   * @param x posizione x dell'immagine
   * @param y posizione y dell'immagine
   * @see {@link checkAlign}
   */
  creaLogo(idDisegniAltro: number, x: number, y: number) {
    this.resetStato();
    let thisClass = this;
    Konva.Image.fromURL(thisClass.itemUrl, function (img) {
      let idImg = thisClass.mappaImmagineToId.get(thisClass.itemUrl);
      img.setAttrs({
        x: x,
        y: y,
        width: 106,
        height: 118,
        draggable: thisClass.isEnableDraw(),
        //Per le immagini occorre che id non sia univico al fine di
        //caricare tutte le immagine, si potrebbe usare id, ma diventa difficile la gestione
        id: idImg,
        name: 'figura',
        perfectDrawEnabled: false,
      });
      thisClass.layer.add(img);
      thisClass.stage.add(thisClass.layer);
      thisClass.aggiornaStato(img, 'immagine');
      thisClass.checkAlign(idDisegniAltro);
    });
  }

  lineaGommaPenna = false;
  /**
   * L'utente vuole disegnare o cancellare
   * @param mode penna e gomma
   * @see generateLine metodo che crea l'oggetto linea o gomma
   * @see creaAreaSelezione metodo che contiene gli ascolti agli eventi
   */
  creaLinea(mode: string) {
    this.stage.setPosition({ x: undefined, y: undefined });
    setTimeout(() => {
      this.stage.setPosition({ x: undefined, y: undefined });
    }, 10);
    this.showMenu = false;
    this.strumentoSelezionato = mode;
    this.seleziona = false;
    this.trasformer.nodes([]);
    this.mantieniLinea = false;
    //letiabile che indica all'ascolto della tela all'evento
    //"tener premuto" la creazione della linea
    this.linea = true;
    //letiabile che indica che tipo di line creare
    this.modeLinea = mode;
    this.lineaGommaPenna = true;
  }

  /**
   * Onde evitare che una figura si disegnasse in base alla posizione del mouse
   * occorre resettare determinate letiabili.
   */
  resetStato() {
    this.trasformer.nodes([]);
    this.linea = false;
    this.mantieniLinea = false;
    this.seleziona = false;
    this.strumentoSelezionato = '';
  }

  creaFreccia() {
    this.resetStato();
    let freccia = new Konva.Arrow({
      x: 0,
      y: 200,
      points: [0, 0, 620 / 4, 465 / 4],
      pointerLength: 20,
      pointerWidth: 20,
      fill: this.colorShape_Stroke,
      stroke: this.colorShape_Stroke,
      strokeWidth: this.sizeShape_StrokeWidth,
      name: 'figura',
      id: '' + this.idDisegni,
      draggable: this.isEnableDraw(),
    });

    // add the shape to the layer
    this.layer.add(freccia);
    // add the layer to the stage
    this.stage.add(this.layer);
    this.aggiornaStato(freccia, 'freccia');
  }

  creaTesto() {
    this.resetStato();
    let testo = new Konva.Text({
      text: 'Scrivi qualcosa qui',
      x: 0,
      y: 200,
      fontSize: this.sizeText_FontSize,
      draggable: this.isEnableDraw(),
      width: 200,
      id: '' + this.idDisegni,
      name: 'figura',
      fill: this.colorShape_Stroke,
    });
    this.aggiungiCaratteristicheTesto(testo);
    this.layer.add(testo);
    this.stage.add(this.layer);
    if (this.ruolo == 1) {
      this.aggiornaStato(testo, 'testo');
    }
  }
  creaCerchio() {
    this.resetStato();
    let cerchio = new Konva.Circle({
      //posizione di apparizione
      x: 80,
      y: 200,
      radius: 70,
      stroke: this.colorShape_Stroke,
      id: '' + this.idDisegni,
      strokeWidth: this.sizeShape_StrokeWidth,
      draggable: this.isEnableDraw(),
      name: 'figura', //messo per ogni figura serve per la ricerca
    });
    // add the shape to the layer
    this.layer.add(cerchio);
    // add the layer to the stage
    this.stage.add(this.layer);
    this.aggiornaStato(cerchio, 'cerchio');
  }

  //#region Add caratteristiche Draw
  /**
   * Richiamato per la creazione della linea
   * @param modeLinea penna o gomma
   */
  generateLine(modeLinea: string) {
    this.seleziona = false;
    //l'utente sta disegnando
    //recupero la posizione del mouse
    let pos = this.stage.getPointerPosition();
    if (
      pos === undefined ||
      pos === null ||
      pos.x === undefined ||
      pos.x === null
    ) {
      pos = { x: 0, y: 0 };
    }
    let linea = new Konva.Line({
      stroke: this.colorShape_Stroke,
      strokeWidth: this.sizeShape_StrokeWidth,
      globalCompositeOperation:
        modeLinea === 'penna' ? 'source-over' : 'destination-out',
      points: [pos.x / this.scale, pos.y / this.scale],
      draggable: false,
      id: '' + this.idDisegni,
      name: 'figura',
    });
    this.aggiornaStato(linea, 'linea');
    //recupero l'ultima linea
    this.layer.add(linea);
    this.stage.add(this.layer);
  }

  /**
   * Possibilità di modificare il testo
   * @param testo
   */
  aggiungiCaratteristicheTesto(testo: Konva.Text) {
    let thisClass = this;
    //Il commento ha permesso il resize corretto
    //tuttavia area di testo non si allarga
    //Fix testo da risolvere in altri modi
    // testo.on('transform', function () {
    //   console.log("testo transform",thisClass.scale,testo)
    //   testo.setAttrs({
    //     width: testo.width()*testo.scaleX(),
    //     scaleX: thisClass.scale,
    //   })
    // })

    testo.on('dblclick dbtap', () => {
      testo.hide();
      this.trasformer.hide();
      //crea un'area di testo dove scrivere
      //per farlo ci serve la posizione odierna del testo
      let posizioneTesto = testo.absolutePosition();
      //offset
      let offset = testo.getRelativePointerPosition();
      //poi creiamo area di scrittura attorno alla posizione
      let areaPosizione = {
        x: this.stage.container().offsetLeft / this.scale + posizioneTesto.x,
        y: this.stage.container().offsetTop / this.scale + posizioneTesto.y,
      };
      //infine la textArea
      let textarea = document.createElement('textarea');
      //che inizia nella posizione fornita dal div id=lavagna
      let lavagnaElement = document.getElementById('lavagna');
      lavagnaElement.appendChild(textarea);
      //applichiamo un po' di stile
      textarea.value = testo.text();
      textarea.style.position = 'absolute';
      textarea.style.top = areaPosizione.y + 'px';
      textarea.style.left = areaPosizione.x + 'px';
      textarea.style.width =
        testo.width() / this.scale - testo.padding() * 2 + 'px';
      textarea.style.height =
        testo.height() / this.scale - testo.padding() * 2 + 5 + 'px';
      textarea.style.fontSize = testo.fontSize() + 'px';
      //cancelliamo il bordo e altre proprietà con none
      // in modo che il docente scrive sullo stage
      //e no in una textarea
      textarea.style.border = 'none';
      textarea.style.overflow = 'hidden';
      textarea.style.background = 'none';
      textarea.style.outline = 'none';
      textarea.style.resize = 'none';
      textarea.style.padding = '0px';
      textarea.style.margin = '0px';
      textarea.style.lineHeight = '' + testo.lineHeight();
      textarea.style.fontFamily = testo.fontFamily();
      textarea.style.transformOrigin = 'left top';
      textarea.style.textAlign = testo.align();
      textarea.style.color = testo.fill();
      let rotation = testo.rotation();
      //se il testo presenta una rotazione (non solo in fase di creazione)
      //ma anche di selezione e quindi modifica
      let transform = '';
      if (rotation) {
        //tenere conto di tale rotazione
        transform += 'rotateZ(' + rotation + 'deg)';
      }
      let px = 0;
      //compatibilità con firefox e altri, questa porzione di codice
      //viene fornita da una demo
      let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (isFirefox) {
        px += 2 + Math.round(testo.fontSize() / 20);
      }
      transform += 'translateY(-' + px + 'px)';
      textarea.style.transform = transform;
      //testo responsive
      textarea.style.height = 'auto';
      // after browsers resized it we can set actual value
      textarea.style.height = textarea.scrollHeight + 3 + 'px';
      textarea.focus();
      //funzione che verifica se l'utente clicca fuori dal testo
      function handleOutsideClick(e) {
        if (e.target !== textarea) {
          testo.text(textarea.value);
          removeTextarea();
        }
      }
      //funzione che rimuove area di selezione per la modifica
      function removeTextarea() {
        textarea.parentNode.removeChild(textarea);
        window.removeEventListener('click', handleOutsideClick);
        testo.show();
        thisClass.trasformer.show();
        thisClass.trasformer.forceUpdate();
        thisClass.aggiornaStato(testo, 'testo');
      }

      function setTextareaWidth(newWidth) {
        if (!newWidth) {
          // set width for placeholder
          //#################################################
          //nella demo il codice prevedeva il codice
          //testo.placeholder-> Konva.Text.placeholder ma non esiste
          newWidth = textarea.placeholder.length * testo.fontSize();
        }
        // compatibilità per gli altri browser
        let isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        let isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isSafari || isFirefox) {
          newWidth = Math.ceil(newWidth);
        }
        let isEdge = document.DOCUMENT_NODE || /Edge/.test(navigator.userAgent);
        if (isEdge) {
          newWidth += 1;
        }
        textarea.style.width = newWidth + 'px';
      }

      //gestione tasti escape
      textarea.addEventListener('keydown', function (e) {
        // Gestione Enter
        // ma no shift + enter
        if (e.key === 'Enter' && !e.shiftKey) {
          //quando premiamo enter andiamo d'accapo \n
          testo.text(textarea.value + '\n');
        }
        // Se premiamo esc togliamo area di modifica
        if (e.key === 'Escape') {
          testo.text(textarea.value);
          removeTextarea();
        }
      });
      let thisClass = this;
      textarea.addEventListener('keydown', function (e) {
        let scale = testo.getAbsoluteScale().x;
        //Verificare se conviene absolute o la let
        //istanza
        setTextareaWidth(testo.width() * scale);
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + testo.fontSize() + 'px';
      });

      setTimeout(() => {
        window.addEventListener('click', handleOutsideClick);
      });
    });
  }
  //#endregion

  //#endregion

  //#region Konva update Tela

  /**
   * Aggiorniamo gli attrs delle figure selezionate
   */
  changeAttrs() {
    if (
      this.figureSelezionate === undefined ||
      this.figureSelezionate.length === undefined
    )
      return;
    for (let i = 0; i < this.figureSelezionate.length; i++) {
      if (this.figureSelezionate[i].className === 'Text') {
        this.figureSelezionate[i].setAttr('fontSize', this.sizeText_FontSize);
        this.figureSelezionate[i].setAttr('fill', this.colorShape_Stroke);
      } else {
        this.figureSelezionate[i].setAttr('stroke', this.colorShape_Stroke);
        this.figureSelezionate[i].setAttr(
          'strokeWidth',
          this.sizeShape_StrokeWidth
        );
      }
      if (this.figureSelezionate[i].className === 'Arrow') {
        this.figureSelezionate[i].setAttr('fill', this.colorShape_Stroke);
      }
    }
  }

  /**
   * Questo metodo aggiunge in disegniSuTela la nuova figura creata dal docente.
   *
   * Incrementa la letiabile idDisegni
   *
   * Cancella eventuali disegni in Pending, qualora l'azione precedente era "indietro"
   *
   * Teniamo traccia dell'ultima azione
   *
   * Infine inviamo agli utenti che il docente a creato un nuovo oggetto
   * @param figura di tipo Shape corrisponde al disegno creato
   * @param object per informare gli utenti della figura aggiunta
   * @see disegniSuTela
   */
  aggiornaStato(figura: Shape, object: string) {
    this.disegniSuTela.push(figura);
    this.idDisegni++;
    this.cancellaPending();
    this.ultimaAzione = 'crea';
    if (this.isEnableDraw()) {
      this.sendJsonBody('create', object);
      if(object === 'testo') {
        this.lavagnaService
          .updateBackgroundCanvas(this.bodyDaInviare, this.idAula)
          .subscribe((res) => {});
      }
    }
  }

  /**
   * Vengono chiamati disegni in fase di pending, quei disegni che utente aveva
   *
   * creato, ma che poi tornando indietro li ha momentaneamente cancellati.
   *
   * Se l'utente dopo che torna indietro effettua qualche modifica, i disegni in fase di
   *
   * pending diventano sia inutili che invalidi.
   *
   * Questo metodo serve per cancellare i disegni in stato di pending, in quanto l'utente
   *
   * ha effettuato una modifica.
   */
  cancellaPending() {
    if (this.ultimaAzione == 'indietro') {
      for (let i = 0; i < this.disegniInPending.length; i++) {
        let forma = this.disegniInPending[i];
        //de-alloco la forma
        forma.destroy();
      }
      this.disegniInPending.splice(0, this.disegniInPending.length);
    }
  }

  /**
   * Pulizia di ascolti e disegni
   */
  clearCacheKonva() {
    this.stage.clearCache();
    this.stage.clear();

    if (this.stage.children.length != 0) {
      if (this.layer) {
        this.layer.clear();
        this.layer.clearCache();
      } else {
        this.layer = this.stage.children[0];
      }
    }
  }

  //#endregion

  //#region Konva Azioni Avanti-Indietro-Pulisci

  /**
   * Metodo richiamato se il docente vuole tornare indietro rispetto alla sua ultima azione
   */
  indietro() {
    //deselezioniamo eventuali oggetti evidenziati
    this.trasformer.nodes([]);
    //Il docente non sta più disegnando
    this.linea = false;
    //se ci sono oggetti
    if (this.disegniSuTela.length > 0) {
      //allora possiamo tornare indietro
      //prendiamo l'ultimo oggetto disegnato su tela
      let forma = this.disegniSuTela.pop();
      //salviamo negli array dei disegni in pending
      this.disegniInPending.push(forma);
      //rimuoviamo temporanemante l'oggetto sulla tela
      forma.remove();
      //decrementiamo idDisegni
      this.idDisegni--;
    }
    //aggiorniamo l'ultima azione
    this.ultimaAzione = 'indietro';
    //informiamo gli altri che l'ultima azione è stata quella di tornare indietro
    this.sendJsonBody('undo', ' ');
  }

  /**
   * Metodo richiamato qualora utente vuole continuare il suo disegno, dopo essere tornato indietro
   */
  avanti() {
    //de-seleziona eventuale figure
    this.trasformer.nodes([]);
    //utente non sta disegnando
    this.linea = false;
    //se ci sono delle figure in pending
    if (this.disegniInPending.length > 0) {
      //Allora incrementiamo idDisegni
      this.idDisegni++;
      //preleviamo l'ultimo oggetto aggiunto dall'array in Pending
      let forma = this.disegniInPending.pop();
      //Salviamo l'oggetti nei disegni su tela
      this.disegniSuTela.push(forma);
      //aggiungiamo il disegno nel Konva.layer
      this.layer.add(forma);
    }
    //aggiorniamo l'ultima azione
    this.ultimaAzione = 'avanti';
    //diciamo agli altri che l'azione eseguita dall'utente che sta
    //disegnando è quella di andare avanti
    this.sendJsonBody('redo', ' ');
  }

  /**
   * Elimina tutti gli elementi sulla tela.
   *
   * Pulisce gli array che contiene lo storico.
   *
   * Il trasformer viene azzerato, quindi la selezione si cancella
   */
  pulisci() {
    this.linea = false;
    this.idDisegni = 0;
    for (let i = 0; i < this.disegniInPending.length; i++) {
      let forma = this.disegniInPending[i];
      //de-allochiamo la forma
      forma.destroy();
    }
    for (let i = 0; i < this.disegniSuTela.length; i++) {
      let forma = this.disegniSuTela[i];
      forma.destroy();
    }

    //removeAll
    this.stage.clear()
    this.stage.remove()
    this.disegniInPending.splice(0, this.disegniInPending.length);
    this.disegniSuTela.splice(0, this.disegniSuTela.length);
    this.ultimaAzione = 'pulisci';
    this.trasformer.nodes([]);
    this.sendJsonBody('clear', ' ');
    //puliamo la cache da qualche eventuale immagine
    this.clearCacheKonva();
  }

  //#endregion

  //#region Konva BE
  /**
   * Salviamo immagine
   */
  salvaImmagine() {
    /*
    let link = document.createElement('a');
    link.download = 'Lavagna.png';
    link.href = this.fileString;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
*/
    let thisClass = this;
    const width = this.stage.width();
    const heigth = this.stage.height();
    let immagineTela = new Image();
    immagineTela.onload = function () {
      let immagineDisegni = new Image();
      immagineDisegni.onload = function () {
        let newStage = new Konva.Stage({
          container: 'lavagna', // id of container <div>
          width: width,
          height: heigth,
        });
        let newlayer = new Konva.Layer();
        newStage.add(newlayer);

        let imageKonvaDisegni = new Konva.Image({
          image: immagineDisegni,
          width: width,
          height: heigth,
        });
        let imageKonvaTela = new Konva.Image({
          image: immagineTela,
          width: width,
          height: heigth,
        });

        newlayer.add(imageKonvaTela);
        newlayer.add(imageKonvaDisegni);
        let dataURL = newStage.toDataURL();
        //diciamo al padre che il formData è pronto
        thisClass.formDataDone.emit(
          thisClass.lavagnaService.creaFormData(
            dataURL,
            thisClass.idAula,
            thisClass.aulaInfo.nomeAula
          )
        );
        // thisClass.lavagnaService.sendFileToTutorials(dataURL,thisClass.idAula,thisClass.aulaInfo.nomeAula)
        // .subscribe(res=>{
        // })
        /* per il download da nuova pagina
    let link = document.createElement('a');
    link.download = 'Lavagna.png';
    link.href = dataURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    */
      };
      immagineDisegni.src = thisClass.stage.toDataURL();
    };
    immagineTela.src = this.immagineIniziale;
  }

  /**
   * Salva lo stato del disegno in modo da inviare il file
   */
  saveState() {
    //puliamo lo stato di prima
    let thisClass = this;
    const width = this.stage.width();
    const heigth = this.stage.height();
    let immagineTela = new Image();
    immagineTela.onload = function () {
      let immagineDisegni = new Image();
      immagineDisegni.onload = function () {
        let newlayer = new Konva.Layer();
        thisClass.stage.add(newlayer);

        let imageKonvaDisegni = new Konva.Image({
          image: immagineDisegni,
          width: width,
          height: heigth,
        });
        let imageKonvaTela = new Konva.Image({
          image: immagineTela,
          width: width,
          height: heigth,
        });
        newlayer.add(imageKonvaTela);
        newlayer.add(imageKonvaDisegni);
        let dataURL = thisClass.stage.toDataURL();
        thisClass.fileString = dataURL;
        thisClass.fileState = thisClass.getFileState(dataURL);
        newlayer.remove();
      };
      immagineDisegni.src = thisClass.stage.toDataURL();
    };
    immagineTela.src = this.immagineIniziale;
  }

  getFileState(stringBase64: string): File {
    let ora = formatDate(new Date(), 'dd-MM-yyyy-hh_mm_ss', 'en-US', '+2');
    let title = 'Lavagna ' + this.aulaInfo.nomeAula + ' ' + ora;
    const fileBlob = this.lavagnaService.dataURItoBlob(stringBase64);
    const file = new File([fileBlob], title + '.png');
    return file;
  }

  /**
   * Invio agli studenti.
   * In tutti i casi chi invia manda il suo id
   * Abbiamo 3 tipi di invio:
   *
   * 1: "create" Creazione, in questo caso invio oggetto che lo studente deve creare
   *
   * 2: "update" Spostamento Update che consiste solo nella modifica .
   * dei disegni su teli già esistenti. Docente invio il suo stage con gli attributi aggiornati
   * lo studente deve solo leggerli.
   *
   * 3: "undo,clear,redo" Azioni di Undo,redo,cancello. Inoltre la lunghezza attuale
   * dei disegni su tela e in pending se diverso, "lato studente" occorre un align
   *
   * 4: "align" Solo per gli studenti, manda una richiesta di aggiornamento tela
   *
   * @param mode modalità di invio citate sopra
   * @param object il tipo di oggetto creato
   */
  sendJsonBody(mode: string, object: string) {
    let body = this.numeroPacchettoDaInviare + '###';
    //gestiamo la frequenza
    if (mode !== 'update') {
      //se non è update non occorre gestire la frequenza di chiamate
      this.delay = false;
    }
    if (this.delay) {
      //il delay viene abilitato in update e poi un timeout
      //lo setta a false
      return;
    }
    if (!this.isEnableDraw()) {
      return;
    }

    if (mode === 'create') {
      //caso 1
      //se l'oggetto non è una linea invia il tipo di oggetto
      //create+stringColorShape+sizeShapeNumber+fontSizeNumber
      //itemUrl
      if (object !== 'linea') {
        body =
          body +
          'create+' +
          this.colorShape_Stroke +
          '+' +
          this.sizeShape_StrokeWidth +
          '+' +
          this.sizeText_FontSize +
          '+' +
          this.itemUrl +
          '###' +
          this.lavagnaService.mioId() +
          '###' +
          object +
          '###' +
          this.idDisegni;
        this.bodyDaInviare = body;
      } else {
        //se l'oggetto è una linea deve dire che tipo di linea è
        //una penna o una gomma?
        body =
          body +
          'create+' +
          this.colorShape_Stroke +
          '+' +
          this.sizeShape_StrokeWidth +
          '+' +
          this.sizeText_FontSize +
          '+' +
          this.itemUrl +
          '###' +
          this.lavagnaService.mioId() +
          '###' +
          object +
          '+' +
          this.modeLinea +
          '###' +
          this.idDisegni;

        this.bodyDaInviare = body;
      }
    } else if (mode === 'undo' || mode === 'redo' || mode === 'clear') {
      //caso 2
      let jsonBody: string =
        this.disegniSuTela.length + '+' + this.disegniInPending.length;
      body =
        body + mode + '###' + this.lavagnaService.mioId() + '###' + jsonBody;

      this.bodyDaInviare = body;
    } else if (mode === 'update') {
      //caso 3
      let updateStage = this.stage.toObject();
      let jsonBody = JSON.stringify(updateStage);
      body =
        body + 'update###' + this.lavagnaService.mioId() + '###' + jsonBody;
      this.bodyDaInviare = body;
      //settiamo il ritardo
      this.delay = true;
      setTimeout(() => {
        //dopo un delayTime si toglie
        this.delay = false;
      }, this.delayTime);
    } else {
      //caso 4
      body =
        body + 'align###' + this.lavagnaService.mioId() + '###' + 'request';

      this.bodyDaInviare = body;
    }
    this.numeroPacchettoDaInviare++;
  }

  /**
   * Serve per caricare sulla tela gli stessi disegni del docente
   *
   * Azione:
   * create+stringColorShape+sizeShapeNumber+fontSizeNumber+itemUrl###mittende###oggetto+mode?###idDisegni mode solo se oggetto = linea
   *
   * undo,redo,clear###mittende###suTela.length+inPend.length
   *
   * update###mittende###stageObject
   *
   * align:
   *
   * Studente-> align###mittende###request
   *
   * Docente-> align###mittende###destinatario###Konva.Stage.toJson###ArrayPending
   *
   * Per ottenere il body bastare considerare la 3 voce dopo i 2 ###
   *
   * @param body A seconda dell'azione può essere diverse cose
   * @param azione la modalità di aggiornamento della tela: 3 tipi crea, update e (undo|clear|redo)
   * @param idDisegni solo quando azione è create altrimenti null
   */
  loadJson(body: string, azione: string, idDisegni?: number | null) {
    /*
    create+stringColorShape+sizeShapeNumber+fontSizeNumber+itemUrl###mittende###oggetto+mode###idDisegni? mode solo se oggetto = linea
    update###mittende###stageObject
    */
    const informazioni: string[] = body.split('+');
    const oggetto = informazioni[0];
    const posImage = oggetto.split('_');
    let oggettoScelto = '';
    if (posImage.length <= 1) {
      oggettoScelto = oggetto;
    } else {
      oggettoScelto = posImage[0];
    }
    if (azione === 'create') {
      switch (oggettoScelto) {
        case 'cerchio':
          this.creaCerchio();
          break;
        case 'freccia':
          this.creaFreccia();
          break;
        case 'linea':
          this.modeLinea = informazioni[1];
          this.generateLine(this.modeLinea);
          break;
        case 'testo':
          this.creaTesto();
          break;
        case 'immagine':
          let x = Number(posImage[1]);
          let y = Number(posImage[2]);
          this.creaLogo(idDisegni, x, y);
          break;
        default:
          throw new Error('Switch case errato');
      }
      //non consideriamo qua l'oggetto immagine in quanto
      //visto che impiega tempo al caricamento dell'immagine
      //idDisegni non ha il valore aggiornato
      if (oggettoScelto !== 'immagine') {
        this.checkAlign(idDisegni);
      }
    } else if (azione === 'update') {
      //Modalità update

          if (!this.isEnableDraw()) {
            this.lavagnaService.reAlign(this.idAula,this.checkControlloPacchetto).subscribe(
              res => {
              }
            )
          }
      const stageObj = JSON.parse(body);
      const figureDocente: Shape[] =
        (stageObj.children[0].children as Shape[]) || [];
      let figureStudente: Shape[] =
        (this.stage.children[0].children as Shape[]) || [];
      //Verifichiamo se le figure sono sincronizzate
      if (figureDocente.length !== figureStudente.length) {
        this.checkStessoStato(
          figureDocente.length,
          this.disegniInPending.length
        );
        return;
      }
      //parte da 2 in quanto 0 è layer e 1 il transformer
      for (let i = 2; i < figureStudente.length; i++) {
        if (figureDocente[i].attrs.id === figureStudente[i].attrs.id) {
          this.aggiornoFigura(figureStudente[i], figureDocente[i]);
        }
      }
    } else {
      // undo,redo,clear###mittende###suTela.length+inPend.length
      const dimTelaDoc: number = Number(informazioni[0]);
      const dimPendDoc: number = Number(informazioni[1]);

      if (azione === 'undo') {
        this.indietro();
      } else if (azione == 'redo') {
        this.avanti();
      } else {
        this.pulisci();
      }
      this.checkStessoStato(dimTelaDoc, dimPendDoc);
    }
  }

  /**
   * Verifichiamo se il l'utente ha bisogno di un re-align.
   *
   * Per verificarlo, fa un confronto tra il numero di oggetto di chi disegni e il
   * proprio
   * @param idDisegniAltro il numero di oggetti che possiede chi sta disegnando
   */
  checkAlign(idDisegniAltro: number) {
    if (idDisegniAltro && idDisegniAltro !== this.idDisegni) {
      this.lavagnaService
        .reAlign(this.idAula, this.checkControlloPacchetto)
        .subscribe((res) => {});
    }
  }

  /**
   * Serve per Allineare la vista del docente a quella dello studente
   * @param jsonStage contiene l'oggetto stage
   * @param jsonArrayPending contiene array di disegni nascosti dall'azione indietro
   * @idDisegni puntamento per mantere l'integrelità
   */
  alignWhiteboard(
    jsonStage: string,
    jsonArrayPending: string,
    idDisegni: number
  ) {
    this.pulisci();
    //#region Stage e Disegni su tela
    this.stage = Konva.Node.create(jsonStage, 'lavagna');
    this.layer = this.stage.children[0];
    //per le immagini
    //Occorre caricare le immagine in gruppi se abbiamo più di una immagine
    //questo è caso singolo.
    //si recupera il numero di oggetti
    let thisClass = this;

    for (let pos = 0; pos < thisClass.immagini.length; pos++) {
      let idImmagine = thisClass.immagini[pos].id;
      let imageObj = new Image();
      imageObj.onload = function () {
        thisClass.stage.getLayer();
        //il findOne serve # per la ricerca tramite id, . per la ricerca con name
        // la differenza tra findOne e find e che l'ultimo fornisce un array,
        //vuoto se non trova mentre findOne undefined
        //Conviene usare il find per cercare tutto e poi caricare le forme
        let forme = thisClass.stage.find('#' + idImmagine) as Konva.Image[];
        for (let i = 0; i < forme.length; i++) {
          //verifico se l'immagine è già caricata
          let img = forme[i].image();
          if (img === undefined || img === null) {
            //se non lo è la carico
            forme[i].image(imageObj);
          }
        }
      };
      //fase in cui carico l'immagine
      imageObj.src = this.immagini[pos].src;
    }

    if (this.stage.children.length > 0) {
      let figureStudente: Shape[] =
        (this.stage.children[0].children as Shape[]) || [];
      for (let i = 2; i < figureStudente.length; i++) {
        this.disegniSuTela.push(figureStudente[i]);
      }
    }
    //#endregion

    //idDisegni
    this.idDisegni = idDisegni;

    //#region jsonArrayPending
    const jsonStringArrayDisegni: string[] = JSON.parse(jsonArrayPending);
    for (let i = 0; i < jsonStringArrayDisegni.length; i++) {
      let forma: Shape = JSON.parse(jsonStringArrayDisegni[i]) as Shape;
      this.disegniInPending.push(forma);
    }
    //#endregion

    //Fix senza il codice successivo, uno studente
    //poteva spostare gli oggetti
    //setto i draggable
    this.disegniInPending.forEach((shape) => {
      shape.setAttr('draggable', this.isEnableDraw());
    });
    this.disegniSuTela.forEach((shape) => {
      shape.setAttr('draggable', this.isEnableDraw());
    });
  }

  /**
   * Verifica se abbiamo lo stesso stato del docente, qualora non fosse occorre align
   * @param dimTelaDoc numero di oggetti presenti sul docente
   * @param dimPendDoc numero di oggetti in attesa di avanti del docente
   * @see LavagnaService il metodo align
   */
  checkStessoStato(dimTelaDoc: number, dimPendDoc: number) {
    if (
      dimTelaDoc != this.disegniSuTela.length ||
      dimPendDoc != this.disegniInPending.length
    ) {
      this.lavagnaService
        .reAlign(this.idAula, this.checkControlloPacchetto)
        .subscribe((res) => {});
    }
  }

  aggiornoFigura(figStudente: Shape, figDocente: Shape) {
    switch (figStudente.className) {
      case 'Circle': {
        let stud = figStudente as Konva.Circle;
        let doc = figDocente as Konva.Circle;
        stud.setAttrs({
          draggable: this.isEnableDraw(),
          radius: doc.attrs.radius,
          scaleX: doc.attrs.scaleX,
          scaleY: doc.attrs.scaleY,
          stroke: doc.attrs.stroke,
          strokeWidth: doc.attrs.strokeWidth,
          x: doc.attrs.x,
          y: doc.attrs.y,
        });
        break;
      }
      case 'Arrow': {
        let stud = figStudente as Konva.Arrow;
        let doc = figDocente as Konva.Arrow;
        stud.setAttrs({
          dashEnabled: doc.attrs.dashEnabled,
          draggable: this.isEnableDraw(),
          fill: doc.attrs.fill,
          pointerLength: doc.attrs.pointerLength,
          pointerWidth: doc.attrs.pointerWidth,
          points: doc.attrs.points,
          rotation: doc.attrs.rotation,
          scaleX: doc.attrs.scaleX,
          scaleY: doc.attrs.scaleY,
          skewX: doc.attrs.skewX,
          stroke: doc.attrs.stroke,
          strokeWidth: doc.attrs.strokeWidth,
          x: doc.attrs.x,
          y: doc.attrs.y,
        });
        break;
      }
      case 'Text': {
        let stud = figStudente as Konva.Text;
        let doc = figDocente as Konva.Text;
        stud.setAttrs({
          draggable: this.isEnableDraw(),
          fill: doc.attrs.fill,
          stroke: doc.attrs.stroke,
          fontSize: doc.attrs.fontSize,
          offsetX: doc.attrs.offsetX,
          offsetY: doc.attrs.offsetY,
          rotation: doc.attrs.rotation,
          scaleX: doc.attrs.scaleX,
          scaleY: doc.attrs.scaleY,
          skewX: doc.attrs.skewX,
          skewY: doc.attrs.skewY,
          text: doc.attrs.text,
          width: doc.attrs.width,
          x: doc.attrs.x,
          y: doc.attrs.y,
        });
        break;
      }
      case 'Line': {
        let stud = figStudente as Konva.Line;
        let doc = figDocente as Konva.Line;
        stud.setAttrs({
          draggable: false,
          stroke: doc.attrs.stroke,
          strokeWidth: doc.attrs.strokeWidth,
          colorKey: doc.attrs.colorKey,
          points: doc.attrs.points,
          x: doc.attrs.x,
          y: doc.attrs.y,
        });
        stud.cache();
        this.stage.batchDraw();
        break;
      }
      case 'Image': {
        let stud = figStudente as Konva.Image;
        let doc = figDocente as Konva.Image;
        stud.setAttrs({
          draggable: this.isEnableDraw(),
          offsetX: doc.attrs.offsetX,
          offsetY: doc.attrs.offsetY,
          rotation: doc.attrs.rotation,
          scaleX: doc.attrs.scaleX,
          scaleY: doc.attrs.scaleY,
          skewX: doc.attrs.skewX,
          skewY: doc.attrs.skewY,
          x: doc.attrs.x,
          y: doc.attrs.y,
          colorKey: doc.colorKey,
        });
        break;
      }
      default:
        throw new Error();
    }
  }

  //#endregion

  //#endregion

  //#region GestionePacchetti
  /**
   * Salvo il pacchetto arrivato.
   *
   * Il formato è:
   *
   * idPacchetto
   *
   * corpo del messaggio
   *
   * Il tutto diviso da ###
   * @param updateCanvas dalla socket
   */
  saveJsonStorico(updateCanvas: string) {
    const idPacchettoString: string = updateCanvas.split('###')[0];
    const startJson: number = idPacchettoString.length + 3; //parte da 0
    const endJson: number = updateCanvas.length;
    //Se mettiamo || null nel costruttore Number è il valore è '0'
    //il risultato sarà sempre null.
    //il costruttore fornisce NaN se il parametro non è un numero
    const idPacchetto: number = Number(idPacchettoString);
    //il metodo isNaN è vero se idPacchetto non è un numero
    if (isNaN(idPacchetto)) return;
    const JsonPacchetto: string = updateCanvas.substring(startJson, endJson);
    this.storicoJson.push(new LavagnaStorico(idPacchetto, JsonPacchetto));
  }
  /**
   * Gestione dell'indicizzazione del pacchetto
   * @param updateCanvas messaggio della socket con gli update
   * @returns string con updateCanvas aggiornato oppure null
   */
  gestionePacchetti(updateCanvas: string): string {
    //salvataggio nuovo pacchetto
    this.saveJsonStorico(updateCanvas);
    //se sono un docente non ha senso la gestione dei pacchetti
    if (!this.lavagnaService.isDocente()) {
      //gestione primo pacchetto e delay
      if (this.isPrimoPaccheto) {
        this.isPrimoPaccheto = false;
        this.checkControlloPacchetto = this.storicoJson[0].getIdPacchetto();
      }
      //verifichiamo se ci sono stati fallimenti precedenti
      if (this.nFailed >= 2) {
        //se ci sono riazzeriamo il tutto
        this.nFailed = 0;
        this.storicoJson.splice(0, this.storicoJson.length);
        this.lavagnaService
          .reAlign(this.idAula, this.checkControlloPacchetto, null)
          .subscribe((res) => {});
        this.isPrimoPaccheto = true;
        return;
      }
      //verifica se il pacchetto va analizzato
      if (this.storicoJson[0].getIdPacchetto() < this.checkControlloPacchetto) {
        //il pacchetto arrivato è precedente quindi non lo analizziamo
        this.storicoJson.pop();
        // this.nFailed++;
        return null;
      } else {
        //caso in cui è il pacchetto desiderato è successivo
        //mi salvo il numeroPacchetto nel e proseguo.
        //In questo modo i successivi pacchetti se + piccoli non li conto
        this.checkControlloPacchetto = this.storicoJson[0].getIdPacchetto();
      }
    }
    //caso in cui il pacchetto ricevuto è sicuramente il successivo
    updateCanvas = this.storicoJson.pop().getJsonReceive();
    return updateCanvas;
  }
  //#endregion

  //#region LOGICA Sito

  /**
   *
   * @returns vero se l'utente può modificare la lavagna
   */
  isEnableDraw(): boolean {
    if (this.lavagnaService.isDocente()) {
      //il docente può sempre disegnare
      return true;
    } else {
      return this.enableDraw; //solo se il docente lo abilita
    }
  }

  //StartResetTimer
  /**
   * Il metodo starta un timer per cercare di connettersi alla socket,
   * questo timer quando si attiva cambia idAula in modo da avviare la connessione alla socket
   * in app component
   * @see {@link LavagnaService(LavagnaService)}
   * @see {@link AppComponent(AppComponent)}
   */
  startConnectionSocket() {
    clearTimeout(this.timerConnection);
    this.timerConnection = setInterval(() => {
      this.lavagnaService.changeidAula(this.idAula);
      this.lavagnaService.socketProblem = true;
    }, 5000); //5min 300s*1000=300000 ms
  }

  abilitaPalettaImmagini() {
    this.enableImagesEmitter.emit(true);
    this.lavagnaService.nImmagini();
    this.mostraImmagini = !this.mostraImmagini;
  }

  palettaImmaginiAttiva(): string {
    if (!this.mostraImmagini && !document.fullscreenElement) {
      return '#2196f3';
    }
    return 'green';
  }

  //#endregion

  //#region Responsive
  /**
   * Abilita il menu responsive
   */
  menu() {
    this.check = !this.check;
  }

  getHeightImg(): number {
    return this.height;
  }

  getWidthImg(): number {
    return this.width;
  }

  toggleMenu() {
    this.state = this.state === 'default' ? 'rotated' : 'default';

    this.showMenu = !this.showMenu;
  }
  getWidth() {
    let number = document.getElementById('lavagna').clientWidth;
    return number - 50 + 'px';
  }
  checkFS() {
    if (document.fullscreenElement) {
      return false;
    } else {
      return !this.mostraImmagini;
    }
  }
  checkOnlyFS() {
    if (document.fullscreenElement) {
      return true;
    } else {
      return false;
    }
  }

  imageWidth() {
    let width = document.getElementById('allLavagna').clientWidth;
    return width - 20;
  }
  //#endregion
  inMenu() {
    this.mantieniLinea = false;
    this.stage.stopDrag();
    this.stage.setPosition({ x: undefined, y: undefined });
  }
}

/**
 * Contiene lo spostamento del cursore
 */
class DatiTrasformazione {
  private x1: number = 0;
  private x2: number = 0;
  private y1: number = 0;
  private y2: number = 0;

  getX1(): number {
    return this.x1;
  }

  getX2(): number {
    return this.x2;
  }

  getY1(): number {
    return this.y1;
  }

  getY2(): number {
    return this.y2;
  }

  setX1(x1: number) {
    this.x1 = x1;
  }

  setX2(x2: number) {
    this.x2 = x2;
  }

  setY1(y1: number) {
    this.y1 = y1;
  }

  setY2(y2: number) {
    this.y2 = y2;
  }
}
