<h4>Inserisci Campagna</h4>
<mat-horizontal-stepper orientation="horizontal" #stepper (selectionChange)="showError($event)" >
  <mat-step [completed]="canGoToStep2()">
    <ng-template matStepLabel>Dati Campagna</ng-template>
<div class="flex" [ngClass]="{'pnone': isInfo}">
  <mat-form-field class="w-45" appearance="fill">
    <mat-label>Inserisci periodo di tempo</mat-label>
    <mat-date-range-input [rangePicker]="picker" disabled>
      <input [readonly]="isInfo" matInput [(ngModel)]="campagnaModel.startDate" matStartDate placeholder="Data d'inizio">
      <input [readonly]="isInfo" matInput [(ngModel)]="campagnaModel.endDate" matEndDate placeholder="Data di fine">
    </mat-date-range-input>
    <mat-hint>GG/MM/AAAA – GG/MM/AAAA</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
  </mat-form-field>

  <mat-form-field class="w-45 ml-auto">
    <mat-label>Target</mat-label>
    <mat-select multiple [(ngModel)]="campagnaModel.listaTarget" [compareWith]="compareItems">
      <mat-option *ngFor="let target of listaTarget" [value]="target">{{target.nomeTarget}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="flex">
  <button class="btn"  (click)="goBack()">Indietro</button>
  <button class="btn btn-primary ml-auto" matStepperNext>Avanti</button>
</div>
</mat-step>
<mat-step>
  <ng-template matStepLabel>Dati Questionari</ng-template>

  <mat-form-field class="w-45">
    <input matInput (input)="search()" [placeholder]="'Ricerca questionario'" [(ngModel)]="ricercaQuestionario" />
  </mat-form-field>

  <p-table [ngClass]="{'pnone': isInfo}" style="overflow-x: hidden;" [value]="listaQuestionario" [scrollable]="true" scrollHeight="40rem" scrollWidth="100rem"
  responsiveLayout="stack" *ngIf="isClickableQuestionari">

  <ng-template pTemplate="header">
    <tr>

      <th>Questionario</th>
      <th class="f-right">
        Seleziona i questionari
      </th>

    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-motivo>
    <tr>

      <td>{{motivo.nomeQuestionario}}</td>
      <td class="f-right">
        <p-checkbox [disabled]="true" *ngIf="!checkBoxChecked(motivo)" [(ngModel)]="False" (click)="aggiungiQuestionario(motivo)" [binary]="true"></p-checkbox>
        <p-checkbox [disabled]="true" *ngIf="checkBoxChecked(motivo)" [(ngModel)]="True" (click)="rimuoviQuestionario(motivo)" [binary]="true"></p-checkbox>
      </td>

    </tr>

  </ng-template>

</p-table>
<div class="mt-10">
  <app-paginator *ngIf="refreshView" [pageSize]="maxPagine"
          [(isClickable)]="isClickableQuestionari" (currentPageEmitter)="cambiaPaginaQuestionari($event)"
          [startingPage]="1">
        </app-paginator>
</div>
  <div class="rigaPulsanti">
    <button class="btn btn-primary" matStepperPrevious>Indietro</button>
    <button class="btn btn-primary ml-auto" matStepperNext>Avanti</button>
  </div>
</mat-step>
<mat-step>
  <ng-template matStepLabel>Dati Target</ng-template>


<div *ngFor="let item of campagnaModel.listaTarget; let i = index">
  <div class="flex" *ngIf="i%2===0">
    <mat-form-field class="w-45">
      <mat-label>Numero minimo di chiamate per {{item.nomeTarget}}</mat-label>
      <input [readonly]="isInfo" matInput type="number" [(ngModel)]="item.chiamataTarget" min="0" placeholder="Numero di chiamate" pattern="\d+">
    </mat-form-field>
    <mat-form-field class="w-45 ml-auto" *ngIf="campagnaModel.listaTarget[i+1]">
      <mat-label>Numero minimo di chiamate per {{campagnaModel.listaTarget[i+1].nomeTarget}}</mat-label>
      <input [readonly]="isInfo" matInput type="number" [(ngModel)]="campagnaModel.listaTarget[i+1].chiamataTarget" min="0" placeholder="Numero di chiamate" pattern="\d+">
    </mat-form-field>
  </div>
</div>

  <div class="rigaPulsanti">
    <button class="btn btn-primary" matStepperPrevious>Indietro</button>
    <button class="btn btn-primary ml-auto" *ngIf="!isInfo" (click)="saveCampagna()">Salva</button>
  </div>
</mat-step>
</mat-horizontal-stepper>
