<p-confirmDialog #cd  icon="pi pi-exclamation-triangle" [style]="{width: responsiveWidth() }" [acceptLabel]="'Si'" [baseZIndex]="5">
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="Sì" (click)="cd.accept()"></button>
    <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </p-footer>
</p-confirmDialog>

<div class="content" *ngIf="motivoChiamata">
  <div class="container-fluid">
    <ng-container class="openVidu">
      <div class="card shadow">
        <div class="card-body">
          <p-table [value]="motiviChiamata" selectionMode="single" [(selection)]="selectedMotivo" dataKey="motivoChiamata">
            <ng-template pTemplate="header">
              <tr>
                <th>Seleziona il motivo della chiamata:</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-motivo>
              <tr [pSelectableRow]="motivo">
                <td>{{motivo.motivoChiamata}}</td>
              </tr>
            </ng-template>
          </p-table>

          <div style="text-align: right; justify-content: right; margin-top: 5px;margin-right: 5px;">
            <a class="btn btn-primary" (click)="inserisciMotivoChiamata()" style="color: white;">Conferma</a>
          </div>
          
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="content" *ngIf="controlRoom">
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <p-table *ngIf="controlRooms.data" selectionMode="single" [(selection)]="selectedRoom" [value]="controlRooms.data | keyvalue" dataKey="controlRooms">
          <ng-template pTemplate="header">
            <tr>
              <th>Seleziona la Control Room</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-room>
            <tr [pSelectableRow]="room">
              <td (click)="joinMeeting(room.key)">Control Room di {{room.value}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<!-- <div class="content" *ngIf="controlRoom">
  <div class="container-fluid">
    <div class="card-shadow">
      <div class="card-body">
          <div *ngFor="let room of controlRooms.data | keyvalue">
            <p (click)="joinMeeting(room.key)">Control Room di {{ room.value }}</p>
          </div>
      </div>
    </div>
  </div>
</div> -->

<div style="display: block; width: 100%; margin-top: 5px;">
  <div class="row">
  <div class="col-xs-1"></div>
  <div class="col-xs-10" style="z-index: 0">
  <ng-container>
    <div style="height: 90vh;">
<lib-npx-gap-chime
  *ngIf="mostraJoinMeetingComponent"
  (requestJson)="callJoinMeeting()"
  [sendJsonChime]="sendJson.asObservable()"
></lib-npx-gap-chime>
</div>
</ng-container>
</div>
<div class="col-xs-1"></div>
</div>
</div>

<!-- <div id="container" style="display: block; width: 100%; margin-top: 5px;">
  <div class="row">
    <div class="col-xs-1"></div>
    <div class="col-xs-10" style="z-index: 0">
      <ng-container *ngIf="!motivoChiamata && access">
        <div style="height: 90vh;">
          <iframe src="../../../../assets/dist/meetingV2.html" frameborder="0" style="height: 100%;width: 100%;" *ngIf="isChime"></iframe>
        </div>
      </ng-container>
    </div>
    <div class="col-xs-1"></div>
  </div>
</div> -->
