import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FarmacieCallCenterRoutingModule } from './farmacie-call-center-routing.module';
import { FarmacieListComponentComponent } from './farmacie-list-component/farmacie-list-component.component';
import { FarmacieAddComponent } from './farmacie-add/farmacie-add.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { CustomPaginator } from 'src/app/SERVICES/USER/CustomPaginato';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {
  MatProgressSpinner,
  MatProgressSpinnerModule,
  MatSpinner,
} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [FarmacieListComponentComponent, FarmacieAddComponent],
  imports: [
    MatFormFieldModule,
    CommonModule,
    MatStepperModule,
    MatTableModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatPaginatorModule,
    DialogModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FarmacieCallCenterRoutingModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
  ],
})
export class FarmacieCallCenterModule {}
