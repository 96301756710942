<div *ngIf="userId">
  <div class="ng-chat-title secondary-background shadowed"
    style="font-size:small; position: absolute; top: 0;bottom: 20px;z-index: 9999; margin-left: 210px;"><a
      (click)="chiudi()" style="color: white">x</a></div>
  <div id="chat-container">
    <ng-chat [title]="'Lista Utenti'" [searchPlaceholder]="'Cerca...'" [messageDatePipeFormat]="'d/M/yy, H:mm'" 
    [messagePlaceholder]="'Scrivi un messaggio...'"  [adapter]="adapter" [groupAdapter]="adapter" 
    [userId]="adapter.userId" [historyEnabled]="true" [theme]="currentTheme" 
    (onParticipantClicked)="onEventTriggeredPartecipanti($event)" [pollFriendsList]="true"
    [isViewportOnMobileEnabled]="false"
    >
    </ng-chat>
    <!-- [isViewportOnMobileEnabled]="true" -->
  </div>
</div>

