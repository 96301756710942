<div class="container-fluid">
    <div class="content" >
    
        <div class="row" >
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content"> 
                        <div class="card-header" >
                            <h5 class="card-title"  >Ricerca Avanzata</h5></div>
                            <form [formGroup]="formRicerca" class="form-horizontal">

                                <div class="row">
                                    <label class="col-sm-2 label-on-left">Nome File</label>
                                    <div class="col-sm-10">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" placeholder="Nome File"
                                                formControlName="nomeFile" class="form-control"
                                            
                                                maxlength="30" />
                                        </div>
                                        <div >
                        
                                            <div ></div>
                                        </div>
                                    </div>
                                </div>  


                                <div class="row">
                                    <label class="col-sm-2 label-on-left">Categoria</label>
                                    <div class="col-sm-10">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" placeholder="Categoria"
                                                formControlName="categoria" class="form-control"
                    
                                                maxlength="30" />
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <label class="col-sm-2 label-on-left">Gruppo</label>
                                    <div class="col-sm-10">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" placeholder="Gruppo"
                                                formControlName="gruppo" class="form-control"
                    
                                                maxlength="30" />
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <label class="col-sm-2 label-on-left">Proprietario</label>
                                    <div class="col-sm-10">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" placeholder="Proprietario"
                                                formControlName="proprietario" class="form-control"
                    
                                                maxlength="30" />
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <label class="col-sm-2 label-on-left">Contenuto</label>
                                    <div class="col-sm-10">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" placeholder="Contenuto"
                                                formControlName="contenuto" class="form-control"
                                                minlength="5"
                                                maxlength="30" />
                                                <div *ngIf="submitted && formRicerca.controls.contenuto.errors" class="invalid-feedback">
                                                    <div>Inserisci almeno 5 caratteri</div>
                                                    
                                                </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <label class="col-sm-2 label-on-left">Autore</label>
                                    <div class="col-sm-10">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" placeholder="Autore"
                                                formControlName="autore" class="form-control"
                    
                                                maxlength="30" />
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <label class="col-sm-2 label-on-left">Estensione</label>
                                    <div class="col-sm-10">
                                        <div class="form-group label-floating is-empty">
                                            <label class="control-label"></label>
                                            <input type="text" placeholder="Estensione"
                                                formControlName="estensione" class="form-control"
                    
                                                maxlength="30" />
                                        </div>
                                    </div>
                                </div>


                                <div class="row" >
                                    <label class="col-sm-2 label-on-left" >Data</label>
                                    <div class="col-sm-2">
                                        <div class="form-group label-floating is-empty char">
                                        
                                            <p-calendar [locale]="ita" placeholder="Da" formControlName="dataDa" dateFormat="dd.mm.yy"></p-calendar>
                                           
                                        
                                        </div>
                                    </div>
                                        
                                        <div class="col-sm-2">
                                            <div class="form-group label-floating is-empty char">
                                            
                                                <p-calendar [locale]="ita" placeholder="A" formControlName="dataA" dateFormat="dd.mm.yy"></p-calendar>
                                
                                            </div>
                                        </div></div>


                                
                               
                            </form>
                 

<div class="row" style="margin-top: 2%">
    <div class="col-xs-6">
        <button (click)="goBack()" class="btn btn-secondary" style="color: white;margin-right: 2%;margin-bottom: 2%;margin-top: 2%;">Indietro</button>

    </div>
    <div class="col-xs-6 text-right">
        <button [disabled]="loading" (click)="onSubmit()" class="btn btn-primary">Ricerca
            <span *ngIf="loading"><p-progressSpinner [style]="{width: '10px', height: '10px'}" strokeWidth="8" styleClass="custom-spinner" animationDuration=".5s"></p-progressSpinner></span>
        </button>
    </div>
</div>
</div>

</div>

</div>

</div>
</div></div>