import { Component, OnInit, OnDestroy } from '@angular/core';
import { HostListener } from "@angular/core";
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { CanComponentDeactivate } from 'src/app/HELPERS/candeactivate-guard.service';
import { EnvService } from 'src/app/SERVICES/GENERAL/env.service';
import { OperatorService } from 'src/app/SERVICES/ASSISTENZA/operator.service';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { AccountService } from 'src/app/SERVICES/index';
@Component({
  selector: 'app-home-control-roomLight',
  templateUrl: './home-control-roomLight.component.html',
  styleUrls: ['./home-control-roomLight.component.scss']
})
// export class HomeControlRoomLightComponent implements OnInit, CanComponentDeactivate {
  export class HomeControlRoomLightComponent implements OnInit {
  caricato = false;
  constructor(private operatorService : OperatorService,  private alertService : AlertService,
    private accountService : AccountService,
    private router : Router,
    private env : EnvService ) {

  }

  ngOnInit():void{
    if(!this.env.GEO){
      this.router.navigate(['']);
     }
    if ((this.accountService.ruolo !== 1) && (this.accountService.ruolo !== 2)){
      this.router.navigate(['']);
    }
    setTimeout(()=>{this.caricato=true;},6000)
  }

  mappaCaricata(event){
    this.caricato = event;
  }
  // canDeactivate(): Observable<boolean> | Promise <boolean> | boolean{
  //   if(this.env.GEO){
  //   if(!this.caricato){
  //     alert('Attendi il caricamento della pagina')
  //     return false


  //   } else{
  //     return true;
  //   }}
  //   else{
  //     return true;
  //   }}


}
