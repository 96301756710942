import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { EnvService } from '../GENERAL/env.service';
import { Messaggio } from 'src/app/MODELS/USER/messaggio';




@Injectable({ providedIn: 'root' })
export class ServiceChat {


    constructor(private http: HttpClient, private env : EnvService) {

    }

    lista_utenti(): Observable<any> {
        return this.http.get<any>(`${this.env.apiUrl}/list_users_online`)
    }


    lista_gruppi(id_utente): Observable<any> {
        return this.http.get<any>(`${this.env.apiUrl}/lista_gruppi_utente`)
    }

    manda_messaggio(messaggio: Messaggio): Observable<any> {
        return this.http.post<any>(`${this.env.apiUrl}/manda_messaggio`, messaggio)
    }

    recupera_chat(idCanale): Observable<any> {
        return this.http.post<any>(`${this.env.apiUrl}/recupera_chat/?idCanale=` + idCanale, null)
    }

    creazione_canale(userId,userDest): Observable<any> {
        let request = [userId, userDest]
        return this.http.post<any>(`${this.env.apiUrl}/apri_canale`,request)
    }
}