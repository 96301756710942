import { Injectable } from "@angular/core";

@Injectable(
  {
    providedIn: 'root'
  }
)
export class IOTService {
  dispositivoSelezionato:any = {};
  tipoDispositivo = 'Wearable';
}
