import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'edificioStato'
})
export class EdificioStatoPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if (value === 'incorso') {
      return 'In Corso'
    } else if (value === 'nessunsopralluogo') {
      return 'Nessun Sopralluogo'
    } else if (value === 'completato') {
      return 'Completato'
    } else {
      return '';
    }
  }
}
