import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utente } from 'src/app/MODELS/USER/utente';
import { AlertService } from 'src/app/SERVICES/GENERAL/alert.service';
import { UsersService } from 'src/app/SERVICES/index';
import {Message} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
@Component({
  selector: 'app-aggiungi-gruppo',
  templateUrl: './aggiungi-gruppo.component.html',
  styleUrls: ['./aggiungi-gruppo.component.scss']
})
export class AggiungiGruppoComponent implements OnInit {

  @Output() backGruppi=new EventEmitter<boolean>();

  form: FormGroup;

  submitted=false;
  tornaGesGruppi;
  users: Utente[];

  allUser: Utente[];
  idUtentiSelezionati: String[]=[];
  selectedUsers3: Utente[];
  searchform: FormGroup;
  listautenti: Utente[];
  allListautenti: Utente[];
  msgs: Message[] = [];
  carica: boolean;
  appoggioclick: number;
 
    constructor( 
      private formBuilder: FormBuilder,
      private userService: UsersService,
      private alertService: AlertService,
      private primengConfig: PrimeNGConfig,) {
        
     }
  
    ngOnInit(): void {
      this.primengConfig.ripple = true;
      this.form = this.formBuilder.group({

      nome: ['', [Validators.required, Validators.pattern(/.*\S.*/)]],
        descrizione: ['', [Validators.required, Validators.pattern(/.*\S.*/)]]

      });
      this.searchform = this.formBuilder.group({
        search: ['', Validators.required]
      })
      this.userService.getAll().subscribe((res) => {
        this.listautenti = res.data.listaUtentiAttivi;
     
        this.allListautenti = this.listautenti;
        
      }, (error) => {
  
      });
    }

 
  get f() { return this.form.controls; }

 
    onSubmit() {
      this.submitted = true;
      if (this.form.invalid) {
        return;
      }
      
      let nome = this.form.value.nome;     
      let descrizione = this.form.value.descrizione;
      
      this.idUtentiSelezionati = new Array;
      
      if(this.selectedUsers3){

          this.selectedUsers3.forEach(user => {
            let id: string;
            id = user.id;
            this.idUtentiSelezionati.push(id);

          });
         
        }
        else {
          this.idUtentiSelezionati = [];
        }

      this.userService.createGruppo(nome, descrizione, this.idUtentiSelezionati).subscribe(
        (res) => { 
          this.alertService.success(res.status.description);
            this.backGruppi.emit(true);
          
        }), 
      (error) => { 
    
      };
      
    }
    
    tornaGestioneGruppi(vai){
      
      this.backGruppi.emit(vai);
     
    }
    searchInUser() {
      
      
      let valore: string = this.searchform.value.search;
      
     this.listautenti = this.allListautenti;
      if (valore) {
        let listautenti = []
        this.listautenti.forEach(user => {
          let nomeCognome = user.nome + ' ' + user.cognome;
          let cognomeNome = user.cognome + ' ' + user.nome;
          user.gruppos.forEach(gruppo => {
            if (gruppo.nomeGruppo.toLowerCase().includes(valore.toLowerCase())) {
              if (!(listautenti.find(x => x === user))) {
              listautenti.push(user);
            }
            }
          })
          if (cognomeNome.toLowerCase().includes(valore.toLowerCase()) || 
              nomeCognome.toLowerCase().includes(valore.toLowerCase()) || 
              user.nome.toLowerCase().includes(valore.toLowerCase()) || 
              user.cognome.toLowerCase().includes(valore.toLowerCase()) || 
              user.email.toLowerCase().includes(valore.toLowerCase()) 
          ) {
            if (!(listautenti.find(x => x === user))) {
            listautenti.push(user);
            }
          }
        });
        this.listautenti = listautenti;
        
        
        
      } else if (!valore) {
        this.listautenti = this.allListautenti;
      
       
      }
    }
  }